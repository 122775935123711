import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import kFormatter from '../../../utils/kFormatter';

import postIcon from '../../../../public/assets/icons/COMMUNITY-BLACK.svg';
import './PostNumber.scss';

class PostNumber extends Component {
  static defaultProps = {
    count:0
  }

  static propTypes = {
    count: PropTypes.number
  }

  render() {
    const { count, onClick } = this.props;

    if (count > 0) {
      const postText = count > 1 ? 'Posts' : 'Post'
      return (
        <span className="PostNumber" onClick={onClick ? onClick : null} style={{cursor: onClick? 'pointer' : 'default'}}>
          <SVG src={postIcon} /> {kFormatter(count)} {postText}
        </span>
      )
    } else {
      return null;
    }
  }
}

export default PostNumber;