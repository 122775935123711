import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import App from '.';

import { onRouteChange } from '../../store/App/actions';

class RouteChangeTracker extends Component {
  componentDidUpdate () {
    this.routeChanged();
  }

  routeChanged () {
    this.props.onRouteChange(this.props.history);

    if (this.props.scrollInstance) {
      this.props.scrollInstance.scroll({y:0});
    }
  }

  render () {
    return null;
  }

}

const mapStateToProps = ({App}) => {
  return {
    scrollInstance: App.os
  }
};


export default withRouter(connect(mapStateToProps, { onRouteChange })(RouteChangeTracker));