import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputMadLib, { INPUT_MADLIB_WHITE } from '../../common/Input/InputMadLib';

import './Onboarding.scss';

class OnboardingTextVerificationSubmit extends PureComponent {
  static propTypes = {
    
  };
   
  render() {

    const {
      smsCode,
      handleSmsCodeChange,
      back,
      errors
    } = this.props;

    return (
      <div className="Onboarding__page-container-username">
        <div>
          <h2 className="Onboarding__heading">Enter your code</h2>
          <h4 className="Onboarding__subheading"></h4>
        </div>
        <div className="Onboarding__username">
          <InputMadLib
            className="Onboarding__username-url-name code"
            hideMaxLengthText={true}
            inputMode="numeric"
            maxLength={6}
            minWidth={300}
            minWidthMobile={110}
            onChange={handleSmsCodeChange}
            placeholder="------"
            theme={INPUT_MADLIB_WHITE}
            value={smsCode}
          />
        </div>
        {
          (errors['smsCode']) && (
            <span className="Onboarding__error">{errors['smsCode'].message}</span>
          )
        }
        <button className="Onboarding__resend-code" onClick={back}>
          Resend Code
        </button>
      </div>
    )
  }
}

export default OnboardingTextVerificationSubmit;
