import React from "react";

import { WizardActions } from '../../../../components/Wizard';
import { ModalBody, ModalFooter } from '../../ModalContainer';
import Button, { THEME_BLUE } from '../../../../common/Button';
import productCollageImage from '../../../../../public/assets/Product-Collage-2.jpg';

const CollectiveCreationIntro = ({ wizard }) => {

  return (
    <div className="CollectiveCreationIntro">
      <ModalBody>
        <img src={productCollageImage} />

        <h1 className="CollectiveCreationModal__heading">Build your own community.</h1>

        <p className="CollectiveCreationModal__text">
          Become a founder of your own Collective, where you'll be able to
          cultivate and grow your own community, no matter where you are. The
          sky's the limit here!
        </p>

      </ModalBody>

      <ModalFooter>
        <Button 
          className="CollectiveCreationModal__cta"
          noOutline  
          onClick={wizard.next}
          theme={THEME_BLUE} 
          >Get Started
        </Button>
      </ModalFooter>
    </div>
  );
};

export default CollectiveCreationIntro;
