import React, { Component } from 'react';
import './Banner.scss';

class Banner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { left, right } = this.props;

    return (
      <div className="Banner">
        <div className="Banner__inner">
          <div className="Banner__left">
            {left}
          </div>
          <div className="Banner__right">
            {right}
          </div>
        </div>
      </div>
    )
  }
}

export default Banner;