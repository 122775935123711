import { call } from 'redux-saga/effects';
import fetchAPI from '../services/APIService';

export function* callAPI(...args) {
  const response = yield call(fetchAPI, ...args);
  if (!response.success) {
    const error = new Error("API Error");
    error.message = response.message;
    error.status = response.status;
    error.code = response.code;
    error.errors = response.errors;
    throw error;
  }
  return response;
}
