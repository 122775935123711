import {
  CLEAR_CONNECTIONS,
  CONNECTIONS_GET_FULFILLED
} from './actions';

const defaultState = {
  displayConnections: [],
  connections: [],
  limit: 10,
  total: 0,
  offset: 0,
  hasLoadedAllConnections: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_CONNECTIONS:
      return {
        ...state,
        displayConnections:action.payload ? [] : state.displayConnections,
        connections:[],
        total:0,
        offset:0,
        hasLoadedAllConnections:false
      }
    case CONNECTIONS_GET_FULFILLED: {
      let displayConnections = state.displayConnections;
      if (!action.request.initialPayload.name && state.offset === 0) displayConnections = action.payload.data;

      return {
        ...state,
        displayConnections,
        connections:state.connections.concat(action.payload.data),
        total:action.payload.total,
        offset:Math.min(state.offset + action.payload.data.length, action.payload.total),
        hasLoadedAllConnections: action.payload.total == Math.min(state.offset + action.payload.data.length, action.payload.total)
      }
    }
    default:
      return state;
  }
};
