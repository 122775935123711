/**
  Directory Search
 */
export const DIRECTORY_SEARCH = 'DIRECTORY_SEARCH';
export const DIRECTORY_SEARCH_SUCCESS = 'DIRECTORY_SEARCH_SUCCESS';
export const DIRECTORY_SEARCH_ERROR = 'DIRECTORY_SEARCH_ERROR';

export const directorySearch = (payload, loadMore) => {
  return {
    type: DIRECTORY_SEARCH,
    payload,
    loadMore
  }
};

export const directorySearchSuccess = payload => {
  return {
    type: DIRECTORY_SEARCH_SUCCESS,
    payload     
  }
};

export const directorySearchError = error => {
  return {
    type: DIRECTORY_SEARCH_ERROR,
    error
  }
};
