/**
  Contact requests
**/
export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST';
export const SEND_CONTACT_REQUEST_SUCCESS = 'SEND_CONTACT_REQUEST_SUCCESS';
export const SEND_CONTACT_REQUEST_ERROR = 'SEND_CONTACT_REQUEST_ERROR';
export const ACCEPT_CONTACT_REQUEST = 'ACCEPT_CONTACT_REQUEST';
export const IGNORE_CONTACT_REQUEST = 'IGNORE_CONTACT_REQUEST';
export const RESPOND_CONTACT_REQUEST_SUCCESS = 'RESPOND_CONTACT_REQUEST_SUCCESS';
export const RESPOND_CONTACT_REQUEST_ERROR = 'RESPOND_CONTACT_REQUEST_ERROR';
export const RENEW_CONTACT_REQUEST_STATE = 'RENEW_CONTACT_REQUEST_STATE';

export const sendContactRequest = payload => {
  return {
    type: SEND_CONTACT_REQUEST,
    payload
  }
};

export const sendContactRequestSuccess = payload => {
  return {
    type: SEND_CONTACT_REQUEST_SUCCESS,
    payload
  }
};

export const sendContactRequestError = error => {
  return {
    type: SEND_CONTACT_REQUEST_ERROR,
    error
  }
};

export const renewContactRequestState = () => {
  return {
    type: RENEW_CONTACT_REQUEST_STATE
  }
}

export const acceptContactRequest = (requestId, username) => {
  return {
    type: ACCEPT_CONTACT_REQUEST,
    payload: { requestId, username }
  }
}

export const ignoreContactRequest = (requestId) => {
  return {
    type: IGNORE_CONTACT_REQUEST,
    payload: requestId
  }
}

export const respondContactRequestSuccess = payload => {
  return {
    type: RESPOND_CONTACT_REQUEST_SUCCESS,
    payload
  }
}

export const respondContactRequestError = payload => {
  return {
    type: RESPOND_CONTACT_REQUEST_ERROR,
    payload
  }
}

/**
  Get Contacts
**/
export const CONTACTS_GET = 'CONTACTS_GET';
export const CONTACTS_GET_EMPTY = 'CONTACTS_GET_EMPTY';
export const CONTACTS_GET_SUCCESS = 'CONTACTS_GET_SUCCESS';
export const CONTACTS_GET_ERROR = 'CONTACTS_GET_ERROR';
export const CONTACTS_SEARCH = 'CONTACTS_SEARCH';

export const contactsGet = () => {
  return {
    type: CONTACTS_GET
  }
};

export const contactsGetSuccess = (payload) => {
  return {
    type: CONTACTS_GET_SUCCESS,
    payload
  }
};

export const contactsGetError = (error) => {
  return {
    type: CONTACTS_GET_ERROR,
    error
  }
};

export const contactsGetEmpty = () => {
  return {
    type: CONTACTS_GET_EMPTY
  }
}

export const contactsSearch = (search_term) => {
  return {
    type: CONTACTS_SEARCH,
    payload: search_term
  }
}