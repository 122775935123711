import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InlineCommenting from '../index';
import CommentFooter from '../../CommentFooter';

import '../InlineCommenting.scss';

class InlineCommentingComment extends Component {
  static propTypes = {
    className: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    controlledValue: PropTypes.string
  };

  render() {
    return (
      <CommentFooter className={this.props.className}>
        <InlineCommenting
          setRef={this.props.setRef}
          onSubmit={this.props.onSubmit}
          onChange={this.props.onChange}
          value={this.props.value}
          controlledValue={this.props.controlledValue}
        />
      </CommentFooter>
    )
  }
}

export default InlineCommentingComment;
