import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";

import Notification from "./index";

class NotificationInvite extends Component {
  onClickNotification = e => {
    if (e.target.id === 'sender-link') return;

    const {closeSideNav, invite, history} = this.props;
    if (closeSideNav) closeSideNav();
    history.push(`/groups/${invite.collective.slug}?invite_token=${invite.token}`);
  };

  render() {
    const { props } = this;

    const { contentClassName, sender, invite } = this.props;

    return (
      <Notification onClick={this.onClickNotification} {...props}>
        <p
          className={classNames(
            "Notification__text--content",
            contentClassName
          )}
        >
          <Link
            className="NotificationText__text--link"
            id="sender-link"
            to={`/${sender.username}`}
          >
            {sender.first_name} {sender.last_name}
          </Link> invited you to the {' '}
          <Link
            className="NotificationText__text--link"
            to={`/groups/${this.props.invite.collective.slug}?invite_token=${invite.token}`}
          >
            {invite.collective.name}
          </Link> Collective!
        </p>
      </Notification>
    );
  }
}

export default withRouter(NotificationInvite);
