import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Radio.scss';

class Radio extends Component {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    radioContainerClassName: PropTypes.string,
    radioClassName: PropTypes.string,
    radioInputClassName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      checked: null
    }
  }

  handleOnChange = e => {
    const optionName = e.target.value;

    this.setState({ checked: optionName });
    this.props.onChange(optionName);
  }

  render() {
    const {
      name,
      options,
      selection,
      onChange,
      radioContainerClassName,
      radioClassName,
      radioInputClassName,
      disabled
    } = this.props;

    return (
      <div
        className={classNames(
          "Radio",
          radioContainerClassName
        )}
      >
        {
          options.map(option => (
            <label
              className="Radio__container"
              key={option}
            >
              <input type="radio" disabled={disabled} checked={selection == option} name={name} value={option} onChange={this.handleOnChange} />
              <span className="Radio__checkmark"></span>
              <span className="Radio__label">{option}</span>
            </label>
          ))
        }
      </div>
    )
  }
}

export default Radio;
