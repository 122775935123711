import React, { Component } from "react";

import mainHeroImage from '../../../../../public/assets/illustrations/main-hero.png';

class WelcomeModalMessage extends Component {
  render() {
    return (
      <div>
        <h2 className="WelcomeModal__header">Welcome to Girlboss!</h2>

        <div className="WelcomeModal__hero">
          <img src={mainHeroImage} />
        </div>

        <p className="WelcomeModal__text">
          We're a community of ambitious women with big dreams. We show up to support
          each other, celebrate successes, and help each other through tough
          stuff along the way.
        </p>
      </div>
    );
  }
}

export default WelcomeModalMessage;
