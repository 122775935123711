import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ProfilePhoto, {
  SIZE_MEDIUM
} from '../../components/ProfilePhoto';
import Button, {
  THEME_BLACK
} from '../../common/Button';

import { routes } from '../../config/routes';

import SophiaPhoto from '../../../public/assets/Homepage/Sophia2.jpg';

import './WelcomeMessage.scss';

class WelcomeMessage extends PureComponent {     
  render() {
    const { journeyCards, bio, industry, location, photo } = this.props;

    let hasJourneyCards = journeyCards.length > 0;
    let hasBio = false;
    if (bio.id_like_to_meet &&
        bio.id_like_to_learn &&
        bio.im_good_at) {
      hasBio = true;
    }
    let hasLocation = location !== '';
    let hasIndustry = industry !== '';
    let hasPhoto = photo !== 'https://storage.googleapis.com/girlboss_profile_pictures/avatar_default.png';

    if (hasLocation && hasBio && hasIndustry && hasPhoto) return null;

    return (
      <div className="WelcomeMessage">
        <ProfilePhoto
          size={SIZE_MEDIUM}
          className="WelcomeMessage__photo"
          photo={SophiaPhoto}
          alt="Sophia Amoruso, CEO of Girlboss"
        />
        <div className="WelcomeMessage__container">
          <p className="WelcomeMessage__header">
            Hi! I'm Sophia.
          </p>
          <p>
            Thank you for joining, and welcome. Don't forget
            to complete your profile!
          </p>
          <Button
            component={Link}
            className="WelcomeMessage__button"
            to={routes.onboarding}
            theme={THEME_BLACK}
          >
            Update now
          </Button>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { user } = state.User;

  return {
    journeyCards: user.journey_cards || [],
    bio: user.bio || {},
    industry: user.industry || '',
    photo: user.id ? user.photo.thumbnail : ''
  }
}

export default connect(mapStateToProps)(WelcomeMessage);
