import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ContactRequestModal.scss';

class ContactRequestModalSent extends Component {
  static propTypes = {
    user:PropTypes.object
  };

  render() {
    const { user } = this.props;

    return (
      <div className="ContactRequestModal__inner">
        <h4 className='ContactRequestModal__sent-heading'>Request sent!</h4>
        
        <p className='ContactRequestModal__sent-subheading'>
          Thanks for saying 👋 hey!
        </p>
        <p className='ContactRequestModal__sent-subheading'>
          You'll be notified if {user.first_name} accepts your request. 
        </p>
        <p className='ContactRequestModal__sent-subheading'>
          If so, your emails will be visible to each other on your profiles.
        </p>
      </div>
    )
  };
};

export default ContactRequestModalSent;
