import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import locationFormatter from '../../../../utils/locationFormatter';

import './ContactRequestResponseModal.scss';

const intents = {
  'im_a_fan': 'is a fan',
  'lets_collaborate' : 'wants to collaborate',
  'lets_meet_up': 'wants to meet up',
  'i_can_help_you': 'wants to help you',
  'id_love_your_help': 'wants your help'
}

class ContactRequestResponseContent extends Component {
  static propTypes = {
    contactRequest:PropTypes.object,
    sender:PropTypes.object,
    onAccept:PropTypes.func,
    onIgnore:PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { sender, contactRequest } = this.props;

    return (
      <div className="ContactRequestResponseModal__container">
          <h2 className="ContactRequestResponseModal__title">{sender.first_name} {intents[contactRequest.request_type]}!</h2>
          <div className="ContactRequestResponseModal__sender">
            <div style={{backgroundImage:`url(${sender.photo.medium})`}} className="ContactRequestResponseModal__sender--photo" />

            <p className="ContactRequestResponseModal__sender--name">{sender.first_name} {sender.last_name}</p>

            <p className="ContactRequestResponseModal__sender--details">{sender.headline}</p>
            <p className="ContactRequestResponseModal__sender--details">{locationFormatter(sender.location)}</p>    
          </div>

          <div className="ContactRequestResponseModal__message">
            <p>{contactRequest.initial_message}</p>
          </div>

          <p className="ContactRequestResponseModal__disclaimer">
            By accepting, you agree to share your email address with {sender.first_name}.
          </p>

          <div className="ContactRequestResponseModal__cta">
              <button onClick={() => this.props.onIgnore(contactRequest.id)}>Ignore</button>
              <button onClick={() => this.props.onAccept(contactRequest.id)}>Accept</button>
          </div>
      </div>
    )
  }
}

export default ContactRequestResponseContent;
