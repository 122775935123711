import { store } from '../index';
import { modalOpen } from '../store/Modal/actions';
import { SIGNUP_PROMPT_MODAL } from '../containers/ModalManager/ModalTypes';
import { getToken } from '../lib/localStorage'


function openSignupModal() {
  store.dispatch(modalOpen({
    modal: SIGNUP_PROMPT_MODAL,
    whiteExitButton: true
  }));
}

export const checkAuth = (func) => {
  const token = getToken();
  if (!token) {
    return openSignupModal;
  } 
  
  return func;
}