import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { selectCollectivePolicy } from '../CollectivePolicy';
import MembersPreview from '../../../components/Collectives/MembersPreview';
import PostNumber from '../../../components/Collectives/PostNumber';
import addIcon from '../../../../public/assets/icons/ADD.svg';
import checkIcon from '../../../../public/assets/icons/CHECK.svg'
import crownIcon from '../../../../public/assets/icons/CROWN.svg';
import { THEME_BLUE, THEME_LIGHT_BLUE } from '../../../common/Button';
import ButtonToggle from '../../../common/Button/ButtonToggle';
import SVG from 'react-inlinesvg';

import './CollectivesList.scss';

class CollectivesListItem extends Component {
  static defaultProps = {
    showPostNumber: true,
    noSubscription: false
  }

  static propTypes = {
    collective: PropTypes.object,
    user: PropTypes.object,
    listType: PropTypes.oneOf(['recommended', 'user']),
    onSubscribe: PropTypes.func,
    onUnsubscribe: PropTypes.func,
    showPostNumber: PropTypes.bool,
    toggleSubscriptionOnClick: PropTypes.bool,
    noSubscription: PropTypes.bool
  }

  onSubscribe = e => {
    e.stopPropagation();
    this.props.onSubscribe({
      collective_id: this.props.collective.id,
      list: this.props.listType
    });
  }

  onUnsubscribe = e => {
    e.stopPropagation();
    const memberId = this.props.collective.members.find(member => (member.user.id === this.props.user.id)).id;
    this.props.onUnsubscribe({
      memberId,
      collective_id: this.props.collective.id,
      slug: this.props.collective.slug,
      list: this.props.listType
    });
  }

  toggleSubscription = e => {
    const { collective, isMember, user } = this.props;
    const isSubscribed = isMember ? isMember : !!collective.members.find(member => member.user.id === user.id);
    if (isSubscribed) {
      this.onUnsubscribe(e);
    } else {
      this.onSubscribe(e);
    }
  }

  goToCollective = () => {
    this.props.history.push(`/groups/${this.props.collective.slug}`);
    if (this.props.modalClose) this.props.modalClose();
  }

  render() {
    const {
      children,
      collective, 
      collectivePolicy,
      user, 
      showPostNumber, 
      showAvatar, 
      isMember, 
      toggleSubscriptionOnClick, 
      noSubscription,
      noCTA
    } = this.props;

    let isSubscribed = isMember ?
      isMember :
      collective.members ?
        !!collective.members.find(member => member.user.id === user.id) :
        false;

    let canSubscribe = collectivePolicy.subscribe();

    return (
      <div className="CollectivesListItem" onClick={toggleSubscriptionOnClick ? this.toggleSubscription : () => {}}> 
        <div
          className="CollectivesListItem__photo"
          style={{ backgroundImage: `url(${collective.image ? collective.image.medium : ''})` }}
        >
          { (collective.role === 'admin' || collective.role === 'owner') && <span className="CollectivesListItem__photo--admin"><SVG src={crownIcon} /></span> }
        </div>

        <div className="CollectivesListItem__details">
          <div className="CollectivesListItem__details__title">{collective.name}</div>

          <div className="CollectivesListItem__details--container">
            <MembersPreview showAvatar={showAvatar} members={collective.members} memberCount={collective.member_count ? collective.member_count : 0} user={user ? user.id : -1} />
            { showPostNumber && <PostNumber count={collective.post_count} />}
          </div>
        </div>

        {
          !children && !noCTA && canSubscribe && !noSubscription && !isMember && (
            <ButtonToggle
              noOutline
              pill
              className={classnames(
                "CollectivesListItem__cta", {
                  "CollectivesListItem__cta__checked": isSubscribed,
                  "CollectivesListItem__cta__unchecked": !isSubscribed
                })}
              className="CollectivesListItem__cta"
              checkedTheme={THEME_BLUE}
              uncheckedTheme={THEME_LIGHT_BLUE}
              checkedContent={<React.Fragment><SVG src={checkIcon} /></React.Fragment>}
              uncheckedContent={<React.Fragment><SVG src={addIcon} /></React.Fragment>}
              isChecked={isSubscribed}
              onClick={this.toggleSubscription}
            />
          ) 
        }
        { children }

        {
          !toggleSubscriptionOnClick && (
            <div className="CollectivesListItem__link" onClick={this.goToCollective} />
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state, { collective }) => {
  return {
    collectivePolicy: selectCollectivePolicy(state.User.user, collective)
  }
}

export default connect(mapStateToProps)(withRouter(CollectivesListItem));