import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserActions from '../../containers/UserActions';

import { TYPE_COMMENT } from '../../components/UserWithBody';
import Button, { THEME_GRAY, THEME_BLUE } from '../../common/Button';
import chatIcon from '../../../public/assets/icons/CHAT.svg';
import praiseIcon from '../../../public/assets/icons/PRAISE.svg';

import {
  PRAISE_REACTION_TYPE,
  getActivityIcon,
  reactionTypes
} from '../../store/Legion/logic';

import './ReactionsAndCommentsText.scss';

class ReactionsAndCommentsText extends Component {
  static propTypes = {
    reactions: PropTypes.arrayOf(PropTypes.shape({
      user_id: PropTypes.number.isRequired,
      post_id: PropTypes.number,
      comment_id: PropTypes.number,
      reaction_type: PropTypes.number.isRequired
    })),
    commentsCount: PropTypes.number, // not used in replies
    isText: PropTypes.bool,
    onReact: PropTypes.func.isRequired,
    onComment: PropTypes.func.isRequired,
    route: PropTypes.string,
    isReacted: PropTypes.bool
  };

  static defaultProps = {
    isText: false,
    commentCount: 0,
    reactions: [],
    onComment: () => {}
  };

  getReactionsText = () => {
    const reactionsCount = this.props.reactions.length;
    const action = reactionTypes[PRAISE_REACTION_TYPE].action;

    if (reactionsCount === 1) return `${reactionsCount} ${action}`;
    return `${reactionsCount} ${action}s`;
  }

  getReactionsIcons = () => {
    const reactionsCount = this.props.reactions.length || '';

    return (
      <Button 
        pill
        noOutline
        className="ReactionsAndCommentsText__container--icon"
        theme={this.props.isReacted ? THEME_BLUE : THEME_GRAY}
      >
        <img src={praiseIcon} style={{width: '19px', top: '-1px', marginRight: reactionsCount > 0 ? '5px' : '0'}} />
        {reactionsCount}
      </Button>
    );
  }

  getCommentsText = () => {
    const { commentsCount } = this.props;

    if (commentsCount === 1) return `${commentsCount} comment`;
    return `${commentsCount} comments`;
  }

  getCommentsIcons = () => {
    // for replies usually
    const { commentsCount, reply } = this.props;

    let replyText;
    if(commentsCount < 1) {
      replyText = 'Reply';
    } else if(commentsCount === 1) {
      replyText = 'reply';
    } else {
      replyText = 'replies';
    };

    return (
      <Button
        pill
        noOutline
        onClick={reply}
        theme={THEME_GRAY}
        className="ReactionsAndCommentsText__container--icon"
      >
      <img src={chatIcon} style={{width: '27px', top: '1px'}} /> {commentsCount > 0 ? commentsCount : null} {replyText}
      </Button>
    );
  }

  render() {
    const { 
      isText, 
      route, 
      readOnly,
      userId,
      contentId,
      type,
      actions,
      currentUserId,
      timestamp,
      flagged,
      postId,
      isLoggedIn
    } = this.props;
    const Component = ((readOnly || !route) ? 'div' : Link);

    return (
      <Component to={route}>
        <div className="ReactionsAndCommentsText">

          {/* hiding the reply button until we have user tagging */}

          {type === TYPE_COMMENT ? 
            <div
            className={classNames(
              "ReactionsAndCommentsText__container"
            )}
            onClick={!isText ? this.props.onComment : () => {}}
          >
            { isText ? this.getCommentsText() : this.getCommentsIcons() }
          </div>
          : null}
        
          <div
            className={classNames(
              "ReactionsAndCommentsText__container"
            )}
            onClick={!isText ? this.props.onReact : () => {}}
          >
            { isText ? this.getReactionsText() : this.getReactionsIcons() }
          </div>
          <span className="ReactionsAndCommentsText__timestamp">{timestamp}</span>

          {isLoggedIn && (
             <div className="ReactionsAndCommentsText__flag">
              <UserActions
                userId={userId}
                typeId={contentId}
                type={type}
                doesBelongToUser={currentUserId === userId}
                {...actions}
                flag={currentUserId !== userId}
                flagged={flagged}
                postId={postId}
              />
            </div>
          )}
        </div>
      </Component>
    )
  }
}

export default ReactionsAndCommentsText;
