import React, { Component } from 'react';
import classNames from 'classnames';

import './Card.scss';

class Card extends Component {
  render() {
    const { backgroundColor, className } = this.props;

    return (
      <div className={classNames(className, 'Card')} style={{backgroundColor}}>
        {this.props.children}
      </div>
    )
  }
}

export default Card;