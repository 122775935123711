import React, { Component } from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ContentTags.scss';

class ContentTag extends Component {
    static propTypes = {
        tagName: PropTypes.string,
        tagIndex: PropTypes.string,
        selected: PropTypes.bool,
        selectTag: PropTypes.func
    };

    render() {
        const { tagName, tagIndex, selected, selectTag } = this.props;

        return (
            <div className={classnames('ContentTag', {selected})} onClick={() => selectTag(tagIndex)}>
                {tagName}
            </div>
        )
    };
};


export default ContentTag;