const nycRally2017 = [
  {
    id: 245119474,
    thumbnail_id: 676919792,
    title: 'Welcome Remarks, NYC',
    description: 'Girlboss founder and CEO Sophia Amoruso kicks off the day, exploring success, failure, resilience, and confidence—all through the lens of her own journey.',
    featuring: 'Sophia Amoruso'
  },
  {
    id: 245260835,
    thumbnail_id: 669889541,
    title: 'Success Is An Inside Job',
    description: `Success is a journey more than anything else, and the way each of us thinks about where it leads is completely different—and can change over time. How can you create space for yourself to define and redefine this word for yourself in ways that give you freedom? And how can you try something today that will set you on that path? Consider this a crash course in asking yourself "what if"—and then taking a big leap.`,
    featuring: 'Gabby Bernstein'
  },
  {
    id: 246149764,
    thumbnail_id: 671039304,
    title: 'Resilience on the Bumpy Road to Entrepreneurship',
    description: `Founders who have built some of the most interesting businesses of our time reflect on the failures and stumbling blocks that pointed them to where they are today. We all know the struggle makes you stronger—but here's how to actually survive it.`,
    featuring: 'Lisa Price, Aurora James, and Kathryn Minshew in conversation with Sophia Amoruso'
  },
  {
    id: 245124784,
    thumbnail_id: 669717912,
    title: 'Ladies Pioneering Tech',
    description: `What does a woman taking over the tech world look like? Any other woman in this room. Women have the power to lead innovation—and we're bringing four leaders in the space together to talk about why that's not just important, but necessary.`,
    featuring: 'Beth Comstock and Nina Tandon in conversation with Neha Gandhi'
  },
  {
    id: 245126095,
    thumbnail_id: 669719461,
    title: 'Body Talk: Nurturing Self-Love in our Own Skin',
    description: `An intimate conversation with the model and author about finding your voice, speaking your truth, and nurturing confidence in the face of haters who will happily shout you down if you let them.`,
    featuring: 'Ashley Graham in conversation with Sophia Amoruso'
  },
  {
    id: 245103191,
    thumbnail_id: 669690995,
    title: 'Cultivating Confidence in a World that Stomps Daisies',
    description: 'An intimate conversation with the model and author about finding your voice, speaking your truth, and nurturing confidence in the face of haters who will happily shout you down if you let them.',
    featuring: 'Dr. Lauren Hazzouri, Tess Holliday, and Joanna Coles, in conversation with Jerico Mandybur'
  },
  {
    id: 245135463,
    thumbnail_id: 669733285,
    title: 'All Ships Rise',
    description: `Women from a range of industries discuss the power of representaion and diversity—and why this isn't just a fringe initiative or the right thing to do. It's the only way forward if we want to continue to advance as a society, produce the best and most innovative work, and compete globally.`,
    featuring: 'Amani Al-Khatahtbeh and Ukonwa Ojo in conversation with Deena Drewis'
  },
  {
    id: 245104375,
    thumbnail_id: 245104375,
    title: 'Fixing a Broken World',
    description: `Health care. Gun control. Immigration. The right to protest. Basic life-giving essentials in disaster-sticken parts of the country. These are just some of the challenging issues we're staring down today. So, how do you make a real difference in a broken world? Three women who are making meaningful change every day show us how we can all make activism our side hustles.`,
    featuring: 'Zerlina Maxwell, Alencia Johnson, and Nancy Lublin, in conversation with Neha Gandhi'
  },
  {
    id: 245130406,
    thumbnail_id: 669881094,
    title: 'Lead like a Woman',
    description: `Don't cry at work. Don't say sorry so much. Don't be too emotionally attached to your work and your team. We've probably all heard these BS pieces of prescriptive advice that push women to behave more like a man in order to get ahead. But what if we leaned more on our authentic selves to manage teams in the ways that took advantage of our strength? Three industry leaders break down the most important management lessons they have learned over their careers.`,
    featuring: 'Linda Wells and Deborah Curtis in conversation with Neha Gandhi'
  },
  {
    id: 245139973,
    thumbnail_id: 669740581,
    title: `It's Complicated: Our Relationship with $$ (with opening remarks by Sallie Krawcheck)`,
    description: `Pay equality, re-entry into the workforce, flexibility, earning power, and more all start with the way we as women think and talk about money. Except, most of us don't talk about money nearly often enough. So, we're asking a former investor, a personal finance pro, and a big-deal finance exec-turned founder to help us get started uncomplicating our relationships with money.`,
    featuring: 'Sallie Krawcheck, Sarah Kunst, and Priya Malani, in conversation with Deena Drewis'
  },
  {
    id: 244915282,
    thumbnail_id: 669449559,
    title: 'Productivity, Flexibility, and the Future',
    description: 'The modern workplace is changing—for the better. We’re moving into a world where flexibility is non-negotiable, and productivity on your own terms is what businesses are starting to value about all else. Why do we need flexibility at work? How do we become better as a result? And how do you create the kind of start-up that builds flexibility and productivity into its core values from day one? The founders of Werk, a job marketplace that’s all about flexibility, will show you.',
    featuring: 'Annie Dean presented by Google Chrome'
  },
  {
    id: 245133263,
    thumbnail_id: 669724771,
    title: 'Meet the Girlboss Grant Winners'
  },
  {
    id: 246554891,
    thumbnail_id: 671601996,
    title: 'Sell Yourself, Not Your Soul',
    description: 'How to build a brand for yourself—and your business—in an age of influencer marketing, social on steroids, and a whole lot of noise.',
    featuring: 'Elaine Welteroth, Leandra Medine, and Whitney Wolfe, in conversation with Moj Mahdara'
  },
  {
    id: 669883452,
    thumbnail_id: 669883452,
    title: 'Sex & Dating in a Swipe-Right Era',
    description: 'A thoughtful exploration of modern relationships with the woman who has changed the way we all think about sex, infidelity, partnership, and companionship.',
    featuring: 'Esther Perel in conversation with Jerico Mandybur'
  },
  {
    id: 245263498,
    thumbnail_id: 669890515,
    title: 'Startup Studio: How to Run an A+ Meeting',
    description: `How do you avoid ending up with a toxic meeting culture? In this session, we’ll help you run and create successful meetings and work with your team to get the job done while still maintaining a work-life balance. Founder Reese Evans share the best practices every founder needs to know to build an infrastructure that works for you, not against you.`,
    featuring: 'Reese Evans presented by Google Chromebook'
  },
  {
    id: 245262966,
    thumbnail_id: 669889899,
    title: 'Startup Studio: What Good Internal Communications Looks Like',
    description: `Communications when you’re working with a tight-knit team is ESSENTIAL in creating a successful startup. The more you create smart practices from the get-go, the better you can scale your business. Dara Deshe Segal explains how to build the kind of business where everyone can communicate clearly and respectfully, and share information through processes that make sense and scale.`,
    featuring: 'Dara Deshe Segal presented by Google Chromebook'
  },
  {
    id: 245264627,
    thumbnail_id: 669893706,
    title: 'Startup Studio: Navigating the Wild World of Venture',
    description: 'What does it mean to raise VC funding in order to get your business off the ground? What kinds of businesses should get this kind of funding? And if you need it, how do you set up meetings, score the right introductions, and blow it out of the water with the meetings that you do get? Learn how to navigate the world of venture with the a founding partner and the CFO of Human Ventures, a fund that’s all about helping “normal” people with great ideas become successful founders.',
    featuring: 'Heather Hartnett and Lindsey Taylor Wood presented by Google Chromebook'
  },
  {
    id: 245263802,
    thumbnail_id: 669891283,
    title: 'Startup Studio: Getting your Finances in Order',
    description: `This might not be the most glamorous part of building a business, but it’s the most essential. Smart financial planning is the key to building a company that can become profitable and scale. This is where great strategy starts, and where you figure out what you can spend and how you should price what you sell—and track that money as it comes in. Join the head of finance and ops at one of the coolest maternity clothing brands out there, for some real talk about $$.`,
    featuring: 'Lindsay Bressler presented by Google Chromebook'
  },
  {
    id: 245264298,
    thumbnail_id: 669891673,
    title: 'Startup Studio: Brand-Building 101, Part 1',
    description: 'Learn how to build a distinctive brand that people remember—and that inspires your brand fans to loyalty and conversion. How do you make your brand truly unforgettable and unmissable? A branding pro and a founder who’s done it shows you how.',
    featuring: 'Denise Lee presented by Google Chromebook'
  },
  {
    id: 245263273,
    thumbnail_id: 669890147,
    title: 'Brand-Building 101, Part 2',
    description: 'Learn how to build a distinctive brand that people remember—and that inspires your brand fans to loyalty and conversion. How do you make your brand truly unforgettable and unmissable? A branding pro and a founder who’s done it shows you how.',
    featuring: 'Denise Lee presented by Google Chromebook'
  },
  {
    id: 245134014,
    thumbnail_id: 669725720,
    title: 'How to Build a Social Audience',
    description: `What does traditional brand marketing look like when you can tap influencers, build a social following, and activate ambassadors without ever going through traditional channels? A social pro explains how to leverage your audience and build your brand.`,
    featuring: 'Kiki Von Glinow presented by Google Chromebook'
  }
];

export default nycRally2017;
