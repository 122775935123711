import {
  ONBOARDING_SAVE,
  ONBOARDING_SAVE_CLEAR,
  ONBOARDING_ERROR,
  ONBOARDING_ERROR_CLEAR,
  ONBOARDING_NEXT,
  ONBOARDING_BACK,
  ONBOARDING_SKIP,
  ONBOARDING_INFO_CHECK_SUCCESS,
  ONBOARDING_INFO_CHECK_ERROR,
  ONBOARDING_USERNAME_CHECK_SUCCESS,
  ONBOARDING_USERNAME_CHECK_ERROR,
  ONBOARDING_COUNTRY,
  ONBOARDING_COUNTRY_INTERNATIONAL,
  ONBOARDING_PHONE_CHECK_SUCCESS,
  ONBOARDING_PHONE_CHECK_ERROR,
  ONBOARDING_SIGNUP_SUCCESS,
  ONBOARDING_SIGNUP_ERROR,
  ONBOARDING_SAVE_PROFILE_SUCCESS,
  ONBOARDING_SAVE_PROFILE_ERROR,
  ONBOARDING_PROFILE_STARTED,
  ONBOARDING_PROFILE_COMPLETE
} from './actions';

import {
  GTMpushDataLayerEvent,
  GTM_SIGNUP_VALID_EMAIL,
  GTM_SIGNUP_VALID_USERNAME,
  GTM_SIGNUP_VALID_PHONE,
  GTM_SIGNUP_COMPLETED,
  GTM_ONBOARDING_IM_GOOD_AT,
  GTM_ONBOARDING_ID_LIKE_TO_LEARN,
  GTM_ONBOARDING_ID_LIKE_TO_MEET,
  GTM_ONBOARDING_LOCATION,
  GTM_ONBOARDING_INDUSTRY,
  GTM_ONBOARDING_PHOTO,
  GTM_SIGNUP_COUNTRY,
  GTM_SIGNUP_COUNTRY_INTERNATIONAL,
  GTM_ONBOARDING_STARTED
} from '../../lib/GoogleTagManager';

const initialState = {
  step: 0,
  isLoading: false,
  fields: {},
  completed: {},
  errors: {}
};

function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case ONBOARDING_NEXT: {
      return {
        ...state,
        step: state.step + 1
      };
    }
    case ONBOARDING_SKIP: {
      return {
        ...state,
        step: state.step + action.payload
      };
    }
    case ONBOARDING_BACK: {
      return {
        ...state,
        step: state.step - 1
      };
    }
    case ONBOARDING_SAVE: {
      const fieldsObj = state.fields || {};
      const mergedFields = Object.assign({}, fieldsObj, action.payload);

      return {
        ...state,
        fields: mergedFields
      };
    }
    case ONBOARDING_SAVE_CLEAR: {
      return {
        ...state,
        step:0,
        fields: {}
      };
    }
    case ONBOARDING_ERROR: {
      const errorsObj = state.errors || {};
      const mergedErrors = Object.assign({}, errorsObj, action.payload);

      return {
        ...state,
        errors: mergedErrors
      };
    }
    case ONBOARDING_ERROR_CLEAR: {
      return {
        ...state,
        errors: {}
      };
    }
    case ONBOARDING_INFO_CHECK_SUCCESS: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        GTMpushDataLayerEvent({ event: GTM_SIGNUP_VALID_EMAIL });
      }

      let mergeErrors;

      if (state.errors) {
        mergeErrors = { ...state.errors };
        delete mergeErrors['first_name'];
        delete mergeErrors['last_name'];
        delete mergeErrors['email'];
        delete mergeErrors['password'];
      }

      return {
        ...state,
        errors: mergeErrors,
        completed: completed,
        isLoading: false,
        emailToken: action.payload.email_token,
        step: state.step + 1
      };
    }
    case ONBOARDING_INFO_CHECK_ERROR: {
      return {
        ...state,
        errors: action.payload.errors,
        isLoading: false
      };
    }
    case ONBOARDING_USERNAME_CHECK_SUCCESS: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        GTMpushDataLayerEvent({ event: GTM_SIGNUP_VALID_USERNAME });
      }
      
      let mergeErrors;

      if (state.errors) {
        mergeErrors = state.errors;
        delete mergeErrors['username'];
      }

      return {
        ...state,
        errors: mergeErrors,
        isLoading: false,
        step: state.step + 1
      };
    }
    case ONBOARDING_USERNAME_CHECK_ERROR: {
      return {
        ...state,
        errors: action.payload.errors,
        isLoading: false
      };
    }
    case ONBOARDING_COUNTRY: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        GTMpushDataLayerEvent({ event: GTM_SIGNUP_COUNTRY });
      }
      return {
        ...state
      }
    }
    case ONBOARDING_COUNTRY_INTERNATIONAL: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        GTMpushDataLayerEvent({ event: GTM_SIGNUP_COUNTRY_INTERNATIONAL });
      }
      return {
        ...state
      }
    }
    case ONBOARDING_PHONE_CHECK_SUCCESS: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        GTMpushDataLayerEvent({ event: GTM_SIGNUP_VALID_PHONE });
      }

      let mergeErrors;

      if (state.errors) {
        mergeErrors = state.errors;
        delete mergeErrors['phone_number'];
      }

      return {
        ...state,
        errors: mergeErrors,
        isLoading: false,
        step: state.step + 1
      };
    }
    case ONBOARDING_PHONE_CHECK_ERROR: {
      return {
        ...state,
        errors: action.payload.errors,
        isLoading: false
      };
    }
    case ONBOARDING_SIGNUP_SUCCESS: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        GTMpushDataLayerEvent({ event: GTM_SIGNUP_COMPLETED });
      }

      let mergeErrors;

      if (state.errors) {
        mergeErrors = state.errors;
        delete mergeErrors['signup'];
      }

      return {
        ...state,
        errors: mergeErrors,
        isLoading: false,
        // [Uncomment when reenabling phone verification]
        // step: state.step + 1
        // [Remove when reenabling phone verification]
        step: state.step + 2
      };
    }
    case ONBOARDING_SIGNUP_ERROR: {
      let errors = Object.assign({}, state.errors);
      errors.smsCode = {
        message:'Invalid verification code'
      };
      let newState = Object.assign({}, state);
      return {
        ...newState,
        errors: errors,
        isLoading: false
      };
    }
    case ONBOARDING_PROFILE_STARTED: {
      let completed = Object.assign({}, state.completed);
      if (!completed['start']) {
        completed['start'] = true;
        GTMpushDataLayerEvent({ event: GTM_ONBOARDING_STARTED });
      }
      return {
        ...state,
        isLoading: false,
        errors:{},
        completed
      };
    }
    case ONBOARDING_SAVE_PROFILE_SUCCESS: {
      let completed = Object.assign({}, state.completed);
      if (!completed[state.step]) {
        completed[state.step] = true;
        /*
          0 - I'm good at
          1 - I'd like to learn
          2 - I'd like to meet
          3 - Location
          4 - Industry
          5 - Profile picture
        */
        switch (state.step) {
          case 0:
            GTMpushDataLayerEvent({ event: GTM_ONBOARDING_IM_GOOD_AT });
            break;
          case 1:
            GTMpushDataLayerEvent({ event: GTM_ONBOARDING_ID_LIKE_TO_LEARN });
            break;
          case 2:
            GTMpushDataLayerEvent({ event: GTM_ONBOARDING_ID_LIKE_TO_MEET });
            break;
          case 3:
            GTMpushDataLayerEvent({ event: GTM_ONBOARDING_LOCATION });
            break;
          case 4:
            GTMpushDataLayerEvent({ event: GTM_ONBOARDING_INDUSTRY });
            break;
          case 5:
            GTMpushDataLayerEvent({ event: GTM_ONBOARDING_PHOTO });
            break;
        }
      }

      return {
        ...state,
        isLoading: false,
        errors:{},
        completed,
        step: state.step + 1
      };
    }
    case ONBOARDING_SAVE_PROFILE_ERROR: {
      let errors = Object.assign({}, state.errors);
      errors.location = {
        message:action.payload
      };
      let newState = Object.assign({}, state);
      newState.fields.location = undefined;
      return {
        ...newState,
        errors:errors,
        isLoading: false
      };
    }
    case ONBOARDING_PROFILE_COMPLETE:
    default:
      return state;
  }
}

export default onboardingReducer;
