import React, { Component } from 'react'
import { connect } from 'react-redux';
import { onSelectingTag } from '../../store/Feed/actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ContentTag from'./ContentTag';
import './ContentTags.scss';

export const tags = {
    all: 'All',
    asks: 'Asks',
    offers: 'Offers',
    digital_firesides: 'Digital Firesides',
    marketing: 'Marketing',
    money: 'Money',
    entrepreneurship: 'Entrepreneurship',
    intros: 'Intros',
    self_care: 'Self-Care',   
    creative: 'Creative',
    technology: 'Technology',
    meet_ups: 'Meetups',
    career: 'Career',
    collabs: 'Collabs',
    life: 'Life',
    girlboss_moment: 'Girlboss Moments',
    podcasts: 'Podcasts',
    books: 'Books'     
};

class ContentTags extends Component {
    static propTypes = {
        viewport: PropTypes.oneOf(['mobile', 'desktop']),
        contentTags: PropTypes.array,
        selectTag: PropTypes.func
    };

    static defaultProps = {
        viewport: 'desktop'
    };

    selectingTag = tagIndex => {
        if(!this.props.isLoading) {
            this.props.onSelectingTag(tagIndex);
        };

        if(this.props.viewport === 'mobile') {
            window.scrollTo(0, 0);
        };
    };

    renderContentTags = () => {
        const contentTags = [];
        const { viewport } = this.props;
        for(let tag in tags) {
            const index = this.props.contentTags.indexOf(tag);
            // exclude 'all' if on mobile
            if(viewport === 'desktop' || (viewport === 'mobile' && tag !== 'all')) {
                contentTags.push(
                    <ContentTag 
                        key={tag}
                        tagName={tags[tag]} 
                        tagIndex={tag} 
                        selectTag={tagIndex => this.selectingTag(tagIndex)} 
                        selected={true ? index > -1 || (this.props.contentTags.length === 0 && tag === 'all') : false} />
                );
            };
        };

        return contentTags;
    };

    render() {
        const { viewport } = this.props;

        return (
            <div className={classnames("ContentTags", {'ContentTags__mobile': viewport === 'mobile'})}>
                {this.renderContentTags()}
            </div>
        )
    };
};

const mapStateToProps = state => {
    return {
        contentTags: state.Feed.contentTags,
        isLoading: state.Feed.isLoading
    };
};

export default connect(mapStateToProps, { onSelectingTag })(ContentTags);