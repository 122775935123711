import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProfilePhoto from '../ProfilePhoto';

import UserActions from '../../containers/UserActions';

import './UserWithHeadline.scss';

class UserWithHeadline extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({}),
    photo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subheadline: PropTypes.string,
    timestamp: PropTypes.object,
    secondaryClassName: PropTypes.string
  }

  goToProfile = () => {
    const { onClick } = this.props;
    if (onClick) return onClick();
    this.props.history.push(`/${this.props.username}`);
  }

  render() {
    const { 
      name, 
      subheadline, 
      username, 
      userId, 
      typeId, 
      currentUserId, 
      actions, 
      type, 
      onClick, 
      timestamp, 
      showTimeStamp,
      showAdminBadge 
    } = this.props;

    return (
      <div
        className={classNames(
          this.props.className,
          "UserWithHeadline"
        )}
        onClick={onClick}
      >
        <ProfilePhoto
          photo={this.props.photo}
          alt={name}
          onClick={this.goToProfile}
          showAdminBadge={showAdminBadge}
        />
        <div className="UserWithHeadline__container" onClick={this.goToProfile}>
          <p className="UserWithHeadline__name">{name}</p>
          <p className="UserWithHeadline__headline">{subheadline}</p>
          { 
            timestamp && showTimeStamp ? <p className="UserWithHeadline__timestamp">{timestamp}</p> : null
          }
        </div>

        {actions ?    
            <div className="UserCommentWithBody__container--actions">
              <UserActions
                userId={userId}
                typeId={typeId}
                type={type}
                doesBelongToUser={currentUserId === userId}
                {...actions}
              />
            </div> : null
        }
     
        
      </div>
    )
  }
}

export default withRouter(UserWithHeadline);
