import { defineRequest } from '../../lib/reduxRequests';

/* Collective Invites */

export const {
  INVITEES_GET,
  INVITEES_GET_FULFILLED,
  inviteesGet
} = defineRequest('INVITEES_GET');

export const {
  SEND_INVITES,
  SEND_INVITES_FULFILLED,
  sendInvites
} = defineRequest('SEND_INVITES')

export const {
  INVITE_GET,
  INVITE_GET_FULFILLED,
  inviteGet
} = defineRequest('INVITE_GET');

export const {
  INVITES_GET,
  INVITES_GET_FULFILLED,
  invitesGet
} = defineRequest('INVITES_GET');

export const {
  INVITE_ACCEPT,
  INVITE_ACCEPT_FULFILLED,
  inviteAccept
} = defineRequest('INVITE_ACCEPT');