import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ContactRequestModal.scss';

const IM_A_FAN = 'im_a_fan';
const LETS_COLLABORATE = 'lets_collaborate';
const LETS_MEET_UP = 'lets_meet_up';
const I_CAN_HELP_YOU = 'i_can_help_you';
const ID_LOVE_YOUR_HELP = 'id_love_your_help';

const INTENT_LABELS = {
  [IM_A_FAN]:"I'm a fan!",
  [LETS_COLLABORATE]:"Let's collaborate!",
  [LETS_MEET_UP]:"Let's meet up!",
  [I_CAN_HELP_YOU]:"I can help you!",
  [ID_LOVE_YOUR_HELP]:"I'd love your help!"
};

class ContactRequestModalConfirmation extends Component {
  static propTypes = {
    user:PropTypes.object,
    intent:PropTypes.string,
    message:PropTypes.string,
    handleConfirmation:PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  submit = () => {
    this.props.handleConfirmation();
  }

  render() {
    const { user, message, intent } = this.props;

    return (
      <div className="ContactRequestModal__inner">
      <h4 className='ContactRequestModal__heading'>Get specific</h4>
        <h6 className="ContactRequestModal__subheading">"{ INTENT_LABELS[intent] }"</h6>
        <p className='ContactRequestModal__content-review'>{ message }</p>
        <button className='ContactRequestModal__submit-button' onClick={this.submit}>Send</button>
      </div>
    )
  }
}

export default ContactRequestModalConfirmation;
