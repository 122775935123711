import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  magicSignUpTokenVerify
} from '../../store/Magic/actions';
import { routes } from '../../config/routes';

function hasTokenFromLocationSearch(locationStr) {
  // get the ?token
  const hasToken = locationStr.substr(0, 7);

  if (hasToken !== '?magic=') return '';
  return locationStr.substr(7, locationStr.length);
}

class MagicRoute extends Component {
  static defaultProps = {
    initializeDataCall: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingToken: true
    }
  }

  componentDidMount() {
    this.verifyToken();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.isLoading && !newProps.isLoading) {
      this.setState({ isLoadingToken: false });
    }
  }

  verifyToken() {
    // get token param from window
    const token = hasTokenFromLocationSearch(this.props.location.search);

    if (!token) {
      this.setState({ isLoadingToken: false });
    } else {
      this.props.magicSignUpTokenVerify(token);
    }
  }

  render() {
    const { isLoadingToken } = this.state;
    let RenderComponent = this.props.render;

    if (isLoadingToken) {
      return <div>Loading...</div>
    }

    if (!isLoadingToken && this.props.magicUser) {
      return (
        <Route render={() => (
          <RenderComponent {...this.props} />
        )} />
      )
    }

    return (
      <Redirect to={routes.root} />
    )
  }
}

function mapStateToProps({ Magic }) {
  return {
    isLoading: Magic.isLoading,
    magicUser: Magic.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    magicSignUpTokenVerify: (args) => dispatch(magicSignUpTokenVerify(args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MagicRoute));
