function getURLwithEnvironment(URL) {
  return `${getHostFromEnv()}${URL}`;
};

export const APIprefix = '/api';

export const replaceAPIUrlWithParams = function (APIUrl, params = {}) {
  const paramIDs = Object.keys(params);
  let URL = APIUrl;

  paramIDs.forEach(param => {
    URL = URL.replace(`:${param}`, encodeURIComponent(params[param]));
  });

  return URL;
}

export function getFullUrlFromEnv(url) {
  return `${APIprefix}${url}`;
};

const APIConfig = {
  login: {
    url: '/auth/login',
    method: 'POST',
    public: true
  },
  logout: {
    url: '/auth/logout',
    method: 'POST'
  },
  signup: {
    url: '/auth/signup',
    method: 'POST',
    public: true
  },
  authValidations: {
    url: '/auth/validations',
    method: 'POST',
    public: true
  },
  phoneCheck: {
    url: '/auth/phone-check',
    method: 'POST',
    public: true
  },
  profileComplete: {
    url: '/user/profile-complete',
    method: 'POST'
  },
  passwordResetRequest: {
    url: '/forgotpassword',
    method: 'POST',
    public: true
  },
  checkResetPasswordToken: {
    url: '/resetpassword/:token',
    params: {
      token: ''
    },
    method: 'GET',
    public: true
  },
  resetPassword: {
    url: '/resetpassword/:token',
    params: {
      token: ''
    },
    method: 'POST',
    public: true
  },
  updateUser: {
    url: '/user',
    params: {
      id: ''
    },
    method: 'PUT'
  },
  updateUserPhoto: {
    url: '/user/photo',
    method: 'POST'
  },
  deleteUser: {
    url: '/user/delete',
    method: 'POST'
  },
  getUser: {
    url: '/user',
    method: 'GET'
  },
  updatePassword: {
    url: '/user/password',
    method: 'PUT'
  },
  getNotifications: {
    url: '/notifications?limit=:limit&offset=:offset',
    params: {
      limit: '',
      offset: ''
    },
    method: 'GET'
  },
  readNotifications: {
    url: '/notifications',
    method: 'PUT'
  },
  readAllNotifications: {
    url: '/notifications/all',
    method: 'PUT'
  },
  getPreferencesExternal: {
    url: '/preferences/external?t=:token',
    params: {
      token: ''
    },
    method: 'GET',
    public: true
  },
  updatePreferencesExternal: {
    url: '/preferences/external',
    method: 'PUT',
    public: true
  },
  reactPost: {
    url: '/post/:post_id/reaction',
    params: {
      post_id: ''
    },
    method: 'POST'
  },
  deleteReactPost: {
    url: '/post/:post_id/reaction',
    params: {
      post_id: ''
    },
    method: 'DELETE'
  },
  reactComment: {
    url: '/comment/:comment_id/reaction',
    params: {
      comment_id: ''
    },
    method: 'POST'
  },
  deleteReactComment: {
    url: '/comment/:comment_id/reaction',
    params: {
      comment_id: ''
    },
    method: 'DELETE'
  },
  reportUser: {
    url: '/flag/user',
    method: 'POST'
  },
  reportPost: {
    url: '/flag/post',
    method: 'POST'
  },
  reportReply: {
    url: '/flag/reply',
    method: 'POST'
  },
  checkPost: {
    url: '/post/check',
    method: 'POST'
  },
  postPost: {
    url: '/post',
    method: 'POST'
  },
  editPost: {
    url: '/post/:id',
    params: {
      id: ''
    },
    method: 'PUT'
  },
  deletePost: {
    url: '/post/:id',
    params: {
      id: ''
    },
    method: 'DELETE'
  },
  getPost: {
    url: '/post/:id',
    params: {
      id: ''
    },
    public: true,
    method: 'GET'
  },
  getPostComments: {
    url: '/post/:id/comments?limit=:limit&offset=:offset',
    params: {
      id: '',
      limit: '',
      offset: ''
    },
    public: true,
    method: 'GET'
  },
  getCommentReplies: {
    url: '/comment/:id?limit=:limit&offset=:offset',
    params: {
      id: '',
      limit: '',
      offset: ''
    },
    method: 'GET'
  },
  getFeed: {
    url: '/feed?limit=:limit&offset=:offset&topics=:topics',
    params: {
      limit: '',
      offset: '',
      topics: ''
    },
    method: 'GET'
  },
  updateFeed: {
    url: '/feed/update?last_update=:last_update_millis&back_to=:back_to_millis&topics=:topics',
    params: {
      last_update_millis: '',
      back_to_millis: '',
      topics: ''
    },
    method: 'GET'
  },
  getCollectivesFeed: {
    url: '/feed/collective/:collectiveId?limit=:limit&offset=:offset',
    params: {
      collectiveId: '',
      limit: '',
      offset: ''
    },
    public: true,
    method: 'GET'
  },
  updateCollectivesFeed: {
    url: '/feed/collective/:collectiveId/update?last_update=:last_update_millis&back_to=:back_to_millis',
    params: {
      collectiveId: '',
      last_update_millis: '',
      back_to_millis: '',
    },
    method: 'GET'
  },
  getFeaturedMembers: {
    url: '/featured/members',
    method: 'GET'
  },
  getUsers: {
    url: '/users?name=:name&collective_id=:collectiveId&connected_user_id=:connectedUserId&in_network=:inNetwork&exclude=:exclude&limit=:limit&offset=:offset',
    params: {
      name: '',
      collectiveId: '',
      connectedUserId: '',
      inNetwork: '',
      exclude: '',
      limit: '',
      offset: ''
    },
    public: true,
    method: 'GET'
  },
  getProfile: {
    url: '/profile/:username',
    params: {
      username: ''
    },
    public: true,
    method: 'GET'
  },
  search: {
    url: '/search?q=:query&index=:index&limit=:limit&offset=:offset',
    params: {
      query: '',
      index: '',
      offset: 0,
      limit: ''
    },
    method: 'GET'
  },
  searchProfiles: {
    url: '/profile?q=:q&name=:name&industry=:industry&lat=:lat&lng=:lng&exclude=:exclude&offset=:offset&limit=:limit',
    params: {
      q: '',
      name: '',
      industry: '',
      lat: '',
      lng: '',
      exclude: '',
      offset: 0,
      limit: ''
    },
    method: 'GET'
  },
  locations: {
    url: '/locations?q=:query&sessiontoken=:session',
    params: {
      query: '',
      session: ''
    },
    method: 'GET'
  },
  selectLocation: {
    url: '/locations/:placeId?sessiontoken=:session',
    params: {
      placeId: '',
      session: ''
    },
    method: 'GET'
  },
  reactToPost: {
    url: '/post/:post_id/reaction',
    params: {
      post_id: ''
    },
    method: 'POST'
  },
  removeReactToPost: {
    url: '/post/:post_id/reaction',
    params: {
      post_id: ''
    },
    method: 'DELETE'
  },
  reactToComment: {
    url: '/comment/:comment_id/reaction',
    params: {
      comment_id: ''
    },
    method: 'POST'
  },
  removeReactToComment: {
    url: '/comment/:comment_id/reaction',
    params: {
      comment_id: ''
    },
    method: 'DELETE'
  },
  postComment: {
    url: '/comment',
    method: 'POST'
  },
  editComment: {
    url: '/comment/:id',
    params: {
      id: ''
    },
    method: 'PUT'
  },
  deleteComment: {
    url: '/comment/:id',
    params: {
      id: ''
    },
    method: 'DELETE'
  },
  createCollective: {
    url: '/collectives/:id',
    params: {
      id: ''
    },
    method: 'POST'
  },
  updateCollective: {
    url: '/collectives/:id',
    method: 'PUT',
    params: {
      id: ''
    }
  },
  getCollectives: {
    url: '/collectives?q=:query&recommended=:recommended&limit=:limit&offset=:offset',
    params: {
      query: '',
      recommended: '',
      limit: '',
      offset: ''
    },
    method: 'GET'
  },
  getCollective: {
    url: '/collectives/:slug',
    params: {
      slug: ''
    },
    public: true,
    method: 'GET'
  },
  getCollectiveMember: {
    url: '/collective-members?collective_id=:collectiveId&user_id=:userId&role=:role&limit=:limit&offset=:offset',
    params: {
      collectiveId: '',
      userId: '',
      role: '',
      limit: '',
      offset: ''
    },
    public: true,
    method: 'GET'
  },
  addCollectiveMember: {
    url: '/collective-members',
    method: 'POST'
  },
  removeCollectiveMember: {
    url: '/collective-members/:id',
    params: {
      id: ''
    },
    method: 'DELETE'
  },
  sendInvites: {
    url: '/invites',
    method: 'POST'
  },
  getInvite: {
    url: '/invites/:token',
    method: 'GET',
    params: {
      token: ''
    },
    public: true
  },
  getInvites: {
    url: '/invites?collective_id=:collective_id&limit=:limit&offset=:offset&status=:status',
    method: 'GET',
    params: {
      collective_id: '',
      limit: '',
      offset: '',
      status: ''
    }
  },
  acceptInvite: {
    url: '/invites/:token',
    method: 'PUT',
    params: {
      token: ''
    }
  },
  verifySignUpToken: {
    url: '/magic/verify',
    method: 'POST'
  },
  waitlist: {
    url: '/cm/waitlist',
    method: 'POST'
  },
  waitlistReferral: {
    url: '/cm/referral',
    method: 'POST'
  },
  cmStats: {
    url: '/cm/details',
    method: 'GET'
  },
  cmDetails: {
    url: '/cm/details',
    method: 'GET'
  },
  updateUserPing: {
    url: '/ping',
    method: 'POST'
  },
  addPersonalityCard: {
    url: '/personalitycard',
    method: 'POST'
  },
  editPersonalityCard: {
    url: '/personalitycard/:id',
    params: {
      id: ''
    },
    method: 'PUT'
  },
  deletePersonalityCard: {
    url: '/personalitycard/:id',
    params: {
      id: ''
    },
    method: 'DELETE'
  },
  addJourneyCard: {
    url: '/journeycard',
    method: 'POST'
  },
  editJourneyCard: {
    url: '/journeycard/:id',
    params: {
      id: ''
    },
    method: 'PUT'
  },
  deleteJourneyCard: {
    url: '/journeycard/:id',
    params: {
      id: ''
    },
    method: 'DELETE'
  },
  getActivity: {
    url: '/activities?user_id=:userId&limit=:limit&offset=:offset',
    params: {
      userId: '',
      limit: '',
      offset: ''
    },
    method: 'GET'
  },
  getAdmires: {
    url: '/admires?user_id=:userId&target_user_id=:targetUserId&limit=:limit&offset=:offset&name=:name',
    params: {
      userId: '',
      targetUserId: '',
      limit: '',
      offset: '',
      name: '',
    },
    method: 'GET'
  },
  addAdmire: {
    url: '/admires',
    param: {},
    method: 'POST'
  },
  removeAdmire: {
    url: '/admires/:id',
    params: {
      id: ''
    },
    method: 'DELETE'
  },
  getContactRequest: {
    url: '/contactrequest?contact_id=:id',
    params: {
      id: ''
    },
    method: 'GET'
  },
  sendContactRequest: {
    url: '/contactrequest/send',
    params: {
      id: ''
    },
    method: 'POST'
  },
  acceptContactRequest: {
    url: '/contactrequest/accept',
    params: {
      id: ''
    },
    method: 'POST'
  },
  ignoreContactRequest: {
    url: '/contactrequest/ignore',
    params: {
      id: ''
    },
    method: 'POST'
  },
  getContacts: {
    url: '/contacts',
    params: {
      id: ''
    },
    method: 'POST'
  },
  createMessagingSession: {
    url: '/messaging/sessions',
    method: 'POST'
  }
};

export default APIConfig;
