import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import './UserList.scss';
import UserListItem from './UserListItem';
import Loader from '../../common/Loader';
import InfiniteScroller from '../InfiniteScroller';

import { modalOpen, modalClose } from '../../store/Modal/actions';
import {
  sendContactRequest
} from '../../store/Contact/actions';
import { admireAdd } from '../../store/Admires/actions';

import {
  CONTACT_REQUEST_MODAL
} from '../../containers/ModalManager/ModalTypes';

class UserList extends Component {
  static defaultProps = {
    hasLoadedAllUsers: true,
    loadMore: () => {},
    clear: () => {}
  }

  static propTypes = {
    request: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string
  }

  constructor (props) {
    super(props);
  }

  setListRef = ref => {
    if (this.props.listRef) {
      this.listRef = this.props.listRef;
    } else {
      this.listRef = ref;
    }

    this.forceUpdate();
  }

  componentDidUpdate = prevProps => {
    const { listRef } = this.props;

    if (listRef !== prevProps.listRef) {
      this.listRef = listRef;
      this.forceUpdate();
    }
  }

  sendContactRequest = user => {
    const { sendContactRequest } = this.props;
    this.props.modalOpen({
      modal: CONTACT_REQUEST_MODAL,
      user,
      send:sendContactRequest
    });
  }

  requestSent = id => {
    if(this.props.contactRequestsIds.indexOf(id) !== -1) {
      return true;
    } 
    return false;
  };

  contactURL = id => {
    const { contacts } = this.props;
    let contactURL = false;

    contacts.map(contact => {
      if(contact.contact_id == id) {
        // contactURL = `/messaging/${contact.sendbird_group_id}`;
        contactURL = 'x';
      }
    });
    return contactURL;
  };

  render () {
    const {
      addedUsers,
      adminIds,
      admireAdd,
      admiresMap,
      canSearch,
      className,
      hasLoadedAllUsers,
      isLoggedIn,
      loadMore,
      modalClose,
      onClickAdd,
      onUserClick,
      request = {},
      title,
      user,
      users,
    } = this.props;

    if (!users.length && request.pending && !canSearch) {
      return <div className={classNames("UserList", className)}>
        <Loader />
      </div>
    }

    if (!users.length && !canSearch) return null;

    return (
      <div className={classNames("UserList", className)}>
        { title && (<h4 className="UserList__title">{title}</h4>) }
        <div className="UserList__container" ref={this.setListRef}>
          <InfiniteScroller
            shouldLoadMore={!request.pending && !hasLoadedAllUsers}
            hasLoadedAll={hasLoadedAllUsers}
            loadMore={loadMore}
            componentRef={this.listRef}
          >
            {
              users.map(user => ( 
                <UserListItem
                  key={user.id}
                  isCurrentUser={user.id == this.props.user.id}
                  currentUser={this.props.user}
                  user={user}
                  sendContactRequest={this.sendContactRequest}
                  contactURL={this.contactURL(user.id)}
                  contactRequestSent={this.requestSent(user.id)}
                  modalClose={modalClose}
                  admireAdd={admireAdd}
                  admiresMap={admiresMap}
                  showAdminBadge={adminIds ? adminIds.indexOf(user.id) !== -1 : null}
                  onClickAdd={onClickAdd}
                  onUserClick={onUserClick}
                  addedUsers={addedUsers}
                  isLoggedIn={isLoggedIn}
                />
              ))
            }

            {request.pending && !hasLoadedAllUsers ? <span>loading...</span> : null}
          </InfiniteScroller>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ App, User, Contact, Admires }) => ({
  isLoggedIn: App.isLoggedIn,
  user: User.user,
  contactRequestsIds: App.isLoggedIn ? User.user.contact_requests.map(contactRequest => contactRequest.contact_id) : [],
  contacts: Contact.contacts,
  admiresMap: Admires.admiresMap
});

export default connect(mapStateToProps, {
  modalOpen,
  modalClose,
  sendContactRequest,
  admireAdd
})(UserList);