import React, { PureComponent } from 'react';
import classNames from 'classnames';

import './Loader.scss';

class Loader extends PureComponent {
  render() {
    const { className } = this.props;

    return (
      <div className="Loader">
        <div className={classNames(
          "Loader__bounce Loader__bounce--1",
          className
        )} />
        <div className={classNames(
          "Loader__bounce Loader__bounce--2",
          className
        )} />
        <div className={classNames(
          "Loader__bounce",
          className
        )} />
      </div>
    )
  }
}

export default Loader;
