import { getPosts, getPostsWithUserEntity } from '../Post/actions';
import { getProfileEntitiesWithUser } from '../ProfileEntities/actions';

/**
  Get Feed
**/
export const FEED_GET = 'FEED_GET';
export const FEED_GET_LOADING = 'FEED_GET_LOADING';
export const FEED_GET_SUCCESS = 'FEED_GET_SUCCESS';
export const FEED_GET_ERROR = 'FEED_GET_ERROR';
export const FEED_GET_COMPLETE = 'FEED_GET_COMPLETE';
export const INIT_FEED = 'INIT_FEED';

export const feedGet = payload => {
  let data = {};
  if (payload) {
    data = {...payload};
  };

  return {
    type: FEED_GET,
    ...data
  }
};

export const feedGetLoading = () => {
  return {
    type: FEED_GET_LOADING
  }
};

export const feedGetSuccess = (payload, totalPostCount, limitFeed) => {
  return {
    type: FEED_GET_SUCCESS,
    payload,
    totalPostCount,
    limitFeed
  }
};

export const feedGetError = error => {
  return {
    type: FEED_GET_ERROR,
    error
  }
};

export const feedGetComplete = () => {
  return {
    type: FEED_GET_COMPLETE
  }
};

export const initFeed = () => {
  return {
    type: INIT_FEED
  }
}

/**
  Update Feed
**/
export const FEED_UPDATE = 'FEED_UPDATE';
export const FEED_UPDATE_NEW_POSTS = 'FEED_UPDATE_NEW_POSTS';
export const FEED_UPDATE_BLOCKED_POSTS = 'FEED_UPDATE_BLOCKED_POSTS';
export const ON_UPDATE_FEED = 'ON_UPDATE_FEED';
export const SET_LAST_UPDATE = 'SET_LAST_UPDATE';

export const feedUpdate = payload => {
  return {
    type: FEED_UPDATE,
    collectiveId: payload ? payload.collectiveId : null
  }
}

export const feedUpdateNewPosts = payload => {
  const newFeedEntities = payload.map(feedItem => ( feedItem.id ));
  return {
    type: FEED_UPDATE_NEW_POSTS,
    newFeedEntities,
    newPostContent: payload
  }
}

export const feedUpdateBlockedPosts = payload => {
  return {
    type: FEED_UPDATE_BLOCKED_POSTS,
    blocked_ids: payload
  }
}

export const onUpdateFeed = () => {
  return {
    type: ON_UPDATE_FEED
  }
}

export const setLastUpdateTime = () => {
  return {
    type: SET_LAST_UPDATE
  }
}

export const GET_CONTENT_TAGS = 'GET_CONTENT_TAGS';
export const SELECT_CONTENT_TAG = 'SELECT_CONTENT_TAG';
export const SELECT_CONTENT_TAG_SUCCESS = 'SELECT_CONTENT_TAG_SUCCESS';
export const CLEAR_CONTENT_TAGS_REDUCER = 'CLEAR_CONTENT_TAGS_REDUCER';
export const RESET_FEED_OFFSET = 'RESET_FEED_OFFSET';
export const RESET_FEED_ENTITIES = 'RESET_FEED_ENTITIES';

/**
  Content Tagging on Feed
**/
export const getContentTags = () => {
  let preselectedTags = JSON.parse(localStorage.getItem('content-tags'));

  if(preselectedTags) {
    return {
      type: GET_CONTENT_TAGS,
      payload: preselectedTags
    };
  } else {
    return {
      type: GET_CONTENT_TAGS,
      payload: []
    };
  };
};

export const onSelectingTag = payload => {
  return {
    type: SELECT_CONTENT_TAG,
    payload 
  }
};

export const clearContentTagsReducer = () => {
  localStorage.removeItem('content-tags');
  
  return {
    type: CLEAR_CONTENT_TAGS_REDUCER
  }
}

export const onSelectingTagSuccess = payload => {
  return {
    type: SELECT_CONTENT_TAG_SUCCESS,
    payload
  };
};

export const resetFeedOffset = () => {
  return {
    type: RESET_FEED_OFFSET
  };
};

export const resetFeedEntities = () => {
  return {
    type: RESET_FEED_ENTITIES
  };
};

/**
  Featured members list
**/
export const FEED_FEATURED_MEMBERS_GET ='FEED_FEATURED_MEMBERS_GET';
export const FEED_FEATURED_MEMBERS_GET_SUCCESS = 'FEED_FEATURED_MEMBERS_GET_SUCCESS';
export const FEED_FEATURED_MEMBERS_GET_ERROR = 'FEED_FEATURED_MEMBERS_GET_ERROR';

export const feedFeaturedMembersGet = () => {
  return {
    type: FEED_FEATURED_MEMBERS_GET
  }
};

export const feedFeaturedMembersGetSuccess = payload => {
  return {
    type: FEED_FEATURED_MEMBERS_GET_SUCCESS,
    payload
  }
};

export const feedFeaturedMembersGetError = payload => {
  return {
    type: FEED_FEATURED_MEMBERS_GET_ERROR,
    payload
  }
};

// selectors
export const getFeedWithEntities = state => {
  const posts = getPostsWithUserEntity(state);
  return state.Feed.entities.map(feedId => posts[feedId]);
}

export const getFeaturedMembersWithEntities = state => {
  const profiles = getProfileEntitiesWithUser(state);
  return state.Feed.featured_members_entities.map(featuredMemberId => {
    return profiles[featuredMemberId];
  });
}

export const feedGetLimit = state => state.Feed.limit;
export const feedGetOffset = state => state.Feed.offset;
