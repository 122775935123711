import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectRequest } from '../../../../store/requests';
import classNames from 'classnames';

import { createCollective, updateCollective } from '../../../../store/Collectives/actions';
import { modalClose } from '../../../../store/Modal/actions';
import { isEqual } from 'lodash';

import { WizardActions } from '../../../../components/Wizard';
import { ModalHeader, ModalBody, ModalFooter } from '../../ModalContainer';
import InputWithLabel from '../../../../common/Input/InputWithLabel';
import { INPUT_SECONDARY } from '../../../../common/Input';
import ImageUploader from '../../../../components/ImageUploader';

import '../../../../common/Input/Input.scss';

class CollectiveCreationIntoForm extends Component {
  state = {
    collectiveName: this.props.collectiveCreation.name || '',
    collectiveDescription: this.props.collectiveCreation.description || '',
    collectiveImage: this.props.collectiveCreation.image || ''
  }

  onCollectiveNameChange = val => {
    this.setState({
      collectiveName: val
    })
  }

  onCollectiveDescChange = val => {
    this.setState({
      collectiveDescription: val
    })
  }

  onNext = () => {
    const { collectiveCreation } = this.props;
    let isCreated = false;
    if (collectiveCreation) {
      isCreated = !!this.props.collectives[collectiveCreation.slug];
    }
    let editing = false;
    if (this.props.stepProps && this.props.stepProps.editMode) editing = true;

    let changed = false;
    changed = !isEqual(this.state.collectiveName, collectiveCreation.name) || changed;
    changed = !isEqual(this.state.collectiveDescription, collectiveCreation.description) || changed;
    changed = !isEqual(this.state.collectiveImage, collectiveCreation.image) || changed;

    if (isCreated || editing) {
      if (changed) {
        this.props.updateCollective({
          name: this.state.collectiveName,
          description: this.state.collectiveDescription,
          image: this.state.collectiveImage
        }, this.props.history, this.props.wizard.next);
      } else {
        if (editing) {
          this.props.modalClose();
        } else {
          this.props.wizard.next();
        }
      }
    } else {
      this.props.createCollective({
        name: this.state.collectiveName,
        description: this.state.collectiveDescription,
        image: this.state.collectiveImage
      }, this.props.history, this.props.wizard.next);
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.collectiveCreation.image != this.props.collectiveCreation.image) {
      this.setState({
        collectiveImage: this.props.collectiveCreation.image
      })
    }
  }

  onUpload = data => {
    this.setState({
      collectiveImage: data
    })
  }

  render() {
    const { collectiveName, collectiveDescription, collectiveImage } = this.state;
    const { createRequest, updateRequest, collectiveCreation } = this.props;
    const { errors } = this.props.collectiveCreation;

    let editing = false;
    if (this.props.stepProps && this.props.stepProps.editMode) {
      editing = true;
    }
    let isCreated = false;
    if (collectiveCreation) {
      isCreated = !!this.props.collectives[collectiveCreation.slug];
    }

    return (
      <div className="CollectiveCreationModal__container">
         <ModalHeader 
            header={editing ? `Edit ${collectiveName}` : 'Create a Collective'}
            subHeader={'Build your own Girlboss network.'}
          />
          <ModalBody>
            <div className="CollectiveCreationModal__input">
              <InputWithLabel 
                description="Keep it broad or niche, but make it clear."
                disabled={editing}
                errors={errors.name ? [errors['name']] : null}
                info={!editing ? `${collectiveName.length}/50`: null}
                label="Collective name"
                minLength={2}
                maxLength={50}
                onChange={this.onCollectiveNameChange}
                placeholder="Best Collective ever"
                required
                value={collectiveName}
                theme={INPUT_SECONDARY}
              />
            </div>
            <div className="CollectiveCreationModal__input">
              <InputWithLabel 
                component="textarea"
                description="Keep it broad or niche, but make it clear."
                errors={errors.description ? [errors['description']] : null}
                info={`${collectiveDescription.length}/500`}
                label="Tell us more"
                minLength={10}
                maxLength={500}
                minRows={3}
                maxRows={3}
                onChange={this.onCollectiveDescChange}
                placeholder="This group is for..."
                required
                value={collectiveDescription}
                theme={INPUT_SECONDARY}
              />
            </div> 

            <label className="Input__label Input__label--required">Make it pretty</label>
            <p className="Input__description">Share a design or image that represents what your group is about.</p>

            <ImageUploader 
              className={classNames("CollectiveCreationModal__container--uploader", {'editing': !!this.state.collectiveImage})}
              imageName="image"
              imageWidth={1200}
              imageHeight={1200}
              request={{}}
              image={this.props.collectiveCreation.image || ''}
              onSelect={this.onUpload}
              uploadText={!!this.state.collectiveImage ? 'Edit Image' : 'Upload Image'}
            />
          </ModalBody>

          <ModalFooter>
            <WizardActions 
              onNext={this.onNext} 
              nextDisabled={!collectiveName || !collectiveDescription || !collectiveImage}
              nextLabel={editing ? 'Looks good!' : null}
              wizard={this.props.wizard} 
              nextPending={(editing || isCreated) ? updateRequest.pending : createRequest.pending}
            />
          </ModalFooter>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    createRequest: selectRequest(state, createCollective),
    updateRequest: selectRequest(state, updateCollective),
    collectives: state.Collectives.collectives,
    collectiveCreation: state.Collectives.collectiveCreation
  }
}

export default connect(mapStateToProps, {
  createCollective,
  updateCollective,
  modalClose
})(withRouter(CollectiveCreationIntoForm));