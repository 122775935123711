import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import routes, { girlbossComLinks, socialLinks } from '../../config/routes';

import SocialItem from './SocialItem';

import './Footer.scss';

import GirlbossLogoG from '../../../public/assets/GirlbossLogo_GMark_Black.png';

import FacebookLogo from '../../../public/assets/social/facebook.svg';
import InstagramLogo from '../../../public/assets/social/instagram.svg';
import LinkedInLogo from '../../../public/assets/social/linkedin.svg';
import PinterestLogo from '../../../public/assets/social/pinterest.svg';
import TwitterLogo from '../../../public/assets/social/twitter.svg';

const footerItems = [
  { link: girlbossComLinks.about, text: 'About Us' },
  { link: girlbossComLinks.communityGuidelines, text: 'Community Guidelines' },
  { link: girlbossComLinks.terms, text: 'Terms & Privacy' },
  { link: girlbossComLinks.faq, text: "FAQs" },
  { link: girlbossComLinks.home, text: 'Girlboss.com' },
  { link: girlbossComLinks.careers, text: "We're Hiring!" }
];

const socialItems = [
  { link: socialLinks.instagram, svg: InstagramLogo, altText: 'Girlboss Instagram' },
  { link: socialLinks.linkedin, svg: LinkedInLogo, altText: 'Girlboss LinkedIn' },
  { link: socialLinks.twitter, svg: TwitterLogo, altText: 'Girlboss Twitter' },
  { link: socialLinks.facebook, svg: FacebookLogo, altText: 'Girlboss Facebook' },
  { link: socialLinks.pinterest, svg: PinterestLogo, altText: 'Girlboss Pinterest' }
];

class Footer extends Component {
  render() {
    return (
      <div className="Footer__parent">
        <div className="Footer">
          <div className="Footer__left">
            <Link to={routes.root}>
              <img
                src={GirlbossLogoG}
                alt="Girlboss community"
                className="Footer__image"
              />
            </Link>
            <span className="Footer__copy">© Girlboss {new Date().getFullYear()}</span>
          </div>
          <div className="Footer__contents">
            {footerItems.map(footerItem => {
              const { link, text } = footerItem;
              if (footerItem.isInternal) {
                return (
                  <Link to={link} key={text} className="Footer__section__link">
                    {text}
                  </Link>
                );
              }
              return (
                <a
                  href={link}
                  key={text}
                  className="Footer__section__link"
                >
                  {text}
                </a>
              );
            })}
          </div>
          <div className="Footer__socials">
            {
              socialItems.map(socialItem => (
                <SocialItem 
                  altText={socialItem.altText} 
                  icon={socialItem.svg} 
                  key={socialItem.altText} 
                  link={socialItem.link}
                  />
              ))
            }
          </div>
        </div>
      </div> 
    )
  }
}

export default Footer;