import { takeEvery, put, call, select } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  feedGet,
  FEED_GET,
  feedGetLoading,
  feedGetSuccess,
  feedGetError,
  feedGetComplete,
  feedGetLimit,
  feedGetOffset,
  FEED_FEATURED_MEMBERS_GET,
  feedFeaturedMembersGetSuccess,
  feedFeaturedMembersGetError,
  FEED_UPDATE,
  feedUpdate,
  feedUpdateNewPosts,
  setLastUpdateTime,
  feedUpdateBlockedPosts,
  SELECT_CONTENT_TAG,
  onSelectingTagSuccess,
  resetFeedOffset,
  resetFeedEntities
} from './actions';

import { tags } from '../../components/ContentTags';
import { isEqual } from 'lodash';

const LIMIT_FEED_COUNT = 0;

function* feedSagaGet(action) {
  yield put(feedGetLoading());
  const state = yield select();

  const limit = action.limitFeed ? LIMIT_FEED_COUNT : yield select(feedGetLimit);
  const offset = yield select(feedGetOffset);
  const topics = JSON.stringify(state.Feed.contentTags);

  let response;
  if(action.collectiveId) {
    response = yield call(fetchAPI, 'getCollectivesFeed', {
      collectiveId: action.collectiveId,
      limit,
      offset
    });
  } else {
    response = yield call(fetchAPI, 'getFeed', {
      limit,
      offset,
      topics
    });
  }

  if (response.success) {
    yield put(feedGetSuccess(response.feed, response.total_post_count, action.limitFeed));
    
  } else {
    yield put(feedGetError(response));
  }
  yield put(setLastUpdateTime());
  yield put(feedGetComplete());
};

function* feedSagaUpdate(action) {
  const state = yield select();

  const i = state.Feed.entities[state.Feed.entities.length - 1];
  const last_update_millis = state.Feed.last_updated;
  const back_to_millis = i ? Date.parse(state.Post.posts[i].created_at) : 0;
  const topics = JSON.stringify(state.Feed.contentTags);

  if (!last_update_millis) return;

  let response;
  if(action.collectiveId) {
    response = yield call(fetchAPI, 'updateCollectivesFeed', {
      collectiveId: action.collectiveId,
      last_update_millis,
      back_to_millis,
    });
  } else {
    response = yield call(fetchAPI, 'updateFeed', {
      last_update_millis,
      back_to_millis,
      topics
    });
  }

  if(response.success) {
    if(response.new_posts.count !== 0) {
      if(!isEqual(response.new_posts.rows, state.Post.newPostContent)) {
        yield put(feedUpdateNewPosts(response.new_posts.rows));
      }
    }
    if(response.blocked_posts.length > 0) {
      if(!isEqual(response.blocked_posts, state.Feed.blockedPostsEntities)) {
        yield put(feedUpdateBlockedPosts(response.blocked_posts));
      }
    }
  }
};

function* feedSagaGetWithTags(action) {
  yield put(feedGetLoading());

  const state = yield select();
  let contentTags = state.Feed.contentTags;

  const index = contentTags.indexOf(action.payload);
  if(action.payload === 'all') {
    contentTags = [];
  } else if(index > -1) {
    contentTags.splice(index, 1);
  } else {
    contentTags.push(action.payload);
  };

  // if users manually select all the tags,
  // automatically deselect the tags and select 'All'
  if(contentTags.length === Object.keys(tags).length - 1) {
    contentTags = [];
  };

  localStorage.setItem('content-tags', JSON.stringify(contentTags));

  yield put(resetFeedOffset());
  yield put(resetFeedEntities());
  yield put(onSelectingTagSuccess(contentTags));
  yield put(feedGet());
};

function* feedSagaGetFeaturedMembers(action) {
  const response = yield call(fetchAPI, 'getFeaturedMembers');

  if (response.success) {
    yield put(feedFeaturedMembersGetSuccess(response.featured_members));
  } else {
    yield put(feedFeaturedMembersGetError(response));
  }
};

export const feedSagas = [
  takeEvery(FEED_GET, feedSagaGet),
  takeEvery(FEED_UPDATE, feedSagaUpdate),
  takeEvery(FEED_FEATURED_MEMBERS_GET, feedSagaGetFeaturedMembers),
  takeEvery(SELECT_CONTENT_TAG, feedSagaGetWithTags)
];
