import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import '../SettingsBlock.scss';

class SettingsItem extends Component {
  static propTypes = {
    left: PropTypes.node,
    right: PropTypes.node,
    required: PropTypes.bool
  };

  static defaultProps = {
    required: false
  };

  render() {
    const {
      left,
      right,
      required
    } = this.props;

    return (
      <span className="SettingsItem">
        <p className={classNames({ 'required': required })}>
          {left}
        </p>
        <span>{right}</span>
      </span>
    )
  }
}

export default SettingsItem;
