import React, { Component } from 'react'
import PropTypes from 'prop-types';

export const SEND_THEME_BLACK = '#1E1E1E';
export const SEND_THEME_GREY = '#F3F3F3';
export const SEND_THEME_BLUE ='#4E5DD6';
export const SEND_THEME_BLUE_DISABLED ='#E9EBFF';

export class SendIcon extends Component {
    static propTypes = {
        theme: PropTypes.oneOf([
            SEND_THEME_BLACK, 
            SEND_THEME_GREY, 
            SEND_THEME_BLUE, 
            SEND_THEME_BLUE_DISABLED
        ])
      };
    
      static defaultProps = {
        theme: SEND_THEME_BLACK
      };

    render() {

        return (
            <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>send</title>
                <g id="Emails_Send-Email_send-email-2" data-name="Emails / Send-Email / send-email-2">
                <g id="Group_2" data-name="Group 2">
                <g id="send-email-2">
                <path fill={this.props.theme} id="Shape_3" data-name="Shape 3" d="M6.2,11.56,1.15,2.64a1.5,1.5,0,0,1,2-2.07l19,9.66a.74.74,0,0,1,0,1.33"/>
                <path fill={this.props.theme} id="Shape_3-2" data-name="Shape 3" d="M6.2,12.44,1.15,21.36a1.5,1.5,0,0,0,2,2.07l19-9.66a.74.74,0,0,0,0-1.33"/>
                </g>
                </g>
                </g>
            </svg>
        )
    }
}

export default SendIcon;
