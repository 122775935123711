import {
  REACT_POST,
  REACT_POST_REMOVE,
  REACT_COMMENT,
  REACT_COMMENT_REMOVE,
  REACT_REPLY,
  REACT_REPLY_REMOVE
} from './actions';

const initialState = {};

function reactionsReducer(state = initialState, action) {
  switch (action.type) {
    case REACT_POST:
    case REACT_POST_REMOVE:
    case REACT_COMMENT:
    case REACT_COMMENT_REMOVE:
    case REACT_REPLY:
    case REACT_REPLY_REMOVE:
      return { ...state, isLoading: true };
    default:
      return {
        ...state,
        isLoading: false
      };
  }
}

export default reactionsReducer;
