import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const BOTTOM_THRESHOLD = 200;

class InfiniteScroller extends Component {
  static propTypes = {
    loadMore: PropTypes.func.isRequired,
    shouldLoadMore: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    hasLoadedAll: PropTypes.bool,
    className: PropTypes.string,
    threshold: PropTypes.number,
    onClick: PropTypes.func
  };

  componentDidMount() {
    this.scrollContainer = this.props.componentRef || window;
    this.addScrollListener();
  }

  componentDidUpdate(prevProps) {
    const { componentRef } = this.props;

    if (componentRef !== this.scrollContainer) {
      this.removeScrollListener();
      this.scrollContainer = componentRef || window;
      this.addScrollListener();
    }
  }

  componentWillUnmount() {
    this.removeScrollListener();
  }

  addScrollListener() {
    this.scrollContainer.addEventListener('scroll', this.handleInfiniteScroll);
    this.scrollContainer.addEventListener('mousewheel', this.handleInfiniteScroll);
  }

  removeScrollListener() {
    this.scrollContainer.removeEventListener('scroll', this.handleInfiniteScroll);
    this.scrollContainer.removeEventListener('mousewheel', this.handleInfiniteScroll);
  }

  handleInfiniteScroll = () => {
    const { componentRef } = this.props;
    let scrollTop, scrollHeight, clientHeight;

    if(this.props.componentRef) {
      scrollTop = componentRef.scrollTop;
      scrollHeight = componentRef.scrollHeight;
      clientHeight = componentRef.clientHeight;
    } else {
      scrollTop = window.pageYOffset || document.body.scrollTop;
      scrollHeight = document.body.scrollHeight;
      clientHeight = window.innerHeight;
    };

    if (!this.props.shouldLoadMore) return;

    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) + (this.props.threshold ? this.props.threshold : BOTTOM_THRESHOLD) >= scrollHeight;
 
    if (scrolledToBottom) {
      this.props.loadMore();
    }
  }

  render() {
    return (
      <div className={this.props.className} onClick={this.props.onClick}>
        {this.props.children}
        {this.props.isLoading && <div>Loading...</div>}
        {/* {this.props.hasLoadedAll && <div>You've seen everything!</div>} */}
      </div>
    )
  }
}

export default InfiniteScroller;
