import pick from 'lodash/pick';
import { takeEvery, put, call, take, fork, cancel, select } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';

import {
  collectiveGet,
  COLLECTIVES_GET,
  COLLECTIVE_GET,
  CREATE_COLLECTIVE,
  UPDATE_COLLECTIVE,
  PUBLISH_COLLECTIVE,
  updateCollectiveData,
  collectiveMemberGet,
  clearCurrentMembersList,
  COLLECTIVE_MEMBER_GET,
  COLLECTIVE_MEMBER_ADD,
  COLLECTIVE_MEMBER_REMOVE
} from './actions';
import {
  userGetAll
} from '../User/actions';
import {
  modalOpen,
  modalClose
} from '../Modal/actions';
import {
  toasterOpen,
  TOASTER_TYPE_SUCCESS,
  TOASTER_TYPE_ERROR
} from '../Toaster/actions';
import {
  post
} from '../Post/actions';
import { inviteAccept } from '../Invites/actions';

function* collectivesSagaGet(action) {
  const query = { limit: 12, offset: 0, ...action.payload };
  yield sagaRequest(action, callAPI('getCollectives', query));
};

function* collectiveSagaGet(action) {
  yield sagaRequest(action, callAPI('getCollective', { slug: action.payload }), {
    *fulfilled(response) {
      yield put(clearCurrentMembersList());
      yield put(collectiveMemberGet({
        slug: response.data.slug,
        collectiveId: response.data.id,
        limit: 10,
        offset: 0
      }));
    }
  });
};

function* createCollectiveSaga(action) {
  const { name, description, image } = action.payload[0];
  let responseData = {};

  yield sagaRequest(action, function*() {
    const fieldResponse = yield callAPI('createCollective', {
      body: {name, description}
    });

    const imageResponse = yield callAPI('updateCollective', {
      id: fieldResponse.data.id,
      body: image,
    }, true);

    if (fieldResponse.success && imageResponse.success) {
      responseData = {...imageResponse.data}
    }

    return responseData;
  }, {
    *fulfilled(response) {
      yield put(updateCollectiveData({
        id: response.id,
        slug: response.slug,
        name: response.name,
        description: response.description,
        image: response.image.medium
      }))

      if (window.location.pathname != `/groups/${response.slug}`) {
        action.payload[1].push(`/groups/${response.slug}`);
      }

      action.payload[2]();
    },
    *rejected(response) {
      if (response.response.status != 422) {
        yield put(toasterOpen({
          type: TOASTER_TYPE_ERROR, 
          content: 'Something went wrong',
          timeout: 4000
        }));
      }
    }
  })
}

function* updateCollectiveSaga(action) {
  const { name, description, image } = action.payload[0];
  const { Collectives } = yield select();
  const { id, slug } = Collectives.collectiveCreation;

  let fieldsChanged = name != Collectives.collectiveCreation.name || description != Collectives.collectiveCreation.description;
  let imagedChanged = image != Collectives.collectiveCreation.image;
  let collectivePublished = !!Collectives.collectives[slug].published_at;

  let body = {
    name,
    description
  };
  if (collectivePublished) {
    body = {
      description
    }
  };

  yield sagaRequest(action, function*() {
    let responseData = {};

    if (fieldsChanged) {
      const fieldsResponse = yield callAPI('updateCollective', {
        body: {...body},
        id
      });
      if (fieldsResponse.success) {
        responseData = {
          data: {...fieldsResponse.data}
        }
      }
    }
    if (imagedChanged) {
      const imageResponse = yield callAPI('updateCollective', {
        id,
        body: image
      }, true);
  
      if (imageResponse.success) {
        responseData = {
          data: {...imageResponse.data}
        };
      }
    }

    return responseData;
  }, {
    *fulfilled(response) {
      if (collectivePublished) {
        yield put(modalClose());
        yield put(toasterOpen({
          type: TOASTER_TYPE_SUCCESS, 
          content: 'Collective successfully updated',
          timeout: 4000
        }));
      } else {
        yield put(updateCollectiveData({
          slug: response.data.slug,
          name: response.data.name,
          description: response.data.description,
          image: response.data.image.medium
        }))

        action.payload[1].replace(`/groups/${response.data.slug}`);
        action.payload[2]();
      }
    },
    *rejected(response) {
      if (response.response.status != 422) {
        yield put(toasterOpen({
          type: TOASTER_TYPE_ERROR, 
          content: 'Something went wrong',
          timeout: 4000
        }));
      }
    }
  })
}

function* publishCollectiveSaga(action) {
  const { Collectives } = yield select();
  const { id, name, description, slug, title, body, topics } = Collectives.collectiveCreation;

  yield sagaRequest(action, callAPI('updateCollective', { 
    body: {
      name,
      description,
      published: true
    },
    id
   }), {
    *fulfilled(response) {
      yield put(post({
        body: {
          title,
          body,
          topics,
          collective_id: id,
        },
        updatePosts: true
      }));
      yield put(collectiveGet(slug));
      action.payload.next();
    }
  });
}

function* collectiveMemberSagaGet(action) {
  const { Collectives } = yield select();
  const { slug, ...payload } = action.payload[0];
  const collective = Collectives.collectives[slug];
  const query = { offset: (collective && collective.offset) || 0, limit: 10, ...payload };

  yield sagaRequest(action, function*() {
    const response = yield callAPI('getCollectiveMember', query);
    const members = response.data;

    const { App } = yield select();

    return { userId: payload.userId, slug, members, total: response.total, shouldShuffle: action.payload[1], isLoggedIn: App.isLoggedIn};
  });
}

function* collectiveMemberSagaAdd(action) {
  const user = yield select(userGetAll);
  const { collective_id, user_id = user.id, role = 'subscriber', invite_token } = action.payload;

  yield sagaRequest(action, function*() {
    const response = yield callAPI('addCollectiveMember', {
      body: {
        collective_id,
        user_id,
        role
      }
    });

    return {
      member:response.data,
      id:action.payload.collective_id, 
      list: action.payload.list || null,
      user: pick(user, ['id', 'username', 'first_name', 'last_name', 'headline', 'industry', 'location', 'photo'])
    }
  }, {
    *fulfilled ()  {
      if (invite_token) {
        yield put(inviteAccept(invite_token));
      }
    }
  });
}

function* collectiveMemberSagaRemove(action) {
  yield sagaRequest(action, callAPI('removeCollectiveMember', {
    id: action.payload.memberId
  }));
}

export const collectiveSagas = [
  takeEvery(COLLECTIVES_GET, collectivesSagaGet),
  takeEvery(COLLECTIVE_GET, collectiveSagaGet),
  takeEvery(CREATE_COLLECTIVE, createCollectiveSaga),
  takeEvery(UPDATE_COLLECTIVE, updateCollectiveSaga),
  takeEvery(PUBLISH_COLLECTIVE, publishCollectiveSaga),
  takeEvery(COLLECTIVE_MEMBER_GET, collectiveMemberSagaGet),
  takeEvery(COLLECTIVE_MEMBER_ADD, collectiveMemberSagaAdd),
  takeEvery(COLLECTIVE_MEMBER_REMOVE, collectiveMemberSagaRemove)
];
