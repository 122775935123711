import {
  INVITEES_GET_FULFILLED,
  INVITE_GET_FULFILLED,
  INVITE_GET_REJECTED,
  INVITES_GET_FULFILLED,
  INVITE_ACCEPT_FULFILLED
} from './actions';
import {
  COLLECTIVE_MEMBER_ADD_FULFILLED
} from '../Collectives/actions';

const initialState = {
  defaultCandidates: {
    offset: 0,
    users: [],
    hasLoadedAllUsers: false
  },
  searchCandidates: {
    offset: 0,
    users: [],
    hasLoadedAllUsers: false
  },
  invites: []
};

const OFFSET_INCREASE = 12;

function invitesReducer(state = initialState, action) {
  switch (action.type) {
    case INVITEES_GET_FULFILLED: {
      const { name, offset } = action.request.initialPayload;
      let newInvitees;
      let newOffset;

      if (name != null) {
        newInvitees = [...state.searchCandidates.users];
        newOffset = state.searchCandidates.offset;

        // on initial data load 
        if (offset === 0) {
          newOffset = offset;
          newInvitees = [];
        };

        newInvitees = [...newInvitees, ...action.payload.data];
        newOffset += OFFSET_INCREASE;

        let hasLoadedAllUsers = false;
        if (newOffset >= action.payload.total) hasLoadedAllUsers = true;

        return {
          ...state,
          searchCandidates: {
            offset: newOffset,
            users: newInvitees,
            hasLoadedAllUsers
          }
        }
      } else {
        newInvitees = [...state.defaultCandidates.users];
        newOffset = state.defaultCandidates.offset;

        // on initial data load 
        if (offset === 0) {
          newOffset = offset;
          newInvitees = [];
        };

        newInvitees = [...newInvitees, ...action.payload.data];
        newOffset += OFFSET_INCREASE;

        let hasLoadedAllUsers = false;
        if (newOffset >= action.payload.total) hasLoadedAllUsers = true;

        return {
          ...state,
          defaultCandidates: {
            offset: newOffset,
            users: newInvitees,
            hasLoadedAllUsers
          }
        }
      }
    }
    case INVITE_GET_FULFILLED: {
      return {
        ...state,
        invites: [action.payload.data]
      }
    }
    case INVITE_GET_REJECTED: {
      return {
        ...state
      }
    }
    case INVITES_GET_FULFILLED: {
      return {
        ...state,
        invites: action.payload.data
      }
    }
    case INVITE_ACCEPT_FULFILLED: {
      return {
        ...state,
        invites: []
      }
    }
    case COLLECTIVE_MEMBER_ADD_FULFILLED: {
      return {
        ...state,
        invites: []
      }
    }
    default:
      return {
        ...state
      }
  }
}

export default invitesReducer;