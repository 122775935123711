import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './NavOptions.scss';

class NavOption extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired
  };

  handleOnClick = () => {
    this.props.onClick(this.props.index);
  }

  render() {
    return (
      <span
        className={classNames(
          this.props.className,
          'NavOption',
          {
            'NavOption__active': this.props.activeIndex === this.props.index
          }
        )}
        onClick={this.handleOnClick}
      >
        {this.props.title}
      </span>
    )
  }
}

export default NavOption;
