import { takeEvery, put, call } from 'redux-saga/effects';
import {
  COMMENT,
  commentSuccess,
  commentError,
  COMMENT_EDIT,
  commentEditSuccess,
  commentEditError,
  COMMENT_DELETE,
  commentDeleteSuccess,
  commentDeleteError,
  COMMENT_REACT,
  commentReactSuccess,
  commentReactError,
  COMMENT_REACT_DELETE,
  commentReactDeleteSuccess,
  commentReactDeleteError,
  REPLY,
  replySuccess,
  replyError,
  REPLY_EDIT,
  replyEditSuccess,
  replyEditError,
  REPLY_DELETE,
  replyDeleteSuccess
} from './actions';
import { toasterOpen, TOASTER_TYPE_ERROR } from '../Toaster/actions';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';
import fetchAPI from '../../services/APIService';
import {
  GTMpushDataLayerEvent,
  GTM_COMMENT_DELETE,
  GTM_COMMENT_EDIT,
  GTM_REPLY_DELETE,
  GTM_REPLY_EDIT
} from '../../lib/GoogleTagManager';
import { modalClose } from '../Modal/actions';

function* commentSaga(action) {
  yield sagaRequest(action, callAPI('postComment', { body: { body: action.payload.body, post_id: action.payload.postId } }), {
    *rejected(error) {
      yield put(toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: "Failed to post comment.",
        timeout:5000
      }));
    }
  });
};

function* commentSagaEdit(action) {
  yield sagaRequest(action, callAPI('editComment', { id: action.payload.commentId, body: { body: action.payload.body, id: action.payload.commentId } }), {
    *fulfilled(response) {
      GTMpushDataLayerEvent({ event: GTM_COMMENT_EDIT });
    },
    *rejected(error) {
      yield put(toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: "Failed to edit comment.",
        timeout:5000
      }));
    }
  });
};

function* commentSagaDelete(action) {
  yield sagaRequest(action, callAPI('deleteComment', { id: action.payload.commentId }), {
    *fulfilled(response) {
      yield put(commentDeleteSuccess());
      yield put(modalClose());
    },
    *rejected(error) {
      yield put(commentDeleteError());
    }
  });
};

function* replySaga(action) {
  yield sagaRequest(action, callAPI('postComment', { body: {
    body: action.payload.body, post_id: action.payload.postId, parent_id: action.payload.parentId
  } }), {
    *rejected(error) {
      yield put(toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: "Failed to post reply.",
        timeout:5000
      }));
    }
  });
};

function* replySagaEdit(action) {
  yield sagaRequest(action, callAPI('editComment', { id: action.payload.replyId, body: { body: action.payload.body, id: action.payload.replyId } }), {
    *fulfilled(response) {
      GTMpushDataLayerEvent({ event: GTM_REPLY_EDIT });
    },
    *rejected(error) {
      yield put(toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: "Failed to edit reply.",
        timeout:5000
      }));
    }
  });
}

function* replySagaDelete(action) {
  const { postId, parentId, commentId } = action;

  const response = yield call(fetchAPI, 'deleteComment', {
    body: action.payload,
    id: commentId
  });

  yield put(replyDeleteSuccess(postId, parentId, commentId));

  GTMpushDataLayerEvent({ event: GTM_REPLY_DELETE });
}

export const commentSagas = [
  takeEvery(COMMENT, commentSaga),
  takeEvery(COMMENT_EDIT, commentSagaEdit),
  takeEvery(COMMENT_DELETE, commentSagaDelete),
  takeEvery(REPLY, replySaga),
  takeEvery(REPLY_EDIT, replySagaEdit),
  takeEvery(REPLY_DELETE, replySagaDelete)
];
