import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { checkAuth } from '../../utils/checkAuth';
import {cloneDeep, isEqual} from 'lodash';

import {
  GTMpushDataLayerEvent,
  GTM_PROFILE_EDIT,
  GTM_PROFILE_LINK_COPIED
} from '../../lib/GoogleTagManager';

import {
  FIRST_NAME,
  LAST_NAME,
  HEADLINE,
  LOCATION,
  INDUSTRY,
  PROFILE_PHOTO,
  IM_GOOD_AT,
  ID_LIKE_TO_LEARN,
  ID_LIKE_TO_MEET,
  ID,
  BIO,
  INSTAGRAM,
  WEBSITE
} from '../../common/Input/CommonFields/profile';

import { modalOpen } from '../../store/Modal/actions';
import { 
  collectiveMemberGet,
  clearCollectivesOffset,
  clearUserCollectives
} from '../../store/Collectives/actions';
import {
  userUpdate,
  userPersonalityCardAdd,
  userPersonalityCardUpdate,
  userPersonalityCardDelete,
  userJourneyCardAdd,
  userJourneyCardUpdate,
  userJourneyCardDelete,
  userRemoveContactRequest
} from '../../store/User/actions';
import {
  sendContactRequest
} from '../../store/Contact/actions';
import {
  connectionsGet,
  connectionsClear
} from '../../store/Connections/actions';
import {
  activityGet,
  activityClear
} from '../../store/Activity/actions';
import {
  admireAdd,
  admireRemove,
  admirersGet,
  admirersClear
} from '../../store/Admires/actions';

import {
  DAILY_HOROSCOPE_MODAL,
  JOURNEY_CARD_VIEW_MORE_WORK_EXPERIENCE_MODAL,
  JOURNEY_CARD_VIEW_MORE_GIRLBOSS_MOMENT_MODAL,
  JOURNEY_CARD_VIEW_MORE_EDUCATION_MODAL,
  CONTACT_REQUEST_MODAL,
  URL_COPIED_MODAL,
  CONTACT_REQUEST_PENDING_MODAL,
  COLLECTIVES_LIST_MODAL,
  ACTIVITY_FEED_MODAL,
  NETWORK_MODAL
} from '../ModalManager/ModalTypes';

import { isName, isNameInLimits } from '../../common/Input/CommonFields/validations';

import Editing from './Editing';
import Viewing from './Viewing';

import './Profile.scss';

class Profile extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    user: PropTypes.shape({
      [ID]: PropTypes.number.isRequired,
      [FIRST_NAME]: PropTypes.string.isRequired,
      [LAST_NAME]: PropTypes.string.isRequired,
      [HEADLINE]: PropTypes.string,
      [LOCATION]: PropTypes.string,
      [INDUSTRY]: PropTypes.string,
      [INSTAGRAM]: PropTypes.string,
      [WEBSITE]: PropTypes.string,
      [BIO]: PropTypes.shape({
        [IM_GOOD_AT]: PropTypes.string,
        [ID_LIKE_TO_LEARN]: PropTypes.string,
        [ID_LIKE_TO_MEET]: PropTypes.string
      })
    }),
    isLoading: PropTypes.bool,
    userUpdate: PropTypes.func.isRequired,
    userPersonalityCardAdd: PropTypes.func.isRequired,
    userPersonalityCardUpdate: PropTypes.func.isRequired,
    userPersonalityCardDelete: PropTypes.func.isRequired,
    profileCardsUpdated: PropTypes.bool
  };

  static defaultProps = {
    canViewEditStates: false
  };

  constructor (props) {
    super(props);

    let user = {
      id:null,
      first_name:'',
      last_name:'',
      headline:'',
      industry:'',
      location:'',
      link_instagram:null,
      link_personal:null,
      bio:{},
      photo: {
        thumbnail:'',
        medium:'',
        large:''
      }
    };

    if (props.user) {
      user.id = props.user.id;
      user.first_name = props.user.first_name;
      user.last_name = props.user.last_name;
      user.headline = props.user.headline;
      user.location = props.user.location;
      user.industry = props.user.industry;
      user.bio = props.user.bio;
      user.link_instagram = props.user.link_instagram;
      user.link_personal = props.user.link_personal;
      user.photo = props.user[PROFILE_PHOTO];
      user.email = props.user.email;
    }

    this.state = {
      user:user,
      savedUser:cloneDeep(user),
      isEditing:false,
      zodiacCard:this.getCardByType('zodiac'),
      savedZodiacCard:this.getCardByType('zodiac'),
      myersBriggsCard:this.getCardByType('myers_briggs'),
      savedMyersBriggsCard:this.getCardByType('myers_briggs'),
      journeyCards:props.user.journey_cards,
      year:new Date().getFullYear(),
      profileCardsUpdated:false
    };
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.clearDisplayConnections();
    this.props.activityClear();
    this.props.clearUserCollectives();
    setTimeout(() => {
      this.loadMoreActivity();
      this.loadMoreConnections();
      this.loadMoreAdmirers();
      this.props.collectiveMemberGet({userId:this.props.user.id});
    }, 100);
  }

  viewDailyHoroscope = () => {
    this.props.modalOpen({
      modal: DAILY_HOROSCOPE_MODAL,
      locationHash: 'daily-horoscope',
      whiteExitButton:true,
      sign: this.state.zodiacCard ? this.state.zodiacCard.card_data.sign : undefined,
      allHoroscopes: this.props.allHoroscopes,
      customOverlay: '#193952'
    });
  }

  viewConnections = checkAuth(() => {
    this.loadMoreConnections();
    this.loadMoreAdmirers();
    this.props.modalOpen({
      modal: NETWORK_MODAL,
      currentUser: this.props.currentUser,
      header:`Network`,
      canSearch: true,
      onCloseCallback: () => {
        this.clearConnections();
        this.clearAdmirers();
      },
      currentOption: this.props.connections.length ? 'connections' : 'admirers',
      options: {
        connections:{
          label:'Connections',
          loadMore: this.loadMoreConnections,
          clear: this.clearConnections
        },
        admirers:{
          label:'Admirers',
          loadMore: this.loadMoreAdmirers,
          clear: this.clearAdmirers
        }
      }
    });
  })

  clearDisplayConnections = () => {
    this.props.connectionsClear(true);
    this.props.admirersClear(true);
  }

  clearConnections = () => {
    this.props.connectionsClear();
  }

  loadMoreConnections = (search) => {
    this.props.connectionsGet({
      userId:this.props.user.id,
      limit:14,
      offset:this.props.connectionsOffset,
      name: search
    });
  }

  clearAdmirers = () => {
    this.props.admirersClear();
  }

  loadMoreAdmirers = (search) => {
    if (!this.props.currentUser.id) return;

    this.props.admirersGet({
      targetUserId:this.props.user.id,
      limit:14,
      offset:this.props.admiresOffset,
      name: search
    });
  }

  viewActivity = () => {
    this.props.modalOpen({
      modal: ACTIVITY_FEED_MODAL,
      loadMore: this.loadMoreActivity,
      clear: this.clearActivity,
      header:`${this.props.user.first_name}'s Activity`,
      user:this.props.user
    });
  }

  clearActivity = () => {
    this.props.activityClear();
  }

  loadMoreActivity = () => {
    if (!this.props.currentUser.id) return;

    this.props.activityGet({
      userId:this.props.user.id,
      limit:10,
      offset:this.props.activityOffset
    });
  }

  viewAllCollectives = checkAuth(() => {
    this.props.modalOpen({
      modal: COLLECTIVES_LIST_MODAL,
      header: 'Subscribed Collectives',
      loadMore: this.props.collectiveMemberGet,
      onCloseCallback: this.props.clearCollectivesOffset,
      noSubscription:true,
      targetUser:this.props.user
    });
  })

  toggleEditMode = () => {
    if (this.props.canViewEditStates) {
      GTMpushDataLayerEvent({ event: GTM_PROFILE_EDIT });
      window.scrollTo(0,0);
      this.setState({isEditing:!this.state.isEditing});
    }
  }

  updateUserState = (user) => {
    let state = { ...this.state };
    state.user = Object.assign({}, user); 
    this.setState(state);
  }

  updateBioState = (newState) => {
    let state = { ...this.state };
    state.user.bio = newState;
    this.setState(state);
  }

  saveUser = () => {
    if (this.compareUserChanges() && this.validateProfile()) {
      let { user, journeyCards, zodiacCard, myersBriggsCard } = this.state;

      if (user.link_personal) {
        if (user.link_personal.substring(0,4) != 'http') {
          user.link_personal = 'http://' + user.link_personal;
        }
      }
      if (user.link_personal == '') {
        user.link_personal = null;
      }
      if (user.link_instagram == '') {
        user.link_instagram = null;
      }

      user.journey_cards = this.props.journeyCards;
      user[PROFILE_PHOTO] = this.props.user[PROFILE_PHOTO];

      let userToSave = cloneDeep(user);
      delete userToSave.email;
      this.props.userUpdate(userToSave);

      if (this.state.zodiacCard.toBeCreated) {
        delete this.state.zodiacCard.toBeCreated;
        this.state.zodiacCard.user_id = this.props.user.id;
        this.props.userPersonalityCardAdd(this.state.zodiacCard);
      } else if (this.state.zodiacCard.card_data) {
        this.props.userPersonalityCardUpdate(this.state.zodiacCard);
      }

      if (this.state.myersBriggsCard.toBeCreated) {
        delete this.state.myersBriggsCard.toBeCreated;
        this.state.myersBriggsCard.user_id = this.props.user.id;
        this.props.userPersonalityCardAdd(this.state.myersBriggsCard);
      } else if (this.state.myersBriggsCard.card_data) {
        this.props.userPersonalityCardUpdate(this.state.myersBriggsCard);
      }

      let newState = this.state;
      newState.savedUser = cloneDeep(user);
      newState.user = cloneDeep(user);
      newState.savedZodiacCard = cloneDeep(zodiacCard);
      newState.savedMyersBriggsCard = cloneDeep(myersBriggsCard);
      newState.profileCardsUpdated = false;
      newState.journeyCards = this.props.user.journey_cards;

      this.setState(newState);
    }
  }

  cancelEdit = () => {
    this.updatePersonalityCardZodiac(this.state.savedZodiacCard);
    this.updatePersonalityCardMyersBriggs(this.state.savedMyersBriggsCard);
    let newState = this.state;
    newState.user = cloneDeep(this.state.savedUser);
    this.setState(newState);
    this.toggleEditMode();
  }

  saveNewJourneyCard = (data) => {
    data.user_id = this.props.user.id;
    if (data.current) {
      data.end_date = null;
    } else {
      if (!data.end_date.month || !data.end_date.year) {
        data.end_date = data.start_date;
      }
    }
    this.props.userJourneyCardAdd(data);
    let newState = this.state;
    newState.profileCardsUpdated = true;
    newState.journeyCards.push(data);
    this.setState(newState);
  }

  saveJourneyCard = (data) => {
    delete data.user_id;
    if (data.current === null) {
      data.current = false;
    }
    if (data.current) {
      data.end_date = null;
    }

    // Filter out unnecessary leftover data if the card type changed.
    if (data.prev_card_type) {
      if (data.card_type == 'moment') {
        data.card_data = {
          title:data.card_data.title
        }
      }
      if (data.card_type == 'experience') {
        data.card_data = {
          title:data.card_data.title,
          company:data.card_data.company,
          industry:data.card_data.industry
        }
      }
      if (data.card_type == 'education') {
        data.card_data = {
          school:data.card_data.school,
          degree:data.card_data.degree
        }
      }
    }

    this.props.userJourneyCardUpdate(data);

    let newJourneyCards = this.props.currentUser.journey_cards || [];

    for (let i = 0; i < newJourneyCards.length; i++) {
      if (newJourneyCards[i].id == data.id) {
        newJourneyCards[i] = data;
      }
    }

    let newState = this.state;
    newState.journeyCards = newJourneyCards;
    newState.profileCardsUpdated = true;
    this.setState(newState);
  }

  deleteJourneyCard = (id) => {
    this.props.userJourneyCardDelete(id);
    let newState = this.state;
    newState.profileCardsUpdated = true;
    newState.journeyCards = newState.journeyCards.filter(card => (card.id != id));
    this.setState(newState);
  }

  viewJourneyCardWorkExperience = (data) => {
    this.props.modalOpen({
      modal: JOURNEY_CARD_VIEW_MORE_WORK_EXPERIENCE_MODAL,
      data: data
    });
  }

  viewJourneyCardGirlbossMoment = (data) => {
    this.props.modalOpen({
      modal: JOURNEY_CARD_VIEW_MORE_GIRLBOSS_MOMENT_MODAL,
      data: data
    });
  }

  viewJourneyCardEducation = (data) => {
    this.props.modalOpen({
      modal: JOURNEY_CARD_VIEW_MORE_EDUCATION_MODAL,
      data: data
    });
  }

  updatePersonalityCardZodiac = (data) => {
    let newState = this.state;
    newState.zodiacCard = data;
    newState.profileCardsUpdated = true;
    this.setState(newState);
  }

  updatePersonalityCardMyersBriggs = (data) => {
    let newState = this.state;
    newState.myersBriggsCard = data;
    newState.profileCardsUpdated = true;
    this.setState(newState);
  }
  
  sendContactRequest = () => {
    const { user, sendContactRequest } = this.props;
    this.props.modalOpen({
      modal: CONTACT_REQUEST_MODAL,
      user:user,
      send:sendContactRequest
    });
  }

  getMetaTitle = () => {
    const { user } = this.state;
    const fullName = `${user[FIRST_NAME]} ${user[LAST_NAME]}`;
    let subheadline = '';

    if (user[BIO] && user[BIO][IM_GOOD_AT]) {
      subheadline = user[BIO][IM_GOOD_AT];
    }

    if (user[HEADLINE]) {
      subheadline = user[HEADLINE];
    }

    if (subheadline) {
      return `${fullName} | ${subheadline} - Girlboss`;
    }

    return `${fullName} - Girlboss`;
  }

  getCardByType = (type) => {
    const { user } = this.props;
    let card = {};
    for (let i = 0; i < user.cards.length; i++) {
      if (user.cards[i].card_type === type) {
        card = cloneDeep(user.cards[i]);
      }
    }
    return card;
  }

  instantiateZodiacCard = () => {
    this.setState({
      zodiacCard:{
        card_type:'zodiac',
        display_on_profile:false,
        card_data:{
          sign:'Aries'
        },
        toBeCreated:true
      }
    });
  }

  instantiateMyersBriggsCard = () => {
    this.setState({
      myersBriggsCard:{
        card_type:'myers_briggs',
        display_on_profile:false,
        card_data:{
          type:'INTJ'
        },
        toBeCreated:true
      }
    })
  }

  handleJourneyScroll = (e) => {
    const years = e.target.getElementsByClassName('journey-year-container');
    let currentYear = new Date().getFullYear();
    for (let i = 0; i < years.length; i++) {
      if (years[i].getBoundingClientRect().left < years[i].parentElement.parentElement.getBoundingClientRect().left + 100) {
        currentYear = parseInt(years[i].children[0].innerHTML);
      }
    }
    if (currentYear != this.state.year) {
      this.setState({year:currentYear});
    }
  }

  scrollToJourneyYear = (year) => {
    const yearElements = this.osJourneyRef.current.getElementsByClassName('journey-year-container');
    let yearElement = null;
    for (let i = 0; i < yearElements.length; i++) {
      if (yearElements[i].children[0].innerHTML == year) {
        yearElement = yearElements[i];
      }
    }
    if (yearElement) {
      let targetScrollPosition = yearElement.getBoundingClientRect().left; // - 200;
      this.osJourneyRef.current.scrollLeft += targetScrollPosition;
    } else {
      this.osJourneyRef.current.scrollLeft = 0;
    }
  }

  compareUserChanges = () => {
    const { user, savedUser, zodiacCard, savedZodiacCard, myersBriggsCard, savedMyersBriggsCard, profileCardsUpdated } = this.state;

    let changed = false;
    changed = !isEqual(user, savedUser) || changed;
    changed = profileCardsUpdated ? profileCardsUpdated : changed;
    if (savedUser.bio) {
      changed = !isEqual(user, savedUser) || changed;
    } else if (user.bio) {
      changed = true;
    }

    if (zodiacCard.card_data) {
      changed = !isEqual(zodiacCard, savedZodiacCard) || changed;
    }
    if (myersBriggsCard.card_data) {
      changed = !isEqual(myersBriggsCard, savedMyersBriggsCard) || changed;
    }

    const ensureHTTP = (link) => {
      if (link) {
        if (link.substring(0,7).toLowerCase() != 'http://' &&
            link.substring(0,8).toLowerCase() != 'https://') {
              return 'http://' + link;
        }
      }
      return link;
    };

    const linkIsValid = (link) => {
      let valid = link.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
      if (valid == null) {
        return false;
      }
      return true;
    };

    if (user.link_personal && user.link_personal.length > 0) {
      let link_personal = ensureHTTP(user.link_personal);
      if (!linkIsValid(link_personal)) {
        return false;
      }
    }

    changed = !isEqual(this.props.user[PROFILE_PHOTO], savedUser.photo) || changed;

    return changed;
  }

  validateProfile = () => {
    const { user } = this.state;
    let errors = {};
    errors[FIRST_NAME] = !(isName(user[FIRST_NAME]) && isNameInLimits(user[FIRST_NAME]));
    errors[LAST_NAME] = !(isName(user[LAST_NAME]) && isNameInLimits(user[LAST_NAME]));
    return errors;
  }

  contactURL = () => {
    const { user } = this.props;
    if (user.contacts && user.contacts.length) {
      // return user.contacts[0].sendbird_group_id;
      return 'x';
    }
    return false;
  }

  requestSent () {
    if(this.props.contactRequestsIds.indexOf(this.props.user.id) > -1) {
      return true;
    } 
    return false;
  };

  findContactRequestForCurrentProfile = () => {
    const { currentUser, user } = this.props;
    if (currentUser.contact_requests) {
      for (let i = 0; i < currentUser.contact_requests.length; i++) {
        let contact_request = currentUser.contact_requests[i];
        if (contact_request.contact_id == user.id) {
          return contact_request;
        }
      }
    }
    return false;
  }
  
  contactRequestPendingModal = () => {
    this.props.modalOpen({
      modal: CONTACT_REQUEST_PENDING_MODAL,
      contactRequestAvailableTimestamp:this.requestAvailableTimestamp()
    });
  }

  copyProfileLink = () => {
    const { user } = this.props;
    
    let el = document.createElement('textarea');
    el.value = process.env.NODE_ENV == 'production' ? `https://girlboss.com/${user.username}` : `https://community-staging.girlboss.com/${user.username}`;
    el.setAttribute('contenteditable', '');
    el.style = {position: 'fixed', left: '0', top: '0'};
    document.body.appendChild(el);
    el.focus();
    el.setSelectionRange(0,500);
    document.execCommand('copy');
    document.body.removeChild(el);
    window.scrollTo(0,0);

    GTMpushDataLayerEvent({ event: GTM_PROFILE_LINK_COPIED });

    this.props.modalOpen({
      modal: URL_COPIED_MODAL
    });
  }

  render() {
    const {
      canViewEditStates,
      currentUser,
      isLoading,
      isLoggedIn,
      collectives,
      admireAdd,
      admireRemove,
      admiresMap,
      displayConnections,
      displayAdmirers,
      activity
    } = this.props;
    let {
      user,
      isEditing,
      zodiacCard,
      myersBriggsCard,
      year,
      journeyCards
    } = this.state;
    const editableUser = this.state.user;
    if (!editableUser[BIO]) {
      editableUser[BIO] = {};
    }

    const errors = this.validateProfile();
    const canSave = !errors[FIRST_NAME] && !errors[LAST_NAME];

    if (isEditing) {
      return (
        <Editing
          user={user}
          zodiacCard={zodiacCard}
          myersBriggsCard={myersBriggsCard}
          instantiateZodiacCard={this.instantiateZodiacCard}
          instantiateMyersBriggsCard={this.instantiateMyersBriggsCard}
          journeyCards={journeyCards}
          saveJourneyCard={this.saveJourneyCard}
          createJourneyCard={this.saveNewJourneyCard}
          deleteJourneyCard={this.deleteJourneyCard}
          update={this.updateUserState}
          canSave={canSave}
          save={this.saveUser}
          toggleEditMode={this.toggleEditMode}
          updatePersonalityCardZodiac={this.updatePersonalityCardZodiac}
          updatePersonalityCardMyersBriggs={this.updatePersonalityCardMyersBriggs}
          errors={errors}
        />
      );
    }
    return (
      <React.Fragment>
        <Helmet title={`Girlboss - ${user.first_name} ${user.last_name}`} />
        <Viewing
          user={user}
          currentUser={currentUser}
          canViewEditStates={canViewEditStates}
          isLoading={isLoading}
          isLoggedIn={isLoggedIn}
          zodiacCard={zodiacCard}
          myersBriggsCard={myersBriggsCard}
          journeyCards={journeyCards}
          generateJourneyCards={this.generateJourneyCards}
          year={year}
          selectYear={this.selectYear}
          toggleEditMode={this.toggleEditMode}
          viewConnections={this.viewConnections}
          viewAllCollectives={this.viewAllCollectives}
          viewActivity={this.viewActivity}
          viewDailyHoroscope={this.viewDailyHoroscope}
          sendContactRequest={this.sendContactRequest}
          contactURL={this.contactURL()}
          contactRequestSent={this.requestSent()}
          copyProfileLink={this.copyProfileLink}
          connections={displayConnections}
          admirers={displayAdmirers}
          collectives={collectives}
          admireAdd={admireAdd}
          admireRemove={admireRemove}
          admiresMap={admiresMap}
          activity={activity}
        />
      </React.Fragment>
    )
  }
};

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args)),
    userUpdate: (args) => dispatch(userUpdate(args)),
    userPersonalityCardAdd: (args) => dispatch(userPersonalityCardAdd(args)),
    userPersonalityCardUpdate: (...args) => dispatch(userPersonalityCardUpdate(...args)),
    userPersonalityCardDelete: (args) => dispatch(userPersonalityCardDelete(args)),
    userJourneyCardAdd: (args) => dispatch(userJourneyCardAdd(args)),
    userJourneyCardUpdate: (...args) => dispatch(userJourneyCardUpdate(...args)),
    userJourneyCardDelete: (args) => dispatch(userJourneyCardDelete(args)),
    sendContactRequest: (args) => dispatch(sendContactRequest(args)),
    userRemoveContactRequest: (args) => dispatch(userRemoveContactRequest(args)),
    connectionsGet: (args) => dispatch(connectionsGet(args)),
    connectionsClear: (args) => dispatch(connectionsClear(args)),
    admirersGet: (args) => dispatch(admirersGet(args)),
    admirersClear: (args) => dispatch(admirersClear(args)),
    activityGet: (args) => dispatch(activityGet(args)),
    activityClear: (args) => dispatch(activityClear(args)),
    collectiveMemberGet: (args) => dispatch(collectiveMemberGet(args)),
    clearCollectivesOffset: () => dispatch(clearCollectivesOffset()),
    clearUserCollectives: () => dispatch(clearUserCollectives()),
    admireAdd: (args) => dispatch(admireAdd(args)),
    admireRemove: (args) => dispatch(admireRemove(args))
  }
};

const mapStateToProps = state => ({
  stateJourneyCards:state,
  allHoroscopes:state.Profile.allHoroscopes,
  currentUser:state.User.user,
  isLoggedIn:state.App.isLoggedIn,
  contactRequestsIds:state.User.user && state.User.user.contact_requests ? state.User.user.contact_requests.map(contactRequest => contactRequest.contact_id) : [],
  collectives: state.Collectives.userCollectives,
  displayConnections: state.Connections.displayConnections,
  connections: state.Connections.connections,
  connectionsOffset: state.Connections.offset,
  activity: state.Activity.activity,
  activityOffset: state.Activity.offset,
  admiresMap: state.Admires.admiresMap,
  admiresOffset: state.Admires.offset,
  displayAdmirers: state.Admires.displayAdmirers
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);