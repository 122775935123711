import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ProfilePersonalityCard.scss';

import ProfilePersonalityCardActive from './ProfilePersonalityCardActive';
import ProfilePersonalityCardEditing from './ProfilePersonalityCardEditing';
import ProfilePersonalityCardNew from './ProfilePersonalityCardNew';

import SVG from 'react-inlinesvg';

import Aries from '../../../../public/assets/zodiac/Aries.png';
import Taurus from '../../../../public/assets/zodiac/Taurus.png';
import Gemini from '../../../../public/assets/zodiac/Gemini.png';
import Cancer from '../../../../public/assets/zodiac/Cancer.png';
import Leo from '../../../../public/assets/zodiac/Leo.png';
import Virgo from '../../../../public/assets/zodiac/Virgo.png';
import Libra from '../../../../public/assets/zodiac/Libra.png';
import Scorpio from '../../../../public/assets/zodiac/Scorpio.png';
import Sagittarius from '../../../../public/assets/zodiac/Sagittarius.png';
import Capricorn from '../../../../public/assets/zodiac/Capricorn.png';
import Aquarius from '../../../../public/assets/zodiac/Aquarius.png';
import Pisces from '../../../../public/assets/zodiac/Pisces.png';

import AriesBlack from '../../../../public/assets/zodiac/Aries_black.png';
import TaurusBlack from '../../../../public/assets/zodiac/Taurus_black.png';
import GeminiBlack from '../../../../public/assets/zodiac/Gemini_black.png';
import CancerBlack from '../../../../public/assets/zodiac/Cancer_black.png';
import LeoBlack from '../../../../public/assets/zodiac/Leo_black.png';
import VirgoBlack from '../../../../public/assets/zodiac/Virgo_black.png';
import LibraBlack from '../../../../public/assets/zodiac/Libra_black.png';
import ScorpioBlack from '../../../../public/assets/zodiac/Scorpio_black.png';
import SagittariusBlack from '../../../../public/assets/zodiac/Sagittarius_black.png';
import CapricornBlack from '../../../../public/assets/zodiac/Capricorn_black.png';
import AquariusBlack from '../../../../public/assets/zodiac/Aquarius_black.png';
import PiscesBlack from '../../../../public/assets/zodiac/Pisces_black.png';

import AriesNavy from '../../../../public/assets/zodiac/Aries_navy.svg';
import TaurusNavy from '../../../../public/assets/zodiac/Taurus_navy.svg';
import GeminiNavy from '../../../../public/assets/zodiac/Gemini_navy.svg';
import CancerNavy from '../../../../public/assets/zodiac/Cancer_navy.svg';
import LeoNavy from '../../../../public/assets/zodiac/Leo_navy.svg';
import VirgoNavy from '../../../../public/assets/zodiac/Virgo_navy.svg';
import LibraNavy from '../../../../public/assets/zodiac/Libra_navy.svg';
import ScorpioNavy from '../../../../public/assets/zodiac/Scorpio_navy.svg';
import SagittariusNavy from '../../../../public/assets/zodiac/Sagittarius_navy.svg';
import CapricornNavy from '../../../../public/assets/zodiac/Capricorn_navy.svg';
import AquariusNavy from '../../../../public/assets/zodiac/Aquarius_navy.svg';
import PiscesNavy from '../../../../public/assets/zodiac/Pisces_navy.svg';

export const SIGNS = {
  Aries: {
    name:'Aries',
    time:'Mar 21 - Apr 19',
    image:Aries,
    imageDark:AriesBlack,
    imageSVG:AriesNavy
  },
  Taurus: {
    name:'Taurus',
    time:'Apr 20 - May 20',
    image:Taurus,
    imageDark:TaurusBlack,
    imageSVG:TaurusNavy
  },
  Gemini: {
    name:'Gemini',
    time:'May 21 - Jun 20',
    image:Gemini,
    imageDark:GeminiBlack,
    imageSVG:GeminiNavy
  },
  Cancer: {
    name:'Cancer',
    time:'Jun 21 - Jul 22',
    image:Cancer,
    imageDark:CancerBlack,
    imageSVG:CancerNavy
  },
  Leo: {
    name:'Leo',
    time:'Jul 23 - Aug 22',
    image:Leo,
    imageDark:LeoBlack,
    imageSVG:LeoNavy
  },
  Virgo: {
    name:'Virgo',
    time:'Aug 23 - Sept 22',
    image:Virgo,
    imageDark:VirgoBlack,
    imageSVG:VirgoNavy
  },
  Libra: {
    name:'Libra',
    time:'Sept 23 - Oct 22',
    image:Libra,
    imageDark:LibraBlack,
    imageSVG:LibraNavy
  },
  Scorpio: {
    name:'Scorpio',
    time:'Oct 23 - Nov 21',
    image:Scorpio,
    imageDark:ScorpioBlack,
    imageSVG:ScorpioNavy
  },
  Sagittarius: {
    name:'Sagittarius',
    time:'Nov 22 - Dec 21',
    image:Sagittarius,
    imageDark:SagittariusBlack,
    imageSVG:SagittariusNavy
  },
  Capricorn: {
    name:'Capricorn',
    time:'Dec 22 - Jan 19',
    image:Capricorn,
    imageDark:CapricornBlack,
    imageSVG:CapricornNavy
  },
  Aquarius: {
    name:'Aquarius',
    time:'Jan 20 - Feb 18',
    image:Aquarius,
    imageDark:AquariusBlack,
    imageSVG:AquariusNavy
  },
  Pisces: {
    name:'Pisces',
    time:'Feb 19 - Mar 20',
    image:Pisces,
    imageDark:PiscesBlack,
    imageSVG:PiscesNavy
  }
};

class ProfilePersonalityCardZodiac extends Component {
  static propTypes = {
    data:PropTypes.object,
    edit:PropTypes.func,
    isEditing:PropTypes.bool,
    className:PropTypes.string
  }

  static defaultProps = {
    data:{
      card_type:'zodiac',
      display_on_profile:false,
      card_data:{
        sign:'Aries'
      },
      toBeCreated:true
    }
  }

  constructor(props) {
    super(props);
  }

  editSign = (e) => {
    let newData = this.props.data;
    newData.card_data.sign = e.target.value;
    this.props.edit(newData);
  }

  editVisibility = (e) => {
    let newData = this.props.data;
    newData.display_on_profile = e.target.checked;
    this.props.edit(newData);
  }

  render() {

    const { isEditing, edit, data, viewDailyHoroscope, className, displayFromFeed, create, promptCreation } = this.props;

    if (!data.card_data || promptCreation) {
      return (
        <ProfilePersonalityCardNew
          className={classNames('ProfilePersonalityCard', className, 'ProfilePersonalityCard__add')}
          label="Add Zodiac Sign"
          // [Uncomment to enable daily horoscopes]
          // description="Get your daily horoscope reading by adding this to your profile."
          create={create}
        />
      )
    }

    return (
      <div>
        {/* Public */}
        {
          (displayFromFeed || (data && data.display_on_profile && !isEditing)) && (
            <ProfilePersonalityCardActive
              className={className}
              isEditing={isEditing}
              editCard={edit}
              className={classNames('ProfilePersonalityCardZodiac', className, `ProfilePersonalityCardZodiac--${data.card_data.sign}`)}
              // [Uncomment to enable daily horoscopes] onClick={viewDailyHoroscope}
            >
              <SVG src={SIGNS[data.card_data.sign].imageSVG} />
              <div className="ProfilePersonalityCardZodiac__text-container">
                <div className="ProfilePersonalityCardZodiac__main">{ SIGNS[data.card_data.sign].name }</div>
                <div className="ProfilePersonalityCardZodiac__sub-main">{ SIGNS[data.card_data.sign].time }</div>
              </div>
              {/* [Uncomment to enable daily horoscopes] <div className="ProfilePersonalityCardZodiac__read-more">View Today's Readings</div> */}
            </ProfilePersonalityCardActive>
          )
        }
        {/* Editing */}
        {
          (isEditing) && (
            <ProfilePersonalityCardEditing
              isEditing={isEditing}
              editCard={edit}
              className={classNames('ProfilePersonalityCardZodiacEditing', className)}
            >
            <div className="ProfilePersonalityCardZodiacEditing__heading">Update Zodiac Sign</div>
            <div className="ProfilePersonalityCardZodiacEditing__main" style={{ backgroundImage:`url(${ SIGNS[data.card_data.sign].imageDark })`}}></div>
            <div className="ProfilePersonalityCardZodiacEditing__sub-main">{ SIGNS[data.card_data.sign].time }</div>
            <select className="ProfilePersonalityCardZodiacEditing__sign-select" value={data.card_data.sign} onChange={this.editSign}>
              {
                Object.keys(SIGNS).map((sign) => {
                  return <option key={sign} value={sign}>{SIGNS[sign].name}</option>
                })
              }
            </select>
            <div className="ProfilePersonalityCardZodiacEditing__hide-show">Show Card</div>
            <label className="switch">
              <input type="checkbox" defaultChecked={data.display_on_profile} onChange={this.editVisibility} />
              <span className="slider round"></span>
            </label>
            </ProfilePersonalityCardEditing>
          )
        }
      </div>
    )
  }
}

export default ProfilePersonalityCardZodiac;