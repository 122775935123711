import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Onboarding.scss';

class OnboardingNavItem extends PureComponent {
  static propTypes = {
    label:PropTypes.string,
    icon:PropTypes.string,
    active:PropTypes.bool
  };
   
  render() {

    const { label, icon, active } = this.props;

    return (
      <div className={
        classNames(
          "Onboarding__nav-item",
          {
            "inactive":!active
          }
        )
      }>
        <div className="Onboarding__nav-icon" style={{backgroundImage:`url(${icon})`}}></div>
        <span>{label}</span>
      </div>
    )
  }
}

export default OnboardingNavItem;
