import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import {
  PROFILE_PHOTO
} from './../../common/Input/CommonFields/profile';
import { userGetPhoto } from './../../store/User/actions';
import { notifsGetUnreadCount } from './../../store/Notifications/actions';

import withSizes, { mapSizesToProps } from '../../lib/WithSizes';
import routes from '../../config/routes';

import './Nav.scss';

import SideProfileNav from './SideProfileNav';
import SideNotificationNav from './SideNotificationNav';

import GBetaDesktopLogo from '../../components/SVG/GBetaDesktopLogo.js';
import GBetaMobileLogo from '../../components/SVG/GBetaMobileLogo.js';

export const NAV_TYPE_COMPONENT = 'component';
export const NAV_TYPE_LINK = 'link';
export const NAV_TYPE_LINK_EXTERNAL = 'external-link';
export const NAV_TYPE_ICON = 'icon';
export const NAV_TYPE_MOBILE_FOOTER = 'mobile-footer';

function throttled(delay, fn) {
  let lastCall = 0;
  return function (...args) {
    const now = (new Date).getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  }
}

class Nav extends Component {
  static propTypes = {
    className: PropTypes.string,
    leftLinks: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf([
        NAV_TYPE_LINK,
        NAV_TYPE_LINK_EXTERNAL,
        NAV_TYPE_COMPONENT,
        NAV_TYPE_ICON
      ])
    })),
    rightLinks: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf([
        NAV_TYPE_LINK,
        NAV_TYPE_LINK_EXTERNAL,
        NAV_TYPE_COMPONENT,
        NAV_TYPE_ICON
      ])
    })),
    mobileFooterLinks: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf([
        NAV_TYPE_MOBILE_FOOTER,
        NAV_TYPE_COMPONENT
      ])
    }))
  };

  static defaultProps = {
    className: '',
    links: null
  };

  constructor(props) {
    super(props);

    this.state = {
      isNavOnTop: null,
      isAtTop: null,
      onDesktop: this.props.onDesktop
    };

    this.lastY = null;
  }

  componentDidMount = () => {
    let isTouch = true;
    let throttledHandler;

    isTouch = function checkTouch() {
      try {
        document.createEvent("TouchEvent");
        return true;
      } catch (e) {
        return false;
      }
    }();

    // if (isTouch) {
    //   throttledHandler = throttled(250, this.toggleNavBarTouch);
    //   window.addEventListener('touchstart', this.saveTouchStart);
    //   window.addEventListener('touchend', throttledHandler);
    // } else {
    //   throttledHandler = throttled(250, this.toggleNavBar);
    //   window.addEventListener('mousewheel', throttledHandler);
    // }
  }

  componentWillUnmount = () => {
    window.removeEventListener('touchstart', this.saveTouchStart);
    window.removeEventListener('touchend', this.toggleNavBarTouch);
    window.removeEventListener('mousewheel', this.toggleNavBar);
  }

  toggleNavBar = (e, delta) => {
    // const isScrollingDown = e.wheelDelta < 0;
    // const isAtTop = document.body.scrollTop < 400;

    // this.setState({ isAtTop });

    // if (isAtTop) {
    //   this.setState({ isNavOnTop: null });
    //   return;
    // }

    // if (isScrollingDown) {
    //   this.setState({ isNavOnTop: false });
    // } else {
    //   this.setState({ isNavOnTop: true });
    // }
  }

  saveTouchStart = e => {
    this.lastY = e.touches[0].clientY;
  }

  toggleNavBarTouch = (e, delta) => {
    const currentY = e.changedTouches[0].clientY;
    const isAtTop = document.body.scrollTop < 20;

    this.setState({ isAtTop });

    if (isAtTop) {
      this.setState({ isNavOnTop: null });
      return;
    }

    if (currentY + 5 < this.lastY) {
      this.setState({ isNavOnTop: false });
    } else {
      this.setState({ isNavOnTop: true });
    }
  }

  renderLinks = (links) => {
    return (
      links.map((link, indx) => {
        let item;
        const { text, type, url, component, icon, className } = link;

        switch (type) {
          case NAV_TYPE_COMPONENT:
            item = (component);
            break;
          case NAV_TYPE_ICON:
            item = (
              <Link to={url}>
                <img src={icon} alt={text} className="Nav__items--icon" />
              </Link>
            );
            break;
          case NAV_TYPE_MOBILE_FOOTER:
            item = (
              <Link to={url}>
                <div className={classNames(
                  "Nav__mobile-footer-item",
                  {"active":this.compareCurrentRoute(link)}
                )}>
                  <img src={icon} />
                  <span>{text}</span>
                </div>
              </Link>
            )
            break;
          case NAV_TYPE_LINK_EXTERNAL:
            item = (
              <a href={url} target="_blank" className={classNames(
                "Nav__items--link",
                {"active":this.compareCurrentRoute(link)},
                {"Nav__items-desktop-only":link.desktopOnly},
                {"Nav__items-mobile-only":link.mobileOnly}
              )}>
                {text}
                <div></div>
              </a>
            )
            break;
          case NAV_TYPE_LINK:
          default:
            item = (
              <Link to={url} className={classNames(
                "Nav__items--link",
                {"active":this.compareCurrentRoute(link)},
                {"Nav__items-desktop-only":link.desktopOnly},
                {"Nav__items-mobile-only":link.mobileOnly}
              )}>
                {text}
                <div></div>
              </Link>
            )
            break;
        }

        return (
          <div className={classNames("Nav__item", className)} key={indx}>
            {item}
            {
              link.notification && (
                <div className="Nav__notification-dot"></div>
              )
            }
          </div>
        );
      })
    )
  }
  
  compareCurrentRoute = (link) => {
    const { pathname } = this.props.location;
    if (link.activeRoutes) {
      for (let i = 0; i < link.activeRoutes.length; i++) {
        let match = matchPath(pathname, link.activeRoutes[i]);
        if (match && match.isExact) {
          // Preventing messaging and videos from matching with user profiles.
          if (link.activeRoutes[i] == routes.username && (pathname == '/videos' || pathname == '/messaging')) {
            return false;
          }
          return true;
        }
      }
    } else {
      if (pathname == link.url) {
        return true;
      }
    }
    return false;
  }


  render() {
    const {
      className,
      isLoggedIn,
      leftLinks,
      rightLinks,
      centerLinks,
      mobileFooterLinks,
      onDesktop,
      location,
      user
    } = this.props;
    const {
      isNavOnTop
    } = this.state;
    const photo = this.props[PROFILE_PHOTO];

    return (
        <React.Fragment>
          {
            isLoggedIn && !isEmpty(user) ? 
              <React.Fragment>
                <SideProfileNav 
                user={user} 
                open={this.props.SideProfileNavOpen}
                closeNav={this.props.toggleMobileProfileNav}
                onDesktop={onDesktop}
                />

                <SideNotificationNav 
                  user={user} 
                  open={this.props.SideNotificationNavOpen}
                  closeNav={this.props.toggleMobileNotificationNav}
                  onDesktop={onDesktop}
                />
              </React.Fragment> :
              null
          }
        
          <div
          className={classNames(
            "Nav__parent",
            this.props.className,
            {
              "mobile": !onDesktop ,
              'Nav__background--fade': isNavOnTop === false,
              'Nav__background--fadeIn': isNavOnTop === true
            }
          )}
          >
            <div
              className="Nav"
              onScroll={this.toggleNavBar}
            >

            {
             onDesktop && !isLoggedIn ? 
              <React.Fragment>
                <Link to={routes.root} style={{marginRight:'auto'}}>
                  <div className="Nav__logo" />
                </Link>
                <span className="Nav__items">
                {rightLinks && this.renderLinks(rightLinks)}
                </span>
              </React.Fragment> :
               null
            }

            {
              onDesktop && isLoggedIn ?  
              <React.Fragment>
                <Link to={routes.root}>
                  <div className="Nav__logo" />
                </Link>

                <span className="Nav__items-container">
                  <span className="Nav__items">
                    {leftLinks && this.renderLinks(leftLinks)}
                  </span>
                  <span className="Nav__items">
                    {rightLinks && this.renderLinks(rightLinks)}
                  </span>
                </span>
              </React.Fragment> :
              null
            }
            {
              !onDesktop && !isLoggedIn ? 
              <React.Fragment>
                <Link to={routes.root} style={{marginRight:'auto'}}>
                  <div className="Nav__logo" />
                </Link>
                <span className="Nav__items">
                {rightLinks && this.renderLinks(rightLinks)}
                </span>
              </React.Fragment> :
              null
            }

            { 
              !onDesktop && isLoggedIn ? 
              centerLinks && this.renderLinks(centerLinks)
              : null
            }
            </div>
          </div>

            {
              !onDesktop && mobileFooterLinks ? 
                <div className="Nav__mobile-footer">
                  {this.renderLinks(mobileFooterLinks)}
                </div> : null
            }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.User.user,
    isLoggedIn: state.App.isLoggedIn,
    [PROFILE_PHOTO]: userGetPhoto(state),
    notifsUnreadCount: notifsGetUnreadCount(state),
  }
}

export default (withRouter(connect(mapStateToProps)(withSizes(mapSizesToProps)(Nav))));