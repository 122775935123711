import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const EDIT_THEME_BLACK = '#2D2D2D';
export const EDIT_THEME_WHITE = '#FFFFFF';

class EditIcon extends PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf([EDIT_THEME_BLACK, EDIT_THEME_WHITE])
  };

  static defaultProps = {
    theme: EDIT_THEME_BLACK
  };

  render() {
    return (
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9148 1.14651C10.7196 0.951202 10.403 0.951202 10.2077 1.14651L9.43267 1.92142L11.5536 4.04324L12.3288 3.2681C12.524 3.07278 12.5241 2.75638 12.3289 2.56107L10.9148 1.14651ZM8.37188 1.56766L8.36388 1.57596L1.50506 8.43387C1.47625 8.46268 1.4486 8.49271 1.42229 8.52371C1.40368 8.53763 1.38586 8.55325 1.36889 8.5701C1.30456 8.63431 1.26147 8.7117 1.23949 8.79373C1.22051 8.82962 1.20299 8.86649 1.18694 8.90384L0.125846 11.3794C-0.407601 12.6241 0.850944 13.8826 2.09557 13.3492L4.73705 12.2166C4.79778 12.1922 4.85473 12.1555 4.90392 12.1062L11.9069 5.10379L13.0359 3.97537C13.6216 3.38968 13.6218 2.43997 13.0361 1.85403L11.6221 0.439484C11.0364 -0.146454 10.0865 -0.146454 9.5006 0.43924L8.37994 1.55984L8.37188 1.56766ZM4.55053 11.0454L10.8465 4.75027L8.72552 2.62845L2.42956 8.92386L4.55053 11.0454ZM1.89098 9.79959L1.04497 11.7735C0.867179 12.1883 1.28667 12.6079 1.70159 12.43L3.67492 11.584L1.89098 9.79959Z" transform="translate(0.399597 0.914307)" fill={this.props.theme} />
      </svg>
    )
  }
};

export default EditIcon;
