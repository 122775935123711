import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Input from '../../common/Input';
import profileInputFields, { EMAIL_ADDRESS } from '../../common/Input/CommonFields/profile';
import Button, { THEME_BLUE, THEME_YELLOW } from '../../common/Button';
import ProfilePhoto, { SIZE_LARGE } from '../../components/ProfilePhoto';

import {
  submitEmailToWaitlist,
  submitEmailToWaitlistReferral,
  cmStats
} from '../../store/App/actions';
import { modalOpen } from '../../store/Modal/actions';
import {
  GTMpushDataLayerEvent,
  GTM_WAITLIST_ADD
} from '../../lib/GoogleTagManager';

import { girlbossComLinks } from '../../config/routes';
import { HOMEPAGE_REFERRAL_MODAL } from '../../containers/ModalManager/ModalTypes.js';

import GridItem from './GridItem';
import GridLeader from './GridLeader';
import PerksItem from './PerksItem';
import TestimonialItem from './TestimonialItem';
import HomepageVideo from './HomepageVideo';

// note to minify all pngs
import MainHero from '../../../public/assets/illustrations/main-hero.png';
import MeetingMentor from '../../../public/assets/illustrations/meeting-mentor.png';
import Megaphone from '../../../public/assets/illustrations/megaphone.png';
import NetworkSignal from '../../../public/assets/illustrations/network-signal.png';
import Networking from '../../../public/assets/illustrations/networking.png';
import Phones from '../../../public/assets/illustrations/phones.png';
import Signals from '../../../public/assets/illustrations/signals.png';
import SpeechBubbles from '../../../public/assets/illustrations/speech-bubbles.png';
import Laptop from '../../../public/assets/illustrations/laptop.png';

import ElaineWelteroth from '../../../public/assets/Homepage/thought-leaders/HS_ELAINE.jpg';
import BethComstock from '../../../public/assets/Homepage/thought-leaders/HS_BETH.jpg';
import BozomaStJohn from '../../../public/assets/Homepage/thought-leaders/HS_BOZOMA.jpg';
import EmilyWeiss from '../../../public/assets/Homepage/thought-leaders/HS_EMILY.jpg';
import JenRubio from '../../../public/assets/Homepage/thought-leaders/HS_JEN.jpg';
import PayalKadakia from '../../../public/assets/Homepage/thought-leaders/HS_PAYAL.jpg';
import WhitneyWolfe from '../../../public/assets/Homepage/thought-leaders/HS_WHITNEY.jpg';
import KateWalsh from '../../../public/assets/Homepage/thought-leaders/HS_KATE.jpg';

import Jennifer from  '../../../public/assets/Homepage/testimonials/jennifer.jpg';
import Monique from  '../../../public/assets/Homepage/testimonials/monique.jpg';
import Sam from  '../../../public/assets/Homepage/testimonials/sam.jpg';

import Sophia from '../../../public/assets/Homepage/Sophia.jpg';
import Signature from '../../../public/assets/Homepage/SophiaSignature.png';

import VideoThumbnail from '../../../public/assets/Homepage/video_thumbnail.jpg';
import PlayButton from '../PlayButton';

import './Homepage.scss';

const gridItems = [
  {
    copy: 'Start conversations, get mentorship, and share your expertise.',
    image: Networking,
    image_title: 'Network with the best'
  },
  {
    copy: 'Over 50 hours of video content',
    image: Laptop,
    image_title: 'Level up your skills'
  },
  {
    copy: "Find freelancer's and get work.",
    image: MeetingMentor,
    image_title: 'Hire and get hired'
  },
  {
    copy: "We're coming to a city near you.",
    image: Megaphone,
    image_title: 'Join local meetups'
  }
];

const gridLeaders = [
  {
    photo: BozomaStJohn,
    name: 'Bozoma St. John',
    jobTitle: 'Chief Marketing Officer, Endeavor'
  },
  {
    photo: EmilyWeiss,
    name: 'Emily Weiss',
    jobTitle: 'Founder and CEO, Glossier'
  },
  {
    photo: BethComstock,
    name: 'Beth Comstock',
    jobTitle: 'Author, Imagine It Forward'
  },
  {
    photo: PayalKadakia,
    name: 'Payal Kadakia',
    jobTitle: 'Founder and Executive Chairman, Classpass'
  },
  {
    photo: WhitneyWolfe,
    name: 'Whitney Wolfe Herd',
    jobTitle: 'Founder and CEO, Bumble'
  },
  {
    photo: ElaineWelteroth,
    name: 'Elaine Welteroth',
    jobTitle: 'Journalist and Former Editor-in-Chief, Teen Vogue'
  },
  {
    photo: JenRubio,
    name: 'Jen Rubio',
    jobTitle: 'Co-Founder, Away'
  },
  {
    photo: KateWalsh,
    name: 'Kate Walsh',
    jobTitle: 'Founder, Boyfriend Perfume'
  }
];

const perksItems = [
  { text: 'Early access for tickets to our Girlboss Rallies' },
  { text: 'First access to limited edition Girlboss merch' }
];

const testimonialItems = [
  {
    name: 'Jennifer Cedillo',
    photo: Jennifer,
    title: 'Marketing Director for Group 1 Automotive',
    testimonial: 'The Girlboss community has shown me examples of women making waves in all categories of business. It’s shown me my ideas aren’t crazy, and that it doesn’t matter when you start, that you should JUST start!'
  },
  {
    name: 'Sam Laliberte',
    photo: Sam,
    title: 'Founder + Host, Freedom Lifestyle',
    testimonial: `I had an upcoming speaking gig and binge-watched all the Girlboss streaming videos. Wouldn't have thought to create my own branded cue cards if it wasn't for the genius team behind the Girlboss community <3.`
  },
  {
    name: 'Monique Partington',
    photo: Monique,
    title: 'Media Pro turned Aspiring Baker',
    testimonial: 'The Girlboss community provides me a great deal of support from women in different stages in life and business. It motivates me to actually put the fear aside [...] and truly makes me believe that anything is possible.'
  }
];

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      savedName: '',
      [EMAIL_ADDRESS]: '',
      savedEmail: '',
      errors: [],
      showError: false,
      appearMessage: false,
      showVideo: false
    }
  }

  componentWillMount() {
    if (!this.props.totalSubscriberCount) {
      this.props.cmStats();
    }
  }
  
  componentDidMount () {
    if (this.props.location.search.indexOf('refer-a-friend') !== -1) {
      this.handleReferFriend();
    }
  }

  handleChangeEmail = (val, label, isValidated, errors) => {
    this.setState({ [EMAIL_ADDRESS]: val, errors });
  }

  handleChangeName = (val, label, isValidated, errors) => {
    this.setState({ fullName: val, errors });
  }

  handleSubmitEmail = e => {
    e.preventDefault();

    if (!this.state[EMAIL_ADDRESS].length || !this.state.fullName.length) {
      return;
    }

    GTMpushDataLayerEvent({ event: GTM_WAITLIST_ADD });

    this.props.submitEmailToWaitlist({
      name: this.state.fullName,
      [EMAIL_ADDRESS]: this.state[EMAIL_ADDRESS]
    });

    this.setState({
      isSubmitted: true,
      savedEmail: this.state[EMAIL_ADDRESS],
      savedName: this.state.fullName,
      [EMAIL_ADDRESS]: '',
      fullName: '',
      appearMessage: true
    });

    setTimeout(() => {
      this.setState({ appearMessage: false });
    }, 2000);
  }

  toggleShowVideo = () => {
    this.setState({ showVideo: !this.state.showVideo });
  }

  handleReferFriend = () => {
    this.props.modalOpen({
      modal: HOMEPAGE_REFERRAL_MODAL,
      submitEmailToWaitlistReferral: this.props.submitEmailToWaitlistReferral
    });
  }

  render() {
    const { appearMessage } = this.state;
    const { totalSubscriberCount } = this.props;

    return (
      <div className="LandingPage">
        {this.state.showVideo && <HomepageVideo toggleShowVideo={this.toggleShowVideo} />}
        <section className="LandingPage__section LandingPage__section--top">
          <div className="LandingPage__section__illustration">
            <img
              src={MainHero}
              alt="Girlboss community is a place to build your network with like-minded women."
              className="LandingPage__img"
            />
          </div>
          <div className="LandingPage__section__content">
            <h1 className="LandingPage__headline--bold LandingPage__headline--top">
              The most interesting women in the world, together.
            </h1>
            <p className="LandingPage_subheadline">
              The Girlboss community is a private network of ambitious, influential women. Meet
              like-minded Girlbosses locally and across the country, and find the resources you need
              to level up. Ask questions, get answers, and share your knowledge.
            </p>
            <div className="LandingPage__containers--subscribers">
              {!!totalSubscriberCount && (
                  <h4>
                    Join <span className="subscriber-count bold">{numberWithCommas(totalSubscriberCount)}</span> other Girlbosses on the waitlist!
                  </h4>
                )
              }
            </div>
            <form className="LandingPage__form" onSubmit={this.handleSubmitEmail}>
              <div className="LandingPage__form--input">
                <Input
                  className="LandingPage__input"
                  id="waitlist-input-name"
                  displayError={false}
                  placeholder="Full Name"
                  controlledValue={this.state.fullName}
                  onChange={this.handleChangeName}
                  autoFocus
                  required
                />
                <Input
                  {...profileInputFields[EMAIL_ADDRESS]}
                  className="LandingPage__input"
                  id="waitlist-input-email"
                  displayError={false}
                  placeholder="Email Address"
                  controlledValue={this.state[EMAIL_ADDRESS]}
                  onChange={this.handleChangeEmail}
                  required
                />
              </div>
              <div className="LandingPage__form--terms">
                Joining our waitlist means you're down with our <a href={girlbossComLinks.terms} target="_blank">terms and conditions</a>
              </div>
              <Button
                theme={THEME_BLUE}
                className="LandingPage__btn"
                type="submit"
                onSubmit={this.handleSubmitEmail}
              >
                Request Invite
              </Button>
              {
                this.state.isSubmitted && (
                  <div>
                    <p className={classNames(
                        'success',
                        'msg',
                        { fadeInFromTop: appearMessage }
                      )}
                    >
                      ✨ You're on the waitlist {this.state.savedName}! ✨<br />
                    </p>
                    <p className={classNames(
                        'msg',
                        'smallText',
                        { fadeInFromTop: appearMessage }
                      )}
                    >
                      We’ll be sending beta invites in cycles over the next few months. Keep an eye out for your secret link to register and create your profile!
                    </p>
                  </div>
                )
              }
            </form>
            <Button
              theme={THEME_YELLOW}
              className="LandingPage__btn"
              onClick={this.handleReferFriend}
            >
              Refer a Friend
            </Button>
          </div>
        </section>
        <section className="LandingPage__section--flex">
          <div
            style={{ backgroundImage: `url('${VideoThumbnail}')` }}
            className="LandingPage__container--video"
            onClick={this.toggleShowVideo}
          >
            <PlayButton />
          </div>
        </section>
        <section className="LandingPage__section--flex">
          <div className="LandingPage__section__text">
            <h2 className="LandingPage__headline--bold LandingPage__headline LandingPage__headline--center LandingPage__headline--2xl">
              Meet Our Founding Members
            </h2>
            <p>Ambitious women changing the world, together.</p>
          </div>
          <div className="LandingPage__section__leaders">
            {gridLeaders.map(gridLeader => (
              <GridLeader
                key={gridLeader.name}
                photo={gridLeader.photo}
                photoSize={SIZE_LARGE}
                name={gridLeader.name}
                jobTitle={gridLeader.jobTitle}
              />
            ))}
          </div>
        </section>
        <section className="LandingPage__section--flex">
          <div className="LandingPage__section__text">
            <h2 className="LandingPage__headline--bold LandingPage__headline LandingPage__headline--center LandingPage__headline--2xl">
              Love From Our Happy Members
            </h2>
          </div>
          <div className="LandingPage__section__testimonials">
            {testimonialItems.map(testimonial => (
              <TestimonialItem
                key={testimonial.name}
                photo={testimonial.photo}
                name={testimonial.name}
                title={testimonial.title}
                testimonial={testimonial.testimonial}
              />
            ))}
          </div>
        </section>
        <section className="LandingPage__welcome">
          <div className="LandingPage__section__sophia">
            <img
              src={Sophia}
              alt="Sophia Amoruso"
              className="LandingPage__sophia__image"
            />
          </div>
          <div className="LandingPage__welcome__content">
            <h1 className="LandingPage__headline--bold LandingPage__welcome__header">
              Welcome to the Girlboss community!
            </h1>
            <div className="LandingPage_content">
              <p className="LandingPage_content_copy_1 LandingPage__small">
                You’re invited to the good side of the internet. We’re an ecosystem of content,
                advice, support, tools, and resources, made by and for self-identified ambitious
                women. We’re here to grow, to learn, to fail, and to thrive – together. This is a
                place where ambition is mandatory, not knowing is celebrated, and the power of women
                shakes the keyboard.
              </p>
              <p className="LandingPage_content_copy_2 LandingPage__small">
                And I’m Sophia. I may have written the book on how to be a Girlboss, but the truth
                is that I’ve still got my training wheels on. We built Girlboss community for
                people in progress; the woman who is striving, learning, and making mistakes. This
                is the platform I wish I had when I was a young founder, because not knowing is a
                lonely place. But with Girlboss community, none of us have to stand alone.
              </p>
              <img
                src={Signature}
                alt="Sophia Amoruso's signature"
                className="LandingPage__signature__img"
              />
              <p>Sophia Amoruso</p>
              <p>Co-Founder & CEO, Girlboss</p>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = ({ App }) => {
  return {
    isSubmittingEmail: App.isSubmittingEmail,
    emailPayload: App.emailPayload,
    emailWaitlistError: App.emailWaitlistError,
    totalSubscriberCount: App.totalSubscriberCount
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitEmailToWaitlist: (args) => dispatch(submitEmailToWaitlist(args)),
    submitEmailToWaitlistReferral: (args) => dispatch(submitEmailToWaitlistReferral(args)),
    cmStats: () => dispatch(cmStats()),
    modalOpen: (args) => dispatch(modalOpen(args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
