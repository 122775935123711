import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../common/Input';
import profileInputFields, {
  EMAIL_ADDRESS
} from '../../../common/Input/CommonFields/profile';

import SettingsBlock from '../SettingsBlock';
import SettingsItem from '../SettingsBlock/SettingsItem';

import '../Settings.scss';

class EmailSettings extends Component {
  static defaultProps = {
    email: ''
  };

  static propTypes = {
    onEmailChange: PropTypes.func.isRequired,
    email: PropTypes.string
  };

  handleEmailChange = (value, label, isValidated, errors) => {
    this.props.onEmailChange(value, label, errors);
  }

  render() {
    return (
      <SettingsBlock header="Email">
        <SettingsItem
          left="Update Email Address"
          right={
            <Input
              {...profileInputFields[EMAIL_ADDRESS]}
              onChange={this.handleEmailChange}
              value={this.props.email}
              className="Settings__input"
            />
          }
        />
      </SettingsBlock>
    )
  }
}

export default EmailSettings;
