import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ProfileDescription.scss';

import InlineContentEditable from '../../../common/Input/ContentEditable/Inline';

class ProfileSentence extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    sentence: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    text: PropTypes.string,
    updateBio: PropTypes.func
  };

  handleSentenceUpdates = (newSentence) => {
    this.props.updateBio(this.props.id, newSentence);
  }

  render() {
    const { id, isEditing, label, sentence, text, placeholder, updateBio } = this.props;

    if (!isEditing && (!text || !label)) return null;

    return (
      <div data-aos="fade-up" className="about-me-row">
        {sentence} <InlineContentEditable minWidth={400} className={"about-me-entry input-full-width-mobile"} onBlur={ this.handleSentenceUpdates } placeholder={ placeholder } active={ isEditing } value={ text } maxLength={ 130 } />.
      </div>
    )
  }
}

export default ProfileSentence;
