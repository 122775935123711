import React, { PureComponent } from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Input.scss';
import TextareaAutosize from 'react-textarea-autosize';

export const INPUT_PRIMARY = 'primary';
export const INPUT_PRIMARY_PLAIN = 'primary-plain';
export const INPUT_PRIMARY_UNDERLINE = 'primary-underline';
export const INPUT_SECONDARY = 'secondary';
export const INPUT_SECONDARY_PLAIN = 'secondary-plain';

class Input extends PureComponent {
  static defaultProps = {
    autoCompleteGoogle: 'none',
    autoCorrect: 'on',
    autoFocus: false,
    className: '',
    component: 'input',
    disabled: false,
    displayError: true,
    errors: null,    
    label: null,
    maxRows: 5,
    minRows: 1,
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    placeholder: '',
    showLabel: true,
    theme: INPUT_PRIMARY,
    type: 'text'
  };

  static propTypes = {
    autoCompleteGoogle: PropTypes.string,
    autoCorrect: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    component: PropTypes.any,
    controlledValue: PropTypes.string,
    disabled: PropTypes.bool,
    displayError: PropTypes.bool,
    label: PropTypes.string,
    errors: PropTypes.array,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onEnter: PropTypes.func,
    onScroll: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    theme: PropTypes.oneOf([
        INPUT_PRIMARY,
        INPUT_PRIMARY_PLAIN,
        INPUT_PRIMARY_UNDERLINE,
        INPUT_SECONDARY,
        INPUT_SECONDARY_PLAIN
    ]),
    type: PropTypes.string,
    value: PropTypes.string,
    controlledValue: PropTypes.string,
    validate: PropTypes.func
  };

  state = {
    canShowError: false
  };

  handleOnInputChange = e => {
      const { value } = e.target;
      const {
          onChange
      } = this.props;

      if (this.props.onEnter && e.keyCode === 13) {
        this.props.onEnter();
        this.setState({ showError: true});
      } else {
        onChange(value, this.props.label);
      }      
  }

  handleOnBlur = e => {
      // only show error on escape of text field
      this.setState({ canShowError: true });
      this.props.onBlur(e.target.value, this.props.label);
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  onFocus = e => {
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }
  }

  onScroll = e => {
    if (this.props.onScroll) {
      this.props.onScroll(e);
    }
  }

  render() {
    const { 
      autoCompleteGoogle,
      autoCorrect,
      className, 
      component,
      controlledValue,
      disabled,
      displayError,
      errors,
      hasError = !!errors,
      info,
      inputMode,
      maxLength,
      maxRows,
      minLength,
      minRows,
      placeholder, 
      required,
      theme,
      type,          
      value
    } = this.props;

    let Component = component;
    let textareaProps = {};

    if (component === 'textarea') {
      Component = TextareaAutosize;

      textareaProps = {
        inputRef: this.setRef,
        maxRows,
        minRows
      };
    } 

    const shouldAutoFocus = (this.props.isMobile ? false : this.props.autoFocus);

    return (
      <React.Fragment>
        <Component 
          autoComplete={autoCompleteGoogle}
          autoCorrect={autoCorrect}
          autoFocus={shouldAutoFocus}
          className={classnames(
              'Input',
              `Input__${theme}`,
              className,
              {
                  'error': displayError && (this.state.canShowError && hasError) ? true : false,
                  'Input--textarea': component === 'textarea' ? true : false,
                  'Input--disabled': disabled 
              }
          )}
          disabled={disabled}
          inputMode={inputMode}
          minLength={minLength}
          maxLength={maxLength}
          onChange={this.handleOnInputChange}
          onKeyDown={this.handleOnInputChange}
          onFocus={this.onFocus}
          onBlur={this.handleOnBlur}
          onScroll={this.onScroll}
          placeholder={placeholder}
          required={required}
          type={type}
          value={controlledValue !== undefined ? controlledValue : value}
          ref={component !== 'textarea' ? this.setRef : null}
          {...textareaProps}
        />
        <div className="Input__footer">
          <div>
            {this.state.canShowError && displayError && errors && errors.map((e, i) => (
              <p className="Input__error" key={i}>
                {typeof e === 'string' ? e : e.message}
              </p>
            ))}
          </div>
          { info && <span className="Input__footer--info">{info}</span>}
        </div>
        
      </React.Fragment>
    )
  }
}

export default Input;