import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI, {fetchAnyAPI} from '../../services/APIService';
import {
  PROFILE_GET,
  profileGetSuccess,
  profileGetError,
  profileGetComplete,
  HOROSCOPES_GET,
  horoscopesGetSuccess,
  horoscopesGetError
} from './actions';
import { AIRTABLE_API_KEY } from '../../config';

function* profileSagaGet(action) {
  let payload = { username: action.payload };
  const response = yield call(fetchAPI, 'getProfile', payload);
  if (response && response.success) {
    yield put(profileGetSuccess(response));
  } else {
    // yield put(profileGetError(response));
    if (action.history) {
      action.history.push('/invalid-user');
    }
  }

  yield put(profileGetComplete());
};

let tz;
try {
  tz = `'${Intl.DateTimeFormat().resolvedOptions().timeZone}'`;
} catch(e) {
  tz = 'America/Los Angeles';
}

function* horoscopesSagaGet(action) {
  const formula = encodeURIComponent(`
  AND(
    DATETIME_DIFF(
    DATETIME_FORMAT(
    SET_TIMEZONE(
    {Date} , ${tz}
    ), 'MM/DD/YYYY HH:mm'
    ), TODAY(), 'days') > -15,
      DATETIME_DIFF(DATETIME_FORMAT(SET_TIMEZONE({Date} , ${tz}), 'MM/DD/YYYY HH:mm'), TODAY(), 'days') <= 1
    )
  `);

  const response = yield call(
    fetchAnyAPI,
    `https://api.airtable.com/v0/appK2cbvQQA1VaKIQ/Horoscopes?api_key=${AIRTABLE_API_KEY}&filterByFormula=` + formula + '&sort%5B0%5D%5Bfield%5D=Date&sort%5B0%5D%5Bdirection%5D=desc'
  );

  if (response && response.records && response.records[0]) {
    yield put(horoscopesGetSuccess(response.records));
  } else {
    yield put(horoscopesGetError(response));
  }
}

export const profileSagas = [
  takeEvery(PROFILE_GET, profileSagaGet),
  takeEvery(HOROSCOPES_GET, horoscopesSagaGet)
];
