import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserActions from '../../containers/UserActions';
import Button, { THEME_GRAY, THEME_BLUE } from '../../common/Button';

import { checkAuth } from '../../utils/checkAuth';
import {
  PRIMARY_REACTION_TYPE,
  getActivityIcon,
  reactionTypes
} from '../../store/Legion/logic';
import {
  GTMpushDataLayerEvent,
  GTM_COMMENT_POST_BUTTON,
  GTM_POST_PRAISE,
  GTM_POST_UNPRAISE
} from '../../lib/GoogleTagManager';

import chatIcon from '../../../public/assets/icons/CHAT.svg';
import praiseIcon from '../../../public/assets/icons/PRAISE.svg';

import './ReactAndCommentButtons.scss';

class ReactAndCommentButtons extends Component {
  static propTypes = {
    reactionCount: PropTypes.number,
    commentCount: PropTypes.number,
    isReacted: PropTypes.bool.isRequired,
    onReact: PropTypes.func.isRequired,
    onComment: PropTypes.func,
    variant: PropTypes.oneOf(['thread', 'comment', 'feed']),
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    isReacted: false
  };

  getReactionText = () => {
    const action = reactionTypes[PRIMARY_REACTION_TYPE].action;
    const reaction = reactionTypes[PRIMARY_REACTION_TYPE].reaction;

    if (this.props.isLoading) {
      return '...';
    }

    const text = this.props.isReacted ? reaction : action;
    return `${text.slice(0, 1).toUpperCase()}${text.slice(1).toLowerCase()}`;
  }

  onReact = () => {
    this.props.onReact();

    GTMpushDataLayerEvent({
      event: this.props.isReacted ? GTM_POST_UNPRAISE : GTM_POST_PRAISE,
      referrer: this.props.variant === 'thread' ? 'feed' : 'thread'
    })
  }

  onComment = checkAuth(() => {
    this.props.onComment();

    GTMpushDataLayerEvent({
      event: GTM_COMMENT_POST_BUTTON,
      referrer: this.props.variant === 'thread' ? 'feed' : 'thread'
    });
  })

  render() {
    const { 
      actions,
      commentCount, 
      contentId,
      currentUserId,
      flagged,
      isLoggedIn,
      isReacted, 
      onComment,
      reactionCount, 
      route,
      type, 
      userId,
      variant, 
    } = this.props;

    const isOnThread = (variant === 'thread');
    // const routeLink = !isOnThread ? route : ''; 
    const routeLink = route;

    let replyText;
    if(commentCount < 1) {
      replyText = 'Reply';
    } else if(commentCount === 1) {
      replyText = 'reply';
    } else {
      replyText = 'replies';
    };

    return (
      <div className="ReactAndCommentButtons__container">
        <Button
          component={Link}
          pill 
          noOutline 
          onClick={onComment ? this.onComment : () => {}}
          to={routeLink}
          theme={THEME_GRAY}
          className="ReactAndCommentButtons__reaction"
        >
            <img src={chatIcon} style={{width: '27px', top: '1px'}} />
            <span>{commentCount > 0 ? commentCount : null} {replyText}</span>
        </Button>

        <Button 
          pill 
          noOutline 
          onClick={this.onReact} 
          theme={isReacted ? THEME_BLUE : THEME_GRAY}
          className={classnames("ReactAndCommentButtons__reaction", {'reacted': isReacted})}
        >
        {
          reactionCount > 0 ? 
          <React.Fragment>
            <img src={praiseIcon} width="19" style={{marginRight: '5px', top: '-1px'}} /> 
            {reactionCount}
          </React.Fragment> :
          <img src={praiseIcon} width="19" style={{top: '-1px'}} />
        }
        </Button>
        
        {/* <span className="ReactAndCommentButtons__timestamp">{timestamp}</span> */}

        { isLoggedIn && (
          <div className="ReactAndCommentButtons__flag">
            <UserActions
              userId={userId}
              typeId={contentId}
              type={type}
              doesBelongToUser={currentUserId === userId}
              {...actions}
              flag
              flagged={flagged}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ReactAndCommentButtons;
