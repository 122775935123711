import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  FIRST_NAME,
  HEADLINE,
  INDUSTRY,
  LAST_NAME,
  LOCATION,
  PROFILE_PHOTO,
  USERNAME,
  ID,
  BIO,
  I_AM,
  IM_GOOD_AT,
  I_WORK_AT
} from '../../common/Input/CommonFields/profile';

import AccountNotFound from '../../components/AccountNotFound';

import MyProfile from './MyProfile';
import UserProfile from './UserProfile';

import './Profile.scss';

class Profile extends Component {
  static propTypes = {
    user: PropTypes.shape({
      [ID]: PropTypes.number,
      [FIRST_NAME]: PropTypes.string,
      [LAST_NAME]: PropTypes.string,
      [LOCATION]: PropTypes.string,
      [HEADLINE]: PropTypes.string,
      [INDUSTRY]: PropTypes.string,
      [BIO]: PropTypes.shape({
        [I_AM]: PropTypes.string,
        [IM_GOOD_AT]: PropTypes.string,
        [I_WORK_AT]: PropTypes.string
      })
    })
  };

  render() {
    if (this.props.user && this.props.user.username == this.props.currentUser.username) {
      return (
        <MyProfile
          {...this.props}
        />
      )
    } else {
      if (!this.props.user) return <AccountNotFound />
      return <UserProfile {...this.props} />;
    }
  }
};

const mapStateToProps = ({ User }) => {
  return {
    currentUser: User.user
  }
}

export default connect(mapStateToProps)(Profile);
