import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Button, {
  THEME_GRAY
} from '../../common/Button';
import routes from '../../config/routes';

import Laptop from '../../../public/assets/illustrations/laptop.png';

import './NotFound.scss';

class NotFound extends PureComponent {
  render() {
    return (
      <div className="NotFound">
        <h1 className="NotFound__header">404</h1>
        <h3 className="NotFound__text">
            Can't seem to find the page you're looking for.
        </h3>
        <Button
          component={Link}
          to={routes.root}
          className="NotFound__button"
          theme={THEME_GRAY}
          pill
        >
          <span className="NotFound__button--arrow"></span> Back
        </Button>
      </div>
    )
  }
}

export default NotFound;
