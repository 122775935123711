import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import memberSearchIcon from '../../../public/assets/icons/MEMBER-SEARCH.svg';

import './NoResults.scss';

class NoResults extends PureComponent {
  render() {
    const { heading, subheading } = this.props;
    return (
      <div className="NoResults">
        <SVG src={memberSearchIcon} />
        
        <h2>{heading}</h2>
        <p>{subheading}</p>
      </div>
    )
  }
}

export default NoResults;
