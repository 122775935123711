/**
  Profile Get
**/
export const PROFILE_GET = 'PROFILE_GET';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_ERROR = 'PROFILE_GET_ERROR';
export const PROFILE_GET_COMPLETE = 'PROFILE_GET_COMPLETE';

export const profileGet = (payload, history) => {
  return {
    type: PROFILE_GET,
    payload,
    history
  }
};

export const profileGetSuccess = payload => {
  return {
    type: PROFILE_GET_SUCCESS,
    payload     
  }
};

export const profileGetError = error => {
  return {
    type: PROFILE_GET_ERROR,
    error
  }
};

export const profileGetComplete = () => {
  return {
    type: PROFILE_GET_COMPLETE
  }
};

/*
  Daily Horoscopes
*/

export const HOROSCOPES_GET = 'HOROSCOPES_GET';
export const HOROSCOPES_GET_SUCCESS = 'HOROSCOPES_GET_SUCCESS';
export const HOROSCOPES_GET_ERROR = 'HOROSCOPES_GET_ERROR';

export const horoscopesGet = payload => {
  return {
    type: HOROSCOPES_GET,
    payload
  }
};

export const horoscopesGetSuccess = payload => {
  return {
    type: HOROSCOPES_GET_SUCCESS,
    payload     
  }
};

export const horoscopesGetError = error => {
  return {
    type: HOROSCOPES_GET_ERROR,
    error
  }
};