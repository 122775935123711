import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextWidthCalculator from './TextWidthCalculator';

class ContentEditableInline extends Component {
  static propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    maxLen: PropTypes.number,
    onBlur: PropTypes.func,
    label: PropTypes.string,
    minWidth: PropTypes.number,
    minWidthMobile: PropTypes.number,
    onChange: PropTypes.func
  };

  static defaultProps = {
    active: true
  };

  constructor(props) {
    super(props);
    if (props.value) {
      this.state = {value:props.value};
    } else {
      this.state = {value:''};
    }

    this.inputRef = React.createRef();
  }

  handleChange = (e) => {

    const { onBlur, onChange, label } = this.props;

    this.setState({value: e.target.value});
    if (label) {
      let res = {};
      res[label] = e.target.value;
      if (onBlur) {
        onBlur(res);
      }
    } else {
      if (onBlur) {
        onBlur(e.target.value);
      }
    }
    if (onChange) {
      onChange(e.target.value);
    }
  }

  changeDimensions = (newWidth, newHeight) => {
    let newState = this.state;
    newState.calculatedWidth = (newWidth + 10) + "px";
    newState.calculatedHeight = (newHeight + 4) + "px";
    this.setState(newState);
  }

  render() {

    const { active, placeholder, value, className, minWidth, minWidthMobile, maxLength } = this.props;

    const actualWidth = this.state.calculatedWidth != undefined ? this.state.calculatedWidth : 'auto';
    const actualHeight = this.state.calculatedHeight != undefined ? this.state.calculatedHeight : '1.2em';

    return (
      <span>
        <TextWidthCalculator
          text={this.props.value || this.state.value}
          handleChange={this.changeDimensions}
          minWidth={minWidth}
          minWidthMobile={minWidthMobile}
          {...this.props}
        />
        { !active && (
          <span
            className={ className }
            placeholder={ placeholder }
          >
            { value }
          </span>
        ) }
        { active && (
          <textarea
            style={{width:actualWidth, height:actualHeight, borderLeft: 'none', borderTop: 'none', borderRight: 'none', padding: '0'}}
            className={
              classNames(
                className,
                'inline'
              )
            }
            onChange={ this.handleChange }
            placeholder={ placeholder }
            value={ this.props.value || this.state.value }
            maxLength={ maxLength }
            type="email" 
            autocorrect="off" 
            autocapitalize="none"
          >
            { value }
          </textarea>
        ) }
      </span>
    )
  }
}

export default ContentEditableInline;
