import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button, { THEME_BLACK, THEME_WHITE } from '../../../common/Button';
import { TITLE, BODY } from '../../../common/Input/CommonFields/post';
import { postCheck, post, postEdit } from '../../../store/Post/actions';
import { selectRequest } from '../../../store/requests';
import { resetRequest } from '../../../lib/reduxRequests';
import { apiErrorMessage } from '../../../lib/API';
import NewPostForm from '../../../components/Forms/NewPostForm';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../ModalContainer';
import '../../Post/Post.scss';

class PostModal extends Component {
  static propTypes = {
    [TITLE]: PropTypes.string,
    [BODY]: PropTypes.string,
    id: PropTypes.number,
    topics: PropTypes.array
  };

  static defaultProps = {
    [TITLE]: '',
    [BODY]: '',
    id: null,
  };

  state = {};

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  submitPost = postData => {
    this.setState({ postData }, this.checkPost);
  }

  checkPost = () => {
    const { postData } = this.state;
    this.props.postCheck({ post_id: this.props.id, title: postData.title, body: postData.body });
  }

  savePost = () => {
    const { postData } = this.state;

    if (this.props.id) {
      this.props.postEdit(
        this.props.id,
        postData
      )
    } else {
      postData.collective_id = this.props.collectiveId;
      this.props.submitPost({body: postData, updatePosts: true});
    }
  }

  resetCheckRequest = () => {
    this.props.resetRequest(postCheck);
  }

  getFormData = () => {
    this.postForm.submit();
  }

  componentDidUpdate(prevProps) {
    const { checkRequest, checkWarning, isLoading } = this.props;
    if (checkRequest !== prevProps.checkRequest && checkRequest.fulfilled && !checkWarning) {
      this.savePost();
    } else if (!isLoading && prevProps.isLoading) {
      this.props.close();
    }
  }

  componentWillUnmount() {
    this.resetCheckRequest();
  }

  renderOverlay() {
    const { checkRequest, checkWarning, isLoading } = this.props;

    if (checkRequest.fulfilled && checkWarning) {
      return (
        <div className="PostModal__overlay">
          <div className="PostModal__overlay-body">
            <h2>Have you posted this before?</h2>
            <p>This looks very similar to one of your recent posts. Spam is against our <a href="/acceptable-use" target="_blank">Acceptable Use Policy</a>. Please ensure your posts are fresh and relevant!</p>
          </div>
          <div className="PostModal__overlay-footer">
            <Button
              className="PostFocus__button--submit"
              theme={THEME_WHITE}
              isLoading={isLoading}
              onClick={this.savePost}>
              My post is ok as is
            </Button>
            <Button
              className="PostFocus__button--submit"
              theme={THEME_BLACK}
              disabled={isLoading}
              onClick={this.resetCheckRequest}>
              Edit Post
            </Button>
          </div>
        </div>
      );
    }

    if (checkRequest.error) {
      if (checkRequest.error.status === 403) {
        return (
          <div className="PostModal__overlay">
            <div className="PostModal__overlay-body">
              <h2>Your post was blocked</h2>
              <p>We noticed that you’ve posted similar content multiple times. Spam is against our <a href="/acceptable-use" target="_blank">Acceptable Use Policy</a>. Please ensure your posts are fresh and relevant!</p>
            </div>
            <div className="PostModal__overlay-footer">
              <Button
                className="PostFocus__button--submit"
                theme={THEME_WHITE}
                onClick={this.props.close}>
                Cancel
              </Button>
              <Button
                className="PostFocus__button--submit"
                theme={THEME_BLACK}
                onClick={this.resetCheckRequest}>
                Edit Post
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <div className="PostModal__overlay">
            <div className="PostModal__overlay-body">
              <h2>{apiErrorMessage(checkRequest.error)}</h2>
            </div>
            <div className="PostModal__overlay-footer">
              <Button
                className="PostFocus__button--submit"
                theme={THEME_BLACK}
                isLoading={checkRequest.pending}
                onClick={this.checkPost}>
                Try Again
              </Button>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    const { id, setRef, close, collectiveName, checkRequest } = this.props;

    return (
      <ModalContainer className="PostModal" setRef={setRef} close={close}>
        {this.props.children}

        <ModalHeader header={id ? 'Edit your post' : 'New Post'} />

        <ModalBody>
          {collectiveName && <p className="PostModal__collective-line">Posting in <span>{collectiveName}</span></p>}
          <NewPostForm
            close={this.props.close}
            setRef={this.props.setRef}
            submitPost={this.submitPost}
            body={this.props[BODY]}
            title={this.props[TITLE]}
            topics={this.props.topics}
            onRef={ref => (this.postForm = ref)}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            className="PostFocus__button--submit"
            theme={THEME_BLACK}
            onSubmit={this.getFormData}
            onClick={this.getFormData}
            isLoading={checkRequest.pending || this.props.isLoading}
          >
            { id ? 'Edit Post' : 'Post' }
          </Button>
        </ModalFooter>

        {this.renderOverlay()}
      </ModalContainer>
    )
  }
}

const mapStateToProps = state => {
  const { Post } = state;
  return {
    isLoading: Post.isLoading,
    errors: Post.errors,
    checkWarning: Post.checkWarning,
    checkRequest: selectRequest(state, postCheck)
  }
}

export default connect(mapStateToProps, {
  postCheck,
  submitPost: post,
  postEdit,
  resetRequest
})(PostModal);
