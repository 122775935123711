import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Input from '../../common/Input';
import ContentEditable from '../../common/Input/ContentEditable/Inline';
import InputWithError from '../../common/Input/InputWithError';

import './Onboarding.scss';

class OnboardingCountryInternational extends PureComponent {
  static propTypes = {

  };
   
  render() {

    const {
      emailAddress
    } = this.props;

    return (
      <div className="Onboarding__page-container-username">
        <div style={{textAlign: 'center'}}>
          <h2 className="Onboarding__heading">We're not in your country - yet!</h2>
          <h4 className="Onboarding__subheading">We're working hard to expand beyond the US. Confirm your email and we’ll let you know as soon as we’re ready for you.</h4>
          <h2 className="Onboarding__heading">{emailAddress}</h2>
        </div>
      </div>
    )
  }
}

export default OnboardingCountryInternational;
