import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProfileField from './ProfileField';
import { MONTHS, PAST_PRESENT_YEARS } from '../../common/Dropdown/CommonFields';

class JourneyCardEditingGirlbossMoment extends Component {
  static propTypes = {
    card: PropTypes.object
  };

  constructor (props) {
    super(props);
  }

  render() {
    const {
      children,
      card,
      errors,
      enableEditing,
      editing,
      updateCard,
      updateCardData,
      updateStartDate,
      updateEndDate
    } = this.props;

    if (!card || !card.card_data) return null;

    return (
      <React.Fragment>
        <div onClick={enableEditing} className="JourneyCardEditing__default JourneyCardEditing__moment">
          <h4>Girlboss Moment</h4>
          {card.card_data.title}
        </div>
        {
          (editing) && (
            <div className="JourneyCardEditing__editing">
              {children}
              <ProfileField
                label="Title"
                field={'title'}
                data={card.card_data}
                placeholder="Did something awesome"
                update={updateCardData}
                error={errors.title}
                errorMessage={'Title cannot be blank'}
                required
              />
              <div className="ProfileEditing__columns">
                <ProfileField
                  label="Month"
                  field={'month'}
                  data={card.start_date}
                  placeholder="January"
                  update={updateStartDate}
                  options={MONTHS}
                />
                <ProfileField
                  label="Year"
                  field={'year'}
                  data={card.start_date}
                  placeholder="2019"
                  update={updateStartDate}
                  options={PAST_PRESENT_YEARS}
                />
              </div>
              <ProfileField
                label="Location"
                field={'location'}
                data={card}
                placeholder="Los Angeles"
                update={updateCard}
              />
              <ProfileField
                label="Description"
                field={'description'}
                data={card}
                placeholder="Here's what I did that was awesome..."
                update={updateCard}
              />
            </div>
          )
        }
      </React.Fragment>
    )
  }
};

export default JourneyCardEditingGirlbossMoment;