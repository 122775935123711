import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectRequest } from '../../../store/requests';
import {
  activityGet
} from '../../../store/Activity/actions';

import { ModalHeader, ModalContainer, ModalBody } from '../ModalContainer';
import ActivityFeed from '../../ActivityFeed';

import './ActivityFeedModal.scss';

class ActivityFeedModal extends Component {
  static defaultProps = {
    loadMore: () => {},
    hasLoadedAllActivity: true,
    offset: 0
  }

  static propTypes = {
    hasLoadedAllActivity: PropTypes.bool,
    offset: PropTypes.number
  };

  setListRef = ref => {
    this.listRef = ref;
    this.forceUpdate();
  }

  constructor(props) {
    super(props);
  }

  render() {
    const {
      close,
      clear,
      loadMore,
      setRef,
      header,
      hasLoadedAllActivity,
      offset,
      activities,
      user,
      request
    } = this.props;

    return (
      <ModalContainer
        className="ActivityFeedModal"
        modalContainerClassName="ActivityFeed__container"
        setRef={setRef}
      >
        {this.props.children}

        <ModalHeader header={header} />

        <ModalBody setRef={this.setListRef}>
          <ActivityFeed
            activities={activities}
            user={user}
            hasLoadedAllActivity={hasLoadedAllActivity}
            request={request}
            offset={offset}
            clear={clear}
            loadMore={loadMore}
            close={close}
            listRef={this.listRef}
          />
        </ModalBody>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  activities: state.Activity.activity,
  hasLoadedAllActivity: state.Activity.hasLoadedAllActivity,
  request: selectRequest(state, activityGet)
});

export default connect(mapStateToProps)(ActivityFeedModal);
