import { takeEvery, select, put } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';
import uuidv4 from 'uuid/v4';

import { LOCATION_GET, locationGetSession, locationSessionTokenSet, LOCATION_SELECTED_GET } from './actions';
import { resetRequestType } from '../../lib/reduxRequests';
import { selectRequest } from '../requests';

function* locationSagaGet(action) {
  const { query = '' } = action.payload;
  let effect;

  if (query.trim()) {
    const { key } = action.request;
    let session = yield select(locationGetSession);

    if (!session) {
      session = uuidv4();
      yield put(locationSessionTokenSet(session));
    }

    effect = callAPI('locations', { query, session, key });
  } else {
    effect = { data: [] };
  }

  yield sagaRequest(action, effect);
};

function* selectLocationSaga(action) {
  const { key } = action.request;
  const { placeId, session } = action.payload;
  if (placeId && session) {
    yield sagaRequest(action, callAPI('selectLocation', {
      placeId,
      session,
      key
    }));
  }
}

export const locationSagas = [
  takeEvery(LOCATION_GET, locationSagaGet),
  takeEvery(LOCATION_SELECTED_GET, selectLocationSaga)
];
