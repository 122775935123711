import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ModalContainer, ModalHeader, ModalBody } from '../ModalContainer';
import ModalDialog from '../ModalTemplates/ModalDialog';
import InfiniteScroller from'../../../components/InfiniteScroller';
import CollectivesListItem from '../../Collectives/CollectivesList/CollectivesListItem';

import { selectRequest } from '../../../store/requests';
import { collectiveMemberGet, collectiveMemberAdd, collectiveMemberRemove } from '../../../store/Collectives/actions';
import { modalClose } from '../../../store/Modal/actions';

import './CollectivesListModal.scss';

class CollectivesListModal extends Component {
  static defaultProps = {
    userList: 'members',
    loadMore: () => {}
  }

  static propTypes = {
    hasLoadedAllUsers: PropTypes.bool,
    offset: PropTypes.number,
    userList: PropTypes.string,
    targetUser: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.listRef = null;
  }

  setListRef = ref => {
    this.listRef = ref;
    this.forceUpdate();
  }

  loadMoreCollectives = () => {
    this.props.loadMore({
      userId: this.props.targetUser ? this.props.targetUser.id : this.props.user.id,
      limit: 10,
      offset: this.props.offset
    });
  }

  renderCollectives = () => {
    const { userCollectives, listType, user, modalClose, noSubscription } = this.props;

    const collectivesList = [];

    for(let collective in userCollectives) {
      collectivesList.push(
        <CollectivesListItem 
          key={userCollectives[collective].id} 
          collective={userCollectives[collective]} 
          user={user} 
          listType={listType}
          showAvatar
          isMember={listType === 'user'}
          modalClose={modalClose}
          noSubscription={noSubscription}
        />
      );
    }

    return collectivesList;
  }

  render() {
    const {
      close,
      setRef,
      loadMore,
      header,
      userCollectivesRequest,
      hasLoadedAllCollectives
    } = this.props;

    return (
      <ModalContainer
        setRef={this.setRef}
        close={close}
        setRef={setRef}
        className="CollectivesListModal"
      >
        {this.props.children}

        <ModalHeader header={header} />

        <ModalBody setRef={this.setListRef}>
          <div className="CollectivesListModal__container">
            <InfiniteScroller
              shouldLoadMore={!userCollectivesRequest.pending && !hasLoadedAllCollectives}
              hasLoadedAll={hasLoadedAllCollectives}
              loadMore={this.loadMoreCollectives}
              componentRef={this.listRef}
              className="CollectivesListModal__container--div"
            >
              {this.renderCollectives()}
              {userCollectivesRequest.pending && !hasLoadedAllCollectives ? <span>loading...</span> : null}
            </InfiniteScroller>
          </div>
        </ModalBody>
      </ModalContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
    userCollectivesRequest: selectRequest(state, collectiveMemberGet),
    userCollectives: state.Collectives.userCollectives,
    offset: state.Collectives.offset,
    hasLoadedAllCollectives: state.Collectives.hasLoadedAllCollectives
  }
}

export default connect(mapStateToProps, { collectiveMemberAdd, collectiveMemberRemove, modalClose })(CollectivesListModal);
