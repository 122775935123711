import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  PASSWORD_RESET_REQUEST,
  passwordResetRequestSuccess,
  passwordResetRequestError,
  PASSWORD_RESET,
  passwordResetSuccess,
  passwordResetError
} from './actions';
import { toasterOpen, TOASTER_TYPE_SUCCESS } from '../Toaster/actions';

function* passwordResetRequestSaga(action) {
  const response = yield call(fetchAPI, 'passwordResetRequest', {
    body: action.payload
  });

  if (response && response.success) {
    action.callback(true);
    yield put(toasterOpen({
      type: TOASTER_TYPE_SUCCESS,
      content: 'Email sent!',
      timeout: 5000
    }));
    yield put(passwordResetRequestSuccess(response));
  } else {
    action.callback(false);
    yield put(passwordResetRequestError(response));
  }
};

function* passwordResetSaga(action) {
  const response = yield call(fetchAPI, 'resetPassword', {
    body: action.payload,
    token: action.token
  });

  if (response && response.success) {
    action.callback(true);
    yield put(passwordResetSuccess(response));
  } else {
    action.callback(false);
    yield put(passwordResetError(response));
  }
};

export const passwordResetSagas = [
  takeEvery(PASSWORD_RESET_REQUEST, passwordResetRequestSaga),
  takeEvery(PASSWORD_RESET, passwordResetSaga)
];
