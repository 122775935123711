import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SVG from 'react-inlinesvg';
import { selectRequest } from '../../../store/requests';
import { collectiveMemberGet } from '../../../store/Collectives/actions';
import { connectionsGet } from '../../../store/Connections/actions';
import { debounce } from 'lodash';

import { ModalContainer, ModalHeader, ModalBody} from '../ModalContainer';
import UserList from '../../../components/UserList';
import InputWithIcon from '../../../common/Input/InputWithIcon';
import { INPUT_SECONDARY_PLAIN } from '../../../common/Input';
import searchIcon from '../../../../public/assets/icons/SEARCH.svg';

import './UserListModal.scss';
import Button, { THEME_GRAY, THEME_CLEAR } from '../../../common/Button';
import { admirersGet } from '../../../store/Admires/actions';

class NetworkModal extends Component {
  static defaultProps = {
    loadMore: () => {},
    hasLoadedAllUsers: true,
    offset: 0
  }

  static propTypes = {
    hasLoadedAllUsers: PropTypes.bool,
    offset: PropTypes.number,
    userList: PropTypes.string,
    currentOption: PropTypes.string,
    options: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      search:'',
      currentOption:props.currentOption
    }
    this.debouncedLoad = debounce(this.loadMore, 300);
    this.bodyRef = null;
  }

  search = (search) => {
    const { options } = this.props;
    const { currentOption } = this.state;
    const option = options[currentOption];
    if (this.state.search != search) {
      this.setState({search}, () => {
        option.clear();
        this.debouncedLoad();
      });
    }
  }

  loadMore = () => {
    const { options } = this.props;
    const { currentOption } = this.state;
    const option = options[currentOption];
    option.loadMore(this.state.search);
  }

  setBodyRef = ref => {
    this.bodyRef = ref;
    this.forceUpdate();
  }

  setCurrentOption = currentOption => {
    if (currentOption !== this.state.currentOption) {
      const { options } = this.props;
      const { search } = this.state;
      const option = options[currentOption];
      option.clear();
      this.setState({currentOption}, () => {
        option.loadMore(search);
      });
    }
  }

  render() {
    let {
      close,
      setRef,
      canSearch,
      header,
      options
    } = this.props;
    const { currentOption } = this.state;
    
    const option = options[currentOption];

    return (
      <ModalContainer
        close={close}
        setRef={setRef}
        className="UserListModal"
      >
        {this.props.children}

        <ModalHeader header={header ? header : ''}>
          {
            canSearch && (
              <div style={{marginTop:'30px'}}>
                <InputWithIcon 
                  addBefore={<SVG src={searchIcon} />}
                  autoCorrect={'off'}
                  onChange={this.search}
                  placeholder="Search connections"
                  theme={INPUT_SECONDARY_PLAIN}
                  value={this.state.search}
                />
              </div>
            )
          }
        </ModalHeader>

        <ModalBody setRef={this.setBodyRef}>
          <div className="UserListModal__options">
            {
              Object.keys(options).map(key => (
                <Button
                  key={options[key].label}
                  tab
                  autoWidth
                  theme={currentOption === key ? THEME_GRAY : THEME_CLEAR}
                  onClick={() => {this.setCurrentOption(key)}}
                >
                  {options[key].label}
                </Button>
              ))
            }
          </div>
          <UserList
            users={option.users} 
            hasLoadedAllUsers={option.hasLoadedAllUsers}
            offset={option.offset}
            canSearch={option.canSearch}
            clear={option.clear}
            loadMore={this.loadMore}
            debounceLoad={this.debouncedLoad}
            request={option.request}
            adminIds={option.adminIds}
            searchValue={this.state.search}
            listRef={this.bodyRef}
          />
        </ModalBody>
      </ModalContainer>
    )
  }
}

export const NetworkList = connect((state, props) => {
  const connectionData = {
    users:state.Connections.connections,
    hasLoadedAllUsers: state.Connections.hasLoadedAllConnections,
    offset: state.Connections.offset,
    request: selectRequest(state, connectionsGet)
  }

  const admirerData = {
    users:state.Admires.admirers,
    hasLoadedAllUsers: state.Admires.hasLoadedAllAdmirers,
    offset: state.Admires.offset,
    request: selectRequest(state, admirersGet)
  }
  
  let options = {...props.options};
  options.connections = {...options.connections, ...connectionData};
  options.admirers = {...options.admirers, ...admirerData};

  return {
    options
  }
})(NetworkModal);