/**
 * Import this file to access config variables.
 * The `preval` macro evaluates `variables.js` and writes
 * environment-specific config variables to this file at build time.
 */
module.exports = preval`
  require('dotenv').config();
  const { env } = process;
  const { CONFIG, ENV_VARIABLES } = require('./variables');

  // Merge defaults with the current environment's config.
  const current = { ...CONFIG.defaults, ...CONFIG[env.NODE_ENV || 'development'] };

  // Override config with environment variables.
  for (let key of Object.keys(ENV_VARIABLES)) {
    const envValue = env[key];
    if (envValue !== undefined) {
      switch (ENV_VARIABLES[key]) {
        case String:
          current[key] = String(envValue);
          break;
        case Number:
          current[key] = Number(envValue);
          break;
        case Boolean:
          current[key] = envValue === true || envValue === 'true' || envValue == 1;
          break;
        default:
          current[key] = envValue;
      }
    }
  }

  module.exports = current;
`;
