import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import NotificationActivity from '../../../components/Notification/NotificationActivity';
import NotificationContactRequest from '../../../components/Notification/NotificationContactRequest';
import NotificationAdmire from '../../../components/Notification/NotificationAdmire';
import NotificationInvite from '../../../components/Notification/NotificationInvite';

import routes from '../../../config/routes';

import './SideNotificationNav.scss';

export class SideNotificationNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

    this.notifNav = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      loading: false // prevent the nav from sliding out on initial load
    });
  }

  componentWillUnmount = () => {
    disableBodyScroll(this.notifNav.current);
    clearAllBodyScrollLocks();
  }

  render() {
    const { notifications, onDesktop } = this.props;

      if (this.props.open) {
        disableBodyScroll(this.notifNav.current);
      } else {
        clearAllBodyScrollLocks();
      };

    return (
      <React.Fragment>
        <div
          className={classnames(
            "overlay-container", "center-align", 'overlay-container--belowNav'
          )}
          style={{ display: this.props.open ? 'block' : 'none' }}
          onClick={() => this.props.closeNav()}
          ref={this.notifNav}
        />
        <div
          className={classnames({
            SideNotificationNav: true,
            open: this.props.open,
            onDesktop
          })}
          style={{ display: this.state.loading ? 'none' : 'block' }}
        >
          <div className="SideNotificationNav__heading">
            <h2>
              Notifications
            </h2>
          </div>

          <div 
            className="SideNotificationNav__notifications"
            ref={this.notifNav}
          >
            {
              notifications.length
                ? (
                  notifications.map(notification => (
                    ((notification.type == 'postActivity') && (
                      <NotificationActivity
                        postActivity={notification.post_activity}
                        sender={notification.sender}
                        receiver={notification.receiver}
                        postContent={notification.post_content}
                        commentContent={notification.comment_content}
                        date={notification.created_at}
                        notificationType={notification.notification_type}
                        isRead={notification.is_read}
                        key={notification.id}
                        id={notification.id}
                        closeSideNav={this.props.closeNav}
                      />
                    ))
                    ||
                    ((notification.type == 'contactRequest') && (
                      <NotificationContactRequest
                        contactRequest={notification.contact_request}
                        sender={notification.sender}
                        receiver={notification.receiver}
                        postContent={notification.post_content}
                        commentContent={notification.comment_content}
                        date={notification.created_at}
                        notificationType={notification.notification_type}
                        isRead={notification.is_read}
                        key={notification.id}
                        id={notification.id}
                        closeSideNav={this.props.closeNav}
                      />
                    ))
                    || 
                    ((notification.type == 'admire') && (
                      <NotificationAdmire
                        sender={notification.sender}
                        receiver={notification.receiver}
                        admire={notification.admire}
                        date={notification.created_at}
                        notificationType={notification.notification_type}
                        isRead={notification.is_read}
                        key={notification.id}
                        id={notification.id}
                        closeSideNav={this.props.closeNav}
                      />
                    ))
                    ||
                    ((notification.type == 'invite') && (
                      <NotificationInvite
                        sender={notification.sender}
                        date={notification.created_at}
                        notificationType={notification.notification_type}
                        invite={notification.invite.invite}
                        isRead={notification.is_read}
                        key={notification.id}
                        id={notification.id}
                        closeSideNav={this.props.closeNav}
                      />
                    ))
                  ))
                )
                : (
                  <div className="SideNotificationNav__notifications--p">
                    <p>No notifications right now...</p>
                  </div>
                )
            }
          </div>

          <div className="SideNotificationNav__see-all">
            <Link to={routes.notifications} onClick={() => this.props.closeNav()}>
              See All
            </Link>
          </div>

          <div className="close-btn" onClick={() => this.props.closeNav()} />

        </div>
      </React.Fragment>
    )
  }
}

const MapStateToProps = state => {
  return {
    notifications: state.Notifications.notifications
  }
}

export default connect(MapStateToProps)(SideNotificationNav);