import TagManager from 'react-gtm-module';

const gtmId = 'GTM-5RLBR2K';

/**
 * Initializes Google Tag Manager
 */
export const GTMinit = () => {
  TagManager.initialize({
    gtmId: process.env.NODE_ENV !== 'development' ? gtmId : ''
  });
};

/**
 * Pushes the data layer object to GTM
 * @param {object} { event: '123', user: '123' }
 */
export const GTMpushDataLayerEvent = (dataLayerVariables) => {
  TagManager.dataLayer({
    dataLayer: dataLayerVariables
  });
};

// values
export const GTM_SIGNUP_STEP1 = 'signUpStep1';
export const GTM_SIGNUP_STEP2 = 'signUpStep2';
export const GTM_SIGNUP_STEP3 = 'signUpStep3';
export const GTM_SIGNUP_SUCCESS = 'signUpSuccess';

export const GTM_SIGNUP_STARTED = 'signup_started';
export const GTM_SIGNUP_VALID_EMAIL = 'signup_valid_email';
export const GTM_SIGNUP_VALID_USERNAME = 'signup_valid_username';
export const GTM_SIGNUP_COUNTRY = 'signup_country';
export const GTM_SIGNUP_COUNTRY_INTERNATIONAL = 'signup_country_international';
export const GTM_SIGNUP_VALID_PHONE = 'signup_valid_phone';
export const GTM_SIGNUP_COMPLETED = 'signup_completed';

export const GTM_ONBOARDING_STARTED = 'onboarding_started';
export const GTM_ONBOARDING_IM_GOOD_AT = 'onboarding_good_at';
export const GTM_ONBOARDING_ID_LIKE_TO_LEARN = 'onboarding_like_to_learn';
export const GTM_ONBOARDING_ID_LIKE_TO_MEET = 'onboarding_like_to_meet';
export const GTM_ONBOARDING_LOCATION = 'onboarding_location';
export const GTM_ONBOARDING_INDUSTRY = 'onboarding_industry';
export const GTM_ONBOARDING_PHOTO = 'onboarding_photo';
export const GTM_ONBOARDING_COMPLETE = 'onboarding_completed';

export const GTM_COMMENT_ON_POST = 'comment_Post';
export const GTM_COMMENT_VIEWMORE = 'viewmore_Comment';
export const GTM_COMMENT_PRAISE = 'praiseComment';
export const GTM_COMMENT_UNPRAISE = 'unpraise_Comment';
export const GTM_COMMENT_DELETE = 'deleteComment';
export const GTM_COMMENT_EDIT = 'editComment';

export const GTM_COMMENT_POST_BUTTON = 'commentPost_button';

export const GTM_REPLY_ON_COMMENT = 'comment_Reply';
export const GTM_REPLY_VIEWMORE = 'viewmore_Reply';
export const GTM_REPLY_PRAISE = 'praise_Reply';
export const GTM_REPLY_UNPRAISE = 'unpraise_Reply';
export const GTM_REPLY_DELETE = 'deleteReply';
export const GTM_REPLY_EDIT = 'editReply';

export const GTM_REPLIES_VIEW = 'viewReplies';

export const GTM_POST_CREATE = 'createPost';
export const GTM_POST_CLICK_CREATE_FIELD = 'createPost_field_homepage';
export const GTM_POST_CLICK_CREATE_NAVBUTTON = 'createPost_button_nav';
export const GTM_POST_VIEWMORE = 'viewmore_Post';
export const GTM_POST_DELETE = 'deletePost';
export const GTM_POST_EDIT = 'editPost';
export const GTM_POST_VIEW = 'viewPost';
export const GTM_POST_PRAISE = 'praise_Post';
export const GTM_POST_UNPRAISE = 'unpraise_Post';

export const GTM_LOGIN_SUCCESS = 'loginSuccess';
export const GTM_LOGOUT_SUCCESS = 'logoutSuccess';
export const GTM_DELETE_ACCOUNT = 'deleteAccount';

export const GTM_NOTIFICATIONS_NAV = 'notifications_view_nav';
export const GTM_NOTIFICATIONS_SEE_ALL = 'notifications_seeAll';
export const GTM_NOTIFICATION_READ = 'notificationRead';

export const GTM_PROFILE_VIEW = 'click_Profileview';
export const GTM_PROFILE_EDIT = 'edit_profile';
export const GTM_PROFILE_LINK_COPIED = 'profile_link_copied';

export const GTM_SETTINGS_BILLING_INFO = 'settings_billingInfo';

export const GTM_CHARGEBEE_CHECKOUT_CLOSE = 'chargebee_checkout_close';
export const GTM_CHARGEBEE_CHECKOUT_SUCCESS = 'chargebee_checkout_success';
export const GTM_CHARGEBEE_CHECKOUT_STEP = 'chargebee_checkout_step';

export const GTM_CHARGEBEE_PORTAL_CLOSE = 'chargebee_portal_close';
export const GTM_CHARGEBEE_PORTAL_STEP = 'chargebee_portal_step';

export const GTM_MODAL_UPDATESUBSCRIPTION_SIGNOUT = 'modal_updateSubscription_signout';
export const GTM_MODAL_UPDATESUBSCRIPTION_UPDATE = 'modal_updateSubscription_update';

export const GTM_WAITLIST_ADD = 'submit_email_waitlist';

export const GTM_VIDEO_SUBNAV_CLICKED = 'Navigated_to_video_subheaders';
export const GTM_VIDEO_SUBNAV_CLICKED_ALL = 'Navigated_to_All_videos';
export const GTM_VIDEO_PLAY = 'Play_Video';
export const GTM_VIDEO_EXIT_EXPLICIT = 'Exit_video_explicit';
export const GTM_VIDEO_EXIT_IMPLICIT = 'Exit_video_implicit';

export const GTM_ONBOARDING_SKIP = 'Onboarding_skip';
export const GTM_ONBOARDING_GO_TO_FEED = 'Onboarding_Go_to_feed';
export const GTM_ONBOARDING_GO_TO_PROFILE = 'Onboarding_Go_to_profile';

export const GTM_HOROSCOPE_VIEWED = 'horoscope_viewed';

export const GTM_CONTACT_REQUEST_STARTED = 'contact_request_started';
export const GTM_CONTACT_REQUEST_INTENT_SELECTED = 'contact_request_intent_selected';
export const GTM_CONTACT_REQUEST_SENT = 'contact_request_sent';
export const GTM_CONTACT_REQUEST_VIEWED = 'contact_request_viewed';

export const GTM_NAVIGATION_CLICK = 'navigation_click';

// Featured member A/B testing  
export const GTM_MEMBER_FEATURED_VIEWED = 'member_featured_viewed';
export const GTM_MEMBER_LOCAL_VIEWED = 'member_local_viewed';
export const GTM_MEMBER_INDUSTRY_VIEWED = 'member_industry_viewed';

export const GTM_MEMBER_FEATURED_INTERACTION = 'member_featured_click';
export const GTM_MEMBER_LOCAL_INTERACTION = 'member_local_click';
export const GTM_MEMBER_INDUSTRY_INTERACTION = 'member_industry_click';

// Global search.
export const GTM_GLOBAL_SEARCH = 'global_search';
export const GTM_GLOBAL_SEARCH_CLICKED = 'global_search_clicked';

export default TagManager;
