import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Button, { THEME_BLUE, THEME_LIGHT_BLUE, THEME_GRAY } from '../../common/Button';

import avatarIcon from '../../../public/assets/icons/AVATAR.svg';
import clockIcon from '../../../public/assets/icons/CLOCK.svg';
import waveIcon from '../../../public/assets/icons/WAVE.svg';
import bookmarkIcon from '../../../public/assets/icons/BOOKMARK.svg';
import bookmarkFilledIcon from '../../../public/assets/icons/BOOKMARK-FILLED.svg';

import {
  FIRST_NAME,
  LAST_NAME,
  HEADLINE,
  LOCATION,
  INDUSTRY,
  PROFILE_PHOTO,
  USERNAME,
  IM_GOOD_AT,
  ID_LIKE_TO_LEARN,
  ID_LIKE_TO_MEET,
  ID,
  BIO,
  INSTAGRAM,
  WEBSITE
} from '../../common/Input/CommonFields/profile';

import ProfileUser from './ProfileUser/index';
import ProfilePhoto, { SIZE_LARGE } from '../../components/ProfilePhoto';

import ProfileDescription from './ProfileDescription';
import ProfileSection from './ProfileSection';

import JourneyCard from './JourneyCard';

import ProfilePersonalityCardZodiac from './ProfilePersonalityCards/ProfilePersonalityCardZodiac';
import ProfilePersonalityCardMyersBriggs from './ProfilePersonalityCards/ProfilePersonalityCardMyersBriggs';

import ConnectionList from '../../components/ConnectionList';
import CollectivesList from '../Collectives/CollectivesList';

import './Profile.scss';
import Activity from '../ActivityFeed/Activity';

class ProfileViewing extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    user: PropTypes.shape({
      [ID]: PropTypes.number.isRequired,
      [FIRST_NAME]: PropTypes.string.isRequired,
      [LAST_NAME]: PropTypes.string.isRequired,
      [HEADLINE]: PropTypes.string,
      [LOCATION]: PropTypes.string,
      [INDUSTRY]: PropTypes.string,
      [INSTAGRAM]: PropTypes.string,
      [WEBSITE]: PropTypes.string,
      [BIO]: PropTypes.shape({
        [IM_GOOD_AT]: PropTypes.string,
        [ID_LIKE_TO_LEARN]: PropTypes.string,
        [ID_LIKE_TO_MEET]: PropTypes.string
      })
    }),
    collectives: PropTypes.object,
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool
  };

  static defaultProps = {
    canViewEditStates: false,
    collectives: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      viewingJourneyCards: false
    }
  }

  toggleJourneyCards = () => {
    this.setState({ viewingJourneyCards: !this.state.viewingJourneyCards });
  };

  toggleAdmire = () => {
    const {
      user,
      currentUser,
      admiresMap,
      admireAdd,
      admireRemove
    } = this.props;
    const admired = admiresMap[`${currentUser.id}-${user.id}`];
    if (admired) {
      admireRemove({ id: admired.id, mapKey: `${currentUser.id}-${user.id}` });
    } else {
      admireAdd(user.id);
    }
  }

  renderSidebar = () => {
    const {
      currentUser,
      user,
      zodiacCard,
      myersBriggsCard,
      viewDailyHoroscope,
      connections,
      admirers,
      collectives,
      viewConnections,
      viewAllCollectives,
      canViewEditStates,
      toggleEditMode
    } = this.props;
    const network = connections.concat(admirers.map(u => u.user).filter(u => !connections.find(c => c.id === u.id))).slice(0,12);
    return <React.Fragment>
      <ProfileSection>
        <ConnectionList
          title="Network"
          users={network}
        />
        {
          network.length > 0 && (
            <Button theme={THEME_GRAY} pill autoWidth onClick={viewConnections}>
              View all
            </Button>
          )
        }
      </ProfileSection>

      <CollectivesList
        title="Collectives"
        collectives={collectives}
        listType="user"
        showPostNumber={false}
        showAvatar
        noSubscription
        maxLength={3}
        className="CollectivesList__profile"
      />
      {
        Object.keys(collectives).length > 3 && (
          <Button theme={THEME_GRAY} pill autoWidth onClick={viewAllCollectives}>
            View all
          </Button>
        )
      }

      <div style={{ height: '30px' }}></div>

      {
        ((zodiacCard.display_on_profile || myersBriggsCard.display_on_profile) && canViewEditStates) && (
          <ProfileSection
            label="Personality"
            className="ProfileSection__public"
          >
            <div className="ProfileSection__body" style={{ marginBottom: '60px' }}>
              <div className="ProfilePersonalityCard__container">
                <ProfilePersonalityCardZodiac
                  className='ProfilePersonalityCard__sidebar-zodiac'
                  isEditing={false}
                  edit={this.updatePersonalityCardZodiac}
                  data={zodiacCard.card_data ? zodiacCard : undefined}
                  viewDailyHoroscope={viewDailyHoroscope}
                  create={toggleEditMode}
                  promptCreation={currentUser.id == user.id && !zodiacCard.card_data}
                />
                <ProfilePersonalityCardMyersBriggs
                  className='ProfilePersonalityCard__sidebar-myers-briggs'
                  isEditing={false}
                  edit={this.updatePersonalityCardMyersBriggs}
                  data={myersBriggsCard.card_data ? myersBriggsCard : undefined}
                  create={toggleEditMode}
                  promptCreation={currentUser.id == user.id && !myersBriggsCard.card_data}
                />
              </div>
            </div>
          </ProfileSection>
        )
      }
    </React.Fragment>
  }

  renderAdmireButton = () => {
    const { 
      user, 
      admireRemove, 
      admireAdd, 
      currentUser, 
      admiresMap
    } = this.props;

    if (admiresMap[`${currentUser.id}-${user.id}`]) {
      return (
        <Button 
          pill
          noOutline
          theme={THEME_BLUE} 
          className="DirectoryResult__cta" 
          onClick={() => admireRemove({
            id: admiresMap[`${currentUser.id}-${user.id}`].id, 
            mapKey: `${currentUser.id}-${user.id}`
          })}
        >
          <SVG src={bookmarkFilledIcon} />
          Admired
        </Button>
      )
    } else {
      return (
        <Button 
          pill
          noOutline
          theme={THEME_LIGHT_BLUE} 
          className="DirectoryResult__cta" 
          onClick={() => admireAdd(user.id)}
          disabled={user.id == currentUser.id} 
        >
          <SVG src={bookmarkIcon} />
          {admiresMap[`${user.id}-${currentUser.id}`] ? 'Admire Back' : 'Admire'}
        </Button>
      )
    }
  }

  render() {
    const {
      user,
      currentUser,
      journeyCards,
      canViewEditStates,
      isLoggedIn,
      toggleEditMode,
      sendContactRequest,
      contactURL,
      contactRequestSent,
      copyProfileLink,
      admiresMap,
      viewActivity,
      activity
    } = this.props;
    const { viewingJourneyCards } = this.state;

    const photo = user[PROFILE_PHOTO];
    const userPhoto = (typeof photo === 'string' ? photo : photo.large);
    const admired = admiresMap[`${currentUser.id}-${user.id}`];

    return (
      <div className="ProfileViewing__background">
        <div className="ProfileViewing__container">
          <div className="ProfileViewing__left">
            <ProfilePhoto
              photo={user[PROFILE_PHOTO].large}
              size={SIZE_LARGE}
              className={"ProfileViewing__left--photo"}
            />

            {
              isLoggedIn && canViewEditStates && (
                <button className="ProfileUser__action-button-edit" onClick={toggleEditMode}>Edit Profile</button>
              )
            }

            {
              currentUser.id && (user.id !== currentUser.id) && (
                <div className="ProfileViewing__cta-container">
                  {this.renderAdmireButton()}
                  { 
                    (contactURL && (
                      <span className="Button Button__blue DirectoryResult__cta Button--rounded Button--no-outline">
                        <SVG src={avatarIcon} />
                        Connected
                      </span>
                    ))
                    || 
                    (contactRequestSent && (
                    <Button 
                      pill
                      noOutline
                      theme={THEME_GRAY} 
                      className="DirectoryResult__cta" 
                      disabled
                    >
                      <SVG src={clockIcon} />
                      Request Sent
                      </Button>
                    ))
                    ||
                    (!contactRequestSent && (
                      <Button 
                        pill
                        noOutline
                        theme={THEME_LIGHT_BLUE} 
                        disabled={user.id === currentUser.id} 
                        className="DirectoryResult__cta" 
                        onClick={sendContactRequest}
                      >
                        <img src={waveIcon} />
                        Say Hey
                      </Button>
                    ))
                  }
                </div>
              )
            }

            <div className="desktop-only">
              {
                this.renderSidebar()
              }
            </div>
          </div>
          <div className="ProfileViewing__right">
            <ProfileSection>
              <ProfileUser
                {
                ...{
                  [PROFILE_PHOTO]: userPhoto,
                  [FIRST_NAME]: user[FIRST_NAME],
                  [LAST_NAME]: user[LAST_NAME],
                  [HEADLINE]: user[HEADLINE],
                  [LOCATION]: user[LOCATION],
                  [INDUSTRY]: user[INDUSTRY],
                  [USERNAME]: user[USERNAME],
                  [INSTAGRAM]: user[INSTAGRAM],
                  [WEBSITE]: user[WEBSITE],
                  email: user.email
                }
                }
                canViewEditStates={canViewEditStates}
                isLoggedIn={isLoggedIn}
                toggleEditMode={toggleEditMode}
                userId={user.id}
                ownUserId={currentUser.id}
                sendContactRequest={sendContactRequest}
                contactRequestSent={contactRequestSent}
                contactURL={contactURL}
                copyProfileLink={copyProfileLink}
                ownUserId={currentUser.id}
                userId={user.id}
              />
            </ProfileSection>

            {
              (user[BIO]) && (user[BIO][IM_GOOD_AT] ||
                user[BIO][ID_LIKE_TO_LEARN] ||
                user[BIO][ID_LIKE_TO_MEET]) && (
                <ProfileSection
                  label="About"
                  className="ProfileSection__public"
                >
                  <ProfileDescription
                    bio={user[BIO]}
                    onEdit={this.editDescription}
                    update={this.updateBioState}
                  />
                </ProfileSection>
              )
            }

            {
              activity.length > 0 && (
                <ProfileSection
                  label="Activity"
                  className="ProfileSection__public"
                >
                  {
                    activity.slice(0,3).map(activity => (
                      <Activity
                        user={user}
                        key={activity.id}
                        activity={activity}
                      />
                    ))
                  }
                  {
                    activity.length > 3 && (
                      <Button theme={THEME_GRAY} pill autoWidth onClick={viewActivity}>
                        View all activity
                      </Button>
                    )
                  }
                </ProfileSection>
              )
            }


            {
              journeyCards.length > 0 && (
                <ProfileSection
                  label="Journey"
                  className="ProfileSection__public"
                >
                  {
                    journeyCards.slice(0, viewingJourneyCards ? journeyCards.length : 3).map(card => (
                      <JourneyCard
                        key={card.id}
                        card={card}
                      />
                    ))
                  }
                  {
                    journeyCards.length > 3 && (
                      <Button theme={THEME_GRAY} onClick={this.toggleJourneyCards} pill autoWidth>
                        {
                          viewingJourneyCards && <React.Fragment>Collapse</React.Fragment> || <React.Fragment>Expand</React.Fragment>
                        }
                      </Button>
                    )
                  }
                </ProfileSection>
              )
            }

            <div className="mobile-tablet-only">
              {
                this.renderSidebar()
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default ProfileViewing;