import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class TextWidthCalculator extends Component {
  static propTypes = {
    text:PropTypes.string.isRequired,
    minWidth:PropTypes.number,
    minWidthMobile:PropTypes.number,
    minHeight:PropTypes.number,
    handleChange:PropTypes.func.isRequired,
    className:PropTypes.string
  };

  static defaultProps = {
    active: true
  };

  constructor(props) {
    super(props);
    this.state = {value:''};
    this.inputCalculator = React.createRef();

    this.state.calculatedWidth = 0;

    setTimeout(() => {
      this.handleChange();
    }, 100);
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleChange);
    this.handleChange();
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleChange);
  }

  componentDidUpdate () {
    if (this.inputCalculator.current) {
      if (this.state.text != this.props.text) {
        this.handleChange();
      }
    }
  }

  handleChange = () => {

    const { minWidth, minWidthMobile, minHeight } = this.props;

    if (this.inputCalculator.current) {
      let newState = this.state;
      newState.text = this.props.text;
      this.setState(newState);

      let isMobile = window.innerWidth < 600;

      this.props.handleChange(Math.ceil(this.inputCalculator.current.offsetWidth) ||
                              (isMobile ? minWidthMobile : minWidth) ||
                              200,
                              Math.ceil(this.inputCalculator.current.offsetHeight) || minHeight);
    }
  }

  render() {

    setTimeout(() => {
    if (this.inputCalculator.current) {
    }
  }, 100);
    return (
      <div ref={this.inputCalculator} style={{position:'absolute', left:'0', visibility:'hidden', wordWrap:'break-word', whiteSpace:'pre-wrap'}} className={this.props.className}>
        {this.props.text}
      </div>
    )
  }
}

export default TextWidthCalculator;
