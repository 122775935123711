import { defineRequest } from '../../lib/reduxRequests';

/**
  Connections
**/

export const CLEAR_CONNECTIONS = 'CLEAR_CONNECTIONS';

export const connectionsClear = (payload) => {
  return {
    type: CLEAR_CONNECTIONS,
    payload
  }
}

export const {
  CONNECTIONS_GET,
  CONNECTIONS_GET_FULFILLED,
  connectionsGet
} = defineRequest('CONNECTIONS_GET', (params, key) => ({ key, payload: params }));

export const connectionGetOffset = state => state.Connections.offset;