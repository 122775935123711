import {
  PROFILE_GET_SUCCESS
} from '../Profile/actions';
import {
  FEED_FEATURED_MEMBERS_GET_SUCCESS
} from '../Feed/actions';

const initialState = {};

function profileEntitiesReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_GET_SUCCESS: {
      const user = action.payload.user;
      return { ...state, [user.username]: user };
    }
    case FEED_FEATURED_MEMBERS_GET_SUCCESS: {
      const featuredProfiles = {};

      action.payload.map(featuredMember => (
        featuredProfiles[featuredMember.username] = featuredMember
      ));

      return { ...state, ...featuredProfiles };
    }
    default: {
      return { ...state };
    }
  }
}

export default profileEntitiesReducer;
