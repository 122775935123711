import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

import DropdownIcon from '../../../public/assets/select-icon.png';

import './Dropdown.scss';

export const THEME_WHITE = 'white';


class Dropdown extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    theme: PropTypes.oneOf([THEME_WHITE]),
    isError: PropTypes.bool,
    disabledOptions: PropTypes.array,
    noIndicator: PropTypes.bool,
    noOptionsMessage: PropTypes.string
  };

  static defaultProps = {
    disabled: false
  };

  constructor(props) {
    super(props);
    this.DropdownIndicator = () => (
      <div className="Dropdown__select-indicator" style={{ backgroundImage: `url(${DropdownIcon})` }} />
    );
    this.NoDropdownIndicator = () => (
      <div style={{ display: 'none' }} />
    );
    this.state = { val: '' };
  }

  handleOnChange = (e) => {
    this.props.onChange(e.target.value, this.props.label);
  }

  onChangeSelect = val => {
    this.props.onSelect(val);
  }

  onChangeDropdown = val => {
    this.props.onChange(val);
    this.setState({val});
  }

  render() {
    const {
      disabled,
      theme,
      disabledOptions,
      useSelect,
      value,
      className,
      placeholder,
      options,
      styles,
      noIndicator,
      noOptionsMessage,
      showOptions
    } = this.props;

    let controlStyle = {
      borderRadius: '0',
      width: '100%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      minHeight: '58px'
    };

    if (styles) {
      if (styles.control) {
        controlStyle = {
          ...controlStyle,
          ...styles.control
        }
      }
    }

    const defaultStyles = {
      control: () => (controlStyle)
    }

    const DropdownIndicator = noIndicator ? this.NoDropdownIndicator : this.DropdownIndicator;
    const IndicatorSeparator = this.NoDropdownIndicator;

    if (useSelect) {
      return (
        <Select
          className={classNames(
            'Dropdown__select',
            className,
            {
              'Dropdown--white': theme === THEME_WHITE,
              'Dropdown--error': this.props.isError
            }
          )}
          value={value}
          onChange={this.onChangeSelect}
          onInputChange={this.onChangeDropdown}
          styles={defaultStyles}
          placeholder={<span>{placeholder}</span>}
          options={options}
          components={{ DropdownIndicator, IndicatorSeparator }}
          noOptionsMessage={() => <React.Fragment>{noOptionsMessage}</React.Fragment>}
          menuIsOpen={showOptions}
          isClearable
          filterOption={() => true}
        />
      )
    }

    return (
      <label
        className={classNames(
          "Dropdown__label",
          this.props.className
        )}
      >
        <select
          className={classNames(
            'Dropdown',
            {
              'Dropdown--white': theme === THEME_WHITE,
              'Dropdown--error': this.props.isError
            }
          )}
          onChange={this.handleOnChange}
          value={this.props.value}
          required={this.props.required}
          disabled={disabled}
        >
          <option value="" disabled>
            {this.props.placeholder}
          </option>
          {
            this.props.options.map(optionVal => (
              <option value={optionVal} key={optionVal} disabled={disabledOptions && disabledOptions.indexOf(optionVal) > -1}>
                {optionVal}
              </option>
            ))
          }
        </select>
      </label>
    )
  }
}

export default Dropdown;
