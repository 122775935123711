import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  FIRST_NAME,
  HEADLINE,
  INDUSTRY,
  LAST_NAME,
  LOCATION,
  PROFILE_PHOTO,
  USERNAME,
  ID,
  EXPERIENCES,
  EDUCATION,
  BIO,
  I_AM,
  IM_GOOD_AT,
  I_WORK_AT
} from '../../common/Input/CommonFields/profile';
import Profile from './index';

import NotFound from '../../components/NotFound';

import './Profile.scss';

class UserProfile extends Component {
  static propTypes = {
    user: PropTypes.shape({
      [ID]: PropTypes.number,
      [FIRST_NAME]: PropTypes.string,
      [LAST_NAME]: PropTypes.string,
      [LOCATION]: PropTypes.string,
      [HEADLINE]: PropTypes.string,
      [INDUSTRY]: PropTypes.string,
      [BIO]: PropTypes.shape({
        [I_AM]: PropTypes.string,
        [IM_GOOD_AT]: PropTypes.string,
        [I_WORK_AT]: PropTypes.string
      }),
      [EXPERIENCES]: PropTypes.arrayOf(
        PropTypes.shape({})
      ),
      [EDUCATION]: PropTypes.arrayOf(
        PropTypes.shape({})
      )
    })
  };

  render() {
    if (!this.props.user) return <NotFound />
    return <Profile user={this.props.user} />;
  }
};

const mapStateToProps = ({ User }) => {
  return {
    currentUser: User.user
  }
}

export default connect(mapStateToProps)(UserProfile);
