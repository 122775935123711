import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import SVG from 'react-inlinesvg';
import qs from 'query-string';

import { modalOpen } from '../../../store/Modal/actions';
import { POST_MODAL } from '../../ModalManager/ModalTypes';
import {
  collectiveMemberGet,
  collectiveMemberAdd,
  collectiveMemberRemove,
  setCurrentCollectiveSlug,
  clearCurrentMembersList,
  updateCollectiveData
} from '../../../store/Collectives/actions';
import { contactsGet } from '../../../store/Contact/actions';
import { inviteGet, invitesGet, inviteAccept } from '../../../store/Invites/actions';
import { feedGet, initFeed } from '../../../store/Feed/actions';
import { checkAuth } from '../../../utils/checkAuth';

import { selectCollectivePolicy } from '../CollectivePolicy';
import Linkify from 'react-linkify';

import CollectiveFeed from './CollectiveFeed';
import MembersPreview from '../../../components/Collectives/MembersPreview';
import PostNumber from '../../../components/Collectives/PostNumber';
import UserList from '../../../components/UserList';
import PostFeedInput from '../../Post/PostFeedInput';
import ButtonToggle from '../../../common/Button/ButtonToggle';
import { COLLECTIVE_MEMBERS_LIST, COLLECTIVE_INVITATION_MODAL, COLLECTIVE_CREATION_MODAL, SIGNUP_PROMPT_MODAL } from '../../ModalManager/ModalTypes';
import Loader from '../../../common/Loader';
import NotFound from '../../../components/NotFound';

import Button, { THEME_BLUE, THEME_LIGHT_BLUE, THEME_BLACK, THEME_GRAY, THEME_NAVY } from '../../../common/Button';
import addIcon from '../../../../public/assets/icons/ADD.svg';
import checkIcon from '../../../../public/assets/icons/CHECK.svg';

import './CollectivePage.scss';
import LinkWithParams from '../../../common/LinkWithParams';
import routes from '../../../config/routes';
import Banner from '../../../components/Banner';

export const USER_LIST_LENGTH = 3;
export const MOBILE_USER_LIST_LENGTH = 6;

class Collective extends Component {
  constructor(props) {
    super(props);

    if (props.isLoggedIn) props.contactsGet();

    this.postColumn = React.createRef();
  }

  componentDidMount = () => {
    const { pathname, computedMatch, currentSlug, collective, invitesGet, user, userMember } = this.props;

    if (collective) {
      if (!pathname || currentSlug !== computedMatch.params.slug) {
        this.initCollectivePage();
      } else {
        this.refreshFeed();
      }
    };

    // makes sure the window position starts on the top when loading into the collective page
    if (!pathname || !pathname.includes('/post/')) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate = prevProps => {
    const { computedMatch, currentSlug, collective, userMember } = this.props;
    if (collective) {
      if (currentSlug !== computedMatch.params.slug) {
        this.initCollectivePage();
      } else if ((userMember || {}).id !== (prevProps.userMember || {}).id) {
        this.refreshFeed();
      }
    } 
  }

  initCollectivePage = () => {
    const { isLoggedIn, location, computedMatch, collective, invite, userMember } = this.props;
    const params = qs.parse(location.search);

    if (!invite && !userMember) {
      if (params.invite_token) {
        this.props.inviteGet(params.invite_token);
      } else if (collective.id && isLoggedIn) {
        this.props.invitesGet({ collective_id: collective.id });
      }
    }
    
    this.props.setCurrentCollectiveSlug(computedMatch.params.slug);
    this.refreshFeed(true);

    window.scrollTo(0, 0);

    if (collective && collective.members) {
      if (userMember && userMember.role === 'owner' && !collective.published_at) {
        this.props.updateCollectiveData({
          id: collective.id,
          slug: collective.slug,
          name: collective.name,
          description: collective.description,
          image: collective.image.medium
        });

        this.props.modalOpen({
          modal: COLLECTIVE_CREATION_MODAL,
          implicitExit: false,
          startingStep: 'post'
        })
      }
    }
  }

  refreshFeed(force) {
    const { collective, collectivePolicy, feedLoaded, initFeed, feedGet } = this.props;

    if (force || collectivePolicy.limitFeed() || !feedLoaded) {
      initFeed();

      let limit = {};
      if (collectivePolicy.limitFeed()) {
        limit = { limitFeed: true };
      }

      if (collective.id) {
        feedGet({ collectiveId: collective.id, ...limit });
      }
    }
  }

  openPostModal = checkAuth(e => {
    this.props.modalOpen({
      modal: POST_MODAL,
      collectiveId: this.props.collective.id,
      collectiveName: this.props.collective.name,
      position: 'belowNav',
      implicitExit: false
    });

    e.target.blur();
  })

  checkSubscription = () => {
    const { collective, userMember } = this.props;
    if (!userMember) {
      this.subscribe();
    } else {
      this.unsubscribe(collective.slug, userMember.id);
    }
  }

  subscribe = () => {
    const { invite_token } = qs.parse(this.props.location.search);
    this.props.collectiveMemberAdd({
      collective_id: this.props.collective.id,
      invite_token
    });
  }

  unsubscribe = (slug, memberId) => {
    this.props.collectiveMemberRemove({
      slug,
      memberId
    });
  }

  viewAllMembers = checkAuth(userList => {
    let adminIds = this.props.collective.members
      .filter(member => member.role === 'admin')
      .map(member => member.user.id);

    const params = {
      collectiveId: this.props.collective.id,
      slug: this.props.collective.slug,
      limit: 20
    };

    this.props.modalOpen({
      modal: COLLECTIVE_MEMBERS_LIST,
      slug: this.props.collective.slug,
      loadMore: () => this.props.collectiveMemberGet(params),
      header: userList === 'admin' ? 'Founders' : 'Subscribed Members',
      role: userList,
      adminIds
    })
  })

  onOpenSignupModal = () => {
    this.props.modalOpen({
      modal: SIGNUP_PROMPT_MODAL
    })
  }

  onInvite = checkAuth(() => {
    this.props.modalOpen({
      modal: COLLECTIVE_INVITATION_MODAL,
      collective_id: this.props.collective.id,
      collective_name: this.props.collective.name,
      implicitExit: false
    });
  })

  onEditCollective = () => {
    const { collective } = this.props;

    this.props.updateCollectiveData({
      id: collective.id,
      slug: collective.slug,
      name: collective.name,
      description: collective.description,
      image: collective.image.medium
    });

    if (collective.published_at) {
      this.props.modalOpen({
        modal: COLLECTIVE_CREATION_MODAL,
        implicitExit: false,
        editMode: true
      })
    } else {
      this.props.modalOpen({
        modal: COLLECTIVE_CREATION_MODAL,
        implicitExit: false,
        startingStep: 'post'
      })
    }
  }

  scrollToPostColumn = () => {
    if (this.postColumn.current) {
      this.postColumn.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  render () {
    const { collective, collectiveRequest, collectivePolicy, user, userMember, invite } = this.props;
    
    if (!collective && collectiveRequest.rejected) return <NotFound />;
    if (!collective || !collective.members) return <Loader />;
    if (collectiveRequest.pending && (!collective || !collective.members)) return <Loader />;

    let memberList = [];
    let adminList = [];
    let isAdmin = userMember && (userMember.role === 'owner' || userMember.role === 'admin');

    if (collective.members) {
      memberList = collective.members
        .filter(member => (member.role === 'subscriber'))
        .map(member => (member.user));

      adminList = collective.members
        .filter(member => member.role === 'admin' || member.role === 'owner')
        .map(member => member.user);
    };

    let adminIds = adminList.map(admin => admin.id);

    const showViewAllAdmins = adminList.length > USER_LIST_LENGTH;
    const showViewAllMembers = collective.member_count - adminList.length > USER_LIST_LENGTH;
    const showViewAllAdminsMobile = adminList.length > MOBILE_USER_LIST_LENGTH;
    const showViewAllMembersMobile = collective.member_count - adminList.length > MOBILE_USER_LIST_LENGTH;

    let canSubscribe = collectivePolicy.subscribe();     
    let canInvite = collectivePolicy.invite();
    let limitFeed = collectivePolicy.limitFeed();

    return (
      <div className="Collective__background">
        <Helmet title={`Girlboss - ${collective.name}`} />
        {
          invite && (
            <Banner
              left={
                <React.Fragment>
                  <div className="Collective__invite-banner-icon" style={{ backgroundImage: `url(${invite.sender.photo.thumbnail})` }}></div>
                  <h4 className="Collective__invite-banner-text"><Link to={`/${invite.sender.username}`}>{invite.sender.first_name} {invite.sender.last_name}</Link><br className="mobile-only" /> invited you to join this Collective!</h4>
                </React.Fragment>
              }
              right={
                <React.Fragment>
                  {user && <Button className="Collective__invite-banner-button" theme={THEME_NAVY} pill autoWidth onClick={this.subscribe}>Accept Invite</Button>}
                  {!user && <LinkWithParams to={routes.logIn}><Button className="Collective__invite-banner-button" theme={THEME_NAVY} pill autoWidth>Accept Invite</Button></LinkWithParams>}
                </React.Fragment>
              }
            />
          )
        }
        <div className="Collective__container">
          <div className="Collective__left">
            <div className="Collective__summary-container">
              <div className="Collective__summary-image" style={{ backgroundImage: `url(${collective.image.large})` }}></div>
              <div className="Collective__summary">
                <h1 className="Collective__summary-heading">{ collective.name }</h1>
                <p className="Collective__summary-description">
                  <Linkify properties={{target: '_blank'}}>{collective.description}</Linkify>
                </p>
                <div className="Collective__statistics">
                  <MembersPreview
                    members={collective.members}
                    memberCount={collective.member_count}
                    showAvatar={false}
                    onClick={() => this.viewAllMembers()}
                  />
                  <PostNumber onClick={this.scrollToPostColumn} count={collective.post_count} />
                </div>
                <div className="Collective__summary--cta">
                  {
                    canSubscribe && (
                      <React.Fragment>
                        {/* {
                          isAdmin && (
                            <Button
                              theme={THEME_GRAY}
                              noOutline
                              pill
                              className="Collective__summary--cta--button"
                              onClick={this.onEditCollective}
                            >
                              Edit
                            </Button>
                          )
                        } */}
                        {
                          !isAdmin && !limitFeed && (
                            <ButtonToggle
                              noOutline
                              pill
                              className="Collective__summary--cta--button"
                              checkedTheme={THEME_LIGHT_BLUE}
                              uncheckedTheme={THEME_BLUE}
                              checkedContent={<React.Fragment><SVG src={addIcon} /> Subscribe</React.Fragment>}
                              uncheckedContent={<React.Fragment><SVG src={checkIcon} /> Subscribed</React.Fragment>}
                              isChecked={!userMember}
                              onClick={this.checkSubscription}
                              disabled={!!userMember && adminIds.indexOf(this.props.user.id) !== -1}
                          />)
                        }
                        {
                          canInvite && (
                            <Button
                              pill
                              noOutline
                              theme={THEME_BLACK}
                              className="Collective__summary--cta--button"
                              onClick={this.onInvite}
                            >
                              Invite
                            </Button>
                          )
                        }
                      </React.Fragment>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="Collective__user-list">
              <UserList
                users={adminList.slice(0, USER_LIST_LENGTH)}
                className='desktop-only'
                title="Founders"
                adminIds={adminIds}
              />
              { showViewAllAdmins && (
                <Button
                  theme={THEME_LIGHT_BLUE}
                  className="Collective__members-view-all desktop-only"
                  pill
                  noOutline
                  onClick={() => this.viewAllMembers('admin')}
                >
                  View More
                </Button>
              )}
            </div>
            <div className="Collective__user-list">
              <UserList
                users={memberList.slice(0, USER_LIST_LENGTH)}
                className='desktop-only'
                title="Members"
              />
              { showViewAllMembers && (
                <Button
                  theme={THEME_LIGHT_BLUE}
                  className="Collective__members-view-all desktop-only"
                  pill
                  noOutline
                  onClick={() => this.viewAllMembers('subscriber')}
                >
                  View More
                </Button>
              )}
            </div>
          </div>
          <div className="Collective__right" ref={this.postColumn}>
            {
              collective.published_at && !limitFeed && (
                <PostFeedInput handleFocus={this.openPostModal} className="PostFeedInput__collective" />
              )
            }
            <CollectiveFeed 
              collective={collective} 
              viewAllMembers={this.viewAllMembers}
              showViewAllMembers={showViewAllMembersMobile}
              showViewAllAdmins={showViewAllAdminsMobile}
              adminIds={adminIds}
              limitFeed={limitFeed}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, { collective }) => {
  const { user } = state.User;
  const userMember = collective && collective.members &&
    collective.members.find(member => member.user.id === user.id);
  const invite = collective && !userMember &&
    state.Invites.invites.find(invite => invite.collective_id === collective.id && invite.status === 'sent')

  return {
    user,
    pathname: state.App.pathname,
    isLoading: state.Collectives.isLoading,
    currentSlug: state.Collectives.currentSlug,
    currentMembersList: state.Collectives.currentMembersList,
    collectivePolicy: selectCollectivePolicy(state.User.user, collective, true),
    userMember,
    invite,
    feedLoaded: !!state.Feed.entities.length
  }
}

export default withRouter(connect(mapStateToProps, {
  modalOpen,
  collectiveMemberGet,
  collectiveMemberAdd,
  collectiveMemberRemove,
  contactsGet,
  feedGet,
  initFeed,
  setCurrentCollectiveSlug,
  clearCurrentMembersList,
  updateCollectiveData,
  inviteGet,
  invitesGet,
  inviteAccept
})(Collective));
