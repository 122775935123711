import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { routes } from '../../config/routes'; 

import Button, { THEME_BLUE } from '../../common/Button';
import Card from '../../components/Card';

class UnauthorizedMessage extends Component {
  redirectToLogin = () => {
    const ref = location.pathname + location.hash + location.search;
    this.props.history.push(`${routes.logIn}?${qs.stringify({ ref })}`);
  }

  render() {
    return (
      <Card className="Thread__UnauthorizedMessage" backgroundColor="#F7F7F7" key="signup-block">
        <h1>You must be logged in to view this post.</h1>
        <Button 
          className="Thread__UnauthorizedMessage__cta"
          onClick={this.redirectToLogin} theme={THEME_BLUE} pill noOutline autoWidth>Log In</Button>
      </Card>
    )
  }
}

export default withRouter(UnauthorizedMessage);