import React, { Component } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';

import './PasswordRequestSuccess.scss';

class RequestPasswordReset extends Component {
  constructor (props) {
    super(props);

    this.state = {
      emailSent:false
    }
  }

  render () {
    return (
      <div className="container">
        <div className="row center">
          <div className="reset-container">
            <h2 className="reset">Reset successful</h2>
            <h4 className="reset">Try logging in again with your new password</h4>
            <Link to="/login" className="reset-return-home">
              Back to login
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default RequestPasswordReset;
