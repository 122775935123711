import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Video from './Video.js';

import InfiniteScroller from '../../components/InfiniteScroller';
import NavOptions from '../../components/NavOptions';

import { VIDEO_MODAL } from '../ModalManager/ModalTypes';
import { modalOpen } from '../../store/Modal/actions';

import {
  GTMpushDataLayerEvent,
  GTM_VIDEO_SUBNAV_CLICKED,
  GTM_VIDEO_SUBNAV_CLICKED_ALL
} from '../../lib/GoogleTagManager';

import laRally2017 from './data_laRally2017.js';
import nycRally2017 from './data_nycRally2017.js';
import laRally2018 from './data_laRally2018.js';
import nycRally2018 from './data_nycRally2018.js';

import './Videos.scss';

const VIEW_OFFSET = 12;

const navOptions = [
  {
    id: 'nycRally2018',
    data: nycRally2018,
    title: 'Fall 2018 Rally'
  },
  {
    id: 'laRally2018',
    data: laRally2018,
    title: 'Spring 2018 Rally'
  },
  {
    id: 'nycRally2017',
    data: nycRally2017,
    title: 'Fall 2017 Rally'
  },
  {
    id: 'laRally2017',
    data: laRally2017,
    title: 'Spring 2017 Rally'
  },
  {
    id: 'all',
    data: [...nycRally2018, ...laRally2018, ...nycRally2017, ...laRally2017],
    title: 'All Videos'
  }
];

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      numVideosInView: VIEW_OFFSET,
      isLoading: false
    }
  }

  modalOpen = ({ title, featuring, id, description }) => {
    this.props.modalOpen({
      modal: VIDEO_MODAL,
      title,
      featuring,
      id,
      description
    });
  }

  loadMore = () => {
    this.setState({
      numVideosInView: this.state.numVideosInView + VIEW_OFFSET,
      isLoading: true
    });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
  }

  shouldLoadMore = () => (
    !this.state.isLoading && (this.state.numVideosInView <= navOptions[this.state.activeIndex].data.length)
  );

  handleChangeActiveTab = index => {
    this.setState({
      activeIndex: index,
      numVideosInView: VIEW_OFFSET
    });
  }

  loadMore = () => {
    this.setState({
      numVideosInView: this.state.numVideosInView + VIEW_OFFSET,
      isLoading: true
    });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);
  }

  shouldLoadMore = () => (
    !this.state.isLoading && (this.state.numVideosInView <= navOptions[this.state.activeIndex].data.length)
  );

  handleChangeActiveTab = index => {
    this.setState({
      activeIndex: index,
      numVideosInView: VIEW_OFFSET
    });

    // gtm
    GTMpushDataLayerEvent({
      event: GTM_VIDEO_SUBNAV_CLICKED,
      subHeader: navOptions[index]['title']
    });

    if (navOptions[index]['id'] === 'all') {
      GTMpushDataLayerEvent({
        event: GTM_VIDEO_SUBNAV_CLICKED_ALL
      });
    }
  }

  render() {
    const shouldLoadMore = this.shouldLoadMore();
    return (
      <div className="Videos">        
        <div className="Videos__container--header">
          <p className="Page__header">Watch</p>
          <p className="Page__subHeader">Videos to strengthen your skillsets</p>
        </div>
        <div className="Videos__nav">
          <NavOptions
            options={navOptions}
            handleChangeActiveTab={this.handleChangeActiveTab}
            activeIndex={this.state.activeIndex}
          />
        </div>
        <InfiniteScroller
          loadMore={this.loadMore}
          shouldLoadMore={shouldLoadMore}
          isLoading={this.state.isLoading}
          className="Videos__container--videos"
        >
          {
            navOptions[this.state.activeIndex].data.slice(0, this.state.numVideosInView).map(video => (
              <Video
                key={video.id}
                id={video.id}
                thumbnailId={video.thumbnail_id}
                title={video.title}
                description={video.description}
                featuring={video.featuring}
                modalOpen={this.modalOpen}
              />
            ))
          }
        </InfiniteScroller>
      </div>
    )
  }
};

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args))
  }
};

export default connect(null, mapDispatchToProps)(Videos);
