import { defineRequest } from '../../lib/reduxRequests';

/**
  User Get
**/
export const USER_GET = 'USER_GET';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_ERROR = 'USER_GET_ERROR';
export const USER_GET_COMPLETE = 'USER_GET_COMPLETE';

export const userGet = () => {
  return {
    type: USER_GET
  }
};

export const userGetSuccess = payload => {
  return {
    type: USER_GET_SUCCESS,
    payload
  }
};

export const userGetError = payload => {
  return {
    type: USER_GET_ERROR,
    payload
  }
};

export const userGetComplete = () => {
  return {
    type: USER_GET_COMPLETE
  }
};

/**
  User Update
**/
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const userUpdate = (payload, location) => {
  return {
    type: USER_UPDATE,
    payload,
    location
  }
};

export const userUpdateSuccess = payload => {
  return {
    type: USER_UPDATE_SUCCESS,
    payload
  }
};

export const userUpdateError = payload => {
  return {
    type: USER_UPDATE_ERROR,
    payload
  }
};

export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_ERROR = 'USER_UPDATE_PASSWORD_ERROR';
export const USER_RESET_UPDATE_ERRORS_AND_SUCCESS = 'USER_RESET_UPDATE_ERRORS_AND_SUCCESS';

export const userUpdatePassword = payload => {
  return {
    type: USER_UPDATE_PASSWORD,
    payload
  }
};

export const userUpdatePasswordSuccess = payload => {
  return {
    type: USER_UPDATE_PASSWORD_SUCCESS,
    payload
  }
};

export const userUpdatePasswordError = payload => {
  return {
    type: USER_UPDATE_PASSWORD_ERROR,
    payload
  }
};

export const userResetUpdateErrorsAndSuccess = () => {
  return {
    type: USER_RESET_UPDATE_ERRORS_AND_SUCCESS
  }
}

export const USER_UPDATE_WITH_PASSWORD = 'USER_UPDATE_WITH_PASSWORD';
export const USER_UPDATE_WITH_PASSWORD_SUCCESS = 'USER_UPDATE_WITH_PASSWORD_SUCCESS';
export const USER_UPDATE_WITH_PASSWORD_ERROR = 'USER_UPDATE_WITH_PASSWORD_ERROR';

export const userUpdateWithPassword = payload => {
  return {
    type: USER_UPDATE_WITH_PASSWORD,
    payload
  }
};

export const userUpdateWithPasswordSuccess = payload => {
  return {
    type: USER_UPDATE_WITH_PASSWORD_SUCCESS,
    payload
  }
};

export const userUpdateWithPasswordError = payload => {
  return {
    type: USER_UPDATE_WITH_PASSWORD_ERROR,
    payload
  }
};

/**
  User update photo
**/
export const {
  USER_UPDATE_PHOTO,
  USER_UPDATE_PHOTO_FULFILLED,
  userUpdatePhoto
} = defineRequest('USER_UPDATE_PHOTO', (params) => ({
  payload: params
}));

/**
  User update personality card
**/
export const USER_UPDATE_CARD = 'USER_UPDATE_CARD';
export const USER_UPDATE_CARD_SUCCESS = 'USER_UPDATE_CARD_SUCCESS';
export const USER_UPDATE_CARD_ERROR = 'USER_UPDATE_CARD_ERROR';

export const userUpdateCard = payload => {
  return {
    type: USER_UPDATE_CARD,
    payload
  }
};

export const userUpdateCardSuccess = payload => {
  return {
    type: USER_UPDATE_CARD_SUCCESS,
    payload
  }
};

export const userUpdateCardError = payload => {
  return {
    type: USER_UPDATE_CARD_ERROR,
    payload
  }
};

/**
  User update optimistic
**/
export const USER_UPDATE_OPTIMISTIC = 'USER_UPDATE_OPTIMISTIC';

export const userUpdateOptimistic = payload => {
  return {
    type: USER_UPDATE_OPTIMISTIC,
    payload
  }
};

/**
  User Save
**/
export const USER_SAVE = 'USER_SAVE';

// saves user data but is not connected to an endpoint
export const userSave = payload => {
  return {
    type: USER_SAVE,
    payload
  }
}

/**
  Delete User
**/
export const {
  USER_DELETE,
  USER_DELETE_FULFILLED,
  userDelete
} = defineRequest('USER_DELETE');

/**
  Journey Cards
**/
export const USER_JOURNEY_CARD_ADD = 'USER_JOURNEY_CARD_ADD';
export const USER_JOURNEY_CARD_ADD_SUCCESS = 'USER_JOURNEY_CARD_ADD_SUCCESS';
export const USER_JOURNEY_CARD_ADD_ERROR = 'USER_JOURNEY_CARD_ADD_ERROR';

export const userJourneyCardAdd = payload => {
  return {
    type: USER_JOURNEY_CARD_ADD,
    payload
  }
};

export const userJourneyCardAddSuccess = payload => {
  return {
    type: USER_JOURNEY_CARD_ADD_SUCCESS,
    payload
  }
};

export const userJourneyCardAddError = payload => {
  return {
    type: USER_JOURNEY_CARD_ADD_ERROR,
    payload
  }
};

export const USER_JOURNEY_CARD_UPDATE = 'USER_JOURNEY_CARD_UPDATE';
export const USER_JOURNEY_CARD_UPDATE_SUCCESS = 'USER_JOURNEY_CARD_UPDATE_SUCCESS';
export const USER_JOURNEY_CARD_UPDATE_ERROR = 'USER_JOURNEY_CARD_UPDATE_ERROR';

export const userJourneyCardUpdate = (payload, id) => {
  return {
    type: USER_JOURNEY_CARD_UPDATE,
    id,
    payload
  }
};

export const userJourneyCardUpdateSuccess = payload => {
  return {
    type: USER_JOURNEY_CARD_UPDATE_SUCCESS,
    payload
  }
};

export const userJourneyCardUpdateError = payload => {
  return {
    type: USER_JOURNEY_CARD_UPDATE_ERROR,
    payload
  }
};

export const USER_JOURNEY_CARD_DELETE = 'USER_JOURNEY_CARD_DELETE';
export const USER_JOURNEY_CARD_DELETE_SUCCESS = 'USER_JOURNEY_CARD_DELETE_SUCCESS';
export const USER_JOURNEY_CARD_DELETE_ERROR = 'USER_JOURNEY_CARD_DELETE_ERROR';

export const userJourneyCardDelete = id => {
  return {
    type: USER_JOURNEY_CARD_DELETE,
    id
  }
};

export const userJourneyCardDeleteSuccess = payload => {
  return {
    type: USER_JOURNEY_CARD_DELETE_SUCCESS,
    payload
  }
};

export const userJourneyCardDeleteError = payload => {
  return {
    type: USER_JOURNEY_CARD_DELETE_ERROR,
    payload
  }
};

/**
  Personality Cards
**/
export const USER_PERSONALITY_CARD_ADD = 'USER_PERSONALITY_CARD_ADD';
export const USER_PERSONALITY_CARD_ADD_SUCCESS = 'USER_PERSONALITY_CARD_ADD_SUCCESS';
export const USER_PERSONALITY_CARD_ADD_ERROR = 'USER_PERSONALITY_CARD_ADD_ERROR';

export const userPersonalityCardAdd = (payload, location) => {
  return {
    type: USER_PERSONALITY_CARD_ADD,
    payload,
    location
  }
};

export const userPersonalityCardAddSuccess = payload => {
  return {
    type: USER_PERSONALITY_CARD_ADD_SUCCESS,
    payload: payload.createdCard
  }
};

export const userPersonalityCardAddError = payload => {
  return {
    type: USER_PERSONALITY_CARD_ADD_ERROR,
    payload
  }
};

export const USER_PERSONALITY_CARD_UPDATE = 'USER_PERSONALITY_CARD_UPDATE';
export const USER_PERSONALITY_CARD_UPDATE_SUCCESS = 'USER_PERSONALITY_CARD_UPDATE_SUCCESS';
export const USER_PERSONALITY_CARD_UPDATE_ERROR = 'USER_PERSONALITY_CARD_UPDATE_ERROR';

export const userPersonalityCardUpdate = (payload, id) => {
  return {
    type: USER_PERSONALITY_CARD_UPDATE,
    id,
    payload
  }
};

export const userPersonalityCardUpdateSuccess = payload => {
  return {
    type: USER_PERSONALITY_CARD_UPDATE_SUCCESS,
    payload: payload.updatedCard
  }
};

export const userPersonalityCardUpdateError = payload => {
  return {
    type: USER_PERSONALITY_CARD_UPDATE_ERROR,
    payload
  }
};

export const USER_PERSONALITY_CARD_DELETE = 'USER_PERSONALITY_CARD_DELETE';
export const USER_PERSONALITY_CARD_DELETE_SUCCESS = 'USER_PERSONALITY_CARD_DELETE_SUCCESS';
export const USER_PERSONALITY_CARD_DELETE_ERROR = 'USER_PERSONALITY_CARD_DELETE_ERROR';

export const userPersonalityCardDelete = id => {
  return {
    type: USER_PERSONALITY_CARD_DELETE,
    id
  }
};

export const userPersonalityCardDeleteSuccess = payload => {
  return {
    type: USER_PERSONALITY_CARD_DELETE_SUCCESS,
    payload
  }
};

export const userPersonalityCardDeleteError = payload => {
  return {
    type: USER_PERSONALITY_CARD_DELETE_ERROR,
    payload
  }
};

/**
  User Lists 
**/
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST';

export const updateUserList = (listType, payload) => {
  return {
    type: UPDATE_USER_LIST,
    listType,
    payload
  }
}

/**
  User Ping
**/

export const USER_UPDATE_PING = 'USER_PING';

export const userUpdatePing = () => {
  return {
    type: USER_UPDATE_PING
  }
};

/**
  User Remove Outdated Contact Request
**/

export const USER_REMOVE_CONTACT_REQUEST = 'USER_REMOVE_CONTACT_REQUEST';

export const userRemoveContactRequest = (payload) => {
  return {
    type: USER_REMOVE_CONTACT_REQUEST,
    payload
  }
}

/**
  Selectors
**/

export const userGetAll = state => state.User.user;
export const userGetId = state => state.User.user.id;
export const userGetPhoto = state => state.User.user.photo;
export const userGetEmailAddress = state => state.User.user.email;
export const userGetUsername = state => state.User.user.username;

