import React, { Component } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'query-string';

import routes from '../../../config/routes';

import {
  authLogIn,
  authErrorsClear
} from '../../../store/Auth/actions';
import {
  appGetIsUserLoggedIn
} from '../../../store/App/actions';
import {
  inviteGet
} from '../../../store/Invites/actions';

import Input, { INPUT_SECONDARY } from '../../../common/Input';
import profileInputFields, {
  EMAIL_ADDRESS,
  PASSWORD
} from '../../../common/Input/CommonFields/profile';
import {
  checkRequiredFieldsFilled
} from '../../../common/Input/CommonFields/validations';
import Button, {
  THEME_BLACK
} from '../../../common/Button';
import { apiErrorMessage } from '../../../lib/API';

import './Login.scss';
import '../Auth.scss';
import LinkWithParams from '../../../common/LinkWithParams';

const REQUIRED_FIELDS = [
  EMAIL_ADDRESS,
  PASSWORD
];

class Login extends Component {
  static propTypes = {
    authLogIn: PropTypes.func.isRequired,
    logInErrorCode: PropTypes.number,
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      inputErrors: false,
      showError: false,
      errors: '',
      [EMAIL_ADDRESS]: '',
      [PASSWORD]: ''
    }

    const params = qs.parse(props.location.search);
    if (params.invite_token) props.inviteGet(params.invite_token);
  }

  componentWillUnmount = () => {
    this.props.authErrorsClear();
  }

  handleOnChange = (value, label, isValidated) => {
    this.setState({ [label]: value, inputErrors: !isValidated });
  }

  logIn = e => {
    e.preventDefault();

    const params = qs.parse(this.props.location.search);
    const { invites } = this.props;

    const hasReqFields = checkRequiredFieldsFilled(this.state, REQUIRED_FIELDS);
    const showError = !hasReqFields || this.state.inputErrors;

    this.setState({ showError, errors:hasReqFields ? '' : 'Please enter your login credentials' });

    if (hasReqFields) {
      this.props.authLogIn({
        [EMAIL_ADDRESS]: this.state[EMAIL_ADDRESS],
        [PASSWORD]: this.state[PASSWORD],
        inviteToken: params.invite_token,
        collective: params.invite_token && invites.length ? invites[0].collective.slug : null
      }, this.props.history);
    }
  }

  renderErrorMessage = () => {
    const { logInErrorCode } = this.props;
    
    if (logInErrorCode) {
      return <p className="error">{apiErrorMessage(logInErrorCode)}</p>;
    }
  }

  renderMissingFieldErrorMessage = () => {
    const { showError, errors } = this.state;

    if (showError) {
      return <p className="error">{errors}</p>;
    }
    return null;
  }

  render() {
    return (
      <div className="Login center--column maxWidth">
        <h3 className="centertext">
          Log In
        </h3>
        <form className="Auth__container--form" onSubmit={this.logIn}>
          <Input
            {...profileInputFields[EMAIL_ADDRESS]}
            onChange={this.handleOnChange}
            displayError={false}
            type="email"
            autoFocus
            theme={INPUT_SECONDARY}
          />
          <Input
            {...profileInputFields[PASSWORD]}
            placeholder="Password"
            onChange={this.handleOnChange}
            displayError={false}
            theme={INPUT_SECONDARY}
          />
          <Button
            onSubmit={this.logIn}
            onClick={this.logIn}
            theme={THEME_BLACK}
            isLoading={this.props.isLoading}
            type="submit"
            className="Login__button"
          >
            Log In
          </Button>
        </form>
        <LinkWithParams to={routes.signUp}>Don't have an account?</LinkWithParams>
        <Link to="/request-reset">Forgot password?</Link>


        {this.renderErrorMessage()}
        {this.renderMissingFieldErrorMessage()}
   
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: appGetIsUserLoggedIn(state),
    logInErrorCode: state.Auth.logInErrorCode,
    isLoading: state.Auth.isLoading,
    invites: state.Invites.invites
  }
};

export default withRouter(connect(mapStateToProps, {
  authLogIn,
  authErrorsClear,
  inviteGet
})(Login));
