import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import classnames from 'classnames';

import locationFormatter from '../../utils/locationFormatter';

import { isEmpty } from 'lodash';

import avatarIcon from '../../../public/assets/icons/AVATAR.svg';
import clockIcon from '../../../public/assets/icons/CLOCK.svg';
import waveIcon from '../../../public/assets/icons/WAVE.svg';
import bookmarkIcon from '../../../public/assets/icons/BOOKMARK.svg';
import crownIcon from '../../../public/assets/icons/CROWN.svg';
import addIcon from '../../../public/assets/icons/ADD.svg';
import bookmarkFilledIcon from '../../../public/assets/icons/BOOKMARK-FILLED.svg';
import checkIcon from '../../../public/assets/icons/CHECK.svg';

class UserListItem extends Component {

  static propTypes = {
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    photo: PropTypes.string
  }

  constructor (props) {
    super(props);

    this.state = {
      admireTransition: false
    }
  }

  onMessaging = () => {
    if (this.props.modalClose) this.props.modalClose();
    this.props.history.push(`/messaging/${this.props.contactURL}`);
  }
  
  onAdmireAdd = () => {
    if (this.state.admireTransition) return;
    
    this.props.admireAdd(this.props.user.id);
    this.setState({
      admireTransition: true
    }, () => {
      setTimeout(() => {
        this.setState({
          admireTransition: false
        })
      }, 1000)
    })
  }

  render () {
    const {
      addedUsers,
      isCurrentUser,
      user,
      contactRequestSent,
      contactURL,
      admireAdd,
      admiresMap,
      currentUser,
      sendContactRequest,
      showAdminBadge, 
      onClickAdd,
      children,
      noCTA
    } = this.props;

    const { admireTransition } = this.state;

    let admireSent = admiresMap[`${currentUser.id}-${user.id}`];

    let added;
    if (addedUsers) added = !!addedUsers[user.id];

    return (
      <div className="UserListItem">
        <div className="UserListItem__container">
          <div className="UserListItem__image" style={{backgroundImage:`url(${user.photo ? user.photo.medium : ''})`}}>
          {showAdminBadge && <span><SVG src={crownIcon} /></span>}
          </div>
          <div className="UserListItem__info">
            <h4>{user.first_name} {user.last_name}</h4>
            <p>{user.industry}</p>
            <p>{locationFormatter(user.location)}</p>
          </div>
          {
            !isCurrentUser && !children && !noCTA && (
              <div style={{zIndex:10}}>
                  {
                    onClickAdd && !isEmpty(user.collective_members) && (
                      <div className="UserListItem__connect--member">
                        Member
                      </div>
                    )
                  }
                  {
                    onClickAdd && isEmpty(user.collective_members) && (
                      <div 
                        className={classnames({
                        "UserListItem__connect UserListItem__connect--add": !added,
                        "UserListItem__connect UserListItem__connect--message": added
                        })} 
                        onClick={() => onClickAdd(user)}
                      >
                        { added ?  <SVG src={checkIcon} /> : <SVG src={addIcon} /> }
                      </div>
                    )
                  }
                  {
                    (admireTransition || !admireSent && !contactURL && !contactRequestSent && !onClickAdd) && (
                      <a title="Admire" className={classnames("UserListItem__connect", "UserListItem__connect--wave", {
                        'admire-transition': admireTransition
                      })} onClick={this.onAdmireAdd}>
                       {admireTransition ? <SVG src={bookmarkFilledIcon} /> : <SVG src={bookmarkIcon} />}
                      </a>
                    )
                  }
                  {
                    !admireTransition && admireSent && !contactURL && !contactRequestSent && !onClickAdd  && (
                      <a title="Say Hey" className="UserListItem__connect UserListItem__connect--wave" onClick={() => sendContactRequest(user)}>
                        <img src={waveIcon} />
                      </a>
                    )
                  }
                  {
                    contactURL && !onClickAdd && (
                      <Link
                        title="Connected"
                        className="UserListItem__connect UserListItem__connect--message"
                        to={`/${user.username}`}
                      >
                        <SVG src={avatarIcon} />
                      </Link>
                    )
                  }
                  {
                    !contactURL && contactRequestSent && !onClickAdd && (
                      <a title="Request Sent" className="UserListItem__connect UserListItem__connect--sent">
                        <SVG src={clockIcon} />
                      </a>
                    )
                  }
              </div>
            )
          }
          { children }
        </div>

        {
          !this.props.onUserClick && (
          <Link to={`/${this.props.user.username}`} onClick={this.props.modalClose}>
            <div className="UserListItem__container-link" />
          </Link>)
        }
        { this.props.onUserClick && (isEmpty(user.collective_members) || !user.collective_members) && 
          <div onClick={() => this.props.onUserClick(this.props.user)} className="UserListItem__container-link" />
        }
      </div>
    )
  }
}

export default withRouter(UserListItem);