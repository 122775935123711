export const TITLE = 'title';
export const BODY = 'body';

export const postFields = {
  TITLE,
  BODY
};

export const postInputFields = {
  [TITLE]: {
    label: TITLE,
    minLength: 10,
    maxLength: 150
  },
  [BODY]: {
    label: BODY,
    minLength: 0,
    maxLength: 2500
  }
};

export default postInputFields;
