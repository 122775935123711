import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import fetchAPI from '../../services/APIService';
import './UserTagSelector.scss';
import UserTagEntry from './UserTagEntry';

class UserTagSelector extends Component {
  static propTypes = {
    horizontal:PropTypes.number,
    vertical:PropTypes.number,
    search:PropTypes.string,
    tags:PropTypes.array,
    addTag:PropTypes.func
  };

  constructor (props) {
    super(props);

    this.root = document.getElementById('root');
    this.containerRef = React.createRef();

    this.state = {
      isLoading:true,
      currentSelection:0,
      users:[],
      bestPosition:{}
    }
  }

  componentWillMount = () => {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('scroll', this.calculateBestPosition);
    window.removeEventListener('resize', this.calculateBestPosition);
  }

  handleKeyDown = e => {
    let { currentSelection } = this.state;
    // Down arrow
    if (e.keyCode == 40) currentSelection++;
    // Up arrow
    if (e.keyCode == 38) currentSelection--;
    if (currentSelection < 0) currentSelection = 0;
    if (currentSelection >= this.state.users.length) currentSelection = this.state.users.length - 1;
    if (e.keyCode == 38 || e.keyCode == 40) {
      e.preventDefault();
      this.setState({currentSelection});
    }
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.calculateBestPosition);
    window.addEventListener('resize', this.calculateBestPosition);
    this.search();
  }
  
  componentDidUpdate = (prevProps) => {
    if (prevProps.search !== this.props.search) {
      this.search();
    }
  }

  calculateBestPosition = () => {
    const { horizontal, vertical } = this.props;
    const windowHeight = window.innerHeight;

    let style = {
      left: horizontal + 'px'
    }

    if (this.containerRef.current) {
      // if (vertical > windowHeight / 2) style.bottom = windowHeight - vertical + 4;
      // else style.top = vertical + 24;
      let top;
      this.props.label ? top = 4 : top = 30;
      if (window.innerWidth > 960) {
        style.top = vertical - this.containerRef.current.offsetHeight + window.scrollY;
      } else {
        style.top = window.scrollY + top;
      }
      style.maxHeight = vertical;
    }

    this.setState({bestPosition:style});
  }

  search = () => {
    const { search, user, tags } = this.props;

    fetchAPI('getUsers', {
      connectedUserId: user.id,
      name: search,
      exclude: tags.map(t => t.id).join(','),
      limit: 5
    }).then((resp) => {
      if (resp.success) {
        this.setState({
          users: resp.data,
          isLoading: false
        }, () => {
          this.calculateBestPosition();
        });
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  }

  preventBubbling = (e) => {
    e.stopPropagation();
  }

  render() {
    const { isLoading, users, currentSelection, bestPosition } = this.state;
    const { addTag } = this.props;

    if (!users.length) return null;

    return ReactDOM.createPortal(
      <div className="UserTagSelector__container" ref={this.containerRef} style={bestPosition} onClick={this.preventBubbling}>
        {
          (isLoading) && (
            <div style={{padding:'15px'}}>Searching...</div>
          )
        }
        <ul>
          {
            users.map((user, index) => (
              <UserTagEntry
                selected={index == currentSelection}
                id={user.id}
                name={`${user.first_name.trim()} ${user.last_name.trim()}`}
                headline={user.headline}
                photo={user.photo.thumbnail}
                key={user.username}
                addTag={addTag}
              />
            ))
          }
        </ul>
      </div>,
      this.root
    )
  }
}


const mapStateToProps = ({ User }) => {
  return {
    user: User.user
  }
};

export default connect(mapStateToProps, null)(withRouter(UserTagSelector));