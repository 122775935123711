import {
  CLEAR_ACTIVITY,
  ACTIVITY_GET_FULFILLED
} from './actions';

const defaultState = {
  activity: [],
  limit: 10,
  total: 0,
  offset: 0,
  hasLoadedAllActivity: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_ACTIVITY:
      return {
        ...state,
        activity:[],
        total:0,
        offset:0,
        hasLoadedAllActivity:false
      }
    case ACTIVITY_GET_FULFILLED: {
      return {
        ...state,
        activity:state.activity.concat(action.payload.data),
        total:action.payload.total,
        offset:Math.min(state.offset + action.payload.data.length, action.payload.total),
        hasLoadedAllActivity: action.payload.total == Math.min(state.offset + action.payload.data.length, action.payload.total)
      }
    }
    default:
      return state;
  }
};
