import { takeEvery, take, put, call, select } from 'redux-saga/effects';
import qs from 'query-string';
import fetchAPI from '../../services/APIService';
import { removeToken } from '../../lib/localStorage';
import { LANDING_PAGE_URL } from '../../config';
import { apiErrorMessage, validationErrorsToObject } from '../../lib/API';
import {
  setToken
} from '../../lib/localStorage';
import {
   AUTH_LOGIN,
   authLogInSuccess,
   authLogInError,
   AUTH_SIGNUP,
   authSignUpSuccess,
   authSignUpError,
   AUTH_INFO_CHECK,
   authInfoCheckSuccess,
   authInfoCheckError,
   AUTH_USERNAME_CHECK,
   authUsernameCheckSuccess,
   authUsernameCheckError,
   AUTH_PHONE_CHECK,
   AUTH_POST_LOGIN,
   AUTH_LOGOUT,
   authLogOutSuccess,
} from './actions';
import {
  userSave,
  userGet,
  USER_GET_COMPLETE,
  USER_GET_ERROR,
  userUpdatePing
} from '../User/actions';
import {
  onboardingInfoCheckSuccess,
  onboardingInfoCheckError,
  onboardingUsernameCheckSuccess,
  onboardingUsernameCheckError,
  onboardingPhoneCheckSuccess,
  onboardingPhoneCheckError,
  onboardingSignupSuccess,
  onboardingSignupError
} from '../Onboarding/actions';
import {
  getContentTags
} from '../Feed/actions';
import {
  notifsGet,
  NOTIFS_GET_SUCCESS,
  NOTIFS_GET_ERROR,
  notifsGetUserSendbirdId,
  notifsGetMessages
} from '../Notifications/actions';
import {
  appInitialize,
  appInitializeSuccess,
  appGetIsUserSubscribed
} from '../App/actions';
import {
  GTMpushDataLayerEvent,
  GTM_LOGIN_SUCCESS,
  GTM_LOGOUT_SUCCESS,
  GTM_SIGNUP_SUCCESS
} from '../../lib/GoogleTagManager';
import { inviteAccept } from '../Invites/actions';

function* authSagaLogIn(action) {
  const logInResponse = yield call(fetchAPI, 'login', { body: action.payload });

  if (logInResponse.success) {
    setToken(logInResponse.token);

    yield put(appInitialize());

    // gtm
    GTMpushDataLayerEvent({
      event: GTM_LOGIN_SUCCESS,
      userId: action.payload.email
    });

    yield take(USER_GET_COMPLETE);

    // If a referrer path/query is present in the query params,
    // redirect to that location. Otherwise, redirect to home.
    const { ref } = qs.parse(window.location.search);
    const collectiveURL = action.payload.collective ? `/groups/${action.payload.collective}` : null;
    const redirectUrl = ref || collectiveURL || '/';

    if (action.payload.inviteToken) {
      yield put(inviteAccept(action.payload.inviteToken));
    }

    action.history.push(redirectUrl);
    yield put(authLogInSuccess(logInResponse));
  } else {
    yield put(authLogInError(logInResponse)); 
  }
}

function* authSagaLogInAfterSignup(action) {
  const logInResponse = yield call(fetchAPI, 'login', { body: action.payload });

  if (logInResponse.success) {
    setToken(logInResponse.token);
    yield put(appInitialize());

    // gtm
    GTMpushDataLayerEvent({
      event: GTM_LOGIN_SUCCESS,
      userId: action.payload.email
    });

    yield take(USER_GET_COMPLETE);

    yield put(authLogInSuccess(logInResponse));
  } else {
    yield put(authLogInError(logInResponse));
  }
}

export function* authSagaPostLogin(action) {
  // TODO needs fixing in order/right after each put call

  yield put(userGet());
  yield take([
    USER_GET_COMPLETE,
    USER_GET_ERROR
  ]);

  const isUserSubscribed = yield select(appGetIsUserSubscribed);

  if (isUserSubscribed) {
    yield put(notifsGet());
    yield take([NOTIFS_GET_SUCCESS, NOTIFS_GET_ERROR]);

    const sendbirdId = yield select(notifsGetUserSendbirdId);
    if(sendbirdId) yield put(notifsGetMessages(sendbirdId));

    yield put(getContentTags());
  }

  yield put(userUpdatePing());
  yield put(appInitializeSuccess());
}

function* authSagaSignUp(action) {
  const signUpResponse = yield call(fetchAPI, 'signup', { body: action.payload });

  if (signUpResponse.success) {
    setToken(signUpResponse.token);
    yield put(userSave(signUpResponse.user));
    yield put(appInitialize());

    yield take(USER_GET_COMPLETE);

    // (JC) This never worked. You can't pass a generator function to put(), only an action.
    // Not sure if it authSagaLogInAfterSignup() should be called or not.
    // yield put(authSagaLogInAfterSignup());

    yield put(onboardingSignupSuccess());
    yield put(authSignUpSuccess());

    if (action.payload.inviteToken) {
      yield put(inviteAccept(action.payload.inviteToken));
    }
  } else {
    yield put(onboardingSignupError(signUpResponse));
    yield put(authSignUpError(signUpResponse));
  }

  GTMpushDataLayerEvent({
    event: GTM_SIGNUP_SUCCESS
  });
}

function* authSagaInfoCheck(action) {
  const response = yield call(fetchAPI, 'authValidations', { body: action.payload });

  if (response.success) {
    yield put(onboardingInfoCheckSuccess(response));
    yield put(authInfoCheckSuccess(response));
  } else {
    const { status, errors, ...payload } = response;
    if (status === 422) {
      payload.errors = validationErrorsToObject(errors);
    } else {
      payload.errors = { email: [{ message: apiErrorMessage(response) }] };
    }
    yield put(onboardingInfoCheckError(payload));
    yield put(authInfoCheckError(payload));
  }
}

function* authSagaUsernameCheck(action) {
  const response = yield call(fetchAPI, 'authValidations', { body: action.payload });

  if (response.success) {
    yield put(onboardingUsernameCheckSuccess(response));
    yield put(authUsernameCheckSuccess(response));
  } else {
    const { status, errors, ...payload } = response;
    if (status === 422) {
      payload.errors = validationErrorsToObject(errors);
    } else {
      payload.errors = { username: [{ message: apiErrorMessage(response) }] };
    }
    yield put(onboardingUsernameCheckError(payload));
    yield put(authUsernameCheckError(payload));
  }
}

function* authSagaPhoneCheck(action) {
  const response = yield call(fetchAPI, 'phoneCheck', { body: action.payload });

  if (response.success) {
    yield put(onboardingPhoneCheckSuccess(response));
  } else {
    const { status, errors, ...payload } = response;
    if (status === 422) {
      payload.errors = validationErrorsToObject(errors);
    } else {
      payload.errors = { phone_number: [{ message: apiErrorMessage(response, 'phoneCheck') }] };
    }
    yield put(onboardingPhoneCheckError(payload));
  }
}

function* authSagaLogout(action) {
  yield call(fetchAPI, 'logout', { body: {} });
  window.location = LANDING_PAGE_URL;

  removeToken();

  yield put(authLogOutSuccess());

  GTMpushDataLayerEvent({
    event: GTM_LOGOUT_SUCCESS
  });
}

export const authSagas = [
  takeEvery(AUTH_LOGIN, authSagaLogIn),
  takeEvery(AUTH_SIGNUP, authSagaSignUp),
  takeEvery(AUTH_LOGOUT, authSagaLogout),
  takeEvery(AUTH_INFO_CHECK, authSagaInfoCheck),
  takeEvery(AUTH_USERNAME_CHECK, authSagaUsernameCheck),
  takeEvery(AUTH_PHONE_CHECK, authSagaPhoneCheck),
  takeEvery(AUTH_POST_LOGIN, authSagaPostLogin)
];
