export const BODY = 'body';

export const commentFields = {
  BODY
};

export const commentInputFields = {
  [BODY]: {
    label: BODY,
    minLength: 1,
    maxLength: 2500
  }
};

export default commentInputFields;
