import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './WithError.scss';

class WithError extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    error: PropTypes.object
  };

  render() {
    const { className, error, errorClassName } = this.props;

    return (
      <div className={classNames(className, "WithError__full-width")}>
        {this.props.children}
        {error && (
          <p className={classNames("WithError__padding", errorClassName)}>
            {error.message}
          </p>
        )}
      </div>
    )
  }
}

export default WithError;
