import React, { Component } from 'react';
import classNames from 'classnames';

import './ModalContainer.scss';

export class ModalContainer extends Component {
  setRef = ref => {
    if (this.props.setRef) this.props.setRef(ref);
  }

  render() {
    return (
      <div
        className={classNames(
          "ModalContainer",
          this.props.className
        )}
        ref={this.setRef}
      >
        <div
          className={classNames(
            "ModalContainer__content",
            this.props.modalContainerClassName
          )}
        >
        {this.props.children}
        </div>
      </div>
    )
  }
}

export const ModalHeader = props => {
  return (
    <div className="ModalContainer__header">
      {props.header && <h1>{props.header}</h1>}

      {props.subHeader && <h2>{props.subHeader}</h2>}

      {props.children}
    </div>
  )
}

export class ModalBody extends Component {
  setRef = ref => {
    if (this.props.setRef) this.props.setRef(ref);
  }

  render() {
    return (
      <div className="ModalContainer__body" ref={this.setRef}>
        {this.props.children}
      </div>
    )
  }
}

export const ModalFooter = props => {
  return (
    <div className="ModalContainer__footer">
        {props.children}
    </div>
  )
}

