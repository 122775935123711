import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import linkifyhtml from 'linkifyjs/html';
import './UserTagParser.scss';
import * as tagUtils from '../../utils/userTag.js';

class UserTagParser extends Component {
  static propTypes = {
    body:PropTypes.string
  };

  parseTags = (val, tags) => {
    val = linkifyhtml(val);

    if (tags) {
      tags.forEach(tag => {
        if (tag.user.user_status !== 'banned') {
          const tagRegex = tagUtils.getRegExpForUserId(tag.user_id);
          val = val.replace(tagRegex, `<a href="/${tag.user.username}">${tag.user.first_name} ${tag.user.last_name}</a>`);
        }
      });
    }

    val = this.hideTags(val);

    return val;
  }

  hideTags = (val) => {
    val = val.replace(tagUtils.TAG_MARKUP_REGEX_CAPTURE, '$1');
    val = val.replace(tagUtils.TAG_MARKUP_REGEX_NO_SUFFIX, '');
    return val;
  }

  render() {
    const { body, tags, hideTags } = this.props;

    return (
      <React.Fragment>
        {
          hideTags && this.hideTags(body)
        }
        {
          !hideTags && (
            <span className="UserTagParser" dangerouslySetInnerHTML={{__html:this.parseTags(body, tags, hideTags)}}></span>
          )
        }
      </React.Fragment>
    )
  }
}

export default UserTagParser;
