import {
  CLEAR_SEARCH,
  SEARCH_GET_FULFILLED,
  SEARCH_SET_VIEWING,
  SEARCH_SET_QUERY,
  SEARCH_SET_POSITION
} from './actions';

const defaultState = {
  viewing: 'everything',
  query: '',
  categories: {},
  initialLoadComplete: false,
  shouldReset: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_SEARCH:
      return {
        ...state,
        shouldReset:true
      }
    case SEARCH_SET_VIEWING: {
      return {
        ...state,
        viewing: action.payload.option
      }
    }
    case SEARCH_SET_QUERY: {
      return {
        ...state,
        query: action.payload.query
      }
    }
    case SEARCH_SET_POSITION: {
      let categories = { ...state.categories };
      if (categories[action.payload.category] && categories[action.payload.category].length === 1) categories[action.payload.category][0].scrollPosition = action.payload.scrollPosition;
      return {
        ...state,
        categories
      }
    }
    case SEARCH_GET_FULFILLED: {
      let categories = {};
      if (!state.shouldReset) categories = { ...state.categories };
      
      let category = categories[action.request.key];

      if (action.payload.data.length > 1) {
        categories[action.request.key] = action.payload.data;
      } else {
        category = [{
          index: action.payload.data[0].index,
          results: (category ? category[0].results : []).concat(action.payload.data[0].results),
          total: action.payload.data[0].total,
          offset: action.payload.data[0].results.length + (category ? category[0].offset : 0),
          hasLoadedAll: action.payload.data[0].results.length + (category ? category[0].offset : 0) == action.payload.data[0].total,
          scrollPosition: category ? category[0].scrollPosition : 0
        }]
        categories[action.request.key] = category;
      }

      return {
        ...state,
        categories,
        initialLoadComplete:true,
        shouldReset:false
      }
    }
    default:
      return state;
  }
};
