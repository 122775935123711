import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Post from '../Post';

import InfiniteScroller from '../../components/InfiniteScroller';

import Input, { THEME_WHITE } from '../../common/Input';
import Button, { THEME_GRAY } from '../../common/Button';

import postInputFields, {
  TITLE,
  BODY
} from '../../common/Input/CommonFields/post';
import commentInputFields from '../../common/Input/CommonFields/comment';

import Comment from './Comment';
import { selectRequest } from '../../store/requests';
import { modalOpen } from '../../store/Modal/actions';
import {
  reactPost,
  reactPostRemove,
  reactComment,
  reactCommentRemove,
  reactReply,
  reactReplyRemove
} from '../../store/Reactions/actions';
import { comment, reply } from '../../store/Comment/actions';
import { postDelete, ajaxGetPost, getPostComments } from '../../store/Post/actions';
import { POST_MODAL, DELETE_MODAL } from '../ModalManager/ModalTypes';

import NotFound from '../../components/NotFound';
import UnauthorizedMessage from './UnauthorizedMessage';

import { GTMpushDataLayerEvent, GTM_COMMENT_ON_POST } from '../../lib/GoogleTagManager';
import withSizes, { mapSizesToProps } from '../../lib/WithSizes';


import ReplyInputBox from './ReplyInputBox';

import './Thread.scss';

class Thread extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [BODY]: '',
      replyKey: 0,
      hasScrolledToComment: false,
      selected_replybox: null // for mobile
    }

    this.ajaxRefresh = 0;
  }

  selectReplyBox = (id) => {
    this.setState({
      selected_replybox: id
    })
  }

  componentDidMount = () => { 
    if (this.inputRef && this.props.isDesktop) {
      this.inputRef.focus();
    }

    if(this.props.location.hash === '') {
      window.scrollTo(0,0);
    }

    this.ajaxRefresh = setInterval(this.ajaxRefreshCall, 60 * 1000);

    this.checkForCommentHash();
  }

  componentDidUpdate = (prevProps) => {
    this.checkForCommentHash();
    if (prevProps.commentRequest.pending && this.props.commentRequest.fulfilled) {
      this.commentSuccess();
    }
  }

  checkForCommentHash = () => {
    const { post, location } = this.props;
    const { hasScrolledToComment } = this.state;

    if (post) {
      const commentId = parseInt(location.hash.substr(1));
      if (!isNaN(commentId) && commentId !== hasScrolledToComment) {
        if (post.comments.length) {
          this.setState({hasScrolledToComment:commentId});
          const e = document.getElementById(commentId);
          if (e) {
            const scrollTarget = e.getBoundingClientRect().top + window.scrollY - 100;
            requestAnimationFrame(() => {
              window.scrollTo({ top:scrollTarget, behavior:'smooth' });
            });
          } else {
            window.scrollTo({top:10000, behavior:'smooth'});
          }
        }
      }
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.ajaxRefresh);
  }

  ajaxRefreshCall = () => {
    const { post, limit, loadComments } = this.props;

    if(this.props.post.hasLoadedAllComments) {
      loadComments({
        id:post.id,
        offset:post.comment_count,
        limit
      });
    }
  }

  handleEditPost = () => {
    const { post } = this.props;

    this.props.modalOpen({
      modal: POST_MODAL,
      id: post.id,
      [TITLE]: post.title,
      [BODY]: post.body,
      topics: post.topics,
      implicitExit: false,
      position: 'belowNav'
    });
  }

  handleDeletePost = () => {
    this.props.postDelete(this.props.post.id);
  }

  handleChange = value => {
    this.setState({ [BODY]: value });
  }

  submitComment = e => {
    e.preventDefault();   

    const commentBody = this.state[BODY];

    if (commentBody.trim().length && !this.props.commentRequest.pending) {
      const id = this.props.computedMatch.params.id;
      const post = this.props.posts[id];

      this.props.comment({body: commentBody, postId: post.id});

      // gtm
      GTMpushDataLayerEvent({
        event: GTM_COMMENT_ON_POST,
        commentLength: commentBody.length
      });
    }
  }

  commentSuccess = () => {
    this.setState({ [BODY]: '', replyKey:this.state.replyKey + 1 });
  }

  focusInput = () => {
    this.inputRef.focus();
  }

  setRef = ref => this.inputRef = ref;

  setContentRef = (id, ref) => this[id] = ref;

  onComment = () => {
    this.focusInput();

    this.setState({
      selected_replybox: null
    })
  }

  goBack = () => {
    this.props.history.goBack();
  }

  loadComments = () => {
    const { post, limit, loadComments } = this.props;
    loadComments({
      id:post.id,
      offset:post.offset || post.comments.length,
      limit
    });
  }

  render() {
    const { 
      post, 
      currentUserId, 
      isLoading, 
      contentTags, 
      pathname, 
      currentSlug, 
      collectives, 
      isLoggedIn,
      postError
    } = this.props;

    let adminIds;
    if (currentSlug) adminIds = collectives[currentSlug].members
      .filter(member => member.role === 'admin')
      .map(member => member.user.id);

    if (!post) {
      if (isLoggedIn) {
        return <NotFound />;
      } else {
        if (postError === 403) {
          return <UnauthorizedMessage />;
        } else {
          return <NotFound />;
        }
      }
    } 

    return (
      <div className="Thread">
        <Helmet title={`Girlboss - ${post.title}`} />
        {
          pathname !== null && (
            <Button theme={THEME_GRAY} pill noOutline onClick={this.goBack} className="Thread__back-button">
              <span className="Thread__back-button-arrow"></span> Back
            </Button>
          )
        }
        <Post
          actions={{
            handleEdit: this.handleEditPost,
            handleDelete: this.handleDeletePost
          }}
          bodyClassName="Thread__body"
          post={post}
          onReact={this.props.reactPost}
          onReactRemove={this.props.reactPostRemove}
          onComment={this.onComment}
          variant="thread"
          currentUserId={currentUserId}
          contentTags={contentTags}
          showTimeStamp={post.priority === 0}
          viewAll={true}
          showAdminBadge={adminIds ? adminIds.indexOf(post.creator.id) > -1 : null}
        />

        { isLoggedIn && (
          <ReplyInputBox
            setRef={this.setRef}
            isDesktop={this.props.isDesktop}
            handleChange={this.handleChange}
            unfocusInput={this.unfocusInput}
            focusInput={this.focusInput}
            value={this.state[BODY]}
            onSubmit={this.submitComment}
            placeholder="Leave a reply..."
            key={this.state.replyKey}
          />
        )}
        
        { post.comments && post.comments.length > 0 ? <h3 className="Thread__subtitle">Replies</h3> : null}

        <div className="Thread__container--comments">
          <div className="Thread__comments--body">
            <InfiniteScroller
              shouldLoadMore={!isLoading && !post.hasLoadedAllComments}
              isLoading={isLoading}
              hasLoadedAll={post.hasLoadedAllComments}
              loadMore={this.loadComments}
              threshold={350}
            >
              {
                post.comments && post.comments.map(comment => (
                  <Comment
                    comment={comment}
                    key={comment.id}
                    id={comment.id}
                    setRef={this.setContentRef}
                    isReacted={comment.is_reacted}
                    onReact={this.props.reactComment}
                    onReactRemove={this.props.reactCommentRemove}
                    onReactReply={this.props.reactReply}
                    onReactReplyRemove={this.props.reactReplyRemove}
                    postId={post.id}
                    onReply={this.props.reply}
                    currentUserId={currentUserId}
                    selectReplyBox={this.selectReplyBox}
                    selected_replybox={this.state.selected_replybox}
                    isDesktop={this.props.isDesktop}
                    replies={comment.replies}
                    adminIds={adminIds}
                  />
                ))
              }
            </InfiniteScroller>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.App.isLoggedIn,
    isLoading: state.Post.isLoadingPostComments,
    limit: state.Post.limit,
    currentUserId: state.User.user.id,
    contentTags: state.Feed.contentTags,
    pathname: state.App.pathname,
    currentSlug: state.Collectives.currentSlug,
    collectives: state.Collectives.collectives,
    commentRequest: selectRequest(state, comment),
    postError: state.Post.error
  }
};

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args)),
    reactPost: (args) => dispatch(reactPost(args)),
    reactPostRemove: (args) => dispatch(reactPostRemove(args)),
    reactComment: (...args) => dispatch(reactComment(...args)),
    reactCommentRemove: (...args) => dispatch(reactCommentRemove(...args)),
    reactReply: (...args) => dispatch(reactReply(...args)),
    reactReplyRemove: (...args) => dispatch(reactReplyRemove(...args)),
    comment: (...args) => dispatch(comment(...args)),
    loadComments: (...args) => dispatch(getPostComments(...args)),
    reply: (...args) => dispatch(reply(...args)),
    postDelete: (args) => dispatch(postDelete(args)),
    ajaxGetPost: (args) => dispatch(ajaxGetPost(args))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withRouter(withSizes(mapSizesToProps)(Thread))));
