import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  IM_GOOD_AT,
  ID_LIKE_TO_LEARN,
  ID_LIKE_TO_MEET,
  BIO
} from '../../../common/Input/CommonFields/profile';

import ProfileAddContainer from '../ProfileAddContainer';
import ProfileSentence from './ProfileSentence';

import EditIcon from '../EditIcon';

import './ProfileDescription.scss';

const FIELDS = [
  {
    label: [IM_GOOD_AT],
    id:"good-at",
    text: "I'm good at",
    placeholder: "so, so many things"
  },
  {
    label: [ID_LIKE_TO_LEARN],
    id:"like-to-learn",
    text: "I'd like to learn",
    placeholder: "a lot of stuff"
  },
  {
    label: [ID_LIKE_TO_MEET],
    id:"like-to-meet",
    text: "I'd like to meet",
    placeholder: "cool people"
  }
];

class ProfileDescription extends PureComponent {
  static defaultProps = {
    isEditing:false
  }

  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    update: PropTypes.func,
    [BIO]: PropTypes.shape({
      [IM_GOOD_AT]: PropTypes.string,
      [ID_LIKE_TO_LEARN]: PropTypes.string,
      [ID_LIKE_TO_MEET]: PropTypes.string
    })
  };

  constructor (props) {
    super(props);

    this.state = {
      bio:props.bio || {}
    };
  }

  updateBio = (id, value) => {
    let newBio = { ...this.state.bio };

    for (let i = 0; i < FIELDS.length; i++) {
      if (FIELDS[i].id === id) {
        newBio[FIELDS[i].label] = value;
      }
    }
    
    this.setState({bio:newBio});
    this.props.update(newBio);
  };

  render() {
    const { bio, isEditing } = this.props;

    return (
      <div>
        {
          FIELDS.map(field => (
            <ProfileSentence
              id={field.id}
              sentence={field.text}
              label={field.label[0]}
              text={bio[field.label]}
              key={field.label}
              placeholder={field.placeholder}
              isEditing={isEditing}
              updateBio={this.updateBio}
            />
          ))
        }
      </div>
    )
  }
}

export default ProfileDescription;
