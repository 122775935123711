import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { modalOpen } from '../../store/Modal/actions';
import { CONTACT_REQUEST_RESPONSE_MODAL } from '../../containers/ModalManager/ModalTypes.js';

import Notification from './index';

class NotificationContactRequest extends Component {
  openRequestModal = () => {
    const { modalOpen, sender, contactRequest, receiver, closeSideNav } = this.props;
    
    if (closeSideNav) closeSideNav();

    modalOpen({
      modal: CONTACT_REQUEST_RESPONSE_MODAL,
      contactRequest:contactRequest.contact_request,
      sender,
      receiver
    });
  }

  render() {
    const { props } = this;

    const {
      contentClassName,
      sender,
      contactRequest,
      closeSideNav
    } = this.props;

    if(contactRequest.contact_request.accepted) {
      return (
        <Notification
          onClick={closeSideNav}
          {...props}
          link={`/${this.props.sender.username}`}
        >
          <p
            className={classNames(
              "Notification__text--content",
              contentClassName
            )}
          >
            You are now connected with {sender.first_name} {sender.last_name}!
          </p>
        </Notification>
      )
    } else if(contactRequest.contact_request.ignored_at) {
      return (
        <Notification {...props}>
          <p
            className={classNames(
              "Notification__text--content",
              this.props.contentClassName
            )}
          >
              You ignored a request from <Link className="NotificationText__text--link" to={`/${this.props.sender.username}`}>{sender.first_name} {sender.last_name}</Link>.
          </p>
        </Notification>
      );
    } else {
      return (
        <React.Fragment>
          <Notification
            onClick={this.openRequestModal}
            {...props}
          >
            <p
              className={classNames(
                "Notification__text--content",
                this.props.contentClassName
              )}
            >
              You received a contact request from {sender.first_name} {sender.last_name}!
            </p>
          </Notification>
        </React.Fragment>
      )
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args))
  }
}

export default connect(null, mapDispatchToProps)(withRouter(NotificationContactRequest));
