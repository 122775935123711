import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RibbonIcon from '../../components/SVG/RibbonIcon.js';

class PerksItem extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  };

  render() {
    const { text } = this.props;

    return (
      <div>
        <div className="LandingPage__svg">
          <RibbonIcon />
        </div>
        <div className="LandingPage__link__content">
          {text}
        </div>
      </div>
    )
  }
}

export default PerksItem;
