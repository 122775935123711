import { combineReducers } from 'redux';

import { requestsReducer } from './requests';
import Activity from './Activity/reducer';
import Admires from './Admires/reducer';
import App from './App/reducer';
import Auth from './Auth/reducer';
import Collectives from './Collectives/reducer';
import Comment from './Comment/reducer';
import Connections from './Connections/reducer';
import Contact from './Contact/reducer';
import Directory from './Directory/reducer';
import Feed from './Feed/reducer';
import Invites from './Invites/reducer';
import Location from './Location/reducer';
import Modal from './Modal/reducer';
import Notifications from './Notifications/reducer';
import Onboarding from './Onboarding/reducer';
import Reactions from './Reactions/reducer';
import Post from './Post/reducer';
import Profile from './Profile/reducer';
import PasswordReset from './PasswordReset/reducer';
import Preferences from './Preferences/reducer';
import ProfileEntities from './ProfileEntities/reducer';
import Search from './Search/reducer';
import Toaster from './Toaster/reducer';
import User from './User/reducer';
import Magic from './Magic/reducer';

import {
  AUTH_LOGOUT
} from './Auth/actions';
import {
  APP_VALIDATE_TOKEN_FAILURE
} from './User/actions';

const appReducer = combineReducers({
  requests: requestsReducer,
  Activity,
  Admires,
  App,
  Auth,
  Collectives,
  Comment,
  Connections,
  Contact,
  Directory,
  Feed,
  Invites,
  Location,
  Modal,
  Notifications,
  Onboarding,
  Post,
  Profile,
  Preferences,
  Reactions,
  Search,
  Toaster,
  ProfileEntities,
  Magic,
  User,
  PasswordReset
});

const clearCases = [
  AUTH_LOGOUT,
  APP_VALIDATE_TOKEN_FAILURE
];

const rootReducer = (state, action) => {
  if (clearCases.includes(action.type)) {
    state = undefined;
  };

  return appReducer(state, action);
};

export default rootReducer;
