import React, { Component } from 'react'
import { connect } from 'react-redux';

import { modalClose } from '../../../store/Modal/actions';
import { onSelectingTag } from '../../../store/Feed/actions';

import { ModalContainer, ModalBody, ModalHeader, ModalFooter} from '../ModalContainer';
import ContentTags from '../../../components/ContentTags';
import Button, { THEME_BLACK } from '../../../common/Button';

import './FilterContentTagsModal.scss';

class FilterContentTagsModal extends Component {
  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  onClearTags = () => {
    if(this.props.contentTags.length !== 0) {
        this.props.onSelectingTag('all');
    }
  }

  render() {
    return (
      <ModalContainer 
        setRef={this.setRef}
        className="FilterContentTagsModal">
          {this.props.children}
          <ModalHeader header="Filter by topic" />

          <ModalBody setRef={this.setRef}>
            <div className="FilterContentTagsModal__container">
              <ContentTags viewport='mobile' />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button 
              onClick={this.onClearTags} 
              className="FilterContentTagsModal__container--clear-btn" 
              pill 
              noOutline
            >
              Clear Filters
            </Button>

            <Button 
              onClick={this.props.modalClose} 
              theme={THEME_BLACK} 
              className="FilterContentTagsModal__container--cta"
            >Update
            </Button>

          </ModalFooter>

      </ModalContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
      contentTags: state.Feed.contentTags
  }
}

export default connect(mapStateToProps, { modalClose, onSelectingTag })(FilterContentTagsModal);