import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import {
  isNoEmoji
} from '../../common/Input/CommonFields/validations';
import {
  appGetIsUserLoggedIn
} from '../../store/App/actions';
import {
  onboardingProfileStarted,
  onboardingSave,
  onboardingSaveClear,
  onboardingError,
  onboardingErrorClear,
  onboardingNext,
  onboardingBack
} from '../../store/Onboarding/actions';
import {
  userUpdate
} from '../../store/User/actions';

import { submitEmailToWaitlistReferral } from '../../store/App/actions';

import RegistrationIcon from '../../../public/assets/onboarding/registration-icon.png';
import AboutYouIcon from '../../../public/assets/onboarding/about-you-icon.png';
import ProfileSetupIcon from '../../../public/assets/onboarding/profile-setup-icon.png';

import Onboarding from '../Onboarding';
import OnboardingGoodAt from './OnboardingGoodAt';
import OnboardingLearning from './OnboardingLearning';
import OnboardingMeet from './OnboardingMeet';
import OnboardingTitle from './OnboardingTitle';
import OnboardingLocation from './OnboardingLocation';
import OnboardingIndustry from './OnboardingIndustry';
import OnboardingPhoto from './OnboardingPhoto';
import OnboardingProfileComplete from './OnboardingProfileComplete';

class OnboardingProfile extends PureComponent {

  constructor(props) {
    super(props);
    props.onboardingSaveClear();

    let initialValues = {};
    const { user } = props;

    initialValues.im_good_at = user.bio ? user.bio.im_good_at : null;
    initialValues.id_like_to_learn = user.bio ? user.bio.id_like_to_learn : null;
    initialValues.id_like_to_meet = user.bio ? user.bio.id_like_to_meet : null;
    initialValues.headline = user.headline || null;
    initialValues.location = user.location || null;
    initialValues.industry = user.industry || null;

    props.onboardingSave(initialValues);
    props.onboardingProfileStarted();
  }

  update = () => {
    const { fields } = this.props;
    let updatedProfile = {
      bio: {}
    };

    for (let field in fields) {
      if (field == 'im_good_at' || field == 'id_like_to_learn' || field == 'id_like_to_meet') {
        updatedProfile.bio[field] = fields[field];
      } else {
        updatedProfile[field] = fields[field];
      }
    }

    this.props.userUpdate(updatedProfile);
  }

  next = () => {
    this.props.onboardingNext();
  }

  back = () => {
    this.props.onboardingBack();
  }

  render() {
    const params = queryString.parse(location.search);
    this.sections = [
      {
        title: 'Registration',
        icon: RegistrationIcon,
        pages: []
      },
      {
        title: 'About You',
        icon: AboutYouIcon,
        pages: [
          {
            component: OnboardingGoodAt,
            data: {
              im_good_at: this.props.fields.im_good_at
            },
            handlers: {
              handleGoodAtChange: (value) => {
                this.props.onboardingSave({ im_good_at: value });
              }
            },
            validate: () => {
              const { im_good_at } = this.props.fields;
              let invalid = false;

              invalid = !im_good_at || !im_good_at.length || invalid;

              return !invalid;
            },
            next: () => {
              this.update();
            }
          },
          {
            component: OnboardingLearning,
            data: {
              id_like_to_learn: this.props.fields.id_like_to_learn
            },
            handlers: {
              handleLearnChange: (value) => {
                this.props.onboardingSave({ id_like_to_learn: value });
              }
            },
            validate: () => {
              const { id_like_to_learn } = this.props.fields;
              let invalid = false;

              invalid = !id_like_to_learn || !id_like_to_learn.length || invalid;

              return !invalid;
            },
            next: () => {
              this.update();
            },
            back: () => {
              this.back();
            }
          },
          {
            component: OnboardingMeet,
            data: {
              id_like_to_meet: this.props.fields.id_like_to_meet
            },
            handlers: {
              handleMeetChange: (value) => {
                this.props.onboardingSave({ id_like_to_meet: value });
              }
            },
            validate: () => {
              const { id_like_to_meet } = this.props.fields;
              let invalid = false;

              invalid = !id_like_to_meet || !id_like_to_meet.length || invalid;

              return !invalid;
            },
            next: () => {
              this.update();
            },
            back: () => {
              this.back();
            }
          },
          {
            component: OnboardingTitle,
            data: {
              headline: this.props.fields.headline
            },
            handlers: {
              handleHeadlineChange: (value) => {
                this.props.onboardingSave({ headline: value });
              }
            },
            validate: () => {
              const { headline } = this.props.fields;
              let invalid = false;

              invalid = !headline || headline.length < 2 || invalid;

              return !invalid;
            },
            next: () => {
              if (this.props.fields) {
                if (/^[a-zA-Z0-9._\-,! ]+$/.test(this.props.fields.headline) && isNoEmoji(this.props.fields.headline)) {
                  this.props.onboardingErrorClear();
                  this.update();
                } else {
                  this.props.onboardingError({
                    headline: {
                      message: 'Titles must only contain letters, numbers, commas, hyphens, plus signs, and periods'
                    }
                  });
                }
              }
            },
            back: () => {
              this.back();
            }
          },
          {
            component: OnboardingLocation,
            data: {
              location: this.props.fields.location ? { label: this.props.fields.location, value: this.props.fields.place } : null
            },
            handlers: {
              handleLocationChange: (location) => {
                if (location) {
                  this.props.onboardingSave({ location: location.formatted_address, place: location.place_id, location_id: location.id });
                } else {
                  this.props.onboardingSave({ location: '', place: '', location_id: null });
                }
              }
            },
            validate: () => {
              const { location } = this.props.fields;
              let invalid = false;

              invalid = !location || location.length < 2 || invalid;

              return !invalid;
            },
            next: () => {
              this.update();
            },
            back: () => {
              this.back();
            }
          },
          {
            component: OnboardingIndustry,
            data: {
              industry: this.props.fields.industry
            },
            handlers: {
              handleIndustryChange: (value) => {
                this.props.onboardingSave({ industry: value });
              }
            },
            validate: () => {
              const { industry } = this.props.fields;
              let invalid = false;

              invalid = !industry || !industry.length || invalid;

              return !invalid;
            },
            next: () => {
              this.update();
            },
            back: () => {
              this.back();
            }
          },
          {
            component: OnboardingPhoto,
            data: {
              profilePhoto: () => {
                if (this.props.user) {
                  return this.props.user.photo.medium;
                }
              }
            },
            validate: () => {
              const userPhoto = this.props.user.photo.medium;
              const invalid = userPhoto === 'https://storage.googleapis.com/girlboss_profile_pictures/avatar_default.png';

              return !invalid;
            },
            next: () => {
              this.next();
            },
            back: () => {
              this.back();
            }
          }
        ]
      },
      {
        title: 'Profile Setup',
        icon: ProfileSetupIcon,
        pages: [
          {
            component: OnboardingProfileComplete,
            nextLabel: "Go!",
            nextClass: 'Onboarding__navigation-button-complete',
            backLabel: 'Invite a Friend',
            backClass: 'Onboarding__navigation-button-invite',
            next: () => {
              this.next();
            }
          },
          {
            component: () => {
              return <Redirect to={params.collective ? `/groups/${params.collective}` : "/"} />
            }
          }
        ]
      }
    ];

    return (
      <Onboarding
        sections={this.sections}
        state={this.props.fields}
        page={this.props.step}
        errors={this.props.errors}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.User.user,
    step: state.Onboarding.step,
    fields: state.Onboarding.fields,
    errors: state.Onboarding.errors,
    isLoading: state.Auth.isLoading,
    isLoggedIn: appGetIsUserLoggedIn(state)
  }
};

function mapDispatchToProps(dispatch) {
  return {
    userUpdate: (args) => dispatch(userUpdate(args)),
    onboardingProfileStarted: () => dispatch(onboardingProfileStarted()),
    onboardingSave: (args) => dispatch(onboardingSave(args)),
    onboardingSaveClear: () => dispatch(onboardingSaveClear()),
    onboardingError: (args) => dispatch(onboardingError(args)),
    onboardingErrorClear: () => dispatch(onboardingErrorClear()),
    onboardingNext: () => dispatch(onboardingNext()),
    onboardingBack: () => dispatch(onboardingBack()),
    submitEmailToWaitlistReferral: (...args) => dispatch(submitEmailToWaitlistReferral(...args))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingProfile);
