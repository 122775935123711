import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NavOption from './NavOption.js';

import './NavOptions.scss';

class NavOptions extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired
      })
    ),
    activeIndex: PropTypes.number.isRequired,
    handleChangeActiveTab: PropTypes.func.isRequired
  };

  handleNavChange = (index) => this.props.handleChangeActiveTab(index);

  render() {
    return (
      <div className="NavOptions">
        {
          this.props.options.map((option, index) => (
            <NavOption
              key={option.id}
              id={option.id}
              title={option.title}
              index={index}
              onClick={this.handleNavChange}
              activeIndex={this.props.activeIndex}
            />
          ))
        }
      </div>
    )
  }  
}

export default NavOptions;
