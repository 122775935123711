import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FreeRoute from './FreeRoute';
import { selectRequest } from '../../store/requests';
import { collectiveGet } from '../../store/Collectives/actions';

class CollectiveRoute extends Component {
  static defaultProps = {
    initializeDataCall: () => {}
  };

  constructor (props) {
    super(props);
    this.state = {};
  }

  loadCollective = slug => {
    slug = slug || this.props.computedMatch.params.slug;
    this.props.collectiveGet(slug);
  }

  componentDidUpdate = prevProps => {
    if (prevProps.computedMatch.params.slug !== this.props.computedMatch.params.slug) {
      this.props.collectiveGet(this.props.computedMatch.params.slug);
    }
  }

  render() {
    const { collectives } = this.props;
    const slug = this.props.computedMatch.params.slug;

    return (
      <FreeRoute
        {...this.props}
        collective={collectives[slug]}
        initializeDataCall={this.loadCollective}
        publicRoute
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.User.user,
    collectives: state.Collectives.collectives,
    collectiveRequest: selectRequest(state, collectiveGet)
  };
}

export default connect(mapStateToProps, { collectiveGet })(CollectiveRoute);
