import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import GirlbossLogoG from '../../../public/assets/GirlbossLogo_GMark_Black.png';

import './LoadingScreen.scss';

class LoadingScreen extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    isAppInitializing: PropTypes.bool
  };

  render() {
    if (this.props.isLoggedIn && !this.props.isAppInitializing && !this.props.requiresUser) {
      return null;
    }

    return (
      <ReactCSSTransitionGroup
        transitionName=""
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        <div className="LoadingScreen">
          <div className="LoadingScreen__circle" />
          <div className="LoadingScreen__line--mask">
            <div className="LoadingScreen__line" />
          </div>
          <div className="LoadingScreen__girlboss">
            <img
              src={GirlbossLogoG}
              alt="Girlboss community"
              className="LoadingScreen__logo"
            />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    )
  }
};

export default LoadingScreen;
