const nycRally2018 = [
  {
    id: 313917755,
    thumbnail_id: 755610041,
    title: `Sophia's Journey And The Power Of Community`,
    description: 'Girlboss founder and CEO Sophia Amoruso kicks off the day, exploring her journey to this point.',
    featuring: 'Sophia Amoruso'
  },
  {
    id: 314059734,
    thumbnail_id: 755610492,
    title: 'On The Idea Of Failing Forward With Sophia Amoruso',
    description: 'Girlboss founder and CEO Sophia Amoruso kicks off the second day of the Girlboss Rally NYC 2018.',
    featuring: 'Sophia Amoruso'
  },
  {
    id: 311681635,
    thumbnail_id: 752599694,
    title: 'An Inspiring Poetry Reading By Amanda Gorman',
    description: 'Amanda Gorman, the first ever U.S. Youth Poet Laureate, gives a poetry performance.',
    featuring: 'Amanda Gorman'
  },
  {
    id: 311301530,
    thumbnail_id: 752600059,
    title: 'Moving Your Body To Spark Your Mind With Kirsty Godso',
    description: 'Nike Master Trainer Kirsty Godso shows us how movement can fuel our minds and help us harness our power.',
    featuring: 'Kirsty Godso'
  },
  {
    id: 313899093,
    thumbnail_id: 755353150,
    title: 'Dance To Self-Love With Queen Moves',
    description: 'Girlboss Grant winners Queen Moves turn up the music and show us how to express self-love via movement.',
    featuring: 'Queen Moves'
  },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Closing Remarks w/ Sophia Amoruso',
  //   description: `Closing on a note of gratitude, Sophia Amoruso reflects on the day's events and inspiration.`,
  //   featuring: 'Sophia Amoruso'
  // },
  {
    id: 314106342,
    thumbnail_id: 755909091,
    title: `Representation In Entertainment, Film, and TV`,
    description: 'Representation matters. Here, four powerful forces discuss their paths, their challenges, and what we need to see more of. With Amani Al-Khatahtbeh, Nafessa Williams, and Allana Harkin. Moderated by Rachel Sklar.',
    featuring: 'Amani Al-Khatahtbeh, Nafessa Williams, Allana Harkin, Rachel Sklar'
  },
  {
    id: 311742380,
    thumbnail_id: 752627903,
    title: 'Some Real Talk About Your Finances With Sallie Krawcheck',
    description: `Ellevest founder and CEO Sallie Krawcheck wants you to think twice about what you believe you know about money.`,
    featuring: 'Sallie Krawcheck'
  },
  // {
  //   id: 1,
  //   thumbnail_id: 1,,
  //   title: 'So, The Marketing Business Changed Overnight...',
  //   description: `For most of history, big budgets, direct-response ads, and other tried-and-true strategies have dominated the branding and marketing businesses. But in the past few years, brands like the Museum of Ice Cream have sprung up seemingly overnight, taking over our culture and our conversations. And established powerhouses like Target and Uber are taking innovative new approaches to getting their products in front of this generation of consumers. So, what does great marketing actually look like in the age of Instagram moments and influencers? Four women who are changing the space explain.`,
  //   featuring: 'Maryellis Bunn, Noria Morales, Erika Decker Wykes-Sneyd'
  // },
  {
    id: 312776525,
    thumbnail_id: 755609497,
    title: 'What You *Can\'t* Learn About Money From A Book',
    description: `Four financial powerhouses who built their careers working with money explain how they approach the topic—and what you can't learn from a book. With Kristin Lemkau, Shivani Siroya, Stephanie Cohen, and Marissa Campise. Moderated by Neha Gandhi.`,
    featuring: 'Kristin Lemkau, Shivani Siroya, Stephanie Cohen, Marissa Campise'
  },
  {
    id: 311304665,
    thumbnail_id: 752134829,
    title: 'Elaine Welteroth On Finding Your Unique Voice',
    description: 'Elaine Welteroth talks about her journey from editor to advocate, and inspires us all to find—and use—our own voices. In conversation with Jerico Mandybur.',
    featuring: 'Elaine Welteroth'
  },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'In Conversation With Arianna Huffington',
  //   description: `Resilience is everything. Very few people know that as intimately as Arianna Huffington. She's had a multi-faceted career —from writing 15 books to building a game-changing company and launching a $120M+ startup. But she's also faced failure, and learned to embrace it. Today, she's focused on changing the way we work and live by ending the epidemic of stress and burnout and helping people prioritize their well-being. Here, the entrepreneur talks to Sophia Amoruso about the ups and downs of her journey, the importance of unplugging and recharging, and how we can all stop wearing our exhaustion as a badge of honor.`,
  //   featuring: 'Arianna Huffington'
  // },
  {
    id: 311754716,
    thumbnail_id: 752644730,
    title: 'The Hard Truth About Building With Lavinia Errico',
    description: 'Equinox founder Lavinia Errico walks us through the journey she took to get to this point, and the toughest lessons she learned along the way.',
    featuring: 'Lavinia Errico'
  },
  {
    id: 311609187,
    thumbnail_id: 752604897,
    title: 'The Journey From Idea To Business',
    description: 'Here, three women who have built some of the most game-changing businesses of our time explain how they did it. With Lavinia Errico, Shan-Lyn Ma, and Norma Kamali. Moderated by Jerico Mandybur.',
    featuring: 'Lavinia Errico, Shan-Lyn Ma, Norma Kamali'
  },
  {
    id: 311546901,
    thumbnail_id: 752424921,
    title: 'Building A Billion-Dollar Empire With Jenn Hyman',
    description: `Here, Rent the Runway founder and CEO Jenn Hyman goes deep on what it really takes to build and grow and billion-dollar business. In conversation with Neha Gandhi.`,
    featuring: 'Jenn Hyman'
  },
  {
    id: 311949940,
    thumbnail_id: 753131856,
    title: 'Swimming In A Sea Of Tech Bros',
    description: `What are the skills and tools every woman hoping to strive and thrive in STEAM fields should have and sharpen? With Leila Janah, Kathryn Minshew, Angelica Ross, and Aisha Bowe. Moderated by Laurie Segall.`,
    featuring: 'Leila Janah, Kathryn Minshew, Angelica Ross, Aisha Bowe, Laurie Segall'
  },
  {
    id: 314315229,
    thumbnail_id: 755909586,
    title: 'Courageous Creativity With Piera Gelardi',
    description: 'Refinery29 co-founder and executive creative director Piera Gelardi almost lost her voice and her will to create a few years ago—here, she explains how she reclaimed her vision and purpose.',
    featuring: 'Piera Gelardi'
  },
  {
    id: 311940795,
    thumbnail_id: 753235042,
    title: 'Stepping Into Your Power With Bozoma St. John',
    description: 'Bozoma Saint John is one of the coolest, most confident, and most powerful self-directed women we have ever met. In the past few years, she has had some ~huge~ jobs: Apple Music marketing exec, Uber Chief Brand Officer, and most recently, Endeavor Chief Marketing Officer. Today, she’s sharing everything she’s learned on that journey, in conversation with Sophia Amoruso.This is the inspiration you need to level up your confidence and walk away feeling like you could crush your dreams with the mere lift of a pinky finger.',
    featuring: 'Bozoma Saint John'
  },
  {
    id: 311962748,
    thumbnail_id: 753234912,
    title: 'The Obvious Way To Build A Better Company',
    description: 'Diversity means better ideas and better work. Here, three corporate leaders tackling this problem from different angles. With Callie Field, Lauren Wesley Wilson, and Aaron Rose. Moderated by Jerico Mandybur. Presented in partnership with T-Mobile.',
    featuring: 'Callie Field, Lauren Wesley Wilson, Aaron Rose'
  },
  {
    id: 314144532,
    thumbnail_id: 755878602,
    title: `What Does Activism Look Like In 2018?`,
    description: 'Four activists working on different topics that we should all care about talk about what the work of activism really looks like. With Dawn Laguens, Symone D. Sanders, Sarah Sophie Flicker, and Marya Bangee. Moderated by Neha Gandhi.',
    featuring: 'Dawn Laguens, Symone D. Sanders, Sarah Sophie Flicker, Marya Bangee'
  },
  {
    id: 311726333,
    thumbnail_id: 755910051,
    title: 'How 4 Buzzy Founders Built Their Businesses',
    description: 'Four women who have built some of the most game-changing businesses of our time explain how they did it. With Aurora James, Brooklyn Decker, Alexandra Friedman, and Afton Vechery. Moderated by Theresa Avila.',
    featuring: 'Aurora James, Brooklyn Decker, Alexandra Friedman, Afton Vechery'
  },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Good News: How To Get The PR You Really Want',
  //   description: `You're doing all the hard work: building a business, driving growth, and getting shit done. But that's not enough if nobody knows you're doing it. So, how do you get the right kind of press—the kind that's going to blow the competition out of the water and bring customers streaming through the doors? Three powerhouse communications pros break it down.`,
  //   featuring: 'Leland Drummond, Arian Simone, Mallory Blair'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Building A (New) Media Empire',
  //   description: `Print is dying. Magazines are shuttering. Digital publishers are consolidating. It's tough out there. But what happens next? Three women thinking about media in brand new ways, pushing boundaries, and telling truly innovative stories explain what the future of storytelling could look like.`,
  //   featuring: 'Ari Wengroff, Shydeia Caldwell, Marah Lidey'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Collaboration, Not Competition',
  //   description: `There's nothing quite like the collective power of women joining together to support each other. Three women who have advanced and achieved by leveraging exactly that communal support and power explain what community means to them, and what you need to know if you want to build a strong support system of your own. (Presented in partnership with J.Crew)`,
  //   featuring: 'Madeline Poole, Vanessa Holden, Liz Kleinrock'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: `Building A Brand They Can't Stop Talking About`,
  //   description: `Some of the brands we talk about, think about, and use every single day weren't even a glimmer in their founders' eyes 10 years ago. So, how do you take an amazing idea and turn into into an unforgettable, inescapable brand? Three women who have done exactly that explain.`,
  //   featuring: 'Alex Williamson, Hannah Bronfman, Rea Ann Silva'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'The Future of Retail',
  //   description: 'These days, anyone can get a retail brand off the ground—the thing that sets the great ones apart from the rest is focus. What's the one thing you do well; what's the one thing you can sell that everyone wants to buy? Three retail upstarts who are building some of the most interesting retail brands of our generation explain what it takes to do it right. (Presented in partnership with Shopify)',
  //   featuring: 'Becca McCharren-Tran, Abigail Stone, Melissa Butler'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: `Don't Just Find Your Personal Brand—Own It`,
  //   description: `Everyone has a personal brand. How can you distinguish yours from the rest of what's out there? In this workshop, you'll learn how to build a personal brand story that's authentic to you—and hear the amazing stories of the women around you. Come prepared to listen, and leave with a clearer sense of how you want the world to see you.`,
  //   featuring: 'Olivia Christian'
  // },
  {
    id: 311780129,
    thumbnail_id: 752870657,
    title: '7 Steps To The Perfect Pitch',
    description: `SHere's your guide to dazzling once you’re in the boardroom. With Kathryn Finney and Banu Guler. Moderated by Sutian Dong. Presented in partnership with Uber.`,
    featuring: 'Kathryn Finney, Banu Guler, Sutian Dong'
  },
  {
    id: 311712384,
    thumbnail_id: 755910978,
    title: 'How to Raise Capital For Your Business',
    description: `Navigating the fundraising process when you don’t know anyone in venture capital can be...tricky. Here's what you need to know. With Danielle Duboise, Whitney Tingle, and Michelle Cordeiro Grant. Moderated by Ita Ekpoudom. Presented in partnership with Uber.`,
    featuring: 'Danielle Duboise, Whitney Tingle, Michelle Cordeiro Grant, Ita Ekpoudom'
  },
  {
    id: 311584203,
    thumbnail_id: 752601084,
    title: 'Can You Hear Me Now? Making Your Voice Heard',
    description: `Research tells us that women get tougher, more negative questions than their male counterparts—how do you make your voice heard and influence decision makers? With Katherine Ryder and Bettina Huang. Moderated by Morra Aarons-Mele. Presented in partnership with Uber.`,
    featuring: 'Katherine Ryder, Bettina Huang, Morra Aarons-Mele'
  },
  {
    id: 311547182,
    thumbnail_id: 752604374,
    title: 'Help! People: Culture Building In The Workplace',
    description: `There’s an art and science to building positive culture and hiring the right people to protect it. With Fran Hauser and Alli Young. Moderated by Lucia Smith. Presented in partnership with Uber.`,
    featuring: 'Fran Hauser, Alli Young, Lucia Smith'
  },
  {
    id: 311559610,
    thumbnail_id: 752603756,
    title: 'How To Build A Killer Brand - From Scratch',
    description: `Here’s the real story on how to build a brand and generate buzz when nobody’s ever heard of you. With Cyndi Ramirez and Jaspre Guest. Moderated by Nicole Williams. Presented in partnership with Uber.`,
    featuring: 'Cyndi Ramirez, Jaspre Guest, Nicole Williams'
  },
  {
    id: 311704242,
    thumbnail_id: 752868848,
    title: 'Hiring Fast, Hiring Rockstars',
    description: `The best leaders hire people they would be willing to work for. But that’s a lot of pressure when you’re desperate to get people in the door yesterday. So, how do you get it right? With Sarah Larson Levey and Maggie Winter. Moderated by Annie Dean. Presented in partnership with Uber.`,
    featuring: 'Sarah Larson Levey, Maggie Winter, Annie Dean'
  },
  {
    id: 317591155,
    thumbnail_id: 760438039,
    title: 'Failing Forward and Aiming Higher',
    description: `Author, columnist, and businesswoman Arianna Huffington talks about finding success in failure and leveraging your past to build your future.`,
    featuring: 'Arianna Huffington'
  },
  {
    id: 317610445,
    thumbnail_id: 760615507,
    title: 'What Does Great Marketing Look Like Today?',
    description: ``,
    featuring: ''
  },
  {
    id: 317589904,
    thumbnail_id: 759840116,
    title: 'Sophia Amoruso on Gratitude to Girlbosses',
    description: ``,
    featuring: 'Sophia Amoruso'
  }
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'The Practice: A Confidence Ceremony with Dr. Lauren Hazzouri',
  //   description: 'Psychologist and confidence coach Dr. Lauren will lead us through a session filled with moving, chanting, sharing, and reflecting to help us consider our mental health and spiritual wellness. Following the session, Dr. Lauren will lead a mini-talk on self-confidence, all the outside factors that challenge our sense of self worth on a daily basis, and how we can overcome those in service of a greater, more persistent sense of mental wellbeing.',
  //   featuring: 'Dr. Lauren Hazzouri'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Your Body, Your Mind, Your Power',
  //   description: `From building physical strength and mental agility to cultivating confidence to raising your energy levels, your body and mind come together to drive you forward—and achieve your goals. Fitness pro turned entrepreneur Kirsty Godso knows this from experience, and wants to help you find your own power by working smarter, not harder. Here, she'll show you how, in conversation with marathoner and fitness podcast host Emily Abbatte. (Presented in partnership with Smartwater)`,
  //   featuring: 'Kirsty Godso'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: `"You Need To Learn To Relax"`,
  //   description: `Normally, that phrase makes us cringe. But in this setting, we'll accept it. Let's take a beat and truly relax. With some help from an ayurvedic healer, a bit of guided meditation, and a sound bath, we're creating a peaceful space to truly heal and rest.`,
  //   featuring: 'Regina, Brittany Josephina'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'The Art of Negotiation: Knowing Your Worth & Advocating For It',
  //   description: `If you don’t negotiate for yourself, you run the risk of never closing the gap in terms of compensation, learning, or opportunity. Learn the art of professional negotiations just in time for the season of promotions, raises, and reviews up ahead.`,
  //   featuring: 'Lisa Skeete Tatum'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Personal Finance 101: From Saving Hacks To Real Talk On Compound Interest',
  //   description: `Bad with money? We're calling bullshit. Anyone can feel like a financial genius (or at least feel a little less weird about money) if you're armed with the right information. Here's what you need to know to get smarter about how you spend, how you budget, and what you save. And of course, once you're saving, you need to know how to invest it properly. (Presented in partnership with BlackRock)`,
  //   featuring: `Kristin O'Keeffe Merrick`
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'The Psychology Of Saving—And Spending',
  //   description: 'The choices we make about our money run our lives and determine our futures. Financial wellness (the honest, realistic version of it, anyway) isn’t just about how many dollars you save and how you invest that money (although that definitely matters). It’s about your relationship with money, and hacking your brain to value your future just a little more than instant gratification. Here, we talk to three real-deal experts to break down the psychology of how and why we spend, how we can all do better and get smarter, and why the whole thing feels so damn fraught. (Presented in partnership with BlackRock)',
  //   featuring: 'Anne Ackerley, Priya Malani, Ayelet Fishbach'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Personal Finance 101: From Saving Hacks To Real Talk On Compound Interest',
  //   description: `Bad with money? We're calling bullshit. Anyone can feel like a financial genius (or at least feel a little less weird about money) if you're armed with the right information. Here's what you need to know to get smarter about how you spend, how you budget, and what you save. And of course, once you're saving, you need to know how to invest it properly. (Presented in partnership with BlackRock)`,
  //   featuring: `Kristin O'Keeffe Merrick`
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'Personal Finance 101: What You Really Need To Know To Get Smarter About Money',
  //   description: `What does it mean to be "good with money"? How do you go from a place where you're struggling with debt, unsure of what all those acronyms mean, and unsteady in how to get started with saving to a place where you're building real wealth? Tiffany Aliche, the Budgetnista, breaks it down.`,
  //   featuring: 'Tiffany Aliche'
  // },
  // {
  //   id: 1,
  //   thumbnail_id: 1,
  //   title: 'The State of Equal Pay',
  //   description: 'We all know the stats about how women make 70-some cents on the dollar for what a man earns—and that the numbers look even worse when we look at Latinx women, Native American women, and Black women. But what's the real state of what's going on out there? Why do we still have a pay gap? And what can we do—in our lives, in our businesses, and in our culture—to negotiate for change?',
  //   featuring: 'Andy McNicol, Claire Wasserman'
  // }
];

export default nycRally2018;
