import { defineRequest } from '../../lib/reduxRequests';
/**
  Post Comment
**/

export const {
  COMMENT,
  COMMENT_FULFILLED,
  comment
} = defineRequest('COMMENT', (params, key) => ({ key, payload: params }));

export const COMMENT_SUCCESS = 'COMMENT_SUCCESS';
export const COMMENT_ERROR = 'COMMENT_ERROR';

/**
  Edit Comment
**/

export const {
  COMMENT_EDIT,
  COMMENT_EDIT_FULFILLED,
  commentEdit
} = defineRequest('COMMENT_EDIT', (params, key) => ({ key, payload: params }));

/**
  Delete Comment
**/

export const {
  COMMENT_DELETE,
  COMMENT_DELETE_FULFILLED,
  commentDelete
} = defineRequest('COMMENT_DELETE', (params, key) => ({ key, payload: params }));

export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_ERROR = 'COMMENT_DELETE_ERROR';

export const commentDeleteSuccess = (postId, commentId) => {
  return {
    type: COMMENT_DELETE_SUCCESS,
    postId,
    commentId
  }
};

export const commentDeleteError = (postId, commentId) => {
  return {
    type: COMMENT_DELETE_ERROR,
    postId,
    commentId
  }
};

/**
  Post Reply
**/

export const {
  REPLY,
  REPLY_FULFILLED,
  reply
} = defineRequest('REPLY', (params, key) => ({ key, payload: params }));

/**
  Reply Edit
**/

export const {
  REPLY_EDIT,
  REPLY_EDIT_FULFILLED,
  replyEdit
} = defineRequest('REPLY_EDIT', (params, key) => ({ key, payload: params }));

/**
  Reply Delete
**/
export const REPLY_DELETE = 'REPLY_DELETE';
export const REPLY_DELETE_SUCCESS = 'REPLY_DELETE_SUCCESS';

export const replyDelete = (postId, parentId, commentId) => {
  return {
    type: REPLY_DELETE,
    postId,
    parentId,
    commentId
  }
};

export const replyDeleteSuccess = (postId, parentId, commentId) => {
  return {
    type: REPLY_DELETE_SUCCESS,
    postId,
    parentId,
    commentId
  }
};
