import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProfilePhoto, {
  SIZE_XSMALL,
  SIZE_SMALL
} from '../ProfilePhoto';

import './User.scss';

class User extends PureComponent {
  static propTypes = {
    actions: PropTypes.node,
    photo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subheadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    subheadlineClassName: PropTypes.string,
    headline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    username: PropTypes.string,
    secondaryClassName: PropTypes.string
  }

  getProfilePhotoSize = () => {
    switch (this.props.variant) {
      case 'comment':
        return SIZE_XSMALL;
      case 'post':
      default:
        return SIZE_SMALL;
    }
  }

  render() {
    const {
      actions,
      photo,
      name,
      subheadline,
      headline,
      username,
      variant
    } = this.props;

    return (
      <div className="user-container">
        <div className="user-container-data">
          <Link to={`/${username}`}>
            <ProfilePhoto
              photo={photo}
              alt={name}
              size={this.getProfilePhotoSize()}
            />
          </Link>
          <Link to={`/${username}`}>
            <span
              className={classNames(
                "User__title User__name",
                {
                  'fz-xs': variant === 'comment'
                },
                this.props.nameClassName
              )}
              style={{marginLeft:'15px'}}
            >
              {name}
            </span>
          </Link>
          {
            headline &&
            <p
              className={classNames(
                "User__title User__subheadline fz-xs",
              )}
            >
              • {headline}
            </p>
          }
        </div>
        {
          actions &&
          <div className="User__rightPanel">
            {actions}
          </div>
        }
      </div>
    )
  }
}

export default User;
