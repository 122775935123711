import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { BODY } from '../../../common/Input/CommonFields/post';
import Moment from '../../../components/Moment';
import UserCommentWithBody, { TYPE_REPLY } from '../../../components/UserCommentWithBody';
import ReactionsAndCommentsText from '../../../components/ReactionsAndCommentsText';
import { selectRequest } from '../../../store/requests';
import { replyEdit, replyDelete } from '../../../store/Comment/actions';

import {
  GTMpushDataLayerEvent,
  GTM_REPLY_PRAISE,
  GTM_REPLY_UNPRAISE
} from '../../../lib/GoogleTagManager';

import './Reply.scss';

const REPLY_INCREASE = 2;

class Reply extends PureComponent {
  static propTypes = {
    handleDeleteReply: PropTypes.func.isRequired,
    handleSaveReply: PropTypes.func.isRequired,
    creator: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    onComment: PropTypes.func.isRequired,
    createdAt: PropTypes.string.isRequired,
    scrolledToRef: PropTypes.string,
    setRef: PropTypes.func.isRequired,
    onReact: PropTypes.func.isRequired,
    onReactRemove: PropTypes.func.isRequired,
    isReacted: PropTypes.bool,
    parentId: PropTypes.number.isRequired,
    postId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      [BODY]: this.props.body
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.replyEditRequest.pending && this.props.replyEditRequest.fulfilled) {
      this.editReplySuccess();
    }
  }

  handleEditReply = () => {
    this.setState({ isEditing: true });
  }

  handleSaveEditReply = e => {
    e.preventDefault();

    if (this.state[BODY]) {
      this.props.replyEdit({
        replyId:this.props.id,
        [BODY]: this.state[BODY]
      }) 
    }
  }

  editReplySuccess = () => {
    this.setState({ isEditing: false });
  }

  handleCancelEditReply = () => {
    this.setState({ isEditing: false });
  }

  handleDelete = () => {
    this.props.replyDelete(this.props.postId, this.props.parentId, this.props.id);
  }

  onReact = () => {
    const { isReacted, postId, parentId, id } = this.props;

    if (isReacted) {
      this.props.onReactRemove(postId, parentId, id);
      GTMpushDataLayerEvent({ event: GTM_REPLY_UNPRAISE });
    } else {
      this.props.onReact(postId, parentId, id);
      GTMpushDataLayerEvent({ event: GTM_REPLY_PRAISE });
    }
  }

  setRef = ref => {
    this.props.setRef(this.props.id, ref);
  }

  handleOnChange = value => {
    this.setState({ [BODY]: value });
  }

  render() {
    const {
      userId,
      body,
      userTags,
      className,
      creator,
      createdAt,
      reactions,
      scrolledToRef,
      id,
      type,
      currentUserId,
      flagged,
      postId,
      showAdminBadge
    } = this.props;

    const photo = creator.photo;
    const userPhoto = (typeof photo === 'string' ? photo : photo.thumbnail);

    return (
       <div
        className={classNames(
          "Reply__comment",
          className,
        )}
        ref={this.setRef}
        id={id}
      >
        <UserCommentWithBody
          actions={{
            handleEdit: this.handleEditReply,
            handleDelete: this.handleDelete
          }}
          alt={`${creator.first_name} ${creator.last_name}`}
          body={body}
          userTags={userTags}
          userId={this.props.userId}
          contentId={id}
          photo={userPhoto}
          type={TYPE_REPLY}
          name={`${creator.first_name} ${creator.last_name}`}
          username={creator.username}
          headline={<Moment short date={createdAt} />}
          subheadline={creator.headline}
          isEditing={this.state.isEditing}
          saveEdit={this.handleSaveEditReply}
          cancelEdit={this.handleCancelEditReply}
          handleOnChange={this.handleOnChange}
          variant="comment"
          currentUserId={currentUserId}
          showAdminBadge={showAdminBadge}
        />
        <ReactionsAndCommentsText
          onReact={this.onReact}
          onComment={this.props.onComment}
          reactions={reactions}
          isReacted={this.props.isReacted}
          userId={userId}
          type={type}
          contentId={id}
          currentUserId={currentUserId}
          isLoggedIn={!!currentUserId}
          timestamp={<Moment short date={createdAt} />}
          actions={{
            handleEdit: this.handleEditReply,
            handleDelete: this.handleDelete
          }}
          flagged={flagged}
          postId={postId}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    replyEditRequest: selectRequest(state, replyEdit)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    replyEdit: (...args) => dispatch(replyEdit(...args)),
    replyDelete: (...args) => dispatch(replyDelete(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reply);
