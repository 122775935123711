import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import CollectivesListItem from './CollectivesListItem';

import {
  collectiveMemberAdd,
  collectiveMemberRemove
} from '../../../store/Collectives/actions';

import { modalClose } from '../../../store/Modal/actions';

import './CollectivesList.scss';

class CollectivesList extends Component {
  static propTypes = {
    listType: PropTypes.oneOf(['recommended', 'user']),
    isMember: PropTypes.bool,
    noSubscription: PropTypes.bool,
    maxLength: PropTypes.number
  }

  static defaultProps = { 
    collectives:{}
  }

  renderCollectivesItem = () => {
    const { 
      collectives, 
      user, 
      isMember,
      collectiveMemberAdd,
      collectiveMemberRemove,
      showPostNumber,
      modalClose,
      showAvatar,
      noSubscription,
      maxLength
    } = this.props;
    const collectivesList = [];

    let i = 0;
    for(let collective in collectives) {
      i++;
      if (maxLength && i > maxLength) break;
      collectivesList.push(
        <CollectivesListItem 
          key={collectives[collective].id} 
          collective={collectives[collective]} 
          user={user} 
          listType={this.props.listType}
          onSubscribe={collectiveMemberAdd}
          onUnsubscribe={collectiveMemberRemove}
          showPostNumber={showPostNumber}
          showAvatar={showAvatar}
          isMember={isMember}
          modalClose={modalClose}
          noSubscription={noSubscription}
        />
      );
    }

    return collectivesList;
  }

  render() {
    const { collectives, title, className } = this.props;

    if(!Object.keys(collectives).length) return null;

    return (
      <div className={classNames("CollectivesList", className)}>
        { title && (<h4 className="CollectivesList__title">{title}</h4>)}
        <div className="CollectivesList__container">
          { this.renderCollectivesItem() }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    collectiveMemberAdd:args => dispatch(collectiveMemberAdd(args)),
    collectiveMemberRemove:args => dispatch(collectiveMemberRemove(args)),
    modalClose: () => dispatch(modalClose()),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(CollectivesList);