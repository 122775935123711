import {
  TOASTER_OPEN,
  TOASTER_CLOSE
} from './actions';

const initialState = {};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case TOASTER_OPEN:
      return { toasterOpen: action.payload };
    case TOASTER_CLOSE:
      return { toasterOpen: null };
    default:
      return state;
  }
}

export default modalReducer;

