import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './PlayButton.scss';

class PlayButton extends Component {
  render() {
    return (
      <div className="PlayButton">
        <div className="PlayButton--button">
          <span className="PlayButton--triangle" />
        </div>
      </div>
    )
  }
}

export default PlayButton;
