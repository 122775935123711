import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { fetchAnyAPI } from '../../services/APIService';

import OnboardingNavItem from './OnboardingNavItem';

import NavLine from '../../../public/assets/onboarding/nav-line.png';

import './Onboarding.scss';

class Onboarding extends PureComponent {
  static propTypes = {
    sections:PropTypes.array,
    submit:PropTypes.func
  };

  constructor (props) {
    super(props);
  }

  getPageCount = () => {

    const { sections } = this.props;

    let i = 0;

    for (let j = 0; j < sections.length; j++) {
      i += sections[j].pages.length;
    }

    return i;
  }

  getCurrentSectionIndex () {
    
    const { sections, page } = this.props;

    let decrementedIndex = page;

    for (let i = 0; i < sections.length; i++) {
      decrementedIndex -= sections[i].pages.length;
      if (decrementedIndex < 0) {
        return i;
      }
    }

    return sections.length - 1;
  }

  getCurrentPageIndex = () => {
    
    const { sections, page } = this.props;

    let decrementedIndex = page;

    for (let i = 0; i < sections.length; i++) {
      for (let j = 0; j < sections[i].pages.length; j++) {
        if (decrementedIndex == 0) {
          return j;
        }
        decrementedIndex--;
      }
    }
    return 0;
  }

  renderNavigation = (pages) => {
    return pages.map((page, index) => (
      <React.Fragment key={page.title}>
        {
          (index > 0) && (
            <div className={
              classNames(
                "Onboarding__nav-line",
                {
                  "inactive":index > this.getCurrentSectionIndex()
                }
               )
            }
            style={{backgroundImage:`url(${NavLine}`}}
            ></div>
          )
        }
        <OnboardingNavItem
          label={page.title}
          icon={page.icon}
          active={index <= this.getCurrentSectionIndex()}
        />
      </React.Fragment>
    ))
  }

  renderPage = (PageComponent, data, handlers, errors, next, back, validate) => {
    return (
      <PageComponent
        {...data}
        {...handlers}
        errors={errors}
        next={next}
        back={back}
        validate={validate}
      />
    )
  }

  render() {

    const { sections, submit, page, errors } = this.props;

    const currentSectionIndex = this.getCurrentSectionIndex();
    const currentPageIndex = this.getCurrentPageIndex();

    const currentSection = sections[currentSectionIndex];
    const currentPage = currentSection.pages[currentPageIndex];

    const valid = (currentPage.validate ? currentPage.validate() : true);

    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      let response = fetchAnyAPI('https://hooks.zapier.com/hooks/catch/2555309/vaovu7', 'GET');
    });

    return (
      <div className="Onboarding">
        <div className="Onboarding__nav-container">
          {
            this.renderNavigation(sections)
          }
        </div>
        <div className="Onboarding__page-counter">
          {/* { page + 1 } / { this.getPageCount() } */}
        </div>
        <div className="Onboarding__page-container">
          {
            this.renderPage(
              currentPage.component,
              currentPage.data,
              currentPage.handlers,
              errors,
              currentPage.next,
              currentPage.back,
              currentPage.validate
            )
          }
        </div>
        <div className="Onboarding__navigation-buttons">
          {
            (page > 0) && (currentPage.back) && (
              <button
                onClick={
                  () => {
                    if (currentPage.back) {
                      currentPage.back();
                    }
                  }
                }
                className={currentPage.backClass ? currentPage.backClass : "Onboarding__navigation-button-next"}
              >
                {currentPage.backLabel ? currentPage.backLabel : 'Back'}
              </button>
            )
          }
          {
            (page < this.getPageCount() - 1) && (
              <button
                onClick={
                  () => {
                    if (valid && currentPage.next) {
                      currentPage.next();
                    }
                  }
                }
                className={
                  classNames(
                    currentPage.nextClass ? currentPage.nextClass : "Onboarding__navigation-button-next",
                    { 'inactive':!valid }
                  )
                }
              >
                {currentPage.nextLabel ? currentPage.nextLabel : 'Next'}
              </button>
            )
          }
          {
            (page == this.getPageCount() - 1) && (
              <button
                className="Onboarding__navigation-button-next"
                onClick={
                  () => {
                    if (valid && currentPage.next) {
                      currentPage.next();
                    }
                  }
                }
              >
                Done
              </button>
            )
          }
        </div>
      </div>
    )
  }
}

export default Onboarding;
