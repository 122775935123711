import pick from 'lodash/pick';
import { takeEvery, put, call, take, fork, cancel, select } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';

import {
  ACTIVITY_GET,
  activityGetOffset
} from './actions';
import { userGetId } from '../User/actions';

function* activitySagaGet(action) {
  const currentUserId = yield select(userGetId);
  const { userId = currentUserId, name = '', offset = 0, limit = 10 } = action.payload; 
  const params = { userId, offset, limit, name };

  yield sagaRequest(action, callAPI('getActivity', params));
};

export const activitySagas = [
  takeEvery(ACTIVITY_GET, activitySagaGet)
];
