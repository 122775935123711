import { takeEvery, put, call, take, fork, select } from 'redux-saga/effects';
import { SYSTEM_NOTICE_VERSION } from '../../config';
import { testSystemNoticeRoute } from '../../config/routes';
import { SYSTEM_NOTICE_MODAL } from '../../containers/ModalManager/ModalTypes';
import fetchAPI from '../../services/APIService';
import {
  APP_INITIALIZE,
  appInitializeSuccess,
  SUBMIT_EMAIL,
  submitEmailToWaitlistSuccess,
  submitEmailToWaitlistError,
  APP_VALIDATE_TOKEN_SUCCESS,
  APP_VALIDATE_TOKEN_FAILURE,
  SUBMIT_EMAIL_WAITLIST_REFERRAL,
  CM_STATS,
  cmStatsSuccess,
  CREATE_OS_REFERENCE,
  ON_ROUTE_CHANGE
} from './actions';
import {
  authSagaPostLogin
} from '../Auth/logic';
import {
  inviteGet
} from '../Invites/actions';
import {
  FEED_GET_COMPLETE
} from '../Feed/actions';
import {
  USER_GET_COMPLETE
} from '../User/actions';
import {
  getToken
} from '../../lib/localStorage';
import {
  clearContentTagsReducer
} from '../Feed/actions';
import {
  modalOpen,
  modalClose
} from '../Modal/actions';

import getQueryParam from '../../utils/getQueryParameter';

function* appSagaInitialize(action) {
  const token = getToken();

  if (!token) {
    yield put(appInitializeSuccess());
  } else {
    const invite_token = getQueryParam('invite_token');
    if (invite_token) {
      yield put(inviteGet(invite_token));
    }
    yield call(authSagaPostLogin);
    yield call(appSagaSystemNotice, location.pathname);
  }
};

function* appSagaEmailToWaitlist(action) {
  const signUpResponse = yield call(fetchAPI, 'waitlist', { body: action.payload });

  if (signUpResponse.success) {
    yield put(submitEmailToWaitlistSuccess(signUpResponse));
  } else {
    yield put(submitEmailToWaitlistError(signUpResponse));
  }
};

function* appSagaEmailToWaitlistReferral(action) {
  const referralResponse = yield call(fetchAPI, 'waitlistReferral', { body: action.payload });

  // TODO: handle logic
}

function* appSagaCampaignMonitorStats(action) {
  const response = yield call(fetchAPI, 'cmStats');

  yield put(cmStatsSuccess(response.total_subscribers));
}

function* appSagaRouteChange(action) {
  const { location } = action.payload;
  const { Feed } = yield select();
  const { Modal } = yield select();

  // clear content tags if users navigate out of feed and thread view
  if(!(location.pathname.includes('/post/') || 
    location.pathname === '/' || 
    location.pathname === '/login') && 
    Feed.contentTags.length !== 0) {
    yield put(clearContentTagsReducer());
  }

  if (Modal.modalOpen && !location.pathname.includes('/groups/')) {
    const { locationHash } = Modal.modalOpen;
    if (!locationHash || location.hash !== `#${locationHash}`) {
      yield put(modalClose());
    }
  }

  yield call(appSagaSystemNotice, location.pathname);
}

function* appSagaSystemNotice(pathname) {
  const { User: { user }, Modal } = yield select();

  if (!Modal.modalOpen && user.id) {
    const lastViewedSystemNotice = (user.preferences && user.preferences.last_viewed_system_notice) || 0;

    if (
      lastViewedSystemNotice < SYSTEM_NOTICE_VERSION &&
      testSystemNoticeRoute(pathname)
    ) {
      yield put(modalOpen({
        modal: SYSTEM_NOTICE_MODAL,
        version: SYSTEM_NOTICE_VERSION
      }));
    }
  }
}

export const appSagas = [
  takeEvery(APP_INITIALIZE, appSagaInitialize),
  takeEvery(SUBMIT_EMAIL, appSagaEmailToWaitlist),
  takeEvery(SUBMIT_EMAIL_WAITLIST_REFERRAL, appSagaEmailToWaitlistReferral),
  takeEvery(CM_STATS, appSagaCampaignMonitorStats),
  takeEvery(ON_ROUTE_CHANGE, appSagaRouteChange)
];
