import {
  INVALID_ALPHABET,
  INVALID_EMAIL,
  INVALID_EMAIL_LENGTH,
  INVALID_EMOJIS,
  INVALID_NAME_LENGTH,
  INVALID_NAME,
  // INVALID_PASSWORD,
  INVALID_PASSWORD_LENGTH,
  INVALID_PASSWORD_CONTAIN_NUMBER,
  INVALID_PASSWORD_CONTAIN_LOWERCASE,
  INVALID_PASSWORD_CONTAIN_UPPERCASE,
  INVALID_SPACES,
  INVALID_SYMBOLS,
  INVALID_WEB_URL,
  normalizeCapitalFirst,
  normalizeLowerCase,
  compose
} from './validations';

export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const EMAIL_ADDRESS = 'email';
export const PHONE_NUMBER = 'phone_number';
export const COUNTRY_CODE = 'country_code';
export const USERNAME = 'username';
export const PROFILE_PHOTO = 'photo';
export const HEADLINE = 'headline';
export const BIO = 'bio';
export const IM_GOOD_AT = 'im_good_at';
export const ID_LIKE_TO_LEARN = "id_like_to_learn";
export const ID_LIKE_TO_MEET = "id_like_to_meet";
export const INDUSTRY = 'industry';
export const LOCATION = 'location';
export const ID = 'id';
export const EDUCATION = 'education';
export const EXPERIENCES = 'experiences';
export const INSTAGRAM = 'link_instagram';
export const WEBSITE = 'link_personal';
export const PASSWORD = 'password';
export const OLD_PASSWORD = 'old_password';
export const NEW_PASSWORD = 'new_password';
export const NEW_PASSWORD_REPEAT = 'new_password_repeat';

export const CARDS = [
  {
    LABEL:'label',
    VISIBILITY:true,
    
  }
];

export const profileFields = {
  ID,
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  PASSWORD,
  USERNAME,
  PROFILE_PHOTO,
  HEADLINE,
  BIO,
  IM_GOOD_AT,
  ID_LIKE_TO_LEARN,
  ID_LIKE_TO_MEET,
  CARDS,
  INSTAGRAM,
  WEBSITE
};

export const profileInputFields = {
  [EMAIL_ADDRESS]: {
    placeholder: 'Email',
    label: EMAIL_ADDRESS,
    type: 'email',
    autoCompleteGoogle: 'email',
    minLength: 3,
    maxLength: 255,
    required: true,
    validate: emailAddress => (
      compose(emailAddress, [
        INVALID_EMAIL,
        INVALID_EMAIL_LENGTH,
        INVALID_EMOJIS
      ])
    )
  },
  [FIRST_NAME]: {
    placeholder: 'First Name',
    label: FIRST_NAME,
    autoCompleteGoogle: 'given-name',
    minLength: 1,
    maxLength: 50,
    required: true,
    validate: firstName => (
      compose(firstName, [
        INVALID_NAME_LENGTH,
        INVALID_NAME,
        INVALID_EMOJIS
      ])
    ),
    normalize: normalizeCapitalFirst
  },
  [HEADLINE]: {
    placeholder: 'Headline',
    label: HEADLINE,
    minLength: 1,
    maxLength: 252
  },
  [INDUSTRY]: {
    placeholder: 'Industry',
    label: INDUSTRY,
    minLength: 1,
    maxLength: 150
  },
  [IM_GOOD_AT]: {
    placeholder: "I'm good at",
    label: IM_GOOD_AT,
    minLength: 1,
    maxLength: 50
  },
  [ID_LIKE_TO_LEARN]: {
    placeholder: "I'd like to learn",
    label: ID_LIKE_TO_LEARN,
    minLength: 1,
    maxLength: 50
  },
  [ID_LIKE_TO_MEET]: {
    placeholder: "I'd like to meet",
    label: ID_LIKE_TO_MEET,
    minLength: 1,
    maxLength: 200
  },
  [CARDS]: {
    placeholder: 'Cards',
    label: CARDS,
    minLength: 1,
    maxLength: 65
  },
  [LAST_NAME]: {
    placeholder: 'Last Name',
    label: LAST_NAME,
    autoCompleteGoogle: 'family-name',
    minLength: 1,
    maxLength: 50,
    required: true,
    validate: lastName => (
      compose(lastName, [
        INVALID_NAME_LENGTH,
        INVALID_NAME,
        INVALID_EMOJIS
      ])
    ),
    normalize: normalizeCapitalFirst
  },
  [LOCATION]: {
    placeholder: 'Location',
    label: LOCATION,
    minLength: 1,
    maxLength: 65
  },
  [PASSWORD]: {
    placeholder: 'Create a password',
    label: PASSWORD,
    autoCompleteGoogle: 'current-password',
    type: 'password',
    minLength: 8,
    maxLength: 255,
    required: true,
    validate: password => (
      compose(password, [
        // INVALID_PASSWORD,
        INVALID_PASSWORD_LENGTH,
        // INVALID_PASSWORD_CONTAIN_NUMBER,
        INVALID_PASSWORD_CONTAIN_LOWERCASE,
        // INVALID_PASSWORD_CONTAIN_UPPERCASE, // temp remove capital letter pw criteria
        INVALID_EMOJIS
      ])
    )
  },
  [USERNAME]: {
    placeholder: 'Create a username',
    label: USERNAME,
    autoCompleteGoogle: 'username',
    minLength: 1,
    maxLength: 20,
    required: true,
    validate: username => (
      compose(username, [
        INVALID_SYMBOLS,
        INVALID_SPACES,
        INVALID_EMOJIS
      ])
    ),
    normalize: normalizeLowerCase
  },
  [INSTAGRAM]: {
    placeholder: 'Instagram',
    label: INSTAGRAM,
    minLength: 1,
    maxLength: 65
  },
  [WEBSITE]: {
    placeholder: 'Website',
    label: WEBSITE,
    minLength: 1,
    maxLength: 65
  }
};

export default profileInputFields;
