import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import SVG from 'react-inlinesvg';

import './GlobalSearch.scss';
import { TabinationButtons, TabinationContent } from '../../components/Tabination';
import Input, { INPUT_PRIMARY_UNDERLINE } from '../../common/Input';
import SearchCategoryContainer from './SearchCategoryContainer';
import Button, { THEME_LIGHT_BLUE } from '../../common/Button';
import UserListItem from '../../components/UserList/UserListItem';

import { searchSetViewing, searchSetQuery, searchQuery, searchCheckClear, searchSetPosition, SEARCH_GET } from '../../store/Search/actions';
import CollectivesListItem from '../Collectives/CollectivesList/CollectivesListItem';
import SearchPostResult from './SearchPostResult';
import InfiniteScroller from '../../components/InfiniteScroller';
import Loader from '../../common/Loader';

import NoResults from '../../components/NoResults';
import { GTMpushDataLayerEvent, GTM_GLOBAL_SEARCH, GTM_GLOBAL_SEARCH_CLICKED } from '../../lib/GoogleTagManager';

const SEARCH_LABELS = {
  everything: 'Everything',
  users: 'Members',
  collectives: 'Collectives',
  posts: 'Posts'
}

class GlobalSearch extends Component {
  static propTypes = {

  };

  constructor(props) {
    super(props);
    if (!props.initialLoadComplete) this.props.searchCheckClear({ limit: 3 }, 'everything');

    this.debouncedSearch = debounce(this.search, 500);
    this.debouncedSearchGTM = debounce(this.GTMSearchCompleted, 2000);
  }

  componentDidMount = () => {
    const { categories, currentOption } = this.props;
    const newPosition = categories[currentOption] && categories[currentOption].length === 1 ? categories[currentOption][0].scrollPosition : 0;
    // The timeout is necessary to allow the scroll container a chance
    // to rerender (if the new position is greater than the scroll container's old maximum height).
    setTimeout(() => {
      this.listRef.scrollTop = newPosition;
    }, 50);
  }

  componentWillUnmount = () => {
    this.setScrollPosition();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.shouldReset && !this.props.shouldReset) this.listRef.scrollTop = 0;
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  GTMSearchCompleted = () => { this.GTMSearchEvent(GTM_GLOBAL_SEARCH); }
  GTMSearchResultClicked = () => { this.GTMSearchEvent(GTM_GLOBAL_SEARCH_CLICKED); }
  GTMSearchEvent = (event) => {
    GTMpushDataLayerEvent({
      event,
      term: this.props.query,
      category: this.props.currentOption,
    });
  }

  handleSearchChange = query => {
    if (query !== this.props.query) {
      this.props.searchSetQuery({ index: this.props.currentOption, query }, this.props.currentOption);
      this.debouncedSearch(query);
      this.debouncedSearchGTM();
    }
  }

  search = query => {
    this.props.searchQuery({ index: this.props.currentOption, query }, this.props.currentOption);
  }

  setScrollPosition = () => {
    const { currentOption } = this.props;
    this.props.searchSetPosition({ category: currentOption, scrollPosition: this.listRef.scrollTop });
  }

  setCurrentOption = option => {
    this.setScrollPosition();
    this.props.searchSetViewing({ option });
    if (!this.props.categories || !this.props.categories[option]) this.props.searchCheckClear({ limit: 12, index: option, query: this.props.query }, option);
    const newPosition = this.props.categories[option] && this.props.categories[option].length === 1 ? this.props.categories[option][0].scrollPosition : 0;
    // The timeout is necessary to allow the scroll container a chance
    // to rerender (if the new position is greater than the scroll container's old maximum height).
    setTimeout(() => {
      this.listRef.scrollTop = newPosition;
    }, 50);
  }

  loadMore = () => {
    const { currentOption, categories } = this.props;
    this.props.searchCheckClear({ limit: 10, offset: categories[currentOption][0].offset, index: currentOption == 'everything' ? '' : currentOption }, currentOption);
  }

  getCategory = (category, limit, noScroll) => {
    const { categories, requests, currentOption } = this.props;

    const data = categories[category] || [];
    let hasLoaded = false;
    let hasResults = false;
    return (
      <div>
        {
          data.map(result => {
            if (!requests) return;

            const shouldLoadMore = !noScroll && !result.hasLoadedAll && category == currentOption && (
              requests[result.index] ? requests[result.index].status != 'PENDING' : true
            );

            hasLoaded = true;
            hasResults = !!result.results.length || hasResults;

            if (!result.results.length) return;

            return (
              <SearchCategoryContainer
                title={SEARCH_LABELS[result.index]}
                seeMoreAction={category === 'everything' ? () => { this.setCurrentOption(result.index) } : undefined}
                key={result.index}
              >
                <InfiniteScroller
                  index={result.index}
                  shouldLoadMore={shouldLoadMore}
                  hasLoadedAll={result.hasLoadedAll}
                  loadMore={this.loadMore}
                  componentRef={this.listRef}
                  onClick={this.GTMSearchResultClicked}
                >
                  {
                    this.renderComponentsByIndex(result.index, result.results, limit)
                  }
                  {
                    category !== 'everything' && !result.hasLoadedAll && (
                      <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom: '20px'}}>
                        <Loader className="bg__blue" />
                      </div>
                    )
                  }
                </InfiniteScroller>
              </SearchCategoryContainer>
            )
          })
        }
        {
          hasLoaded && !hasResults && (
            <NoResults
              heading="No Results Found"
              subheading="Can’t find any results for that search. Try another?"
            />
          )
        }
      </div>
    )
  }

  renderComponentsByIndex = (index, results, limit) => {
    const { close } = this.props;
    switch (index) {
      case 'users':
        return results.slice(0, limit).map(user => (
          <UserListItem user={user} currentUser={this.props.user} admiresMap={{}} key={user.username} modalClose={close} noCTA />
        ))
      case 'collectives':
        return results.slice(0, limit).map(collective => (
          <CollectivesListItem collective={collective} showAvatar showPostNumber={false} key={collective.name} modalClose={close} noCTA />
        ))
      case 'posts':
        return results.slice(0, limit).map(post => (
          <SearchPostResult post={post} key={post.id + post.title} modalClose={close} />
        ))
    }
  }

  setListRef = ref => {
    if (this.props.listRef) {
      this.listRef = this.props.listRef;
    } else {
      this.listRef = ref;
    }

    this.forceUpdate();
  }

  render() {
    const { query, currentOption } = this.props;
    const options = {
      everything: {
        label: 'Everything',
        loadMore: this.loadMoreConnections,
        clear: this.clearConnections,
        component: this.getCategory('everything', 3, true)
      },
      users: {
        label: 'Members',
        loadMore: this.loadMoreAdmirers,
        clear: this.clearAdmirers,
        component: this.getCategory('users')
      },
      collectives: {
        label: 'Collectives',
        loadMore: this.loadMoreAdmirers,
        clear: this.clearAdmirers,
        component: this.getCategory('collectives')
      },
      posts: {
        label: 'Posts',
        loadMore: this.loadMoreAdmirers,
        clear: this.clearAdmirers,
        component: this.getCategory('posts')
      }
    }
    return (
      <div className="GlobalSearch">
        <div className="GlobalSearch__search-container">
          <Input
            autoCorrect={'off'}
            onChange={this.handleSearchChange}
            placeholder="Search members, collectives, or posts"
            theme={INPUT_PRIMARY_UNDERLINE}
            value={query}
            autoFocus
          />
          <TabinationButtons
            setCurrentOption={this.setCurrentOption}
            currentOption={currentOption}
            options={options}
            center
          />
        </div>
        <div className="GlobalSearch__content-container" ref={this.setListRef}>
          <TabinationContent
            setCurrentOption={this.setCurrentOption}
            currentOption={currentOption}
            options={options}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ Search, requests }) => ({
  query: Search.query,
  currentOption: Search.viewing,
  initialLoadComplete: Search.initialLoadComplete,
  categories: Search.categories,
  requests: requests[SEARCH_GET],
  shouldReset: Search.shouldReset
});

export default connect(mapStateToProps, {
  searchSetViewing,
  searchSetQuery,
  searchSetPosition,
  searchCheckClear,
  searchQuery
})(GlobalSearch);
