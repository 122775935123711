import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { INPUT_SECONDARY }  from '../../common/Input';
import InputWithLabel from '../../common/Input/InputWithLabel';
import Dropdown from '../../common/Dropdown';
import InputWithUserTagging from '../../common/Input/InputWithUserTagging';
import postInputFields, {
  TITLE,
  BODY
} from '../../common/Input/CommonFields/post';
import { collapseErrors } from '../../common/Input/CommonFields/validations';
import { tags } from '../../components/ContentTags';

import './NewPostForm.scss';

const MAX_TAGS_NUM = 3;

class NewPostForm extends Component {
  static propTypes = {
    [TITLE]: PropTypes.string,
    [BODY]: PropTypes.string,
  };

  static defaultProps = {
    [TITLE]: '',
    [BODY]: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      [TITLE]: this.props[TITLE] || '',
      [BODY]: this.props[BODY] || '',
      bodyCount: 0,
      errors: [],
      displayError: false,
      selectedTag: '',
      postTags: [],
      showTagMessage: true,
      isTagErrored: false
    };
  };

  componentDidMount = () => {
    if(this.props.topics) {
      // convert the tags key back to the value
      let postTags = this.props.topics.map(topic => tags[topic]);
      this.setState({
        postTags
      });
    };    

    this.props.onRef(this);
  };

  componentWillUnmount = () => {
    this.props.onRef(undefined);
  }
  
  componentWillReceiveProps = newProps => {
    if (this.props.isLoading && !newProps.isLoading && !newProps.errors) {
      this.props.close();
    }
  }

  handleTitleChange = (val) => {
    this.setState({ [TITLE]: val, errors: [] });
  }

  handleBodyChange = (val) => {
    this.setState({ [BODY]: val, errors: [] });
  }

  updateBodyCount = val => {
    this.setState({
      bodyCount: val.length
    });
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  setInputRef = ref => {
    this.setRef(ref); 
  }

  onSelectTags = val => {
    if(this.state.postTags.length <= MAX_TAGS_NUM && this.state.postTags.indexOf(val) === -1) {
      let tags = this.state.postTags;
      tags.push(val);
      this.setState({
        postTags: tags,
        showTagMessage: this.state.postTags.length === MAX_TAGS_NUM ? true : false,
        selectedTag: val,
        isTagErrored: false
      });
    } else if(this.state.postTags.length === 0) {
      this.setState({
        showTagMessage: true,
        isTagErrored: false
      });
    };
  };

  onDeselectTag = index => {
    let tags = this.state.postTags;
    tags.splice(index, 1);

    this.setState({
      postTags: tags,
      showTagMessage: index === 0 ? true : false,
      selectedTag: '',
      isTagErrored: false
    });
  };

  submit = () => {
    if(this.state[TITLE].trim().length  === 0 || this.state[BODY].trim().length === 0) {
      this.state.errors = ['The fields cannot be empty.'];
    };

    const hasErrors = !!this.state.errors.length;
    
    this.setState({ 
      displayError: hasErrors,
      isTagErrored: this.state.postTags.length === 0 ? true : false,
      errors: this.state.errors
    }, () => {
      if (hasErrors || this.state.isTagErrored) return;

      if (this.state[TITLE]) {
        this.submitPost();
      };
    });   
  };

  submitPost = () => {
    const title = this.state[TITLE];
    const body = this.state[BODY];

    const topics = [];
    for(let tag in tags) {
      // convert the tag values back to the keys
      if(this.state.postTags.indexOf(tags[tag]) > -1) {
        topics.push(tag);
      };
    };

    this.props.submitPost({
      [TITLE]: title,
      [BODY]: body,
      topics
    });
  };

  render() {
    const { postTags, showTagMessage, selectedTag, isTagErrored, bodyCount } = this.state;

    let options = [];
    for(let tag in tags) {
      if(tag !== 'all' & tag !== 'digital_firesides') {
        options.push(tags[tag]);
      };
    };

    let errorMessage = '';
    if(postTags.length === 0) {
      errorMessage = 'Please add at least 1 topic.'
    } else if(postTags.length === 3) {
      errorMessage = "You're maxed out!";
    };

    return (
      <form onSubmit={this.submit}>
        <div className="PostFocus__input-container">
          {
            this.state.displayError && (
              collapseErrors(this.state.errors).map(error => (
                <p className="PostModal__topic err" key={error}>{error}</p>
              ))
            )
          }

          <InputWithLabel
            autoFocus
            className="PostFocus__input PostFocus__input--question"
            displayError={false}
            info={`${this.state[TITLE].length}/150`}
            placeholder="Your post headline goes here."
            onChange={this.handleTitleChange}
            required
            theme={INPUT_SECONDARY}
            value={this.state[TITLE]}
            {...postInputFields[TITLE]}
          />
        </div>
        <div className="PostFocus__input-container">
          <InputWithUserTagging
            className="PostFocus__input PostFocus__input--body Input"
            component="textarea"
            displayError={false}
            info={`${bodyCount}/2500`}
            inputComponent={InputWithLabel}
            minRows={5}
            onChange={this.handleBodyChange}
            placeholder="The Girlboss community is here for you. How can we help?"
            required
            theme={INPUT_SECONDARY}
            value={this.state[BODY]}
            updateBodyCount={this.updateBodyCount}
            {...postInputFields[BODY]}
          />
        </div>
        <p className={classnames("PostModal__topic", {err: isTagErrored, max: postTags.length === MAX_TAGS_NUM})}>
          <span className="PostModal__topic--header">Topic</span> 
          <span className={classnames({"PostModal__topic--selected": postTags.length > 0 && postTags.length < MAX_TAGS_NUM})}>{postTags.length}</span> 
          <span>{`/${MAX_TAGS_NUM}`}</span>
          {showTagMessage ? <span className="PostModal__topic--error">{errorMessage}</span> : null}
        </p>

        <div className="PostModal__dropdown-container">
          <div className="PostModal__tags-container"  >
            {
              postTags.length === 0 ? 
              <span>Add Topic</span> :
              postTags.map((postTag, index) => <div className="PostModal__tags-container--tag" key={index} onClick={() => this.onDeselectTag(index)}>{postTag}</div>)
            }
          </div>
          <Dropdown 
            className="PostModal__dropdown" 
            onChange={this.onSelectTags} 
            options={options} 
            placeholder="Select tags" 
            label="tags" 
            disabledOptions={postTags}
            value={selectedTag}
            disabled={postTags.length === MAX_TAGS_NUM}
          />
        </div>
      </form>
    )
  }
}

export default NewPostForm;
