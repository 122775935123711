import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import locationFormatter from '../../../utils/locationFormatter';

import {
  FIRST_NAME,
  LAST_NAME,
  PROFILE_PHOTO,
  HEADLINE,
  INDUSTRY,
  LOCATION,
  INSTAGRAM,
  WEBSITE
} from '../../../common/Input/CommonFields/profile';

import Button, { THEME_GRAY } from '../../../common/Button';
import instagramIcon from '../../../../public/assets/icons/INSTAGRAM.svg';
import hyperlinkIcon from '../../../../public/assets/icons/HYPERLINK.svg';

import UserActions from '../../../containers/UserActions';

import CopyIcon from '../../../../public/assets/icons/SHARE.svg';

export const TYPE_USER = 'user';

import '../Profile.scss';

class ProfileUser extends Component {
  static propTypes = {
    [FIRST_NAME]: PropTypes.string,
    [LAST_NAME]: PropTypes.string,
    [PROFILE_PHOTO]: PropTypes.string,
    [LOCATION]: PropTypes.string,
    [HEADLINE]: PropTypes.string,
    [INDUSTRY]: PropTypes.string,
    [INSTAGRAM]: PropTypes.string,
    [WEBSITE]: PropTypes.string,
    toggleEditMode: PropTypes.func,
    update: PropTypes.func,
    save: PropTypes.func,
    cancel: PropTypes.func,
    isEditing: PropTypes.bool.isRequired,
    canViewEditStates: PropTypes.bool.isRequired,
    sendContactRequest: PropTypes.func,
    contactURL: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    contactRequestSent:PropTypes.bool,
    contactRequestAvailable: PropTypes.bool,
    contactRequestAvailableTimestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    copyProfileLink: PropTypes.func
  }

  static defaultProps = {
    contactURL:false,
    contactRequestExpiration:false,
    contactRequestAvailable:true,
    isEditing:false,
    canViewEditStates:false
  }

  constructor(props) {
    super(props);
  }

  renderInstagramLink = () => {
    if(this.props[INSTAGRAM].includes('instagram.com')) {
      if(this.props[INSTAGRAM].startsWith('http')) {
        return this.props[INSTAGRAM];
      } else if(this.props[INSTAGRAM].startsWith('@')) {
        return this.props[INSTAGRAM].substring(1);
      } else {
        return 'https://' + this.props[INSTAGRAM];
      }
    } else {
      return `https://www.instagram.com/${this.props[INSTAGRAM]}`;
    }
  }

  render() {
    const {
      isEditing,
      canViewEditStates,
      isLoggedIn,
      ownUserId,
      userId,
      email,
      copyProfileLink
    } = this.props;

    return (
      <div className="ProfileUser__container">
        <div className="ProfileUser">
          <h1 className="ProfileUser__heading">
            { this.props[FIRST_NAME] }
            <br />
            { this.props[LAST_NAME] }
          </h1>
          <h4 className="ProfileUser__subheading ProfileUser__subheading-spacing">
              { this.props[HEADLINE] }
          </h4>
          <h5 className="ProfileUser__subheading">
            <span>{ locationFormatter(this.props[LOCATION]) }</span>
          </h5>
          <h5 className="ProfileUser__subheading">
            <span>{ this.props[INDUSTRY] }</span>
          </h5>
          {userId != ownUserId && email && (
            <h5 className="ProfileUser__subheading" style={{ textTransform: 'none' }}>
              <a href={`mailto:${email}`}>{email}</a>
            </h5>
          )}
          <div className="ProfileUser__link-container">
            {
              this.props[INSTAGRAM] && (
                <a href={this.renderInstagramLink()} target="_blank">
                  <img className="ProfileUser__link-icon scale-on-hover" src={instagramIcon} />
                </a>
              )
            }
            {
              this.props[WEBSITE] && (
                <a href={this.props[WEBSITE]} target="_blank">
                  <img className="ProfileUser__link-icon scale-on-hover" src={hyperlinkIcon} />
                </a>
              )
            }
            <Button onClick={copyProfileLink} theme={THEME_GRAY} pill noOutline className="ProfileUser__share-profile">
              <img src={CopyIcon} style={{position:'relative', top:'1px'}} />
              Copy URL
            </Button>
            { isLoggedIn && !canViewEditStates && (
                <div className="ProfileUser__report-user--phone">
                  <UserActions 
                    userId={ownUserId}
                    typeId={userId}
                    type={TYPE_USER}
                    doesBelongToUser={ownUserId === userId}
                    reportUser
                  />
                </div>
              )
            }
          </div>
        </div>
        {
          !isLoggedIn && (
            // '<Link to="/login">
            //   <button className="large bottom left ProfileUser__action-button-message">Log In to Say Hey 👋</button>
            // </Link>' 
            ''
          ) 
        }
        {
          isLoggedIn && !isEditing && !canViewEditStates && (
            <div className="ProfileUser__report-user--desktop">
              <UserActions 
                userId={ownUserId}
                typeId={userId}
                type={TYPE_USER}
                doesBelongToUser={ownUserId === userId}
                reportUser
              />
            </div>
          )
        }
      </div>
    )
  }
}

export default ProfileUser;
