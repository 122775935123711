import React, { PureComponent } from 'react';
import Input from '../../common/Input';

import './Onboarding.scss';

class OnboardingTextVerificationRequest extends PureComponent {
  static propTypes = {

  };
   
  render() {
    const {
      phoneNumber,
      handlePhoneNumberChange,
      errors
    } = this.props;

    return (
      <div className="Onboarding__page-container-username">
        <div style={{textAlign: 'center'}}>
          <h2 className="Onboarding__heading">Enter Your Mobile Number</h2>
          <h4 className="Onboarding__subheading">We'll text a code to this number to verify your account. Standard text messaging rates apply. US mobile numbers only.</h4>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Input
              className="Onboarding__input Onboarding__input--medium"
              placeholder="(xxx)-xxx-xxxx"
              type="text"
              maxLength={10}
              onChange={handlePhoneNumberChange}
              controlledValue={phoneNumber}
              errors={errors['phone_number']}
              inputMode="numeric"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default OnboardingTextVerificationRequest;
