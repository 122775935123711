import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  passwordReset
} from '../../../store/PasswordReset/actions';
import {
  toasterOpen,
  TOASTER_TYPE_ERROR
} from '../../../store/Toaster/actions';

import Input, { INPUT_SECONDARY } from '../../../common/Input';
import Button, {
  THEME_BLACK
} from '../../../common/Button';

import {
  INVALID_PASSWORD_LENGTH,
  INVALID_PASSWORD_CONTAIN_NUMBER,
  INVALID_PASSWORD_CONTAIN_LOWERCASE,
  INVALID_PASSWORD_CONTAIN_UPPERCASE,
  compose
} from '../../../common/InputV2/CommonFields/validations';

import './PasswordReset.scss';

class PasswordReset extends Component {
  constructor (props) {
    super(props);

    this.state = {
      password:'',
      errors: {},
      passwordConfirmation:'',
      passwordLengthError:false,
      passwordMatchError:false
    }
  }

  handlePasswordChange = (e) => {
    this.setState({password:e.target.value});
  }

  handlePasswordConfirmationChange = (e) => {
    this.setState({passwordConfirmation:e.target.value});
  }

  validatePassword = (password) => {
    let passwordErrors = compose(password, [
      INVALID_PASSWORD_LENGTH,
      INVALID_PASSWORD_CONTAIN_NUMBER,
      INVALID_PASSWORD_CONTAIN_LOWERCASE,
      INVALID_PASSWORD_CONTAIN_UPPERCASE
    ]);

    return passwordErrors;
  }

  handleChange = (value, label) => {
    if (!value) {
      delete this.state.errors[label];
    } else {
      let passwordErrors = this.validatePassword(value);
      if(passwordErrors.length > 0) {
        this.state.errors[label] = passwordErrors;
      } else {
        delete this.state.errors[label];
      }
    }

    this.setState({ [label]: value, errors: this.state.errors });

    this.setState({
      firstPassword:value,
    });
  }

  handleRepeatChange = (value, label) => {
    if (value !== this.state.firstPassword) {
      this.state.errors[label] = ['Passwords must match.'];
    } else {
      delete this.state.errors[label];
    }

    this.setState({
      errors: this.state.errors,
      secondPassword:value
    });
  }

  setPassword = (e) => {
    e.preventDefault();

    const { firstPassword, secondPassword } = this.state;
    
    if (!this.state.errors.password && firstPassword === secondPassword) {
      this.props.passwordReset(this.passwordChangedConfirmation, this.props.match.params.token, {password:firstPassword});
    }
  }

  passwordChangedConfirmation = (changed) => {
    if (changed) {
      this.props.history.push('/reset-success');
    } else {
      this.props.toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: 'Something went wrong',
        timeout: 4000
      })
    }
  }

  render () {
    return (
      <div className="container">
        <div className="row center">
          <form className="reset-container" onSubmit={this.setPassword}>
            <h2 className="reset">Create a new password</h2>
            <h4 className="reset">Try to remember it this time!</h4>
            <div className="reset-input">
              <Input
                theme={INPUT_SECONDARY}
                onChange={this.handleChange}
                placeholder="New password"
                type={'password'}
                errors={this.state.errors.password}
                label={'password'}
              />
            </div>
            <div className="reset-input">
              <Input
                theme={INPUT_SECONDARY}
                placeholder="Re-enter new password"
                onChange={this.handleRepeatChange}
                type={'password'}
                label={'passwordRepeat'}
                errors={this.state.errors.passwordRepeat}
              />
            </div>
            <h5 className="reset">Password must contain an uppercase letter, lowercase letter, and number</h5>
            <Button theme={THEME_BLACK} onClick={this.setPassword}>Submit</Button>
          </form>
        </div>
      </div>
    )
  }
}

export default connect(null, { passwordReset, toasterOpen })(PasswordReset);
