import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalContainer, ModalBody } from '../../ModalContainer';
import ContactRequestModalIntent from './ContactRequestModalIntent';
import ContactRequestModalContent from './ContactRequestModalContent';
import ContactRequestModalConfirmation from './ContactRequestModalConfirmation';
import ContactRequestModalSent from './ContactRequestModalSent';

import {
  GTMpushDataLayerEvent,
  GTM_CONTACT_REQUEST_STARTED,
  GTM_CONTACT_REQUEST_INTENT_SELECTED
} from '../../../../lib/GoogleTagManager';

import CaretLeftIcon, { CARET_LEFT_THEME_DARK } from'../../../../components/SVG/CaretLeftIcon';

import './ContactRequestModal.scss';

class ContactRequestModal extends Component {
  static propTypes = {
    user:PropTypes.object,
    send:PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      stage:1,
      intent:'',
      message:''
    }

    GTMpushDataLayerEvent({
      event: GTM_CONTACT_REQUEST_STARTED
    });
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  setNotificationRef = ref => {
    this.setRef(ref);
  }

  handleNotificationConfirmation = () => {
    this.setState({stage:1});
  }

  handleIntentSelection = (intent) => {
    
    GTMpushDataLayerEvent({
      event: GTM_CONTACT_REQUEST_INTENT_SELECTED
    });

    this.setState({intent, stage:2});
  }

  handleMessageChange = (message) => {
    this.setState({message});
  }

  handleMessageSubmission = () => {
    const { send, user, close } = this.props;
    const { intent, message } = this.state;

    if (this.state.message.length > 0) {
      send({
        contactId:user.id,
        requestType:intent,
        initialMessage:message
      });

      this.setState({stage:3});
    }
  }

  handleBack = () => {
    let { stage } = this.state;
    if (stage > 1 && stage < 3) {
      stage--;
      this.setState({stage});
    }
  }

  render() {
    const { user } = this.props;
    const { intent, message, stage } = this.state;

    return (
      <ModalContainer
        className={
          classNames(
            "ContactRequestModal",
            {
              'Sent':(stage == 3)
            }
          )
        }
        ref={this.setRef}
      >
        {this.props.children}

        <ModalBody>
        {
          (stage > 1) && (stage < 3) && (
            <div className="ContactRequestModal__back-icon" onClick={this.handleBack}>
              <CaretLeftIcon theme={CARET_LEFT_THEME_DARK} />
            </div>
          )
        }
        {
          (stage == 1) && (
            <ContactRequestModalIntent
              user={user}
              handleIntentSelection={this.handleIntentSelection}
              intent={this.state.intent}
            />
          )
        }
        {
          (stage == 2) && (
            <ContactRequestModalContent
              user={user}
              handleMessageChange={this.handleMessageChange}
              handleMessageSubmission={this.handleMessageSubmission}
              message={message}
              intent={intent}
            />
          )
        }
        {
          (stage == 3) && (
            <ContactRequestModalSent user={user} />
          )
        }

      </ModalBody>
    </ModalContainer>
    )
  }
}

export default ContactRequestModal;
