import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import {
  getActivityIcon,
  activityTypes,
  getNotificationContentType,
  getNotificationContentLink,
  getNotificationCollectiveLink,
  getNotificationSnippet
} from '../../store/Legion/logic';

import Notification from './index';
import NotificationText from '../NotificationText';

import UserTagParser from '../UserTagParser';

class NotificationActivity extends Component {
  redirectToPost = (link) => {
    this.props.history.push(link);
  }

  render() {
    const { props } = this;

    const {
      postActivity,
      containerClassName,
      sender,
      receiver,
      notificationType,
      date,
      isRead,
      children,
      closeSideNav
    } = this.props;

    if (!postActivity) {
      return null;
    }

    const { comment_content, post_content, activity_type, comment_id, post_id } = postActivity;

    if (!activityTypes[activity_type]) {
      return null;
    }
    
    const scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop + 340;

      window.scrollTo({
        top: elementPosition,
        left: 0,
        behavior: 'smooth'
      });
    }

    const activityData = activityTypes[postActivity.activity_type];
    const activityAction = activityData.action;
    const activityContentType = activityData.content;

    const snippet = getNotificationSnippet({ commentContent: comment_content, postContent: post_content, activity: activity_type });
    const contentLink = getNotificationContentLink(post_id, post_content.slug, comment_id);
    const collectiveLink = getNotificationCollectiveLink(post_content.collective.slug);
    const collectiveName = post_content.collective.name;

    return (
      <React.Fragment>
        <Notification
          {...props}
          onClick={closeSideNav}
          icon={getActivityIcon(activityAction)}
          link={contentLink}
        >
          <NotificationText
            sender={sender}
            receiver={receiver}
            notificationType={notificationType}
            activityType={postActivity.activity_type}
            contentType={activityContentType}
            className={this.props.notificationClassName}
            link={contentLink}
            collectiveLink={collectiveLink}
            collectiveName={collectiveName}
            id={this.props.id}
            isRead={isRead}
            closeSideNav={closeSideNav}
          />
          {
            snippet && (
              <Link 
              scroll={el => { scrollWithOffset(el, 60)}}
              to={postActivity.comment_id ? contentLink + '#' + postActivity.comment_id : contentLink}
              onClick={closeSideNav}
              >
                <p
                  className={classNames(
                    "Notification__text--content",
                    this.props.contentClassName
                  )}
                >
                  "{<UserTagParser body={snippet} hideTags />}"
                </p>
              </Link>
            )
          }
        </Notification>
      </React.Fragment>
    )
  }
}

export default withRouter(NotificationActivity);
