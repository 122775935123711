import { defineRequest, MODE_LEADING } from '../../lib/reduxRequests';

/**
  Collectives
**/

export const SET_CURRENT_COLLECTIVE_SLUG = 'SET_CURRENT_COLLECTIVE_SLUG';
export const CLEAR_COLLECTIVES_OFFSET = 'CLEAR_COLLECTIVES_OFFSET';

export const {
  COLLECTIVES_GET,
  COLLECTIVES_GET_FULFILLED,
  collectivesGet
} = defineRequest('COLLECTIVES_GET', (params, key) => ({ key, payload: params }));

export const {
  COLLECTIVE_GET,
  COLLECTIVE_GET_FULFILLED,
  collectiveGet
} = defineRequest('COLLECTIVE_GET');

export const collectivesGetRecommended = params => (
  collectivesGet({ ...params, recommended: true }, 'recommended')
);

export const setCurrentCollectiveSlug = payload => {
  return {
    type: SET_CURRENT_COLLECTIVE_SLUG,
    payload
  }
}

export const clearCollectivesOffset = () => {
  return {
    type: CLEAR_COLLECTIVES_OFFSET
  }
}


 export const UPDATE_COLLECTIVE_DATA = 'UPDATE_COLLECTIVE_DATA';
 export const CLEAR_COLLECTIVE_DATA = 'CLEAR_COLLECTIVE_DATA';

 export const updateCollectiveData = data => {
  return {
    type: UPDATE_COLLECTIVE_DATA,
    payload: data
  }
 }

 export const clearCollectiveData = () => {
   return {
     type: CLEAR_COLLECTIVE_DATA
   }
 }

 /**
 * Create Collective
 */

 export const {
   CREATE_COLLECTIVE,
   CREATE_COLLECTIVE_FULFILLED,
   CREATE_COLLECTIVE_REJECTED,
   createCollective 
 } = defineRequest('CREATE_COLLECTIVE', (...params) => ({
  payload: params
 }));

/**
 * Update Collective
 */
export const {
  UPDATE_COLLECTIVE,
  UPDATE_COLLECTIVE_FULFILLED,
  UPDATE_COLLECTIVE_REJECTED,
  updateCollective
 } = defineRequest('UPDATE_COLLECTIVE', (...params) => ({
   payload: params
 }));

/**
 * Publish Collective
 */
export const {
  PUBLISH_COLLECTIVE,
  PUBLISH_COLLECTIVE_FULFILLED,
  publishCollective
} = defineRequest('PUBLISH_COLLECTIVE', params => ({
  payload: params
}));
 
/**
 * Collective Member
 */

export const CLEAR_CURRENT_MEMBERS_LIST = 'CLEAR_CURRENT_MEMBERS_LIST';

export const clearCurrentMembersList = () => {
  return {
    type: CLEAR_CURRENT_MEMBERS_LIST
  }
}

export const CLEAR_USER_COLLECTIVES = 'CLEAR_USER_COLLECTIVES';

export const clearUserCollectives = () => {
  return {
    type: CLEAR_USER_COLLECTIVES
  }
}

export const {
  COLLECTIVE_MEMBER_GET,
  COLLECTIVE_MEMBER_GET_FULFILLED,
  collectiveMemberGet
} = defineRequest('COLLECTIVE_MEMBER_GET', (...params) => ({
  payload: params
}));

export const {
  COLLECTIVE_MEMBER_ADD,
  COLLECTIVE_MEMBER_ADD_FULFILLED,
  collectiveMemberAdd
} = defineRequest('COLLECTIVE_MEMBER_ADD', params => ({
  key: `${params.collective_id}_${params.user_id || '*'}`,
  payload: params,
  mode: MODE_LEADING,
  cleanup: true
}));

export const {
  COLLECTIVE_MEMBER_REMOVE,
  COLLECTIVE_MEMBER_REMOVE_FULFILLED,
  collectiveMemberRemove
} = defineRequest('COLLECTIVE_MEMBER_REMOVE', params => ({
  key: params.memberId,
  payload: params,
  mode: MODE_LEADING,
  cleanup: true
}));

// Selectors

export const getCollectiveBySlug = (state, slug) => (state.Collectives.collectives.find(collective => (collective.slug == slug)));