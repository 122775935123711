
import ellipsize from 'ellipsize';
import { find } from 'linkifyjs';
import linkifyhtml from 'linkifyjs/html';

const snippetize = (value, length) => {
  const originalLinks = find(value);
  const linkified = linkifyhtml(ellipsize(value, length, { ellipse: ''}));
  let i = 0;
  return linkified.replace(/href\=\"(.+?)\"/g, () => {
    i++;
    return `href="${originalLinks[i-1].href}"`;
  });  
};

export default snippetize;