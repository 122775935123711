import { takeEvery, put, call, select } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  notifsGet,
  NOTIFS_GET,
  notifsGetLimit,
  notifsGetOffset,
  notifsGetSuccess,
  notifsGetError,
  NOTIFS_READ,
  notifsReadSuccess,
  notifsReadError,
  NOTIFS_READ_All,
  notifsReadAllSuccess,
  notifsGetMoreSuccess,
  NOTIFS_GET_MESSAGES,
  notifsGetMessages,
  notifsGetMessagesSuccess,
  notifsGetMessagesError,
  notifsGetUserSendbirdId,
  notifsUpdateSuccess
} from './actions';
import {
  GTMpushDataLayerEvent,
  GTM_NOTIFICATION_READ
} from '../../lib/GoogleTagManager';

function* notifsSagaGet(action) {
  const limit = yield select(notifsGetLimit);
  const offset = yield select(notifsGetOffset);

  const response = yield call(fetchAPI, 'getNotifications', {
    limit: action.payload === 'read-all' ? offset : limit,
    offset: action.payload === 'ajax-refresh' || action.payload === 'read-all' ? 0 : offset
  });

  if (response.success) {
    if(action.payload === 'get-more') {
      // loading more notifications 
      yield put(notifsGetMoreSuccess(response));
      const sendbirdId = yield select(notifsGetUserSendbirdId);      
      if(sendbirdId) yield put(notifsGetMessages(sendbirdId));
    } else if(action.payload === 'ajax-refresh') {
      // prepends new notifications to notifications
      const state = yield select();
      let notificationIds = state.Notifications.notifications.map(notification => notification.id);
      let newNotifications = [];
      for(let responseNotif of response.notifications) {
        if(notificationIds.indexOf(responseNotif.id) === -1) {
          newNotifications.push(responseNotif);
        };
      };
      response.notifications = newNotifications;
      yield put(notifsUpdateSuccess(response));
    } else if(action.payload === 'read-all') {
      yield put(notifsReadAllSuccess(response));
    } else {
      yield put(notifsGetSuccess(response));
    }
  } else {
    yield put(notifsGetError(response));
  };
};

function* notifsSagaGetMessages(action) {
  // let messageCount;

  // try {
  //   const session = yield messaging.startSession(action.payload);
      
  //   yield new Promise(resolve => {
  //     messaging.client.getTotalUnreadMessageCount((count, err) => {
  //       if (err) {
  //         console.log(err);
  //       } else {
  //         messageCount = count;
  //       }
  //       session.close();
  //       resolve();
  //     });
  //   });
  // } catch(err) {
  //   console.log(err);
  // }
  
  // if (messageCount === null) {
  //   yield put(notifsGetMessagesError());
  // }
  // yield put(notifsGetMessagesSuccess(messageCount));
}

function* notifsSagaRead(action) {
  const response = yield call(fetchAPI, 'readNotifications', {
    body: {
      notification_ids: action.payload
    }
  });

  if(response.success) {
    yield put(notifsReadSuccess(action.payload));
  } else {
    yield put(notifsReadError(response));
  }

  GTMpushDataLayerEvent({ event: GTM_NOTIFICATION_READ });
}

function* notifsSagaReadAll(action) {
  const response = yield call(fetchAPI, 'readAllNotifications', {});

  if(response.success) {
    yield put(notifsGet('read-all'));
  }
}

export const notifsSagas = [
  takeEvery(NOTIFS_GET, notifsSagaGet),
  takeEvery(NOTIFS_READ, notifsSagaRead),
  takeEvery(NOTIFS_READ_All, notifsSagaReadAll),
  takeEvery(NOTIFS_GET_MESSAGES, notifsSagaGetMessages)
];
