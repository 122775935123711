import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CountdownTimer from '../../../components/CountdownTimer';

class ContactRequestPendingModal extends Component {
  static propTypes = {

  };

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  render() {

    const { contactRequestAvailableTimestamp, close } = this.props;

    return (
      <div className="ContactRequestPendingModal__container">
        <div className="ContactRequestPendingModal" ref={this.setRef}>
          <h2>Request Pending</h2>
          <p>You currently have an active pending request. Try again in...</p>
          <span>
            <CountdownTimer endTimestamp={contactRequestAvailableTimestamp} />
          </span>
          <div onClick={close}>Dismiss</div>
        </div>
      </div>
    )
  }
}

export default ContactRequestPendingModal;
