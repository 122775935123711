import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Button, { THEME_BLACK } from '../../common/Button';
import ProfilePhotoUploader from '../../components/ProfilePhotoUploader';
import { MONTHS_INT } from '../../common/Dropdown/CommonFields';

import { isName, isNameInLimits, isNoEmoji } from '../../common/Input/CommonFields/validations';

import {
  GTMpushDataLayerEvent,
  GTM_PROFILE_EDIT
} from '../../lib/GoogleTagManager';

import {
  FIRST_NAME,
  LAST_NAME,
  HEADLINE,
  LOCATION,
  INDUSTRY,
  PROFILE_PHOTO,
  USERNAME,
  IM_GOOD_AT,
  ID_LIKE_TO_LEARN,
  ID_LIKE_TO_MEET,
  ID,
  BIO,
  INSTAGRAM,
  WEBSITE
} from '../../common/Input/CommonFields/profile';

import { INDUSTRIES } from '../../common/Dropdown/CommonFields';

import ProfileSection from './ProfileSection';

import ProfilePersonalityCardZodiac from './ProfilePersonalityCards/ProfilePersonalityCardZodiac';
import ProfilePersonalityCardMyersBriggs from './ProfilePersonalityCards/ProfilePersonalityCardMyersBriggs';

import EditingProfileBar from './EditingProfileBar';
import ProfileField from './ProfileField';
import BioField from './BioField';

import './Editing.scss';
import Input from '../../common/Input';
import ProfilePhoto from '../../components/ProfilePhoto';
import JourneyCardEditing from './JourneyCardEditing';
import InputLocation from '../../common/Input/InputLocation';

const BLANK_CARD = () => {
  const date = new Date();
  return {
    id:-date.getTime(),
    card_type:'moment',
    card_data:{},
    start_date:{
      month:'January',
      year:'2019'
    },
    end_date:{
      month:'February',
      year:'2019'
    }
  }
}

class ProfileEditing extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    user: PropTypes.shape({
      [ID]: PropTypes.number.isRequired,
      [FIRST_NAME]: PropTypes.string.isRequired,
      [LAST_NAME]: PropTypes.string.isRequired,
      [HEADLINE]: PropTypes.string,
      [LOCATION]: PropTypes.string,
      [INDUSTRY]: PropTypes.string,
      [INSTAGRAM]: PropTypes.string,
      [WEBSITE]: PropTypes.string,
      [BIO]: PropTypes.shape({
        [IM_GOOD_AT]: PropTypes.string,
        [ID_LIKE_TO_LEARN]: PropTypes.string,
        [ID_LIKE_TO_MEET]: PropTypes.string
      })
    })
  };

  constructor (props) {
    super(props);

    this.state = {
      journeyCardEditCount:0,
      creatingJourneyCard:false,
      cardData:Object.assign({user_id:props.user.id}, BLANK_CARD())
    }
  }

  updateUser = (user) => {
    let { update } = this.props;
    update(user);
  }

  updateLocation = (location) => {
    let { user } = this.props;
    user.location_id = location ? location.id : null;
    user.location = location ? location.formatted_address : null;
    this.updateUser(user);
  }

  saveUser = () => {
    let { save, toggleEditMode } = this.props;
    const { journeyCardEditCount } = this.state;
    if (journeyCardEditCount == 0) {
      save();
      toggleEditMode();
    } else {
      alert("You have unsaved journey cards!");
    }
  }

  startCreatingJourneyCard = () => {
    this.setState({creatingJourneyCard:true});
  }

  createJourneyCard = () => {
    const { cardData } = this.state;
    this.props.createJourneyCard(cardData);
    this.setState({creatingJourneyCard:false, cardData:Object.assign({user_id:this.props.user.id}, BLANK_CARD())});
  }

  startEditingJourneyCard = () => {
    this.setState({journeyCardEditCount:this.state.journeyCardEditCount + 1});
  }

  doneEditingJourneyCard = () => {
    this.setState({journeyCardEditCount:Math.max(this.state.journeyCardEditCount - 1, 0)});
  }

  cancelJourneyCardCreation = () => {
    this.setState({creatingJourneyCard:false, cardData:Object.assign({user_id:this.props.user.id}, BLANK_CARD())});
  }

  render() {
    const {
      user,
      zodiacCard,
      myersBriggsCard,
      updatePersonalityCardZodiac,
      updatePersonalityCardMyersBriggs,
      instantiateZodiacCard,
      instantiateMyersBriggsCard,
      journeyCards,
      saveJourneyCard,
      deleteJourneyCard,
      canSave,
      toggleEditMode,
      errors
    } = this.props;

    const {
      creatingJourneyCard,
      cardData
    } = this.state;

    const photo = user[PROFILE_PHOTO];
    const userPhoto = (typeof photo === 'string' ? photo : photo.large);
    const location = this.props.user.location ? {label:this.props.user.location, value:''} : null;

    return (
      <div className="ProfileEditing__background">
        <EditingProfileBar
          changed={canSave}
          save={this.saveUser}
          cancel={toggleEditMode}
        />
        <div className="ProfileEditing__container">
          <div className="ProfileEditing__left">
            <ProfilePhotoUploader
              uploadText="Update"
            />
            <div className="ProfileEditing__desktop-only">
              <ProfilePersonalityCardZodiac
                className="ProfileEditing__personality-card"
                isEditing={true}
                create={instantiateZodiacCard}
                edit={updatePersonalityCardZodiac}
                data={zodiacCard.card_data ? zodiacCard : {}}
                viewDailyHoroscope={() => {}}
              />
              <ProfilePersonalityCardMyersBriggs
                className="ProfileEditing__personality-card"
                isEditing={true}
                create={instantiateMyersBriggsCard}
                edit={updatePersonalityCardMyersBriggs}
                data={myersBriggsCard.card_data ? myersBriggsCard : {}}
              />
            </div>
          </div>
          <div className="ProfileEditing__right">
            <ProfileSection
              label="My Info"
              about="This information helps other members discover and learn more about who you are and what you do."
              className="ProfileEditing__section"
            >
              <div className="ProfileEditing__columns">
                <ProfileField
                  label="First Name"
                  required
                  field={FIRST_NAME}
                  data={user}
                  placeholder="First Name"
                  update={this.updateUser}
                  error={errors[FIRST_NAME]}
                  errorMessage="Invalid name"
                />
                <ProfileField
                  label="Last Name"
                  required
                  field={LAST_NAME}
                  data={user}
                  placeholder="Last Name"
                  update={this.updateUser}
                  error={errors[LAST_NAME]}
                  errorMessage="Invalid name"
                />
              </div>
              <ProfileField
                label="Headline"
                field={HEADLINE}
                data={user}
                placeholder="CEO of my life"
                update={this.updateUser}
              />
              <div className="ProfileEditing__columns">
                <div className="ProfileField">
                  <h4 className="ProfileField__label">
                    Location
                  </h4>
                  <InputLocation
                    placeholder={"Location"}
                    defaultValue={location}
                    searchKey="profile"
                    onChange={this.updateLocation}
                  />
                </div>
                <ProfileField
                  label="Industry"
                  field={INDUSTRY}
                  data={user}
                  placeholder="Industry"
                  update={this.updateUser}
                  options={INDUSTRIES}
                />
              </div>
              <div className="ProfileEditing__columns">
                <ProfileField
                  label="Personal Website"
                  field={WEBSITE}
                  data={user}
                  placeholder="hello.com"
                  update={this.updateUser}
                />
                <ProfileField
                  label="Instagram"
                  field={INSTAGRAM}
                  data={user}
                  placeholder="instagram.com/"
                  update={this.updateUser}
                />
              </div>
            </ProfileSection>
            <ProfileSection
              label="About Me"
              about="Help others know what you can offer and how they can help."
              className="ProfileEditing__section"
            >
              <BioField
                label="I'm good at"
                field={IM_GOOD_AT}
                data={user}
                placeholder="making spreadsheets"
                update={this.updateUser}
              />
              <BioField
                label="I'd like to learn"
                field={ID_LIKE_TO_LEARN}
                data={user}
                placeholder="more about cryptocurrency"
                update={this.updateUser}
              />
              <BioField
                label="I'd like to meet"
                field={ID_LIKE_TO_MEET}
                data={user}
                placeholder="founders and entrepreneurs"
                update={this.updateUser}
              />
            </ProfileSection>
            <ProfileSection
              label="My Journey"
              about="Tell others where you've been and what you've done."
              className="ProfileEditing__section"
            >
              <div className="ProfileEditing__journey-container">
                {
                  journeyCards.sort((a,b) => {
                    if (a.start_date.year > b.start_date.year) return -1;
                    if (a.start_date.year < b.start_date.year) return 1;
                    if (MONTHS_INT[a.start_date.month.toUpperCase()] > MONTHS_INT[b.start_date.month.toUpperCase()]) return -1;
                    if (MONTHS_INT[a.start_date.month.toUpperCase()] < MONTHS_INT[b.start_date.month.toUpperCase()]) return 1;
                    return 0;
                  }).map(card => (
                    <JourneyCardEditing
                      key={card.id}
                      card={card}
                      saveJourneyCard={saveJourneyCard}
                      startEditingJourneyCard={this.startEditingJourneyCard}
                      doneEditingJourneyCard={this.doneEditingJourneyCard}
                      delete={deleteJourneyCard}
                    />
                  ))
                }
                {
                  creatingJourneyCard && (
                    <JourneyCardEditing
                      card={cardData}
                      saveJourneyCard={this.createJourneyCard}
                      startEditingJourneyCard={this.startEditingJourneyCard}
                      doneEditingJourneyCard={this.doneEditingJourneyCard}
                      creating={true}
                      editing={true}
                      delete={this.cancelJourneyCardCreation}
                    />
                  )
                }
                {
                  !creatingJourneyCard && (
                    <Button
                      theme={THEME_BLACK}
                      onClick={this.startCreatingJourneyCard}
                    >
                      Add Moment
                    </Button>
                  )
                }
              </div>
            </ProfileSection>
            <div className="ProfileEditing__mobile-only">
              <ProfilePersonalityCardZodiac
                className="ProfileEditing__personality-card"
                isEditing={true}
                create={instantiateZodiacCard}
                edit={updatePersonalityCardZodiac}
                data={zodiacCard.card_data ? zodiacCard : {}}
                viewDailyHoroscope={() => {}}
              />
              <ProfilePersonalityCardMyersBriggs
                className="ProfileEditing__personality-card"
                isEditing={true}
                create={instantiateMyersBriggsCard}
                edit={updatePersonalityCardMyersBriggs}
                data={myersBriggsCard.card_data ? myersBriggsCard : {}}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default ProfileEditing;