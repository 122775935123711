import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import './DailyHoroscopeModal.scss';

import { SIGNS } from '../../Profile/ProfilePersonalityCards/ProfilePersonalityCardZodiac';

import {
  GTMpushDataLayerEvent, 
  GTM_HOROSCOPE_VIEWED 
} from '../../../lib/GoogleTagManager';

class DailyHoroscopeModal extends Component {
  static propTypes = {
    sign:PropTypes.string,
    allHoroscopes:PropTypes.array
  };

  componentDidMount = () => {
    GTMpushDataLayerEvent({ event: GTM_HOROSCOPE_VIEWED });
    document.getElementById('horoscope-container').scrollLeft -= 280;
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  render() {
    const { sign, allHoroscopes, children } = this.props;
    
    return (
      <div className='Modal DailyHoroscopeModal' ref={this.setRef}>
      {children}

        <h1 className="DailyHoroscopeModal__heading">Daily Horoscope</h1>
        <div className='DailyHoroscopeModal__container' id="horoscope-container">

        {
          allHoroscopes
            .map((horoscope, i) => {
              let readings = 'No horoscope available today. Check back tomorrow!';
              if(horoscope.fields[sign]) {
                readings = horoscope.fields[sign];
              }
              let date = horoscope.fields['Date'];

              return (
                <div className='DailyHoroscopeModal__container--horoscope' key={i}>
                  <div className='DailyHoroscopeModal__container--horoscope--sign'>
                    <img src={SIGNS[sign].imageSVG} />
                    <span>{sign}</span>
                  </div>
                  
                  <h4>{ format(date, 'dddd') }</h4>
                  <h5>{ format(date, 'MMMM Do, YYYY') }</h5>
                  <p>{ readings }</p>
                </div>
              )
            })
        }
        </div>
      </div>
    )
  }
}

export default DailyHoroscopeModal;
