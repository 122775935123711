import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import InfiniteScroller from '../../components/InfiniteScroller';
import Activity from './Activity';

import './ActivityFeed.scss';

class ActivityFeed extends Component {
  static defaultProps = {
    hasLoadedAllActivities: true
  }

  static propTypes = {
    request: PropTypes.object,
    activities: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string
  }

  constructor (props) {
    super(props);
  }

  setListRef = ref => {
    if (this.props.listRef) {
      this.listRef = this.props.listRef;
    } else {
      this.listRef = ref;
    }

    this.forceUpdate();
  }

  render () {
    const {
      activities = [],
      user,
      className,
      title,
      close,
      hasLoadedAllActivity,
      request = {},
      loadMore,
      listRef
    } = this.props;

    if (!activities.length) return null;

    return (
      <div className={classNames("ActivityFeed", className)}>
        { title && (<h4 className="ActivityFeed__title">{title}</h4>) }
        <div className="ActivityFeed__infinite-scroll" ref={this.setListRef}>
          <InfiniteScroller
            shouldLoadMore={!request.pending && !hasLoadedAllActivity}
            hasLoadedAll={hasLoadedAllActivity}
            loadMore={loadMore}
            componentRef={listRef || this.listRef}
          >
            {
              activities.map(activity => (
                <Activity
                  user={user}
                  key={activity.id}
                  activity={activity}
                  close={close}
                />
              ))
            }
          </InfiniteScroller>
        </div>
      </div>
    )
  }
}

export default ActivityFeed;