import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { authLogOut } from '../../../store/Auth/actions';
import ChargebeeWrapper from '../../../containers/ChargebeeWrapper';

import Button, {
  THEME_BLUE,
  THEME_GRAY
} from '../../../common/Button';
import ModalDialog from '../ModalTemplates/ModalDialog';
import {
  GTMpushDataLayerEvent,
  GTM_MODAL_UPDATESUBSCRIPTION_UPDATE,
  GTM_MODAL_UPDATESUBSCRIPTION_SIGNOUT
} from '../../../lib/GoogleTagManager';

import RaiseImage from '../../../../public/assets/illustrations/raise.png';

class UpdateSubscriptionModal extends Component {
  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  handleUpdatePaymentInfo() {
    GTMpushDataLayerEvent({
      event: GTM_MODAL_UPDATESUBSCRIPTION_UPDATE
    });
  }

  handleSignOut = () => {
    this.props.authLogOut();

    GTMpushDataLayerEvent({
      event: GTM_MODAL_UPDATESUBSCRIPTION_SIGNOUT
    });
  }

  render() {
    return (
      <div className="Modal UpdateSubscriptionModal" ref={this.setRef}>
        {this.props.children}
        <div>
          <img
            className="UpdateSubscriptionModal__image"
            src={RaiseImage}
            alt="Girlboss community"
          />
        </div>
        <h2 className="UpdateSubscriptionModal__text--header">
          Update your subscription to access the community!
        </h2>
        <p className="UpdateSubscriptionModal__text--description">
          Girlboss community is a membership only platform.
          Access all of our features to connect with like-minded women by
          updating your payment method.
        </p>
        <div className="UpdateSubscriptionModal__container--buttons">
          <a
            href="javascript:void(0)"
            data-cb-type="portal"
            className="no-underline"
          >
            <Button
              theme={THEME_BLUE}
              onClick={this.handleUpdatePaymentInfo}
              pill
            >
              Update Payment Info
            </Button>
          </a>
          <Button
            theme={THEME_GRAY}
            onClick={this.handleSignOut}
            pill
          >
            Sign Out
          </Button>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authLogOut: (args) => dispatch(authLogOut(args))
  }
}

export default (ChargebeeWrapper)(
  connect(null, mapDispatchToProps)(UpdateSubscriptionModal)
);
