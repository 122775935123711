import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import InputMadLib from '../../common/Input/InputMadLib';
import { BIO } from '../../common/Input/CommonFields/profile';

class BioField extends Component {
  static propTypes = {
    data: PropTypes.object,
    label: PropTypes.string,
    field: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  };

  static defaultProps = {
    canViewEditStates: false
  };

  constructor (props) {
    super(props);
  }

  updateBio = e => {
    let { data, field, update } = this.props;
    data[BIO][field] = e;
    update(data);
  }

  render() {
    const { data, label, field, placeholder, required } = this.props;

    return (
      <div className="BioField">
        {
          (label) && (
            <h4 className="BioField__label">
              {label}
            </h4>
          )
        }
        <InputMadLib
          className="BioField__input"
          controlledValue={data[BIO][field]}
          hideMaxLengthText={true}
          maxLength={130}
          onChange={this.updateBio}
          placeholder={placeholder}
          textareaLineHeight={30}
        />
      </div>
    )
  }
};

export default BioField;