import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Toaster from '../../containers/Toaster';
import Loader from '../../common/Loader';

import { appGetIsMainLoading } from '../../store/App/actions';

import './Layout.scss';

class Layout extends Component {
  static propTypes = {
    Left: PropTypes.func,
    Main: PropTypes.func.isRequired,
    Nav: PropTypes.func,
    Right: PropTypes.func,
    modalOpen: PropTypes.shape({}),
    toasterOpen: PropTypes.shape({}),
    isFullWidth: PropTypes.bool,
    isFullScreen: PropTypes.bool
  };

  static defaultProps = {
    isFullWidth: false,
    isFullScreen: false
  };

  renderLeft = () => {
    if (this.props.isFullWidth || this.props.isFullScreen) return null;

    const Left = this.props.Left;

    if (Left) {
      return (
        <div className="App__panel App__left">
          <Left {...this.props} />
        </div>
      );
    }
    return <div className="App__panel App__left" />;
  };

  renderRight = () => {
    if (this.props.isFullWidth || this.props.isFullScreen) return null;

    const Right = this.props.Right;
    const isRight = Right !== null && Right;

    if (isRight) {
      return (
        <div className="App__panel App__right">
          <Right {...this.props} />
        </div>
      );
    }
    return <div className="App__panel App__right" />;
  };

  renderMain = () => {
    const { Main, Left, Right, directory, isFullWidth, isFullScreen, Nav, isLoading, isMainLoading } = this.props;

    if (isMainLoading || isLoading) {
      return <Loader className="bg__blue" />;
    }

    if (Main) {
      return (
        <div
          className={classNames(
            'App__main',
            {
              'App__main-gridThree': Left || Right,
              'App__main-directory': directory
            }
          )}
        >
          <Main {...this.props} />
        </div>
      );
    }

    return null;
  };

  renderFooter = () => {
    const { Footer } = this.props;

    if (Footer) {
      return (
        <div className="App__footer">
          <Footer />
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      Nav,
      modalOpen,
      toasterOpen
    } = this.props;

    return (
      <div id="doc">
        {Nav && (
            <Nav />
        )}
        <div className="App">
          {toasterOpen && <Toaster {...toasterOpen} {...this.props} />}
          <div className={classNames(
            "App__container",
            {
              "App__container-header-buffer":Nav
            }
          )}>
            {this.renderLeft()}
            {this.renderMain()}
            {this.renderRight()}
          </div>
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    toasterOpen: state.Toaster.toasterOpen,
    isMainLoading: appGetIsMainLoading(state),
    isLoggedIn: state.App.isLoggedIn,
  };
}

export default connect(mapStateToProps)(Layout);
