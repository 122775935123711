import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SVG from 'react-inlinesvg';

import { selectRequest } from '../../../store/requests';
import { inviteesGet, sendInvites } from '../../../store/Invites/actions';
import { modalClose } from '../../../store/Modal/actions'
import { isEmpty, debounce } from 'lodash';
import { compose, INVALID_EMAIL } from '../../../common/Input/CommonFields/validations';

import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../ModalContainer';
import UserList from '../../../components/UserList';
import Loader from '../../../common/Loader';
import Input, { INPUT_SECONDARY_PLAIN } from '../../../common/Input';
import Button, { THEME_BLACK, THEME_WHITE } from '../../../common/Button';
import memberSearchIcon from '../../../../public/assets/icons/MEMBER-SEARCH.svg';
import addIcon from '../../../../public/assets/icons/ADD.svg';
import checkIcon from '../../../../public/assets/icons/CHECK.svg';

import './CollectiveInvitationModal.scss';
import NoResults from '../../../components/NoResults';

class CollectiveInvitationModal extends Component {
  static defaultProps = {
    loadMore: () => {},
    hasLoadedAllUsers: true,
    offset: 0
  }

  static propTypes = {
    hasLoadedAllUsers: PropTypes.bool,
    offset: PropTypes.number,
    userList: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      invitees: [],
      isEmail: false,
      inputErrors: null,
      debouncing: false,
      emails: [],
      users: {}
    };

    props.inviteesGet({ collectiveId: props.collective_id, offset: 0 })
  }

  onInputChange = val => {
    let isEmail;
    if (val.includes('@')) {
      isEmail = true;
    } else {
      isEmail = false;
    }
    
    let isList = val.match(/[ ,]+/);

    if (isEmail && !isList) {
      let invalid = compose(val, [INVALID_EMAIL]);
      if (invalid.length) {
        let inputErrors = invalid;
        this.setState({
          isEmail,
          searchValue: val,
          inputErrors
        });

        return;
      } 
    } 

    this.setState({
      searchValue: val,
      isEmail,
      inputErrors: null,
      debouncing: true
    });

    if (this.state.searchValue !== val) this.collectiveCandidatesSearch(val);
  }

  collectiveCandidatesSearch = debounce(val => {
    this.props.inviteesGet({
      collectiveId: this.props.collective_id,
      name: val,
      offset: 0
    });

    this.setState({
      debouncing: false
    })
  }, 300);

  onSelectingUser = user => {
    if (this.state.inputErrors) return;

    let emails = [...this.state.emails];

    if (this.state.isEmail || !user) {
      let invalidEmails = [];
      let inputErrors = null;
      let list = this.state.searchValue.split(/[ ,]+/);
     
      if (list) {
        list.forEach(email => {
          email = email.trim();
          if (email === '') return;
          let invalid = compose(email, [INVALID_EMAIL]);
          if (invalid.length) {
            inputErrors = invalid;
            invalidEmails.push(email);
          } 
          if (!inputErrors) {
            if (emails.indexOf(email) === -1) {
              emails.push(email);
            } 
          }
        })

        this.setState({
          isEmail: inputErrors ? true : false,
          emails,
          inputErrors,
          searchValue: inputErrors ? invalidEmails.join(', ') : ''
        });
      } else {
        if (emails.indexOf(this.state.searchValue) === -1) {
          emails.push(this.state.searchValue);
        } 
  
        this.setState({
          isEmail: false,
          emails,
          searchValue: ''
        });
  
        this.onInputChange('');
      }
    } else {
      let users = {...this.state.users};
      users[user] = true;

      this.setState({
        users
      })
    }
  }

  onDeselectingUser = id => {
    let users = {...this.state.users};
    delete users[id];

    this.setState({
      users
    });
  }

  onDeselectingEmail = val => {
    let emails = [...this.state.emails].filter(email => email !== val);

    this.setState({
      emails
    });
  }

  renderEmailInvitees = () => {
    const { emails } = this.state; 
    if (isEmpty(emails)) return null;

    const renderList = emails.map(invitee => {
      
      return (
        <div 
          key={invitee} 
          className="CollectiveInvitationModal__selected-list--item" 
          onClick={() => this.onDeselectingEmail(invitee)}
        >
        <span>{invitee}</span>
        <div><SVG src={checkIcon} /></div>
      </div>
      )
    })
  
    return (
      <div className="CollectiveInvitationModal__selected-list">
        {renderList}
      </div>
    )
  }

  onSubmitInvitations = () => {
    const { collective_id, modalClose, sendInvites } = this.props;
    const { users, emails }  = this.state;

    let invitees = [];
    for (let user in users) {
      invitees.push({
        type: 'user',
        value: parseInt(user)
      });
    };
    emails.map(email => invitees.push({type: 'email', value: email}));

    sendInvites({
      type: 'collective',
      collective_id,
      invitees
    });

    modalClose();
  }

  setListRef = ref => {
    this.listRef = ref;
    this.forceUpdate();
  }

  setInputRef = ref => {
    this.inputRef = ref;
  }

  onClickAdd = user => {
    if (!this.state.users[user.id]) {
      return this.onSelectingUser(user.id);
    } else {
      return this.onDeselectingUser(user.id);
    }
  }

  render() {
    const {
      close,
      setRef,
      inviteesGet,
      inviteCandidates,
      collective_name,
      collective_id,
      getCandidatesRequest,
      sendInvitesRequest,
      searchCandidates,
      defaultCandidates
    } = this.props;

    const { searchValue, isEmail, debouncing } = this.state;

    let isList = searchValue.match(/[ ,]+/);

      return (
        <ModalContainer
          className="CollectiveInvitationModal"
          setRef={setRef}
          close={close}
        >
          {this.props.children}
          <ModalHeader 
            header={`Invite To ${collective_name}`}
            subHeader={'Reach out to your network or bring in new members.'}
          >
            <Input 
              className="CollectiveInvitationModal__search-input"
              theme={INPUT_SECONDARY_PLAIN} 
              onChange={this.onInputChange}
              onEnter={isEmail ? this.onSelectingUser : null}
              placeholder="Invite by member name or email address" 
              errors={this.state.inputErrors}
              value={this.state.searchValue}
              setRef={this.setInputRef}
            />
          </ModalHeader>

          <ModalBody setRef={this.setListRef}>
            <div className="CollectiveInvitationModal__container--content">
              {
                debouncing && searchValue !== '' && isEmpty(inviteCandidates) && !isEmail &&
                (
                  <Loader />
                )
              }
              {
                isEmail && (
                  <div className="CollectiveInvitationModal__email-container">
                    <h3>{isList ? 'Add emails to your invite list' : 'Add this email to your invite list'}</h3>

                    <div className="CollectiveInvitationModal__email-container--item">
                    <p>{searchValue}</p> 

                    <Button className="email-add" theme={THEME_WHITE} pill noOutline onClick={this.onSelectingUser}>
                      <SVG src={addIcon} />
                    </Button>
                    </div>
                  </div>
                )
              }
              {
                searchValue === '' && !isEmail && (
                  <React.Fragment>
                    {this.renderEmailInvitees()}
                    <UserList
                      users={defaultCandidates.users} 
                      offset={defaultCandidates.offset}
                      hasLoadedAllUsers={defaultCandidates.hasLoadedAllUsers}
                      loadMore={() => inviteesGet({
                        collectiveId: collective_id, 
                        offset: defaultCandidates.offset
                      })}
                      request={getCandidatesRequest}
                      onClickAdd={this.onClickAdd}
                      onUserClick={this.onClickAdd}
                      listRef={this.listRef}
                      addedUsers={this.state.users}
                    />
                  </React.Fragment>
                )
              }
              {
                searchValue !== '' && !isEmpty(inviteCandidates) && !isEmail && (
                  <UserList
                    users={searchCandidates.users} 
                    offset={searchCandidates.offset}
                    hasLoadedAllUsers={searchCandidates.hasLoadedAllUsers}
                    loadMore={() => inviteesGet({
                      collectiveId: collective_id, 
                      name: searchValue,
                      offset: searchCandidates.offset
                    })}
                    request={getCandidatesRequest}
                    onClickAdd={this.onClickAdd}
                    onUserClick={this.onClickAdd}
                    listRef={this.listRef}
                    addedUsers={this.state.users}
                  />
                )
              }
              {
                searchValue !== '' && !debouncing && !getCandidatesRequest.pending && !isEmail && isEmpty(inviteCandidates) && (
                  <NoResults
                    heading="No Results Found"
                    subheading="Psst! You can invite your friends and colleagues by email above!"
                  />
                )
              }
            </div>
          </ModalBody>
          <ModalFooter>
            <Button 
              className="CollectiveInvitationModal__cta" 
              theme={THEME_BLACK}
              disabled={isEmpty(this.state.users) && isEmpty(this.state.emails)}
              onClick={this.onSubmitInvitations}
              isLoading={sendInvitesRequest.pending}
              noOutline
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContainer>
      )
    }
}

const mapStateToProps = state => {
  return {
    users: state.Directory.users,
    inviteCandidates: state.Invites.searchCandidates.users,
    hasLoadedAllCandidates: state.Invites.hasLoadedAllInvitees,
    offset: state.Invites.offset,
    defaultCandidates: state.Invites.defaultCandidates,
    searchCandidates: state.Invites.searchCandidates,
    getCandidatesRequest: selectRequest(state, inviteesGet),
    sendInvitesRequest: selectRequest(state, sendInvites)
  }
}

export default connect(mapStateToProps, {
  inviteesGet,
  sendInvites,
  modalClose
})(CollectiveInvitationModal);

