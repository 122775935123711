import React, { Component }  from "react";
import { connect } from 'react-redux';

import { modalOpen } from '../../../../store/Modal/actions';
import { clearCollectiveData } from '../../../../store/Collectives/actions';
import { ModalBody, ModalFooter } from '../../ModalContainer';
import { COLLECTIVE_INVITATION_MODAL } from '../../ModalTypes';
import Button, { THEME_BLACK } from '../../../../common/Button';
import productCollageImage from '../../../../../public/assets/Product-Collage-4.png';

class CollectiveInvitationIntro extends Component {
  openInvitationModal = () => {
    this.props.modalOpen({
      modal: COLLECTIVE_INVITATION_MODAL,
      collective_id: this.props.collectiveCreation.id,
      collective_name: this.props.collectiveCreation.name,
      implicitExit: false
    });

    this.props.clearCollectiveData();
  } 

  render() {
    return (
      <div className="CollectiveInvitationIntro">
        <ModalBody>
          <img src={productCollageImage} />
  
          <h1 className="CollectiveCreationModal__heading">Let's get the party started</h1>
  
          <p className="CollectiveCreationModal__text">
            Add at least 3 people to join your collective. Search members or invite new ones by typing in their email address.
          </p>
  
        </ModalBody>
  
        <ModalFooter>
          <Button theme={THEME_BLACK} className="CollectiveCreationModal__cta" noOutline onClick={this.openInvitationModal}>Get Started</Button>
        </ModalFooter>
      </div>
    );
  }
};

const mapStateToProps = ({ Collectives }) => {
  return {
    collectiveCreation: Collectives.collectiveCreation
  }
}

export default connect(mapStateToProps, { modalOpen, clearCollectiveData })(CollectiveInvitationIntro);
