import React, { Component } from 'react'
import PropTypes from 'prop-types';
import SendIcon, { SEND_THEME_BLUE, SEND_THEME_BLUE_DISABLED } from '../../../components/SVG/SendIcon';

import commentInputFields from '../../../common/Input/CommonFields/comment';
import { INPUT_PRIMARY_PLAIN } from '../../../common/Input'
import classnames from 'classnames';

import InputWithUserTagging from '../../../common/Input/InputWithUserTagging';

import './ReplyInputBox.scss';

export class ReplyInputBox extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    setRef: PropTypes.func,
    value: PropTypes.string,
  };

  setRef= ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  };

  render() {
    const {
      creator,
      focusInput,
      handleChange,
      isDesktop,
      onSubmit,
      placeholder,
      unfocusInput,
      value,
    } = this.props;

    return (
      <form className="ReplyInputBox" onSubmit={this.submitComment}>
        {creator ?
          <p className="ReplyInputBox__heading">Replying to <span>{creator.first_name} {creator.last_name}</span></p>
          : null}
        <InputWithUserTagging
          value={value}
          onChange={handleChange}
          focus={isDesktop ? "true" : "false"}
          theme={INPUT_PRIMARY_PLAIN}
          setRef={this.setRef}
          onBlur={unfocusInput}
          onFocus={focusInput}
          className={classnames(
            "ReplyInputBox__comment"
          )}
          component="textarea"
          {...commentInputFields[value]}
          controlledValue={value}
          placeholder={placeholder}
          maxRows={6}
          maxLength={2500}
        />
        <button
          onSubmit={onSubmit}
          onClick={onSubmit}
          className="ReplyInputBox__button"
        >
          <SendIcon theme={value.trim() !== '' ? SEND_THEME_BLUE : SEND_THEME_BLUE_DISABLED} />
        </button>
      </form>
    )
  }
}

export default ReplyInputBox;