import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './ContactRequestModal.scss';

class ContactRequestModalIntent extends Component {
  static propTypes = {
    user:PropTypes.object,
    handleIntentSelection:PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  selectImAFan = () => {
    this.props.handleIntentSelection('im_a_fan');
  }

  selectLetsCollaborate = () => {
    this.props.handleIntentSelection('lets_collaborate');
  }

  selectLetsMeetUp = () => {
    this.props.handleIntentSelection('lets_meet_up');
  }

  selectICanHelpYou = () => {
    this.props.handleIntentSelection('i_can_help_you');
  }

  selectIdLoveYourHelp = () => {
    this.props.handleIntentSelection('id_love_your_help');
  }

  render() {
    const { user, intent } = this.props;

    return (
      <div className="ContactRequestModal__inner">
        <h4 className='ContactRequestModal__heading'>Let { user.first_name } know why you're reaching out.</h4>
        <button 
          className={
            classnames({
              'ContactRequestModal__intent-button': true,
              active: intent === 'im_a_fan'
            })
          } 
          onClick={this.selectImAFan}
        >
          I'm a fan
        </button>
        <button 
          className={
            classnames({
              'ContactRequestModal__intent-button': true,
              active: intent === 'lets_collaborate'
            })
          }
          onClick={this.selectLetsCollaborate}
        >
          Let's collaborate
        </button>
        <button 
          className={
            classnames({
              'ContactRequestModal__intent-button': true,
              active: intent === 'lets_meet_up'
            })
          }
          onClick={this.selectLetsMeetUp}
        >
          Let's meet up
        </button>
        <button 
          className={
            classnames({
              'ContactRequestModal__intent-button': true,
              active: intent === 'i_can_help_you'
            })
          }
          onClick={this.selectICanHelpYou}
        >
          I can help you
        </button>
        <button 
          className={
            classnames({
              'ContactRequestModal__intent-button': true,
              active: intent === 'id_love_your_help'
            })
          }
          onClick={this.selectIdLoveYourHelp}
        >
          I'd love your help
        </button>
      </div>
    )
  }
}

export default ContactRequestModalIntent;
