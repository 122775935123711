import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'moment-timezone/builds/moment-timezone-with-data';

class CountdownTimer extends Component {
  static propTypes = {
    creationTime:PropTypes.object,
    endTimestamp:PropTypes.number,
    complete:PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      hours: null,
      minutes: null,
      seconds: null
    }
  }

  componentDidMount = () => {
    const { creationTimestamp, endTimestamp } = this.props;
    if (creationTimestamp) {
      this.getTimestampByCreation();
      setInterval(this.getTimestampByCreation, 1000);
    } else if (endTimestamp) {
      this.getTimestampByEnd();
      setInterval(this.getTimestampByEnd, 1000);
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.getTimestamp);
    clearInterval(this.getTimestampByEnd);
    clearInterval(this.getTimestampByCreation);
  }

  getTimestampByCreation = () => {
    const { creationTimestamp } = this.props;

    let expirationTime = moment.tz(creationTimestamp, 'America/Los_Angeles').startOf('day').add(2, 'day').valueOf();
    let now = Date.now();
    
    let diffMilliseconds = expirationTime - now;

    this.formatTimestamp(diffMilliseconds);
  
    if (diffMilliseconds < 0) {
      clearInterval(this.getTimestampByCreation);
    }
  };

  getTimestampByEnd = () => {
    const { endTimestamp } = this.props;
    let diff = endTimestamp - Date.now();
    this.formatTimestamp(diff);
  
    if (diff < 0) {
      clearInterval(this.getTimestampByEnd);
    }
  };

  formatTimestamp = (diffMilliseconds) => {

    if (this.props.complete) {
      if (diffMilliseconds - window.diff < 0) {
        this.props.complete();
      }
    }

    let hoursRounded, minutesRounded, secondsRounded, hours, minutes, seconds;

    hours = diffMilliseconds / 1000 / 60 / 60;
    hoursRounded = Math.max(Math.floor(hours), 0);
    minutes = (hours % 1) * 60;
    minutesRounded = Math.max(Math.floor(minutes), 0);
    seconds = (minutes % 1) * 60; 
    secondsRounded = Math.max(Math.floor(seconds), 0);

    this.setState({
      hours:hoursRounded,
      minutes:minutesRounded,
      seconds:secondsRounded
    });
  }

  renderTimestamp = () => {
    let { hours, minutes, seconds } = this.state;

    if(minutes !== null && seconds !== null) {
      minutes = ('00' + minutes).slice(-2);
      seconds = ('00' + seconds).slice(-2);
    }

    return hours + ":" + minutes + ":" + seconds;
  }


  render() {
    return (
      <span className="timestamp">{ this.renderTimestamp() }</span>
    )
  }
}

export default CountdownTimer;
