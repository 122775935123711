import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { connect } from 'react-redux';

import locationFormatter from '../../utils/locationFormatter';

import { locationGet, locationClear, locationSelectedGet } from '../../store/Location/actions';
import { selectRequest } from '../../store/requests';

import Dropdown from '../Dropdown';

class InputLocation extends Component {
  static propTypes = {
    required: PropTypes.bool,
    searchKey: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = { lastQuery: '', lastSearchedQuery: '' };
    this.debouncedSearch = debounce(this.search, 300);
  }

  componentDidUpdate(prevProps) {
    const { searchKey } = this.props;
    const prevSelection = searchKey ? prevProps.selectionKeys[searchKey] : prevProps.selection;
    const selection = searchKey ? this.props.selectionKeys[searchKey] : this.props.selection;
    if (prevSelection != selection) {
      this.props.onChange(selection);
    }
  }

  onSelect = val => {
    if (val) {
      this.props.locationSelectedGet({placeId:val.value, session:this.props.sessionToken}, this.props.searchKey);
    } else {
      this.props.onChange(val);
    }
  }

  onChange = val => {
    if (val !== this.state.lastQuery) {
      this.setState({ lastQuery: val, lastSearchedQuery: '' }, () => {
        this.debouncedSearch(val);
      });
    }
  }

  search = (query) => {
    this.props.locationGet({ query }, this.props.searchKey);
    this.setState({ lastSearchedQuery: query });
  }

  formatResults = (locations) => {
    return locations.map(location => ({ label: location.description, value: location.place_id }));
  }

  render() {
    const {
      results,
      keys,
      searchKey,
      styles,
      placeholder,
      defaultValue,
      selectRequest,
      searchRequest
    } = this.props;

    let locations = searchKey ? this.formatResults(keys[searchKey] || []) : this.formatResults(results);

    const formattedDefaultValue = defaultValue ? {label:locationFormatter(defaultValue.label), value:defaultValue.value} : null;
    const requestValue = selectRequest.pending ? {label:this.state.lastQuery, value:''} : formattedDefaultValue;

    return (
      <Dropdown
        useSelect
        value={requestValue}
        className={this.props.className}
        onChange={this.onChange}
        placeholder={placeholder}
        options={locations}
        showOptions={locations.length || (this.state.lastSearchedQuery && searchRequest.resolved)}
        onSelect={this.onSelect}
        styles={styles}
        noIndicator
        noOptionsMessage="No locations found"
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    results: state.Location.locations,
    keys: state.Location.keys,
    sessionToken: state.Location.session,
    selection: state.Location.selection,
    selectionKeys: state.Location.selectionKeys,
    selectRequest: selectRequest(state, locationSelectedGet, props.searchKey),
    searchRequest: selectRequest(state, locationGet, props.searchKey)
  };
};

export default connect(mapStateToProps, { locationGet, locationClear, locationSelectedGet })(InputLocation);