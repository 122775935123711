import { takeEvery, put, select } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';
import { userGetId } from '../User/actions';
import { INVITEES_GET, SEND_INVITES, INVITE_ACCEPT, INVITE_GET, INVITES_GET } from './actions';
import { toasterOpen, TOASTER_TYPE_SUCCESS } from '../Toaster/actions';

function* inviteesSagaGet(action) {
  const currentUserId = yield select(userGetId);
  const { userId = currentUserId, collectiveId = '', name = '', offset, limit = 12 } = action.payload;

  yield sagaRequest(action, yield callAPI('getUsers', {
    connectedUserId: userId,
    collectiveId,
    name,
    offset,
    limit
  }));
}

function* sendInvitesSaga(action) {
  yield sagaRequest(action, callAPI('sendInvites', { body: action.payload }), {
    *fulfilled() {
      yield put(toasterOpen({
        type: TOASTER_TYPE_SUCCESS,
        content: action.payload.invitees.length > 1 ? 'Invites Sent!' : 'Invite Sent',
        timeout: 4000
      }));
    }
  });
}

function* getInviteSaga(action) {
  yield sagaRequest(action, callAPI('getInvite', { token: action.payload }));
}

function* getInvitesSaga(action) {
  const { limit = 1, offset = 0, collective_id, status = 'sent' } = action.payload;
  yield sagaRequest(action, callAPI('getInvites', { limit, offset, collective_id, status }));
}

function* acceptInviteSaga(action) {
  yield sagaRequest(action, callAPI('acceptInvite', { token: action.payload, body: { status: 'accepted' } }));
}

export const invitesSagas = [
  takeEvery(INVITEES_GET, inviteesSagaGet),
  takeEvery(SEND_INVITES, sendInvitesSaga),
  takeEvery(INVITE_ACCEPT, acceptInviteSaga),
  takeEvery(INVITE_GET, getInviteSaga),
  takeEvery(INVITES_GET, getInvitesSaga)
];
