import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputMadLib, { INPUT_MADLIB_WHITE } from '../../common/Input/InputMadLib';

import './Onboarding.scss';

class OnboardingMeet extends PureComponent {
  static propTypes = {
    id_like_to_meet: PropTypes.string,
    handleMeetChange: PropTypes.func.isRequired
  };
   
  render() {

    const {
      id_like_to_meet,
      handleMeetChange
    } = this.props;

    return (
      <div className="Onboarding__page-container-good-at">
      <div className="Onboarding__heading-container-bio">
          <h2 className="Onboarding__heading">Who would you like to meet?</h2>
        </div>
        <div className="Onboarding__i-am-container">
          <span className="Onboarding__i-am" style={{marginRight:'12px'}}>I'd like to meet</span>
          <span className="Onboarding__i-am-input-container">
            <InputMadLib 
              placeholder="founders and entrepreneurs"
              className="Onboarding__i-am"
              onChange={handleMeetChange}
              value={id_like_to_meet}
              maxLength={130}
              theme={INPUT_MADLIB_WHITE}
            />
            <span className="Onboarding__i-am" style={{display: 'flex', flexDirection: 'column-reverse', marginBottom: '17px'}}>.</span>
          </span>
        </div>
      </div>
    )
  }
}

export default OnboardingMeet;
