import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import InfiniteScroller from '../InfiniteScroller';
import PostLoadingScreen from '../PostLoadingScreen';
import Post from '../../containers/Post';
import sendIcon from '../../../public/assets/icons/SEND.svg';

import {
  reactPost,
  reactPostRemove
} from '../../store/Reactions/actions';

class PostList extends Component {
  static defaultProps = {
    contentTags: [],
    renderMobileExtraContent: () => {},
    renderDesktopExtraContent: () => {},
    hideCollectiveLine: false
  }

  static propTypes = {
    ajaxUpdate: PropTypes.func,
    contentTags: PropTypes.array,
    feed: PropTypes.array,
    hasLoadedAllPosts: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    updatePostList: PropTypes.func,
    renderMobileExtraContent: PropTypes.func,
    renderDesktopExtraContent: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.ajaxRefresh = 0;
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mousewheel', this.handleScroll);

    this.ajaxRefresh = setInterval(this.ajaxRefreshCall, 60 * 1000);
  }

  componentWillUnmount = () => {
    clearInterval(this.ajaxRefresh);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousewheel', this.handleScroll);
  }

  ajaxRefreshCall = () => {
    this.props.ajaxUpdate();
  }

  handleScroll = () => {
    const scrollTop = window.pageYOffset || document.body.scrollTop;

    if(scrollTop === 0 && this.props.newPostEntities) {
      this.props.updatePostList();
    };
  }

  onUpdatePostList = () => {
    this.props.updatePostList();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  render() {
    const { 
      contentTags,
      feed,
      hasLoadedAllPosts,
      isLoading, 
      loadMore, 
      newPostEntities,
      renderExtraContent,
      hideCollectiveLine,
      adminIds
    } = this.props;

    return (
      <React.Fragment>
        {
          newPostEntities ? 
          <button className="Feed__refresh-btn" onClick={this.onUpdatePostList}>
            <img src={sendIcon} />
            New Posts
          </button> : 
          null
        }

        <InfiniteScroller
          shouldLoadMore={!isLoading && !hasLoadedAllPosts}
          hasLoadedAll={hasLoadedAllPosts}
          loadMore={loadMore}
        >
          {
            feed.length ? feed.map((post, index) => {
              return (
                <React.Fragment key={index + '-' + post.flagged}>
                  <Post
                    post={post}
                    isReacted={post.is_reacted}
                    onReact={this.props.reactPost}
                    onReactRemove={this.props.reactPostRemove}
                    contentTags={contentTags}
                    topics={post.topics}
                    readOnly={false}
                    showTimeStamp={post.priority === 0}
                    variant={'feed'}
                    hideCollectiveLine={hideCollectiveLine}
                    showAdminBadge={adminIds ? adminIds.indexOf(post.creator.id) > -1 : null}
                  />
                  {renderExtraContent(index)}
                </React.Fragment>
              )
            }) : 
              (
              <React.Fragment>
                {renderExtraContent()}
              </React.Fragment>
              )
          }


          {isLoading ? <React.Fragment><PostLoadingScreen isLoading /><PostLoadingScreen isLoading /><PostLoadingScreen isLoading /><PostLoadingScreen isLoading /></React.Fragment> : null}
        </InfiniteScroller>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.App.isLoggedIn,
    contentTags: state.Feed.contentTags,
    user: state.User.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reactPost: (args) => dispatch(reactPost(args)),
    reactPostRemove: (args) => dispatch(reactPostRemove(args))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(PostList);