import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { requestsMiddleware } from './requests';
import rootReducer from './rootReducer';

let composeMiddleware;

if (process.env.NODE_ENV !== 'development') {
  composeMiddleware = compose;
} else {
  composeMiddleware = require('redux-devtools-extension').composeWithDevTools;
}

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    initialState,
    composeMiddleware(
      applyMiddleware(
        requestsMiddleware,
        sagaMiddleware
      )
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default
      store.replaceReducer(nextRootReducer)
    });
  }

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
