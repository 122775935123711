import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { selectRequest } from '../../store/requests';

import {
  PROFILE_PHOTO
} from '../../common/Input/CommonFields/profile';
import {
  userUpdatePhoto,
  userUpdateOptimistic
} from '../../store/User/actions';

import ImageUploader from '../ImageUploader';

class ProfilePhotoUploader extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    photo: PropTypes.string
  };

  static defaultProps = {
    disabled: false
  };

  userUpdatePhoto = data => {
    this.props.userUpdatePhoto({
      [PROFILE_PHOTO]: data
    });
  }

  render() {
    const { photo, disabled, request, uploadText, className } = this.props;

    return (
      <ImageUploader 
        className={className}
        disabled={disabled}
        imageWidth={600}
        imageHeight={600}
        imageName="avatar"
        request={request}
        image={photo}
        onSelect={this.userUpdatePhoto}
        uploadText={uploadText}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    photo: state.User.user.photo.large,
    request: selectRequest(state, userUpdatePhoto)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userUpdatePhoto: (args) => dispatch(userUpdatePhoto(args)),
    userUpdateOptimistic: (args) => dispatch(userUpdateOptimistic(args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePhotoUploader);