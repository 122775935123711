import React, { Component } from 'react';
import classNames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notifsRead } from '../../store/Notifications/actions';

import ProfilePhoto, {
  SIZE_MEDIUM
} from '../ProfilePhoto';
import {
  getActivityIcon,
  activityTypes,
  getNotificationContentType,
  getNotificationContentLink,
  getNotificationSnippet
} from '../../store/Legion/logic';
import NotificationText from '../NotificationText';
import Moment from '../Moment';

import './Notification.scss';
import { comment } from '../../store/Comment/actions';

class Notification extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    isRead: PropTypes.bool,
    containerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    notificationClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    sender: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    }),
    receiver: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired
    }),
    post_content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.string
    }),
    comment_content: PropTypes.shape({
      body: PropTypes.string.isRequired
    }),
    notificationType: PropTypes.number,
    activityType: PropTypes.number
  };

  clickNotification = (e) => {
    const { onClick, notifsRead, isRead, id } = this.props;

    if (!isRead) {
      notifsRead([id]);
    }

    if (onClick) {
      onClick(e);
    }
  }

  render() {
    const {
      containerClassName,
      sender,
      receiver,
      notificationType,
      activityType,
      postContent,
      commentContent,
      date,
      isRead,
      children,
      onClick,
      link
    } = this.props;

    return (
      <div className={classNames({
          'Notification__container': true,
          Notification: true,
          'maxWidth': true,
          unread: isRead === false 
        })}
        onClick={this.clickNotification}
      >
        {
          (link) && (
            <Link to={link}>
              <div
                style={{
                  position:'absolute',
                  top:'0',
                  bottom:'0',
                  left:'0',
                  right:'0'
                }}
              ></div>
            </Link>
          )
        }
        <div
          className={classNames(
            "Notification__grid1-2", 
            this.props.iconClassName
          )}
          style={{
            backgroundImage:`url(${sender.photo.medium})`
        }} 
        >
        </div>
        <div className="Notification__container--notif Notification__grid2-2">
          { children }
          <p className="Notification__text--time">
            <Moment short date={date} />
          </p>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notifsRead: (args) => dispatch(notifsRead(args))
  }
};

export default connect(null, mapDispatchToProps)(Notification);