import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Input, { THEME_GRAY } from '../../common/Input';
import Radio from '../../common/Radio';
import Checkbox, { THEME_BLUE } from '../../common/Checkbox';

class Preference extends PureComponent {

  static propTypes = {
    label:PropTypes.string,
    description:PropTypes.string,
    isChecked:PropTypes.bool,
    handleChange:PropTypes.func,
    handleOptionChange:PropTypes.func
  }

  constructor(props) {
    super(props);
  }

  handleChange = () => {
    this.props.handleChange(this.props.id);
  }

  handleOptionChange = (selection) => {
    this.props.handleOptionChange(this.props.id, selection);
  }

  render() {

    const { label, description, isChecked, options, selection, disabled } = this.props;

    return (
      <div className="Preference__container">
        <Checkbox theme={THEME_BLUE} onChange={this.handleChange} isChecked={isChecked} disabled={disabled} />
        <div className="Preference__description">
          <h4>{ label }</h4>
          <p>{ description }</p>
          {
            options && (
              <div>
                <Radio
                  name="options"
                  options={options}
                  selection={selection}
                  onChange={this.handleOptionChange}
                  disabled={!isChecked || disabled}
                />
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default Preference;
