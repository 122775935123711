import React, { Component } from 'react';

import CollectivesListItem from '../../../Collectives/CollectivesList/CollectivesListItem';

class WelcomeModalCollectivesList extends Component {

  renderCollectivesItem = () => {
    const { 
      collectives, 
      user,
      collectiveMemberAdd,
      collectiveMemberRemove,
      modalClose
    } = this.props;
    const collectivesList = []

    for(let collective in collectives) {
      collectivesList.push(
        <CollectivesListItem 
          key={collectives[collective].id} 
          collective={collectives[collective]} 
          user={user} 
          listType="recommended"
          onSubscribe={collectiveMemberAdd}
          onUnsubscribe={collectiveMemberRemove}
          modalClose={modalClose}
          showAvatar
          toggleSubscriptionOnClick={true}
          showPostNumber={false}
        />
        );
    }

    return collectivesList;
  }
  
  render() {
    return (
      <React.Fragment>
        <h2 className="WelcomeModal__header">Subscribe to get started.</h2>
        <div className="WelcomeModalCollectivesList">

          { this.renderCollectivesItem() }

        </div>
        
        <div className="WelcomeModalCollectivesList__gradient" />
      </React.Fragment>
    )
  }
}


export default WelcomeModalCollectivesList;