import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProfileField from './ProfileField';
import { DEGREES, MONTHS, PAST_PRESENT_YEARS } from '../../common/Dropdown/CommonFields';
import Checkbox from '../../common/Checkbox';

class JourneyCardEditingGirlbossMoment extends Component {
  static propTypes = {
    card: PropTypes.object
  };

  constructor (props) {
    super(props);
  }

  render() {
    const {
      card,
      errors,
      children,
      enableEditing,
      editing,
      updateCard,
      updateCardData,
      updateStartDate,
      updateEndDate,
      updateCurrent
    } = this.props;

    if (!card || !card.card_data) return null;

    return (
      <React.Fragment>
        <div onClick={enableEditing} className="JourneyCardEditing__default JourneyCardEditing__education">
          <h4>Education</h4>
          {card.card_data.school}, {card.card_data.degree}
        </div>
        {
          (editing) && (
            <div className="JourneyCardEditing__editing">
              {children}
              <ProfileField
                label="School"
                field={'school'}
                data={card.card_data}
                placeholder="Harvard"
                update={updateCardData}
                error={errors.school}
                errorMessage={'School cannot be blank'}
                required
              />
              <ProfileField
                label="Degree"
                field={'degree'}
                data={card.card_data}
                placeholder="B.S."
                update={updateCardData}
                options={DEGREES}
              />
              <div className="ProfileEditing__columns">
                <ProfileField
                  label="Start Month"
                  field={'month'}
                  data={card.start_date}
                  placeholder="January"
                  update={updateStartDate}
                  options={MONTHS}
                  error={errors.date}
                />
                <ProfileField
                  label="Start Year"
                  field={'year'}
                  data={card.start_date}
                  placeholder="2019"
                  update={updateStartDate}
                  options={PAST_PRESENT_YEARS}
                  error={errors.date}
                />
              </div>
              {
                (!card.current) && (
                  <div className="ProfileEditing__columns">
                    <ProfileField
                      label="End Month"
                      field={'month'}
                      data={card.end_date || {}}
                      placeholder="January"
                      update={updateEndDate}
                      options={MONTHS}
                      error={errors.date}
                    />
                    <ProfileField
                      label="End Year"
                      field={'year'}
                      data={card.end_date || {}}
                      placeholder="2019"
                      update={updateEndDate}
                      options={PAST_PRESENT_YEARS}
                      error={errors.date}
                    />
                  </div>
                )
              }
              {
                (errors.date) && (
                  <p className="ProfileField__error-message">Start date must be earlier than the end date</p>
                )
              }
              <Checkbox
                label="current"
                onChange={updateCurrent}
                isChecked={card.current}
                className="ProfileField__checkbox"
              >
                Currently Attending
              </Checkbox>
              <ProfileField
                label="Location"
                field={'location'}
                data={card}
                placeholder="Los Angeles"
                update={updateCard}
              />
              <ProfileField
                label="Description"
                field={'description'}
                data={card}
                placeholder="Here's what I did that was awesome..."
                update={updateCard}
              />
            </div>
          )
        }
      </React.Fragment>
    )
  }
};

export default JourneyCardEditingGirlbossMoment;