import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GridItem extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  };

  render() {
    const { text, photo, title } = this.props;
    return (
      <div className="LandingPage__grid__item">
        <img src={photo} alt={title} className="LandingPage__item__img" />
        <p className="LandingPage__headline--bold">{title}</p>
        <p className="LandingPage__item__copy">{text}</p>
      </div>
    )
  }
}

export default GridItem;
