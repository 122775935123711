import { takeEvery, select, put } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';

import { SEARCH_CHECK_CLEAR, SEARCH_GET, SEARCH_QUERY, searchGet, searchCheckClear, searchClear } from './actions';
import { resetRequestType } from '../../lib/reduxRequests';
import { selectRequest } from '../requests';

function* searchSagaCheckClear(action) {
  const { requests } = yield select();
  const request = requests[SEARCH_GET];
  if (!request) {
    yield put(searchClear());
  }
  yield put(searchGet(action.payload, action.key));
}

function* searchSagaGet(action) {
  const { query = '', index = '', offset = 0, limit = 10 } = action.payload;

  yield sagaRequest(action, callAPI('search', {
    query,
    index: index == 'everything' ? '' : index,
    offset,
    limit
  }));
};

function* searchSagaQuery(action) {
  yield put(resetRequestType(SEARCH_GET));
  yield put(searchCheckClear(action.payload, action.key));
}

export const searchSagas = [
  takeEvery(SEARCH_CHECK_CLEAR, searchSagaCheckClear),
  takeEvery(SEARCH_QUERY, searchSagaQuery),
  takeEvery(SEARCH_GET, searchSagaGet)
];
