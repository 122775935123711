import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactFitText from 'react-fittext';

import { userPersonalityCardAdd } from '../../../store/User/actions';
import { modalClose } from '../../../store/Modal/actions';

import Button, { THEME_NAVY } from '../../../common/Button';
import { SIGNS } from '../../Profile/ProfilePersonalityCards/ProfilePersonalityCardZodiac';


import './FeedHoroscopesModal.scss';

class FeedHoroscopesModal extends Component {
    static propTypes = {
      horoscopes:PropTypes.array
    };

    setRef = ref => {
      const { setRef } = this.props;
      if (setRef) setRef(ref);
    }

    addHoroscope = horoscope => {
        let data = {
            card_data: {
                sign: horoscope
            },
            card_type: 'zodiac',
            display_on_profile: true,
            user_id: this.props.user.id
        };

        this.props.userPersonalityCardAdd(data, 'feed');
        this.props.modalClose();
    };

    renderHoroscopes = () => {
        const horoscopeCards = [];
        
        for(let sign in SIGNS) {
          horoscopeCards.push(
              <div className="FeedHoroscopesModal__container--horoscope" key={sign}>
                  <img src={SIGNS[sign].imageSVG} />
                  <h2>{sign}</h2>
                  <p className="time">{SIGNS[sign].time}</p>

                  <Button onClick={() => this.addHoroscope(sign)} theme={THEME_NAVY} noOutline>Add to feed</Button>
              </div>
          );
        };

        return horoscopeCards;
    }
  
    render() {
      return (
        <div className='Modal FeedHoroscopesModal' ref={this.setRef}>
            <h1 className="FeedHoroscopesModal__heading">Daily Horoscope</h1>
            <p className="FeedHoroscopesModal__text">Select your zodiac sign and see your daily reading.</p>
            {this.props.children}

            <div className='FeedHoroscopesModal__container'>
                {this.renderHoroscopes()}
            </div>
        </div>
      )
    }
  }
  
  export default connect(null, { userPersonalityCardAdd, modalClose })(FeedHoroscopesModal);
  