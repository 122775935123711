
// Profile entities need to have lowercase keys to ensure both lowercase and uppercase URLs are compatible.
export const getProfileEntities = state => {
  let key, keys = Object.keys(state.ProfileEntities);
  let lowerCaseProfileEntities = {};

  let i = keys.length;
  while (i--) {
    key = keys[i];
    lowerCaseProfileEntities[key.toLowerCase()] = state.ProfileEntities[key];
  }

  return lowerCaseProfileEntities;
}

export const getProfileEntitiesWithUser = state => {
  const profileEntitiesState = getProfileEntities(state);

  const profiles = {};

  const userInfo = state.User.user;
  const userUsername = state.User.user.username;

  Object.keys(profileEntitiesState).forEach(profileKey => {
    const profile = profileEntitiesState[profileKey];

    if (profile.username === userUsername) {
      profiles[profile.username] = userInfo;
    } else {
      profiles[profile.username] = profile;
    }
  });

  return profiles;
};
