import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './store/configureStore';
import rootSaga from './sagas/rootSaga';

import App from './containers/App';

import { GTMinit } from './lib/GoogleTagManager';

import './styles/style.scss';

export const store = configureStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

// initialize google tag manager
GTMinit();

// check for conditionals getConfirmation
// is onboarding for signup
requestAnimationFrame(() => {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
});
