export function isProfileLink(pathname) {
  const afterSlash = pathname.split('/');

  if (afterSlash.length === 2) return true;
  return false;
};

export function formatProfileUsername(pathname) {
  return pathname.slice(1).toLowerCase();
};
