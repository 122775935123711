import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import loadScript from '../../utils/loadScript';
import scripts from '../../config/scripts';

import Loader from '../../common/Loader';
import {
  authSignUpSave,
  authSignUp
} from '../../store/Auth/actions';
import {
  GTMpushDataLayerEvent,
  GTM_CHARGEBEE_CHECKOUT_CLOSE,
  GTM_CHARGEBEE_CHECKOUT_SUCCESS,
  GTM_CHARGEBEE_CHECKOUT_STEP,
  GTM_CHARGEBEE_PORTAL_CLOSE,
  GTM_CHARGEBEE_PORTAL_STEP
} from '../../lib/GoogleTagManager';

const DefaultLoadingContainer = props => <Loader />;

export const ChargebeeWrapper = WrappedComponent => {
  class chargebeewrapper extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isChargebeeLoaded: false
      };
    };

    componentWillMount() {
      if (window.Chargebee) {
        this.setState({ isChargebeeLoaded: true });
      } else {
        this.loadChargebeeScript(); 
      }
    }

    componentDidMount() {
      if (window.Chargebee) {
        window.Chargebee.registerAgain();
      }
    }

    loadChargebeeScript = () => {
      loadScript(scripts.chargebee)
      .then(script => {
        this.chargebeeScript = script;
        this.initChargebee();
      })
      .catch(error => {
        console.error(error);
      });
    }

    initChargebee = () => {
      const chargebeeSite = (process.env.NODE_ENV === 'production' ? 'girlboss' : 'girlboss-test');
      window.Chargebee.init({
        site: chargebeeSite,
        enableGATracking: true
      });

      this.initChargebeeCheckoutCallbacks();
      this.initChargebeePortalCallbacks();

      this.setState({ isChargebeeLoaded: true });

      window.Chargebee.registerAgain();
    }

    initChargebeePortalCallbacks() {
      window.Chargebee.getInstance().setPortalCallbacks({
        close: function() {
          // reload will trigger a /user call
          window.location.reload();

          GTMpushDataLayerEvent({
            event: GTM_CHARGEBEE_PORTAL_CLOSE
          });
        },
        visit: function(stepValue) {
          GTMpushDataLayerEvent({
            event: GTM_CHARGEBEE_PORTAL_STEP,
            step: stepValue
          });
        }
      });
    }

    initChargebeeCheckoutCallbacks = () => {
      window.Chargebee.getInstance().setCheckoutCallbacks((cart) => {
        return {
          close: () => {
            GTMpushDataLayerEvent({
              event: GTM_CHARGEBEE_CHECKOUT_CLOSE
            });
          },
          success: (hostedPageId) => {
            const tempSavedFields = Object.assign({}, this.props.savedFields, {
              hosted_page_id: hostedPageId
            });

            this.props.authSignUpSave({
              hosted_page_id: hostedPageId
            });

            this.props.authSignUp(tempSavedFields, this.props.history);

            GTMpushDataLayerEvent({
              event: GTM_CHARGEBEE_CHECKOUT_SUCCESS
            });
          },
          step: (stepValue) => {
            GTMpushDataLayerEvent({
              event: GTM_CHARGEBEE_CHECKOUT_STEP,
              step: stepValue
            });
          }
        }
      });
    }

    render() {
      const props = Object.assign({}, this.props, {
        loaded: this.state.isChargebeeLoaded,
        chargebee: window.Chargebee
      });

      return <WrappedComponent {...props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      savedFields: state.Auth.fields
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      authSignUp: (...args) => dispatch(authSignUp(...args)),
      authSignUpSave: (args) => dispatch(authSignUpSave(args))
    }
  }

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(chargebeewrapper));
};

export default ChargebeeWrapper;
