export class CollectivePolicy {
  constructor(user, collective, invited) {
    this.user = user;
    this.collective = collective;
    this.invited = invited;
  }

  isAdmin() {
    return !!this.collective.members.find(member => (member.role === 'admin' || member.role === 'owner') && (member.user_id === this.user.id));
  }

  isMember() {
    return !!this.collective.members.find(member => member.user_id === this.user.id);
  }

  subscribe() {
    if (!this.collective.published_at) return;
    // TODO: define pro user
    // const isProUser = true;
  
    switch (this.collective.access_control) {
      case 'public':
        return true;
      case 'system':
        return false;
      // case 'pro_users':
      //   return isProUser;
      case 'exclusive':
      case 'private': 
        return this.invited;
      default:
        return true;
    }
  }

  invite() {
    if (!this.collective.published_at) return;

    const isMember = this.isMember();
    const isAdmin = this.isAdmin();
  
    switch (this.collective.access_control) {
      case 'public':
        return true;
      case 'system':
        return false;
      // case 'pro_users':
      //   return isMember;
      case 'exclusive':
      case 'private':
        return isAdmin;
      default:
        return true;
    }
  }

  limitFeed() {
    if (!this.collective.published_at) return;

    const isMember = this.isMember();
    
    switch (this.collective.access_control) {
      case 'pro_users':
      case 'exclusive':
      case 'private':
        return !isMember;
      default:
        return false;
    }  
  }
}
  
export const selectCollectivePolicy = (...args) => {
  return new CollectivePolicy(...args)
};
