const ERROR_MESSAGES = {
  common: {
    default: "Uh oh! Something went wrong.",
    40101: "Invalid Credentials",
    40102: "Your account is under review. Contact help@girlboss.com if you need further assistance.",
    40300: "This request is not permitted.",
    40400: "We couldn't find what you're looking for.",
    40901: "This username is already taken",
    40902: "This email is already taken",
    50000: "Request could not be completed at this time"
  },
  phoneCheck: {
    40900: "Phone number is already taken"
  }
}

export function apiErrorMessage(error, scope = 'common') {
  const code = error && typeof error === 'object' ? error.code || `${error.status}00` : error;
  const message = ERROR_MESSAGES[scope] && ERROR_MESSAGES[scope][code];
  return message || ERROR_MESSAGES.common[code] || ERROR_MESSAGES.common.default;
}

// For new Joi api errors
export function validationErrorsToObject(errors) {
  const errorsObj = {};
  for (let error of errors) {
    const errors = errorsObj[error.path] || (errorsObj[error.path] = []);
    errors.push(error);
  }
  return errorsObj;
}
