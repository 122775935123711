/**
  App intiailized
**/
export const APP_INITIALIZE = 'APP_INITIALIZE';
export const APP_INITIALIZE_SUCCESS = 'APP_INITIALIZE_SUCCESS';

export const appInitialize = payload => {
  return {
    type: APP_INITIALIZE,
    payload
  }
};

export const appInitializeSuccess = () => {
  return {
    type: APP_INITIALIZE_SUCCESS
  }
};

/**
  App token validate
**/
export const APP_VALIDATE_TOKEN_SUCCESS = 'APP_VALIDATE_TOKEN_SUCCESS';
export const APP_VALIDATE_TOKEN_FAILURE = 'APP_VALIDATE_TOKEN_FAILURE';

export const appValidateTokenSuccess = () => {
  return {
    type: APP_VALIDATE_TOKEN_SUCCESS
  }
};

export const appValidateTokenFailure = () => {
  return {
    type: APP_VALIDATE_TOKEN_FAILURE
  }
};

/**
  API token retrieval
**/
export const API_TOKEN_HELPER = 'API_TOKEN_HELPER';

export const apiTokenHelper = () => {
  return {
    type: API_TOKEN_HELPER
  }
};

/**
  Waitlist
**/
export const SUBMIT_EMAIL = 'SUBMIT_EMAIL';
export const SUBMIT_EMAIL_SUCCESS = 'SUBMIT_EMAIL_SUCCESS';
export const SUBMIT_EMAIL_ERROR = 'SUBMIT_EMAIL_ERROR';

export const submitEmailToWaitlist = payload => {
  return {
    type: SUBMIT_EMAIL,
    payload
  }
};

export const submitEmailToWaitlistSuccess = payload => {
  return {
    type: SUBMIT_EMAIL_SUCCESS,
    payload
  }
}

export const submitEmailToWaitlistError = payload => {
  return {
    type: SUBMIT_EMAIL_ERROR,
    payload
  }
}

export const SUBMIT_EMAIL_WAITLIST_REFERRAL = 'SUBMIT_EMAIL_WAITLIST_REFERRAL';

export const submitEmailToWaitlistReferral = payload => {
  return {
    type: SUBMIT_EMAIL_WAITLIST_REFERRAL,
    payload
  }
};

/**
  Campaign Monitor stats
**/
export const CM_STATS = 'CM_STATS';
export const CM_STATS_SUCCESS = 'CM_STATS_SUCCESS';

export const cmStats = () => {
  return {
    type: CM_STATS
  }
};

export const cmStatsSuccess = payload => {
  return {
    type: CM_STATS_SUCCESS,
    payload
  }
};

/**
  OverlayScrollbar body object
**/

export const CREATE_OS_REFERENCE = 'CREATE_OS_REFERENCE';

export const createOsReference = payload => {
  return {
    type: CREATE_OS_REFERENCE,
    payload
  }
}

/**
  Route Changes
**/

export const ON_ROUTE_CHANGE = 'ON_ROUTE_CHANGE';

export const onRouteChange = payload => {
  return {
    type: ON_ROUTE_CHANGE,
    payload
  }
}

/**
  Selectors
**/
export const appGetIsUserSubscribed = state => state.App.isUserSubscribed
export const appGetIsUserLoggedIn = state => state.App.isLoggedIn;
export const appGetIsInitializing = state => state.App.isAppInitializing;
export const appGetIsFullPageLoading = state => state.App.isFullPageLoading;
export const appGetIsMainLoading = state => state.App.isMainLoading;
export const appGetIsScriptLoaded = state => state.App.isScriptLoaded;
