import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Button, {
  THEME_GRAY
} from '../../common/Button';
import routes from '../../config/routes';


import './AccountNotFound.scss';

class NotFound extends PureComponent {
  render() {
    return (
      <div className="AccountNotFound">
        <h1 className="AccountNotFound__header">Account not found.</h1>
        <h3 className="AccountNotFound__text">
            Sorry but this account can't be found at this time.
        </h3>
        <Button
          component={Link}
          to={routes.root}
          className="AccountNotFound__button"
          theme={THEME_GRAY}
          pill
          noOutline
        >
          <span className="AccountNotFound__button--arrow"></span> Back
        </Button>
      </div>
    )
  }
}

export default NotFound;
