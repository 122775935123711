import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ProfilePersonalityCard.scss';

import ProfilePersonalityCardActive from './ProfilePersonalityCardActive';
import ProfilePersonalityCardEditing from './ProfilePersonalityCardEditing';
import ProfilePersonalityCardNew from './ProfilePersonalityCardNew';

const PERSONALITY_TYPES = {
  INTJ: {
    name:'INTJ',
    descriptor:'Strategist',
    class: 'yellow' // for styling
  },
  INTP: {
    name:'INTP',
    descriptor:'Lifelong Learner',
    class: 'yellow'
  },
  ENTJ: {
    name:'ENTJ',
    descriptor:'Fearless Leader',
    class: 'yellow'
  },
  ENTP: {
    name:'ENTP',
    descriptor:'Mental Gymnast',
    class: 'pink'
  },

  INFJ: {
    name:'INFJ',
    descriptor:'Activist',
    class: 'pink'
  },
  INFP: {
    name:'INFP',
    descriptor:'Flower Child',
    class: 'pink'
  },
  ENFJ: {
    name:'ENFJ',
    descriptor:'Leading Lady',
    class: 'pink'
  },
  ENFP: {
    name:'ENFP',
    descriptor:'Social Butterfly',
    class: 'pink'
  },

  ISTJ: {
    name:'ISTJ',
    descriptor:'Loyalist',
    class: 'orange'
  },
  ISFJ: {
    name:'ISFJ',
    descriptor:'Fierce Protector',
    class: 'orange'
  },
  ESTJ: {
    name:'ESTJ',
    descriptor:'CEO',
    class: 'orange'
  },
  ESFJ: {
    name:'ESFJ',
    descriptor:'Knight in Shining Armor',
    class: 'orange'
  },

  ISTP: {
    name:'ISTP',
    descriptor:'Renaissance Woman',
    class: 'red'
    
  },
  ISFP: {
    name:'ISFP',
    descriptor:'Daredevil',
    class: 'red'
  },
  ESTP: {
    name:'ESTP',
    descriptor:'Founder',
    class: 'red'
  },
  ESFP: {
    name:'ESFP',
    descriptor:'Life of the Party',
    class: 'red'
  }
};

class ProfilePersonalityCardMyersBriggs extends Component {
  static propTypes = {
    data:PropTypes.object,
    edit:PropTypes.func,
    isEditing:PropTypes.bool,
    className:PropTypes.string
  }

  static defaultProps = {
    data:{
      card_type:'myers_briggs',
      display_on_profile:false,
      card_data:{
        type:'INTJ'
      },
      toBeCreated:true
    }
  }

  constructor(props) {
    super(props);
  }

  editSign = (e) => {
    let newData = this.props.data;
    newData.card_data.type = e.target.value;
    this.props.edit(newData);
  }

  editVisibility = (e) => {
    let newData = this.props.data;
    newData.display_on_profile = e.target.checked;
    this.props.edit(newData);
  }

  render() {

    const { isEditing, edit, data, className, create, promptCreation } = this.props;

    if (!data.card_data || promptCreation) {
      return (
        <ProfilePersonalityCardNew
          className={classNames('ProfilePersonalityCard', className, 'ProfilePersonalityCard__add')}
          label="Add Personality Type"
          description="Know your personality type? Add it to your profile!"
          create={create}
        />
      )
    }

    return (
      <div>
        {/* Public */}
        {
          (data && data.display_on_profile && !isEditing) && (
            <ProfilePersonalityCardActive
            className={className}
            isEditing={isEditing}
            editCard={edit}
            className={classNames('ProfilePersonalityCardMyersBriggs', className)}
          >
            <div className={`ProfilePersonalityCardMyersBriggs__main ProfilePersonalityCardMyersBriggs__main--${PERSONALITY_TYPES[data.card_data.type].class}`}>{ PERSONALITY_TYPES[data.card_data.type].name }</div>
            <div className="ProfilePersonalityCardMyersBriggs__sub-main">{ PERSONALITY_TYPES[data.card_data.type].descriptor }</div>
          </ProfilePersonalityCardActive>
          )
        }
        {/* Editing */}
        {
          (isEditing) && (
            <ProfilePersonalityCardEditing
              isEditing={isEditing}
              editCard={edit}
              className={classNames('ProfilePersonalityCardMyersBriggsEditing', className)}
            >
            <div className={`ProfilePersonalityCardMyersBriggsEditing__main ProfilePersonalityCardMyersBriggs__main--${PERSONALITY_TYPES[data.card_data.type].class}`}>{ PERSONALITY_TYPES[data.card_data.type].name }</div>
            <div className="ProfilePersonalityCardMyersBriggsEditing__sub-main">{ PERSONALITY_TYPES[data.card_data.type].descriptor }</div>
            <select className="ProfilePersonalityCardMyersBriggsEditing__type-select" value={data.card_data.type} onChange={this.editSign}>
              {
                Object.keys(PERSONALITY_TYPES).map((type) => {
                  return <option key={type} value={type}>{PERSONALITY_TYPES[type].name}</option>
                })
              }
            </select>
            <div className="ProfilePersonalityCardMyersBriggsEditing__hide-show">Show Card</div>
            <label className="switch">
              <input type="checkbox" defaultChecked={data.display_on_profile} onChange={this.editVisibility} />
              <span className="slider round"></span>
            </label>
            </ProfilePersonalityCardEditing>
          )
        }
      </div>
    )
  }
}

export default ProfilePersonalityCardMyersBriggs;