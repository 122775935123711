import { takeEvery, put, call, select } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';
import { LANDING_PAGE_URL } from '../../config';
import {
  USER_DELETE,
  USER_UPDATE,
  userUpdate,
  userUpdateSuccess,
  userUpdateError,
  USER_GET,
  userGetSuccess,
  userGetError,
  userGetComplete,
  userGetId,
  USER_UPDATE_PHOTO,
  userUpdatePhotoSuccess,
  userUpdatePhotoError,
  USER_JOURNEY_CARD_ADD,
  userJourneyCardAddSuccess,
  userJourneyCardAddError,
  USER_JOURNEY_CARD_UPDATE,
  userJourneyCardUpdateSuccess,
  userJourneyCardUpdateError,
  USER_JOURNEY_CARD_DELETE,
  userJourneyCardDeleteSuccess,
  userJourneyCardDeleteError,
  USER_PERSONALITY_CARD_ADD,
  userPersonalityCardAddSuccess,
  userPersonalityCardAddError,
  USER_PERSONALITY_CARD_UPDATE,
  userPersonalityCardUpdateSuccess,
  userPersonalityCardUpdateError,
  USER_PERSONALITY_CARD_DELETE,
  userPersonalityCardDeleteSuccess,
  userPersonalityCardDeleteError,
  USER_UPDATE_PASSWORD,
  userUpdatePasswordSuccess,
  userUpdatePasswordError,
  USER_UPDATE_PING,
  USER_UPDATE_WITH_PASSWORD,
  userUpdateWithPassword,
  userUpdateWithPasswordSuccess,
  userUpdateWithPasswordError
} from './actions';
import {
  authSubscriptionNotFound,
  authSubscriptionFound
} from '../Auth/actions';
import {
  onboardingSaveProfileSuccess,
  onboardingSaveProfileError
} from '../Onboarding/actions';
import {
  toasterOpen,
  TOASTER_TYPE_SUCCESS,
  TOASTER_TYPE_ERROR
} from '../Toaster/actions';
import { removeToken } from '../../lib/localStorage';
import {
  GTMpushDataLayerEvent,
  GTM_DELETE_ACCOUNT
} from '../../lib/GoogleTagManager';
import { setToken } from '../../lib/localStorage';

function* userSagaGet(action) {
  const response = yield call(fetchAPI, 'getUser');

  if (response.success) {
    const { user } = response;
    yield put(userGetSuccess(user));
  } else {
    yield put(userGetError(response));
  }

  if (response.is_user_subscribed === true) {
    yield put(authSubscriptionFound());
  } else {
    yield put(authSubscriptionNotFound());
  }

  yield put(userGetComplete());
}

function* userSagaDelete(action) {
  yield sagaRequest(action, callAPI('deleteUser', { body: action.payload }), {
    *fulfilled() {
      removeToken();
      GTMpushDataLayerEvent({ event: GTM_DELETE_ACCOUNT });
      location.href = LANDING_PAGE_URL;
    }
  });
}

function* userSagaUpdateWithPassword(action) {
  const response = yield call(fetchAPI, 'updatePassword', {
    body: action.payload.password
  });

  if (response.success) {
    setToken(response.token);
    yield put(userUpdatePasswordSuccess(response));
    yield put(userUpdate(action.payload.user.payload, action.payload.user.location));
  } else {
    yield put(userUpdateWithPasswordError(response.message.message));
    yield put(toasterOpen({
      type: TOASTER_TYPE_ERROR,
      content: response.message.message ? response.message.message : ''
    }));
  }
}

function* userSagaUpdate(action) {
  let payload = Object.assign({}, action.payload);
  if (payload && payload.photo) {
    delete payload.photo;
  }
  const response = yield call(fetchAPI, 'updateUser', {
    body: payload
  });
  if (response.success) {
    yield put(onboardingSaveProfileSuccess(response.user));
    yield put(userUpdateSuccess(response.user));

    // only send toaster notifications if updating info from settings
    if(action.location === 'settings') {
      yield put(toasterOpen({
        type: TOASTER_TYPE_SUCCESS,
        content: response.message.message ? response.message.message : '',
        timeout: 5000
      }));
    }
  } else {
    yield put(onboardingSaveProfileError(response.errors.message));
    yield put(userUpdateError(response.errors.message));

    if(action.location === 'settings') {
      yield put(toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: response.errors.message ? response.errors.message : '',
        timeout: 5000
      }));
    }
  }
}

function* userSagaUpdatePhoto(action) {
  yield sagaRequest(action, callAPI('updateUserPhoto', { body: action.payload.photo }, true), {
    *fulfilled(response) {
      yield put(userUpdateSuccess(response.user));
    },
    *rejected(response) {
      yield put(userUpdateError(response.message));
    }

  });
}

// JOURNEY CARDS

function* userSagaJourneyCardAdd(action) {
  let payload = Object.assign({}, action.payload);
  if (payload && payload.id) {
    delete payload.id;
  }
  const response = yield call(fetchAPI, 'addJourneyCard', {
    body: payload
  });

  if (response.success) {
    yield put(userJourneyCardAddSuccess({
      journey_cards: response.journeyCard
    }));
  } else {
    yield put(userJourneyCardAddError(response));
  }
}

function* userSagaJourneyCardUpdate(action) {
  // If the card type has been changed, delete the old card and recreate it with the new type.
  if (action.payload.prev_card_type && action.payload.prev_card_type != action.payload.card_type) {
    const deleteResponse = yield call(fetchAPI, 'deleteJourneyCard', {
      id: action.payload.id
    });

    if (deleteResponse.success) {
      const response = yield call(fetchAPI, 'addJourneyCard', {
        body: action.payload
      });
    
      if (response.success) {
        yield put(userJourneyCardUpdateSuccess({
          journey_cards: response.journeyCard
        }));
      } else {
        yield put(userJourneyCardUpdateError(response));
      }
    }
  } else {
    const response = yield call(fetchAPI, 'editJourneyCard', {
      body: action.payload,
      id: action.payload.id
    });

    if (response.success) {
      yield put(userJourneyCardUpdateSuccess({
        journey_cards: response.journeyCard
      }));
    } else {
      yield put(userJourneyCardUpdateError(response));
    }
  }
}

function* userSagaJourneyCardDelete(action) {
  const response = yield call(fetchAPI, 'deleteJourneyCard', {
    id: action.id
  });

  if (response.success) {
    yield put(userJourneyCardDeleteSuccess({
      journey_cards: response.journeyCard
    }));
  } else {
    yield put(userJourneyCardDeleteError(response));
  }
}

// PERSONALITY CARDS

function* userSagaPersonalityCardAdd(action) {
  const response = yield call(fetchAPI, 'addPersonalityCard', {
    body: action.payload
  });

  if (response.success) {
    yield put(userPersonalityCardAddSuccess({
      createdCard: response.createdCard
    }));

    if(action.location === 'feed') {
      yield put(toasterOpen({
        type: TOASTER_TYPE_SUCCESS,
        content: 'Horoscope added!'
      }));
    }
  } else {
    yield put(userPersonalityCardAddError(response));

    if(action.location === 'feed') {
      yield put(toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: response.message.message ? response.message.message : 'Uh oh! Something went wrong.'
      }));
    }
  }
}

function* userSagaPersonalityCardUpdate(action) {
  const response = yield call(fetchAPI, 'editPersonalityCard', {
    body: action.payload,
    id: action.payload.id
  });

  if (response.success) {
    yield put(userPersonalityCardUpdateSuccess({
      updatedCard: response.updatedCard
    }));
  } else {
    yield put(userPersonalityCardUpdateError(response));
  }
}

function* userSagaPersonalityCardDelete(action) {
  const response = yield call(fetchAPI, 'deletePersonalityCard', {
    id: action.payload.id
  });

  if (response.success) {
    yield put(userPersonalityCardDeleteSuccess({
      education: response.education
    }));
  } else {
    yield put(userPersonalityCardDeleteError(response));
  }
}

function* userSagaUpdatePassword(action) {
  const response = yield call(fetchAPI, 'updatePassword', {
    body: action.payload
  });

  if (response.success) {
    setToken(response.token);
    yield put(userUpdatePasswordSuccess(response));
    yield put(toasterOpen({
      type: TOASTER_TYPE_SUCCESS,
      content: response.message.message ? response.message.message : ''
    }));

  } else {
    yield put(userUpdatePasswordError(response.message.message));
    yield put(toasterOpen({
      type: TOASTER_TYPE_ERROR,
      content: response.message.message ? response.message.message : ''
    }));
  }
}

function* userSagaUpdatePing(action) {
  const response = yield call(fetchAPI, 'updateUserPing');

  if (response.success) {
    setToken(response.auth_token);
  }
}

export const userSagas = [
  takeEvery(USER_GET, userSagaGet),
  takeEvery(USER_DELETE, userSagaDelete),
  takeEvery(USER_UPDATE, userSagaUpdate),
  takeEvery(USER_UPDATE_PHOTO, userSagaUpdatePhoto),
  takeEvery(USER_JOURNEY_CARD_ADD, userSagaJourneyCardAdd),
  takeEvery(USER_JOURNEY_CARD_UPDATE, userSagaJourneyCardUpdate),
  takeEvery(USER_JOURNEY_CARD_DELETE, userSagaJourneyCardDelete),
  takeEvery(USER_PERSONALITY_CARD_ADD, userSagaPersonalityCardAdd),
  takeEvery(USER_PERSONALITY_CARD_UPDATE, userSagaPersonalityCardUpdate),
  takeEvery(USER_PERSONALITY_CARD_DELETE, userSagaPersonalityCardDelete),
  takeEvery(USER_UPDATE_WITH_PASSWORD, userSagaUpdateWithPassword),
  takeEvery(USER_UPDATE_PASSWORD, userSagaUpdatePassword),
  takeEvery(USER_UPDATE_PING, userSagaUpdatePing)
];
