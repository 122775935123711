import { all, fork, takeEvery } from 'redux-saga/effects';

import { activitySagas } from '../store/Activity/logic';
import { appSagas } from '../store/App/logic';
import { authSagas } from '../store/Auth/logic';
import { collectiveSagas } from '../store/Collectives/logic';
import { commentSagas } from '../store/Comment/logic';
import { connectionSagas } from '../store/Connections/logic';
import { directorySagas } from '../store/Directory/logic';
import { feedSagas } from '../store/Feed/logic';
import { invitesSagas } from '../store/Invites/logic';
import { locationSagas } from '../store/Location/logic';
import { notifsSagas } from '../store/Notifications/logic';
import { onboardingSagas } from '../store/Onboarding/logic';
import { postSagas } from '../store/Post/logic';
import { reactionSagas } from '../store/Reactions/logic';
import { profileSagas } from '../store/Profile/logic';
import { preferencesSagas } from '../store/Preferences/logic';
import { reportSagas } from '../store/Report/logic';
import { searchSagas } from '../store/Search/logic';
import { userSagas } from '../store/User/logic';
import { magicSagas } from '../store/Magic/logic';
import { passwordResetSagas } from '../store/PasswordReset/logic';
import { contactSagas } from '../store/Contact/logic';
import { admireSagas } from '../store/Admires/logic';

export default function* rootSaga() {
  yield all([
    ...activitySagas,
    ...appSagas,
    ...authSagas,
    ...collectiveSagas,
    ...commentSagas,
    ...connectionSagas,
    ...directorySagas,
    ...feedSagas,
    ...invitesSagas,
    ...locationSagas,
    ...notifsSagas,
    ...onboardingSagas,
    ...postSagas,
    ...preferencesSagas,
    ...profileSagas,
    ...reactionSagas,
    ...reportSagas,
    ...searchSagas,
    ...userSagas,
    ...magicSagas,
    ...passwordResetSagas,
    ...contactSagas,
    ...admireSagas
  ]);
};
