import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Preference from './Preference';

import './PreferencesExternal.scss';

class PreferencesExternal extends PureComponent {

  static propTypes = {
    email:PropTypes.string,
    user:PropTypes.object,
    token:PropTypes.string,
    selection:PropTypes.string,
    preferences:PropTypes.object,
    isLoading:PropTypes.bool,
    handlePreferenceChange:PropTypes.func,
    handlePreferenceOptionChange:PropTypes.func,
    handlePreferenceFrequencyChange:PropTypes.func
  }

  generatePreferenceList = () => {
    const {
      preferences,
      selection,
      handlePreferenceChange,
      handlePreferenceOptionChange
    } = this.props;
    let preferenceList = [];
    Object.keys(preferences).forEach(i => {
      preferenceList.push(
        <Preference
          key={i}
          id={i}
          disabled={selection != 'pick'}
          isChecked={preferences[i].checked}
          selection={preferences[i].option}
          handleChange={handlePreferenceChange}
          handleOptionChange={handlePreferenceOptionChange}
          label={preferences[i].label}
          description={preferences[i].description}
          options={preferences[i].options}
        />
      )
    });
    return preferenceList;
  }

  render() {

    const {
      isLoading,
      email,
      user,
      token,
      preferences,
      selection,
      handlePreferenceChange,
      handlePreferenceOptionChange,
      handlePreferenceFrequencyChange,
      submitPreferences
    } = this.props;
    
    if (!token && !(user && user.email_token)) {
      return (
        <h4 className="PreferencesExternal__email">Log in to edit preferences.</h4>
      )
    }

    if (!email) {
      return (
        <h4 className="PreferencesExternal__email">Loading preferences...</h4>
      )
    }

    return (
      <React.Fragment>
        {
          (!isLoading) && (
            <div className="PreferencesExternal__preferences">
              <h3 className="PreferencesExternal__notifications-heading">Email Lists</h3>
              <div className="PreferencesExternal__frequency-container">
                <h5 className="PreferencesExternal__notifications-subheading">Tell us what you really want</h5>
                <select value={selection} onChange={handlePreferenceFrequencyChange} className="PreferencesExternal__frequency-selector">
                  <option value="all">Get 'em all</option>
                  <option value="few">Get a few</option>
                  <option value="pick">Let me pick</option>
                </select>
              </div>
              { this.generatePreferenceList(selection, preferences) }
            </div>
          )
        }
      </React.Fragment>
    )
  }
}

export default PreferencesExternal;

