import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WizardActions } from '../../../../components/Wizard';

import { selectRequest } from '../../../../store/requests';
import { publishCollective } from '../../../../store/Collectives/actions';

import { ModalHeader, ModalBody, ModalFooter } from '../../ModalContainer';
import MembersPreview from '../../../../components/Collectives/MembersPreview';
import PostNumber from '../../../../components/Collectives/PostNumber';
import { THEME_BLUE } from '../../../../common/Button';

class CollectiveCreationPreview extends Component {
  state = {
    collective: null
  }

  componentDidMount = () => {
    const slug = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

    this.setState({
      collective: this.props.collectives[slug]
    })
  }

  onEdit = () => {
    this.props.wizard.navigate('create');
  }

  onNext = () => {
    this.props.publishCollective({next: this.props.wizard.next})
  }

  componentDidUpdate(prevProps) {
    const { wizard, request } = this.props;
    if (request !== prevProps.request && request.fulfilled) {
      wizard.next();
    }
  }

  render() {
    const { collective } = this.state;
    const { collectiveCreation, publishRequest } = this.props;
    
    return (
      <div className="CollectiveCreationModal__container">
        <ModalHeader 
          header="Preview your Collective" 
        >
          <h2 className="warning">Once you publish you can't change the name of your Collective.</h2>
        </ModalHeader>

        {
          collective && (
            <ModalBody>
            <div className="Collective__summary-container">
              <div className="Collective__summary-image" style={{backgroundImage:`url(${collectiveCreation.image})`}}></div>
              <div className="Collective__summary">
                <h1 className="Collective__summary-heading">{ collectiveCreation.name }</h1>
                <p className="Collective__summary-description">{ collectiveCreation.description }</p>
                <div className="Collective__statistics">
                  <MembersPreview
                    members={collective.members}
                    memberCount={collective.member_count}
                    showAvatar={false}
                  />
                  <PostNumber count={collective.post_count} />
                </div>
              </div>
            </div>
          </ModalBody>
          )
        } 
        
        <ModalFooter>
          <WizardActions 
            wizard={this.props.wizard} 
            backLabel="Edit"
            nextLabel="Looks good!"
            onBack={this.onEdit}
            onNext={this.onNext}  
            nextPending={publishRequest.pending}
            nextTheme={THEME_BLUE}
          />
        </ModalFooter>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    collectives: state.Collectives.collectives,
    collectiveCreation: state.Collectives.collectiveCreation,
    publishRequest: selectRequest(state, publishCollective)
  }
}

export default connect(mapStateToProps, {
  publishCollective
})(CollectiveCreationPreview);