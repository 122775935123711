import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const FLAG_THEME_RED = '#e03700';
export const FLAG_THEME_WHITE = '#B1B1B1';

class FlagIcon extends PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf([FLAG_THEME_RED, FLAG_THEME_WHITE]),
    width: PropTypes.number
  };

  static defaultProps = {
    theme: FLAG_THEME_WHITE
  };

  render() {
    return (
        <svg width={this.props.width} height={this.props.width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.25 4.75V27.25Z" fill={this.props.theme} />
            <path d="M6.25 4.75V27.25" stroke={this.props.theme} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.25 6.25H25C25.4142 6.25 25.75 6.58579 25.75 7V19C25.75 19.4142 25.4142 19.75 25 19.75H6.25" fill={this.props.theme} />
            <path d="M6.25 6.25H25C25.4142 6.25 25.75 6.58579 25.75 7V19C25.75 19.4142 25.4142 19.75 25 19.75H6.25" stroke={this.props.theme} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
  }
};

export default FlagIcon;
