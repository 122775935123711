import APIConfig, {
  APIRoot,
  replaceAPIUrlWithParams,
  getFullUrlFromEnv
} from '../config/API';
import {
  getToken,
  setToken,
  removeToken
} from '../lib/localStorage';
import { store } from '../index';
import {
  APP_VALIDATE_TOKEN_FAILURE,
  APP_VALIDATE_TOKEN_SUCCESS
} from '../store/App/actions';
import {
  AUTH_SUBSCRIPTION_NOT_FOUND
} from '../store/Auth/actions';
import {
  modalOpen
} from '../store/Modal/actions';
import { SIGNUP_PROMPT_MODAL } from '../containers/ModalManager/ModalTypes';

function fetchAPI(moduleName, params = {}, blob = false) {
  const API = APIConfig[moduleName];
  let APIUrl = API.url;
  let APIOptions = API.options || {};

  if (API.params) {
    APIUrl = replaceAPIUrlWithParams(APIUrl, { ...API.params, ...params });
  };

  const fullUrl = getFullUrlFromEnv(APIUrl);
  const token = getToken();

  if (!token && !API.public) {
    store.dispatch(modalOpen({
      modal: SIGNUP_PROMPT_MODAL,
      whiteExitButton: true
    }));

    return {};
  }

  // default content-type to application/json
  // TODO: remove CORs
  if (!APIOptions.headers) {
    APIOptions.headers = new Headers({
      'Content-Type': 'application/json'
    });
  }

  APIOptions.credentials = 'include';

  if (blob) {
    APIOptions.headers.delete('Content-Type');
    APIOptions.headers.append('Content-Transfer-Encoding', 'base64');
  }

  // default to post
  if (!API.method) {
    APIOptions.method = 'POST';
  } else {
    APIOptions.method = API.method;
  }

  // stringify json body
  if (params.body && !blob) {
    APIOptions.body = JSON.stringify(params.body);
  } else {
    APIOptions.body = params.body;
  }


  function handleErrors(response) {
    if (!response.ok) {
      // catch any fetch errors here

    }
    return response;
  }

  return fetch(fullUrl, APIOptions)
    .then((handleErrors))
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    })
    .then(response => {
      if (response.status === 401) {
        store.dispatch({
          type: APP_VALIDATE_TOKEN_FAILURE
        });

        if (getToken()) {
          removeToken();
          window.location.href = '/login';
        }
      }

      if (token && !store.getState().App.isLoggedIn) {
        store.dispatch({
          type: APP_VALIDATE_TOKEN_SUCCESS
        });
      }

      if (response.statusText === 'No Content') {
        return { success: true };
      }

      if (response.status === 502 || response.status === 403) {
        return response;
      };

      return response.json()
        .then(body => {
          if (!body.status) {
            body.status = response.status;
          }
          return body;
        })
        .catch(err => {
          console.error(err);
          return { status: response.status };
        });
    })
    .then(response => {
      if (store.getState().App.isUserSubscribed && response.is_user_subscribed === false) {
        store.dispatch({
          type: AUTH_SUBSCRIPTION_NOT_FOUND
        });
      }

      return response;
    });
}

export default fetchAPI;

function fetchAnyAPI(path, method = 'GET', params) {
  let body = params ? JSON.stringify(params) : null;

  return fetch(path, {
    method,
    body
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      throw error;
    })
    .then(response => {
      return response.json();
    })
}

export { fetchAnyAPI };