import {
  USER_SAVE,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_OPTIMISTIC,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_ERROR,
  USER_UPDATE_WITH_PASSWORD,
  USER_UPDATE_WITH_PASSWORD_SUCCESS,
  USER_UPDATE_WITH_PASSWORD_ERROR,
  USER_UPDATE_PHOTO,
  USER_RESET_UPDATE_ERRORS_AND_SUCCESS,
  USER_GET,
  USER_GET_SUCCESS,
  USER_JOURNEY_CARD_ADD,
  USER_JOURNEY_CARD_ADD_SUCCESS,
  USER_JOURNEY_CARD_ADD_ERROR,
  USER_JOURNEY_CARD_UPDATE,
  USER_JOURNEY_CARD_UPDATE_SUCCESS,
  USER_JOURNEY_CARD_DELETE,
  USER_JOURNEY_CARD_DELETE_SUCCESS,
  USER_PERSONALITY_CARD_ADD,
  USER_PERSONALITY_CARD_ADD_SUCCESS,
  USER_PERSONALITY_CARD_UPDATE,
  USER_PERSONALITY_CARD_UPDATE_SUCCESS,
  USER_PERSONALITY_CARD_DELETE,
  USER_PERSONALITY_CARD_DELETE_SUCCESS,
  USER_REMOVE_CONTACT_REQUEST,
  UPDATE_USER_LIST
} from './actions';

const initialState = {
  user: {},
  saveCount: 0,
  errorCount: 0,
  industryUsers: [],
  localUsers: []
};

function userReducer(state = initialState, action) {
  let user = {};
  switch (action.type) {
    case USER_GET: {
      return {
        ...state,
        isLoading: true
      }
    }
    case USER_UPDATE:
    case USER_UPDATE_PASSWORD:
    case USER_JOURNEY_CARD_ADD:
    case USER_JOURNEY_CARD_ADD_ERROR:
    case USER_JOURNEY_CARD_ADD_SUCCESS:
      user = { ...state.user };
      if (action.payload) {
        user.journey_cards = action.payload.journey_cards;
      }
      return {
        ...state,
        user,
        isLoading: false
      }
    case USER_UPDATE_PHOTO:
        return {
          ...state,
          isUpdating: true
        }
    case USER_JOURNEY_CARD_UPDATE:
    case USER_JOURNEY_CARD_UPDATE_SUCCESS:
      user = { ...state.user };
      if (action.payload) {
        user.journey_cards = action.payload.journey_cards;
      }
      return {
        ...state,
        user,
        isLoading: false
      }
    case USER_JOURNEY_CARD_DELETE:
    case USER_JOURNEY_CARD_DELETE_SUCCESS:
      user = { ...state.user };
      if (action.payload) {
        user.journey_cards = action.payload.journey_cards;
      }
      return {
        ...state,
        user,
        isLoading: false
      }
    case USER_PERSONALITY_CARD_ADD:
    case USER_PERSONALITY_CARD_UPDATE:
    case USER_PERSONALITY_CARD_UPDATE_SUCCESS: {
      let newState = state;
      for (let i = 0; i < newState.user.cards.length; i++) {
        if (newState.user.cards[i].card_type == action.payload.card_type) {
          newState.user.cards[i] = action.payload;
        }
      }
      return {
        ...newState
      };
    }
    case USER_PERSONALITY_CARD_ADD_SUCCESS: {
      let newState = state;
      newState.user.cards.push(action.payload);

      return {
        ...newState
      }
    }
    case USER_PERSONALITY_CARD_DELETE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case USER_GET_SUCCESS:
    case USER_UPDATE_OPTIMISTIC: {
      const user = { ...state.user, ...action.payload };
      return {
        ...state,
        user,
        isLoading: false
      }
    }
    case USER_UPDATE_ERROR:
      return {
        ...state,
        errorCount: state.errorCount + 1,
        isUpdating: false
      }
    case USER_UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        isLoading: false,
        userUpdateErrors: action.payload
      }
    }
    case USER_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userUpdateErrors: null,
        userUpdateSuccess: true
      }
    case USER_UPDATE_WITH_PASSWORD_ERROR: {
      return {
        ...state,
        isLoading: false,
        userUpdateErrors: action.payload
      }
    }
    case USER_UPDATE_WITH_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userUpdateErrors: null,
        userUpdateSuccess: true
      }
    case USER_RESET_UPDATE_ERRORS_AND_SUCCESS: {
      return {
        ...state,
        userUpdateErrors: null,
        userUpdateSuccess: null
      }
    }
    case USER_UPDATE_SUCCESS: {
      const user = { ...state.user, ...action.payload };
      return {
        ...state,
        user,
        isLoading: false,
        isUpdating: false,
        userUpdateErrors: null,
        saveCount: state.saveCount + 1
      }
    }
    case USER_SAVE:
      return {
        ...state,
        ...action.payload
      };
    case USER_REMOVE_CONTACT_REQUEST: {
      let newState = Object.assign({}, state);
      newState.user.contact_requests = state.user.contact_requests.filter((request) => {
        return request.id !== action.payload.id;
      });
      return {
        ...newState
      };
    }
    case UPDATE_USER_LIST: {
      if (action.listType === 'industry') {
        return {
          ...state,
          industryUsers: action.payload
        }
      } else {
        return {
          ...state,
          localUsers: action.payload
        }
      }
    }
    default:
      return {
        ...state
      }
  }
}

export default userReducer;
