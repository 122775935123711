import React, { Component } from 'react'
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import avatarIcon from '../../../../public/assets/icons/AVATAR.svg';
import './MembersPreview.scss';

import kFormatter from '../../../utils/kFormatter';

class MembersPreview extends Component {
  static defaultProps = {
    showAvatar: false
  }

  static propTypes = {
    memberCount: PropTypes.number.isRequired,
    showAvatar: PropTypes.bool,
    showMemberIcons: PropTypes.bool
  }

  constructor(props) {
    super(props);

    this.state = {
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    }
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.updateViewportSize);
  }

  updateViewportSize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewportSize);
  }

  render() {
    const { 
      members, 
      memberCount, 
      userId, 
      showAvatar, 
      onClick 
    } = this.props;

    let iconAvatar;
    let icons = [];

    if(showAvatar) {
      iconAvatar = <SVG className="icon-image" src={avatarIcon} />;
    } else {
      icons = members
      .filter(member => member.user.id !== userId)
      .map(member => member.user.photo.thumbnail)
      .slice(-3);

      iconAvatar = icons.slice(0,3).map((icon, i) => (
        <div
          key={i}
          className="MembersPreview__icons--photo icon-image"
          style={{ backgroundImage: `url(${icon})` }}
        />))
    };

    let width;
    let marginLeft = 3;
    if(!showAvatar) {
      width = 18; // Not 0 when empty because there's a negative margin on the text component.
      
      if (icons.length) {
        width = 30;
        width = width * icons.length;

        if (icons.length === 2) {
          marginLeft = -6;
        } else if (icons.length === 3) {
          marginLeft = -18;
        }
      }
    } else {
      width = 'auto';
    }

    const membersText = memberCount > 1 ? 'Members' : 'Member'

    if(memberCount > 0) {
      return (
        <div className="MembersPreview" onClick={onClick ? onClick : null} style={{cursor: onClick? 'pointer' : 'default'}}>
          <div className="MembersPreview__icons" style={{width: width + 'px'}}>
            {iconAvatar}
          </div>
  
          <span className="MembersPreview__text" style={{marginLeft: marginLeft + 'px'}}>{kFormatter(memberCount) + ' ' + membersText}</span>
        </div>
      )
    } else {
      return null;
    }
  }
}

export default MembersPreview;