import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from './index.js';

class InputWithTextHighlighting extends Component {
  static defaultProps = {
    inputComponent: Input
  };

  static propTypes = {
    className:PropTypes.string,
    onChange:PropTypes.func,
    expressions:PropTypes.array
  };

  constructor (props) {
    super(props);

    const ua = window.navigator.userAgent.toLowerCase();
    const isIE = !!ua.match(/msie|trident\/7|edge/);
    const isWinPhone = ua.indexOf('windows phone') !== -1;
    const isIOS = !isWinPhone && !!ua.match(/ipad|iphone|ipod/);

    this.state = {
      value:'',
      display:'',
      ua,
      isIE,
      isWinPhone,
      isIOS
    }
  }

  setBackdropRef = ref => {
    this.backdrop = ref;
  }

  setIOSRef = ref => {
    this.ios = ref;
  }

  setHighlightsRef = ref => {
    this.highlights = ref;
  }

  setTextareaRef = ref => {
    this.textarea = ref;
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  handleChange = (e) => {
    const { isIOS } = this.state;
    if (isIOS) {
      this.fixIOS();
    }

    const highlightedText = this.applyHighlights(e);
    this.setState({display:highlightedText});
    // this.highlights.html({ __html:highlightedText });

    const { onChange } = this.props;
    if (onChange) onChange(e);
  }

  applyHighlights = (value) => {
    const { className, expressions } = this.props;
    const { isIE } = this.state;

    expressions.forEach(expression => {
      value = value.replace(/\n$/g, '\n\n').replace(expression.regex, `<mark class="${className}" style="background-color:${expression.color} !important">$&</mark>`);
    });

    if (isIE) value = value.replace(/ /g, ' <wbr>');
    
    return value;
  }

  handleScroll = () => {
    const scrollTop = this.textarea.scrollTop;
    this.backdrop.scrollTop = scrollTop;
    
    const scrollLeft = this.textarea.scrollLeft;
    this.backdrop.scrollLeft = scrollLeft;
  }

  fixIOS = () => {
    // iOS adds 3px of (unremovable) padding to the left and right of a textarea, so adjust highlights div to match
    this.ios.style = {
      paddingLeft: '3px',
      paddingRight: '3px'
    };
  }

  render() {
    const { className, controlledValue } = this.props;
    const InputComponent = this.props.inputComponent;

    return (
      <div>
        <div 
          className={classNames("InputWithTextHighlighting__backdrop")} 
          ref={this.setBackdropRef}
        >
          <div className="InputWithTextHighlighting__ios" ref={this.setIOSRef}>
            <div className="InputWithTextHighlighting__highlights" ref={this.setHighlightsRef} dangerouslySetInnerHTML={{__html:this.applyHighlights(controlledValue)}}></div>
          </div>
        </div>
        <InputComponent
          {...this.props}
          value={controlledValue}
          onChange={this.handleChange}
          onScroll={this.handleScroll}
          setRef={this.setTextareaRef}
        />
      </div>
    )
  }
}

export default InputWithTextHighlighting;
