import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../common/Input';

class ProfileField extends Component {
  static propTypes = {
    data: PropTypes.object,
    label: PropTypes.string,
    field: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string
  };

  static defaultProps = {
    canViewEditStates: false
  };

  constructor (props) {
    super(props);
  }

  updateField = e => {
    let { data, field, update } = this.props;
    data[field] = e;
    update(data);
  }

  updateFieldDropdown = e => {
    let { data, field, update } = this.props;
    data[field] = e.target.value;
    update(data);
  }

  render() {
    const { data, label, field, placeholder, required, options, error, errorMessage } = this.props;

    return (
      <div className="ProfileField">
        {
          (label) && (
            <h4 className="ProfileField__label">
              {label}{ required && <span className="ProfileField__required">*</span>}
            </h4>
          )
        }
        {
          (!options) && (
            <Input
              className={classNames(
                "ProfileField__input",
                {
                  "ProfileField__error":error
                }
              )}
              controlledValue={data[field] || ''}
              onChange={this.updateField}
              placeholder={placeholder}
            />
          )
        }
        {
          (options) && (
            <select
              className={classNames(
                "ProfileField__input",
                "ProfileField__select",
                {
                  "ProfileField__error":error
                }
              )}
              value={data[field]}
              onChange={this.updateFieldDropdown}
              placeholder={placeholder}
            >
              {
                options.map(value => (
                  <option value={value} key={value}>{value}</option>
                ))
              }
            </select>
          )
        }
        {
          error && errorMessage && (
            <span className="ProfileField__error-message">{errorMessage}</span>
          )
        }
      </div>
    )
  }
};

export default ProfileField;