import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalDialog from '../ModalTemplates/ModalDialog';

import {
  GTMpushDataLayerEvent,
  GTM_VIDEO_PLAY,
  GTM_VIDEO_EXIT
} from '../../../lib/GoogleTagManager';

class VideoModal extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    featuring: PropTypes.string,
    description: PropTypes.string
  }

  componentDidMount() {
    // autoplay
    GTMpushDataLayerEvent({
      event: GTM_VIDEO_PLAY,
      videoId: this.props.id
    });
  }

  render() {
    return (
      <ModalDialog
        close={this.props.close}
        setRef={this.props.setRef}
        header={this.props.title}
        subHeader={this.props.featuring}
        className="VideoModal"
        modalContainerClassName="VideoModal__container"
        headerClassName="VideoModal__header VideoModal__text"
        subHeaderClassName="VideoModal__text"
      >
        {this.props.children}
        <div className="VideoModal__container--video">
          <iframe
            src={`https://player.vimeo.com/video/${this.props.id}?autoplay=1`}
            width="900"
            height="360"
            allow="autoplay"
            muted
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
        {this.props.description && (
          <p className="VideoModal__container--description">
            {this.props.description}
          </p>
        )}
      </ModalDialog>
    )
  }
}

export default VideoModal;
