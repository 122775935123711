// Canvas toBlob() polyfill for crappy browsers.
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var dataURL = this.toDataURL(type, quality).split(',')[1];
      setTimeout(function() {
        var binStr = atob(dataURL),
            len = binStr.length,
            arr = new Uint8Array(len);
        for (var i = 0; i < len; i++ ) {
          arr[i] = binStr.charCodeAt(i);
        }
        callback( new Blob( [arr], {type: type || 'image/png'} ) );
      });
    }
  });
}

export function processImageFile(file, width, height) {
  const filename = file.name;

  return readFileAsDataURL(file)
    .then(dataUrl => loadImage(dataUrl))
    .then(img => {
      return getImageOrientation(file)
        .then(orientation => resetOrientation(img, orientation))
        .catch(err => {
          console.error(err);
          return img;
        })
    })
    .then(img => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, width, height);
      drawCroppedImage(ctx, img);
      
      return new Promise(resolve => {
        canvas.toBlob(resolve, 'image/jpeg', 1);
      })
    });
}

export function readFileAsDataURL(file) {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = event => resolve(event.target.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function loadImage(src) {
  const img = new Image();
  return new Promise(resolve => {
    img.onload = () => resolve(img);
    img.src = src;
  });
}

export function resetOrientation(img, srcOrientation) {
  const { width, height } = img;
  let transform;

  switch (srcOrientation) {
    case 2: transform = [-1, 0, 0, 1, width, 0]; break;
    case 3: transform = [-1, 0, 0, -1, width, height]; break;
    case 4: transform = [1, 0, 0, -1, 0, height]; break;
    case 5: transform = [0, 1, 1, 0, 0, 0]; break;
    case 6: transform = [0, 1, -1, 0, height, 0]; break;
    case 7: transform = [0, -1, -1, 0, height, width]; break;
    case 8: transform = [0, -1, 1, 0, 0, width]; break;
    default:
      return Promise.resolve(img);
  }

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext("2d");
  
  if (4 < srcOrientation && srcOrientation < 9) {
    canvas.width = height;
    canvas.height = width;
  } else {
    canvas.width = width;
    canvas.height = height;
  }

  ctx.transform(...transform);
  ctx.drawImage(img, 0, 0);

  return loadImage(canvas.toDataURL());
}

export function getImageOrientation(file) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = event => {
      if (!event.target) {
        return;
      }
  
      const file = event.target;
      const view = new DataView(file.result);
  
      if (view.getUint16(0, false) != 0xFFD8) {
        return resolve(-2);
      }
  
      const length = view.byteLength
      let offset = 2;
  
      while (offset < length) {
        if (view.getUint16(offset+2, false) <= 8) return resolve(-1);
        let marker = view.getUint16(offset, false);
        offset += 2;
  
        if (marker == 0xFFE1) {
          if (view.getUint32(offset += 2, false) != 0x45786966) {
            return resolve(-1);
          }
  
          let little = view.getUint16(offset += 6, false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          let tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) == 0x0112) {
              return resolve(view.getUint16(offset + (i * 12) + 8, little));
            }
          }
        } else if ((marker & 0xFF00) != 0xFF00) {
          break;
        }
        else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(-1);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export function drawCroppedImage(ctx, img) {
  let w = ctx.canvas.width;
  let h = ctx.canvas.height;
  let offsetX = 0.5;
  let offsetY = 0.5;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  let iw = img.width,
      ih = img.height,
      r = Math.min(w / iw, h / ih),
      nw = iw * r,
      nh = ih * r,
      cx, cy, cw, ch, ar = 1;

  // decide which gap to fill    
  if (nw < w) ar = w / nw;                             
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, 0, 0, w, h);
}
