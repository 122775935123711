import {
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_REQUEST_SUCCESS,
  PASSWORD_RESET_REQUEST_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR
} from './actions';

const initialState = {};

function passwordResetReducer(state = initialState, action) {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
    case PASSWORD_RESET_REQUEST_SUCCESS:
    case PASSWORD_RESET_REQUEST_ERROR:
    case PASSWORD_RESET:
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_ERROR:
    default:
      return state;
  }
}

export default passwordResetReducer;
