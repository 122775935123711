import React, { Component } from 'react';
import PropTypes from 'process';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import SVG from 'react-inlinesvg';
import qs from 'query-string';
import routes from '../../../config/routes';
import { selectRequest } from '../../../store/requests';

import {
  collectivesGet,
  collectiveMemberGet,
  collectiveMemberAdd,
  collectiveMemberRemove,
  clearCollectiveData,
  clearUserCollectives
} from '../../../store/Collectives/actions';
import {
  modalOpen
} from '../../../store/Modal/actions';
import { isEmpty } from 'lodash';
import { CollectivePolicy } from '../CollectivePolicy';

import { COLLECTIVE_CREATION_MODAL } from '../../../containers/ModalManager/ModalTypes';
import InfiniteScroller from '../../../components/InfiniteScroller';
import CollectiveDirectoryItem from './CollectivesDirectoryItem';
import { INPUT_SECONDARY_PLAIN } from '../../../common/Input';
import InputWithIcon from '../../../common/Input/InputWithIcon';
import Button, { THEME_BLACK, THEME_BLUE } from '../../../common/Button';
import Loader from '../../../common/Loader';
import searchIcon from '../../../../public/assets/icons/SEARCH.svg';
import addIcon from '../../../../public/assets/icons/ADD.svg';

import './CollectivesDirectory.scss';

class CollectivesDirectory extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      searchTerm: '',
      offset: 0,
      limit: 12,
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    };

    props.clearUserCollectives();
    props.collectiveMemberGet({
      userId: props.user.id,
      role: 'owner'
    })
  }

  componentDidMount() {
    this.searchParamsDidChange();
    window.addEventListener("resize", this.updateViewportSize);
  }

  updateViewportSize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewportSize);
  }
  
  searchParamsDidChange() {
    let params = qs.parse(this.props.location.search);
    let searchTerm = (params.search || '').trim();

    this.setState({searchTerm}, () => {
      this.onSearch();
    });
  }

  handleSearchChange = value => {
    this.setState({
      searchTerm: value
    });
  }

  onSearch = loadMore => {
    this.props.collectivesGet({
      query: this.state.searchTerm,
      limit: this.state.count,
      offset: loadMore ? this.props.offset : 0,
      limit: 12
    });
  }

  onSearchTerm = () => {
    this.updateURL();
    this.onSearch();
  }

  updateURL = () => {
    const { page, searchTerm } = this.state;
    let queryString = routes.collectiveDirectory;
    if (page > 1 || searchTerm) queryString += `?`;
    if (page > 1) queryString += `page=${page}`;
    if (searchTerm) queryString += `&search=${searchTerm}`;

    this.props.history.push(queryString);
  }

  openCollectiveCreationModal = () => {
    this.props.clearCollectiveData();
    
    this.props.modalOpen({
      modal: COLLECTIVE_CREATION_MODAL,
      implicitExit: false
    });
  }

  renderCollectivesList = () => {
    let isMobile = this.state.viewportWidth < 600;
    const { collectives } = this.props;
    const collectivesList = [];

    for(let collective in collectives) {
      collectivesList.push(
        collectives[collective]
      );
    }
    
    return collectivesList.map(collective => {
      let collectivePolicy = new CollectivePolicy(this.props.user, collective, true);

      return (
        <CollectiveDirectoryItem 
          key={collective.id}
          collective={collective}
          collectivePolicy={collectivePolicy}
          user={this.props.user}
          userId={this.props.user.id}
          onSubscribe={this.props.collectiveMemberAdd}
          onUnsubscribe={this.props.collectiveMemberRemove}
          isMobile={isMobile}
      />
      )
    });
  }

  render() {
    const { 
      collectives, 
      hasLoadedAllCollectives, 
      isOwnerRequest,
      request, 
      user, 
      userCollectives, 
    } = this.props;
    const { searchTerm, viewportWidth } = this.state;

    return (
      <div className="CollectivesDirectory">
        <h1 className="CollectivesDirectory__heading">Discover Collectives</h1>
        <p className="CollectivesDirectory__subheading">Find your niche, find your people.</p>

        <div className="CollectivesDirectory__button-container">
          <InputWithIcon 
            addBefore={<SVG src={searchIcon} />}
            autoCorrect={'off'}
            className="CollectivesDirectory__search-bar"
            onChange={this.handleSearchChange}
            onClick={this.onSearchTerm}
            placeholder="Search"
            theme={INPUT_SECONDARY_PLAIN}
            value={searchTerm}
            iconComponent={
            <Button theme={THEME_BLACK} type="submit" noOutline>
              Search
            </Button>}
          />
          
          { !isOwnerRequest.pending && isEmpty(userCollectives) && user.roles.includes('tester') && (
            <Button className="CollectivesDirectory__create-btn" theme={THEME_BLUE} noOutline onClick={this.openCollectiveCreationModal}>
              <SVG src={addIcon} /> {viewportWidth < 600 ? 'Create' : 'Create Collective'} 
            </Button>
          )}
        </div>
   

        <InfiniteScroller
          shouldLoadMore={!request.pending && !hasLoadedAllCollectives}
          hasLoadedAll={hasLoadedAllCollectives}
          loadMore={() => this.onSearch('load-more')}
          className="CollectivesDirectory__container"
        >
          { this.renderCollectivesList() }
        </InfiniteScroller>
        { 
          request.pending ?     
            <div style={{position: 'absolute', left: '50%'}}>
              <Loader className="bg__blue" />
            </div> : null 
        }
        {
          !request.pending && isEmpty(collectives) && (
            <h2 style={{textAlign:'center'}}>No Collectives found.</h2>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { collectives, directorySlugs } = state.Collectives;

  return {
    user: state.User.user,
    collectives: directorySlugs.map(slug => collectives[slug]),
    totalCollectives: state.Collectives.totalCollectives,
    request: selectRequest(state, collectivesGet),
    isOwnerRequest: selectRequest(state, collectiveMemberGet),
    offset: state.Collectives.offset,
    hasLoadedAllCollectives: state.Collectives.hasLoadedAllCollectives,
    userCollectives: state.Collectives.userCollectives
  }
}

export default connect(mapStateToProps, {
  collectivesGet,
  collectiveMemberGet,
  collectiveMemberAdd,
  collectiveMemberRemove,
  clearCollectiveData,
  clearUserCollectives,
  modalOpen
})(withRouter(CollectivesDirectory));
