import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { disableBodyScroll } from 'body-scroll-lock';

import {
  GTMpushDataLayerEvent,
  GTM_VIDEO_EXIT_EXPLICIT,
  GTM_VIDEO_EXIT_IMPLICIT
} from '../../lib/GoogleTagManager';

import { modalClose } from '../../store/Modal/actions';
import mapTypeToModal, { VIDEO_MODAL } from './ModalTypes';
import SVG from 'react-inlinesvg';
import closeSVG from '../../../public/assets/icons/CLOSE.svg';

class ModalManager extends Component {
  static propTypes = {
    submit: PropTypes.func,
    position: PropTypes.oneOf(['center', 'belowNav']),
  };

  static defaultProps = {
    position: 'center'
  };

  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyDown);
    // window.addEventListener('mousedown', this.handleClick, false);
  }

  componentDidUpdate(prevProps) {
    const { history, modalOpen } = this.props;
    if (modalOpen && modalOpen !== prevProps.modalOpen) {
      if (modalOpen.locationHash && `#${modalOpen.locationHash}` !== history.location.hash) {
        history.push(`${history.location.pathname}#${modalOpen.locationHash}`);
      }
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyDown);
    // window.removeEventListener('mousedown', this.handleClick, false);
  }

  handleKeyDown = e => {
    e.stopPropagation();

    // if escape key
    if (e.keyCode === 27) this.handleImplicitExit();
  }

  handleClick = e => {
    e.stopPropagation();
    if (this.modalRef && !this.modalRef.contains(e.target)) {
      this.handleImplicitExit();
    }
  }

  setRef = ref => {
    this.modalRef = ref;
    if (this.modalRef) disableBodyScroll(this.modalRef.current);
  }

  handleClose = () => {
    const { history, modalOpen, onCloseCallback } = this.props;
    
    if (modalOpen.locationHash && history.location.hash === `#${modalOpen.locationHash}`) {
      history.replace(history.location.pathname);
    }

    if (onCloseCallback) onCloseCallback();
    this.props.modalClose();
  }

  handleExplicitExit = () => {
    this.handleClose();

    switch (this.props.modal) {
      case [VIDEO_MODAL]: {
        GTMpushDataLayerEvent({
          event: GTM_VIDEO_EXIT_EXPLICIT,
          category: VIDEO_MODAL,
          videoId: this.props.id
        });
        break;
      }
    }
  }

  handleImplicitExit = () => {
    if (this.props.modalOpen && !this.props.modalOpen.implicitExit) return;

    this.handleClose();

    switch (this.props.modal) {
      case [VIDEO_MODAL]: {
        GTMpushDataLayerEvent({
          event: GTM_VIDEO_EXIT_IMPLICIT,
          videoId: this.props.id,
          category: VIDEO_MODAL
        });
        break;
      }
      default: {
        return null;
        break;
      }
    }
  }

  render() {
    const { position, modalOpen, closeClassName } = this.props;

    if (!modalOpen) return null;

    const Modal = mapTypeToModal[modalOpen.modal];

    return (
      <div
        className={classNames(
          "overlay-container",
          {
            'center-align': position === 'center',
            'overlay-container--belowNav': position === 'belowNav'
          }
        )}
        ref={this.modalElement}
        onMouseDown={this.handleClick}
        style={{background: modalOpen.customOverlay ? modalOpen.customOverlay : null}}
      >
        <Modal
          submit={this.props.submit}
          close={this.handleClose}
          setRef={this.setRef}
          {...this.props.modalOpen}
        >
          <div
            className={classNames(
              'Modal__icon--x',
              closeClassName,
              {
                'Modal__icon--x--white': modalOpen.whiteExitButton
              }
            )}
            onClick={this.handleExplicitExit}
          >
            <SVG src={closeSVG} />
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ Modal }) => {
  return {
    modalOpen: Modal.modalOpen
  }
}

function mapDispatchToProps(dispatch) {
  return {
    modalClose: () => dispatch(modalClose()),
    isNewUserOff: () => dispatch(isNewUserOff())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalManager));
