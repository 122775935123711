import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import { preferencesGetExternal, preferencesUpdateExternal } from '../../store/Preferences/actions';

import Input, { THEME_GRAY } from '../../common/Input';
import Button, { THEME_BLUE } from '../../common/Button';
import Preference from './Preference';

import './PreferencesExternal.scss';
import { isEmailAddress } from '../../common/Input/CommonFields/validations';

import PreferencesExternalPanel from './PreferencesExternal';
import { EMAIL_PREFERENCES } from '../../common/Preferences';

class PreferencesExternal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      token:'',
      emailSet:false,
      selection:'pick',
      preferences:EMAIL_PREFERENCES,
      loadedPreferences:{},
      showSaveAlert:false,
      showErrorAlert:false
    }
  }

  componentDidMount = () => {
    const { user } = this.props;
    let params = qs.parse(this.props.location.search);
    if (user && user.email_token) {
      this.setState({token:user.email_token});
      this.props.preferencesGetExternal(user.email_token);
    } else {
      if (params.t) {
        this.setState({token:params.t});
        this.props.preferencesGetExternal(params.t);
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    const { saveCount, errorCount } = this.props;
    if (saveCount > prevProps.saveCount) {
      this.setState({showSaveAlert:true}, () => {
        setTimeout(() => {
          this.setState({showSaveAlert:false});
        }, 5000)
      });
    }

    if (errorCount > prevProps.errorCount) {
      this.setState({showErrorAlert:true}, () => {
        setTimeout(() => {
          this.setState({showErrorAlert:false});
        }, 5000)
      });
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { preferences } = props;

    let { loadedPreferences } = state;
    Object.keys(preferences).forEach(i => {
      if (i == 'selection' && preferences[i] !== loadedPreferences[i]) state.selection = preferences[i];
      if (state.preferences[i] && preferences[i] !== loadedPreferences[i]) state.preferences[i].checked = (preferences[i] == "true");
      if (state.preferences[i] && preferences[i + '_option'] !== loadedPreferences[i + '_option']) state.preferences[i].option = preferences[i + '_option'];
    });
    state.loadedPreferences = preferences;

    return state;
  }

  handleEmailChange = e => {
    this.setState({email:e});
  }

  handlePreferenceChange = (preference) => {
    let { preferences } = this.state;
    let newPreferences = Object.assign({}, preferences);
    newPreferences[preference].checked = !newPreferences[preference].checked;
    this.setState({preferences:newPreferences});
  }

  handlePreferenceOptionChange = (preference, option) => {
    let { preferences } = this.state;
    let newPreferences = Object.assign({}, preferences);
    newPreferences[preference].option = option;
    this.setState({preferences:newPreferences});
  }

  handlePreferenceFrequencyChange = (selection) => {
    selection = selection.target.value;
    let { preferences } = this.state;
    if (selection == 'few') {
      preferences.newsletter.checked = true;
      // preferences.newsletter.option = 'Twice monthly';
      preferences.mentors.checked = false;
      preferences.radio_network.checked = true;
      preferences.rally.checked = false;
      preferences.international_waitlist.checked = false;
      preferences.sweeps.checked = false;
      preferences.invites.checked = true;
    } else {
      preferences.newsletter.checked = true;
      // preferences.newsletter.option = 'Twice monthly';
      preferences.mentors.checked = true;
      preferences.radio_network.checked = true;
      preferences.rally.checked = true;
      preferences.international_waitlist.checked = true;
      preferences.sweeps.checked = true;
      preferences.invites.checked = true;
    }
    this.setState({selection:selection, preferences});
  }

  mapStateToCM = () => {
    const { token, preferences, selection } = this.state;
    let payload = {
      token,
      preferences:{
        selection
      }
    };
    Object.keys(preferences).forEach((key) => {
      payload.preferences[key] = preferences[key].checked;
      if (preferences[key].option) {
        payload.preferences[key + '_option'] = preferences[key].option;
      }
    });
    return payload;
  }

  setEmail = () => {
    const { email } = this.state;
    if (isEmailAddress(email)) {
      this.props.preferencesGetExternal(email);
      this.setState({email, emailSet:true});
    }
  }

  submitPreferences = () => {
    const payload = this.mapStateToCM();
    this.props.preferencesUpdateExternal(payload);
  }

  render() {

    const { isLoading, email, user } = this.props;
    const { preferences, showSaveAlert, showErrorAlert, selection } = this.state;
    
    let params = qs.parse(this.props.location.search);

    return (
      <div className="PreferencesExternal__background">
        <div className="PreferencesExternal__container">
          <Input
            type={'email'}
            value={email}
            onChange={this.handleEmailChange}
            theme={THEME_GRAY}
            disabled
            className="PreferencesExternal__email-input"
            placeholder={email}
          />
          <PreferencesExternalPanel
            isLoading={isLoading}
            email={email}
            user={user}
            token={params.t}
            preferences={preferences}
            selection={selection}
            handlePreferenceChange={this.handlePreferenceChange}
            handlePreferenceOptionChange={this.handlePreferenceOptionChange}
            handlePreferenceFrequencyChange={this.handlePreferenceFrequencyChange}
          />
          <Button
            className="Preferences__update"
            disabled={isLoading}
            theme={THEME_BLUE}
            onClick={this.submitPreferences}
          >
            Update
          </Button>
        </div>
        <div className="PreferencesExternal__alert-container">
          {
            showSaveAlert && (
              <div className="PreferencesExternal__alert PreferencesExternal__updated">Update successful</div>
            )
          }
          {
            showErrorAlert && (
              <div className="PreferencesExternal__alert PreferencesExternal__error">Something went wrong</div>
            )
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    email: state.Preferences.email,
    preferences: state.Preferences.external,
    saveCount: state.Preferences.saveCount,
    errorCount: state.Preferences.errorCount,
    isLoading: state.Preferences.isLoading,
    user: state.User.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    preferencesGetExternal: (args) => dispatch(preferencesGetExternal(args)),
    preferencesUpdateExternal: (args) => dispatch(preferencesUpdateExternal(args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PreferencesExternal));

