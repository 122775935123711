import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  REPORT,
  reportSuccess,
  reportError,
  reportPost,
  reportComment
} from './actions';
import {
  authLogOut
} from '../Auth/actions';
import {
  userGet
} from '../User/actions';

function* reportSaga(action) {
  try { 
    switch (action.report_type) {
      case 'user': {
        const response = yield call(fetchAPI, 'reportUser', {
          body: action.payload
        });
        if(response.success) {
          yield put(userGet());
        }
        break;
      }
      case 'post': {
        const response = yield call(fetchAPI, 'reportPost', {
          body: action.payload
        });
        if(response.success) {
          if(response.suspended) {
            yield put(authLogOut(action.history));
          } else {
            yield put(reportPost(action.post_id));
          }
        }
        break;
      }
      case 'comment':
      case 'reply': {
        const response = yield call(fetchAPI, 'reportReply', {
          body: action.payload
        });
        if(response.success) {
          if(response.suspended) {
            yield put(authLogOut(action.history));
          } else {
            yield put(reportComment(action.post_id, action.payload.flagged_item_id));
          }
        }
        break;
      }

      default:
        return null;
    }

    yield put(reportSuccess(response));
  } catch (e) {
    yield put(reportError(e));
  }
}

export const reportSagas = [
  takeEvery(REPORT, reportSaga)
];
