import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ModalDialog from '../ModalTemplates/ModalDialog';

class DeleteModal extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['post', 'comment', 'reply']).isRequired,
    close: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool
  };

  static defaultProps = {
    isDeleting: false
  }

  constructor(props) {
    super(props);
  }

  componentWillReceiveProps = newProps => {
    if (this.props.isDeleting && !newProps.isDeleting) {
      this.props.close();

      if (this.props.type === 'post') {
        this.props.history.push('/');
      }
    }
  }

  render() {
    const {
      type,
      close,
      setRef
    } = this.props;

    return (
      <ModalDialog
        header={`Delete ${type}`}
        subHeader="Are you sure you want to delete this?"
        close={close}
        setRef={setRef}
        submit={this.props.submit}
        isLoading={this.props.isDeleting}
        submitText="Delete"
        className="DeleteModal"
      >
        {this.props.children}
      </ModalDialog>
    )
  }
}

const mapStateToProps = ({ Modal }) => {
  return {
    isDeleting: Modal.isDeleting
  }
}

export default withRouter(connect(mapStateToProps)(DeleteModal));
