import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class NotificationBlock extends Component {

  handleChange = (e) => {
    const { category, id, handleChange } = this.props;
    handleChange(category, id, e);
  }

  render() {
    const {
      label,
      description,
      value,
      options
    } = this.props;

    return (
      <div className="Settings__notification-block">
        <div className="Settings__notification-block-label-container">
          <h5 className="Settings__notification-block-label">{label}</h5>
          <p>{description}</p>
        </div>
        <div className="Settings__notification-block-action">
          <Select
            className="Settings__notification-select"
            value={value}
            onChange={this.handleChange}
            options={options}
          />
        </div>
      </div>
    )
  }
}

export default NotificationBlock;
