import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { activityTypes } from '../../store/Legion/logic';
import { notifsRead } from '../../store/Notifications/actions';
import { userGetId } from '../../store/User/actions';

import './NotificationText.scss';

class NotificationText extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    link: PropTypes.string,
    sender: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired
      }),
      PropTypes.string
    ]),
    notificationType: PropTypes.number,
    activityType: PropTypes.number,
    receiver: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
      }),
      PropTypes.string
    ]),
    contentType: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    sender: ''
  };

  getContent = () => (
    this.getStyle(this.props.link, this.props.contentType, this.readNotif)
  )

  getSender = user => (
    this.getStyle(`/${user.username}`, `${user.first_name} ${user.last_name}`)
  )

  getReceiver = user => {
    if (user.id === this.props.userId) {
      // TODO: not correctly showing 'your' when the post belongs to the current user
      // because notificationType is undefined
      if (this.props.notificationType) return 'your';
    }
    return (
      <span>{this.getStyle(`/${user.username}`, `${user.first_name} ${user.last_name}`)}'s</span>
    );
  }

  getCollective = () => (
    this.getStyle(this.props.collectiveLink, this.props.collectiveName, this.readNotif)
  )

  readNotif = () => {
    if (this.props.sender.id !== this.props.userId && !this.props.isRead) {
      this.props.notifsRead([this.props.id]);
      this.props.closeSideNav();
    }
  }

  getStyle = (link, text, onClick) => (
    <Link
      to={link}
      onClick={(e) => {if (onClick) {onClick();} this.stopPropagation(e);}}
      className={classNames(
        'NotificationText__text--link',
        this.props.className
      )}
    >
      {text}
    </Link>
  )

  stopPropagation = (e) => {
    e.stopPropagation();
    this.props.closeSideNav();
  }

  render() {
    let activity;

    if (this.props.activityType) {
      activity = this.props.activityType;
    } else {
      activity = this.props.notificationType;
    }

    const typeData = activityTypes[activity];
    const {
      action,
      preposition,
      pronoun,
      on
    } = typeData;
    const {
      className,
      sender,
      receiver,
      content
    } = this.props;

    // jackie created a post
    if (action && sender && !receiver && pronoun && typeData.content) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {pronoun} {this.getContent()}
        </p>
      )
    }

    // sophia tagged you in a post in LA Girlboss
    if (action == 'tagged' && preposition && sender && pronoun) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {pronoun} {preposition} a {this.getContent()} {preposition} {this.getCollective()} 
        </p>
      )
    }

    // sophia added a new post in LA Girlboss
    if (action && sender && receiver && pronoun && typeData.content && preposition && !on) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {pronoun} {this.getContent()} {preposition} {this.getCollective()}
        </p>
      )
    }

    // praised sophia's comment
    if (action && !preposition && sender && receiver) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {this.getReceiver(receiver)} {this.getContent()}
        </p>
      )
    }

    // commented on sophia's comment
    if (action && preposition && sender && receiver) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {preposition} {this.getReceiver(receiver)} {this.getContent()}
        </p>
      )
    }

    // replied to a comment on sophia's post
    if (action && preposition && pronoun && on && !sender && receiver) {
      return (
        <p className={className}>
          {action} {preposition} {pronoun} {content} on {this.getReceiver(receiver)} {this.getContent()}
        </p>
      )
    }

    // sophia praised your post
    if (action && !preposition && sender && receiver) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {this.getReceiver(receiver)} {this.getContent()}
        </p>
      )
    }

    // sophia commented on your post
    if (action && preposition && sender && receiver) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {preposition} {this.getReceiver(receiver)} {this.getContent()}
        </p>
      )
    }

    // sophia replied to a comment on your post
    if (action && preposition && pronoun && on && sender) {
      return (
        <p className={className}>
          {this.getSender(sender)} {action} {preposition} {pronoun} {this.getContent()} on {this.getReceiver(receiver)} {on}
        </p>
      )
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    userId: userGetId(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notifsRead: (args) => dispatch(notifsRead(args))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationText);
