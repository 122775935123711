import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactFitText from 'react-fittext';

import InfiniteScroller from '../../components/InfiniteScroller';
import NotificationActivity from '../../components/Notification/NotificationActivity';
import NotificationContactRequest from '../../components/Notification/NotificationContactRequest';
import NotificationAdmire from '../../components/Notification/NotificationAdmire';
import NotificationInvite from '../../components/Notification/NotificationInvite';

import {
  notifsGet,
  notifsGetNotifs,
  notifsGetIsLoading,
  notifsGetHasLoadedAllNotifs,
  notifsReadAll
} from '../../store/Notifications/actions';

import './Notifications.scss';

class Notifications extends Component {
  componentDidMount = () => {
    window.scrollTo(0,0);
  }

  render() {
    const { notifications, isLoading, hasLoadedAllNotifs } = this.props;

    return (
      <div className="Notifications">
        <div className="maxWidth">
          <div className="Notifications__header">
            <h1>Notifications</h1>
            <p onClick={this.props.notifsReadAll}>Mark all as read</p>
          </div>
          
          { notifications.length
            ? (
                <InfiniteScroller
                  loadMore={() => this.props.notifsGet('get-more')}
                  hasLoadedAll={hasLoadedAllNotifs}
                  shouldLoadMore={!isLoading && !hasLoadedAllNotifs}
                  isLoading={isLoading}
                >
                  {
                    notifications.map(notification => (
                      ((notification.type == 'postActivity') && (
                        <NotificationActivity
                          postActivity={notification.post_activity}
                          sender={notification.sender}
                          receiver={notification.receiver}
                          postContent={notification.post_content}
                          commentContent={notification.comment_content}
                          date={notification.created_at}
                          notificationType={notification.notification_type}
                          isRead={notification.is_read}
                          key={notification.id}
                          id={notification.id}
                        />
                      ))
                      ||
                      ((notification.type == 'contactRequest') && (
                        <NotificationContactRequest
                          contactRequest={notification.contact_request}
                          sender={notification.sender}
                          receiver={notification.receiver}
                          postContent={notification.post_content}
                          commentContent={notification.comment_content}
                          date={notification.created_at}
                          notificationType={notification.notification_type}
                          isRead={notification.is_read}
                          key={notification.id}
                          id={notification.id}
                        />
                      ))
                      ||
                      ((notification.type == 'admire') && (
                        <NotificationAdmire
                          sender={notification.sender}
                          receiver={notification.receiver}
                          admire={notification.admire}
                          date={notification.created_at}
                          notificationType={notification.notification_type}
                          isRead={notification.is_read}
                          key={notification.id}
                          id={notification.id}
                          closeSideNav={this.props.closeNav}
                        />
                      ))
                      ||
                      ((notification.type == 'invite') && (
                        <NotificationInvite
                          sender={notification.sender}
                          date={notification.created_at}
                          notificationType={notification.notification_type}
                          invite={notification.invite.invite}
                          isRead={notification.is_read}
                          key={notification.id}
                          id={notification.id}
                          closeSideNav={this.props.closeNav}
                        />
                      ))
                    ))
                  }
                </InfiniteScroller>
              )
              
            : <div className="listFooter">No notifications yet...</div>
          }
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    notifications: notifsGetNotifs(state),
    isLoading: notifsGetIsLoading(state),
    hasLoadedAllNotifs: notifsGetHasLoadedAllNotifs(state)
  }
};

function mapDispatchToProps(dispatch) {
  return {
    notifsGet: (args) => dispatch(notifsGet(args)),
    notifsReadAll: () => dispatch(notifsReadAll())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
