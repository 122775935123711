/**
  Open + close modal
**/
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const modalOpen = payload => {
  return {
    type: MODAL_OPEN,
    payload
  }
};

export const modalClose = () => {
  return {
    type: MODAL_CLOSE
  }
};
