import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputMadLib, { INPUT_MADLIB_WHITE } from '../../common/Input/InputMadLib';

import './Onboarding.scss';

class OnboardingLearning extends PureComponent {
  static propTypes = {
    id_like_to_learn: PropTypes.string,
    handleLearnChange: PropTypes.func.isRequired
  };
   
  render() {

    const {
      id_like_to_learn,
      handleLearnChange
    } = this.props;

    return (
      <div className="Onboarding__page-container-good-at">
      <div className="Onboarding__heading-container-bio">
          <h2 className="Onboarding__heading">What would you like to learn?</h2>
        </div>
        <div className="Onboarding__i-am-container">
          <span className="Onboarding__i-am" style={{marginRight:'12px'}}>I'd like to learn</span>
          <span className="Onboarding__i-am-input-container">
            <InputMadLib 
              placeholder="more about cryptocurrency"
              className="Onboarding__i-am"
              onChange={handleLearnChange}
              value={id_like_to_learn}
              maxLength={130}
              theme={INPUT_MADLIB_WHITE}
            />
            <span className="Onboarding__i-am" style={{display: 'flex', flexDirection: 'column-reverse', marginBottom: '17px'}}>.</span>
          </span>
        </div>
      </div>
    )
  }
}

export default OnboardingLearning;
