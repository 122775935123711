/**
  Get Notifications
**/
export const NOTIFS_GET = 'NOTIFS_GET';
export const NOTIFS_GET_SUCCESS = 'NOTIFS_GET_SUCCESS';
export const NOTIFS_GET_ERROR = 'NOTIFS_GET_ERROR';
export const NOTIFS_GET_MORE_SUCCESS = 'NOTIFS_GET_MORE_SUCCESS';
export const NOTIFS_GET_MESSAGES = 'NOTIFS_GET_MESSAGES';
export const NOTIFS_GET_MESSAGES_SUCCESS = 'NOTIFS_GET_MESSAGES_SUCCESS';
export const NOTIFS_GET_MESSAGES_ERROR = 'NOTIFS_GET_MESSAGES_ERROR';
export const NOTIFS_UPDATE_SUCCESS = 'NOTIFS_UPDATE_SUCCESS';

export const notifsGet = (action) => {
  return {
    type: NOTIFS_GET,
    payload: action
  }
};

export const notifsGetSuccess = (payload) => {
  return {
    type: NOTIFS_GET_SUCCESS,
    payload
  }
};

export const notifsGetError = payload => {
  return {
    type: NOTIFS_GET_ERROR,
    payload
  }
};

export const notifsGetMoreSuccess = (payload) => {
  return {
    type: NOTIFS_GET_MORE_SUCCESS,
    payload
  }
}

export const notifsGetMessages = (payload) => {
  return {
    type: NOTIFS_GET_MESSAGES,
    payload
  }
}

export const notifsGetMessagesSuccess = (payload) => {
  return {
    type: NOTIFS_GET_MESSAGES_SUCCESS,
    payload
  }
};

export const notifsGetMessagesError = payload => {
  return {
    type: NOTIFS_GET_MESSAGES_ERROR,
    payload
  }
};

export const notifsUpdateSuccess = payload => {
  return {
    type: NOTIFS_UPDATE_SUCCESS,
    payload
  }
}

/**
  Read Notifications
**/

export const NOTIFS_READ = 'NOTIFS_READ';
export const NOTIFS_READ_SUCCESS = 'NOTIFS_READ_SUCCESS';
export const NOTIFS_READ_ERROR = 'NOTIFS_READ_ERROR';
export const NOTIFS_READ_All = 'NOTIFS_READ_All';
export const NOTIFS_READ_ALL_SUCCESS = 'NOTIFS_READ_ALL_SUCCESS';

export const notifsRead = payload => {
  return {
    type: NOTIFS_READ,
    payload
  }
};

export const notifsReadSuccess = payload => {
  return {
    type: NOTIFS_READ_SUCCESS,
    payload
  }
};

export const notifsReadError = payload => {
  return {
    type: NOTIFS_READ_ERROR,
    payload
  }
};

export const notifsReadAll = () => {
  return {
    type: NOTIFS_READ_All
  }
};

export const notifsReadAllSuccess = payload => {
  return {
    type: NOTIFS_READ_ALL_SUCCESS,
    payload
  }
};


/**
  Selectors
**/
export const notifsGetNotifs = state => state.Notifications.notifications;
export const notifsGetUnreadCount = state => state.Notifications.unread_count;
export const notifsGetIsLoading = state => state.Notifications.isLoading;
export const notifsGetHasLoadedAllNotifs = state => state.Notifications.hasLoadedAllNotifs;
export const notifsGetLimit = state => state.Notifications.limit;
export const notifsGetOffset = state => state.Notifications.offset;
export const notifsGetUserSendbirdId = state => state.User.user.sendbird_user_id;