import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import Input from '../../common/Input';

import './IncompatibleBrowser.scss';

class IncompatibleBrowser extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      message:'Click to copy'
    }
  }

  copyLink = (e) => {
    e.target.select();
    document.execCommand('copy');
    this.setState({message:'Copied!'});
  }

  render() {

    const { message } = this.state;

    return (
      <div className="IncompatibleBrowser">
        <h1 className="IncompatibleBrowser__header">Whoops!</h1>
        <h3 className="IncompatibleBrowser__text">
            Looks like you're using an unsupported web browser! Open the link below in Chrome, Safari, or Firefox instead.
        </h3>
        <Input
          className="IncompatibleBrowser__input"
          onClick={this.copyLink}
          value={'www.girlboss.com'}
          readOnly
        />
        <span className="IncompatibleBrowser__label">{message}</span>
      </div>
    )
  }
}

export default IncompatibleBrowser;
