import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  toasterClose,
  TOASTER_TYPE_ERROR,
  TOASTER_TYPE_CONFIRM,
  TOASTER_TYPE_SUCCESS
} from '../../store/Toaster/actions';

import { userResetUpdateErrorsAndSuccess } from '../../store/User/actions';

import './Toaster.scss';

class Toaster extends Component {
  static propTypes = {
    toasterClose: PropTypes.func.isRequired,
    type: PropTypes.oneOf([
      TOASTER_TYPE_SUCCESS,
      TOASTER_TYPE_CONFIRM,
      TOASTER_TYPE_ERROR
    ]).isRequired,
    content: PropTypes.string,
    timeout: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      shouldFadeOpen: false
    };
  }

  componentDidMount() {
    this.toggleFadeOpen();
    const { toasterClose, timeout } = this.props;
    if (timeout) setTimeout(toasterClose, timeout);
  }

  componentWillReceiveProps = newProps => {
    if (this.props.location.pathname !== newProps.location.pathname) {
      this.props.toasterClose();
    } else {
      this.toggleFadeOpen();
    }
  }

  toggleFadeOpen = () => {
    this.setState({ shouldFadeOpen: true });
    setTimeout(() => {
      this.setState({ shouldFadeOpen: false });
    }, 1000);
  }

  onToasterClose = () => {
    this.props.toasterClose();
    this.props.userResetUpdateErrorsAndSuccess();
  }

  render() {
    const {
      type,
      content
    } = this.props;

    let contentMsg;
    
    if(type === TOASTER_TYPE_SUCCESS) {
      if(!content) {
        contentMsg = 'Successfully updated!';
      } else {
        contentMsg = content;
      };
    } else if(type === TOASTER_TYPE_ERROR) {
      if(!content) {
        contentMsg = 'Something went wrong.';
      } else {
        contentMsg = content;
      };
    };

    return (
      <div className="Toaster">
        <div
          className={classNames(
            "Toaster__container",
            {
              "Toaster__container--error": type === TOASTER_TYPE_ERROR,
              "Toaster__container--confirm": type === TOASTER_TYPE_CONFIRM,
              "Toaster__container--success": type === TOASTER_TYPE_SUCCESS,
              "Toaster__container--fade": this.state.shouldFadeOpen
            }
          )}
          onClick={this.onToasterClose}
        >
          <p className="Toaster__text">{contentMsg}</p>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toasterClose: () => dispatch(toasterClose()),
    userResetUpdateErrorsAndSuccess: () => dispatch(userResetUpdateErrorsAndSuccess())
  }
}

export default connect(null, mapDispatchToProps)(Toaster);
