import {
  POST_CHECK_FULFILLED,
  POST,
  POST_SUCCESS,
  POST_ERROR,
  GET_POST_SUCCESS,
  GET_POST_ERROR,
  GET_POST,
  POST_EDIT,
  POST_EDIT_SUCCESS,
  POST_EDIT_ERROR,
  POST_DELETE_SUCCESS,
  POST_DELETE_ERROR,
  GET_POST_COMMENTS_SUCCESS,
  GET_POST_COMMENTS_ERROR,
  GET_COMMENT_REPLIES_SUCCESS,
  GET_POST_COMMENTS
} from './actions';
import {
  REACT_POST_SUCCESS,
  REACT_POST_ERROR,
  REACT_POST_REMOVE_SUCCESS,
  REACT_COMMENT,
  REACT_COMMENT_SUCCESS,
  REACT_COMMENT_REMOVE,
  REACT_COMMENT_REMOVE_SUCCESS,
  REACT_REPLY,
  REACT_REPLY_SUCCESS,
  REACT_REPLY_REMOVE,
  REACT_REPLY_REMOVE_SUCCESS
} from '../Reactions/actions';
import {
  FEED_GET_SUCCESS,
  FEED_UPDATE_NEW_POSTS,
  ON_UPDATE_FEED,
  FEED_UPDATE_BLOCKED_POSTS,
  INIT_FEED
} from '../Feed/actions';
import {
  COMMENT,
  COMMENT_SUCCESS,
  COMMENT_EDIT,
  COMMENT_EDIT_SUCCESS,
  COMMENT_DELETE_SUCCESS,
  REPLY,
  REPLY_SUCCESS,
  REPLY_EDIT,
  REPLY_EDIT_SUCCESS,
  REPLY_DELETE_SUCCESS,
  COMMENT_FULFILLED,
  COMMENT_EDIT_FULFILLED,
  COMMENT_DELETE_FULFILLED,
  REPLY_FULFILLED,
  REPLY_EDIT_FULFILLED
} from '../Comment/actions';
import {
  POST_REPORT,
  COMMENT_REPORT
} from '../Report/actions';

const initialState = {
  posts: {},
  newPostContent: null,
  limit: 5,
  isLoadingPostComments: false,
  error: null,
  checkWarning: false
};

function postReducer(state = initialState, action) {
  switch (action.type) {
    case COMMENT_EDIT:
    case POST_EDIT:
    case REPLY_EDIT:
    case REACT_REPLY:
    case REACT_REPLY_REMOVE:
    case REACT_COMMENT:
    case REACT_COMMENT_REMOVE:
    case GET_POST:
    case COMMENT:
    case REPLY:
    case POST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case POST_CHECK_FULFILLED: {
      return {
        ...state,
        checkWarning: action.payload.status === 202
      };
    }
    case POST_SUCCESS: {
      const posts = Object.assign({}, state.posts);
      const postData = action.payload;

      posts[postData.id] = postData;

      return {
        ...state,
        posts,
        errors: null,
        isLoading: false
      };
    }
    case POST_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isLoading: false
      };
    }
    case POST_EDIT_SUCCESS: {
      const { postId, payload } = action;
      const posts = Object.assign({}, state.posts);

      posts[postId] = { ...posts[postId], ...payload };

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case POST_EDIT_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case REACT_POST_SUCCESS: {
      const { payload, postId } = action;
      const posts = Object.assign({}, state.posts);

      // place reactions to top of list
      posts[postId].reactions = posts[postId].reactions ? posts[postId].reactions.slice() : [];
      posts[postId].reactions.unshift(payload);
      posts[postId].is_reacted = true;

      return {
        ...state,
        posts,
        isLoading: false
      };
    }
    case REACT_POST_REMOVE_SUCCESS: {
      const { payload, postId, userId } = action;

      const posts = Object.assign({}, state.posts);

      posts[postId].reactions = posts[postId].reactions.filter(reaction => {
        return reaction.user_id !== userId;
      })
      posts[postId].is_reacted = false;

      return {
        ...state,
        posts,
        isLoading: false
      };
    }
    // for data being received in an object
    case GET_POST_SUCCESS: {
      const posts = Object.assign({}, state.posts);
      const postData = action.payload;

      postData.hasLoadedAllComments = false;
      // Check if post already exists and compare the number of root-level comments.
      if (posts[postData.id] &&
        posts[postData.id].comment_count) {

        if (posts[postData.id].comment_count >= postData.comment_count) {
          postData.hasLoadedAllComments = true;
        }

        postData.offset = 30;

        if (postData.total_deep_comment_count > 30) {
          postData.comments = posts[postData.id].comments;
          postData.offset = posts[postData.id].offset < posts[postData.id].comment_count ?
            posts[postData.id].offset : posts[postData.id].comment_count;
        }
        posts[postData.id].comments = posts[postData.id].comments.filter((comment) => {
          return !comment.isLocal;
        });
      }

      if (!postData.hasLoadedAllComments && postData.comment_count <= postData.comments.length) {
        postData.hasLoadedAllComments = true;
      }

      posts[postData.id] = postData;

      return {
        ...state,
        posts,
        isLoading: false,
        error: null
      };
    }
    case GET_POST_ERROR: {
      let error;
      if (action.error.status === 403) {
        error = 403;
      } else {
        error = action.error;
      }

      return {
        ...state,
        error
      }
    }
    case POST_DELETE_SUCCESS: {
      const posts = Object.assign({}, state.posts);
      const { postId } = action

      delete posts[postId];

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case POST_DELETE_ERROR: {
      return {
        ...state,
        isLoading: false
      }
    }
    case GET_POST_COMMENTS: {
      return {
        ...state,
        isLoadingPostComments: true
      }
    }
    case GET_POST_COMMENTS_SUCCESS: {
      const posts = Object.assign({}, state.posts);
      const postData = action.payload;

      // append the new comments
      posts[postData.id].comments = posts[postData.id].comments.concat(postData.comments);

      // increment offset if loading new comments from infinite scroll
      if (!posts[postData.id].hasLoadedAllComments) {
        posts[postData.id].offset = posts[postData.id].offset !== undefined ? posts[postData.id].offset + state.limit : 5 + state.limit;
      }

      // if all comments have been loaded, set hasLoadedAllComments to true
      if (posts[postData.id].comments.length >= posts[postData.id].comment_count || postData.comments.length == 0) {
        posts[postData.id].hasLoadedAllComments = true;
      }

      // on ajax refresh, set the comment count to include the new comments added so the ajax offset is correct
      if (posts[postData.id].hasLoadedAllComments === true) {
        posts[postData.id].comment_count = posts[postData.id].comments.length;
      }

      return {
        ...state,
        posts,
        isLoadingPostComments: false
      };
    }
    case GET_POST_COMMENTS_ERROR: {
      return {
        ...state,
        isLoading: false
      }
    }
    case GET_COMMENT_REPLIES_SUCCESS: {
      const posts = Object.assign({}, state.posts);
      const repliesData = action.payload;
      const comment = posts[repliesData.id].comments.find((e) => {
        return e.id == repliesData.comment_id;
      });

      comment.replies.concat(repliesData.replies);
      // If no replies have previously been loaded (so limit may be undefined), set the new offset to equal the limit.
      // Otherwise, just increment the existing offset by the limit.
      comment.offset = comment.offset ? comment.offset + state.limit : state.limit;

      return {
        ...state,
        posts,
        isLoading: false
      };
    }
    case COMMENT_FULFILLED: {
      const post_id = action.request.initialPayload.postId;
      const posts = Object.assign({}, state.posts);

      const comment = action.payload.comment;
      comment.creator = action.payload.user;

      comment.reactions = [];
      comment.replies = [];
      comment.isLocal = true;
      posts[post_id].comments.push(comment);

      // Increment total deep comment count.
      const totalPostDeepCommentCount = posts[post_id].total_deep_comment_count || 0;
      posts[post_id].total_deep_comment_count = totalPostDeepCommentCount + 1;
      const commentCount = posts[post_id].comment_count || 0;
      posts[post_id].comment_count = commentCount + 1;

      return {
        ...state,
        posts
      }
    }
    case COMMENT_EDIT_FULFILLED: {
      const postId = action.payload.comment.post_id;
      const commentId = action.payload.comment.id;

      const posts = Object.assign({}, state.posts);

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === commentId) {
          return { ...comment, ...action.payload.comment };
        }
        return comment;
      });

      return {
        ...state,
        posts
      }
    }
    case REACT_COMMENT_SUCCESS: {
      const { postId, commentId, payload } = action;
      const posts = Object.assign({}, state.posts);

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === commentId) {
          comment.reactions = comment.reactions ? comment.reactions.slice() : [];
          comment.reactions.unshift(payload);
          comment.is_reacted = true;
        }
        return comment;
      });

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case REACT_COMMENT_REMOVE_SUCCESS: {
      const { postId, userId, commentId, payload } = action;
      const posts = Object.assign({}, state.posts);

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === commentId) {
          comment.reactions = comment.reactions.filter(reaction => {
            return reaction.user_id !== userId;
          });
          comment.is_reacted = false;
        }
        return comment;
      });

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case COMMENT_DELETE_FULFILLED: {
      const { postId, commentId } = action.request.initialPayload;
      const posts = Object.assign({}, state.posts);

      let commentReplies = [];

      posts[postId].comments = posts[postId].comments.filter(comment => {
        if (comment.id === commentId) commentReplies = comment.replies;
        return comment.id !== commentId;
      });

      // Decrement total deep comment count.
      const totalPostDeepCommentCount = posts[postId].total_deep_comment_count || 0;
      // Delete the comment and its replies from the count.
      posts[postId].total_deep_comment_count = Math.max(0, totalPostDeepCommentCount - 1 - commentReplies.length);

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case REPLY_FULFILLED: {
      const { comment, user } = action.payload;
      const { postId, parentId } = action.request.initialPayload;
      const posts = Object.assign({}, state.posts);

      const reply = comment;
      reply.creator = user;

      // Insert reply.
      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === parentId) {
          comment.replies = (comment.replies ? comment.replies.slice() : []);
          comment.replies.push(reply);
        }
        return comment;
      });

      // Increment deep comment count.
      const totalPostDeepCommentCount = posts[postId].total_deep_comment_count || 0;
      posts[postId].total_deep_comment_count = totalPostDeepCommentCount + 1;

      return {
        ...state,
        posts,
        isLoading: false
      };
    }
    case REPLY_EDIT_FULFILLED: {
      const payload = action.payload.comment;
      const postId = payload.post_id;
      const parentId = payload.parent_id;
      const commentId = payload.id;

      const posts = Object.assign({}, state.posts);

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === parentId) {
          comment.replies = comment.replies.map(reply => {
            if (reply.id === commentId) {
              return { ...reply, ...payload };
            }
            return reply;
          });
        }
        return comment;
      });

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case REACT_REPLY_SUCCESS: {
      const { commentId, parentId, postId, payload } = action;
      const posts = Object.assign({}, state.posts);
      // get

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === parentId) {
          comment.replies = comment.replies.map(reply => {
            if (reply.id === commentId) {
              reply.reactions = reply.reactions ? reply.reactions.slice() : [];
              reply.reactions.unshift(payload);

              reply.is_reacted = true;
            }
            return reply;
          });
        }
        return comment;
      });

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case REACT_REPLY_REMOVE_SUCCESS: {
      const { postId, parentId, commentId, userId, payload } = action;
      const posts = Object.assign({}, state.posts);
      // get

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === parentId) {
          comment.replies = comment.replies.map(reply => {
            if (reply.id === commentId) {
              reply.reactions = reply.reactions.filter(reaction => {
                return reaction.user_id !== userId;
              });
              reply.is_reacted = false;
            }
            return reply;
          }).slice();
        }
        return comment;
      });

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    case REPLY_DELETE_SUCCESS: {
      const { postId, parentId, commentId } = action;
      const posts = Object.assign({}, state.posts);

      posts[postId].comments = posts[postId].comments.map(comment => {
        if (comment.id === parentId) {
          comment.replies = comment.replies.filter(reply => {
            return reply.id !== commentId;
          })
        }
        return comment;
      });

      // decrementing total deep comment count when deleting reply
      const totalPostDeepCommentCount = posts[postId].total_deep_comment_count || 0;
      posts[postId].total_deep_comment_count = Math.max(0, totalPostDeepCommentCount - 1);

      return {
        ...state,
        posts,
        isLoading: false
      }
    }
    // for data being received in an array of objects [{},{},{}]
    case FEED_GET_SUCCESS: {
      // make data into keyable format for feed entities
      // posts: { key: {data} }
      const posts = Object.assign({}, state.posts);
      const feedData = action.payload;

      feedData.forEach(feedPost => {
        let comments = [];
        if (posts[feedPost.id] && posts[feedPost.id].comments) {
          comments = posts[feedPost.id].comments;
        }
        let comment_count = 0;
        if (posts[feedPost.id] && posts[feedPost.id].comment_count) {
          comment_count = posts[feedPost.id].comment_count;
        }
        posts[feedPost.id] = { ...posts[feedPost.id], ...feedPost }; // keep post.hasLoadedAllComments if it exists
        posts[feedPost.id].comments = comments;
        posts[feedPost.id].comment_count = comment_count;
      })

      return {
        ...state,
        posts,
        isLoading: false
      };
    }
    case FEED_UPDATE_NEW_POSTS: {
      return {
        ...state,
        newPostContent: action.newPostContent
      }
    }
    case ON_UPDATE_FEED: {
      const posts = state.posts;
      const feedData = state.newPostContent;

      feedData.forEach(feedPost => {
        posts[feedPost.id] = feedPost;
      });

      return {
        ...state,
        posts,
        newPostContent: null
      }
    }
    case FEED_UPDATE_BLOCKED_POSTS: {
      const posts = state.posts;
      const { blocked_ids } = action;
      const blocked = blocked_ids.map(obj => obj.id);

      for (let post in posts) {
        if (blocked.indexOf(post) !== -1) {
          delete posts[post.id];
        }
      }

      return {
        ...state,
        posts
      }
    }
    case INIT_FEED:
      return {
        ...initialState
      }
    case POST_REPORT: {
      const { postId } = action;
      const posts = Object.assign({}, state.posts);
      posts[postId].flagged = true;
      return {
        ...state,
        posts
      }
    }
    case COMMENT_REPORT: {
      const { postId, commentId } = action;
      const posts = Object.assign({}, state.posts);
      posts[postId].comments.find(comment => {
        // Check if comment matches ID.
        if (comment.id === commentId) return comment.flagged = true;
        // If not, check if any reply matches ID.
        const reply = comment.replies.find(reply => (reply.id === commentId));
        if (reply) return reply.flagged = true;
      });

      return {
        ...state,
        posts
      }
    }
    default: {
      return {
        ...state,
        isLoading: false
      };
    }
  }
}

export default postReducer;
