import React, { PureComponent } from 'react';

class RibbonIcon extends PureComponent {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none">
        <path
          fill="#EC8A7D"
          fillRule="evenodd"
          d="M29 14.5c0-1.2331-.8889-2.257-2.0603-2.4738.9974-.6494 1.4261-1.9342.957-3.074-.4728-1.1424-1.6857-1.7463-2.852-1.4992.6745-.981.5787-2.3326-.2938-3.2064-.8726-.8712-2.2242-.9658-3.2052-.2938.2472-1.165-.358-2.3754-1.5004-2.8483-1.1398-.4728-2.4234-.0441-3.0727.9545C16.7544.8877 15.7331 0 14.5 0c-1.2369 0-2.2582.8877-2.4776 2.059-.6481-.9986-1.9329-1.4273-3.074-.9545-1.1411.4716-1.745 1.6833-1.4992 2.8483-.981-.672-2.3313-.5774-3.2051.295-.8725.8726-.9671 2.2242-.2938 3.2052-1.165-.2471-2.373.358-2.8458 1.4992-.4728 1.1398-.0466 2.4246.9507 3.0727C.884 12.243 0 13.2669 0 14.5c0 1.2344.8851 2.257 2.0552 2.4751-.9973.6506-1.4235 1.9354-.952 3.0753.4729 1.1398 1.682 1.7437 2.8471 1.4979-.6733.9822-.5775 2.3326.2938 3.2064.8738.8712 2.2229.967 3.2051.295-.2458 1.1638.3594 2.3742 1.5004 2.847 1.1399.4729 2.4247.0442 3.0728-.9544C12.2418 28.1136 13.2656 29 14.5 29c1.2331 0 2.2544-.8864 2.4726-2.0577.6493.9986 1.9329 1.4247 3.074.9544 1.1411-.4728 1.745-1.6832 1.4991-2.8495.981.6745 2.3326.5787 3.2052-.2925.8725-.8738.9683-2.2242.2938-3.2064 1.165.2471 2.3792-.3581 2.852-1.4979.4729-1.1399.0416-2.4247-.957-3.0753C28.1098 16.7582 29 15.7344 29 14.5z"
          clipRule="evenodd"
        />
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 12l-5.25 5L11 15.2143"
        />
      </svg>
    )
  }
}

export default RibbonIcon;
