import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../common/Input';
import Button, { THEME_BLACK } from '../../common/Button';
import { MONTHS_INT } from '../../common/Dropdown/CommonFields';
import JourneyCardGirlbossMoment from './JourneyCardGirlbossMoment';
import JourneyCardWorkExperience from './JourneyCardWorkExperience';
import JourneyCardEducation from './JourneyCardEducation';

import SVG from 'react-inlinesvg';

import CaretDown from '../../../public/assets/icons/CARET-DOWN.svg';
import CaretDownWhite from '../../../public/assets/icons/CARET-DOWN-WHITE.svg';
import CaretUp from '../../../public/assets/icons/CARET-UP.svg';
import CaretUpWhite from '../../../public/assets/icons/CARET-UP-WHITE.svg';

import GirlbossIconBlack from '../../../public/assets/icons/ICON_BLACK-GIRLBOSSMOMENT.svg';
import WorkIconBlack from '../../../public/assets/icons/ICON_BLACK-WORK.svg';
import EducationIconBlack from '../../../public/assets/icons/ICON_BLACK-EDUCATION.svg';

import GirlbossIconWhite from '../../../public/assets/icons/ICON_WHITE-GIRLBOSSMOMENT.svg';
import WorkIconWhite from '../../../public/assets/icons/ICON_WHITE-WORK.svg';
import EducationIconWhite from '../../../public/assets/icons/ICON_WHITE-EDUCATION.svg';


class JourneyCard extends Component {
  static defaultProps = {
    creating:false
  }

  static propTypes = {
    card: PropTypes.object,
    creating: PropTypes.bool
  };

  constructor (props) {
    super(props);
    this.state = {  
      viewing:false,
      card:{}
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { card } = props;
    if (card && card.card_type != state.card.card_type) {
      state.card = card;
    }
    return state;
  }

  renderCard = (card) => {
    if (!card) return null;
    let CardComponent = <Component />;
    switch (card.card_type) {
      case 'moment':
        CardComponent = JourneyCardGirlbossMoment;
        break;
      case 'experience':
        CardComponent = JourneyCardWorkExperience;
        break;
      case 'education':
        CardComponent = JourneyCardEducation;
        break;
      default:
        return null;
    }
    return <CardComponent
      card={card}
      enableViewing={this.enableViewing}
      viewing={this.state.viewing}
    />;
  }

  enableViewing = e => {
    if (!this.state.viewing) {
      this.setState({viewing:true});
    } else {
      this.doneViewing(e);
    }
  }

  doneViewing = e => {
    e.stopPropagation();
    if (this.state.viewing) {
      this.setState({viewing:false});
    }
  }

  render() {
    const { className } = this.props;
    const { card, viewing } = this.state;

    return (
      <div className={classNames("JourneyCard", className)}>
        <div onClick={this.enableViewing} className={`JourneyCard__dropdown-icon JourneyCard__dropdown-icon-${card.card_type}`}>
          {
            viewing &&
            <SVG src={card.card_type == 'experience' ? CaretUpWhite : CaretUp} /> ||
            <SVG src={card.card_type == 'experience' ? CaretDownWhite : CaretDown} />
          }
        </div>
        {
          this.renderCard(card)
        }
      </div>
    )
  }
};

export default JourneyCard;