import React, { Component } from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';
import SVG from 'react-inlinesvg';
import fetchAPI from '../../services/APIService';

import PostList from '../../components/PostList';
import PostFeedInput from '../Post/PostFeedInput';
import PostFeedInputFocus from '../Post/PostFeedInputFocus';
import CollectivesList from '../Collectives/CollectivesList';
import UserList from '../../components/UserList';
import Button, { THEME_LIGHT_BLUE } from '../../common/Button';

import ProfilePersonalityCardZodiac from '../Profile/ProfilePersonalityCards/ProfilePersonalityCardZodiac';
import cloneDeep from 'lodash.clonedeep';

import closeSVG from '../../../public/assets/icons/CLOSE.svg';
import background from '../../../public/assets/crowdfunding/background.jpg';

import { modalOpen } from '../../store/Modal/actions';
import {
  POST_MODAL,
  CONTACT_REQUEST_RESPONSE_MODAL,
  DAILY_HOROSCOPE_MODAL,
  FEED_HOROSCOPES_MODAL,
  FILTER_CONTENT_TAGS_MODAL,
} from '../ModalManager/ModalTypes';

import filterIcon from '../../../public/assets/icons/FILTER.svg';

import { routes, routeWithParams } from '../../config/routes.js';
import {
  feedGet,
  initFeed,
  feedGetLoading,
  getFeedWithEntities,
  feedUpdate,
  onUpdateFeed
} from '../../store/Feed/actions';
import {
  reactPost,
  reactPostRemove
} from '../../store/Reactions/actions';
import {
  setCurrentCollectiveSlug
} from '../../store/Collectives/actions'

import { FEED_LIST_LENGTH } from '../MainFeed';

import './Feed.scss';

class Feed extends Component {
  static propTypes = {
    feed: PropTypes.arrayOf(PropTypes.shape({
      body: PropTypes.string,
      title: PropTypes.string.isRequired,
      priority: PropTypes.number,
      comments: PropTypes.arrayOf(PropTypes.shape({
        creator: PropTypes.shape({
          id: PropTypes.number.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
          headline: PropTypes.string,
          username: PropTypes.string.isRequired
        }),
        body: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        is_reacted: PropTypes.bool,
        comments: PropTypes.shape({})
      })),
      created_at: PropTypes.string.isRequired,
      creator: PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        headline: PropTypes.string,
        username: PropTypes.string.isRequired
      }),
      id: PropTypes.number.isRequired,
      is_reacted: PropTypes.bool,
      slug: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      user_id: PropTypes.number.isRequired
    }))
  };

  constructor(props) {
    super(props);

    this.checkForContactRequestModal();

    // prevents re-fetching feed after viewing a post
    // refetches if it was on a post from a collective page
    if((props.pathname && !props.pathname.includes('/post/') && !props.currentSlug) || props.currentSlug !== null || !props.pathname) {
      this.props.initFeed();
      this.props.feedGetLoading();
      this.props.feedGet();
      this.props.setCurrentCollectiveSlug(null);
    }

    this.daliyHoroscope = React.createRef();
    this.state = {
      rerender:false
    };
  }


  componentDidUpdate = prevProps => {
    // makes sure the horoscope API finishes before calling viewDailyHoroscope()

    // [Uncomment to enable daily horoscopes]
    //
    // if (!prevProps.allHoroscopes && this.props.allHoroscopes && location.hash === "#daily-horoscope") {
    //   this.horoscopeCTA();
    // }
  }

  setHoroscopeRef = ref => {
    this.daliyHoroscope = ref;

    if (location.hash === '#daily-horoscope') {
      window.scrollTo(0, this.daliyHoroscope.offsetTop)
      this.horoscopeCTA();
    }
  }

  horoscopeCTA = () => {
    const zodiacCard = this.getCardByType('zodiac');

    if (Object.keys(zodiacCard).length === 0) {
      this.addHoroscopeToFeed();
    } else {
      if (this.props.allHoroscopes) {
        this.viewDailyHoroscope();
      }
    }
  }
  checkForContactRequestModal = () => {
    const { modalOpen, user } = this.props;

    let params = qs.parse(this.props.location.search);

    if (params.contact) {
      fetchAPI('getContactRequest', { id: params.contact }).then((resp) => {
        if (resp.success) {
          // if (resp.contact_request.accepted && resp.contact_request.sendbird_group_id) {
          //   this.props.history.push(`/messaging/${resp.contact_request.sendbird_group_id}`);
          // } else {
          if (!resp.contact_request.accepted) {
            modalOpen({
              modal: CONTACT_REQUEST_RESPONSE_MODAL,
              contactRequest: resp.contact_request,
              sender: resp.contact_request.User,
              receiver: user
            });
          }
        }
      });
    }
  }

  openPostModal = e => {
    this.props.modalOpen({
      modal: POST_MODAL,
      position: 'belowNav',
      implicitExit: false
    });

    e.target.blur();
  }

  getCardByType = (type) => {
    const { user } = this.props;
    let card = {};
    for (let i = 0; i < user.cards.length; i++) {
      if (user.cards[i].card_type === type) {
        card = cloneDeep(user.cards[i]);
      }
    }
    return card;
  }

  getFeaturedPostCount = () => {
    const { feed } = this.props
    for (var i = 0; i < feed.length; i++) {
      if (!feed[i].priority) {
        break;
      }
    }
    return i;
  }

  viewDailyHoroscope = () => {
    const zodiacCard = this.getCardByType('zodiac');
  
    this.props.modalOpen({
      modal: DAILY_HOROSCOPE_MODAL,
      locationHash: 'daily-horoscope',
      whiteExitButton: true,
      sign: zodiacCard ? zodiacCard.card_data.sign : undefined,
      allHoroscopes: this.props.allHoroscopes,
      customOverlay: '#193952'
    });
  }

  renderFilterButton = () => {
    const { contentTags } = this.props;

    if (this.props.viewportWidth < 1024) {
      return (
        <Button
          className="Feed__filter-btn"
          pill
          noOutline
          onClick={this.openFilterTagsModal}
        >
          {contentTags.length > 0 ? <span className="Feed__filter-btn--count">{contentTags.length}</span> : <img src={filterIcon} />}
          Filters
        </Button>
      )
    }
  }

  openFilterTagsModal = () => {
    this.props.modalOpen({
      modal: FILTER_CONTENT_TAGS_MODAL
    });
  }

  addHoroscopeToFeed = () => {
    this.props.modalOpen({
      modal: FEED_HOROSCOPES_MODAL,
      horoscopes: this.props.allHoroscopes,
      user: this.props.user,
      whiteExitButton: true,
      customOverlay: '#193952'
    });
  }

  hideCrowdfunding = (e) => {
    localStorage.setItem('crowdfunding-popup', 'hide');
    this.setState({ rerender: true });
    e.stopPropagation();
    e.preventDefault();
  }

  renderContentBlocks = index => {
    const zodiacCard = this.getCardByType('zodiac');
    const featuredPostCount = this.getFeaturedPostCount();
    const unfeaturedPostIndex = index - featuredPostCount;

    const {
      recommendedCollectives,
      userCollectives,
      industryMembers,
      localMembers,
      viewAllCollectives,
      viewAllMembers,
      user
    } = this.props;


    let recommendedCollectivesList = {};
    Object.keys({ ...recommendedCollectives })
      .slice(0, FEED_LIST_LENGTH)
      .map(key => recommendedCollectivesList[key] = recommendedCollectives[key]);

    let userCollectivesList = {};
    Object.keys({ ...userCollectives })
      .slice(0, FEED_LIST_LENGTH)
      .map(key => userCollectivesList[key] = userCollectives[key]);

    if (unfeaturedPostIndex === 2 && !localStorage.getItem('crowdfunding-popup')) {
      return (
        <div className="mobile-only" style={{
          color: 'white',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          marginBottom: '8px'
        }}>
          <a href="https://ifundwomen.com/girlboss?ref=girlboss-community" target="_blank" style={{ textDecoration: 'none', color:'white' }}>
            <div style={{ padding: '20px', backgroundColor: 'rgba(0,0,0,.3)' }}>
              <h3 className="Feed__content-block-heading">Crowdfund Your Biz</h3>
              <p style={{ marginBottom: '8px' }}>Level up your biz with cold hard cash - and coaching. Everything you need to achieve your biggest dreams.</p>
              <Button theme={THEME_LIGHT_BLUE} pill>Start Here</Button>
            </div>
          </a>
          <div
            className={'Modal__icon--x Modal__icon--x--white'}
            style={{ zIndex: 0 }}
            onClick={this.hideCrowdfunding}
          >
            <SVG src={closeSVG} />
          </div>
        </div>
      )
    }

    // [Uncomment to enable daily horoscopes]
    //
    // if (unfeaturedPostIndex === 4 && (Object.keys(zodiacCard).length > 0)) {
    //   return (
    //     <div className="Feed__content-block" id="daily-horoscope" ref={this.setHoroscopeRef}>
    //       <h3 className="Feed__content-block-heading">Daily Horoscopes</h3>
    //       <ProfilePersonalityCardZodiac
    //         key={'zodiac'}
    //         isEditing={false}
    //         edit={null}
    //         data={zodiacCard}
    //         viewDailyHoroscope={this.viewDailyHoroscope}
    //         className='ProfilePersonalityCard__full-width'
    //         displayFromFeed
    //       />
    //     </div>
    //   )
    // }
    // else if ((unfeaturedPostIndex == 4) && Object.keys(zodiacCard).length === 0) {
    //   return (
    //     <div className="Feed__content-block" id="daily-horoscope" ref={this.setHoroscopeRef}>
    //       <h3 className="Feed__content-block-heading">Daily Horoscopes</h3>
    //       <div className="Feed__zodiac-card">
    //         <h4>Daily Horoscope</h4>
    //         <p>Select your sign and see your daily reading.</p>

    //         <Button onClick={this.addHoroscopeToFeed} className="Feed__zodiac-card--cta" pill>Add to Profile</Button>
    //       </div>
    //     </div>
    //   )
    // }

    if (unfeaturedPostIndex == 8) {
      return (
        <div className="Feed__content-block mobile-only">
          <CollectivesList
            title="Collectives for you"
            collectives={recommendedCollectivesList}
            listType="recommended"
            showPostNumber={false}
            showAvatar
          />
          {Object.keys(recommendedCollectives).length > 0 && (
            <Link to={`/groups`}><Button className="MainFeed__left--cta" theme={THEME_LIGHT_BLUE} pill noOutline>Browse Collectives</Button></Link>
          )}
        </div>
      )
    } else if (unfeaturedPostIndex == 12) {
      return (
        <div className="Feed__content-block mobile-only">
          <CollectivesList
            title="Your Collectives"
            collectives={userCollectivesList}
            listType="recommended"
            showPostNumber={false}
            showAvatar
            isMember
          />

          {Object.keys(userCollectives).length > FEED_LIST_LENGTH && (
            <Button onClick={() => viewAllCollectives('user')} className="MainFeed__left--cta" theme={THEME_LIGHT_BLUE} pill noOutline>View More</Button>
          )}
        </div>
      )
    } else if (unfeaturedPostIndex == 16) {
      return (
        <div className="Feed__content-block mobile-only">
          <UserList
            users={industryMembers.slice(0, FEED_LIST_LENGTH)}
            title="Members in your industry"
          />
          {user.industry && (industryMembers.length > FEED_LIST_LENGTH) && (
            <Button onClick={() => viewAllMembers('industry')} className="MainFeed__left--cta" theme={THEME_LIGHT_BLUE} pill noOutline>View More</Button>
          )}
        </div>
      )
    } else if (unfeaturedPostIndex == 20) {
      return (
        <div className="Feed__content-block mobile-only">
          <UserList
            users={localMembers.slice(0, FEED_LIST_LENGTH)}
            title="Members near you"
          />
          {user.location && (localMembers.length > FEED_LIST_LENGTH) && (
            <Button onClick={() => viewAllMembers('local')} className="MainFeed__left--cta" theme={THEME_LIGHT_BLUE} pill noOutline>View More</Button>
          )}
        </div>
      )
    }
  }

  render() {
    const { contentTags, feed, isLoading, hasLoadedAllPosts, newFeedEntities, onUpdateFeed, feedUpdate, viewportWidth } = this.props;
    const { rerender } = this.state;

    return (
      <div className="Feed">
        <Helmet title="Girlboss" />
        <PostFeedInput handleFocus={this.openPostModal} />

        {
          viewportWidth > 1023 &&
          <button className="Feed__filter-desktop" onClick={this.openFilterTagsModal}>
            {contentTags.length > 0 ? <span className="Feed__filter-btn--count">{contentTags.length}</span> : <SVG src={filterIcon} />}
            Filters
            </button>
        }

        <PostList
          key={"rerender-" + rerender}
          feed={feed}
          ajaxUpdate={feedUpdate}
          isLoading={isLoading}
          hasLoadedAllPosts={hasLoadedAllPosts}
          loadMore={this.props.feedGet}
          updatePostList={onUpdateFeed}
          newPostEntities={newFeedEntities}
          renderExtraContent={this.renderContentBlocks}
        />

        {this.renderFilterButton()}
      </div>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    feed: getFeedWithEntities(state),
    isLoading: state.Feed.isLoading,
    hasLoadedAllPosts: state.Feed.hasLoadedAllPosts,
    totalPostCount: state.Feed.totalPostCount,
    notifications: state.Notifications.notifications,
    allHoroscopes: state.Profile.allHoroscopes,
    user: state.User.user,
    newFeedEntities: state.Feed.newFeedEntities,
    contentTags: state.Feed.contentTags,
    pathname: state.App.pathname,
    recommendedCollectives: state.Collectives.recommendedCollectives,
    userCollectives: state.Collectives.userCollectives,
    currentSlug: state.Collectives.currentSlug
  }
};

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args)),
    feedGet: () => dispatch(feedGet()),
    feedGetLoading: () => dispatch(feedGetLoading()),
    initFeed: () => dispatch(initFeed()),
    reactPost: (args) => dispatch(reactPost(args)),
    reactPostRemove: (args) => dispatch(reactPostRemove(args)),
    feedUpdate: () => dispatch(feedUpdate()),
    onUpdateFeed: () => dispatch(onUpdateFeed()),
    setCurrentCollectiveSlug: (args) => dispatch(setCurrentCollectiveSlug(args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Feed));
