import { put, select } from 'redux-saga/effects';
import camelCase from 'lodash/camelCase';

const NULL_REQUEST_STATE = { status: STATUS_INIT, initial: true, startTime: 0, endTime: 0 };

export const DEFAULT_REQUEST_KEY = '*';

//-- Modes --//

export const MODE_LATEST = 'latest';
export const MODE_LEADING = 'leading';
export const MODE_CONCURRENT = 'concurrent';

//-- Statuses --//

export const STATUS_INIT = 'INIT';
export const STATUS_PENDING = 'PENDING';
export const STATUS_FULFILLED = 'FULFILLED';
export const STATUS_REJECTED = 'REJECTED';

//-- Actions --//

export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_REQUEST_TYPE = 'RESET_REQUEST_TYPE';

export function resetRequest(type, key) {
  return {
    type: RESET_REQUEST,
    payload: { type: getRequestType(type), key }
  };
}

export function resetRequestType(type) {
  return {
    type: RESET_REQUEST_TYPE,
    payload: { type: getRequestType(type) }
  };
}

//-- Action Creators --//

export function createRequestAction(type, payload, options) {
  return {
    type,
    payload,
    request: {
      type,
      timestamp: Date.now(),
      ...options
    }
  };
}

export function createPendingAction(actionType, payload, options) {
  return createRequestAction(actionType, payload, {
    mode: MODE_LATEST,
    status: STATUS_PENDING,
    ...options
  });
}

export function createFulfilledAction(initialAction, payload, options) {
  const requestParams = getRequestParams(initialAction);
  return createRequestAction(`${initialAction.type}_${STATUS_FULFILLED}`, payload, {
    status: STATUS_FULFILLED,
    initialPayload: initialAction.payload,
    ...requestParams,
    ...options
  });
}

export function createRejectedAction(initialAction, payload, options) {
  const requestParams = getRequestParams(initialAction);
  const action = createRequestAction(`${initialAction.type}_${STATUS_REJECTED}`, payload, {
    status: STATUS_REJECTED,
    initialPayload: initialAction.payload,
    ...requestParams,
    ...options
  });
  action.error = true;
  return action;
}

function getRequestParams(identifier) {
  if (typeof identifier === 'object') {
    if (identifier.request) {
      identifier = identifier.request;
    }
    return { type: identifier.type, key: identifier.key };
  }
  return { type: identifier };
}

/**
 * Example usage:
 
 * export const {
 *   LOAD_DATA,
 *   LOAD_DATA_FULFILLED,
 *   LOAD_DATA_REJECTED,
 *   loadData
 * } = defineRequest('LOAD_DATA');
 * 
 * export const {
 *   LOAD_DATA,
 *   LOAD_DATA_FULFILLED,
 *   LOAD_DATA_REJECTED,
 *   loadData
 * } = defineRequest('LOAD_DATA', { mode: MODE_LEADING, cleanup: true }, id => ({ key: id, payload: { id } }));
 */
export function defineRequest(actionType, options, pendingActionCallback) {
  if (typeof options === 'function') {
    pendingActionCallback = options
    options = undefined;
  }

  if (!pendingActionCallback) {
    pendingActionCallback = defaultPendingActionCallback;
  }

  const fulfilledType = `${actionType}_${STATUS_FULFILLED}`;
  const rejectedType = `${actionType}_${STATUS_REJECTED}`;

  const pendingActionCreator = (...args) => {
    const { key, payload = key, ...rest } = pendingActionCallback(...args);
    if (key !== undefined) rest.key = key;
    return createPendingAction(actionType, payload, { ...options, ...rest });
  };

  pendingActionCreator.__requestType = actionType;

  return {
    [actionType]: actionType,
    [fulfilledType]: fulfilledType,
    [rejectedType]: rejectedType,
    [camelCase(actionType)]: pendingActionCreator
  };
}

function defaultPendingActionCallback(payload) {
  return { payload };
}

//-- Request Class --//

export class Request {
  constructor(state) {
    this.state = state;
  }

  get status() {
    return this.state.status;
  }

  get initial() {
    return this.state.initial;
  }

  get unresolved() {
    return this.status === STATUS_INIT || this.pending;
  }

  get pending() {
    return this.status === STATUS_PENDING;
  }

  get fulfilled() {
    return this.status === STATUS_FULFILLED;
  }

  get rejected() {
    return this.status === STATUS_REJECTED;
  }

  get resolved() {
    return this.fulfilled || this.rejected;
  }

  get startTime() {
    return this.state.startTime;
  }

  get endTime() {
    return this.state.endTime;
  }

  get timestamp() {
    return this.endTime || this.startTime;
  }

  get duration() {
    return this.status === STATUS_PENDING ? 0 : this.endTime - this.startTime;
  }

  get error() {
    return this.state.error;
  }
}

//-- Configure middleware, reducer, selector --//

export default function configureRequests(initialState = {}) {
  let selectorCache = {};
  let referencedSelectorCacheKeys = [];

  return {
    requestsMiddleware: store => next => action => {
      if (action.request && action.request.status === STATUS_PENDING) {
        const { type, key = DEFAULT_REQUEST_KEY, mode, throttle } = action.request;
        const request = (store.getState().requests[type] || {})[key];

        if (request) {
          if (throttle && request.status !== STATUS_REJECTED && Date.now() - request.startTime < throttle) {
            return;
          }

          if (mode === MODE_LEADING && request.status === STATUS_PENDING) {
            return;
          }
        }
      }

      return next(action);
    },

    /**
      * Example usage:
      *
      * function* loadDataSaga(action) {
      *   yield sagaRequest(action, call(sendApiRequest, { id: action.payload }));
      * }
      *
      * function* loadDataSaga(action) {
      *   yield sagaRequest(action, function*() {
      *     const response = yield call(sendApiRequest, { id: action.payload });
      *     return response;
      *   }, {
      *     *fulfilled(result) {
      *       yield put(afterFulfilled);
      *     },
      *     *rejected(error) {
      *       yield put(afterRejected);
      *     }
      *   });
      * }
    */
    *sagaRequest(action, effect, hooks = {}) {
      const { type, key = DEFAULT_REQUEST_KEY, mode, cleanup } = action.request;
      const { fulfilled, rejected } = hooks;
      const { requests } = yield select();
      const initialRequest = (requests[type] || {})[key];
      let resolution;

      try {
        if (typeof effect === 'function') {
          effect = effect();
        }
        const result = yield effect;
        resolution = function*() {
          yield put(createFulfilledAction(action, result, { cleanup }));
          if (fulfilled) yield fulfilled(result);
          return result;
        };
      } catch (error) {
        resolution = function*() {
          yield put(createRejectedAction(action, error));
          if (rejected) yield rejected(error);
        }
      } finally {
        const { requests } = yield select();
        const request = (requests[type] || {})[key];

        if (mode === MODE_CONCURRENT || request === initialRequest) {
          yield resolution();
        }
      }
    },

    requestsReducer(state = initialState, action) {
      if (action.type === RESET_REQUEST) {
        const { type, key } = action.payload;
        state = deleteRequest(state, type, key);
      } else if (action.type === RESET_REQUEST_TYPE) {
        const { type } = action.payload;
        state = { ...state };
        delete state[type];
      } else if (action.request) {
        const { type, key = DEFAULT_REQUEST_KEY, status, timestamp = Date.now(), cleanup } = action.request;
        const requests = state[type] || {};
        const prevRequest = requests[key];
        const request = {
          status,
          initial: !prevRequest || (prevRequest.initial && prevRequest.status === STATUS_PENDING)
        };

        if (status === STATUS_PENDING) {
          request.startTime = timestamp;
          request.endTime = 0;

          if (prevRequest && prevRequest.error) {
            request.error = prevRequest.error;
          }
        } else {
          if (status === STATUS_FULFILLED && cleanup) {
            return deleteRequest(state, type, key);
          }

          request.startTime = prevRequest ? prevRequest.startTime : timestamp;
          request.endTime = timestamp;

          if (status === STATUS_REJECTED) {
            request.error = action.payload;
          }
        }

        state = { ...state, [type]: { ...requests, [key]: request } };
      } else {
        return state
      }

      // Cleanup selector cache to prevent memory leaks.
      const nextSelectorCache = {};
      for (let cacheKey of referencedSelectorCacheKeys) {
        nextSelectorCache[cacheKey] = selectorCache[cacheKey];
      }
      selectorCache = nextSelectorCache;
      referencedSelectorCacheKeys = [];

      return state;
    },

    selectRequest(state, type, key = DEFAULT_REQUEST_KEY) {
      type = getRequestType(type);

      const cacheKey = `${type}@${key}`;
      let requestState = (state.requests[type] || {})[key];
      let request = selectorCache[cacheKey];

      if (!request) {
        referencedSelectorCacheKeys.push(cacheKey);
      }

      if (!request || request.state !== requestState) {
        request = selectorCache[cacheKey] = new Request(requestState || NULL_REQUEST_STATE);
      }

      return request;
    }
  }
}

function getRequestType(type) {
  if (typeof type === 'function') {
    type = type.__requestType;
  }
  if (type == null) {
    throw new Error("Request type is required.");
  }
  return type;
}

function deleteRequest(state, type, key = DEFAULT_REQUEST_KEY) {
  if (!state[type] || !state[type][key]) return state;
  const requests = { ...state[type] };
  delete requests[key];
  return { ...state, [type]: requests };
}
