import React, { PureComponent } from 'react';

class GBetaMobileLogo extends PureComponent {
  render() {
    return (
      <svg width="60" height="35" xmlns="http://www.w3.org/2000/svg">
        <title>Girlboss Beta</title>
        <g fill="none" fillRule="evenodd">
          <text
            fontFamily="HelveticaNeue-Medium, Helvetica Neue"
            fontSize="12"
            fontWeight="400"
            fill="#5847e1"
            transform="translate(0 -3)"
          >
            <tspan x="23" y="16">
              beta
            </tspan>
          </text>
          <path
            d="M22.254 18.969A2.818 2.818 0 0 1 25 21.834a2.818 2.818 0 0 1-2.838 2.774 2.818 2.818 0 0 1-1.94-4.835 2.823 2.823 0 0 1 2.032-.804zm-8.968-3.074h4.478v10.219a8.881 8.881 0 0 1-6.784 8.636 8.865 8.865 0 0 1-9.975-4.575l3.685-2.223a4.466 4.466 0 0 0 8.6-1.694v-2.651A8.867 8.867 0 0 1 1.965 21.47 8.903 8.903 0 0 1 2.29 9.93 8.866 8.866 0 0 1 13.717 8.44l-2.18 4.042a4.468 4.468 0 0 0-5.564.09 4.487 4.487 0 0 0-1.173 5.45 4.472 4.472 0 0 0 8.486-1.993v-.133z"
            fill="#2D2D2D"
            fillRule="nonzero"
          />
        </g>
      </svg>
    )
  }
}

export default GBetaMobileLogo;
