import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import replaceHTMLChars from '../../utils/replaceHTMLChars';

import UserActions from '../../containers/UserActions';
import ProfilePhoto from '../ProfilePhoto';

import Button, { THEME_BLUE, THEME_WHITE } from '../../common/Button';
import Input, { THEME_TRANSPARENT } from '../../common/Input';
import crownIcon from '../../../public/assets/icons/CROWN.svg';

import {
  GTMpushDataLayerEvent,
  GTM_POST_VIEWMORE,
  GTM_COMMENT_VIEWMORE,
  GTM_REPLY_VIEWMORE
} from '../../lib/GoogleTagManager';

import './UserCommentWithBody.scss';
import UserTagParser from '../UserTagParser';
import InputWithUserTagging from '../../common/Input/InputWithUserTagging';

export const TYPE_POST = 'post';
export const TYPE_COMMENT = 'comment';
export const TYPE_REPLY = 'reply';

export const MAX_WORD = 125;
export const MAX_WORD_INCREMENT = 5000;

function sliceBody(text, stop) {
  return text.slice(0, stop);
}

class UserCommentWithBody extends PureComponent {
  static propTypes = {
    alt: PropTypes.string,
    actions: PropTypes.shape({}),
    body: PropTypes.string,
    bodyClassName: PropTypes.string,
    className: PropTypes.string,
    cancelEdit: PropTypes.func,
    name: PropTypes.string,
    nameClassName: PropTypes.string,
    headline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    isEditing: PropTypes.bool,
    photo: PropTypes.string,
    route: PropTypes.string,
    saveEdit: PropTypes.func,
    subheadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    title: PropTypes.string,
    titleClassName: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.oneOf([
      'comment',
      'post'
    ])
  };

  constructor(props) {
    super(props);

    this.state = {
      sliceCount: MAX_WORD
    }
  }

  getBody = () => {
    const { variant, userTags } = this.props;
    let body = replaceHTMLChars(this.props.body);

    if (!body) return null;

    return (
      <UserTagParser body={body} tags={userTags} />
    );
  }

  goToProfile = () => {
    this.props.history.push(`/${this.props.username}`);
  }

  render() {
    const {
      alt,
      actions,
      body,
      bodyClassName,
      cancelEdit,
      className,
      userId,
      contentId,
      headline,
      isEditing,
      name,
      photo,
      route,
      readOnly,
      saveEdit,
      subheadline,
      title,
      titleClassName,
      type,
      username,
      variant,
      currentUserId,
      showAdminBadge
    } = this.props;

    return (
      <div
        className={classNames(
          "UserCommentWithBody",
          className,
          {
            'UserCommentWithBody__container--reply': type === TYPE_REPLY,
          },
        )}
      >
        {
          (title && readOnly) && (
            <h2 className={titleClassName}>{title}</h2>
          )
        }
        {
          (title && !readOnly) && (
            <Link to={route}>
              <h2 className={titleClassName}>{title}</h2>
            </Link>
          )
        }
        <div className="UserCommentWithBody__container">
            <div className="UserCommentWithBody__container--photo">
                <ProfilePhoto
                    photo={photo}
                    alt={name}
                    onClick={this.goToProfile}
                />
            </div>
          
            <div className="UserCommentWithBody__container--content">
                <span className="UserCommentWithBody__container--content name" onClick={this.goToProfile}>{name}</span> 
                {showAdminBadge && <span className="UserCommentWithBody__container--content admin-badge">
                  <SVG src={crownIcon} />
                  Founder
                </span>}
                {!isEditing ? this.getBody() : null}
            </div>
        </div>
        {
          isEditing
          ? (
            <form onSubmit={saveEdit}>
              <InputWithUserTagging
                className="UserCommentWithBody__textarea--edit"
                component="textarea"
                theme={THEME_TRANSPARENT}
                onChange={this.props.handleOnChange}
                value={body}
                maxLength={2500}
              />
              <div className="UserCommentWithBody__textarea--buttons">
                <Button
                  className="UserCommentWithBody__textarea--button"
                  onClick={saveEdit}
                  type="submit"
                  noOutline
                  pill
                  theme={THEME_BLUE}
                >
                  Save
                </Button>
                <Button
                  className="UserCommentWithBody__textarea--button"
                  onClick={cancelEdit}
                  noOutline
                  pill
                  theme={THEME_WHITE}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )
          : null
        }
      </div>
    )
  }
}

export default withRouter(UserCommentWithBody);
