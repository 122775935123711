import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './JourneyCardModal.scss';

const MONTHS_ABBREVIATED = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

class JourneyCardViewMoreModal extends Component {
  static propTypes = {
    content:PropTypes.object,
    label:PropTypes.string,
    modalClass:PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  render() {

    const { content, children, label, modalClass } = this.props;

    return (
      <div className={`journey-card-view-more-modal-container ${modalClass}`} ref={this.setRef}>
        <div className="journey-card-modal">
          {children}
          <div className="journey-card-headline">
            {label}
          </div>
          {content}
        </div>
      </div>
    )
  }
}

export default JourneyCardViewMoreModal;
