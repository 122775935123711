import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GlobalSearch from '../../GlobalSearch';

import './GlobalSearchModal.scss';

class GlobalSearchModal extends Component {
  static propTypes = {
    
  };

  constructor(props) {
    super(props);
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  render() {
    return (
      <div className="GlobalSearchModal__container" ref={this.setRef}>
        {this.props.children}
        <div className="GlobalSearchModal__inner">
          <GlobalSearch user={this.props.user} close={this.props.close} />
        </div>
      </div>
    )
  }
}

export default GlobalSearchModal;
