import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Input from '../../../common/Input';

import {
  GTMpushDataLayerEvent,
  GTM_POST_CLICK_CREATE_FIELD
} from '../../../lib/GoogleTagManager';

import '../Post.scss';

class PostFeedInput extends Component {
  static propTypes = {
    handleFocus: PropTypes.func.isRequired
  };

  handleFocus = (e) => {
    this.props.handleFocus(e);

    GTMpushDataLayerEvent({
      event: GTM_POST_CLICK_CREATE_FIELD
    });
  }

  render() {
    const { handleFocus, className } = this.props;
    
    return (
      <div className={classNames("PostFeedInput", className)} onClick={ handleFocus }>
        <div className="PostFeedInput__input">
          <p>Join the conversation</p>
        </div>
        <div className="PostFeedInput__button">Post</div>
      </div>
    )
  }
}

export default PostFeedInput;
