import {
  MODAL_OPEN,
  MODAL_CLOSE
} from './actions';
import {
  POST_DELETE,
  POST_DELETE_SUCCESS,
} from '../Post/actions';
import {
  COMMENT_DELETE,
  COMMENT_DELETE_SUCCESS,
  REPLY_DELETE,
  REPLY_DELETE_SUCCESS,
  COMMENT_DELETE_ERROR
} from '../Comment/actions';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';

const initialState = {};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case POST_DELETE:
    case COMMENT_DELETE:
    case REPLY_DELETE:
      return { ...state, isDeleting: true };
    case POST_DELETE_SUCCESS:
    case COMMENT_DELETE_SUCCESS:
    case REPLY_DELETE_SUCCESS:
    case COMMENT_DELETE_ERROR:
      return { ...state, isDeleting: false };
    case MODAL_OPEN:
      return { ...state, modalOpen: action.payload };
    case MODAL_CLOSE:
      clearAllBodyScrollLocks();
      return { ...state, modalOpen: null };
    default:
      return state;
  }
}

export default modalReducer;
