import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import UserList from '../../../components/UserList';
import Button, { THEME_NAVY, THEME_LIGHT_BLUE, THEME_BLUE } from '../../../common/Button'; 
import Card from '../../../components/Card';
import PostLoadingScreen from '../../../components/PostLoadingScreen';

import {
  feedGet,
  getFeedWithEntities,
  feedUpdate,
  onUpdateFeed
} from '../../../store/Feed/actions';
import {
  reactPost,
  reactPostRemove
} from '../../../store/Reactions/actions';
import { MOBILE_USER_LIST_LENGTH } from './index';

import PostList from '../../../components/PostList';

class CollectiveFeed extends Component {
  state = {
    viewportWidth: null
  };

  componentDidMount = () => {
    this.state.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    window.addEventListener("resize", this.updateViewportSize);
  }

  updateViewportSize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewportSize);
  }

  getCard = type => {
    if (type === 'signup') {
      return (
        <Card className="CollectiveFeed__signup-card" backgroundColor="#FDF1E5" key="signup-block">
          <h1>Like what you see? Sign up to keep the conversation going.</h1>
          <Link to={'/signup'}>
            <Button className="CollectiveFeed__signup-card--cta" theme={THEME_NAVY} pill noOutline>Join</Button>
          </Link>
        </Card>
      )
    } else if (type === 'exclusive') {
      return (
        <div>
          <Card className="CollectiveFeed__exclusive-card" backgroundColor="#F7F7F7" key="signup-block">
            <h1>This is a closed group. You need to be invited to participate.</h1>
            <h1>Head back to the Collectives page to discover other conversations you can jump in on.</h1>

            <Link to={'/groups'}>
              <Button className="CollectiveFeed__exclusive-card--cta" autoWidth theme={THEME_BLUE} pill noOutline>Browse Collectives</Button>
            </Link>
          </Card>
          <PostLoadingScreen />
          <PostLoadingScreen />
        </div>
      )
    }
  }

  renderDesktopContentBlocks = index => {
    let signupPrompt = null;
    if (!this.props.isLoggedIn) {
      signupPrompt = this.getCard('signup');
    }

    let exclusiveMessage = null;
    if (this.props.limitFeed) {
      exclusiveMessage = this.getCard('exclusive')
    }
    if (index === undefined && this.props.limitFeed) {
      return exclusiveMessage;
    } else if (index === undefined || index === this.props.feed.length - 1) {
      return signupPrompt;
    }
  }

  renderMobileContentBlocks = index => {
    let memberList = [];
    let adminList = [];
    if (this.props.collective.members) {
      memberList = this.props.collective.members
        .filter(member => member.role === 'subscriber')
        .slice(0, MOBILE_USER_LIST_LENGTH)
        .map(member => member.user);
      adminList = this.props.collective.members
        .filter(member => member.role === 'admin' || member.role === 'owner')
        .slice(0, MOBILE_USER_LIST_LENGTH)
        .map(member => member.user);
    };

    const adminBlock = (
      <div className="CollectiveFeed__content-block" key='admin-block'>
        <UserList
          users={adminList}
          title="Founders"
        />

        { this.props.showViewAllAdmins && (
          <Button
            theme={THEME_LIGHT_BLUE}
            className="Collective__members-view-all"
            pill
            noOutline
            onClick={() => this.props.viewAllMembers('admin')}
          >
            View More
          </Button>
        )}
      </div>
    );
    const memberBlock = (
      <div className="CollectiveFeed__content-block" key='member-block'>
        <UserList
          users={memberList}
          title="Subscribed Members"
        />

        { this.props.showViewAllMembers && (
          <Button
            theme={THEME_LIGHT_BLUE}
            className="Collective__members-view-all"
            pill
            noOutline
            onClick={() => this.props.viewAllMembers('subscriber')}
          >
            View More
          </Button>
        )}
      </div>
    );

    let signupPrompt = null;
    if (!this.props.isLoggedIn) {
      signupPrompt = this.getCard('signup');
    }

    let exclusiveMessage = null;
    if (this.props.limitFeed) {
      exclusiveMessage = this.getCard('exclusive');
    }

    if (this.props.limitFeed && index === undefined) {
      return exclusiveMessage;
    } else if (this.props.feed.length < 4) {
      if (index === undefined || index === this.props.feed.length - 1) return [adminBlock, memberBlock, signupPrompt];
    } else if (4 <= this.props.feed.length && this.props.feed.length < 7) {
      if (this.props.feed.length - 1 === 3 && index === 3) {
        return [adminBlock, memberBlock, signupPrompt];
      } else {
        if (index === 3) {
          return adminBlock;
        } else if (index === this.props.feed.length - 1) {
          return [memberBlock, signupPrompt];
        }
      }
    } else if (this.props.feed.length >= 7 ) {
      if (index === 3) {
        return adminBlock;
      } else if (index === 6) {
        return memberBlock;
      } else if (index === this.props.feed.length - 1) {
        return signupPrompt;
      }
    }
  }

  render() {
    const {
      feed,
      feedGet,
      feedUpdate,
      hasLoadedAllPosts,
      isLoading,
      isLoggedIn,
      onUpdateFeed,
      newFeedEntities,
      collective,
      adminIds
    } = this.props;

    const isDesktop = this.state.viewportWidth > 1023;

    return (
      <div className="CollectiveFeed">
        <PostList
          feed={feed}
          ajaxUpdate={isLoggedIn ? () => feedUpdate({collectiveId: collective.id}) : () => {}}
          isLoading={isLoading}
          hasLoadedAllPosts={hasLoadedAllPosts}
          loadMore={isLoggedIn ? () => feedGet({collectiveId: collective.id}) : () => {}}
          updatePostList={onUpdateFeed}
          newPostEntities={newFeedEntities}
          renderExtraContent={isDesktop ? this.renderDesktopContentBlocks : this.renderMobileContentBlocks}
          hideCollectiveLine
          adminIds={adminIds}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.App.isLoggedIn,
    feed: getFeedWithEntities(state),
    isLoading: state.Feed.isLoading,
    hasLoadedAllPosts: state.Feed.hasLoadedAllPosts,
    totalPostCount: state.Feed.totalPostCount,
    user: state.User.user,
    newFeedEntities: state.Feed.newFeedEntities,
    contentTags: state.Feed.contentTags,
    currentMembersList: state.Collectives.currentMembersList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    feedGet: (args) => dispatch(feedGet(args)),
    reactPost: (args) => dispatch(reactPost(args)),
    reactPostRemove: (args) => dispatch(reactPostRemove(args)),
    feedUpdate: (args) => dispatch(feedUpdate(args)),
    onUpdateFeed: () => dispatch(onUpdateFeed())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectiveFeed);