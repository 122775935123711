import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { girlbossComLinks } from '../../config/routes';

import Input from '../../common/Input';
import Checkbox from '../../common/Checkbox';

import './Onboarding.scss';

class OnboardingBasics extends PureComponent {
  static propTypes = {
    firstName:PropTypes.string,
    handleFirstNameChange:PropTypes.func.isRequired,
    lastName:PropTypes.string,
    handleLastNameChange:PropTypes.func.isRequired,
    emailAddress:PropTypes.string,
    handleEmailAddressChange:PropTypes.func.isRequired,
    password:PropTypes.string,
    handlePasswordChange:PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.state = {
      checked:props.termsAndConditions
    };
  }
  
  static defaultProps = {
    errors: {}
  };

  redirectToFeed = () => {
    this.props.history.push('/');
  }
   
  render() {
    const {
      firstName,
      lastName,
      emailAddress,
      password,
      termsAndConditions,
      handleFirstNameChange,
      handleLastNameChange,
      handleEmailAddressChange,
      handlePasswordChange,
      handleTermsAndConditionsChange,
      errors
    } = this.props;

    if (this.props.user.username) {
      this.redirectToFeed();
    }

    const nameErrors = ['first_name', 'last_name'].reduce((acc, key) => {
      if (errors[key]) acc.push(errors[key][0]);
      return acc;
    }, []);

    return (
      <div className="Onboarding__page-container-default">
        <h2 className="Onboarding__heading-basics">Let's start with the basics</h2>
        <div>
          <div className="Onboarding__basics-inputs">
            <Input
              className="Onboarding__basics-first-name Onboarding__input"
              placeholder="First name"
              onChange={handleFirstNameChange}
              controlledValue={firstName}
              maxLength={35}
              hasError={errors['first_name']}
            />
            <Input
              className="Onboarding__input"
              placeholder="Last name"
              onChange={handleLastNameChange}
              controlledValue={lastName}
              maxLength={35}
              hasError={errors['last_name']}
            />
          </div>
          {nameErrors.length ? (
            <div style={{ marginTop: '-8px', marginBottom: '10px' }}>
              {nameErrors.map((err, i) => (
                <p key={i} className="Input__error">
                  {err.message}
                </p>
              ))}
            </div>
          ) : null}
        </div>

        <div className="Onboarding__basics-input">
          <Input
            className="Onboarding__input"
            placeholder="Email address"
            onChange={handleEmailAddressChange}
            controlledValue={emailAddress}
            type="email"
            errors={errors['email']}
            maxLength={50}
          />
        </div>
        <div className="Onboarding__basics-input">
          <Input
            className="Onboarding__input"
            placeholder="Create password"
            type="password"
            onChange={handlePasswordChange}
            controlledValue={password}
            errors={errors['password']}
            maxLength={35}
          />
        </div>
        <Checkbox
          label="termsAndConditions"
          className="Onboarding__basics-checkbox"
          onChange={handleTermsAndConditionsChange}
          isChecked={termsAndConditions}
        >
          Agree to our <a href={girlbossComLinks.terms} target="_blank" className="Onboarding__basics-checkbox-link">Terms & Conditions</a>
        </Checkbox>
      </div>
    )
  }
}

const mapStateToProps = ({ User }) => {
  return {
    user: User.user
  }
}

export default withRouter(connect(mapStateToProps, null)(OnboardingBasics));
