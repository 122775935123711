import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import ellipsize from 'ellipsize';

import locationFormatter from '../../../utils/locationFormatter';

import Button, { THEME_LIGHT_BLUE, THEME_GRAY, THEME_BLUE, THEME_BLACK } from '../../../common/Button';
import industryIcon from '../../../../public/assets/icons/INDUSTRY.svg'
import locationIcon from '../../../../public/assets/icons/LOCATION.svg';
import avatarIcon from '../../../../public/assets/icons/AVATAR.svg';
import clockIcon from '../../../../public/assets/icons/CLOCK.svg';
import bookmarkIcon from '../../../../public/assets/icons/BOOKMARK.svg';
import bookmarkFilledIcon from '../../../../public/assets/icons/BOOKMARK-FILLED.svg';
import waveIcon from '../../../../public/assets/icons/WAVE.svg';
import viewIcon from '../../../../public/assets/icons/VIEW.svg';

import './DirectoryResult.scss';

class DirectoryResult extends PureComponent {
  static propTypes = {
    noMobile: PropTypes.bool,
    contactRequestSent: PropTypes.bool 
  };

  static defaultProps = {
    noMobile:false
  }

  sendContactRequest = () => {
    const { member } = this.props;

    const user = {
      id: member.id, 
      first_name: member.first_name,
      last_name: member.last_name
    };

    this.props.sendContactRequest(user);
  }

  renderAdmireButton = () => {
    const { 
      member, 
      admireRemove, 
      admireAdd, 
      currentUserId, 
      admiresMap
    } = this.props;

    if (parseInt(member.id) === parseInt(currentUserId)) return;

    if (admiresMap[`${currentUserId}-${member.id}`]) {
      return (
        <Button 
          pill
          noOutline
          theme={THEME_BLUE} 
          className="DirectoryResult__cta" 
          onClick={() => admireRemove({
            id: admiresMap[`${currentUserId}-${member.id}`].id, 
            mapKey: `${currentUserId}-${member.id}`
          })}
        >
          <SVG src={bookmarkFilledIcon} />
          Admired
        </Button>
      )
    } else {
      return (
        <Button 
          pill
          noOutline
          theme={THEME_LIGHT_BLUE} 
          className="DirectoryResult__cta" 
          onClick={() => admireAdd(member.id)}
          disabled={parseInt(member.id) === parseInt(currentUserId)} 
        >
          <SVG src={bookmarkIcon} />
          {admiresMap[`${member.id}-${currentUserId}`] ? 'Admire Back' : 'Admire'}
        </Button>
      )
    }
  }
   
  render() {
    const { 
      member,
      noMobile, 
      contactRequestSent, 
      contactURL,
      currentUserId,
    } = this.props;

    const isCurrentUser = parseInt(member.id) === parseInt(currentUserId);

    return (
      <div className="DirectoryResult">
        <Link to={member.username ? '/' + member.username : ' '} onClick={member.username ? () => {} : (e) => {e.preventDefault()}}>
          <div className="DirectoryResult__container">
            <div
              className="directory-profile-picture"
              style={
                member.photo.large !== ""
                ? {backgroundImage:`url('${member.photo.large}')`, backgroundSize:'cover'}
                : {}
              }
            ></div>
            <h4 
              className="DirectoryResult__container--name"
            >{ member.first_name }</h4>
            <h4
              className="DirectoryResult__container--name"
            >{ member.last_name }</h4>

            { member.bio && member.bio.im_good_at && (
              <h6 className="DirectoryResult__container--good-at">
              <span>I'm good at</span> { ellipsize(member.bio.im_good_at, 50)}</h6>
            )}

            { member.industry && (
              <span className="DirectoryResult__container--details">
                <SVG src={industryIcon} />
                <h6>{ member.industry }</h6>
              </span>
             
            )}

            { member.location && (
              <span className="DirectoryResult__container--details">
                <SVG src={locationIcon} />
                <h6>{ locationFormatter(member.location) }</h6>
              </span>
            )}
          </div>
        </Link>

        <div className="DirectoryResult__cta-container">
          {isCurrentUser && (
            <Button 
              pill
              noOutline
              theme={THEME_BLACK} 
              className="DirectoryResult__cta DirectoryResult__cta--view" 
              component={Link} 
              to={`/${member.username}`}
            >
              <SVG src={viewIcon} />
                View Profile
              </Button>
          )}
          {this.renderAdmireButton()}
          { !isCurrentUser && (
            (contactURL && (
              <Button 
                pill
                noOutline
                theme={THEME_BLUE} 
                className="DirectoryResult__cta" 
                component={Link} 
                to={`/${member.username}`}
              >
                <SVG src={avatarIcon} />
                  Connected
                </Button>
            ))
            || 
            (contactRequestSent && (
            <Button 
              pill
              noOutline
              theme={THEME_GRAY} 
              className="DirectoryResult__cta" 
              disabled
            >
              <SVG src={clockIcon} />
               Request Sent
              </Button>
            ))
            ||
            (!contactRequestSent && (
              <Button 
                pill
                noOutline
                theme={THEME_LIGHT_BLUE} 
                className="DirectoryResult__cta DirectoryResult__cta--wave" 
                onClick={this.sendContactRequest}
              >
                <img src={waveIcon} />
                Say Hey
              </Button>
            ))
          )}
        </div>
      </div>
    )
  }
}

export default DirectoryResult;
