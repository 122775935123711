import React, { PureComponent } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Reply from '../Reply';
import InlineCommentingComment from '../../../components/InlineCommenting/InlineCommentingComment';
import CommentFooter from '../../../components/CommentFooter';

import {
  GTMpushDataLayerEvent,
  GTM_REPLIES_VIEW
} from '../../../lib/GoogleTagManager';

import './ReplyContainer.scss';

const REPLY_INCREASE = 2;

class ReplyContainer extends PureComponent {
  static propTypes = {
    replies: PropTypes.arrayOf(PropTypes.shape({
      creator: PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
        headline: PropTypes.string
      }),
      body: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired
    })),
    setRef: PropTypes.func.isRequired,
    scrolledToRef: PropTypes.string,
    onReact: PropTypes.func.isRequired,
    onReactRemove: PropTypes.func.isRequired,
    postId: PropTypes.number.isRequired,
    parentId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isRepliesHidden: false,
      step: REPLY_INCREASE,
      isLoading: false
    };
  }

  isAtEndOfReplies = () => {
    return (this.props.replies.length <= this.state.step);
  }

  getMoreReplies = () => {
    const step = this.state.step + REPLY_INCREASE;

    this.setState({ step });

    GTMpushDataLayerEvent({
      event: GTM_REPLIES_VIEW,
      viewRepliesStep: REPLY_INCREASE,
      amountOfRepliesViewed: step,
      totalReplies: this.props.replies.length 
    })
  }

  handleSaveReply = () => {
    console.log('clicked save');
  }

  handleDeleteReply = () => {
    console.log('clicked delete');
  }

  handleSubmitComment = value => {
    console.log(value, 'value');
  }

  setContentRef = (id, ref) => {
    this[id] = ref;
    this.props.setRef(id, ref);
  }

  render() {
    const { step } = this.state;
    const { 
      replies,
      isRepliesHidden, 
      scrolledToRef,
      type,
      actions,
      currentUserId,
      postId,
      adminIds
     } = this.props;

    return (
      <div>
        <div>
          {
            this.props.replies.map(reply => {
              return (
                <Reply
                  id={reply.id}
                  userId={reply.creator.id}
                  body={reply.body}
                  userTags={reply.user_tags}
                  creator={reply.creator}
                  createdAt={reply.created_at}
                  className={classNames({
                    // 'ReplyContainer__fadeIn': shouldLoad
                  })}
                  isReacted={reply.is_reacted}
                  reactions={reply.reactions}
                  onComment={this.props.onComment}
                  handleDeleteReply={this.handleDeleteReply}
                  handleSaveReply={this.handleSaveReply}
                  setRef={this.setContentRef}
                  key={reply.id}
                  parentId={this.props.parentId}
                  postId={postId}
                  onReact={this.props.onReact}
                  onReactRemove={this.props.onReactRemove}
                  type={type}
                  currentUserId={currentUserId}
                  actions={actions}
                  flagged={reply.flagged}
                  showAdminBadge={adminIds ? adminIds.indexOf(reply.creator.id) > -1 : null}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default ReplyContainer;
