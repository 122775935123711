import React, { Fragment, forwardRef, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Button, { THEME_BLUE, THEME_BLACK, THEME_RED } from '../../../common/Button';
import Input from '../../../common/Input';
import { ModalContainer, ModalBody, ModalFooter } from '../ModalContainer';
import { modalClose } from '../../../store/Modal/actions';
import { userDelete } from '../../../store/User/actions';
import { selectRequest } from '../../../store/requests';
import { validationErrorsToObject } from '../../../lib/API';

import './DeleteAccountModal.scss';

const REASON_OPTIONS = [
  { value: 'too_many_emails', label: "I'm getting too many emails" },
  { value: 'no_value', label: "I'm not getting any value from the Girlboss network" },
  { value: 'security_concerns', label: "I have security concerns" },
  { value: 'different_network', label: "I'm using a different professional network" },
  { value: 'no_connections', label: "I haven't found anyone to connect with" },
  { value: 'other', label: "Other: Let us know why you are leaving" }
];

const AppealStep = ({ onClose, onNext }) => (
  <Fragment>
    <ModalBody>
      <h1>Are you sure you want to leave us?</h1>
      <h2>Be prepared for the FOMO, because you'll be missing:</h2>
      <ol>
        <li>Daily career horoscopes</li>
        <li>Exclusive discounts and early access to Girlboss events</li>
        <li>Endless networking and mentorship opportunities</li>
        <li>Advice from the women you admire the most, &amp; more</li>
      </ol>
    </ModalBody>

    <ModalFooter>
      <Button
        className="WelcomeModal__cta"
        theme={THEME_BLUE} pill noOutline
        onClick={onClose}>
        Nevermind, I'll stay!
      </Button>
      <Button
        className="WelcomeModal__cta"
        theme={THEME_BLACK} pill noOutline
        onClick={onNext}>
        That's ok, delete my account
      </Button>
    </ModalFooter>
  </Fragment>
);

const ConfirmStep = ({ userDelete, request, onClose }) => {
  const [reason, setReason] = useState('');
  const [reason_text, setReasonText] = useState('');
  const [password, setPassword] = useState('');

  const validationErrors = useMemo(() => {
    const { errors } = request.error || {};
    return errors ? validationErrorsToObject(errors) : {};
  }, [request]);

  useEffect(() => {
    if (request.fulfilled) {
      onClose();
    }
  }, [request]);

  const submit = () => {
    userDelete({ reason, reason_text, password });
  };

  return (
    <Fragment>
      <ModalBody>
        <h1>We're sorry to see you go!</h1>
        <h2>Let us know why you're leaving:</h2>
        <form onSubmit={e => { e.preventDefault(); }}>
          <Input
            component={ReasonSelect}
            displayError={!!validationErrors.reason}
            errors={validationErrors.reason}
            onChange={val => setReason(val)} />

          <Input
            placeholder="Additional notes"
            component="textarea"
            value={reason_text}
            maxLength={1000}
            minRows={3}
            displayError={!!validationErrors.reason_text}
            errors={validationErrors.reason_text}
            onChange={val => setReasonText(val)} />

          <Input
            placeholder="Enter your password to confirm"
            type="password"
            value={password}
            displayError={!!validationErrors.password}
            errors={validationErrors.password}
            onChange={val => setPassword(val)} />
        </form>
      </ModalBody>

      <ModalFooter>
        <Button
          className="WelcomeModal__cta"
          theme={THEME_BLUE} pill noOutline
          disabled={request.pending}
          onClick={onClose}>
          Take me back!
        </Button>
        <Button
          className="WelcomeModal__cta"
          theme={THEME_RED} pill noOutline
          isLoading={request.pending}
          disabled={!reason || !password}
          onClick={submit}>
          Delete my account
        </Button>
        <p class="disclaimer">Deleting your account is permanent.<br/>This cannot be undone.</p>
      </ModalFooter>
    </Fragment>
  );
};

const ReasonSelect = forwardRef((props, ref) => (
  <select ref={ref} defaultValue="" {...props}>
    <option value="" disabled>Select a reason...</option>
    {REASON_OPTIONS.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
  </select>
));

const STEPS = [AppealStep, ConfirmStep];

const DeleteAccountModal = ({ setRef, close, modalClose, children, ...props }) => {
  const [step, setStep] = useState(0);
  const StepComponent = STEPS[step];

  return (
    <ModalContainer
      className="DeleteAccountModal"
      setRef={setRef}
      close={close}>
      {children}
      <StepComponent onClose={modalClose} onNext={() => setStep(step + 1)} {...props} />
    </ModalContainer>
  );
}

export default connect(state => {
  return {
    isLoading: state.User.isLoading,
    request: selectRequest(state, userDelete) 
  };
}, {
  modalClose,
  userDelete
})(DeleteAccountModal);
