import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchCategoryContainer extends Component {
  static propTypes = {
    title: PropTypes.string,
    seeMoreAction: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      query: ''
    }
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  handleSearchChange = query => {
    this.setState({query});
  }

  render() {
    const { title, seeMoreAction, children } = this.props;
    return (
      <div className="SearchCategoryContainer" ref={this.setRef}>
        <div className="SearchCategoryContainer__header">
          {
            title && <h5 className="SearchCategoryContainer__title">{ title }</h5>
          }
          {
            seeMoreAction && <h5 className="SearchCategoryContainer__see-more" onClick={seeMoreAction}>See more</h5>
          }
        </div>
        <div className="SearchCategoryContainer__content">
          { children }
        </div>
      </div>
    )
  }
}

export default SearchCategoryContainer;
