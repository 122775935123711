import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from './index.js';
import WithError from '../../components/WithError';

class InputWithError extends Component {
  static propTypes = {
    error: PropTypes.object,
    required: PropTypes.bool
  };

  render() {
    const inputProps = {...this.props, component: 'input'};
    return (
      <WithError
        headline={this.props.headline}
        subheadline={this.props.subheadline}
        required={this.props.required}
        error={this.props.error}
      >
        <Input {...inputProps} />
      </WithError>
    )
  }
}

export default InputWithError;
