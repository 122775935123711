import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Button, { THEME_BLACK } from '../../../common/Button';
import bookmarkIcon from '../../../../public/assets/icons/BOOKMARK.svg';

import './FirstAdmireModal.scss';

class FirstAdmireModal extends Component {
  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  render() {
    const { close } = this.props;
    return (
      <div className="Modal FirstAdmireModal" ref={this.setRef}>
        <div className="FirstAdmireModal__container">
          <div className="FirstAdmireModal__content">
            <SVG src={bookmarkIcon} />
            <h4>Admired!</h4>
            <p>Admiring is the Girlboss version of a follow. Admiring lets the other member know you've admired them and saves them to your network on your profile.</p>
          </div>
          <Button 
            onClick={close} 
            className="FirstAdmireModal__cta"
            theme={THEME_BLACK}
          >
            Got it!
          </Button>
        </div>
      </div>
    )
  }
}

export default FirstAdmireModal;
