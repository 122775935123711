import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './SearchPostResult.scss';

class SearchPostResult extends Component {
  static propTypes = {
    post:PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { post, modalClose } = this.props;
    return (
      <div className="SearchPostResult">
        <h5 className="SearchPostResult__posted-in">Posted in <Link className="SearchPostResult__collective-link" to={`/groups/${post.collective.slug}`}>{post.collective.name}</Link></h5>
        <h4 className="SearchPostResult__title">{post.title}</h4>
        <span className="SearchPostResult__stats">{post.total_deep_comment_count} replies • {post.reaction_count} praises</span>
        <Link to={`/post/${post.id}/search`} onClick={modalClose}>
          <div className="SearchPostResult__link"></div>
        </Link>
      </div>
    )
  }
}

export default SearchPostResult;
