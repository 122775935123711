import pick from 'lodash/pick';
import { takeEvery, put, call, take, fork, cancel, select } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';

import { FIRST_ADMIRE_MODAL } from '../../containers/ModalManager/ModalTypes';

import {
  ADMIRE_ADD,
  ADMIRE_REMOVE,
  ADMIRERS_GET
} from './actions';
import { userUpdate, userGetAll } from '../User/actions';
import { modalOpen } from '../Modal/actions';

function* admireSagaAdd(action) {
  const params = {
    body: {
      target_user_id:action.payload
    }
  }
  yield sagaRequest(action, callAPI('addAdmire', params), {
    *fulfilled() {
      const user = yield select(userGetAll);
      if (!user.preferences.first_admire_viewed) {
        const params = {
          preferences: {
            first_admire_viewed:true
          }
        }
        yield put(userUpdate(params));
        yield put(modalOpen({modal: FIRST_ADMIRE_MODAL}));
      }
    }
  });
}

function* admireSagaRemove(action) {
  yield sagaRequest(action, callAPI('removeAdmire', { id: action.payload.id }));
}

function* admireSagaGetAdmirers(action) {
  const { targetUserId, name = '', offset, limit = 10 } = action.payload;

  yield sagaRequest(action, callAPI('getAdmires', {
    targetUserId,
    name,
    offset,
    limit
  }));
};

export const admireSagas = [
  takeEvery(ADMIRE_ADD, admireSagaAdd),
  takeEvery(ADMIRE_REMOVE, admireSagaRemove),
  takeEvery(ADMIRERS_GET, admireSagaGetAdmirers)
];
