import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  MAGIC_SIGNUP_TOKEN_VERIFY,
  magicSignUpTokenVerifySuccess,
  magicSignUpTokenVerifyError
} from './actions';
import {
  authSignUpSave
} from '../Auth/actions';

function* magicSagaVerifySignUpToken(action) {
  const response = yield call(fetchAPI, 'verifySignUpToken', {
    body: {
      token: action.payload
    }
  });

  if (response.success) {
    yield put(magicSignUpTokenVerifySuccess(response));
    yield put(authSignUpSave({
      token: response.obscure_id,
      magicEmail: response.user && response.user.email
    }));
  } else {
    yield put(magicSignUpTokenVerifyError(response));
  }
}

export const magicSagas = [
  takeEvery(MAGIC_SIGNUP_TOKEN_VERIFY, magicSagaVerifySignUpToken)
];
