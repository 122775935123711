import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  getFeaturedMembersWithEntities,
  feedFeaturedMembersGet
} from '../../store/Feed/actions';
import { updateUserList } from '../../store/User/actions'
import { 
  collectivesGetRecommended, 
  collectiveMemberGet, 
  clearCollectivesOffset,
  clearUserCollectives
} from '../../store/Collectives/actions';
import { modalOpen } from '../../store/Modal/actions';
import fetchAPI from '../../services/APIService';
import { shuffle } from '../FeaturedMembers/logic';
import { isEqual, isEmpty } from 'lodash';

import Feed from '../Feed';
import UserList from '../../components/UserList';
import CollectivesList from '../Collectives/CollectivesList';
import Button, { THEME_LIGHT_BLUE } from '../../common/Button';
import { WELCOME_MODAL, INDUSTRY_AND_LOCAL_MEMBERS_LIST, COLLECTIVES_LIST_MODAL } from '../ModalManager/ModalTypes';

import './MainFeed.scss';

export const FEED_LIST_LENGTH = 3;

class MainFeed extends Component {
  static propTypes = {
    featuredMembers: PropTypes.array
  };

  constructor (props) {
    super(props);

    this.state = {
      viewportWidth: null,
      localMembers: [],
      industryMembers: [],
      recommendedCollectivesList: {},
      userCollectivesList: {}
    };
  }

  componentDidMount = () => {
    const { user } = this.props;
 
    this.state.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    window.addEventListener("resize", this.updateViewportSize);

    this.search('local');
    this.search('industry');

    const mainFeedInit = () => {
      this.props.clearUserCollectives();
      this.props.collectivesGetRecommended();
      this.props.collectiveMemberGet({userId: user.id, limit: 10}, 'shuffle')
    };

    if(!user.preferences || !user.preferences.welcome_modal_viewed) {
      this.props.modalOpen({
        modal: WELCOME_MODAL,
        implicitExit: false,
        mainFeedInit: mainFeedInit
      });
    } else {
      mainFeedInit();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewportSize);
  }

  updateViewportSize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    });
  }

  search = members_type => {
    const { user } = this.props;
    const params = {
      exclude: user.id,
      limit: 30
    };

    if (members_type == 'industry') {
      params.industry = user.industry;
    }

    if (members_type == 'local') {
      params.lat = user.location_lat || '';
      params.lng = user.location_lng || '';
    }

    fetchAPI('searchProfiles', params).then((resp) => {
      if (resp.success) {
        if(members_type === 'industry' && user.industry) {
          this.setState({
            industryMembers: shuffle(resp.user)
          });
        } else if (user.location) {
          this.setState({
            localMembers: shuffle(resp.user)
          });
        }
        this.props.updateUserList(members_type, resp.user);
      }
    });
  }

  viewAllMembers = userList => {
    const { industryMembers, localMembers } = this.state;

    this.props.modalOpen({
      modal: INDUSTRY_AND_LOCAL_MEMBERS_LIST,
      members: userList === 'industry' ? industryMembers : localMembers,
      header: userList === 'industry' ? 'Members in your industry' : 'Members near you'
    })
  }

  viewAllCollectives = listType => {
    this.props.modalOpen({
      modal: COLLECTIVES_LIST_MODAL,
      listType,
      header: 'Your Collectives',
      loadMore: this.props.collectiveMemberGet,
      onCloseCallback: this.props.clearCollectivesOffset
    })
  }

  renderUserListColumn = () => {
    const { recommendedCollectives, userCollectives, user } = this.props;
    const { industryMembers, localMembers } = this.state;

    let recommendedCollectivesList = {};
    Object.keys({...this.props.recommendedCollectives})
      .slice(0,  FEED_LIST_LENGTH)
      .map(key => recommendedCollectivesList[key] = this.props.recommendedCollectives[key]);

    let userCollectivesList = {};
    Object.keys({...this.props.userCollectives})
      .slice(0,  FEED_LIST_LENGTH)
      .map(key => userCollectivesList[key] = this.props.userCollectives[key]);

    return (
      <div className="MainFeed__left">
        <div className="MainFeed__sidebar-section">
          <CollectivesList 
            title="Collectives for you"
            collectives={recommendedCollectivesList}
            listType="recommended"
            showPostNumber={false}
            showAvatar
          />
          {Object.keys(recommendedCollectives).length > 0 && (
            <Link to={`/groups`}><Button className="MainFeed__left--cta" theme={THEME_LIGHT_BLUE} pill noOutline>Browse Collectives</Button></Link>
          )}
        </div>
        <div className="MainFeed__sidebar-section">
          <CollectivesList 
            title="Your Collectives"
            collectives={userCollectivesList}
            listType="recommended"
            showPostNumber={false}
            showAvatar
            isMember
          />
          {Object.keys(userCollectives).length > FEED_LIST_LENGTH && (
            <Button onClick={() => this.viewAllCollectives('user')} className="MainFeed__left--cta"theme={THEME_LIGHT_BLUE} pill noOutline>View More</Button>
          )}
        </div>
        {
          user.industry && (
            <div className="MainFeed__sidebar-section">
              <UserList 
                users={industryMembers.slice(0, FEED_LIST_LENGTH)} 
                title="Members in your industry"
              />
              {(industryMembers.length > FEED_LIST_LENGTH) && (
                <Button onClick={() => this.viewAllMembers('industry')} className="MainFeed__left--cta"theme={THEME_LIGHT_BLUE} pill noOutline>View More</Button>
              )}
            </div>
          )
        }
        {
          user.location && (
            <div className="MainFeed__sidebar-section">
              <UserList 
                users={localMembers.slice(0, FEED_LIST_LENGTH)} 
                title="Members near you"
              />
              {(localMembers.length > FEED_LIST_LENGTH) && (
                <Button onClick={() => this.viewAllMembers('local')} className="MainFeed__left--cta" theme={THEME_LIGHT_BLUE} pill noOutline>View More</Button>
              )}
            </div>
          )
        }
      </div>
    )
  }

  render() {
    const { viewportWidth, recommendedCollectivesList, userCollectivesList } = this.state;

    return (
      <div className="MainFeed__background">
        <div className="MainFeed__container">

          { viewportWidth > 1023 ? this.renderUserListColumn() : null }

          <div className="MainFeed__right">
            <Feed 
              { ...this.props } 
              viewportWidth={this.state.viewportWidth} 
              industryMembers={this.state.industryMembers}
              localMembers={this.state.localMembers}
              viewAllCollectives={this.viewAllCollectives}
              viewAllMembers={this.viewAllMembers}
            />
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    user: state.User.user,
    featuredMembers: getFeaturedMembersWithEntities(state),
    recommendedCollectives: state.Collectives.recommendedCollectives,
    userCollectives: state.Collectives.userCollectives
  }
};

export default connect(mapStateToProps, {
  modalOpen,
  feedFeaturedMembersGet,
  collectivesGetRecommended,
  collectiveMemberGet,
  clearCollectivesOffset,
  clearUserCollectives,
  updateUserList
})(MainFeed);
