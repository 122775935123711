import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Input from '../../common/Input';
import Button, { THEME_BLACK } from '../../common/Button';
import { MONTHS_INT } from '../../common/Dropdown/CommonFields';
import JourneyCardEditingGirlbossMoment from './JourneyCardEditingGirlbossMoment';
import JourneyCardEditingWorkExperience from './JourneyCardEditingWorkExperience';
import JourneyCardEditingEducation from './JourneyCardEditingEducation';

import SVG from 'react-inlinesvg';

import CaretDown from '../../../public/assets/icons/CARET-DOWN.svg';

import GirlbossIconBlack from '../../../public/assets/icons/ICON_BLACK-GIRLBOSSMOMENT.svg';
import WorkIconBlack from '../../../public/assets/icons/ICON_BLACK-WORK.svg';
import EducationIconBlack from '../../../public/assets/icons/ICON_BLACK-EDUCATION.svg';

import GirlbossIconWhite from '../../../public/assets/icons/ICON_WHITE-GIRLBOSSMOMENT.svg';
import WorkIconWhite from '../../../public/assets/icons/ICON_WHITE-WORK.svg';
import EducationIconWhite from '../../../public/assets/icons/ICON_WHITE-EDUCATION.svg';


class JourneyCardEditing extends Component {
  static defaultProps = {
    creating:false
  }

  static propTypes = {
    card: PropTypes.object,
    creating: PropTypes.bool
  };

  constructor (props) {
    super(props);
    this.state = {
      editing:!!props.editing,
      card:{}
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { card } = props;
    if (card && card.card_type != state.card.card_type) {
      state.card = card;
    }
    return state;
  }

  renderCard = (card) => {
    if (!card) return null;
    let { errors } = this.state;
    if (!errors) errors = {};
    let CardComponent = <Component />;
    switch (card.card_type) {
      case 'moment':
        CardComponent = JourneyCardEditingGirlbossMoment;
        break;
      case 'experience':
        CardComponent = JourneyCardEditingWorkExperience;
        break;
      case 'education':
        CardComponent = JourneyCardEditingEducation
        break;
      default:
        return null;
    }
    return <CardComponent
      card={card}
      enableEditing={this.enableEditing}
      editing={this.state.editing}
      updateCard={this.updateCard}
      updateCardData={this.updateCardData}
      updateStartDate={this.updateStartDate}
      updateEndDate={this.updateEndDate}
      updateCurrent={this.updateCurrent}
      errors={errors}
    >
      <Button
        onClick={this.setGirlbossMoment}
        className={classNames(
          'JourneyCardEditing__type-button',
          {
            'JourneyCardEditing__type-button-active':card.card_type == 'moment'
          }
        )}
      >
        <SVG src={card.card_type == 'moment' ? GirlbossIconWhite : GirlbossIconBlack } />
        <span>Girlboss Moment</span>
      </Button>
      <Button onClick={this.setWorkExperience}
        className={classNames(
          'JourneyCardEditing__type-button',
          {
            'JourneyCardEditing__type-button-active':card.card_type == 'experience'
          }
        )}
      >
        <SVG src={card.card_type == 'experience' ? WorkIconWhite : WorkIconBlack } />
        <span>Work Experience</span>
      </Button>
      <Button onClick={this.setEducation}
        className={classNames(
          'JourneyCardEditing__type-button',
          {
            'JourneyCardEditing__type-button-active':card.card_type == 'education'
          }
        )}
      >
        <SVG src={card.card_type == 'education' ? EducationIconWhite : EducationIconBlack } />
        <span>Education</span>
      </Button>
    </CardComponent>;
  }

  setGirlbossMoment = () => {
    let { card } = this.state;
    card.prev_card_type = card.card_type;
    card.card_type = 'moment';
    this.updateCard(card);
  }

  setWorkExperience = () => {
    let { card } = this.state;
    card.prev_card_type = card.card_type;
    card.card_type = 'experience';
    this.updateCard(card);
  }

  setEducation = () => {
    let { card } = this.state;
    card.prev_card_type = card.card_type;
    card.card_type = 'education';
    card.card_data.degree = 'A.A.';
    this.updateCard(card);
  }

  enableEditing = e => {
    if (!this.state.editing) {
      this.setState({editing:true}, () => {
        this.props.startEditingJourneyCard();
      });
    } else {
      this.doneEditing(e);
    }
  }

  doneEditing = e => {
    e.stopPropagation();
    if (this.state.editing) {
      const errors = this.validate();
      if (!errors) {
        this.setState({editing:false}, () => {
          this.props.doneEditingJourneyCard();
          this.save();
        });
      }
    }
  }

  deleteCard = e => {
    e.stopPropagation();
    this.props.doneEditingJourneyCard();
    this.props.delete(this.state.card.id);
  }

  updateCard = e => {
    this.setState({card:e});
  }

  updateCardData = e => {
    let { card } = this.state;
    card.card_data = e;
    this.updateCard(card);
  }

  updateStartDate = e => {
    let { card } = this.state;
    card.start_date = e;
    this.updateCard(card);
  }

  updateEndDate = e => {
    let { card } = this.state;
    card.end_date = e;
    this.updateCard(card);
  }

  updateCurrent = e => {
    let { card } = this.state;
    card.current = e.target.checked;
    if (!card.current) card.end_date = Object.assign({}, card.start_date);
    this.updateCard(card);
  }

  save = () => {
    const { card } = this.state;
    this.props.saveJourneyCard(card);
  }

  validate = () => {
    const { card } = this.state;
    let errors = {};
    switch (card.card_type) {
      case 'moment':
        if (!card.card_data.title) errors.title = true;
        break;
      case 'experience':
        if (!card.card_data.title) errors.title = true;
        if (!card.card_data.company) errors.company = true;
        break;
      case 'education':
        if (!card.card_data.school) errors.school = true;
        break;
    }
    if (!card.current && card.card_type != 'moment') {
      if (card.start_date.year > card.end_date.year) {
        errors.date = true;
      }
      if (card.start_date.year == card.end_date.year) {
        if (MONTHS_INT[card.start_date.month.toUpperCase()] > MONTHS_INT[card.end_date.month.toUpperCase()]) {
          errors.date = true;
        }
      }
    }
    this.setState({errors});
    return Object.keys(errors).length ? errors : null;
  }

  render() {
    const { className, creating } = this.props;
    const { card, editing } = this.state;

    return (
      <div className={classNames("JourneyCardEditing", className)}>
        {
          !creating && (
            <div className="JourneyCardEditing__dropdown-icon">
              <SVG src={CaretDown} />
            </div>
          )
        }
        {
          this.renderCard(card)
        }
        {
          (editing) && (
            <div className="JourneyCardEditing__editing">
              {
                (card.id) && (
                  <p
                    className="JourneyCardEditing__error-message"
                    style={{cursor:'pointer'}}
                    onClick={this.deleteCard}
                  >
                    { creating ? 'Cancel' : 'Delete Card' }
                  </p>
                )
              }
              <Button theme={THEME_BLACK} onClick={this.doneEditing}>Done</Button>
            </div>
          )
        }
      </div>
    )
  }
};

export default JourneyCardEditing;