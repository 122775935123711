import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import Button, { THEME_BLUE } from '../../../common/Button';
import { ModalContainer, ModalBody, ModalFooter } from '../ModalContainer';
import { userUpdate } from '../../../store/User/actions';
import './SystemNoticeModal.scss';

function SystemNoticeModal({ close, setRef, version, userUpdate }) {
  const [page, setPage] = useState(0);

  const onConfirm = () => {
    userUpdate({
      preferences: { last_viewed_system_notice: version }
    });
    close();
  };

  return (
    <ModalContainer className="SystemNoticeModal" setRef={setRef}>
      <h1>A *new* way to connect with members on Girlboss</h1>

      {page === 0 ? (
        <Fragment>
          <ModalBody>
            <br />
            <p>We’ll be changing the way that you connect with members on the Girlboss network. The Girlboss network will no longer have direct messaging on the platform, and instead, provide a way to connect with each other via email.</p>
            <br />
            <p>So go ahead and wrap up all the conversations you are having and exchange contact information by <em>Sunday May 17</em>, at midnight pacific standard time.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              className="WelcomeModal__cta"
              theme={THEME_BLUE} pill noOutline
              onClick={() => setPage(page + 1)}
            >
              Next
            </Button>
          </ModalFooter>
        </Fragment>
      ) : (
        <Fragment>
          <ModalBody>
            <h2>How to make <em>new</em> connections on the Girlboss network starting Monday, May 18:</h2>
            <ol>
              <li>Click on the person you want to connect with</li>
              <li>Click on the “Say Hey” button to send a connection request</li>
              <li>Let them know why you want to connect with them</li>
              <li>Once they accept your connection request, their email will be shown to you on their profile and yours will be shown to them</li>
              <li>Send them an email</li>
            </ol>
          </ModalBody>
          <ModalFooter>
            <Button
              className="WelcomeModal__cta"
              theme={THEME_BLUE} pill noOutline
              onClick={onConfirm}
            >
              Got it!
            </Button>
          </ModalFooter>
        </Fragment>
      )}
    </ModalContainer>
  )
}

export default connect(null, { userUpdate })(SystemNoticeModal);
