import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditIconSVG from '../../../components/SVG/EditIcon';

import '../Profile.scss';

class EditIcon extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="EditIcon" onClick={this.props.onClick}>
        <EditIconSVG />
      </div>
    )
  }
}

export default EditIcon;
