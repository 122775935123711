import React, { PureComponent } from 'react';

import './Homepage.scss';

class HomepageVideo extends PureComponent {
  componentWillMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('click', this.toggleModal);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('click', this.toggleModal);
  }

  handleKeyDown = e => {
    e.stopPropagation();
    if (e.keyCode === 27) this.props.toggleShowVideo();
  }

  toggleModal = () => {
    this.props.toggleShowVideo();
  }

  render() {
    return (
      <div className="LandingPage__embed">
        <div
          className="LandingPage__icon--x"
          onClick={this.props.toggleShowVideo}
        />
        <iframe width="500" height="315" src="https://www.youtube.com/embed/PUxcR-yNMGA" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </div>
    )
  }
}

export default HomepageVideo;
