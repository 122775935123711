export const DELETE_MODAL = 'DELETE_MODAL';
import DeleteModal from './Modal/DeleteModal';

export const REPORT_MODAL = 'REPORT_MODAL';
import ReportModal from './Modal/ReportModal';

export const DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL';
import DeleteAccountModal from './Modal/DeleteAccountModal';

export const JOURNEY_CARD_VIEW_MORE_WORK_EXPERIENCE_MODAL = 'JOURNEY_CARD_VIEW_MORE_WORK_EXPERIENCE_MODAL';
import JourneyCardViewMoreWorkExperienceModal from './Modal/JourneyCardModals/JourneyCardViewMoreWorkExperienceModal';

export const JOURNEY_CARD_VIEW_MORE_GIRLBOSS_MOMENT_MODAL = 'JOURNEY_CARD_GIRLBOSS_MOMENT_MODAL';
import JourneyCardViewMoreGirlbossMomentModal from './Modal/JourneyCardModals/JourneyCardViewMoreGirlbossMomentModal';

export const JOURNEY_CARD_VIEW_MORE_EDUCATION_MODAL = 'JOURNEY_CARD_EDUCATION_MODAL';
import JourneyCardViewMoreEducationModal from './Modal/JourneyCardModals/JourneyCardViewMoreEducationModal';

export const POST_MODAL = 'POST_MODAL'
import PostModal from './Modal/PostModal';

export const UPDATE_SUBSCRIPTION_MODAL = 'UPDATE_SUBSCRIPTION_MODAL';
import UpdateSubscriptionModal from './Modal/UpdateSubscriptionModal';

export const VIDEO_MODAL = 'VIDEO_MODAL';
import VideoModal from './Modal/VideoModal';

export const HOMEPAGE_REFERRAL_MODAL = 'HOMEPAGE_REFERRAL_MODAL';
import HomepageReferralModal from './Modal/HomepageReferralModal/index.js';

export const DAILY_HOROSCOPE_MODAL = 'DAILY_HOROSCOPE_MODAL';
import DailyHoroscopeModal from './Modal/DailyHoroscopeModal.js';

export const FEED_HOROSCOPES_MODAL = 'FEED_HOROSCOPES_MODAL';
import FeedHoroscopesModal from './Modal/FeedHoroscopesModal';

export const CONTACT_REQUEST_MODAL = 'CONTACT_REQUEST_MODAL'
import ContactRequestModal from './Modal/ContactRequestModal/index.js';

export const CONTACT_REQUEST_RESPONSE_MODAL = 'CONTACT_REQUEST_RESPONSE_MODAL';
import ContactRequestResponseModal from './Modal/ContactRequestResponseModal';

export const CONTACT_REQUEST_INITIATOR_MODAL = 'CONTACT_REQUEST_INITIATOR_MODAL';
import ContactRequestInitiatorModal from './Modal/ContactRequestInitiatorModal';

export const CONTACT_REQUEST_PENDING_MODAL = 'CONTACT_REQUEST_PENDING_MODAL';
import ContactRequestPendingModal from './Modal/ContactRequestPendingModal';

export const URL_COPIED_MODAL = 'URL_COPIED_MODAL';
import URLCopiedModal from './Modal/URLCopiedModal';

export const FILTER_CONTENT_TAGS_MODAL = 'FILTER_CONTENT_TAGS_MODAL';
import FilterContentTagsModal from './Modal/FilterContentTagsModal';

export const WELCOME_MODAL = 'WELCOME_MODAL';
import WelcomeModal from './Modal/WelcomeModal';

export const USER_LIST_MODAL = 'USER_LIST_MODAL';
import UserListModal from './Modal/UserListModal';

export const COLLECTIVE_MEMBERS_LIST = 'COLLECTIVE_MEMBERS_LIST';
import { CollectiveMembersList } from './Modal/UserListModal';

export const INDUSTRY_AND_LOCAL_MEMBERS_LIST = 'INDUSTRY_AND_LOCAL_MEMBERS_LIST';
import { IndustryAndLocalMembersList } from './Modal/UserListModal';

export const CONNECTIONS_LIST_MODAL = 'CONNECTIONS_LIST_MODAL';
import { ConnectionList } from './Modal/UserListModal';

export const NETWORK_MODAL = 'NETWORK_MODAL';
import { NetworkList } from './Modal/NetworkModal';

export const ADMIRERS_LIST_MODAL = 'ADMIRERS_LIST_MODAL';
import { AdmirersList } from './Modal/UserListModal';

export const COLLECTIVES_LIST_MODAL = 'COLLECTIVES_LIST_MODAL';
import CollectivesListModal from './Modal/CollectivesListModal';

export const FIRST_ADMIRE_MODAL = 'FIRST_ADMIRE_MODAL';
import FirstAdmireModal from './Modal/FirstAdmireModal';

export const COLLECTIVE_INVITATION_MODAL = 'COLLECTIVE_INVITATION_MODAL';
import CollectiveInvitationModal from './Modal/CollectiveInvitationModal';

export const COLLECTIVE_CREATION_MODAL = 'COLLECTIVE_CREATION_MODAL';
import CollectiveCreationModal from './Modal/CollectiveCreationModal';

export const ACTIVITY_FEED_MODAL = 'ACTIVITY_FEED_MODAL';
import ActivityFeedModal from './Modal/ActivityFeedModal';

export const GLOBAL_SEARCH_MODAL = 'GLOBAL_SEARCH_MODAL';
import GlobalSearchModal from './Modal/GlobalSearchModal';

export const SIGNUP_PROMPT_MODAL = 'SIGNUP_PROMPT_MODAL';
import SignupPromptModal from './Modal/SignupPromptModal';

export const SYSTEM_NOTICE_MODAL = 'SYSTEM_NOTICE_MODAL';
import SystemNoticeModal from './Modal/SystemNoticeModal';

const mapTypeToModal = {
  [DELETE_MODAL]: DeleteModal,
  [REPORT_MODAL]: ReportModal,
  [DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
  [JOURNEY_CARD_VIEW_MORE_WORK_EXPERIENCE_MODAL]: JourneyCardViewMoreWorkExperienceModal,
  [JOURNEY_CARD_VIEW_MORE_GIRLBOSS_MOMENT_MODAL]: JourneyCardViewMoreGirlbossMomentModal,
  [JOURNEY_CARD_VIEW_MORE_EDUCATION_MODAL]: JourneyCardViewMoreEducationModal,
  [POST_MODAL]: PostModal,
  [UPDATE_SUBSCRIPTION_MODAL]: UpdateSubscriptionModal,
  [VIDEO_MODAL]: VideoModal,
  [HOMEPAGE_REFERRAL_MODAL]: HomepageReferralModal,
  [DAILY_HOROSCOPE_MODAL]: DailyHoroscopeModal,
  [FEED_HOROSCOPES_MODAL]: FeedHoroscopesModal,
  [CONTACT_REQUEST_MODAL]: ContactRequestModal,
  [CONTACT_REQUEST_RESPONSE_MODAL]: ContactRequestResponseModal,
  [CONTACT_REQUEST_INITIATOR_MODAL]: ContactRequestInitiatorModal,
  [CONTACT_REQUEST_PENDING_MODAL]: ContactRequestPendingModal,
  [URL_COPIED_MODAL]: URLCopiedModal,
  [FILTER_CONTENT_TAGS_MODAL]: FilterContentTagsModal,
  [WELCOME_MODAL]: WelcomeModal,
  [USER_LIST_MODAL]: UserListModal,
  [COLLECTIVE_MEMBERS_LIST]: CollectiveMembersList,
  [INDUSTRY_AND_LOCAL_MEMBERS_LIST]: IndustryAndLocalMembersList,
  [COLLECTIVES_LIST_MODAL]: CollectivesListModal,
  [CONNECTIONS_LIST_MODAL]: ConnectionList,
  [NETWORK_MODAL]: NetworkList,
  [ADMIRERS_LIST_MODAL]: AdmirersList,
  [FIRST_ADMIRE_MODAL]: FirstAdmireModal,
  [COLLECTIVE_INVITATION_MODAL]: CollectiveInvitationModal,
  [COLLECTIVE_CREATION_MODAL]: CollectiveCreationModal,
  [ACTIVITY_FEED_MODAL]: ActivityFeedModal,
  [GLOBAL_SEARCH_MODAL]: GlobalSearchModal,
  [SIGNUP_PROMPT_MODAL]: SignupPromptModal,
  [SYSTEM_NOTICE_MODAL]: SystemNoticeModal
};

export default mapTypeToModal;