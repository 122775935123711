import {
  ADMIRE_GET_FULFILLED,
  ADMIRE_ADD_FULFILLED,
  ADMIRE_REMOVE_FULFILLED,
  ADMIRERS_GET_FULFILLED,
  CLEAR_ADMIRERS
} from './actions';

import {
  CONNECTIONS_GET_FULFILLED
} from '../Connections/actions';

import {
  DIRECTORY_SEARCH_SUCCESS
} from '../Directory/actions';

import {
  COLLECTIVE_GET_FULFILLED,
  COLLECTIVE_MEMBER_GET_FULFILLED
} from '../Collectives/actions';

import {
  UPDATE_USER_LIST
} from '../User/actions';

import {
  PROFILE_GET_SUCCESS
} from '../Profile/actions';

const defaultState = {
  admiresMap: {},
  admirers: [],
  displayAdmirers: [],
  offset: 0,
  total: 0,
  hasLoadedAllAdmirers: false
};

function extractAdmires(admiresMap, users) {
  if (!users.length) users = [users];
  users.map(user => {
    if (user.admires && user.admires.length) {
      user.admires.map(admire => admiresMap[`${admire.user_id}-${admire.target_user_id}`] = admire);
    }
  });

  return admiresMap;
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_ADMIRERS:
      return {
        ...state,
        admirers:[],
        displayAdmirers:action.payload ? [] : state.displayAdmirers,
        total:0,
        offset:0,
        hasLoadedAllAdmirers:false
      }
    case ADMIRERS_GET_FULFILLED: {
      let displayAdmirers = state.displayAdmirers;
      if (!action.request.initialPayload.name && state.offset === 0) displayAdmirers = action.payload.data;

       return {
        ...state,
        displayAdmirers,
        admirers:state.admirers.concat(action.payload.data.map(admirer => admirer.user)),
        total:action.payload.total,
        offset:Math.min(state.offset + action.payload.data.length, action.payload.total),
        hasLoadedAllAdmirers: action.payload.total == Math.min(state.offset + action.payload.data.length, action.payload.total)
      }
    }
    case ADMIRE_ADD_FULFILLED: {
      let admiresMap = {...state.admiresMap};
      admiresMap[`${action.payload.data.user_id}-${action.payload.data.target_user_id}`] = action.payload.data;

      return {
        ...state,
        admiresMap
      }
    }
    case ADMIRE_REMOVE_FULFILLED: {
      let admiresMap = {...state.admiresMap};
      delete admiresMap[action.request.initialPayload.mapKey];

      return {
        ...state,
        admiresMap
      }
    }
    case PROFILE_GET_SUCCESS: {
      let map = {...state.admiresMap};
      let admiresMap = extractAdmires(map, action.payload.user);
      return {
        ...state,
        admiresMap
      };
    }
    case CONNECTIONS_GET_FULFILLED: {
      let map = {...state.admiresMap};
      let admiresMap = extractAdmires(map, action.payload.data);
      return {
        ...state,
        admiresMap
      }
    }
    case DIRECTORY_SEARCH_SUCCESS: {
      let map = {...state.admiresMap};
      let admiresMap = extractAdmires(map, action.payload.user);

      return {
        ...state,
        admiresMap
      }
    }
    case COLLECTIVE_GET_FULFILLED: {
      let users = action.payload.data.members.map(member => member.user);
      let map = {...state.admiresMap};
      let admiresMap = extractAdmires(map, users);
      return {
        ...state,
        admiresMap
      }
    }
    case COLLECTIVE_MEMBER_GET_FULFILLED: {
      let { userId, members, isLoggedIn } = action.payload;
      if (userId) {
        return {...state};
      } else {
        if (isLoggedIn) {
          let users = members.map(member => member.user);
          let map = {...state.admiresMap};
          let admiresMap = extractAdmires(map, users);
  
          return {
            ...state,
            admiresMap
          }
        } else {
          return {...state}
        }
      }
    }
    case UPDATE_USER_LIST: {
      let map = {...state.admiresMap};
      let admiresMap = extractAdmires(map, action.payload);

      return {
        ...state,
        admiresMap
      }
    }
  
    default:
      return state;
  }
};
