import React, { Component } from 'react';

import './ProfileEditBar.scss';

class EditingProfileBar extends Component {
  constructor (props) {
    super(props);
  }

  save = () => {
    if (this.props.changed) this.props.save();
  }

  render() {
    const { changed } = this.props;

    return (
      <div className="ProfileEditBar">
        <div className="ProfileEditBar__container">
          <span className="ProfileEditBar__label">Edit Mode</span>
          <div>
            <button className={`ProfileEditBar__save-button ${ changed ? 'ProfileEditBar__save-button-changed' : '' }`} onClick={this.save}>Done</button>
          </div>
        </div>
      </div>
    )
  }
};

export default EditingProfileBar;