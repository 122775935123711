import React, { Component } from 'react';
import classNames from 'classnames';
import Button, {
  THEME_BLUE
} from '../../common/Button';
import withSizes, { mapSizesToProps } from '../../lib/WithSizes';

import './ButtonAdd.scss';

const ButtonAdd = ({ isMobile, text, className, onClick }) => {
  return (
    <Button
      theme={THEME_BLUE}
      className={classNames(
        'ButtonAdd',
        className
      )}
      onClick={onClick}
      pill
    >
      { !isMobile ? text : '' }
    </Button>
  )
}

export default withSizes(mapSizesToProps)(ButtonAdd);
