import React, { Component } from 'react';
import PropTypes from 'prop-types';

class URLCopiedModal extends Component {
  static propTypes = {
    
  };

  constructor(props) {
    super(props);
    setTimeout(() => {
      props.close();
    }, 3000);
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  render() {
    return (
      <div className="ProfileUser__share-profile-modal-container">
        <div className="ProfileUser__share-profile-modal" ref={this.setRef}>
          URL Copied!
        </div>
      </div>
    )
  }
}

export default URLCopiedModal;
