import XRegExp from 'xregexp';

export const TAG_MARKUP_PREFIX = '[[U@';
export const TAG_MARKUP_PREFIX_ESC = '\\[\\[U@';
export const TAG_MARKUP_PREFIX_REGEX = new RegExp(TAG_MARKUP_PREFIX_ESC);
export const TAG_MARKUP_SUFFIX = ']]';
export const TAG_MARKUP_SUFFIX_ESC = '\\]\\]';
export const TAG_MARKUP_SUFFIX_REGEX = new RegExp(TAG_MARKUP_SUFFIX_ESC);
export const TAG_MARKUP_SEPARATOR = '||';
export const TAG_MARKUP_SEPARATOR_ESC = '\\|\\|';
export const NAME_VALID_CHARACTERS_CLASS = '[\p{L}0-9 ,.\(\)\'"’“”\/-]';
export const NAME_VALID_CHARACTERS_CLASS_ESC = '[\\p{L}0-9 ,.\\(\\)\'"’“”\\/-]';
// export const NAME_VALID_CHARACTERS_CLASS = '[\u0041-\u005A\u0061-\u007A\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02C1\u02C60-9 ,.\(\)\'"’“”\/-]';
// export const NAME_VALID_CHARACTERS_CLASS_ESC = '[\\p{L}0-9 ,.\\(\\)\'"’“”\\/-]';
export const TAG_MARKUP_REGEX = new XRegExp(
  `${TAG_MARKUP_PREFIX_ESC}\\d+${TAG_MARKUP_SEPARATOR_ESC}${NAME_VALID_CHARACTERS_CLASS_ESC}+${TAG_MARKUP_SUFFIX_ESC}`,
  'gu'
);
export const TAG_MARKUP_REGEX_CAPTURE = new XRegExp(
  `${TAG_MARKUP_PREFIX_ESC}\\d+${TAG_MARKUP_SEPARATOR_ESC}(${NAME_VALID_CHARACTERS_CLASS_ESC}+)${TAG_MARKUP_SUFFIX_ESC}`,
  'gu'
);
export const TAG_MARKUP_REGEX_NO_SUFFIX = new RegExp(`${TAG_MARKUP_PREFIX_ESC}\\d+${TAG_MARKUP_SEPARATOR_ESC}`, 'g');

export function getRegExpForUserId(userId) {
  return new XRegExp(
    `${TAG_MARKUP_PREFIX_ESC}${userId}${TAG_MARKUP_SEPARATOR_ESC}` +
    `${NAME_VALID_CHARACTERS_CLASS_ESC}+${TAG_MARKUP_SUFFIX_ESC}`,
    'gu'
  );
}