/**
  Preferences Get
**/
export const PREFERENCES_GET_EXTERNAL = 'PREFERENCES_GET_EXTERNAL';
export const PREFERENCES_GET_EXTERNAL_SUCCESS = 'PREFERENCES_GET_EXTERNAL_SUCCESS';
export const PREFERENCES_GET_EXTERNAL_ERROR = 'PREFERENCES_GET_EXTERNAL_ERROR';
export const PREFERENCES_GET_EXTERNAL_COMPLETE = 'PREFERENCES_GET_EXTERNAL_COMPLETE';

export const preferencesGetExternal = payload => {
  return {
    type: PREFERENCES_GET_EXTERNAL,
    payload
  }
};

export const preferencesGetExternalSuccess = payload => {
  return {
    type: PREFERENCES_GET_EXTERNAL_SUCCESS,
    payload
  }
};

export const preferencesGetExternalError = payload => {
  return {
    type: PREFERENCES_GET_EXTERNAL_ERROR,
    payload
  }
};

export const preferencesGetExternalComplete = () => {
  return {
    type: PREFERENCES_GET_EXTERNAL_COMPLETE
  }
};

/**
  Preferences Update
**/
export const PREFERENCES_UPDATE_EXTERNAL = 'PREFERENCES_UPDATE_EXTERNAL';
export const PREFERENCES_UPDATE_EXTERNAL_SUCCESS = 'PREFERENCES_UPDATE_EXTERNAL_SUCCESS';
export const PREFERENCES_UPDATE_EXTERNAL_ERROR = 'PREFERENCES_UPDATE_EXTERNAL_ERROR';

export const preferencesUpdateExternal = payload => {
  return {
    type: PREFERENCES_UPDATE_EXTERNAL,
    payload
  }
};

export const preferencesUpdateExternalSuccess = payload => {
  return {
    type: PREFERENCES_UPDATE_EXTERNAL_SUCCESS,
    payload
  }
};

export const preferencesUpdateExternalError = payload => {
  return {
    type: PREFERENCES_UPDATE_EXTERNAL_ERROR,
    payload
  }
};

export const PREFERENCES_SET_COUNTRY = 'PREFERENCES_SET_COUNTRY';

export const preferencesSetCountry = payload => {
  return {
    type: PREFERENCES_SET_COUNTRY,
    payload
  }
}

export const PREFERENCES_ADD_TO_INTERNATIONAL_WAITLIST = 'PREFERENCES_ADD_TO_INTERNATIONAL_WAITLIST';

export const preferencesAddToInternationalWaitlist = payload => {
  return {
    type: PREFERENCES_ADD_TO_INTERNATIONAL_WAITLIST,
    payload
  }
}