import Cookies from 'js-cookie';

export function getToken() {
  return Cookies.get('token');
};

export function setToken(token) {
  Cookies.set('token', token, { expires: 30 });
};

export function removeToken() {
  Cookies.remove('token');
};
