import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  DIRECTORY_SEARCH,
  directorySearchSuccess,
  directorySearchError
} from './actions';

function* directorySagaSearch(action) {
  const response = yield call(fetchAPI, 'searchProfiles', action.payload);

  if (response && response.success) {
    response.loadMore = action.loadMore;
    yield put(directorySearchSuccess(response));
  } else {
    yield put(directorySearchError(response));
  }
}

export const directorySagas = [
  takeEvery(DIRECTORY_SEARCH, directorySagaSearch),
];
