const CURR_DATE = new Date();
const CURR_YEAR = CURR_DATE.getFullYear();

const MIN_YEARS_AGO = 60;
const MAX_YEARS_AHEAD = 7;

// labels
export const FROM_TO = 'fromTo';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const MONTHS_INT = {
  JANUARY:1,
  FEBRUARY:2,
  MARCH:3,
  APRIL:4,
  MAY:5,
  JUNE:6,
  JULY:7,
  AUGUST:8,
  SEPTEMBER:9,
  OCTOBER:10,
  NOVEMBER:11,
  DECEMBER:12
}

export const PAST_PRESENT_YEARS = function() {
  const pastAndPresent = [];
  for (let i = 0; i < MIN_YEARS_AGO; i++) {
    pastAndPresent.push(CURR_YEAR - i);
  }
  return pastAndPresent;
}();

export const PAST_PRESENT_FUTURE_YEARS = function() {
  const pastAndPresent = [];
  const future = [];

  // if min years ago was 1959
  // takes 4 years to graduate
  // last graduation year should be CURR_YEAR - (MIN + 4) = 1963
  const minGraduationYear = (MIN_YEARS_AGO - 4);

  for (let i = 0; i < minGraduationYear; i++) {
    pastAndPresent.push(CURR_YEAR - i);
  }

  // start at 1 bc 2018 was from last set
  for (let i = 1; i <= MAX_YEARS_AHEAD; i++) {
    future.push(CURR_YEAR + i);
  };

  return future.reverse().concat(pastAndPresent);
}();

export const INDUSTRIES = [
  "Accounting",
  "Administration",
  "Agriculture",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts and Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Beauty",
  "Biotechnology",
  "Civil Engineering",
  "Communications",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Creative",
  "Data",
  "Digital Marketing",
  "E-Learning",
  "Education",
  "Entertainment",
  "Entrepreneurship",
  "Events and Event Services",
  "Executive Office",
  "Facilities Services",
  "Finance",
  "Financial Services",
  "Fine Arts",
  "Food & Beverages",
  "Furniture Design",
  "Government",
  "Graphic Design",
  "Health",
  "Wellness and Fitness",
  "Health Care",
  "Hospitality",
  "Human Resources",
  "Insurance",
  "Interior Design",
  "Investment Banking",
  "Information Technology",
  "Law Enforcement",
  "Legal",
  "Travel & Tourism",
  "Libraries",
  "Luxury Goods & Jewelry",
  "Management Consulting",
  "Market Research",
  "Marketing and Advertising",
  "Mechanical or Industrial Engineering",
  "Media",
  "Medicine",
  "Mental Health Care",
  "Military",
  "Music",
  "Nonprofit",
  "Online Media",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Philosophy",
  "Photography",
  "Product Design",
  "Product Management",
  "Production",
  "Professional Training & Coaching",
  "Project Management",
  "Public Policy",
  "Public Relations and Communications",
  "Publishing",
  "Real Estate",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Salon",
  "Fitness Services",
  "Security",
  "Semiconductors",
  "Social Justice",
  "Sporting Goods",
  "Staffing and Recruiting",
  "Supply Chain and Logistics",
  "Technology",
  "Telecommunications",
  "TV, Film, and Video",
  "Venture Capital & Private Equity",
  "Writing and Editing"
];

export const DEGREES = [
  'A.A.',
  'A.S.',
  'AAS',
  'B.A.',
  'B.S.',
  'BFA',
  'BAS',
  'M.A.',
  'M.S.',
  'MBA',
  'MFA',
  'Ph.D.',
  'J.D.',
  'M.D.',
  'DDS',
  'Certification',
];