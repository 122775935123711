import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Input from '../../common/Input';

import { INDUSTRIES } from '../../common/Dropdown/CommonFields';

import './Onboarding.scss';

class OnboardingIndustry extends PureComponent {
  static propTypes = {
    industry:PropTypes.string,
    handleIndustryChange:PropTypes.func.isRequired
  };
   
  render() {

    const {
      industry,
      handleIndustryChange
    } = this.props;

    return (
      <div className="Onboarding__page-container-industry">
        <h2 className="Onboarding__heading">What industry do you currently work in?</h2>
        <select className="Onboarding__input Onboarding__input--medium" defaultValue={industry} onChange={(e) => {handleIndustryChange(e.target.value);}}>
          <option value="">Industry</option>
          {
            INDUSTRIES.map(industry => (
              <option
                value={industry}
                key={industry}
              >
                {industry}
              </option>
            ))
          }
        </select>
      </div>
    )
  }
}

export default OnboardingIndustry;
