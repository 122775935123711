import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { GTMpushDataLayerEvent } from '../../lib/GoogleTagManager';

import ProfilePhoto, {
  SIZE_SMALL
} from '../ProfilePhoto';
import Input from '../../common/Input';
import commentInputFields, {
  BODY
} from '../../common/Input/CommonFields/comment';
import collapseErrors from '../../common/Input/CommonFields/validations';

import './InlineCommenting.scss';

class InlineCommenting extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setRef: PropTypes.func,
    value: PropTypes.string,
    controlledValue: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      [BODY]: '',
      errors: [],
      showErrors: false
    }
  }

  componentDidMount = () => {
    // this.inputRef.focus();
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({ showErrors: !!this.state.errors.length });

    if (this.state[BODY].length) {
      this.props.onSubmit(this.state[BODY]);
    }
  }

  setRef = ref => {
    this.inputRef = ref;
    this.props.setRef(ref);
  }

  handleCommentChange = (val, label, isValidated, errors) => {
    this.setState({ [BODY]: val, errors });
    this.props.onChange(val, label, isValidated, errors);
  }

  render() {
    // TODO: clean up when all photos have been objects
    const { photo } = this.props;
    const userPhoto = (typeof photo === 'string' ?  photo : photo.thumbnail);

    return (
      <div className="InlineCommenting">
        <form
          className="InlineCommenting__container"
          onSubmit={this.onSubmit}
        >
          <ProfilePhoto
            size={SIZE_SMALL}
            photo={userPhoto}
            className="InlineCommenting__container--photo"
          />
          <Input
            setRef={this.setRef}
            placeholder="Reply to this..."
            className={classNames(
              "InlineCommenting__input",
              this.props.className
            )}
            value={this.props.value}
            controlledValue={this.props.controlledValue}
            onChange={this.handleCommentChange}
            {...commentInputFields[BODY]}
          />
          {
            this.state.showErrors && (
              <p className="error">
                {this.state.errors}
              </p>
            )
          }
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ User }) => {
  return {
    photo: User.user.photo
  }
}

export default connect(mapStateToProps)(InlineCommenting);
