import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tabination.scss';
import Button, { THEME_GRAY, THEME_CLEAR } from '../../common/Button';

class TabinationButtons extends Component {
  static propTypes = {
    currentOption: PropTypes.string,
    options: PropTypes.object,
    center: PropTypes.bool
  };

  render() {
    const { options, currentOption, setCurrentOption, center } = this.props;

    return (
      <div className={classNames(
        "Tabination__options",
        {
          "Tabination__options-center":center
        }
      )}>
        {
          Object.keys(options).map(key => (
            <Button
              key={options[key].label}
              tab
              autoWidth
              theme={currentOption === key ? THEME_GRAY : THEME_CLEAR}
              onClick={() => {setCurrentOption(key)}}
            >
              {options[key].label}
            </Button>
          ))
        }
      </div>
    )
  }
}

class TabinationContent extends Component {
  static propTypes = {
    currentOption: PropTypes.string,
    options: PropTypes.object
  };

  render() {
    const { options, currentOption } = this.props;

    return (
      <div className="Tabination__content">
        {
          Object.keys(options).map(key => (
            <div style={{display:key === currentOption ? 'block' : 'none'}} key={key}>
              {options[key].component}
            </div>
          ))
        }
      </div>
    )
  }
}

export {TabinationButtons, TabinationContent};