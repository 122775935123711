import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  PREFERENCES_UPDATE_EXTERNAL,
  preferencesUpdateExternalSuccess,
  preferencesUpdateExternalError,
  PREFERENCES_GET_EXTERNAL,
  preferencesGetExternalSuccess,
  preferencesGetExternalError,
  PREFERENCES_ADD_TO_INTERNATIONAL_WAITLIST,
  PREFERENCES_SET_COUNTRY
} from './actions';

function* preferencesSagaGetExternal(action) {
  const response = yield call(fetchAPI, 'getPreferencesExternal', {token:action.payload});

  if (response.success) {
    yield put(preferencesGetExternalSuccess({email:response.email, preferences:response.preferences}));
  } else {
    yield put(preferencesGetExternalError(response));
  }
}

function* preferencesSagaUpdateExternal(action) {
  const response = yield call(fetchAPI, 'updatePreferencesExternal', {
    body: action.payload
  });
  if (response.success) {
    yield put(preferencesUpdateExternalSuccess(response.preferences));
  } else {
    yield put(preferencesUpdateExternalError(response.errors.message));
  }
}

function* preferencesSagaSetCountry(action) {
  const jwt = yield call(fetchAPI, 'getPreferencesExternal', {token:action.payload.token});
  if (jwt.success) {
    const response = yield call(fetchAPI, 'updatePreferencesExternal', {
      body: {
        token:action.payload.token,
        preferences:{
          country:action.payload.country
        }
      }
    });
    if (response.success) {
      yield put(preferencesUpdateExternalSuccess(response.preferences));
    } else {
      yield put(preferencesUpdateExternalError(response.errors.message));
    }
  }
}

function* preferencesSagaAddToInternationalWaitlist(action) {
  const jwt = yield call(fetchAPI, 'getPreferencesExternal', {token:action.payload.token});
  if (jwt.success) {
    const response = yield call(fetchAPI, 'updatePreferencesExternal', {
      body: {
        token:action.payload.token,
        preferences:{
          selection:'pick',
          newsletter:false,
          mentors:false,
          radio_network:false,
          international_waitlist:true,
          rally:true,
          sweeps:false,
          invites:false,
          country:action.payload.country
        }
      }
    });
    if (response.success) {
      yield put(preferencesUpdateExternalSuccess(response.preferences));
    } else {
      yield put(preferencesUpdateExternalError(response.errors.message));
    }
  }
}

export const preferencesSagas = [
  takeEvery(PREFERENCES_GET_EXTERNAL, preferencesSagaGetExternal),
  takeEvery(PREFERENCES_UPDATE_EXTERNAL, preferencesSagaUpdateExternal),
  takeEvery(PREFERENCES_ADD_TO_INTERNATIONAL_WAITLIST, preferencesSagaAddToInternationalWaitlist),
  takeEvery(PREFERENCES_SET_COUNTRY, preferencesSagaSetCountry)
];
