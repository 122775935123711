const laRally2018 = [
  {
    id: 271129547,
    thumbnail_id: 702338102,
    title: 'Sophia\'s Advice On Being Underestimated',
    description: 'Girlboss founder and CEO Sophia Amoruso explores what it means to be underestimated - and how that might ultimately be a good thing.',
    featuring: 'Sophia Amoruso'
  },
  {
    id: 271132824,
    thumbnail_id: 703124203,
    title: 'A Poetry Reading By Aija Mayrock',
    description: 'A best-selling author and poet talks about why and how words can change the world - and performs a powerful piece called "The Truth About Being A Girl."',
    featuring: 'Aiya Mayrock'
  },
  {
    id: 271138620,
    thumbnail_id: 703123902,
    title: 'Lizzie Velazquez Talks Raising Your Voice',
    description: `Lizzie Velasquez has overcome unimaginable obstacles like bullying and being labeled the "world's ugliest woman" and used them to her advantage. Here, she'll share the most important lessons she's learned on that journey.`,
    featuring: 'Lizzie Velazquez'
  },
  {
    id: 271141495,
    thumbnail_id: 703123572,
    title: 'Bozoma Saint John On How Small Steps Lead To Big Change',
    description: `In her previous role at Uber, Bozoma St. John walked into a broken corporate culture expected to save the entire company. She's not here for that. But she is on a personal mission of allyship, cultural change, and morale-shifting.`,
    featuring: 'Bozoma St. John in conversation with Neha Gandhi'
  },
  {
    id: 271151285,
    thumbnail_id: 703123129,
    title: 'Starting From The Bottom',
    description: `Multi-million dollar businesses require sweat, tears, grit, and vision to build. Four powerhouse builders who have created some of the brands you spend money with regularly explain how they did it. With Jen Gotch, Jen Rubio, Payal Kadakia, and Sarah Kunst. Moderated by Jerico Mandybur.`,
    featuring: 'Jen Gotch, Jen Rubio, Payal Kadakia, and Sarah Kunst, in conversation with Jerico Mandybur'
  },
  {
    id: 271166055,
    thumbnail_id: 703122273,
    title: 'The Changing Faces Of Women In Hollywood',
    description: `A powerful movement is being driven by women in film and television today—to help raise up other women. Here, four of the leading voices in this conversation come together to talk about money and moving the needle on representation. With Marti Noxon, Jameela Jamil, and Amanda Brugel. Moderated by Neha Gandhi.`,
    featuring: 'Marti Noxon, Jameela Jamil, and Amanda Brugel in conversation with Neha Gandhi'
  },
  {
    id: 274752145,
    thumbnail_id: 706915002,
    title: 'A Lesson In Movement And Confidence',
    description: 'Girlboss Grant recipients Queen Moves are here to get your body up and moving, for a dance exercise that\'ll power you up instantly.',
    featuring: 'Queen Moves'
  },
  {
    id: 271176530,
    thumbnail_id: 703121967,
    title: 'From Homeless To Dream Weaving Venture Capitalist',
    description: `Arlan Hamilton, founder and managing partner of Backstage Capital, defines scrappy. A Silicon Valley outsider with no connections, she tells us how she managed to build her venture fund from the ground up. In conversation with Moj Mahdara.`,
    featuring: 'Arlan Hamilton in conversation with Moj Mahdara'
  },
  {
    id: 271181862,
    thumbnail_id: 702412866,
    title: 'The Art of the Pitch',
    description: `Five incredibly smart women who write massive checks to founders share what they've learned through cold, hard experience—and teach us what a great pitch looks like. With Kara Nortman, Tracy Gray, Nicole Quinn, and Olga Kaplan. Moderated by Suzy Ryoo.`,
    featuring: 'Kara Nortman, Tracy Gray, Nicole Quinn, and Olga Kaplan in conversation with Suzy Ryoo'
  },
  {
    id: 274749131,
    thumbnail_id: 706915070,
    title: 'How I Turned My Hobby Into...Goop',
    description: `Gwyneth Paltrow has built a multi-million-dollar lifestyle empire, from a newsletter she started as a hobby. Here, she explores that journey—and the toughest lessons you have to learn the hard way as a founder. In conversation with Sophia Amoruso.`,
    featuring: 'Gwyneth Paltrow in conversation with Sophia Amoruso'
  },
  {
    id: 271364137,
    thumbnail_id: 703104090,
    title: 'Activism And Making Your Voice Heard',
    description: `We've gathered activists to discuss intersectional feminism, community organizing, and the easiest ways we can all make our voices heard. With Alyssa Mastromonaco, Sarah Sophie Flicker, Marya Bangee, and Paola Mendoza. Moderated by Melissa Batchelor Warnke.`,
    featuring: 'Alyssa Mastromonaco, Sarah Sophie Flicker, Marya Bangee, and Paola Mendoza in conversation with Melissa Batchelor Warnke'
  },
  {
    id: 271364280,
    thumbnail_id: 703103121,
    title: `Making It In The Ecommerce Space`,
    description: `So, you want to start a retail business? It's never been easier than it is today—but there's also never been more competition cropping up. So, what does making it looking like in the e-commerce space? With Pia Arrobio, Ariel Kaye, and Hana Abaza. Moderated by Kari Hamanaka.`,
    featuring: 'Pia Arrobio, Ariel Kaye, and Hana Abaza in conversation with Kari Hamanaka'
  },
  {
    id: 271364414,
    thumbnail_id: 703080650,
    title: 'What Does Equal Actually Look Like',
    description: `We know diversity and real representation make all the difference in work, and in the world. But how do we get there? With Tzef Montana, Arian Simone, and Callie Field. Moderated by Stephanie Elam.`,
    featuring: 'Tzef Montana, Arian Simone, and Callie Field in conversation with Stephanie Elam'
  },
  {
    id: 271144368,
    thumbnail_id: 703122663,
    title: 'Unplug, Unwind, And Find Your Center',
    description: `Let's take a beat to take care of ourselves for a second. We've brought in the most well-versed pros in mindfulness, sound healing, and meditation. to teach you simple but effective practices that will help you relax. Led by Michelle Phan, Heather Lilleston, and Ana Netanel. In partnership with Cathay Pacific Airways.`,
    featuring: 'Michelle Phan, Heather Lillieston, and Ana Natanel'
  },
  {
    id: 271345167,
    thumbnail_id: 703120045,
    title: `The Freelancer's Survival Guide`,
    description: `Here's a crash course in what you should consider before taking on self-employment. This workshop includes financial intel, tax tips, and true stories from the trenches. With Paco de Leon, Kim Goulbourne, Sara McDowell, and Sara Wilson, moderated by Kimmie Green. In partnership with QuickBooks.`,
    featuring: 'In Partnership with Quickbooks, Paco De Leon, Kim Galbourne, Sara McDowell, and Sara Wilson in conversation with Kimmie Green (Quickbooks)'
  },
  {
    id: 271356396,
    thumbnail_id: 703119765,
    title: 'How to Negotiate Like a Lawyer',
    description: `Ever felt butterflies before asking for more? Totally normal. But knowing how to win at negotiation isn't something you're born with—it's an artform and a craft. Here, It's Over Easy founder, Laura Wasser, walks you through some tips and tactics.`,
    featuring: 'Laura Wasser'
  },
  {
    id: 274768339,
    thumbnail_id: 706914703,
    title: 'How To Get Your Business Noticed Online',
    description: `We've partnered with Google #SmallThanks to get some real talk on growing your business from the ground up. Leading the conversation is a pro from Google's Small Business Team, Maria Elena Duron, to talk about how to get your brand seen.`,
    featuring: 'Maria Elena Duron'
  },
  {
    id: 274770591,
    thumbnail_id: 706914607,
    title: 'From Idea To Small Business Greatness',
    description: `There is nothing "as usual" about the way small businesses grow, run, and get off the ground these days. Here, founders Emily Núñez Cavness and Betsy Núñez, discuss the ins and outs of ecommerce life. Moderated by Jerico Mandybur.`,
    featuring: 'Emily Núñez Cavness and Betsy Núñez'
  },
  {
    id: 271357663,
    thumbnail_id: 703118607,
    title: 'How To Build A Brand That Will Outlive You',
    description: `A brand marketer, an influencer-turned-founder, and a brand architect share the essentials for building a brand that's memorable and strong—and authentic too. With Beth Hayes, Lisa Buhler, and Rosie O'Neill. Moderated by Meghan Raab.`,
    featuring: `Beth Hayes, Lisa Buhler, and Rosie O'Neil`
  },
  {
    id: 271360672,
    thumbnail_id: 703118128,
    title: 'Creating A Brand With Real Impact',
    description: `Lisa Clunie is the Co-Founder and CEO, of creative agency, Joan, discusses creating for brands and creating your OWN brand, so you can build a strong one, with real impact.`,
    featuring: 'Lisa Clunie presented by Google Chromebook'
  },
  {
    id: 271360907,
    thumbnail_id: 703117779,
    title: 'How To Really Get Your Business\'s Brand Off The Ground',
    description: `An immersive, 90-minute workshop built to help you get your business off the ground. With Reshma Chattaram Chamberlain and Sonja Rasula. In partnership with Google Chromebook.`,
    featuring: 'Reshma Chattaram Chamberlain and Sonja Rasula In Partnership with Google Chromebook'
  },
  {
    id: 271362785,
    thumbnail_id: 703115636,
    title: 'How To Stay Innovative In Health And Wellness',
    description: 'COO of Wundabar, Kate Simmons, gives us an industry overview and talks about what it takes to innovate in the health and wellness space.',
    featuring: 'Kate Simmons presented in partnership with Google Chromebook'
  },
  {
    id: 271362972,
    thumbnail_id: 703115183,
    title: 'How To Build A Biz People Really Love',
    description: 'Senior Product Manager Lisa Zhu of Aaptiv leads an interactive workshop and shares her five step process for figuring out how to build something others will love.',
    featuring: 'Lisa Zhu in partnership with Google Chromebook'
  },
  {
    id: 271361852,
    thumbnail_id: 703117459,
    title: 'Making A Wellness Business Work RN',
    description: `Founder and CEO of one of LA's first drop-in meditation centers, Tal Rabinowitz, shares her secrets to marketing a wellness business in the digital and physical space.`,
    featuring: 'Tal Rabinowitz presented in partnership with Google Chromebook'
  },
  {
    id: 271362074,
    thumbnail_id: 703116892,
    title: 'How To Craft The Perfect Social Media Strategy',
    description: 'Founder and chiller-in-chief of Chillhouse—a destination for modern self-care—Cyndi Ramirez offers her atypical perspective on ways a well-crafted social media strategy can grow a business.',
    featuring: 'Cyndi Ramirez presented in partnership with Google Chromebook'
  },
  {
    id: 271362274,
    thumbnail_id: 703116088,
    title: 'Everything You Need To Know About Branded Content',
    description: `Maia Knudsen-Schule from Tastemade discusses the art of branded content and how you can maintain your brand's identity while finding solutions that speak to your audience in the most authentic way.`,
    featuring: 'Maia Knudsen-Schule presented in partnership with Google Chromebook'
  },
  {
    id: 271769343,
    thumbnail_id: 703154891,
    title: 'How To Get Your Content Seen By More People',
    description: `Martha Pierece from ATTN: discusses how to get your content seen by more people. She'll dive into how to strategically grow an audience and engage a community of followers on your desired platform.`,
    featuring: 'Martha Pierce presented in partnership with Google Chromebook'
  },
  {
    id: 271363154,
    thumbnail_id: 703114975,
    title: 'Understanding Marketplace Trends',
    description: 'Led by Ivka Adam, the founder and CEO of Iconery, this is a value-packed workshop on marketplace trends that you don\'t wanna miss.',
    featuring: 'Ivka Adam in partnership with Google Chromebook'
  },
  {
    id: 271363318,
    thumbnail_id: 703107721,
    title: 'Business Strategy And Forecasting 101',
    description: 'Leah Stigile, the President and COO of Fame and Partners breaks down how to think about strategy and forecasting for your business.',
    featuring: 'Leah Stigile in partnership with Google Chromebook'
  },
  {
    id: 271363497,
    thumbnail_id: 703114846,
    title: 'The Truth About Supply Chains',
    description: 'Jodie Fox, co-founder and chief creative officer of Shoes of Prey, explains her unique business model and how establishing the right supply chain system can move your ecommerce business forward.',
    featuring: 'Jodie Fox'
  }
]

export default laRally2018;
