import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SocialItem extends Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired
  }
  render() {
    const { link, icon, altText } = this.props;
    return (
      <a href={link} target="_blank" className="Footer__social">    
        <img alt={altText} src={icon} className="Footer__socialIcon" />
      </a> 
    )
  }
}

export default SocialItem;