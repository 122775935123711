import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import ModalDialog from '../ModalTemplates/ModalDialog';
import Radio from '../../../common/Radio';

const options = ['Spam', 'Violates Guidelines', 'Hate or Harassment', 'Other' ];

class ReportModal extends Component {
  static propTypes = {
    type: PropTypes.oneOf([
      'post',
      'comment',
      'reply',
      'user'
    ]).isRequired,
    reporterId: PropTypes.number.isRequired,
    typeId: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    content: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      reportReason: null,
      submitted: false,
      otherText: ''
    };
  }

  handleOnChange = input => {
    this.setState({ reportReason: input });
  }

  handleReportSubmit = () => {
    const { reportReason, otherText } = this.state;

    if (!reportReason) return null;

    const body = {
      flagging_user_id: Number(this.props.reporterId),
      flagged_item_id: Number(this.props.typeId),
      reason: reportReason,
      other_text: reportReason === 'Other' ? otherText.slice(0, 151) : null
    };

    this.props.submit(this.props.type, body, this.props.postId, this.props.history);
    this.setState({ submitted: true });
  }

  onChangeText = e => {
    this.setState({
      otherText: e.target.value
    });
  }

  render() {
    let textInputStyle = {
      width: '100%',
      height: '80px',
      padding: '10px 15px 20px',
      fontSize: '15px',
      marginBottom: '20px'
    };

    const {
      submitted,
      reportReason
    } = this.state;
    const {
      close,
      setRef,
      type
    } = this.props;

    if (submitted) {
      if(type === 'user') {
        return (
          <ModalDialog
            header="Thank You!"
            subHeader="The user has been blocked and we'll take it from here."
            close={close}
            setRef={setRef}
            closeText="Got it"
          />
        )
      } else {
        return (
          <ModalDialog
            header="Thank You!"
            subHeader="We'll take it from here."
            close={close}
            setRef={setRef}
            closeText="Got it"
          />
        )
      }
    }

    return (
      <ModalDialog
        header={`Report this ${type}`}
        subHeader={`What's happening with this ${type}?`}
        close={close}
        setRef={setRef}
        submit={this.handleReportSubmit}
        submitText="Report"
        characters={this.state.otherText.length}
        className="ReportModal"
      >
        {this.props.children}
        <Radio
          options={options}
          onChange={this.handleOnChange}
          selection={reportReason}
        />
        {this.state.reportReason === 'Other' && 
        <div style={{position: 'relative'}}>
          <textarea style={textInputStyle} onChange={this.onChangeText} value={this.state.otherText} />
          <span style={{
            position: 'absolute',
            bottom: '0',
            right: '7px',
            fontSize: '12px',
            color: this.state.otherText.length < 151 ?  'grey' : 'red',
          }}>{this.state.otherText.length}/150</span>
        </div>
        }
      </ModalDialog>
    )
  }
}

export default withRouter(ReportModal);
