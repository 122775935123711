import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Checkbox.scss';

export const THEME_BLUE = 'blue';
export const THEME_BLUE_SLIDER = 'blue-slider';

class Checkbox extends Component {
  static propTypes = {
    isChecked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    isChecked: false
  };

  render() {
    const { label, theme, disabled } = this.props;

    return (
      <div
        className={classNames(
          "Checkbox",
          `Checkbox__${theme}`,
          this.props.className
        )}
      >
        <div className="maxHeight">
          <input
            type="checkbox"
            className="Checkbox__input"
            value={label}
            id={label}
            onChange={this.props.onChange}
            checked={this.props.isChecked}
            disabled={disabled}
          />
        </div>
        <label htmlFor={label} className={this.props.className}>
          {this.props.children}
        </label>
      </div>
    )
  }
}

export default Checkbox;
