import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { LANDING_PAGE_URL } from '../../config';

import ModalManager from '../ModalManager';

import RouteChangeTracker from './RouteChangeTracker';

import Layout from '../Layout';
import Feed from '../MainFeed';

import ProfileNav from '../Nav/ProfileNav';
import HomepageNav from '../Nav/HomepageNav';

import Footer from '../../components/Footer';

import Homepage from '../../components/Homepage';

import PasswordResetRequest from '../Auth/PasswordResetRequest';
import PasswordReset from '../Auth/PasswordReset';
import PasswordResetSuccess from '../Auth/PasswordResetSuccess';

import OnboardingSignup from '../Onboarding/OnboardingSignup';
import OnboardingProfile from '../Onboarding/OnboardingProfile';

import CollectiveDirectory from '../Collectives/CollectivesDirectory';
import Collective from '../Collectives/CollectivePage';

import NotFound from '../../components/NotFound';
import ErrorPage from '../../components/ErrorPage';
import AccountNotFound from '../../components/AccountNotFound';
import IncompatibleBrowser from '../../components/IncompatibleBrowser';

import WelcomeMessage from '../../components/WelcomeMessage';
import ContentTags from '../../components/ContentTags';

import Directory from '../Directory';

import Login from '../Auth/Login';

import Profile from '../Profile/Profile';

import Settings from '../Settings';
import PreferencesExternal from '../PreferencesExternal';

import Thread from '../Thread';
import Notifications from '../Notifications';

import Videos from '../Videos';

// import Messaging from '../Messaging';

import ScrollToTop from '../ReactRouter/ScrollToTop';
import FreeRoute from '../ReactRouter/FreeRoute';
import PaidRoute from '../ReactRouter/PaidRoute';
import CollectiveRoute from '../ReactRouter/CollectiveRoute';
import ProfileRoute from '../ReactRouter/ProfileRoute';
import PostRoute from '../ReactRouter/PostRoute';
import MagicRoute from '../ReactRouter/MagicRoute';

import { routes } from '../../config/routes';
import { appInitialize, createOsReference } from '../../store/App/actions';
import { horoscopesGet } from '../../store/Profile/actions';

import AppHelmet from './AppHelmet.js';

class App extends Component {

  componentWillMount() {
    this.props.appInitialize();
    // this.props.horoscopesGet();
  }

  render() {
    // let ua = navigator.userAgent || navigator.vendor || window.opera;

    let uaArr = [navigator.userAgent, navigator.vendor, window.opera];

    let isInstagram = false;
    let isFacebook = false;
    let isTwitter = false;
    let isSlack = false;
    let isLinkedIn = false;

    for(let ua of uaArr) {
      if(ua) {
        if(ua.indexOf('Instagram') > -1) {
          isInstagram = true;
        } else if((ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)) {
          isFacebook = true;
        } else if(ua.indexOf('Twitter') > -1) {
          isTwitter = true;
        } else if((ua.indexOf('Slack') > -1) || (ua.indexOf('slackbot-linkexpanding') > -1)) {
          isSlack = true;
        } else if(ua.indexOf('LinkedIn') > -1) {
          isLinkedIn = true;
        } 
      }
    };

    return (
      <BrowserRouter>
        <ScrollToTop>
          <AppHelmet />
          <ModalManager />
          <RouteChangeTracker />

          <Switch>
            {
              (isInstagram) || (isFacebook) || (isTwitter) || (isSlack) || (isLinkedIn) && (
                <Route render={props => (<IncompatibleBrowser />)} />
              )
            }
            <FreeRoute
              exact
              path={routes.root}
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={Feed}
                  isFullWidth
                  {...props}
                />
              )}
              redirect={props => {
                window.location.href = LANDING_PAGE_URL;
                return null;
              }}
            />
            <FreeRoute
              exact
              path={routes.logIn}
              render={props => (
                <Layout
                Nav={ProfileNav}
                Main={Feed}
                isFullWidth
                {...props}
                />
              )}
              redirect={props => <Layout Nav={HomepageNav} Main={Login} {...props} />}
            />
            <CollectiveRoute
              exact
              path={routes.collective}
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={Collective}
                  isFullWidth={true}
                  {...props}
                />
              )}
              redirect={props => (
                <Layout
                Nav={HomepageNav}
                Main={Collective}
                isFullWidth={true}
                {...props}
              />
              )}
            />
            <FreeRoute
              exact
              path={routes.collectiveDirectory}
              render={props => <Layout Nav={ProfileNav} isFullWidth Main={CollectiveDirectory} {...props} />}
            />
            <Route 
              exact 
              path={routes.instagram}
              render={props => <IncompatibleBrowser />} 
            />
            <Route
              exact
              path={routes.passwordResetRequest}
              render={props => <Layout Nav={HomepageNav} Main={PasswordResetRequest} {...props} />}
            />
            <Route
              exact
              path={routes.passwordReset}
              render={props => <Layout Nav={HomepageNav} Main={PasswordReset} {...props} />}
            />
            <Route
              exact
              path={routes.passwordResetSuccess}
              render={props => <Layout Nav={HomepageNav} Main={PasswordResetSuccess} {...props} />}
            />
            <FreeRoute
              exact
              publicRoute
              path={routes.preferencesExternal}
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={PreferencesExternal}
                  isFullScreen
                  Footer={Footer}
                  {...props}
                />
              )}
              redirect={props => <Layout isFullScreen Nav={HomepageNav} Main={PreferencesExternal} {...props} />}
            />
            <Route
              exact
              path={routes.signUp}
              render={props => <Layout isFullScreen noFooter Main={OnboardingSignup} {...props} />}
            />
            <FreeRoute
              exact
              path={routes.onboarding}
              render={props => <Layout isFullScreen noFooter Main={OnboardingProfile} {...props} />}
            />
            <PostRoute
              exact
              path={routes.post}
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={Thread}
                  Left={WelcomeMessage}
                  {...props}
                />
              )}
              redirect={props => (
                <Layout
                  Nav={HomepageNav}
                  Main={Thread}
                  Left={() => {return null}}
                  {...props}
                />
              )}
            />
            <PostRoute
              exact
              path={`${routes.post}/*`}
              render={props => <Layout Nav={ProfileNav} Main={Thread} {...props} />}
            />
            <FreeRoute
              exact
              path={routes.postThreadComment}
              render={props => <Layout Nav={ProfileNav} Main={Thread} {...props} />}
            />
            <FreeRoute
              exact
              path={routes.notifications}
              render={props => (
                <Layout Nav={ProfileNav} Main={Notifications} Right={null} {...props} />
              )}
            />
            <FreeRoute
              exact
              path={routes.settings}
              isFullScreen
              render={props => <Layout Nav={ProfileNav} Main={Settings} Right={null} {...props} />}
            />
            <PaidRoute
              exact
              path={routes.videos}
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={Videos}
                  isFullWidth
                  {...props}
                />
              )}
            />
            <FreeRoute
              exact
              directory
              path={routes.directory}
              render={props => <Layout Nav={ProfileNav} isFullWidth Main={Directory} {...props} />}
            />
            {/* <FreeRoute
              path={routes.messagingConversation}
              render={props => <Layout Nav={ProfileNav} isFullScreen noFooter Main={Messaging} {...props} />}
            /> */}
            <Route
              exact
              path={routes.notFound}
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={NotFound}
                  Left={WelcomeMessage}
                  {...props}
                />
              )}
              redirect={props => (
                <Layout
                  Nav={HomepageNav}
                  Main={NotFound}
                  isFullWidth
                  {...props}
                />
              )}
            />
            <FreeRoute
              exact
              path={routes.accountNotFound}
              publicRoute
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={AccountNotFound}
                  Left={WelcomeMessage}
                  {...props}
                />
              )}
              redirect={props => (
                <Layout
                  Nav={HomepageNav}
                  Main={AccountNotFound}
                  isFullWidth
                  {...props}
                />
              )}
            />
            <FreeRoute
              exact
              path={routes.errorPage}
              publicRoute
              render={props => (
                <Layout
                  Nav={ProfileNav}
                  Main={ErrorPage}
                  Left={WelcomeMessage}
                  {...props}
                />
              )}
              redirect={props => (
                <Layout
                  Nav={HomepageNav}
                  Main={ErrorPage}
                  isFullWidth
                  {...props}
                />
              )}
            />
            <ProfileRoute
              exact
              path={routes.username}
              render={props => (
                <Layout Nav={ProfileNav} Main={Profile} Right={null} isFullWidth={true} {...props} />
              )}
              redirect={props => (
                <Layout Nav={HomepageNav} Main={Profile} Right={null} isFullWidth={true} {...props} />
              )}
            />
            <Redirect to={routes.notFound} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    appInitialize: args => dispatch(appInitialize(args)),
    createOsReference: args => dispatch(createOsReference(args)),
    horoscopesGet: () => dispatch(horoscopesGet())
  };
}

export default connect(
  null,
  mapDispatchToProps
)(App);
