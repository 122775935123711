import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import qs from 'query-string';

import Button, { THEME_BLUE, THEME_BLACK } from '../../common/Button';
import Input, { INPUT_SECONDARY } from '../../common/Input';
import Checkbox, { THEME_BLUE_SLIDER } from '../../common/Checkbox';
import EmailSettings from './EmailSettings';
import { 
  INVALID_PASSWORD_LENGTH,
  INVALID_PASSWORD_CONTAIN_NUMBER,
  INVALID_PASSWORD_CONTAIN_LOWERCASE,
  INVALID_PASSWORD_CONTAIN_UPPERCASE,
  compose
} from '../../common/Input/CommonFields/validations';
import {
  FREQUENCY_PREFERENCES,
  COMMUNITY_PREFERENCES,
  ACTIVITY_PREFERENCES
} from '../../common/Preferences';

import ChargebeeWrapper from '../ChargebeeWrapper';

import {
  DELETE_ACCOUNT_MODAL
} from '../ModalManager/ModalTypes';
import profileInputFields, {
  PASSWORD,
  OLD_PASSWORD,
  NEW_PASSWORD,
  NEW_PASSWORD_REPEAT,
  EMAIL_ADDRESS
} from '../../common/Input/CommonFields/profile';
import { collapseErrors } from '../../common/Input/CommonFields/validations';
import { modalOpen } from '../../store/Modal/actions';
import {
  toasterOpen,
  toasterClose,
  TOASTER_TYPE_ERROR
} from '../../store/Toaster/actions';
import { appGetIsUserSubscribed } from '../../store/App/actions';
import {
  userUpdate,
  userUpdateWithPassword,
  userGetId,
  userGetEmailAddress,
  userGetUsername
} from '../../store/User/actions';
import { preferencesGetExternal, preferencesUpdateExternal } from '../../store/Preferences/actions';
import SettingsBlock from './SettingsBlock';
import NotificationBlock from './NotificationBlock';

import PreferencesExternal from '../PreferencesExternal/PreferencesExternal';

import './Settings.scss';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newEmail: this.props.emailAddress,
      [OLD_PASSWORD]: '',
      [NEW_PASSWORD]: '',
      [NEW_PASSWORD_REPEAT]: '',
      errors: {
        email: {},
        password: {}
      },
      selection:'pick',
      loadedPreferences:{},
      preferences: COMMUNITY_PREFERENCES,
      activityPreferences: ACTIVITY_PREFERENCES,
      activity: 'Once a day',
      muted:false,
      preferenceHasChanged:false
    }
  }

  componentDidMount = () => {
    const { user } = this.props;
    const { activityPreferences } = this.state;
    if (user) {
      if (user.email_token) {
        this.props.preferencesGetExternal(user.email_token);
      }
      if (user.preferences) {
        Object.keys(activityPreferences).forEach(key => {
          if (user.preferences[key]) activityPreferences[key].value = user.preferences[key];
        });
        this.setState({activityPreferences});
      }
    }
    window.addEventListener('beforeunload', this.changeWarning);
    window.scrollTo(0, 0);
  }

  hasChanges = () => {
    const { newEmail, preferenceHasChanged } = this.state;
    const { emailAddress } = this.props;
    if (newEmail != emailAddress || preferenceHasChanged) {
      return true;
    }
    return false;
  }

  changeWarning = e => {
    if (this.hasChanges()) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    const { preferences } = props;

    let { loadedPreferences } = state;
    Object.keys(preferences).forEach(i => {
      if (i == 'selection' && preferences[i] !== loadedPreferences[i]) state.selection = preferences[i];
      if (state.preferences[i] && preferences[i] !== loadedPreferences[i]) state.preferences[i].checked = (preferences[i] == "true");
      if (state.preferences[i] && preferences[i + '_option'] !== loadedPreferences[i + '_option']) state.preferences[i].option = preferences[i + '_option'];
    });
    state.loadedPreferences = preferences;

    return state;
  }

  componentWillUnmount = () => {
    // temp fix to close toaster
    this.props.toasterClose();
    window.removeEventListener('beforeunload', this.changeWarning);
  }

  validatePassword = (password) => {
    let passwordErrors = compose(password, [
      INVALID_PASSWORD_LENGTH,
      INVALID_PASSWORD_CONTAIN_NUMBER,
      INVALID_PASSWORD_CONTAIN_LOWERCASE,
      INVALID_PASSWORD_CONTAIN_UPPERCASE
    ]);

    return passwordErrors;
  }

  handleEmailChange = (value, label, isValidated, errors) => {
    this.state.errors.email = errors;

    this.setState({ newEmail: value, errors: this.state.errors });
  }

  handlePasswordChange = (value, label) => {
    if(label !== 'old_password') {
      if (!value) {
        delete this.state.errors.password[label];
      } else {
        let passwordErrors = this.validatePassword(value);
        if(passwordErrors.length > 0) {
          this.state.errors.password[label] = passwordErrors;
        } else {
          delete this.state.errors.password[label];
        }
      }
    }

    this.setState({ [label]: value, errors: this.state.errors }, () => {
      this.checkPasswordFieldsFilled();
    });
  }

  checkPasswordFieldsFilled = () => {
    // TO DO: create pw component to be used in sign up / log in
    const oldPw = this.state[OLD_PASSWORD];
    const newPw = this.state[NEW_PASSWORD];
    const newPwConfirm = this.state[NEW_PASSWORD_REPEAT];

    if (newPw && newPwConfirm) {
      if (newPw === newPwConfirm && newPw !== oldPw) {
        delete this.state.errors.password['password_match'];
      } else if (newPw === newPwConfirm && newPw === oldPw) {
        this.state.errors.password['password_match'] = 'Please choose a different password';
      } else {
        this.state.errors.password['password_match'] = 'Passwords do not match';
      }
    }

    if (newPw && !oldPw || newPw && !newPwConfirm || newPwConfirm && !oldPw) {
      this.state.errors.password['password_fields'] = 'Please fill out all password fields';
    } else {
      delete this.state.errors.password['password_fields'];
    }

    this.setState({ errors: this.state.errors });
  }

  handleDeleteAccount = () => {
    this.props.modalOpen({ modal: DELETE_ACCOUNT_MODAL });
  }

  handleSave = e => {
    e.preventDefault();

    const formErrors = this.getFormErrors();

    if (formErrors.length) {
      this.props.toasterOpen({
        type: TOASTER_TYPE_ERROR,
        content: formErrors[0]
      })
      return null;
    }

    const { newEmail } = this.state;
    let preferences = Object.assign(
      {},
      this.state.activityPreferences
    );
    Object.keys(preferences).forEach(key => {
      preferences[key] = preferences[key].value
    });
    let newUserData = {preferences};

    if (newEmail && newEmail !== this.props.emailAddress) {
      newUserData[EMAIL_ADDRESS] = newEmail;
    }

    if (this.hasPasswordFilled()) {
      this.props.userUpdateWithPassword({
        password:{
          [OLD_PASSWORD]: this.state[OLD_PASSWORD],
          [NEW_PASSWORD]: this.state[NEW_PASSWORD],
          [NEW_PASSWORD_REPEAT]: this.state[NEW_PASSWORD_REPEAT]
        },
        user:{
          payload:newUserData,
          location:'settings'
        }
      });

      this.setState({
        [OLD_PASSWORD]: '',
        [NEW_PASSWORD]: '',
        [NEW_PASSWORD_REPEAT]: ''
      });
    } else {
      this.props.userUpdate(newUserData, 'settings');
    }
    
    const payload = this.mapStateToCM();
    this.props.preferencesUpdateExternal(payload);
    this.setState({preferenceHasChanged:false});
  }

  getFormErrors = () => {
    const { errors } = this.state;
    return collapseErrors({ ...errors.password, ...errors.email });
  }

  hasFormFilled = () => {
    const { newEmail } = this.state;
    const newPassword = this.state[NEW_PASSWORD];

    return !!(newEmail || (this.hasPasswordFilled()));
  }

  hasPasswordFilled = () => {
    return this.state[NEW_PASSWORD] && this.state[OLD_PASSWORD] && this.state[NEW_PASSWORD_REPEAT];
  }

  // onClickBillingInfo = () => {
  //   GTMpushDataLayerEvent({
  //     event: GTM_SETTINGS_BILLING_INFO,
  //     isUserSubscribed: this.props.isUserSubscribed
  //   });
  // }

  handlePreferenceChange = (preference) => {
    let { preferences } = this.state;
    let newPreferences = Object.assign({}, preferences);
    newPreferences[preference].checked = !newPreferences[preference].checked;
    this.setState({preferences:newPreferences, preferenceHasChanged:true});
  }

  handlePreferenceOptionChange = (preference, option) => {
    let { preferences } = this.state;
    let newPreferences = Object.assign({}, preferences);
    newPreferences[preference].option = option;
    this.setState({preferences:newPreferences, preferenceHasChanged:true});
  }

  handlePreferenceFrequencyChange = (selection) => {
    selection = selection.target.value;
    let { preferences } = this.state;
    if (selection == 'few') {
      preferences.member_mondays.checked = true;
      preferences.horoscopes.checked = false;
      preferences.digital_firesides.checked = false;
      preferences.community_news.checked = true;
    } else {
      preferences.member_mondays.checked = true;
      preferences.horoscopes.checked = true;
      preferences.digital_firesides.checked = true;
      preferences.community_news.checked = true;
    }
    this.setState({selection:selection, preferences, preferenceHasChanged:true});
  }

  handleActivityNotificationChange = (category, id, val) => {
    let preferences = this.state[category];
    preferences[id].value = val.value;
    this.setState({[category]:preferences, preferenceHasChanged:true});
  }

  mapPreferenceToSelect = (pref) => {
    return FREQUENCY_PREFERENCES.find(option => (pref == option.value));
  }

  handleMuteChange = (e) => {
    this.setState({muted:e.target.checked});
  }

  generateNotificationPreferenceList = (category) => {
    let preferences = this.state[category];
    let preferenceList = [];
    Object.keys(preferences).forEach(i => {
      preferenceList.push(
        <NotificationBlock
          key={i}
          category={category}
          id={i}
          label={preferences[i].label}
          description={preferences[i].description}
          value={this.mapPreferenceToSelect(preferences[i].value)}
          handleChange={this.handleActivityNotificationChange}
          options={FREQUENCY_PREFERENCES}
        />
      )
    });
    return preferenceList;
  }

  mapStateToCM = () => {
    const { user } = this.props;
    const { preferences, selection } = this.state;
    let payload = {
      token:user.email_token,
      preferences:{
        selection
      }
    };
    Object.keys(preferences).forEach((key) => {
      payload.preferences[key] = preferences[key].checked;
      if (preferences[key].option) {
        payload.preferences[key + '_option'] = preferences[key].option;
      }
    });
    return payload;
  }

  render() {
    const { isLoading, email, user } = this.props;
    const {
      preferences,
      selection,
      muted
    } = this.state;
    
    let params = qs.parse(this.props.location.search);

    return (
      <div className="PreferencesExternal__background">
        <Prompt when={this.hasChanges()} message={() => "Are you sure you want to leave? Changes may not be saved."} />
        <div className="PreferencesExternal__container">
          <h2 className="Settings__category-heading">My Account</h2>
          <p className="Settings__category-description">Update your basic account details.</p>
          <div className="PreferencesExternal__preferences">
            <form>
              <SettingsBlock header="Update email address">
                <Input
                  type="email"
                  theme={INPUT_SECONDARY}
                  {...profileInputFields[EMAIL_ADDRESS]}
                  onChange={this.handleEmailChange}
                  value={this.state.newEmail}
                  autoCompleteGoogle="new-password"
                  className="Settings__input"
                />
              </SettingsBlock>
              <h4 className="Settings__subheading">Update Password</h4>
              <SettingsBlock header="Old password">
                <Input
                  className="Settings__input"
                  theme={INPUT_SECONDARY}
                  onChange={this.handlePasswordChange}
                  {...{
                    ...profileInputFields[PASSWORD],
                    label: OLD_PASSWORD,
                    placeholder: 'Old Password'
                  }}
                  controlledValue={this.state[OLD_PASSWORD]}
                  errors={this.state.errors.password[OLD_PASSWORD]}
                  autoCompleteGoogle="new-password"
                  type='password'
                />
              </SettingsBlock>
              <SettingsBlock header="New password">
                <Input
                  className="Settings__input"
                  theme={INPUT_SECONDARY}
                  onChange={this.handlePasswordChange}
                  {...{
                    ...profileInputFields[PASSWORD],
                    label: NEW_PASSWORD,
                    placeholder: 'New Password'
                  }}
                  controlledValue={this.state[NEW_PASSWORD]}
                  errors={this.state.errors.password[NEW_PASSWORD]}
                  autoCompleteGoogle="new-password"
                  type='password'
                />
              </SettingsBlock>
              <SettingsBlock header="Confirm new password">
                <Input
                  className="Settings__input"
                  theme={INPUT_SECONDARY}
                  label={NEW_PASSWORD_REPEAT}
                  onChange={this.handlePasswordChange}
                  {...{
                    ...profileInputFields[PASSWORD],
                    label: NEW_PASSWORD_REPEAT,
                    placeholder: 'Confirm New Password'
                  }}
                  controlledValue={this.state[NEW_PASSWORD_REPEAT]}
                  errors={this.state.errors.password[NEW_PASSWORD_REPEAT]}
                  autoCompleteGoogle="new-password"
                  type='password'
                />
              </SettingsBlock>
            </form>
          </div>
          <Button
            className="Settings__update"
            theme={THEME_BLUE}
            onClick={this.handleSave}
            isLoading={this.props.isLoading}
            type="submit"
          >
            Update
          </Button>
          {/* <h2 className="Settings__category-heading">Membership Management</h2>
          <p className="Settings__category-description">Update your billing and preferences for your Pro membership.</p>
          <div className="PreferencesExternal__preferences">
            <form>
              <SettingsBlock
                header="Upgrade to Girlboss Pro"
                description="Join Girlboss Pro and get access to exclusive features and perks."
                row
              >
                <Button pill theme={THEME_BLUE} autoWidth>
                  Upgrade to Pro
                </Button>
              </SettingsBlock>
            </form>
          </div>
          <Button
            className="Settings__update"
            theme={THEME_BLUE}
            onClick={this.handleSave}
            isLoading={this.props.isLoading}
            type="submit"
          >
            Update
          </Button> */}
          <h2 className="Settings__category-heading">Your Activity Emails</h2>
          <p className="Settings__category-description">Manage how frequently you receive email notifications.</p>
          <div className="PreferencesExternal__preferences">
            { this.generateNotificationPreferenceList('activityPreferences') }
            {/* <div className="Settings__notification-block">
              <h5 className="Settings__notification-block-label">Mute notifications</h5>
              <div className="Settings__notification-block-action">
                <Checkbox
                  theme={THEME_BLUE_SLIDER}
                  onChange={this.handleMuteChange}
                  isChecked={muted}
                />
              </div>
            </div> */}
          </div>
          <Button
            className="Settings__update"
            theme={THEME_BLUE}
            onClick={this.handleSave}
            isLoading={this.props.isLoading}
            type="submit"
          >
            Update
          </Button>
          <h2 className="Settings__category-heading">Community Newsletters &amp; Updates</h2>
          <p className="Settings__category-description">Manage the emails you receive from Girlboss.</p>
          <PreferencesExternal
            isLoading={isLoading}
            email={email}
            user={user}
            token={params.t}
            preferences={preferences}
            selection={selection}
            handlePreferenceChange={this.handlePreferenceChange}
            handlePreferenceOptionChange={this.handlePreferenceOptionChange}
            handlePreferenceFrequencyChange={this.handlePreferenceFrequencyChange}
          />
          <Button
            className="Settings__update"
            theme={THEME_BLUE}
            onClick={this.handleSave}
            isLoading={this.props.isLoading}
            type="submit"
          >
            Update
          </Button>

          <div className="Settings__delete-account">
            <Button theme={THEME_BLACK} pill onClick={this.handleDeleteAccount}>
              Delete Account
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: userGetId(state),
    emailAddress: userGetEmailAddress(state),
    username: userGetUsername(state),
    isUserSubscribed: appGetIsUserSubscribed(state),
    isLoading: state.User.isLoading,
    userUpdateErrors: state.User.userUpdateErrors,
    userUpdateSuccess: state.User.userUpdateSuccess,
    email: state.Preferences.email,
    preferences: state.Preferences.external,
    saveCount: state.User.saveCount,
    errorCount: state.User.errorCount,
    isLoading: state.Preferences.isLoading,
    user: state.User.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args)),
    toasterOpen: (args) => dispatch(toasterOpen(args)),
    toasterClose: (args) => dispatch(toasterClose(args)),
    userUpdate: (...args) => dispatch(userUpdate(...args)),
    userUpdateWithPassword: (args) => dispatch(userUpdateWithPassword(args)),
    preferencesGetExternal: (args) => dispatch(preferencesGetExternal(args)),
    preferencesUpdateExternal: (args) => dispatch(preferencesUpdateExternal(args))
  }
}

export default (ChargebeeWrapper)(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
