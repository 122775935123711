import {
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_REQUEST_SUCCESS,
  SEND_CONTACT_REQUEST_ERROR,
  CONTACTS_GET,
  CONTACTS_GET_SUCCESS,
  CONTACTS_GET_ERROR,
  CONTACTS_GET_EMPTY,
  RESPOND_CONTACT_REQUEST_SUCCESS,
  RESPOND_CONTACT_REQUEST_ERROR,
  RENEW_CONTACT_REQUEST_STATE
} from './actions';

const initialState = {
  request: {},
  emptyContacts: false,
  contacts: [],
  errors: false,
  acceptedChannelUrl: null,
  pageIsLoading: false
};

function contactReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_CONTACT_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case SEND_CONTACT_REQUEST_SUCCESS: {
      const request = action.payload;

      return {
        ...state,
        request,
        isLoading: false
      };
    }
    case SEND_CONTACT_REQUEST_ERROR: {
      return {
        ...state,
        errors: action.payload,
        isLoading: false
      };
    }
    case CONTACTS_GET: {
      return {
        ...state,
        isLoading: true,
        pageIsLoading: true
      }
    }
    case CONTACTS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        contacts: action.payload,
        errors: false,
        pageIsLoading: false
      }
    }
    case CONTACTS_GET_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.error,
        contacts: [],
        pageIsLoading: false
      }
    } 
    case CONTACTS_GET_EMPTY: {
      return {
        ...state,
        emptyContacts: true
      }
    }
    case RESPOND_CONTACT_REQUEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errors: false,
        // acceptedChannelUrl: action.payload,
        acceptedChannelUrl: 'x',
        emptyContacts: false
      }
    }
    case RESPOND_CONTACT_REQUEST_ERROR: {
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
        acceptedChannelUrl: null
      }
    }
    case RENEW_CONTACT_REQUEST_STATE: {
      return {
        ...state,
        isLoading: false,
        errors: false,
        acceptedChannelUrl: null
      }
    }
    default: {
      return {
        ...state,
        isLoading: false
      };
    }
  }
}

export default contactReducer;
