import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputMadLib, { INPUT_MADLIB_WHITE } from '../../common/Input/InputMadLib';

import './Onboarding.scss';

class OnboardingUsername extends PureComponent {
  static propTypes = {
    username:PropTypes.string,
    handleUsernameChange:PropTypes.func.isRequired
  };
   
  render() {
    const {
      username,
      handleUsernameChange,
      errors
    } = this.props;

    return (
      <div className="Onboarding__page-container-username">
        <div>
          <h2 className="Onboarding__heading">Claim your name</h2>
          <h4 className="Onboarding__subheading">Your username is permanent, choose wisely.</h4>
        </div>
        <div className="Onboarding__username">
          <span className="Onboarding__username-url">girlboss.com/</span>
          <InputMadLib
            className="Onboarding__username-url-name"
            placeholder="username"
            maxLength={20}
            onChange={handleUsernameChange}
            theme={INPUT_MADLIB_WHITE}
            value={username}
          />
        </div>
        {
          (errors['username']) && (
            <span className="Onboarding__error">{errors['username'][0].message}</span>
          )
        }
      </div>
    )
  }
}

export default OnboardingUsername;
