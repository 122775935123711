import React, { Component } from 'react';
import { distanceInWordsToNow, distanceInWordsStrict, format } from 'date-fns';
import PropTypes from 'prop-types';

function getMomentFormat(date) {
  let fromNow = `${distanceInWordsToNow(date, {
    includeSeconds: true
  })} ago`;

  if (fromNow.substr(0, 6) === 'about ') {
    fromNow = fromNow.slice(6);
  }

  if (fromNow.substr(0, 10) === 'less than ') {
    fromNow = fromNow.slice(10);
  }

  if (fromNow.substr(0, 5) === 'half') {
    fromNow = fromNow.slice(5);
  }

  const splitTime = fromNow.split(' ');
  const isDays = splitTime.includes('day');
  const isBiggerThan3DaysAgo = splitTime[0] > 3;

  const isSmallerThanDay = (
    fromNow.includes('minute') ||
    fromNow.includes('second') ||
    fromNow.includes('hour')
  );

  if ((!isBiggerThan3DaysAgo && isDays) || isSmallerThanDay) {
    return fromNow;
  }

  return format(date, 'MMMM DD, YYYY');
};

function getMomentFormatShort(date) {
  let fromNow = `${distanceInWordsStrict(date, new Date())}`;

  let number = fromNow.split(' ')[0];
  let unit = fromNow.split(' ')[1];

  if(unit === 'second' || unit === 'seconds') {
        unit = 's';
  } else if(unit === 'minute' || unit === 'minutes') {
        unit = 'm';
  } else if(unit === 'hour' || unit === 'hours') {
        unit = 'h';
  } else if(unit === 'day' || unit === 'days') {
        if(number > 6) {
            number = Math.ceil(number / 7);
            unit = 'w';
        } else {
            unit = 'd';
        };
  } else if(unit === 'month' || unit === 'months') {
        number = Math.ceil((number * 30) / 7);
        unit = 'w';
  } else if(unit === 'year' || unit === 'years') {
        unit = 'y';
  };

  return number + unit;
};

class Moment extends Component {
  static propTypes = {
    date: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date)
    ]).isRequired,
    className: PropTypes.string,
    short: PropTypes.bool
  };

  static updateRegistry = new Set();

  static registerForUpdates(instance) {
    if (!this.updateRegistry.size) {
      this.updateTimer = setInterval(() => this.updateInstances(), 60000);
    }
    this.updateRegistry.add(instance);
  }

  static unregisterForUpdates(instance) {
    this.updateRegistry.delete(instance);
    if (this.updateTimer && this.updateRegistry.size === 0) {
      clearInterval(this.updateTimer);
      this.updateTimer = null;
    }
  }

  static updateInstances() {
    const currentTime = Date.now();
    this.updateRegistry.forEach(instance => instance.update(currentTime));
  }

  componentDidUpdate = prevProps => {
    if (this.props.date !== prevProps.date && !this.props.date) {
      Moment.unregisterForUpdates(this);
    }
  }

  componentWillUnmount() {
    Moment.unregisterForUpdates(this);
  }

  update(currentTime) {
    // Re-render if relative time is less than an hour,
    // or an hour has elasped since the last render.
    if (currentTime - this.timestamp < 3600000 || currentTime - this.lastUpdateTime > 3600000) {
      this.forceUpdate();
    }
  }

  render() {
    const { short, date } = this.props;
    if (!date) return '';

    // Register for updates if:
    // - Format is short and relative time is less than a week ago.
    // - Format is long and relative time is less than 43 hours ago.
    const currentTime = Date.now();
    const timestamp = new Date(date).getTime();

    if ((short && currentTime - timestamp < 604800000) || currentTime - timestamp < 154800000) {
      this.lastUpdateTime = currentTime;
      this.timestamp = timestamp;
      Moment.registerForUpdates(this);
    } else {
      Moment.unregisterForUpdates(this);
    }

    return short ? getMomentFormatShort(date) : getMomentFormat(date);
  }
}

export default Moment;
