import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ProfilePersonalityCard.scss';

class ProfilePersonalityCard extends Component {
  static propTypes = {
    isEditing:PropTypes.bool.isRequired,
    className:PropTypes.string
  }

  editCard = () => {
    this.props.editCard();
  }

  constructor(props) {
    super(props);
  }

  render() {

    const { isEditing, className } = this.props;

    return (
      <div data-aos="fade-up" className={
        classnames(
          "ProfilePersonalityCard",
          className
        )
      }>
        {this.props.children}
      </div>
    )
  }
}

export default ProfilePersonalityCard;