const laRally2017 = [
  {
    id: 208371283,
    thumbnail_id: 701580023,
    title: 'Welcome Remarks',
    description: '',
    featuring: 'Sophia Amoruso'
  },
  {
    id: 208354396,
    thumbnail_id: 624290889,
    title: 'Wellness for Weirdos',
    description: 'The Moon Juice founder gets real about separating fad from fact when it comes to eating plants, petals and Sex Dust. Ghee whiz!',
    featuring: 'Amanda Chantal Bacon in conversation with Elise Loehnen'
  },
  {
    id: 623648165,
    thumbnail_id: 623648165,
    title: 'Blow Your Own Mind: Sex, Health, and Consciousness',
    description: `The sexperts discuss society’s progress (and hangups) with female sexuality, and why getting real with yourself about what’s good, bad, liberating, and oppressive plays a huge role in all aspects of living our full truths.`,
    featuring: 'Liz Goldwyn in conversation with Tierney Finster'
  },
  {
    id: 208354728,
    thumbnail_id: 624290480,
    title: 'Pursuing your Dreams Fearlessly',
    description: 'The entrepreneur, celebrity publicist and author discusses how she went from living out of her car for a year after college to building a successful PR and marketing firm from the ground up, eventually snagging clients like Sony, Universal and Disney. Arian will share her 7 key steps on how to pursue your dreams fearlessly!',
    featuring: 'Arian Simone'
  },
  {
    id: 208192231,
    thumbnail_id: 624292488,
    title: 'The Myths and Realities of a Misunderstood Generation',
    description: 'The generational expert and trend leader talks about bridging the divide in the workplace, relationships and in life.',
    featuring: 'Jane Buckingham'
  },
  {
    id: 208352693,
    thumbnail_id: 624291424,
    title: 'On Confidence',
    description: 'Jess Weiner, the mind behind Dove and Barbie’s brand transformations, talks self-image in the world of social media and why comparison is the thief of joy when it comes to loving ourselves.',
    featuring: 'Jess Weiner'
  },
  {
    id: 208356301,
    thumbnail_id: 624289854,
    title: 'Commit to Something',
    description: 'The #1 New York Times bestselling author talks committing to happiness and focusing on our inner life in order to create the life we want. We’ll set intentions for the day to clear the blocks that could be standing in the way of your vision and goals.',
    featuring: 'Gabby Bernstein presented by Equinox'
  },
  {
    id: 208356284,
    thumbnail_id: 624290274,
    title: 'The Body Issue',
    description: `The star of Equinox’s striking Commit to Something campaign discusses her choice to have a pre-emptive double mastectomy, the reconstructive surgery that followed, her subsequent removal of her implants, and recognizing the beauty in owning her scars.`,
    featuring: 'Samantha Paige in conversation with Liz Nolan presented by Equinox'
  },
  {
    id: 208356294,
    thumbnail_id: 624290140,
    title: 'Humor is my Anesthesia',
    description: 'The comedian, writer, and producer talks work, relationships, navigating bullshit at high tide, and why everyone should have a dog. Everyone.',
    featuring: 'Whitney Cummings in conversation with Sophia Amoruso'
  },
  {
    id: 208358236,
    thumbnail_id: 624289616,
    title: `The World's Gone Mad`,
    description: 'Real talk from three game-changing women about WTF is going on in the world right now and what we can do about it. Because, seriously, WTF?',
    featuring: 'Jessica Bennett, Alyssa Mastromonaco, and Moj Mahdara in conversation with Deena Drewis'
  },
  {
    id: 208358243,
    thumbnail_id: 624289378,
    title: 'The Girlboss Foundation',
    description: 'Three Girlboss Foundation Grant recipients discuss the perks of starting small, why brand authenticity matters more than ever, and the digital tools that are leveling the playing field for small business owners everywhere.',
    featuring: 'Roseli Ilano, Deena Drewis, and Mara Lecocq in conversation with Sophia Amoruso'
  },
  {
    id: 208358247,
    thumbnail_id: 624289310,
    title: `The Best Career Advice You're Not Getting (And It's Not What You Think}`,
    description: `The co-founder and CEO of Ellevest and author of the book Own It: The Power of Women at Work has built an influential career by being frank when it comes to women, career, and finance; she brings the realness to everything they’re not telling you about leveling up in your career.`,
    featuring: 'Sallie Krawcheck'
  },
  {
    id: 208194680,
    thumbnail_id: 624292327,
    title: 'To Raise or Not to Raise?',
    description: `Everyone’s talking seed rounds and cap tables, but is seeking investors necessary (or smart) for all entrepreneurs? An investor, a former investor, and an entrepreneur dish on the pros and cons of sharing the pie.`,
    featuring: 'Susan Lyne, Sara Christensen, and Sarah Kunst in conversation with Moj Mahdara'
  },
  {
    id: 208372005,
    thumbnail_id: 624286686,
    title: 'How to Be a Bawse',
    description: 'The YouTube superstar discusses what she knows best: How to be a bawsey AF Girlboss—her rise, what she’s learned, and how she makes it all work—all while trying to remember to floss before bed.',
    featuring: 'Lilly Singh in conversation with Sophia Amoruso'
  },
  {
    id: 208358956,
    thumbnail_id: 624289113,
    title: 'Building a Better Mousetrap',
    description: `The founder of ClassPass, Brit + Co and True & CO join the Head of Content at Goop in a conversation about their careers and building companies that make women's lives better every day.`,
    featuring: 'Payal Kadakia, Brit Morin, Michelle Lam in conversation with Elise Loehnen'
  },
  {
    id: 208358961,
    thumbnail_id: 624288665,
    title: 'Follow the Leadership',
    description: `Two women who’ve spent years at the executive level for big brands get real about the struggles of leadership, team building, keeping your head above water when you’re deep in the weeds, and trusting yourself when all that’s left is your gut.`,
    featuring: `Sarah Robb O'Hagan in conversation with Sheree Waterson`
  },
  {
    id: 208358969,
    thumbnail_id: 624288518,
    title: 'Engineering Obsession',
    description: 'Founders of cult-favorite brands Outdoor Voices, Glossier, and Bumble talk with Sophia about how to stand out in the ever-widening sea of entrepreneurs and putting in the work on your most valuable relationship: the customer.',
    featuring: 'Tyler Haney, Emily Weiss, and Whitney Wolfe in conversation with Sophia Amoruso presented by Bumble'
  },
  {
    id: 208359837,
    thumbnail_id: 623655743,
    title: 'Zooming in on Instagram',
    description: 'The Instagram CEO chats with Sophia about what it’s like to keep up with a company that outpaces everyone’s expectations (even your own), and whether he ever anticipated the rise of the selfie.',
    featuring: 'Kevin Systrom in conversation with Sophia Amoruso'
  },
  {
    id: 208359843,
    thumbnail_id: 624288293,
    title: 'Storytelling is Everything',
    description: `Have an idea you can’t figure out how to pitch? Upset because your first business failed? Rachel Shechtman, Founder & CEO of NY based retail media concept STORY, will join us for an intimate conversation about her journey.`,
    featuring: 'Rachel Shechtman presented by Amex'
  },
  {
    id: 208359853,
    thumbnail_id: 703125178,
    title: '20 Years, 20 Mistakes',
    description: `The L’Oreal innovator reflects on her career and the beauty in the blunder.`,
    featuring: 'Rachel Weiss'
  },
  {
    id: 208377469,
    thumbnail_id: 624286219,
    title: 'The Website Dreams are Made Of',
    description: 'The founder of Go Live HQ demonstrates the process of creating a beautiful, functional website and discusses what separates a good web presence from an exceptional one.',
    featuring: 'Promise Tangeman presented by Squarespace'
  },
  {
    id: 208206929,
    thumbnail_id: 624292031,
    title: 'Creativity in the Wild',
    description: `The Executive Creative Director at VICE, LA’s favorite illustrator, a renegade fashion designer and a marketer-turned-entrepreneur discuss that timeless paying-bills-with-your-craft struggle and why it’s important to preserve your weirdness.`,
    featuring: 'Amel Monsur, Tuesday Bassen, Peggy Noland, and Nicole Williams in conversation with Bobby Hundreds'
  },
  {
    id: 208375691,
    thumbnail_id: 624286358,
    title: 'Going Viral for Good',
    description: 'Jess Weiner in conversation with Kelly Oxford, the #1 New York Times Bestselling Author and viral shit-starter on the importance of women owning our stories and using the tools we have to make an impact.',
    featuring: 'Kelly Oxford in conversation with Jess Weiner'
  },
  {
    id: 208354531,
    thumbnail_id: 624290638,
    title: 'Rebels in Film and TV',
    description: 'Boldly breaking with conventions, filmmakers, actors, industry creatives and experts, and producers are taking charge of their own representations—on and off screen. What will it really take to dismantle the Old Guard and create a variety of stories that reflect us all?',
    featuring: 'Kirsten Schaffer in conversation with Roya Rastegar'
  },
  {
    id: 208377471,
    thumbnail_id: 624286499,
    title: 'Crafting the Perfect Space',
    description: 'The coveted interior designer and the creative mind behind The Ace Hotel discuss the importance and how-tos of decking out a space in order to be the queen of your world.',
    featuring: 'Pam Shamshiri and Kelly Sawdon in conversation with Nikolai Haas'
  },
  {
    id: 208380509,
    thumbnail_id: 624284537,
    title: 'Girlboss on NETFLIX',
    description: 'The star and is executives of the forthcoming NETFLIX series Girlboss discuss the making of the series and creating fiction from fact.',
    featuring: 'Kay Cannon, Britt Robertson, Sophia Amoruso, Laverne McKinnon, and Audrey Fisher in conversation with Deena Drewis presented by NETFLIX'
  }
];

export default laRally2017;
