import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { 
  collectivesGetRecommended, 
  collectiveMemberAdd, 
  collectiveMemberRemove,
  collectiveMemberGet
 } from '../../../../store/Collectives/actions';
import { userUpdate } from '../../../../store/User/actions';
import { modalClose } from '../../../../store/Modal/actions';
import { feedGet, initFeed } from '../../../../store/Feed/actions';

import WelcomeModalMessage from './WelcomeModalMessage';
import WelcomeModalCollectiveMessage from './WelcomeModalCollectiveMessage';
import WelcomeModalCollectivesList from './WelcomeModalCollectivesList';
import Button, { THEME_BLUE } from '../../../../common/Button';

import './WelcomeModal.scss';

class WelcomeModal extends Component {
  state = {
    page: 1
  };

  componentDidMount = () => {
    this.props.collectivesGetRecommended({ limit: 100 })
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  onNextPage = () => {
    if(this.state.page === 3) {
      const params = {
        preferences: {
          welcome_modal_viewed: true
        }
      };
      this.props.mainFeedInit();
      this.props.initFeed();
      this.props.feedGet();
      this.props.userUpdate(params);
      this.props.modalClose();
      return;
    } 

    let page = this.state.page;
    page++;

    this.setState({
      page
    });
  }

  render() {
    const { page } = this.state;

    let buttonText = 'Next';
    if(page === 3) buttonText = 'Start a Convo';

    return (
      <div className="Modal WelcomeModal" ref={this.setRef}>
        <div className="WelcomeModal__container">
          {
            page === 1 && <WelcomeModalMessage />
          }

          {
            page === 2 && <WelcomeModalCollectiveMessage />
          }

          {
            page === 3 && 
              <WelcomeModalCollectivesList 
                collectives={this.props.recommendedCollectives} 
                user={this.props.user}
                collectiveMemberAdd={this.props.collectiveMemberAdd}
                collectiveMemberRemove={this.props.collectiveMemberRemove}
                modalClose={this.props.modalClose}
              />
          }


          <Button 
            pill 
            noOutline 
            onClick={this.onNextPage} 
            className="WelcomeModal__cta"
            theme={THEME_BLUE}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
    recommendedCollectives: state.Collectives.recommendedCollectives
  }
};

export default connect(mapStateToProps, {
  collectivesGetRecommended,
  collectiveMemberGet, 
  collectiveMemberAdd,
  collectiveMemberRemove,
  userUpdate,
  modalClose,
  feedGet,
  initFeed
})(WelcomeModal);
