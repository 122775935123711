import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FreeRoute from './FreeRoute';

import { profileGet } from '../../store/Profile/actions';
import { getProfileEntities } from '../../store/ProfileEntities/actions';

import { formatProfileUsername } from '../../lib/URL';
import {
  GTMpushDataLayerEvent,
  GTM_PROFILE_VIEW
} from '../../lib/GoogleTagManager';

function trackGTMprofile(referrer, userId) {
  GTMpushDataLayerEvent({
    event: GTM_PROFILE_VIEW,
    referrer,
    userId
  });
}

class ProfileRoute extends Component {
  static defaultProps = {
    initializeDataCall: () => {}
  };

  static propTypes = {
    initializeDataCall: PropTypes.func,
    profiles: PropTypes.shape({})
  };

  componentDidMount() {
    trackGTMprofile(this.props.location.pathname, this.getUsername());
  }

  getUsername = () => (
    formatProfileUsername(this.props.location.pathname)
  )

  componentWillReceiveProps = newProps => {
    const { user } = this.props;
    const oldPathname = this.props.location.pathname;
    const { pathname } = newProps.location;

    const username = formatProfileUsername(pathname);

    if (oldPathname !== pathname || user.username !== newProps.user.username) {
      this.loadProfile(username, newProps.user.id);
      trackGTMprofile(oldPathname, this.getUsername()); // gtm
    };
  }

  loadProfile = (username, userId) => {
    let userName = username || this.getUsername();

    const { profiles, user } = this.props;
    const hasSaved = profiles && profiles[userName] && profiles[userName].recent_activity;

    if (!profiles || !hasSaved || userId) {
      this.props.profileGet(userName, this.props.history);
    }
  }

  render() {
    return (
      <FreeRoute
        {...this.props}
        user={this.props.profiles[this.getUsername()]}
        initializeDataCall={this.loadProfile}
        publicRoute
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    profiles: getProfileEntities(state),
    user: state.User.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    profileGet: (...args) => dispatch(profileGet(...args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileRoute));
