import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './Button.scss';

export const THEME_CLEAR = 'clear';
export const THEME_BLACK = 'black';
export const THEME_YELLOW = 'yellow';
export const THEME_BLUE = 'blue';
export const THEME_ORANGE = 'orange';
export const THEME_RED = 'red';
export const THEME_NAVY = 'navy';
export const THEME_WHITE = 'white';
export const THEME_GRAY = 'gray';
export const THEME_LIGHT_BLUE = 'light-blue';

class Button extends Component {
  static defaultProps = {
    className: '',
    disabled: false,
    isLoading: false,
    noOutline: false,
    onClick: () => {},
    to: '',
    component: 'button',
    type: 'button'
  };

  static propTypes = {
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
      PropTypes.shape({})
    ]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    noOutline: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    pill: PropTypes.bool,
    autoWidth: PropTypes.bool,
    to: PropTypes.string,
    theme: PropTypes.oneOf([
      THEME_CLEAR,
      THEME_BLACK,
      THEME_BLUE,
      THEME_YELLOW,
      THEME_ORANGE,
      THEME_RED,
      THEME_NAVY,
      THEME_WHITE,
      THEME_GRAY,
      THEME_LIGHT_BLUE
    ]),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
  };

  render() {
    const {
      autoWidth,
      children,
      className,
      component,
      disabled,
      isLoading,
      noOutline,
      onClick,
      onSubmit,
      pill,
      tab,
      theme,
      to,
      type
    } = this.props;

    const Component = component;

    return (
      <Component
        className={classNames(
          'Button',
          `Button__${theme}`,
          className,
          {
            'Button--rounded': pill,
            'Button--tab': tab,
            'Button--auto-width': autoWidth,
            'Button--loading': isLoading,
            'Button--disabled': disabled,
            'Button--no-outline': noOutline
          }
        )}
        disabled={disabled}
        onClick={onClick}
        onSubmit={onSubmit}
        to={to}
        type={type}
      >
        { isLoading ? <Loader /> : children }
      </Component>
    )
  }
}

export default Button;

