
/**
  Store onboarding information.
**/
export const ONBOARDING_SAVE = 'ONBOARDING_SAVE';
export const ONBOARDING_SAVE_CLEAR = 'ONBOARDING_SAVE_CLEAR';
export const ONBOARDING_ERROR = 'ONBOARDING_ERROR';
export const ONBOARDING_ERROR_CLEAR = 'ONBOARDING_ERROR_CLEAR';
export const ONBOARDING_NEXT = 'ONBOARDING_NEXT';
export const ONBOARDING_BACK = 'ONBOARDING_BACK';
export const ONBOARDING_SKIP = 'ONBOARDING_SKIP';

export const onboardingSave = payload => {
  return {
    type: ONBOARDING_SAVE,
    payload
  }
};

export const onboardingSaveClear = () => {
  return {
    type: ONBOARDING_SAVE_CLEAR
  }
}

export const onboardingError = payload => {
  return {
    type: ONBOARDING_ERROR,
    payload
  }
};

export const onboardingErrorClear = () => {
  return {
    type: ONBOARDING_ERROR_CLEAR
  }
}

export const onboardingNext = () => {
  return {
    type: ONBOARDING_NEXT
  }
};

export const onboardingSkip = payload => {
  return {
    type: ONBOARDING_SKIP,
    payload
  }
};

export const onboardingBack = () => {
  return {
    type: ONBOARDING_BACK
  }
};

/**
 * Signup Onboarding
**/

export const ONBOARDING_INFO_CHECK_SUCCESS = 'ONBOARDING_INFO_CHECK_SUCCESS';
export const ONBOARDING_INFO_CHECK_ERROR = 'ONBOARDING_INFO_CHECK_ERROR';
export const ONBOARDING_USERNAME_CHECK_SUCCESS = 'ONBOARDING_USERNAME_CHECK_SUCCESS';
export const ONBOARDING_USERNAME_CHECK_ERROR = 'ONBOARDING_USERNAME_CHECK_ERROR';
export const ONBOARDING_COUNTRY = 'ONBOARDING_COUNTRY';
export const ONBOARDING_COUNTRY_INTERNATIONAL = 'ONBOARDING_COUNTRY_INTERNATIONAL';
export const ONBOARDING_PHONE_CHECK_SUCCESS = 'ONBOARDING_PHONE_CHECK_SUCCESS';
export const ONBOARDING_PHONE_CHECK_ERROR = 'ONBOARDING_PHONE_CHECK_ERROR';
export const ONBOARDING_SIGNUP_SUCCESS = 'ONBOARDING_SIGNUP_SUCCESS';
export const ONBOARDING_SIGNUP_ERROR = 'ONBOARDING_SIGNUP_ERROR';

export const onboardingInfoCheckSuccess = payload => {
  return {
    type: ONBOARDING_INFO_CHECK_SUCCESS,
    payload
  }
};

export const onboardingInfoCheckError = payload => {
  return {
    type: ONBOARDING_INFO_CHECK_ERROR,
    payload
  }
};

export const onboardingUsernameCheckSuccess = () => {
  return {
    type: ONBOARDING_USERNAME_CHECK_SUCCESS
  }
};

export const onboardingUsernameCheckError = payload => {
  return {
    type: ONBOARDING_USERNAME_CHECK_ERROR,
    payload
  }
};

export const onboardingCountry = payload => {
  return {
    type: ONBOARDING_COUNTRY,
    payload
  }
};

export const onboardingCountryInternational = payload => {
  return {
    type: ONBOARDING_COUNTRY_INTERNATIONAL,
    payload
  }
};

export const onboardingPhoneCheckSuccess = () => {
  return {
    type: ONBOARDING_PHONE_CHECK_SUCCESS
  }
};

export const onboardingPhoneCheckError = payload => {
  return {
    type: ONBOARDING_PHONE_CHECK_ERROR,
    payload
  }
};

export const onboardingSignupSuccess = () => {
  return {
    type: ONBOARDING_SIGNUP_SUCCESS
  }
};

export const onboardingSignupError = payload => {
  return {
    type: ONBOARDING_SIGNUP_ERROR,
    payload
  }
};

/**
 * Profile Onboarding
**/

export const ONBOARDING_PROFILE_STARTED = 'ONBOARDING_PROFILE_STARTED';
export const ONBOARDING_SAVE_PROFILE = 'ONBOARDING_SAVE_PROFILE';
export const ONBOARDING_SAVE_PROFILE_SUCCESS = 'ONBOARDING_SAVE_PROFILE_SUCCESS';
export const ONBOARDING_SAVE_PROFILE_ERROR = 'ONBOARDING_SAVE_PROFILE_ERROR';
export const ONBOARDING_PROFILE_COMPLETE = 'ONBOARDING_PROFILE_COMPLETE';

export const onboardingProfileStarted = () => {
  return {
    type: ONBOARDING_PROFILE_STARTED
  }
};

export const onboardingSaveProfile = payload => {
  return {
    type: ONBOARDING_SAVE_PROFILE,
    payload
  }
};

export const onboardingSaveProfileSuccess = payload => {
  return {
    type: ONBOARDING_SAVE_PROFILE_SUCCESS,
    payload
  }
};

export const onboardingSaveProfileError = payload => {
  return {
    type: ONBOARDING_SAVE_PROFILE_ERROR,
    payload
  }
};

export const onboardingProfileComplete = () => {
  return {
    type: ONBOARDING_PROFILE_COMPLETE
  }
};