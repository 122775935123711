import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProfilePhoto from '../../components/ProfilePhoto';

class GridLeader extends Component {
  static propTypes = {
    photo: PropTypes.string.isRequired,
    photoSize: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired
  };

  render() {
    const { photo, photoSize, name, jobTitle } = this.props;

    return (
      <div className="LandingPage__grid__leader">
        <ProfilePhoto alt={`${name}, ${jobTitle}`} photo={photo} size={photoSize} />
        <h4 className="LandingPage_leader__header">{name}</h4>
        <p className="LandingPage_leader__subheader">{jobTitle}</p>
      </div>
    )
  }
}

export default GridLeader;
