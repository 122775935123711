import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import routes from '../../config/routes';
import queryString from 'query-string';

import {
  GTMpushDataLayerEvent,
  GTM_SIGNUP_STARTED
} from '../../lib/GoogleTagManager';

import {
  authSignUp,
  authInfoCheck,
  authUsernameCheck,
  authPhoneCheck
} from '../../store/Auth/actions';
import {
  appGetIsUserLoggedIn
} from '../../store/App/actions';
import {
  onboardingSave,
  onboardingSaveClear,
  onboardingError,
  onboardingErrorClear,
  onboardingNext,
  onboardingBack,
  onboardingPasswordCheckError,
  onboardingPasswordCheckSuccess,
  onboardingSkip,
  onboardingCountry,
  onboardingCountryInternational
} from '../../store/Onboarding/actions';
import {
  inviteGet
} from '../../store/Invites/actions';

import RegistrationIcon from '../../../public/assets/onboarding/registration-icon.png';
import AboutYouIcon from '../../../public/assets/onboarding/about-you-icon.png';
import ProfileSetupIcon from '../../../public/assets/onboarding/profile-setup-icon.png';

import Onboarding from '../Onboarding';
import OnboardingBasics from './OnboardingBasics';
import OnboardingUsername from './OnboardingUsername';
import OnboardingCountry from './OnboardingCountry';
import OnboardingCountryInternational from './OnboardingCountryInternational';
import OnboardingTextVerificationRequest from './OnboardingTextVerificationRequest';
import OnboardingTextVerificationSubmit from './OnboardingTextVerificationSubmit';
import { preferencesAddToInternationalWaitlist, preferencesSetCountry } from '../../store/Preferences/actions';

class OnboardingSignup extends PureComponent {

  constructor(props) {
    super(props);
    props.onboardingSaveClear();
    props.onboardingSave();

    GTMpushDataLayerEvent({ event: GTM_SIGNUP_STARTED });

    const params = queryString.parse(location.search);
    if (params.invite_token) props.inviteGet(params.invite_token);

    props.onboardingSave({
      firstName: '',
      lastName: '',
      emailAddress: params.email || '',
      password: '',
      termsAndConditions: false,
      country: 'USA'
    });

    this.state = {
      addedToWaitlist: false
    }
  }

  next = () => {
    this.props.onboardingNext();
  }

  checkBasics = () => {
    const { emailAddress, password, firstName, lastName } = this.props.fields;
    const params = queryString.parse(location.search);

    this.props.authInfoCheck({
      email: emailAddress,
      first_name: firstName,
      last_name: lastName,
      password,
      utm_source: params.utm_source,
      utm_medium: params.utm_medium,
      utm_campaign: params.utm_campaign,
      utm_term: params.utm_term
    });
  }

  checkUsername = username => {
    this.props.authUsernameCheck({ username });
  }

  checkPhone = phoneNumber => {
    this.props.authPhoneCheck({
      phone_number: phoneNumber,
      country_code: '1'
    });
  }

  checkCountry = (token, country) => {
    this.props.preferencesSetCountry({ token, country });

    // [Remove when reenabling phone verification]
    window.removeEventListener('beforeunload', this.registerZapierHook);

    if (country != "USA") {
      this.props.onboardingCountryInternational(country);
      this.props.onboardingNext();
    } else {
      this.props.onboardingCountry();

      // [Uncomment when reenabling phone verification]
      // this.props.onboardingSkip(2);

      // [Remove when reenabling phone verification]
      this.signup();
    }
  }

  addToInternationalWaitlist = (token, country) => {
    this.props.preferencesAddToInternationalWaitlist({ token, country });
    this.setState({ addedToWaitlist: true });
  }

  registerZapierHook = (event) => {
    event.preventDefault();
    if (this.props.fields.emailAddress) {
      // Request has to be synchronous to ensure it gets delivered before the page closes.
      let request = new XMLHttpRequest();
      request.open('POST', 'https://hooks.zapier.com/hooks/catch/2555309/vaovu7', false);
      request.send({ email: this.props.fields.emailAddress });
    }
  }

  signup = () => {
    const { emailAddress, firstName, lastName, username, password, phoneNumber, smsCode } = this.props.fields;
    const params = queryString.parse(location.search);
    this.props.authSignUp({
      email: emailAddress,
      first_name: firstName,
      last_name: lastName,
      username,
      password,
      // [Uncomment when reenabling phone verification]
      // phone_number: phoneNumber.replace(/\D/g, ''),
      // country_code: '1',
      // sms_code: smsCode,
      inviteToken: params.invite_token
    });
  }

  submit = () => {
    this.checkEmail(this.props.fields.emailAddress);
  };

  render() {
    const params = queryString.parse(location.search);
    let collectiveURL = '';
    if (params.invite_token && this.props.invites.length) collectiveURL = this.props.invites[0].collective.slug;
    this.sections = [
      {
        title: 'Registration',
        icon: RegistrationIcon,
        pages: [
          {
            component: OnboardingBasics,
            data: {
              firstName: this.props.fields.firstName,
              lastName: this.props.fields.lastName,
              emailAddress: this.props.fields.emailAddress,
              password: this.props.fields.password,
              termsAndConditions: this.props.fields.termsAndConditions
            },
            handlers: {
              handleFirstNameChange: (value) => {
                this.props.onboardingSave({ firstName: value });
              },
              handleLastNameChange: (value) => {
                this.props.onboardingSave({ lastName: value });
              },
              handleEmailAddressChange: (value) => {
                this.props.onboardingSave({ emailAddress: value });
              },
              handlePasswordChange: (value) => {
                this.props.onboardingSave({ password: value });
              },
              handleTermsAndConditionsChange: () => {
                this.props.onboardingSave({ termsAndConditions: !this.props.fields.termsAndConditions });
              }
            },
            validate: () => {
              const { firstName, lastName, emailAddress, password, termsAndConditions } = this.props.fields;
              return !!(firstName && lastName && emailAddress && password && termsAndConditions);
            },
            next: () => {
              window.addEventListener('beforeunload', this.registerZapierHook);
              if (this.props.fields) {
                this.checkBasics();
              }
            },
            back: () => {
              this.props.onboardingBack();
            }
          },
          {
            component: OnboardingUsername,
            data: {
              username: this.props.fields.username
            },
            handlers: {
              handleUsernameChange: (value) => {
                this.props.onboardingSave({ username: value });
              }
            },
            validate: () => {
              const { username } = this.props.fields;
              return !!username;
            },
            next: () => {
              if (this.props.fields) {
                const { username } = this.props.fields;
                this.props.onboardingErrorClear();
                this.checkUsername(username);
              }
            },
            back: () => {
              this.props.onboardingBack();
            }
          },
          {
            component: OnboardingCountry,
            data: {
              country: this.props.fields.country
            },
            handlers: {
              handleCountryChange: (value) => {
                this.props.onboardingSave({ country: value.target.value });
              }
            },
            validate: () => {
              const { fields } = this.props;
              if (fields && typeof fields.country == 'string') {
                return true;
              }
              return false;
            },
            next: () => {
              if (this.props.fields) {
                this.checkCountry(this.props.emailToken, this.props.fields.country);
              }
            },
            back: () => {
              this.props.onboardingBack();
            }
          },
          {
            component: OnboardingCountryInternational,
            data: {
              emailAddress: this.props.fields.emailAddress
            },
            handlers: {},
            validate: () => {
              return !this.state.addedToWaitlist;
            },
            nextLabel: this.state.addedToWaitlist ? `Thanks - you're on the list!` : 'Keep me posted',
            nextClass: 'Onboarding__navigation-button-complete',
            backLabel: 'Follow us on IG',
            backClass: 'Onboarding__navigation-button-invite',
            next: () => {
              this.addToInternationalWaitlist(this.props.emailToken, this.props.fields.country);
            },
            back: () => {
              window.location = 'https://instagram.com/girlboss';
            }
          },
          // [Uncomment when reenabling phone verification]
          //
          // {
          //   component: OnboardingTextVerificationRequest,
          //   data: {
          //     phoneNumber: this.props.fields.phoneNumber
          //   },
          //   handlers: {
          //     handlePhoneNumberChange: (value) => {
          //       this.props.onboardingSave({ phoneNumber: value });
          //     }
          //   },
          //   validate: () => {
          //     const { fields } = this.props;
          //     return fields && !!fields.phoneNumber;
          //   },
          //   next: () => {
          //     if (this.props.fields && typeof this.props.fields.phoneNumber == 'string') {
          //       const phoneNumberNumeric = this.props.fields.phoneNumber.replace(/\D/g, '');
          //       this.checkPhone(phoneNumberNumeric);
          //     }
          //   },
          //   back: () => {
          //     this.props.onboardingSkip(-2);
          //   }
          // },
          // {
          //   component: OnboardingTextVerificationSubmit,
          //   data: {
          //     smsCode: this.props.fields.smsCode
          //   },
          //   handlers: {
          //     handleSmsCodeChange: (value) => {
          //       this.props.onboardingSave({ smsCode: value });
          //     }
          //   },
          //   validate: () => {
          //     if (this.props.fields && this.props.fields.smsCode) {
          //       return this.props.fields.smsCode.length >= 6;
          //     }
          //     return false;
          //   },
          //   next: () => {
          //     window.removeEventListener('beforeunload', this.registerZapierHook);
          //     this.signup();
          //   },
          //   back: () => {
          //     this.props.onboardingBack();
          //   }
          // },
          {
            component: () => {
              return <Redirect to={routes.onboarding + (collectiveURL ? `?collective=${collectiveURL}` : '')} />
            },
            data: {},
            handlers: {},
            validate: () => {
              return true;
            }
          }
        ]
      },
      {
        title: 'About You',
        icon: AboutYouIcon,
        pages: []
      },
      {
        title: 'Profile Setup',
        icon: ProfileSetupIcon,
        pages: []
      }
    ];

    return (
      <Onboarding
        sections={this.sections}
        state={this.props.fields}
        submit={this.submit}
        page={this.props.step}
        errors={this.props.errors}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    step: state.Onboarding.step,
    fields: state.Onboarding.fields,
    errors: state.Onboarding.errors,
    emailToken: state.Onboarding.emailToken,
    isLoading: state.Auth.isLoading,
    isLoggedIn: appGetIsUserLoggedIn(state),
    invites: state.Invites.invites
  }
};

export default connect(mapStateToProps, {
  onboardingSave,
  onboardingSaveClear,
  onboardingError,
  onboardingErrorClear,
  onboardingNext,
  onboardingSkip,
  onboardingBack,
  authSignUp,
  authInfoCheck,
  authUsernameCheck,
  authPhoneCheck,
  onboardingPasswordCheckError,
  onboardingPasswordCheckSuccess,
  onboardingCountry,
  onboardingCountryInternational,
  preferencesAddToInternationalWaitlist,
  preferencesSetCountry,
  inviteGet
})(OnboardingSignup);
