import React, { Component } from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input, { INPUT_PRIMARY_PLAIN } from '../Input';

import './Input.scss';

class InputWithIcon extends Component {
    static defaultProps = {
        className: '',
        disabled: false,
        onChange: () => {},
        onClick: () => {},
        theme: INPUT_PRIMARY_PLAIN
    };

    static propTypes = {
        autoCorrect: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        theme: PropTypes.string
    };

    onSubmit = e => {
        e.preventDefault();
        this.props.onClick();
    }

    render() {
        const { 
            addBefore,
            active, 
            autoCorrect,
            className, 
            iconComponent,
            onChange, 
            onClick,
            placeholder, 
            maxLength,
            theme,
            value
        } = this.props;

        return (
            <form className={classNames('InputWithIcon', className)} onSubmit={this.onSubmit}>
                { addBefore ? <span className="InputWithIcon__addBefore">{addBefore}</span> : null }
                <Input 
                    autoCorrect={autoCorrect}
                    onChange={onChange}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    value={value}
                    theme={theme}
                />
                <div className="InputWithIcon__icon"> 
                    {iconComponent}
                </div>
            </form>
        )
    }
}

export default InputWithIcon;