import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import './ConnectionList.scss';

import { modalOpen, modalClose } from '../../store/Modal/actions';
import { contactsGet } from '../../store/Contact/actions';

class ConnectionList extends Component {
  static defaultProps = {
    hasLoadedAllUsers: true
  }

  static propTypes = {
    request: PropTypes.object,
    users: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string
  }

  constructor (props) {
    super(props);
    if (this.props.user.id) {
      this.props.contactsGet();
    }
  }

  render () {
    const {
      users = [], 
      className,
      title,
      modalClose
    } = this.props;

    if (!users.length) return null;

    return (
      <div className={classNames("ConnectionList", className)}>
        { title && (<h4 className="ConnectionList__title">{title}</h4>) }
        <div className="ConnectionList__container" ref={this.setListRef}>
          {
            users.map(user => (
              <Link key={user.id} to={user.username}>
                <div className="ConnectionList__icon" style={{backgroundImage:`url(${user.photo.medium})`}}></div>
              </Link>
            ))
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ User }) => ({
  user:User.user
});

export default connect(mapStateToProps, {
  modalOpen,
  modalClose,
  contactsGet
})(ConnectionList);