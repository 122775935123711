import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { processImageFile } from '../../utils/imageUtil';
import SVG from 'react-inlinesvg';
import cameraIcon from '../../../public/assets/icons/CAMERA.svg';
import './ImageUploader.scss';

const MIN_BYTE_SIZE = 10000; // 10 KB
const MAX_BYTE_SIZE = 8000000; // 8 MB

// error legion
const PROCESSING_ERROR = 'Error processing image';
const UPLOAD_ERROR = 'Error uploading image';
const INVALID_IMAGE_TYPE = 'Please upload a JPEG or PNG';

class ImageUploader extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    uploadText: PropTypes.string,
    image: PropTypes.string,
    request: PropTypes.object,
    onSelect: PropTypes.func,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    imageName: PropTypes.string.isRequired
  };

  static defaultProps = {
    disabled: false,
    imageName: 'image'
  };

  constructor(props) {
    super(props);

    this.fileNode = {};

    this.state = {
      image: props.value || '',
      canSubmit: true,
      error: ''
    };

    this.fileRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.previewUrl !== this.state.previewUrl) {
      revokeObjectURL(prevState.previewUrl);
    }
  }

  componentWillUnmount() {
    revokeObjectURL(this.state.previewUrl);
  }

  setError = errorType => {
    this.setState({
      error: errorType
    });
  }

  readFile = e => {
    const { imageWidth, imageHeight } = this.props;

    // TODO: error handling
    const file = e.target.files[0];

    if (!file) return;
    
    this.setState({ error: '' });

    if(!(file.type === 'image/png' || file.type === 'image/jpeg')) {
      return this.setError(INVALID_IMAGE_TYPE);
    }

    processImageFile(file, imageWidth, imageHeight)
      .then(blob => {
        const { imageName, onSelect } = this.props;
        
        this.setState({ previewUrl: URL.createObjectURL(blob) });

        if (onSelect) {
          const data = new FormData();
          data.append(imageName, blob);
          onSelect(data);
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ error: PROCESSING_ERROR });
      })
  }

  renderFileFrame = () => {
    let { error } = this.state;
    const { image, uploadText, request } = this.props;
    let style;
    
    if (request.rejected) {
      error = UPLOAD_ERROR;
    }

    return (
      <div
        className={classNames(
          "ImageUploader__container--fileFrame",
          { 'ImageUploader__container--loading': !image || error || request.pending }
        )}
        style={style}
      >
        { 
          <div className="ImageUploader__overlay">
            <div className="ImageUploader__overlay--container">
              <div className="ImageUploader__camera-icon">
                <SVG src={cameraIcon} />
              </div>
              <div className="ImageUploader__button Button Button--rounded">
                {request.pending ? "Uploading..." : uploadText}
              </div>
              {error && <div className="ImageUploader__error-text">{error}</div> }
            </div>
          </div>
        }
      </div>
    );
  }

  selectFile = (e) => {
    e.preventDefault();
    this.fileRef.current.click();
  }

  render() {
    const { image, className, disabled, children } = this.props;
    const { previewUrl } = this.state;

    return (
      <form encType="multipart/form-data">
        <label htmlFor="picture" ref={this.saveRef}>
          <div 
            className={classNames("ImageUploader__preview", className)} 
            style={{
              backgroundImage: `url('${previewUrl || image}')`
            }}
          >
            {children && children}
            {this.renderFileFrame()}
            <input
              type="file"
              id="picture"
              accept="image/x-png,image/jpeg"
              name="avatar"
              className="ImageUploader__button--upload"
              onChange={this.readFile}
              disabled={disabled}
              autoFocus
              ref={this.fileRef}
            />
            </div>
        </label>
      </form>
    )
  }
}

// MS Edge throws an error if url is undefined.
function revokeObjectURL(url) {
  if (url) URL.revokeObjectURL(url);
}

export default ImageUploader;