import { takeEvery, put, call, select } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import {
  REACT_POST,
  reactPostSuccess,
  reactPostError,
  REACT_POST_REMOVE,
  reactPostRemoveSuccess,
  reactPostRemoveError,
  REACT_COMMENT,
  reactCommentSuccess,
  reactCommentError,
  REACT_COMMENT_REMOVE,
  reactCommentRemoveSuccess,
  reactCommentRemoveError,
  REACT_REPLY,
  reactReplySuccess,
  reactReplyError,
  REACT_REPLY_REMOVE,
  reactReplyRemoveSuccess,
  reactReplyRemoveError,
  reactCommentRemove,
  reactReplyRemove,
  reactPostRemove
} from './actions';
import {
  userGetId
} from '../User/actions';

export const convertReactionTypes = type => {
  switch (type) {
    case 1:
    default:
      return 'Praised';
  }
};

export const DEFAULT_REACTION = 1;

function* reactPostSaga(action) {
  const { postId } = action;

  const response = yield call(fetchAPI, 'reactPost', {
    body: {
      reaction_type: action.payload && action.payload.reaction_type || DEFAULT_REACTION,
    },
    post_id: postId
  });

  if (response.success) {
    yield put(reactPostSuccess(postId, response.reaction));
  } else {
    yield put(reactPostError(response));
  }
};

function* reactPostRemoveSaga(action) {
  const response = yield call(fetchAPI, 'deleteReactPost', {
    post_id: action.postId
  });

  if (response.success) {
    const userId = yield select(userGetId);
    yield put(reactPostRemoveSuccess(action.postId, userId, response));
  } else {
    yield put(reactPostRemoveError(response));
  }
};

function* reactCommentSaga(action) {
  const { commentId, postId } = action;

  const response = yield call(fetchAPI, 'reactComment', {
    body: {
      post_id: postId,
      reaction_type: action.payload && action.payload.reaction_type || DEFAULT_REACTION,
    },
    comment_id: commentId
  });

  if (response.success) {
    yield put(reactCommentSuccess(postId, commentId, response.reaction));
  } else {
    yield put(reactCommentError(response));
  }
}

function* reactCommentRemoveSaga(action) {
  const { commentId, postId } = action;
  const response = yield call(fetchAPI, 'deleteReactComment', {
    comment_id: commentId
  });

  if (response.success) {
    const userId = yield select(userGetId);
    yield put(reactCommentRemoveSuccess(postId, commentId, userId, response.reaction));
  } else {
    yield put(reactCommentRemoveError(response));
  }
}

function* reactReplySaga(action) {
  const { parentId, commentId, postId } = action;

  const response = yield call(fetchAPI, 'reactComment', {
    body: {
      post_id: postId,
      parent_id: parentId,
      reaction_type: action.payload && action.payload.reaction_type || DEFAULT_REACTION,
    },
    comment_id: commentId,
  });

  if (response.success) {
    yield put(reactReplySuccess(postId, parentId, commentId, response.reaction));
  } else {
    yield put(reactReplyError(response));
  }
}

function* reactReplyRemoveSaga(action) {
  const { parentId, commentId, postId } = action;
  const response = yield call(fetchAPI, 'deleteReactComment', {
    comment_id: commentId
  });

  if (response.success) {
    const userId = yield select(userGetId);
    yield put(reactReplyRemoveSuccess(postId, parentId, commentId, userId, response.reaction));
  } else {
    yield put(reactReplyRemoveError(response));
  }
}

export const reactionSagas = [
  takeEvery(REACT_POST, reactPostSaga),
  takeEvery(REACT_POST_REMOVE, reactPostRemoveSaga),
  takeEvery(REACT_COMMENT, reactCommentSaga),
  takeEvery(REACT_COMMENT_REMOVE, reactCommentRemoveSaga),
  takeEvery(REACT_REPLY, reactReplySaga),
  takeEvery(REACT_REPLY_REMOVE, reactReplyRemoveSaga)
];
