import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button, {
  THEME_BLUE
} from '../../../common/Button';
import Input from '../../../common/Input';
import postInputFields, {
  TITLE,
  BODY
} from '../../../common/Input/CommonFields/post';

import '../Post.scss';

class PostFeedInputFocus extends Component {
  static propTypes = {
    removeFocus: PropTypes.func.isRequired
  };

  handleKeyDown = e => {
    // esc key
    if (e.keyCode === 27) this.props.removeFocus();
  }

  handleClick = e => {
    if (this.containerRef && !this.containerRef.contains(e.target)) {
      this.props.removeFocus();
    }
  }

  componentDidMount = () => {
    if (this.inputRef) this.inputRef.focus();
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('click', this.handleClick);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('click', this.handleClick);
  }

  setRef = ref => {
    this.inputRef = ref;
  }

  setContainerRef = ref => {
    this.containerRef = ref;
  }

  render() {
    return (
      <div className="PostFocus" ref={this.setContainerRef}>
        <div className="PostFocus__title">
          <span className="PostFeed__title">Create a new post</span>
          <span
            className="PostFeed__icon--x"
            onClick={this.props.removeFocus}
          />
        </div>
        <form>
          <div className="PostFocus__header PostFocus__header--question required">
            Title
          </div>
          <Input
            placeholder="What's on your mind?"
            className="PostFocus__input PostFocus__input--question"
            component="textarea"
            setRef={this.setRef}
            {...postInputFields[TITLE]}
          />
          <div className="PostFocus__header PostFocus__header--body">
            Body
          </div>
          <Input
            placeholder="Anything else?"
            className="PostFocus__input PostFocus__input--body"
            component="textarea"
            {...postInputFields[BODY]}
          />
          <Button
            className="PostFocus__button--submit"
            theme={THEME_BLUE}
          >
            Post
          </Button>
        </form>
      </div>
    )
  }
}

export default PostFeedInputFocus;
