import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';

import {
  ONBOARDING_MODAL,
  UPDATE_SUBSCRIPTION_MODAL
} from '../ModalManager/ModalTypes';
import { modalOpen } from '../../store/Modal/actions';

import {
  appInitialize,
  appGetIsFullPageLoading,
  appGetIsInitializing,
  appGetIsUserLoggedIn,
  appGetIsUserSubscribed
} from '../../store/App/actions';

import { routes } from '../../config/routes';

class PrivateRoute extends Component {
  static defaultProps = {
    initializeDataCall: () => {}
  };

  static propTypes = {
    isFullPageLoading: PropTypes.bool,
    initializeDataCall: PropTypes.func
  };

  componentWillMount() {
    this.props.initializeDataCall();
  }

  componentDidMount() {
    if (!this.props.isUserSubscribed && this.props.isLoggedIn && !this.props.isFullPageLoading) {
      this.props.modalOpen({
        modal: UPDATE_SUBSCRIPTION_MODAL
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const isDiffURL = (this.props.location.pathname !== newProps.location.pathname);

    if (!newProps.isUserSubscribed && newProps.isLoggedIn && isDiffURL && !this.props.isFullPageLoading) {
      this.props.modalOpen({
        modal: UPDATE_SUBSCRIPTION_MODAL
      });
    }
  }

  render() {
    const {
      redirect,
      render,
      path,
      isFullPageLoading,
      isUserSubscribed,
      isLoggedIn
    } = this.props;

    let RenderComponent = render;
    let RedirectComponent = redirect;

    if (isFullPageLoading || isFullPageLoading === undefined) {
      return (
        <LoadingScreen
          isLoggedIn={this.props.isLoggedIn}
          isAppInitializing={this.props.isAppInitializing}
        />
      );
    };

    if (isLoggedIn && !isFullPageLoading) {
      // always show modal that user is unsubscribed
      if (!isUserSubscribed) {
        if (path !== this.props.location.pathname) {
          this.props.modalOpen({
            modal: UPDATE_SUBSCRIPTION_MODAL
          });
        }

        // navigate to settings if user is not alrdy on there
        if (path !== routes.settings) {
          this.props.modalOpen({
            modal: UPDATE_SUBSCRIPTION_MODAL
          });
          return <Redirect to={routes.settings} />;
        }
      }

      return (
        <Route render={() => (
          <RenderComponent {...this.props} />
        )} />
      );
    };

    if (!isFullPageLoading && RedirectComponent) {
      return (
        <Route render={() => (
          <RedirectComponent {...this.props} />
        )} />
      );
    };

    return <Redirect to="/" />;
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: appGetIsUserLoggedIn(state),
    isUserSubscribed: appGetIsUserSubscribed(state),
    isAppInitializing: appGetIsInitializing(state),
    isFullPageLoading: appGetIsFullPageLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
