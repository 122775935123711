import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const CARET_LEFT_THEME_DARK = '#193952';

class CaretLeftIcon extends PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf([CARET_LEFT_THEME_DARK])
  };

  static defaultProps = {
    theme: CARET_LEFT_THEME_DARK
  };

  render() {
    return (
      <svg width="34" height="34" viewBox="0 0 34 34" fill='none' xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5417 25.375L10.6083 16.4417C10.4911 16.3246 10.4252 16.1657 10.4252 16C10.4252 15.8343 10.4911 15.6754 10.6083 15.5583L19.5417 6.625" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
}

export default CaretLeftIcon;
