import { defineRequest } from '../../lib/reduxRequests';

/**
  Admires
**/

export const {
  ADMIRE_ADD,
  ADMIRE_ADD_FULFILLED,
  admireAdd
} = defineRequest('ADMIRE_ADD', params => ({
  key: params,
  cleanup: true
}));

export const {
  ADMIRE_REMOVE,
  ADMIRE_REMOVE_FULFILLED,
  admireRemove
} = defineRequest('ADMIRE_REMOVE', params => ({
  key: params,
  cleanup: true
}));

export const {
  ADMIRES_GET,
  ADMIRES_GET_FULFILLED,
  admiresGet
} = defineRequest('ADMIRES_GET', (params, key) => ({ key, payload: params }));

export const CLEAR_ADMIRERS = 'CLEAR_ADMIRERS';

export const admirersClear = (payload) => {
  return {
    type: CLEAR_ADMIRERS,
    payload
  }
}

export const {
  ADMIRERS_GET,
  ADMIRERS_GET_FULFILLED,
  admirersGet
} = defineRequest('ADMIRERS_GET', (params, key) => ({ key, payload: params }));

export const admiresGetOffset = state => state.Admires.offset;