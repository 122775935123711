import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './UserTagSelector.scss';
import UserWithHeadline from '../UserWithHeadline';

class UserTagSelector extends Component {
  static propTypes = {
    id:PropTypes.number,
    username:PropTypes.string,
    name:PropTypes.string,
    headline:PropTypes.string,
    photo:PropTypes.string,
    addTag:PropTypes.func,
    selected:PropTypes.bool
  };

  componentWillMount = () => {
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    const { selected, addTag, id, name } = this.props;
    if (e.keyCode == 13 && selected) {
      e.preventDefault();
      addTag(id, name);
    }
  }

  addTag = () => {
    const { addTag, id, name } = this.props;
    addTag(id, name);
  }

  render() {
    const { username, name, headline, photo, selected } = this.props;

    return <UserWithHeadline
      className={classNames(
        "UserTagEntry",
        {
          "UserWithHeadline__selected":selected
        }
      )}
      name={name}
      subheadline={headline}
      photo={photo}
      key={username}
      onClick={this.addTag}
    />
  }
}

const mapStateToProps = ({ User }) => {
  return {
    user: User.user
  }
};

export default connect(mapStateToProps, null)(withRouter(UserTagSelector));