import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FreeRoute from './FreeRoute';
import { getPost, getPostsWithUserEntity } from '../../store/Post/actions';
import {
  GTMpushDataLayerEvent,
  GTM_POST_VIEW
} from '../../lib/GoogleTagManager';

function trackGTMprofile(referrer) {
  GTMpushDataLayerEvent({
    event: GTM_POST_VIEW,
    referrer
  });
}

class PostRoute extends Component {
  static defaultProps = {
    initializeDataCall: () => {}
  };

  componentDidMount() {
    trackGTMprofile(this.props.location.pathname);
  }

  componentWillReceiveProps = newProps => {
    const { id } = newProps.computedMatch.params;

    if (this.props.computedMatch.params.id !== id) {
      this.loadPost(id);
      trackGTMprofile(this.props.location.pathname);
    };
  }

  loadPost = (postId) => {
    const id = postId || this.props.computedMatch.params.id;

    if (!this.props.posts[postId]) {
      this.props.getPost(id);
    }
  }

  render() {
    const id = this.props.computedMatch.params.id;
    return (
      <FreeRoute
        {...this.props}
        post={this.props.posts[id]}
        initializeDataCall={this.loadPost}
        publicRoute
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    posts: getPostsWithUserEntity(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPost: (args) => dispatch(getPost(args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostRoute);
