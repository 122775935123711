import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Notification from './index';

class NotificationAdmire extends Component {
  onClickNotification = () => {
    if (this.props.closeSideNav) this.props.closeSideNav();
    this.props.history.push(`/${this.props.sender.username}`);
  }

  render() {
    const { props } = this;

    const {
      contentClassName,
      sender
    } = this.props;

      return (
        <Notification
          onClick={this.onClickNotification}
          {...props}
        >
          <p
            className={classNames(
              "Notification__text--content",
              contentClassName
            )}
          >
           <Link className='NotificationText__text--link' to={`/${sender.username}`}>{sender.first_name} {sender.last_name}</Link> admired you!
          </p>
        </Notification>
      )

  }
}

export default withRouter(NotificationAdmire);
