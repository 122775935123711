export const REPORT = 'REPORT';
export const REPORT_SUCCESS = 'REPORT_SUCCESS';
export const REPORT_ERROR = 'REPORT_ERROR';

export const report = (report_type, payload, post_id, history) => {
  return {
    type: REPORT,
    report_type,
    payload,
    post_id,
    history
  }
};

export const reportSuccess = payload => {
  return {
    type: REPORT_SUCCESS,
    payload
  }
};

export const reportError = error => {
  return {
    type: REPORT_ERROR,
    error
  }
};


/*
  Report Post
*/

export const POST_REPORT = 'POST_REPORT';

export const reportPost = (postId) => {
  return {
    type: POST_REPORT,
    postId
  }
};

/*
  Report Comment
*/

export const COMMENT_REPORT = 'COMMENT_REPORT';

export const reportComment = (postId, commentId) => {
  return {
    type: COMMENT_REPORT,
    postId,
    commentId
  }
};