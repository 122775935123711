import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserWithHeadline from '../UserWithHeadline';
import UserActions from '../../containers/UserActions';

import Button from '../../common/Button';
import Input from '../../common/Input';

import UserTagParser from '../UserTagParser';

import {
  GTMpushDataLayerEvent,
  GTM_POST_VIEWMORE,
  GTM_COMMENT_VIEWMORE,
  GTM_REPLY_VIEWMORE
} from '../../lib/GoogleTagManager';

import './UserWithBody.scss';
import snippetize from '../../utils/snippetize';
import replaceHTMLChars from '../../utils/replaceHTMLChars';

export const TYPE_POST = 'post';
export const TYPE_COMMENT = 'comment';
export const TYPE_REPLY = 'reply';

export const MAX_WORD = 200;
export const MAX_WORD_INCREMENT = 5000;

function sliceBody(text, stop) {
  return snippetize(text, stop);
}

class UserWithBody extends PureComponent {
  static propTypes = {
    alt: PropTypes.string,
    actions: PropTypes.shape({}),
    body: PropTypes.string,
    bodyClassName: PropTypes.string,
    userTags:PropTypes.array,
    viewAll: PropTypes.bool,
    className: PropTypes.string,
    cancelEdit: PropTypes.func,
    name: PropTypes.string,
    nameClassName: PropTypes.string,
    headline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    isEditing: PropTypes.bool,
    photo: PropTypes.string,
    route: PropTypes.string,
    saveEdit: PropTypes.func,
    subheadline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    title: PropTypes.string,
    titleClassName: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.oneOf([
      'feed',
      'comment',
      'post',
      'thread'
    ])
  };

  constructor(props) {
    super(props);

    this.state = {
      sliceCount: MAX_WORD
    }
  }

  getBody = () => {
    const { variant, viewAll, route, userTags } = this.props;
    let body = replaceHTMLChars(this.props.body);
    const { sliceCount } = this.state;

    if (!body) return null;

    const bodyClassName = classNames(
      'UserWithBody__body',
      this.props.bodyClassName,
      {
        'fz-xs': variant === 'comment'
      }
    );

    if (!viewAll && this.state.sliceCount < this.props.body.length) {
      return (
        <span>
          <Link to={route}>
              <div className="UserWithBody__link" />
          </Link>
          <div className={classNames(bodyClassName, 'UserWithBody__clickable')}>
            {<UserTagParser body={sliceBody(body, sliceCount)} tags={userTags} />}
          </div>
          <Link to={route} className="UserWithBody__viewMore" onClick={this.viewMore}>...</Link>
        </span>
      )
    }

    if (!viewAll) {
      return (
        <span>
          <Link to={route}>
              <div className="UserWithBody__link" />
          </Link>
          <div className={classNames(bodyClassName, 'UserWithBody__clickable')}>
            {<UserTagParser body={body} tags={userTags} />}
          </div>
        </span>
      );
    }

    return (
      <span className={bodyClassName}>
        <UserTagParser body={body} tags={userTags} />
      </span>
    );
  }

  viewMore = () => {
    this.setState({
      sliceCount: this.state.sliceCount + MAX_WORD_INCREMENT
    });

    // gtm event
    let GTMeventName;

    if (TYPE_POST) GTMeventName = GTM_POST_VIEWMORE;
    if (TYPE_COMMENT) GTMeventName = GTM_COMMENT_VIEWMORE;
    if (TYPE_REPLY) GTMeventName = GTM_REPLY_VIEWMORE;

    GTMpushDataLayerEvent({
      event: GTMeventName,
      viewedMoreBodyLength: this.state.sliceCount,
      viewMoreStep: MAX_WORD_INCREMENT,
      totalBodyLength: this.props.body.length
    });
  }

  render() {
    const {
      alt,
      actions,
      body,
      bodyClassName,
      userTags,
      cancelEdit,
      className,
      userId,
      contentId,
      headline,
      isEditing,
      name,
      photo,
      route,
      readOnly,
      saveEdit,
      subheadline,
      timestamp,
      title,
      titleClassName,
      type,
      username,
      variant,
      currentUserId,
      showTimeStamp,
      showAdminBadge
    } = this.props;

    return (
      <div
        className={classNames(
          "UserWithBody",
          className,
          {
            'UserWithBody__container--reply': type === TYPE_REPLY,
          },
        )}
      >
        {
          (title && readOnly) && (
            <h2 className={
              classNames(
                titleClassName,
                `${titleClassName}--${variant}`
              )
            }>{title}</h2>
          )
        }
        {
          (title && !readOnly) && (
            <Link to={route}>
              <h2 className={titleClassName}>{title}</h2>
            </Link>
          )
        }
        <UserWithHeadline
          alt={alt}
          photo={photo}
          name={name}
          headline={headline}
          subheadline={subheadline}
          username={username}
          route={route}
          variant={variant}
          userId={userId}
          typeId={contentId}
          type={type}
          actions={actions}
          currentUserId={currentUserId}
          timestamp={timestamp}
          showTimeStamp={showTimeStamp}
          showAdminBadge={showAdminBadge}
        />
        {
          isEditing
          ? (
            <form onSubmit={saveEdit}>
              <Input
                className="UserWithBody__textarea--edit"
                component="textarea"
                onChange={this.props.handleOnChange}
                value={body}
              />
              <div className="UserWithBody__textarea--buttons">
                <Button
                  className="UserWithBody__textarea--button UserWithBody__textarea--saveButton"
                  onClick={saveEdit}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  className="UserWithBody__textarea--button"
                  onClick={cancelEdit}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )
          : this.getBody()
        }
      </div>
    )
  }
}

export default UserWithBody;