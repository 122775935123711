import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

import SocialThumbnail from '../../../public/assets/social-share-thumbnail.jpeg';

class AppHelmet extends PureComponent {
  render() {
    return (
      <Helmet
        defaultTitle="Girlboss"
      >
        <html lang="en" amp />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="author" content="Girlboss Community" />
        <meta name="theme-color" content="#4E5DD6" />

        <meta name="og:title" content="Girlboss" />
        <meta name="og:description" content="The Girlboss community is a private network of ambitious, influential women. Meet like-minded Girlbosses locally and across the country, and find the resources you need to level up. Ask questions, get answers, and share your knowledge." />
        <meta name="og:image" content={SocialThumbnail} />

        <meta name="description" content="The Girlboss community is a private network of ambitious, influential women. Meet like-minded Girlbosses locally and across the country, and find the resources you need to level up. Ask questions, get answers, and share your knowledge." />

        <script>
          {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
          ga('create', 'UA-83602486-6', 'auto');
          ga('require', 'GTM-W2D3BBS');
          ga('send', 'pageview');`}
        </script>
        <style>
          {`.async-hide { opacity: 0 !important}`}
        </style>
        <script>
          {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
          h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
          (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
          })(window,document.documentElement,'async-hide','dataLayer',0,
          {'GTM-W2D3BBS':true});`}
        </script>
        {/* <script>
          {`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1303221,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.id='hotjar-script'
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script> */}
        {/* Facebook Pixel Code */}
        <script>
          {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '2395910794022771'); 
          fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`<img height="1" width="1" src="https://www.facebook.com/tr?id=2395910794022771&ev=PageView&noscript=1"/>`}
        </noscript>
        {/* End Facebook Pixel Code */}
      </Helmet>
    )
  }
}

export default AppHelmet;
