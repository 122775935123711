import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ProfileField from './ProfileField';
import { DEGREES, MONTHS, PAST_PRESENT_YEARS } from '../../common/Dropdown/CommonFields';
import Checkbox from '../../common/Checkbox';

class JourneyCardWorkExperience extends Component {
  static propTypes = {
    card: PropTypes.object
  };

  constructor (props) {
    super(props);
  }

  render() {
    const {
      card,
      enableViewing,
      viewing
    } = this.props;

    if (!card || !card.card_data) return null;

    return (
      <React.Fragment>
        <div onClick={enableViewing} className="JourneyCard__default JourneyCard__experience">
          <h4>Work Experience</h4>
          <h5>{card.card_data.title} at {card.card_data.company}</h5>
          <h5 className="JourneyCard__default-date">{card.start_date.month} {card.start_date.year} - {card.current ? 'Present' : card.end_date.month + ' ' + card.end_date.year}</h5>
        </div>
        {
          (viewing && (card.location || card.card_data.industry || card.description)) && (
            <div className="JourneyCard__viewing-experience">
              <h5 className="JourneyCard__viewing-heading">{card.location}</h5>
              <h5 className="JourneyCard__viewing-heading">{card.card_data.industry}</h5>
              {
                card.description && (
                  <h5 className="JourneyCard__viewing-heading JourneyCard__viewing-summary">Summary</h5>
                )
              }
              <p>{card.description}</p>
            </div>
          )
        }
      </React.Fragment>
    )
  }
};

export default JourneyCardWorkExperience;