import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { checkAuth } from '../../../utils/checkAuth';
import {
  BODY
} from '../../../common/Input/CommonFields/post';

import InlineCommentingComment from '../../../components/InlineCommenting/InlineCommentingComment';
// import UserWithBody, { TYPE_COMMENT } from '../../../components/UserWithBody';
import UserCommentWithBody, { TYPE_COMMENT } from '../../../components/UserCommentWithBody';
import ReactionsAndCommentsText from '../../../components/ReactionsAndCommentsText';
import Moment from '../../../components/Moment';
import ReplyContainer from '../ReplyContainer';
import ReplyInputBox from '../ReplyInputBox';
import ProfilePhoto, { SIZE_SMALL } from '../../../components/ProfilePhoto';

import { selectRequest } from '../../../store/requests';
import {
  commentEdit,
  commentDelete,
  reply
} from '../../../store/Comment/actions';

import {
  GTMpushDataLayerEvent,
  GTM_COMMENT_PRAISE,
  GTM_COMMENT_UNPRAISE,
  GTM_REPLY_ON_COMMENT
} from '../../../lib/GoogleTagManager';

import './Comment.scss';
import { TYPE_REPLY } from '../../../components/UserWithBody';

class Comment extends PureComponent {
  static propTypes = {
    comment: PropTypes.shape({
      creator: PropTypes.shape({
        id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
      }),
      body: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      replies: PropTypes.arrayOf(PropTypes.shape({
        creator: PropTypes.shape({
          id: PropTypes.number.isRequired,
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired
        }),
        body: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired
      })),
      created_at: PropTypes.string.isRequired
    }),
    scrolledToRef: PropTypes.string,
    isReacted: PropTypes.bool,
    onReact: PropTypes.func.isRequired,
    onReactRemove: PropTypes.func.isRequired,
    onReactReply: PropTypes.func.isRequired,
    onReactReplyRemove: PropTypes.func.isRequired,
    onReply: PropTypes.func.isRequired,
    postId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    // We're using the replyKey to reset the input when a reply is submitted.
    this.state = {
      isEditing: false,
      isRepliesHidden: false,
      isReplyInputHidden: true,
      [BODY]: this.props.body,
      reply: '',
      replyKey: 0
    };
  }

  handleSaveEditComment = e => {
    e.preventDefault();

    if (this.state[BODY]) {
      this.props.commentEdit({
        commentId: this.props.id,
        [BODY]: this.state[BODY]
      });
    }
  }

  commentEditSuccess = () => {
    this.setState({ isEditing: false });
  }

  handleCancelEditComment = () => {
    this.setState({ isEditing: false });
  }

  handleEditComment = () => {
    this.setState({ isEditing: true });
  }

  handleDelete = () => {
    this.props.commentDelete({postId:this.props.postId, commentId:this.props.id});
  }

  handleOnCommentChange = val => {
    this.setState({ [BODY]: val });
  }

  onReact = () => {
    const { isReacted, id, postId } = this.props;

    if (isReacted) {
      this.props.onReactRemove(postId, id);
      GTMpushDataLayerEvent({ event: GTM_COMMENT_UNPRAISE });
    } else {
      this.props.onReact(postId, id);
      GTMpushDataLayerEvent({ event: GTM_COMMENT_PRAISE });
    }
  }


  setRef = ref => {
    this.inlineCommentingRef = ref;
  }

  setContentRef = (id, ref) => {
    const contentId = id || this.props.id;
    this.props.setRef(contentId, ref);
  }

  viewReplies = () => {
    this.setState({ isRepliesHidden: false });
    this.props.selectReplyBox(this.props.id);
  }

  showReplyInputBox = () => {
    this.setState({ isReplyInputHidden: false });
  }

  handleOnComment = checkAuth(() => {
    if (this.inlineCommentingRef) {
      this.inlineCommentingRef.focus();
    }

    this.viewReplies();
  })

  handleSubmitReply = (e) => {
    e.preventDefault();

    const { id, postId } = this.props;

    if (this.state.reply && this.state.reply.trim().length) {
      this.props.onReply({body: this.state.reply, parentId:id, postId});

      GTMpushDataLayerEvent({
        event: GTM_REPLY_ON_COMMENT,
        replyLength: this.state.reply.length
      });
    }
  }

  replySuccess = () => {
    this.setState({ reply: '', replyKey: this.state.replyKey + 1 });
  }

  handleReplyChange = value => {
    this.setState({ reply: value });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.selected_replybox === null && !this.props.isDesktop) {
      return 'hide_replies'
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot === 'hide_replies') {
      this.setState({
        isRepliesHidden: true
      })
    }
    if (prevProps.commentEditRequest.pending && this.props.commentEditRequest.fulfilled) {
      this.commentEditSuccess();
    }
    if (prevProps.replyRequest.pending && this.props.replyRequest.fulfilled) {
      this.replySuccess();
    }
  }

  render() {
    const {
      isEditing,
      isRepliesHidden,
      isReplyInputHidden,
    } = this.state;
    const {
      comment,
      currentUserId,
      postId,
      adminIds,
      isLoggedIn
    } = this.props;
    const {
      photo,
      first_name,
      last_name,
      headline,
      username
    } = comment.creator;

    const hasReplies = !!(comment.replies && comment.replies.length);

    const userPhoto = (typeof photo === 'string' ? photo : photo.thumbnail);

    return (
      <div className="Comment" style={{ paddingBottom: isReplyInputHidden && hasReplies ? '30px' : '0' }} ref={this.setContentRef} id={comment.id}>
        <div
          className={classnames(
            "Comment__container",
            {
              'Comment__withReplies': hasReplies || !isRepliesHidden
            }
          )}
        >
          <UserCommentWithBody
            userId={comment.creator.id}
            contentId={comment.id}
            photo={userPhoto}
            type={TYPE_COMMENT}
            name={`${first_name} ${last_name}`}
            headline={<Moment short date={comment.created_at} />}
            subheadline={headline}
            handleOnChange={this.handleOnCommentChange}
            body={comment.body}
            userTags={comment.user_tags}
            isEditing={isEditing}
            username={username}
            saveEdit={this.handleSaveEditComment}
            cancelEdit={this.handleCancelEditComment}
            variant="comment"
            currentUserId={currentUserId}
            showAdminBadge={adminIds ? adminIds.indexOf(comment.creator.id) > -1 : null}
          />
          <ReactionsAndCommentsText
            actions={{
              handleEdit: this.handleEditComment,
              handleDelete: this.handleDelete
            }}
            reactions={comment.reactions}
            commentsCount={comment.replies && comment.replies.length}
            onReact={this.onReact}
            onComment={this.handleOnComment}
            isReacted={this.props.isReacted}
            userId={comment.creator.id}
            currentUserId={currentUserId}
            contentId={comment.id}
            type={TYPE_COMMENT}
            timestamp={<Moment short date={comment.created_at} />}
            actions={{
              handleEdit: this.handleEditComment,
              handleDelete: this.handleDelete
            }}
            flagged={comment.flagged}
            postId={postId}
            reply={this.showReplyInputBox}
            isLoggedIn={isLoggedIn}
          />
        </div>
        {hasReplies && (
          <ReplyContainer
            replies={comment.replies}
            onComment={this.handleOnComment}
            isRepliesHidden={isRepliesHidden}
            viewReplies={this.viewReplies}
            onReact={this.props.onReactReply}
            onReactRemove={this.props.onReactReplyRemove}
            setRef={this.setContentRef}
            postId={postId}
            parentId={comment.id}
            currentUserId={currentUserId}
            type={TYPE_REPLY}
            adminIds={adminIds}
          />
        )}
        {
          isLoggedIn && !isRepliesHidden && !isReplyInputHidden && (
            <div className="Comment__replybox">
              <div className="Comment__replybox--photo">
                <ProfilePhoto
                  size={SIZE_SMALL}
                  photo={this.props.photo.thumbnail}
                />
              </div>
              <div className={classnames("Comment__replybox--input", {
                selected: this.props.selected_replybox === this.props.id
              })}>
                <ReplyInputBox
                  setRef={this.setRef}
                  onSubmit={this.handleSubmitReply}
                  handleChange={this.handleReplyChange}
                  value={this.state.reply}
                  creator={comment.creator}
                  key={this.state.replyKey}
                  placeholder="Leave a reply..."
                />
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.App.isLoggedIn,
    photo: state.User.user.photo,
    commentEditRequest: selectRequest(state, commentEdit),
    replyRequest: selectRequest(state, reply)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    commentEdit: (...args) => dispatch(commentEdit(...args)),
    commentDelete: (...args) => dispatch(commentDelete(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
