import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Nav, {
  NAV_TYPE_COMPONENT,
  NAV_TYPE_LINK
} from '../index.js';
import Button, {
  THEME_BLACK
} from '../../../common/Button';

import routes from '../../../config/routes';
import './HomepageNav.scss';

class HomepageNav extends Component {
  state = {
    viewportWidth: null
  }

  handleWindowResize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    })
  }

  componentDidMount = () => {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  render() {
    let location = window.location.href;
    let rightLinks;

    if(location.split('/').includes('login')) {
      rightLinks = [
        {
          type: NAV_TYPE_COMPONENT,
          component: (
            <Button
              noOutline
              component={Link}
              to={routes.signup}
              theme={THEME_BLACK}
              className="HomepageNav__join-btn"
            >
              Join
            </Button>
          )
        }
      ];
    } else {
      rightLinks = [
        {
          type: NAV_TYPE_COMPONENT,
          component: (
            <Link to={routes.logIn} className="HomepageNav__login-btn">Log In</Link>
          )
        },
        {
          type: NAV_TYPE_COMPONENT,
          component: (
            <Button
              component={Link}
              to={routes.signup}
              theme={THEME_BLACK}
             className="HomepageNav__join-btn"
          >
            Join
          </Button>
          )
        }
      ];
    };

    return (
      <Nav 
        rightLinks={rightLinks} 
        onDesktop={this.state.viewportWidth > 1023 ? true : false}
      />
    )
  }
}

export default HomepageNav;
