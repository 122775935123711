import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import crownIcon from '../../../public/assets/icons/CROWN.svg';

import './ProfilePhoto.scss';

export const SIZE_XSMALL = 'xsmall';
export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';

class ProfilePhoto extends PureComponent {
  static propTypes = {
    alt: PropTypes.string,
    photo: PropTypes.string.isRequired,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    size: PropTypes.oneOf([
      SIZE_XSMALL,
      SIZE_SMALL,
      SIZE_MEDIUM,
      SIZE_LARGE
    ]),
    to: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    alt: '',
    component: 'span',
    size: SIZE_SMALL,
    onClick: () => {}
  };

  render() {
    const {
      alt,
      component,
      children,
      className,
      onClick,
      parentClassName,
      photo,
      size,
      to,
      showAdminBadge
    } = this.props;

    const WrappedComponent = component;

    return (
      <WrappedComponent
        to={to}
        className={classNames(
          parentClassName,
          'ProfilePhoto__wrapped'
        )}
        onClick={onClick}
      >
        <div
          className={classNames(
            "ProfilePhoto",
            className,
            {
              'ProfilePhoto--xsmall': size === SIZE_XSMALL,
              'ProfilePhoto--large': size === SIZE_LARGE,
              'ProfilePhoto--medium': size === SIZE_MEDIUM,
              'ProfilePhoto--small': size === SIZE_SMALL
            }
          )}
          alt={alt}
          style={{
            backgroundImage: `url('${photo}')`
          }}
        >
          {showAdminBadge && <span className="ProfilePhoto__admin-badge">
            <SVG src={crownIcon} />
          </span>}
        </div>
      
        {children}
      </WrappedComponent>
    )
  }
}

export default ProfilePhoto;
