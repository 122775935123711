import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PlayButton from '../../components/PlayButton';

class Video extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    thumbnailId: PropTypes.number.isRequired,
    featuring: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string.isRequired
  }

  openVideo = () => {
    this.props.modalOpen({
      id: this.props.id,
      title: this.props.title,
      featuring: this.props.featuring,
      description: this.props.description
    });
  }

  render() {
    return (
      <div className="Video">
        <div className="Video__container--video" key={this.props.id}>
          <div
            className="Video__container--video--thumbnail"
            onClick={this.openVideo}
            style={{ backgroundImage: `url('https://i.vimeocdn.com/video/${this.props.thumbnailId}.jpg')`}}
          >
            <PlayButton />
          </div>
        </div>
        <div className="Video__container--info">
          <h4 className="Video__header">
            {this.props.title}
          </h4>
          <p>{this.props.featuring}</p>
        </div>
      </div>
    )
  }
};

export default Video;
