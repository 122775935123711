import React, { Component } from 'react'
import PropTypes from 'prop-types';

import Button from './index';

class ButtonToggle extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    pill: PropTypes.bool,
    noOutline: PropTypes.bool,
    checkedTheme: PropTypes.string,
    uncheckedTheme: PropTypes.string,
    checkedContent: PropTypes.element,
    uncheckedContent: PropTypes.element,
    onClick: PropTypes.func
  };

  render() {
    const {
      checkedTheme,
      disabled,
      uncheckedTheme,
      checkedContent,
      uncheckedContent,
      className,
      isChecked,
      onClick,
      pill,
      noOutline
    } = this.props;

    return (
      <Button
        disabled={disabled}
        className={className}
        onClick={onClick}
        pill={pill}
        noOutline={noOutline}
        theme={isChecked ? checkedTheme : uncheckedTheme}
      >
        {
          isChecked ? checkedContent : uncheckedContent
        }
      </Button>
    )
  }
}

export default ButtonToggle;