import { defineRequest } from '../../lib/reduxRequests';

/**
  Get Post
**/
export const GET_POST = 'GET_POST';
export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_ERROR = 'GET_POST_ERROR';

export const getPost = payload => {
  return {
    type: GET_POST,
    payload
  }
};

export const getPostSuccess = payload => {
  return {
    type: GET_POST_SUCCESS,
    payload
  }
};

export const getPostError = error => {
  return {
    type: GET_POST_ERROR,
    error
  }
};

/**
  Get Post Comments
**/

export const GET_POST_COMMENTS = 'GET_POST_COMMENTS';
export const GET_POST_COMMENTS_SUCCESS = 'GET_POST_COMMENTS_SUCCESS';
export const GET_POST_COMMENTS_ERROR = 'GET_POST_COMMENTS_ERROR';

export const getPostComments = payload => {
  return {
    type: GET_POST_COMMENTS,
    payload
  }
};

export const getPostCommentsSuccess = payload => {
  return {
    type: GET_POST_COMMENTS_SUCCESS,
    payload
  }
};

export const getPostCommentsError = error => {
  return {
    type: GET_POST_COMMENTS_ERROR,
    error
  }
};

/**
  Get Post Comment Replies
**/

export const GET_COMMENT_REPLIES = 'GET_COMMENT_REPLIES';
export const GET_COMMENT_REPLIES_SUCCESS = 'GET_COMMENT_REPLIES_SUCCESS';
export const GET_COMMENT_REPLIES_ERROR = 'GET_COMMENT_REPLIES_ERROR';

export const getCommentReplies = payload => {
  return {
    type: GET_COMMENT_REPLIES,
    payload
  }
};

export const getCommentRepliesSuccess = payload => {
  return {
    type: GET_COMMENT_REPLIES_SUCCESS,
    payload
  }
};

export const getCommentRepliesError = error => {
  return {
    type: GET_COMMENT_REPLIES_ERROR,
    error
  }
};

export const {
  POST_CHECK,
  POST_CHECK_FULFILLED,
  postCheck
} = defineRequest('POST_CHECK');

/**
  Post Post
**/
export const POST = 'POST';
export const POST_SUCCESS = 'POST_SUCCESS';
export const POST_ERROR = 'POST_ERROR';

export const post = payload => {
  return {
    type: POST,
    payload
  }
};

export const postSuccess = payload => {
  return {
    type: POST_SUCCESS,
    payload
  }
};

export const postError = error => {
  return {
    type: POST_ERROR,
    error
  }
};

/**
  Edit Post
**/
export const POST_EDIT = 'POST_EDIT';
export const POST_EDIT_SUCCESS = 'POST_EDIT_SUCCESS';
export const POST_EDIT_ERROR = 'POST_EDIT_ERROR';

export const postEdit = (id, payload) => {
  return {
    type: POST_EDIT,
    id,
    payload
  }
};

export const postEditSuccess = (postId, payload) => {
  return {
    type: POST_EDIT_SUCCESS,
    postId,
    payload
  }
};

export const postEditError = error => {
  return {
    type: POST_EDIT_ERROR,
    error
  }
};

/**
  Delete Post
**/
export const POST_DELETE = 'POST_DELETE';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_ERROR = 'POST_DELETE_ERROR';

export const postDelete = postId => {
  return {
    type: POST_DELETE,
    postId
  }
};

export const postDeleteSuccess = (postId) => {
  return {
    type: POST_DELETE_SUCCESS,
    postId
  }
};

export const postDeleteError = (postId, payload) => {
  return {
    type: POST_DELETE_ERROR,
    payload
  }
};


// selectors
export const getPosts = state => ( state.Post.posts );

// have users reference to user doc
export const getPostsWithUserEntity = state => {
  const postsState = getPosts(state);
  const posts = {};

  const userInfo = state.User.user;
  const userId = state.User.user.id;

  Object.keys(postsState).forEach(postKey => {
    const post = postsState[postKey];

    if (!post.creator) return null;

    // replace post creator to reference user info
    if (post.creator.id === userId) {
      post.creator = userInfo;
    };

    if (post.comments) {
      post.comments = post.comments.map(comment => {
        if (comment.creator && comment.creator.id === userId) {
          comment.creator = userInfo;
        }

        if (comment.replies) {
          // replace reply creator to reference user info
          comment.replies = comment.replies.map(reply => {
            if (reply.creator && reply.creator.id === userId) {
              reply.creator = userInfo;
            }
            return reply;
          });
        }

        return comment;
      })
    }

    posts[post.id] = post;
  });

  return posts;
};
