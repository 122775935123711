import {
  NOTIFS_GET,
  NOTIFS_GET_SUCCESS,
  NOTIFS_GET_ERROR,
  NOTIFS_READ_SUCCESS,
  NOTIFS_READ_ERROR,
  NOTIFS_READ_ALL_SUCCESS,
  NOTIFS_GET_MORE_SUCCESS,
  NOTIFS_GET_MESSAGES,
  NOTIFS_GET_MESSAGES_SUCCESS,
  NOTIFS_GET_MESSAGES_ERROR,
  RESET_NOTIFS_OFFSET,
  NOTIFS_UPDATE_SUCCESS
} from './actions';

const OFFSET_INCREASE = 20;

const initialState = {
  limit: OFFSET_INCREASE,
  offset: 0,
  ajaxOffset: 0,
  totalNotifsCount: OFFSET_INCREASE,
  hasLoadedAllNotifs: false,
  isLoading: false,
  notifications: [],
  unread_count: 0,
  unread_message_count: 0,
  error: null
};

function notifsReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFS_GET_SUCCESS: {
      const {
        notifications,
        unread_count,
        total_notifications_count
      } = action.payload;

      let offset;
      if(notifications.length < OFFSET_INCREASE) {
        offset = state.offset + notifications.length;
      } else {
       offset = state.offset + OFFSET_INCREASE;
      };

      return {
        ...state,
        notifications,
        unread_count,
        totalNotifsCount: total_notifications_count,
        isLoading: false,
        offset
      };
    };
    case NOTIFS_GET_MORE_SUCCESS: {
      const {
        notifications,
        unread_count,
        total_notifications_count
      } = action.payload;

      let offset;
      if(notifications.length < OFFSET_INCREASE) {
        offset = state.offset + notifications.length;
      } else {
       offset = state.offset + OFFSET_INCREASE;
      };

      const notifsMerge = [...state.notifications, ...notifications];
      const hasLoadedAllNotifs = notifsMerge.length >= (total_notifications_count || state.totalNotifsCount);
      
      return {
        ...state,
        offset,
        hasLoadedAllNotifs,
        notifications: notifsMerge,
        unread_count,
        totalNotifsCount: total_notifications_count,
        isLoading: false
      }
    }
    case NOTIFS_UPDATE_SUCCESS: {
      const {
        notifications,
        unread_count,
      } = action.payload;

      const notifsMerge = [...notifications, ...state.notifications];
      let offset = state.offset + notifications.length;

      return {
        ...state,
        notifications: notifsMerge,
        isLoading: false,
        offset,
        unread_count
      };
    };
    case NOTIFS_READ_SUCCESS: {
      const notifIds = action.payload;

      const notifications = state.notifications.slice().map(notification => {
        if (notifIds.includes(notification.id)) {
          notification.is_read = true;
        }

        return notification;
      });

      return {
        ...state,
        notifications,
        unread_count: state.unread_count - 1,
        isLoading: false
      }
    }
    case NOTIFS_READ_ERROR: {
      return {
        ...state,
        error: action.payload.message.message,
        isLoading: false
      }
    }
    case NOTIFS_READ_ALL_SUCCESS: {
      const {
        notifications
      } = action.payload;

      return {
        ...state,
        isLoading: false,
        notifications,
        unread_count: 0
      };
    };
    case NOTIFS_GET_ERROR: {
      return {
        ...state,
        isLoading: false
      }
    }
    case NOTIFS_GET: {
      return {
        ...state,
        isLoading: true
      }
    }
    case NOTIFS_GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        unread_message_count:action.payload,
        isLoading: false
      }
    }
    default:
      return {
        ...state,
        isLoading: false
      };
  };
};

export default notifsReducer;
