/**
  Log In
**/
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';

export const authLogIn = (payload, history) => {
  return {
    type: AUTH_LOGIN,
    payload,
    history
  }
};

export const authLogInSuccess = payload => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload
  }
};

export const authLogInError = payload => {
  return {
    type: AUTH_LOGIN_ERROR,
    payload
  }
};

/**
  Post Log In calls
**/
export const AUTH_LOGIN_POST = 'AUTH_LOGIN_POST';

export const authLogInPost = () => {
  return {
    type: AUTH_LOGIN_POST
  }
};

/**
  User SignUp
**/
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_ERROR = 'AUTH_SIGNUP_ERROR';

export const authSignUp = (payload, history) => {
  return {
    type: AUTH_SIGNUP,
    payload,
    history
  }
};

export const authSignUpSuccess = payload => {
  return {
    type: AUTH_SIGNUP_SUCCESS,
    payload
  }
};

export const authSignUpError = payload => {
  return {
    type: AUTH_SIGNUP_ERROR,
    payload
  }
};

/**
  User check for email address
**/
export const AUTH_INFO_CHECK = 'AUTH_INFO_CHECK';
export const AUTH_INFO_CHECK_SUCCESS = 'AUTH_INFO_CHECK_SUCCESS';
export const AUTH_INFO_CHECK_ERROR = 'AUTH_INFO_CHECK_ERROR';

export const authInfoCheck = payload => {
  return {
    type: AUTH_INFO_CHECK,
    payload
  }
};

export const authInfoCheckSuccess = payload => {
  return {
    type: AUTH_INFO_CHECK_SUCCESS,
    payload
  }
};

export const authInfoCheckError = payload => {
  return {
    type: AUTH_INFO_CHECK_ERROR,
    payload
  }
};

/**
  User check for username
**/
export const AUTH_USERNAME_CHECK = 'AUTH_USERNAME_CHECK';
export const AUTH_USERNAME_CHECK_SUCCESS = 'AUTH_USERNAME_CHECK_SUCCESS';
export const AUTH_USERNAME_CHECK_ERROR = 'AUTH_USERNAME_CHECK_ERROR';

export const authUsernameCheck = payload => {
  return {
    type: AUTH_USERNAME_CHECK,
    payload
  }
};

export const authUsernameCheckSuccess = payload => {
  return {
    type: AUTH_USERNAME_CHECK_SUCCESS,
    payload
  }
};

export const authUsernameCheckError = payload => {
  return {
    type: AUTH_USERNAME_CHECK_ERROR,
    payload
  }
};

/**
  User check for phone number
**/
export const AUTH_PHONE_CHECK = 'AUTH_PHONE_CHECK';
export const AUTH_PHONE_CHECK_SUCCESS = 'AUTH_PHONE_CHECK_SUCCESS';
export const AUTH_PHONE_CHECK_ERROR = 'AUTH_PHONE_CHECK_ERROR';

export const authPhoneCheck = payload => {
  return {
    type: AUTH_PHONE_CHECK,
    payload
  }
};

export const authPhoneCheckSuccess = payload => {
  return {
    type: AUTH_PHONE_CHECK_SUCCESS,
    payload
  }
};

export const authPhoneCheckError = payload => {
  return {
    type: AUTH_PHONE_CHECK_ERROR,
    payload
  }
};

/**
  Auth hold onto signup information
**/
export const AUTH_SIGNUP_SAVE = 'AUTH_SIGNUP_SAVE';
export const AUTH_SIGNUP_SAVE_CLEAR = 'AUTH_SIGNUP_SAVE_CLEAR';

export const authSignUpSave = payload => {
  return {
    type: AUTH_SIGNUP_SAVE,
    payload
  }
};

export const authSignUpSaveClear = () => {
  return {
    type: AUTH_SIGNUP_SAVE_CLEAR,
    payload
  }
}

export const AUTH_SIGNUP_BACK = 'AUTH_SIGNUP_BACK';

export const authSignUpBack = () => {
  return {
    type: AUTH_SIGNUP_BACK
  }
};

/**
  User Log out
**/
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const authLogOut = payload => {
  return {
    type: AUTH_LOGOUT,
    payload
  }
};

export const authLogOutSuccess = payload => {
  return {
    type: AUTH_LOGOUT_SUCCESS,
    payload
  }
};


/**
  Post Log In
**/
export const AUTH_POST_LOGIN = 'AUTH_POST_LOGIN';
export const AUTH_POST_LOGIN_COMPLETE = 'AUTH_POST_LOGIN_COMPLETE';

export const authPostLogin = () => {
  return {
    type: AUTH_POST_LOGIN
  }
};

export const authPostLoginComplete = () => {
  return {
    type: AUTH_POST_LOGIN_COMPLETE
  }
}


export const AUTH_ERRORS_CLEAR = 'AUTH_ERRORS_CLEAR';

export const authErrorsClear = () => {
  return {
    type: AUTH_ERRORS_CLEAR
  }
};

/**
  User subscription status
**/
export const AUTH_SUBSCRIPTION_NOT_FOUND = 'AUTH_SUBSCRIPTION_NOT_FOUND';
export const AUTH_SUBSCRIPTION_FOUND = 'AUTH_SUBSCRIPTION_FOUND';

export const authSubscriptionNotFound = () => {
  return {
    type: AUTH_SUBSCRIPTION_NOT_FOUND
  }
};

export const authSubscriptionFound = () => {
  return {
    type: AUTH_SUBSCRIPTION_FOUND
  }
};
