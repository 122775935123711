import React, { Component } from 'react';
import PropTypes from 'prop-types';

import JourneyCardViewMoreModal from './JourneyCardViewMoreModal';

import './JourneyCardModal.scss';

const MONTHS_ABBREVIATED = {
  January:'Jan',
  February:'Feb',
  March:'Mar',
  April:'Apr',
  May:'May',
  June:'Jun',
  July:'Jul',
  August:'Aug',
  September:'Sep',
  October:'Oct',
  November:'Nov',
  December:'Dec'
};

class JourneyCardViewMoreGirlbossMomentModal extends Component {
  static propTypes = {
    data:PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {

    const { data } = this.props;

    return (
      <JourneyCardViewMoreModal
        label={'Girlboss Moment'}
        data={data}
        modalClass={'girlboss-moment'}
        content={
          <div>
            <h4 className="journey-card-timeline">{ MONTHS_ABBREVIATED[data.start_date.month] } { data.start_date.year }</h4>
            <h2>{data.card_data.title}</h2>
    
            <h4>{data.location}</h4>
            <hr />
            <h6>Summary</h6>
            <p>{data.description}</p>
          </div>
        }
        {...this.props}
      />
    )
  }
}

export default JourneyCardViewMoreGirlbossMomentModal;
