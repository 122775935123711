import qs from 'query-string';

const getQueryParameter = (param) => {
  if (window.location.search) {
    const params = qs.parse(window.location.search.substr(1));
    return params[param];
  }
  return null;
};

export default getQueryParameter;