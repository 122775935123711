import React, { Component } from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  passwordResetRequest
} from '../../../store/PasswordReset/actions';

import Input, { INPUT_SECONDARY } from '../../../common/Input';
import Button, {
  THEME_BLACK
} from '../../../common/Button';


import {
  INVALID_EMAIL,
  INVALID_EMAIL_LENGTH,
  INVALID_EMOJIS,
  compose
} from '../../../common/InputV2/CommonFields/validations';

import './PasswordResetRequest.scss';

class RequestPasswordReset extends Component {
  constructor (props) {
    super(props);

    this.state = {
      email:'',
      emailSent:false,
      errors: null,
      showErrors: false
    }
  }

  handleChange = (value) => {
    if(!value) {
      this.state.errors = null;
      this.state.showErrors = false;
    } else {
      let emailErrors = compose(value, [
        INVALID_EMAIL,
        INVALID_EMAIL_LENGTH,
        INVALID_EMOJIS
      ]);
      if(emailErrors.length > 0) {
        this.state.errors = emailErrors;
      } else {
        this.state.errors = null;
      }
    }
    
    this.setState({
      email:value,
      errors: this.state.errors,
      showErrors: this.state.showErrors
    });
  }

  sendEmail = (e) => {
    e.preventDefault();
    this.setState({
      showErrors: true
    });
    if (!this.state.errors) {
      this.props.requestReset(this.checkIfEmailExists, {email:this.state.email});
    }
  }

  checkIfEmailExists = (exists) => {
    if (exists) {
      this.setState({
        emailSent:true
      });
    } else {
      this.setState({
        errors:['Email not found in account database.']
      });
    }
  }

  render () {
    return (
      <div className="container">
        <div className="row center">
            {
              (!this.state.emailSent) && (
                <form className="reset-container" onSubmit={this.sendEmail}>
                  <h2 className="reset">Find your account</h2>
                  <h4 className="reset">Enter your email below and we'll send you a link to reset your password</h4>
                  <Input
                    className="reset-input"
                    theme={INPUT_SECONDARY}
                    placeholder="Email"
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    errors={this.state.errors}
                    displayError={false}
                  />
                  <Button theme={THEME_BLACK} onClick={this.sendEmail} disabled={!this.state.email}>Submit</Button>
                  {this.state.showErrors && <p className="error" style={{marginTop:'20px'}}>{this.state.errors}</p>}
                </form>
              )
            }
            {
              (this.state.emailSent) && (
                <div className="reset-container">
                  <h2 className="reset">Check your inbox</h2>
                  <h4 className="reset">We just sent you a link to reset your password</h4>
                  <Button theme={THEME_BLACK} onClick={this.sendEmail}>Resend</Button>
                  <Link to="/login" className="reset-return-home">
                    Back to login
                  </Link>
                </div>
              )
            }
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestReset: (...args) => {dispatch(passwordResetRequest(...args))}
  }
};

export default connect(null, mapDispatchToProps)(RequestPasswordReset);