const initialState = {};

import {
  HOROSCOPES_GET_SUCCESS,
  HOROSCOPES_GET_ERROR
} from './actions';

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case HOROSCOPES_GET_SUCCESS:
      return {
        ...state,
        allHoroscopes: action.payload
      };
    default:
      return state;
  }
}

export default profileReducer;
