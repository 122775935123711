import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../common/Input';
import profileInputFields, { EMAIL_ADDRESS } from '../../../../common/Input/CommonFields/profile';

import './HomepageReferralModal.scss';

class HomepageReferralModalInput extends Component {
  static propTypes = {
    index: PropTypes.number,
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      fullName: '',
      fullNameValid: '',
      emailAddress: '',
      emailAddressValid: ''
    }
  }

  handleChangeEmail = (val, label, isValidated, errors) => {
    // email address is to collect current field input
    // email address valid is to send up whether or not the values are valid
    this.setState({
      emailAddress: val,
      emailAddressValid: (isValidated ? val : '')
    }, () => {
      this.sendEmailNameIfValid();
    });
  }

  handleChangeName = (val, label, isValidated, errors) => {
    this.setState({
      fullName: val,
      fullNameValid: (isValidated ? val : '')
    }, () => {
      this.sendEmailNameIfValid();
    });
  }

  sendEmailNameIfValid = (isValidated) => {
    const isValidFormInput = (this.state.fullNameValid && this.state.emailAddressValid);

    this.props.onChange(this.state, isValidFormInput, this.props.index);
  }

  render() {
    const { index, values } = this.props;

    // if no controlled value is passed in, refer to uncontrolled input
    let controlledFullNameValue = undefined;
    let controlledEmailValue = undefined;


    // if controlled value is passed in, fallback to empty str until input is filled
    if (values) {
      controlledFullNameValue = (values[index] ? values[index].fullName : '');
      controlledEmailValue = (values[index] ? values[index].emailAddress : '');
    }

    return (
      <div className="HomepageReferralModalInput">
        <Input
          className="HomepageReferralModalInput__input"
          displayError={false}
          placeholder="Full Name"
          onChange={this.handleChangeName}
          controlledValue={controlledFullNameValue}
          required={this.props.required}
        />
        <Input
          {...profileInputFields[EMAIL_ADDRESS]}
          className="HomepageReferralModalInput__input"
          displayError={false}
          placeholder="Email Address"
          controlledValue={controlledEmailValue}
          onChange={this.handleChangeEmail}
          required={this.props.required}
        />
      </div>
    )
  }
}

export default HomepageReferralModalInput;
