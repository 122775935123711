import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './SettingsBlock.scss';

class SettingsBlock extends Component {
  render() {
    const {
      header,
      description,
      children,
      row
    } = this.props;

    return (
      <div className={classNames("SettingsBlock", {"SettingsBlock__row":row})}>
        <div className="SettingsBlock__text-container">
          <p className="SettingsBlock__header">{header}</p>
          { description && (<p className="SettingsBlock__description">{description}</p>) }
        </div>
        {children}
      </div>
    )
  }
}

export default SettingsBlock;
