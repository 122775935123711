import {
  MAGIC_SIGNUP_TOKEN_VERIFY,
  MAGIC_SIGNUP_TOKEN_VERIFY_SUCCESS,
  MAGIC_SIGNUP_TOKEN_VERIFY_ERROR
} from './actions';

const initialState = {};

function magicReducer(state = initialState, action) {
  switch (action.type) {
    case MAGIC_SIGNUP_TOKEN_VERIFY:
      return { ...state, isLoading: true };
    case MAGIC_SIGNUP_TOKEN_VERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        token: action.payload.obscure_id
      };
    case MAGIC_SIGNUP_TOKEN_VERIFY_ERROR:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state;
  }
}

export default magicReducer;
