import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import UserWithBody, {
  TYPE_POST
} from '../../components/UserWithBody';
import ReactionsAndCommentsText from '../../components/ReactionsAndCommentsText';
import ReactAndCommentButtons from '../../components/ReactAndCommentButtons';
import Moment from '../../components/Moment';
import routes, { routeWithParams } from '../../config/routes';
import {
  reactPost,
  reactPostRemove
} from '../../store/Reactions/actions';
import { tags } from '../../components/ContentTags';

import './Post.scss';

class Post extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({}),
    post: PropTypes.shape({
      title: PropTypes.string.isRequired,
      priority: PropTypes.number,
      body: PropTypes.string,
      comments: PropTypes.array,
      creator: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        headline: PropTypes.string,
        username: PropTypes.string.isRequired
      }),
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
      comments: PropTypes.arrayOf(PropTypes.shape({
        creator: PropTypes.shape({
          first_name: PropTypes.string.isRequired,
          last_name: PropTypes.string.isRequired,
          headline: PropTypes.string,
          username: PropTypes.string.isRequired
        }),
        body: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        is_reacted: PropTypes.bool,
        comments: PropTypes.shape({}),
        user_tags: PropTypes.array
      })),
      total_deep_comment_count: PropTypes.number,
      is_reacted: PropTypes.bool,
    }),
    reactions: PropTypes.arrayOf(PropTypes.shape({
      // id: PropTypes.string.isRequired,
      // name: PropTypes.string.isRequired,
      // username: PropTypes.string.isRequired
    })),
    variant: PropTypes.oneOf(['thread', 'comment', 'feed']),
    onComment: PropTypes.func,
    onReact: PropTypes.func.isRequired,
    onReactRemove: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    viewAll: PropTypes.bool,
  };

  onReact = () => {
    const { post } = this.props;

    if (post.is_reacted) {
      this.props.onReactRemove(post.id);
    } else {
      this.props.onReact(post.id);
    }
  }

  renderTags = () => {
    if(this.props.post.topics) {
      return (
        <div className="Post__topic" onClick={this.goToPost}>
          {
            this.props.post.topics.map(topic => {
              return (
                <span key={topic} className={classnames('Post__topic--tag', {'selected': this.props.contentTags.indexOf(topic) > -1})}>
                  {tags[topic]}
                </span>
              );
            })
          }
        </div>
      );
    };
  };

  goToPost = e => {    
    if(e.target.id === 'collective-link' || window.location.pathname.includes('/post/')) return;
    const route = routeWithParams(routes.post, { id: this.props.post.id, slug: this.props.post.slug });
    this.props.history.push(route)
  }
   
  render() {
    const {
      isLoggedIn,
      isLoading,
      viewAll,
      variant,
      user,
      readOnly,
      showTimeStamp,
      post,
      isReacted,
      actions,
      hideCollectiveLine,
      showAdminBadge
    } = this.props;

    const route = routeWithParams(routes.post, { id: post.id, slug: post.slug });
    const photo = post.creator.photo;
    const userPhoto = (typeof photo === 'string' ? photo : photo.thumbnail);

    let postPriorityClass = '';
    if(post.priority != 0 && variant === 'feed') {
      if(post.priority < 3) {
        postPriorityClass = `Post__featured Post__featured--${post.priority}`;
      } else {
        postPriorityClass = `Post__featured Post__featured--3`;
      }
    };

    return (
      <div 
        data-aos="fade-up"
        className={"Post" + ' ' + postPriorityClass}
      >
        <div style={{cursor: window.location.pathname.includes('/post/') ? 'default' : 'pointer'}}>
          { 
            !hideCollectiveLine && post.collective &&  
            (<p onClick={this.goToPost} className="Post__collective-line">Posted in <Link id="collective-link" to={`/groups/${post.collective.slug}`}>{post.collective.name}</Link></p>)
          }
        
          {this.renderTags()}
          <UserWithBody
            alt={post.creator.first_name}
            actions={actions}
            timestamp={<Moment short date={post.created_at} />}
            name={`${post.creator.first_name} ${post.creator.last_name}`}
            userId={post.creator.id}
            username={post.creator.username}
            contentId={post.id}
            photo={userPhoto}
            subheadline={post.creator.headline}
            title={post.title}
            body={post.body}
            userTags={post.user_tags}
            viewAll={viewAll}
            route={route}
            type={TYPE_POST}
            readOnly={readOnly}
            titleClassName="Post__title"
            variant={variant}
            currentUserId={user.id}
            showTimeStamp={showTimeStamp}
            showAdminBadge={showAdminBadge}
          />
        </div>
        {/* <ReactionsAndCommentsText
          reactions={reactions}
          commentsCount={this.props.total_deep_comment_count}
          onComment={this.props.onComment}
          onReact={this.onReact}
          route={route}
          readOnly={this.props.readOnly}
          isText
        /> */}
        <ReactAndCommentButtons
          commentCount={post.total_deep_comment_count}
          reactionCount={post.reactions ? post.reactions.length : 0}
          onReact={this.onReact}
          onComment={this.props.onComment}
          route={route}
          reactions={isReacted}
          isReacted={post.is_reacted}
          variant={variant}
          isLoading={isLoading}
          isLoggedIn={isLoggedIn}
          timestamp={<Moment short date={post.created_at} />}
          userId={post.creator.id}
          actions={actions}
          contentId={post.id}
          type={TYPE_POST}
          currentUserId={user.id}
          flagged={post.flagged}
        />
      </div>
    )
  }
}

const mapStateToProps = ({ App, Post, User }) => {
  return {
    isLoggedIn: App.isLoggedIn,
    isLoading: Post.isLoading,
    user: User.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    reactPost: (args) => dispatch(reactPost(args)),
    reactPostRemove: (args) => dispatch(reactPostRemove(args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Post));
