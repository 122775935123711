import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { modalClose } from '../../../../store/Modal/actions';

import {
//   GTMpushDataLayerEvent,
//   GTM_CONTACT_REQUEST_STARTED,
//   GTM_CONTACT_REQUEST_INTENT_SELECTED
} from '../../../../lib/GoogleTagManager';

import './ContactRequestInitiatorModal.scss';
import { init } from 'events';


const connection = {
  name: 'Sophia Amoruso',
  photo: 'https://storage.googleapis.com/girlboss_profile_pictures/avatar_default.png',
};

class ContactRequestInitiatorModal extends Component {
  static propTypes = {
    user:PropTypes.object,
    send:PropTypes.func
  };

//   constructor(props) {
//     super(props);

//     this.state = {
//       stage: 1
//     }
//   }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  goToChat = () => {
    // this.props.acceptContactRequest();
    console.log('go to chat clicked');

    this.props.history.push('/messaging');
  }

  onDismiss = () => {
    // this.props.ignoreConickedtactRequest();
    console.log('dismiss cl');
    this.props.modalClose();
  }


  render() {
    const { user } = this.props;

    return (
      <div className={classNames("ContactRequestInitiatorModal")} ref={this.setRef}>
        <h2 className="ContactRequestInitiatorModal__title">You and {connection.name} are now connected!</h2>

        <div className="ContactRequestInitiatorModal__connected">
                <img src={connection.photo} />
                <img src={connection.photo} />
        </div>

        <div className="ContactRequestInitiatorModal__cta">
                <button onClick={this.onDismiss}>Dismiss</button>
                <button onClick={this.goToChat}>Go Chat</button>
        </div>   
      </div>
    )
  }
}

export default connect(null, { modalClose })(withRouter(ContactRequestInitiatorModal));
