import { matchPath } from 'react-router-dom';

export const routes = {
  root: '/',
  collective: '/groups/:slug',
  collectiveDirectory: '/groups',
  directory: '/directory',
  instagram: '/ig',
  invite: '/invite',
  logIn: '/login',
  logOut: '/logout',
  passwordResetRequest: '/request-reset',
  passwordReset: '/reset/:token',
  passwordResetSuccess: '/reset-success',
  preferencesInternal: '/preferences',
  preferencesExternal: '/preferences/email',
  news: '/news',
  notifications: '/notifications',
  signup: '/signup',
  onboarding: '/onboarding',
  post: '/post/:id/:slug',
  postThreadComment: '/post/:id/:post_title/:id',
  myProfile: '/profile',
  requestInvite: '/request-invite',
  settings: '/settings',
  signUp: '/signup',
  videos: '/videos',
  messaging: '/messaging',
  messagingConversation: '/messaging/:conversation_id?',
  notFound: '/404',
  errorPage: '/503',
  accountNotFound: '/invalid-user',
  username: '/:username'
};

export const systemNoticeRouteNames = [
  'root',
  'collective',
  'collectiveDirectory',
  'directory',
  'notifications',
  'post',
  'postThreadComment',
  'myProfile',
  'messaging',
  'messagingConversation',
  'username'
];

export const girlbossComLinks = {
  about: 'https://www.girlboss.com/about',
  careers: 'https://jobs.lever.co/girlboss',
  home: 'https://www.girlboss.com',
  privacy: '/privacy',
  acceptableUse: '/acceptable-use',
  terms: '/terms-of-use',
  communityGuidelines: '/community-guidelines',
  faq: '/faq',
};

export const socialLinks = {
  facebook: 'https://www.facebook.com/girlbossmedia',
  instagram: 'https://www.instagram.com/girlboss',
  linkedin: 'https://www.linkedin.com/company/girlboss',
  pinterest: 'https://www.pinterest.com/girlboss',
  twitter: 'https://twitter.com/girlboss'
};

// ie:
// route: '/thread/:id/:post_title'
// params { id: 123, post_title: 'hey_there' }
// output => 'thread/123/hey_there'
export function routeWithParams(route, params) {
  let routeWithParamsFilled = route;
  Object.keys(params).forEach(key => {
    routeWithParamsFilled = routeWithParamsFilled.replace(`:${key}`, params[key]);
  });
  return routeWithParamsFilled;
}

export function parseTitleToUnderscore(title) {
  return title.split(' ').join('_');
}

export function matchRouteName(pathname, options) {
  for (const name of Object.keys(routes)) {
    const match = matchPath(pathname, { path: routes[name], ...options });
    if (match) return name;
  }
}

export function testSystemNoticeRoute(pathname) {
  const routeName = matchRouteName(pathname, { exact: true });
  return routeName && systemNoticeRouteNames.includes(routeName);
}

export default routes;
