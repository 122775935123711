import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputMadLib, { INPUT_MADLIB_WHITE } from '../../common/Input/InputMadLib';

import './Onboarding.scss';

class OnboardingGoodAt extends PureComponent {
  static propTypes = {
    im_good_at: PropTypes.string,
    handleGoodAtChange: PropTypes.func.isRequired,
    isMobile: PropTypes.bool
  };
   
  render() {
    const {
      im_good_at,
      handleGoodAtChange
    } = this.props;

    return (
      <div className="Onboarding__page-container-good-at">
        <div className="Onboarding__heading-container-bio">
          <h2 className="Onboarding__heading">What skills or knowledge can you contribute?</h2>
        </div>
        <div className="Onboarding__i-am-container">
          <span className="Onboarding__i-am" style={{marginRight:'12px'}}>I'm good at </span>
          <span className="Onboarding__i-am-input-container">
            <InputMadLib 
              placeholder="making spreadsheets"
              className="Onboarding__i-am"
              onChange={handleGoodAtChange}
              onBlur={handleGoodAtChange}
              value={im_good_at}
              maxLength={130}
              theme={INPUT_MADLIB_WHITE}
            />
            <span className="Onboarding__i-am" style={{display: 'flex', flexDirection: 'column-reverse', marginBottom: '17px'}}>.</span>
          </span>
        </div>
      </div>
    )
  }
}

export default OnboardingGoodAt;
