import { defineRequest } from '../../lib/reduxRequests';

/**
  Search
**/

export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const searchClear = () => {
  return {
    type: CLEAR_SEARCH
  }
}

export const SEARCH_SET_VIEWING = 'SEARCH_SET_VIEWING';

export const searchSetViewing = (payload) => {
  return {
    type: SEARCH_SET_VIEWING,
    payload
  }
}

export const SEARCH_SET_QUERY = 'SEARCH_SET_QUERY';

export const searchSetQuery = (payload, key) => {
  return {
    type: SEARCH_SET_QUERY,
    payload,
    key
  }
}

export const SEARCH_QUERY = 'SEARCH_QUERY';

export const searchQuery = (payload, key) => {
  return {
    type: SEARCH_QUERY,
    payload,
    key
  }
}

export const SEARCH_CHECK_CLEAR = 'SEARCH_CHECK_CLEAR';

export const searchCheckClear = (payload, key) => {
  return {
    type: SEARCH_CHECK_CLEAR,
    payload,
    key
  }
}

export const SEARCH_SET_POSITION = 'SEARCH_SET_POSITION';

export const searchSetPosition = (payload) => {
  return {
    type: SEARCH_SET_POSITION,
    payload
  }
}

export const {
  SEARCH_GET,
  SEARCH_GET_FULFILLED,
  searchGet
} = defineRequest('SEARCH_GET', (params, key) => ({ key, payload: params }));

export const searchGetOffset = state => state.Search.offset;