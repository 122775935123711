export const FREQUENCY_PREFERENCES = [
  {
    value:'realtime',
    label:'Real time'
  },
  {
    value:'daily',
    label:'Once a day'
  },
  {
    value:'twiceaday',
    label:'Twice a day'
  },
  {
    value:'weekly',
    label:'Weekly'
  },
  {
    value:'never',
    label:'Never'
  },
];

export const ACTIVITY_PREFERENCES = {
  my_posts: {
    label:'My Posts',
    description:`Stay up to speed with who’s commenting on your posts, 
    who’s responding to your replies, and when you’re tagged in a comment or post.`,
    value:'realtime'
  },
  my_network: {
    label:'My Network',
    description:`Watch your network grow! See who has sent you a connection request 
    and who has admired you.`,
    value:'realtime'
  },
  // [Uncomment when reenabling messaging]
  // unread_message: {
  //   label:'Messages',
  //   description:`Find out when another member sends you a private message.`,
  //   value:'realtime'
  // },
  connection_request_accepted: {
    label:'Connection Requests',
    description:`Get updates on when Girlboss members accept your connection requests 
    so you can say hey!`,
    value:'realtime'
  },
  post_in_collective: {
    label:'My Collectives',
    description:`Stay in the loop with the conversations going on in each of the 
    Collectives you’re subscribed to.`,
    value:'twiceaday'
  },
  praises: {
    label:'Praises',
    description:`Keep track of all the praises your posts and replies are getting.`,
    value:'daily'
  }
}

export const COMMUNITY_PREFERENCES = {
  member_mondays:{
    label:'Member Monday',
    description:`New members you’ll want to meet—straight to your inbox. Plus, updates on upcoming local meetups and 
    Digital Firesides. Sent weekly on Mondays.`,
    checked:true
  },
  horoscopes:{
    label:'Horoscope Emails',
    description:`Get up-to-date info on how the moon, stars, and everything in-between will be affecting your 
    day-to-day life—and find out exactly how to deal with it—with updates from our resident astrologer. Sent 
    weekly on Wednesdays.`,
    checked:true
  },
  digital_firesides:{
    label:'Digital Fireside Reminders',
    description:`Get reminder emails about our weekly Digital Firesides, so you don’t miss out on your chance to 
    get answers to life’s burning Qs, from some truly brilliant women. Sent weekly on Thursdays.`,
    checked:true
  },
  community_news:{
    label:'Girlboss News & Updates',
    description:`We’ll let you know when we launch something new and exciting on the Girlboss professional 
    network. Sent as we make updates, never more than 3 times a month!`,
    checked:true
  }
}


export const EMAIL_PREFERENCES = {
  newsletter:{
    label:'Newsletter',
    description:`All of our good stuff, wrapped up in one email. Every Tuesday, you’ll receive the latest and greatest 
    Girlboss stories, curated reads, and horoscopes, plus updates on everything we’re cooking up for you 
    at Girlboss HQ.`,
    // options:[
    //   'Twice monthly',
    //   'Once monthly'
    // ],
    // option:'Twice monthly',
    checked:true
  },
  mentors:{
    label:'Girlboss Mentors',
    description:`About once a week, you’ll get a dedicated email from Sophia or other intelligent and insightful female 
    leaders that have lots of good advice to share.`,
    checked:true
  },
  radio_network:{
    label:'Girlboss Radio Network',
    description:`Meet guests from Girlboss Radio, learn about the latest podcast series we're dropping, and updates 
    from Girlboss and our past guests.`,
    checked:true
  },
  rally:{
    label:'Girlboss Rally',
    description:`The Girlboss Rally is where our online community comes together IRL for a weekend of panels, workshops, 
    networking, and more. You’ll receive the latest updates on our upcoming events and featured speakers.`,
    checked:true
  },
  international_waitlist:{
    label:'The Girlboss International Waitlist',
    description:`The Girlboss professional network is an online resource we’re building where you can ask tough 
    questions, get real answers, and connect with like-minded women. We’re still in beta, but we’re working hard 
    to open up access across the globe! If you’re US-based, you can sign up here, but if you’re outside of the US, 
    subscribe now to make sure you’re invited as soon as we do.`,
    checked:false
  },
  sweeps:{
    label:'Girlboss Sweeps',
    description:`Whether it’s extra cash to put into your business, or a luxury vacation in the name of self-care, 
    we’ll curate the best prizes and send you a chance to win them.`,
    checked:true
  },
  invites:{
    label:'Invites',
    description:`Receive personal invitations from members who want to start a conversation with you!`,
    checked:true
  }
}
