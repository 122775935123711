import { takeEvery, take, put, call, select } from 'redux-saga/effects';
import fetchAPI from '../../services/APIService';
import { removeToken } from '../../lib/localStorage';
import {
  ONBOARDING_PROFILE_COMPLETE
} from './actions';

function* onboardingSagaProfileComplete() {
  const profileCompleteResponse = yield call(fetchAPI, 'profileComplete', { body: {} });
}

export const onboardingSagas = [
  takeEvery(ONBOARDING_PROFILE_COMPLETE, onboardingSagaProfileComplete)
];
