import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './ContactRequestResponseModal.scss';

class ContactRequestResponseAccepted extends Component {
  static propTypes = {
    sender:PropTypes.object,
    receiver:PropTypes.object
  };

  render() {
    const { sender, receiver } = this.props;

    return (
        <div>        
            <h2 className="ContactRequestResponseModal__title">You and {sender.first_name} are now connected!</h2>

            <div className="ContactRequestResponseModal__connected">
              <div
                className="ContactRequestResponseModal__connected--thumbnail"
                style={
                  {
                    backgroundImage:`url(${receiver.photo.medium})`
                  }
                }
              />
              <div
                className="ContactRequestResponseModal__connected--thumbnail"
                style={
                  {
                    backgroundImage:`url(${sender.photo.medium})`
                  }
                }
              />
            </div>
            
            <p className="ContactRequestResponseModal__disclaimer"  style={{ marginTop: '20px' }}>
              Your emails are now visible to each other on your profiles.
            </p>

            <Link
              className="Button ContactRequestModal__submit-button"
              to={`/${sender.username}`}
            >
              View Profile
            </Link>
        </div>
    )
  }
}

export default ContactRequestResponseAccepted;
