/**
  Open + close modal
**/
export const MAGIC_SIGNUP_TOKEN_VERIFY = 'MAGIC_SIGNUP_TOKEN_VERIFY';
export const MAGIC_SIGNUP_TOKEN_VERIFY_SUCCESS = 'MAGIC_SIGNUP_TOKEN_VERIFY_SUCCESS';
export const MAGIC_SIGNUP_TOKEN_VERIFY_ERROR = 'MAGIC_SIGNUP_TOKEN_VERIFY_ERROR';

export const magicSignUpTokenVerify = payload => {
  return {
    type: MAGIC_SIGNUP_TOKEN_VERIFY,
    payload
  }
};

export const magicSignUpTokenVerifySuccess = payload => {
  return {
    type: MAGIC_SIGNUP_TOKEN_VERIFY_SUCCESS,
    payload
  }
};

export const magicSignUpTokenVerifyError = payload => {
  return {
    type: MAGIC_SIGNUP_TOKEN_VERIFY_ERROR,
    payload
  }
};
