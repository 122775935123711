import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ellipsize from 'ellipsize';

import chatIcon from '../../../public/assets/icons/CHAT.svg';
import praiseIcon from '../../../public/assets/icons/PRAISE.svg';
import CollectivesListItem from '../Collectives/CollectivesList/CollectivesListItem';
import UserTagParser from '../../components/UserTagParser';


class Activity extends PureComponent {
  static propTypes = {

  };

  sliceBody = (text) => {
    return ellipsize(text, 200, { ellipse: '...'});
  }

  POST_LABELS = {
    1: "commented in",
    2: "replied to a comment in",
    3: "replied to a comment in",
    4: "praised a post in",
    5: "praised a comment in",
    6: "posted in"
  }

  POST_CONTENT = {
    1:(activity) => (
      <div className="Activity__post Activity__body-text-comment">
        <UserTagParser body={this.sliceBody(activity.post_activity.comment_content.body)} />
      </div>
    ),
    2:(activity) => (
      <div className="Activity__post Activity__body-text-comment">
        <UserTagParser body={this.sliceBody(activity.post_activity.comment_content.body)} />
      </div>
    ),
    3:(activity) => (
      <div className="Activity__post Activity__body-text-comment">
        <UserTagParser body={this.sliceBody(activity.post_activity.comment_content.body)} />
      </div>
    ),
    4:(activity) => (
      this.renderPost(activity)
    ),
    5:(activity) => (
      <div className="Activity__post Activity__body-text-comment">
        <UserTagParser body={this.sliceBody(activity.post_activity.comment_content.body)} />
      </div>
    ),
    6:(activity) => (
      this.renderPost(activity)
    )
  }

  renderAdmire = (activity) => (
    <div className="UserListItem">
      <div className="UserListItem__container">
        <div className="UserListItem__image" style={{backgroundImage:`url(${activity.receiver.photo.medium})`}} />
        <div className="UserListItem__info">
          <h4>{activity.receiver.first_name} {activity.receiver.last_name}</h4>
          <p>{activity.receiver.industry}</p>
          <p>{activity.receiver.location}</p>
        </div>
      </div>
      <Link onClick={this.props.close} to={`/${activity.receiver.username}`}>
        <div className="UserListItem__container-link" />
      </Link>
    </div>
  )

  renderPost = (activity) => {
    const commentCount = activity.post_activity.post_content.total_deep_comment_count;
    const reactionCount = activity.post_activity.post_content.reaction_count;
    let replyText;
    if (commentCount === 1) {
      replyText = 'reply';
    } else {
      replyText = 'replies';
    };

    return <div className="Activity__post">
      <h4 className="Activity__body-title">
        { activity.post_activity.post_content.title}
      </h4>
      <p className="Activity__body-text">
        <UserTagParser body={this.sliceBody(activity.post_activity.post_content.body)} />
      </p>
      <div className="ActivityReactAndCommentButtons__container">
        <div className="ActivityReactAndCommentButtons__reaction">
            <img src={chatIcon} style={{width: '27px', top: '1px'}} />
            <span>{commentCount} {replyText}</span>
        </div>
        <div className="ActivityReactAndCommentButtons__reaction">
          <img src={praiseIcon} width="19" style={{marginRight: '5px', top: '-1px'}} />
          {reactionCount}
        </div>
      </div>
    </div>
  }

  renderAction = () => {
    const { activity } = this.props;

    switch (activity.type) {
      case "post":
        return this.POST_CONTENT[activity.post_activity.activity_type](activity);
      case "collectiveJoined":
        return <CollectivesListItem
          collective={activity.collective_joined.member.collective}
          noSubscription
          showAvatar
        />;
      case "admire":
        return this.renderAdmire(activity);
    }
    return null;
  }

  renderLabel = () => {
    const { activity, user } = this.props;
    let action = '';
    let name = '';
    let link = '';

    switch (activity.type) {
      case "post":
        action = this.POST_LABELS[activity.post_activity.activity_type];
        name = activity.post_activity.post_content.collective.name;
        link = activity.post_activity.post_content.collective.slug;
        return <h4 className="Activity__title">
          {user.first_name} {action} <Link onClick={this.props.close} className="Activity__overlay-link" to={`/groups/${link}`}>{name}</Link>
        </h4>;
      case "collectiveJoined":
        name = activity.collective_joined.member.collective.name;
        link = activity.collective_joined.member.collective.slug;
        return <h4 className="Activity__title">
          {user.first_name} joined the <Link onClick={this.props.close} className="Activity__overlay-link" to={`/groups/${link}`}>{name}</Link> Collective
        </h4>
      case "admire":
        name = activity.receiver.first_name + " " + activity.receiver.last_name;
        link = activity.receiver.username;
        return <h4 className="Activity__title">
          {user.first_name} admired <Link onClick={this.props.close} className="Activity__overlay-link" to={`/${link}`}>{name}</Link>
        </h4>
    }

    return null;
  }

  getLink = () => {
    const { activity } = this.props;
    switch (activity.type) {
      case "post":
        return `/post/${activity.post_activity.post_content.id}/${activity.post_activity.post_content.slug}`;
      case "collectiveJoined":
        return `/groups/${activity.collective_joined.member.collective.slug}`;
      case "admire":
        return `/${activity.receiver.username}`;
    }
  }

  render() {
    return (
      <div className="Activity__container">
        <div className="Activity__content">
          {
            this.renderAction()
          }
        </div>
        <div className="Activity__label">
          {
            this.renderLabel()
          }
        </div>
        <Link onClick={this.props.close} className="Activity__link" to={this.getLink()} />
      </div>
    )
  }
}

export default Activity;
