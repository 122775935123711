import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button, {
  THEME_WHITE,
  THEME_BLACK
} from '../../../common/Button';

import '../Modal.scss';

class ModalDialog extends Component {
  static propTypes = {
    className: PropTypes.string,
    cancelClassName: PropTypes.string,
    children: PropTypes.node,
    close: PropTypes.func.isRequired,
    closeText: PropTypes.string,
    closeClassName: PropTypes.string,
    header: PropTypes.string,
    headerClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    modalContainerClassName: PropTypes.string,
    subHeader: PropTypes.string,
    subHeaderClassName: PropTypes.string,
    submit: PropTypes.func,
    submitClassName: PropTypes.string,
    submitText: PropTypes.string,
    children: PropTypes.node
  };

  setRef = ref => {
    if (this.props.setRef) this.props.setRef(ref);
  }

  render() {
    const {
      children,
      close,
      closeText,
      header,
      subHeader,
      submit,
      submitText,
      characters
    } = this.props;

    return (
      <div
        className={classNames(
          "Modal",
          this.props.className
        )}
        ref={this.setRef}
      >
        <div
          className={classNames(
            "Modal__content",
            this.props.modalContainerClassName
          )}
        >
          { header && (
              <div
                className={classNames(
                  'ModalDialog__header',
                  this.props.headerClassName
                )}
              >
                {header}
              </div>
            )
          }
          {
            subHeader && (
              <div
                className={classNames(
                  'ModalDialog__subHeader',
                  this.props.subHeaderClassName
                )}
              >
                {subHeader}
              </div>
            )
          }
          { children && children }
          {
            submitText && (
              <div className="ModalDialog__buttonContainer">
                <Button
                  onClick={submit}
                  className={classNames(
                    this.props.submitClassName
                  )}
                  theme={THEME_BLACK}
                  isLoading={this.props.isLoading}
                  disabled={ characters && characters > 150 ? true : null}
                  noOutline
                >
                  {submitText.toUpperCase()}
                </Button>
              </div>
            )
          }
          {
            closeText && (
              <div className="ModalDialog__buttonContainer">
                <Button
                  onClick={close}
                  className={classNames(
                    'ModalDialog__submit',
                    this.props.closeClassName
                  )}
                  theme={THEME_BLACK}
                  noOutline
                >
                  {closeText}
                </Button>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default ModalDialog;
