/**
  React Post
**/
export const REACT_POST = 'REACT_POST';
export const REACT_POST_SUCCESS = 'REACT_POST_SUCCESS';
export const REACT_POST_ERROR = 'REACT_POST_ERROR';

export const reactPost = (postId, payload) => {
  return {
    type: REACT_POST,
    postId,
    payload
  }
};

export const reactPostSuccess = (postId, payload) => {
  return {
    type: REACT_POST_SUCCESS,
    postId,
    payload
  }
};

export const reactPostError = error => {
  return {
    type: REACT_POST_ERROR,
    error
  }
};

/**
  React Post Remove
**/
export const REACT_POST_REMOVE = 'REACT_POST_REMOVE';
export const REACT_POST_REMOVE_SUCCESS = 'REACT_POST_REMOVE_SUCCESS';
export const REACT_POST_REMOVE_ERROR = 'REACT_POST_REMOVE_ERROR';

export const reactPostRemove = (postId, payload) => {
  return {
    type: REACT_POST_REMOVE,
    postId,
    payload
  }
};

export const reactPostRemoveSuccess = (postId, userId, payload) => {
  return {
    type: REACT_POST_REMOVE_SUCCESS,
    postId,
    userId,
    payload
  }
};

export const reactPostRemoveError = error => {
  return {
    type: REACT_POST_REMOVE_ERROR,
    error
  }
};

/**
  React Comment
**/
export const REACT_COMMENT = 'REACT_COMMENT';
export const REACT_COMMENT_SUCCESS = 'REACT_COMMENT_SUCCESS';
export const REACT_COMMENT_ERROR = 'REACT_COMMENT_ERROR';

export const reactComment = (postId, commentId, payload) => {
  return {
    type: REACT_COMMENT,
    postId,
    commentId,
    payload
  }
};

export const reactCommentSuccess = (postId, commentId, payload) => {
  return {
    type: REACT_COMMENT_SUCCESS,
    postId,
    commentId,
    payload
  }
};

export const reactCommentError = error => {
  return {
    type: REACT_COMMENT_ERROR,
    error
  }
};


/**
  React Comment Remove
**/
export const REACT_COMMENT_REMOVE = 'REACT_COMMENT_REMOVE';
export const REACT_COMMENT_REMOVE_SUCCESS = 'REACT_COMMENT_REMOVE_SUCCESS';
export const REACT_COMMENT_REMOVE_ERROR = 'REACT_COMMENT_REMOVE_ERROR';

export const reactCommentRemove = (postId, commentId, payload) => {
  return {
    type: REACT_COMMENT_REMOVE,
    commentId,
    postId,
    payload
  }
};

export const reactCommentRemoveSuccess = (postId, commentId, userId, payload) => {
  return {
    type: REACT_COMMENT_REMOVE_SUCCESS,
    postId,
    commentId,
    userId,
    payload
  }
};

export const reactCommentRemoveError = error => {
  return {
    type: REACT_COMMENT_REMOVE_ERROR,
    error
  }
};

/**
  React Reply
**/
export const REACT_REPLY = 'REACT_REPLY';
export const REACT_REPLY_SUCCESS = 'REACT_REPLY_SUCCESS';
export const REACT_REPLY_ERROR = 'REACT_REPLY_ERROR';

export const reactReply = (postId, parentId, commentId, payload) => {
  return {
    type: REACT_REPLY,
    postId,
    parentId,
    commentId,
    payload
  }
};

export const reactReplySuccess = (postId, parentId, commentId, payload) => {
  return {
    type: REACT_REPLY_SUCCESS,
    postId,
    parentId,
    commentId,
    payload
  }
};

export const reactReplyError = error => {
  return {
    type: REACT_REPLY_ERROR,
    error
  }
};


/**
  React Reply Remove
**/
export const REACT_REPLY_REMOVE = 'REACT_REPLY_REMOVE';
export const REACT_REPLY_REMOVE_SUCCESS = 'REACT_REPLY_REMOVE_SUCCESS';
export const REACT_REPLY_REMOVE_ERROR = 'REACT_REPLY_REMOVE_ERROR';

export const reactReplyRemove = (postId, parentId, commentId, payload) => {
  return {
    type: REACT_REPLY_REMOVE,
    postId,
    parentId,
    commentId,
    payload
  }
};

export const reactReplyRemoveSuccess = (postId, parentId, commentId, userId, payload) => {
  return {
    type: REACT_REPLY_REMOVE_SUCCESS,
    postId,
    parentId,
    commentId,
    userId,
    payload
  }
};

export const reactReplyRemoveError = error => {
  return {
    type: REACT_REPLY_REMOVE_ERROR,
    error
  }
};
