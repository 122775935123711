import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Input from '../../../../common/Input';
import profileInputFields, { EMAIL_ADDRESS } from '../../../../common/Input/CommonFields/profile';
import Button, { THEME_BLUE } from '../../../../common/Button';

import HomepageReferralModalInput from './HomepageReferralModalInput';

import Raise from '../../../../../public/assets/illustrations/raise.png';

import './HomepageReferralModal.scss';

class HomepageReferralModal extends Component {
  static propTypes = {
    submitEmailToWaitlistReferral: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      myFullName: '',
      myEmailAddress: '',
      referralCount: 1,
      referrals: {},
      referralsInputData: {},
      errorMessage: '',
      successMessage: ''
    }
  } 

  handleReferral = (referralInfo, isValidated, key) => {
    const { fullName, fullNameValid, emailAddress, emailAddressValid} = referralInfo;

    if (isValidated) {
      this.state.referrals[key] = {
        fullName: fullNameValid,
        emailAddress: emailAddressValid
      };
    } else {
      delete this.state.referrals[key];
    }

    // referrals has all 'validated' good data to send up
    // data will be an empty str if invalid
    this.setState({ referrals: this.state.referrals });

    // to keep track of current input, not necessarily validated
    // this is mainly to send as controlled value so when form is submitted, its cleared
    this.state.referralsInputData[key] = { fullName, emailAddress };
    this.setState({ referralsInputData: this.state.referralsInputData });
  }

  handleMyInfoReferral = ({ fullNameValid, emailAddressValid }) => {
    this.setState({
      myFullName: fullNameValid,
      myEmailAddress: emailAddressValid
    });
  }

  submitReferrals = () => {
    if (!this.checkForErrors()) return null;

    const { referrals } = this.state;
    const referralsList = Object.keys(referrals).map(referral => {
      return referrals[referral];
    });

    this.props.submitEmailToWaitlistReferral({
      referrer_name: this.state.myFullName,
      referrer_email: this.state.myEmailAddress,
      referrals: referralsList
    });

    this.setState({
      referrals: {},
      referralsInputData: {},
      referralCount:1,
      successMessage: `Success!`
    });
  }

  checkForErrors = () => {
    if (!this.state.myFullName || !this.state.myEmailAddress) {
      this.setState({ errorMessage: 'Please fill in your referral info', successMessage: '' });
      return false;
    }

    if (!Object.keys(this.state.referrals).length) {
      this.setState({ errorMessage: 'Please add a referral or fill in required fields', successMessage: '' });
      return false;
    }

    this.setState({ errorMessage: '' });
    return true;
  }

  renderReferralList = () => {
    let referralList = [];
    for (let i = 0; i < this.state.referralCount; i++) {
      referralList.push(<HomepageReferralModalInput
        key={i}
        index={i}
        values={this.state.referralsInputData}
        onChange={this.handleReferral}
      />);
    }
    return referralList;
  }

  addElementToReferralList = () => {
    // Clamping the referral count at 5 to prevent ridiculousness.
    this.setState({referralCount:Math.min(this.state.referralCount + 1, 5)});
  }

  setRef = ref => ( this.props.setRef(ref) )

  render() {
    return (
      <div className="HomepageReferralModal Modal" ref={this.setRef}>
        {this.props.children}

        <div className="HomepageReferralModal__container--hero">
          <img src={Raise} alt="Refer a friend to the Girlboss Community" className="HomepageReferralModal__hero" />
          <div className="HomepageReferralModal__container--hero--column">
            <h3 className="HomepageReferralModal__header">
              Better Together
            </h3>
            <h3 className="HomepageReferralModal__subheader">
              Refer a friend and conquer the world together!
            </h3>
          </div>
        </div>

        <form className="HomepageReferralModal__referral-form" onSubmit={this.props.submitReferrals}>
          <div className="HomepageReferralModal__header--section required">
            Your Info
          </div>
          <HomepageReferralModalInput
            index={0}
            onChange={this.handleMyInfoReferral}
            required
          />

          <div className="HomepageReferralModal__header--section">
            Friend's Info
          </div>
          
          <div className="HomePageReferralModal__referral-list">
            {
              this.renderReferralList()
            }
            <span className="HomePageReferralModal__refer-another-friend" onClick={this.addElementToReferralList}>Add Another Friend</span>
          </div>

          <Button
            onClick={this.submitReferrals}
            onSubmit={this.submitReferrals}
            theme={THEME_BLUE}
            className="HomepageReferralModal__button--refer"
          >
            Refer Friends
          </Button>
          {this.state.errorMessage && (
            <p className="error">{this.state.errorMessage}</p>
          )}
          {this.state.successMessage && (
            <p className="success">{this.state.successMessage}</p>
          )}
        </form>
      </div>
    )
  }
}

export default HomepageReferralModal;
