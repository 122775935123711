import XRegExp from 'xregexp';

export const INVALID_EMAIL = 'INVALID_EMAIL';
export const INVALID_EMAIL_LENGTH = 'INVALID_EMAIL_LENGTH';

export const INVALID_NAME_LENGTH = 'INVALID_NAME_LENGTH';
export const INVALID_NAME = 'INVALID_NAME';

export const INVALID_EMOJIS = 'INVALID_EMOJIS';

// export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const INVALID_PASSWORD_LENGTH = 'INVALID_PASSWORD_LENGTH';
export const INVALID_PASSWORD_CONTAIN_NUMBER = 'INVALID_PASSWORD_CONTAIN_NUMBER';
export const INVALID_PASSWORD_CONTAIN_LOWERCASE = 'INVALID_PASSWORD_CONTAIN_LOWERCASE';
export const INVALID_PASSWORD_CONTAIN_UPPERCASE = 'INVALID_PASSWORD_CONTAIN_UPPERCASE';

export const INVALID_WEB_URL = 'INVALID_WEB_URL';

export const INVALID_SPACES = 'INVALID_SPACES';
export const INVALID_SYMBOLS = 'INVALID_SYMBOLS';
export const INVALID_ALPHABET = 'INVALID_ALPHABET';

export const validationMethods = {
  [INVALID_ALPHABET]: isAlphabet,
  [INVALID_EMAIL]: isEmailAddress,
  [INVALID_EMAIL_LENGTH]: isEmailInLimits,
  [INVALID_EMOJIS]: isNoEmoji,
  [INVALID_NAME]: isName,
  [INVALID_NAME_LENGTH]: isNameInLimits,
  // [INVALID_PASSWORD]: isPassword,
  [INVALID_PASSWORD_LENGTH]: isPasswordInLimits,
  [INVALID_PASSWORD_CONTAIN_NUMBER]: isPasswordWithNumber,
  [INVALID_PASSWORD_CONTAIN_LOWERCASE]: isPasswordWithLowerCase,
  [INVALID_PASSWORD_CONTAIN_UPPERCASE]: isPasswordWithUpperCase,
  [INVALID_SPACES]: isNoSpace,
  [INVALID_SYMBOLS]: isNoSymbols,
  [INVALID_WEB_URL]: isValidWebURL
}

export const validationMessages = {
  [INVALID_ALPHABET]: 'Should only have characters A-Z',
  [INVALID_EMAIL]: 'Invalid Email Address',
  [INVALID_EMAIL_LENGTH]: 'Please enter an email address between 3-255 characters',
  [INVALID_EMOJIS]: 'Invalid Name; please enter a name with A-Z characters',
  [INVALID_NAME]: 'Name should not have any invalid characters',
  [INVALID_NAME_LENGTH]: 'Please enter a name between 1-50 characters',
  // [INVALID_PASSWORD]: 'Please enter a valid password',
  [INVALID_PASSWORD_LENGTH]: 'Please enter a password between 8-255 characters',
  [INVALID_PASSWORD_CONTAIN_NUMBER]: 'Password should contain at least one number',
  [INVALID_PASSWORD_CONTAIN_LOWERCASE]: 'Password should contain at least one lowercase letter',
  [INVALID_PASSWORD_CONTAIN_UPPERCASE]: 'Password should contain at least one uppercase letter',
  [INVALID_SPACES]: 'No spaces allowed',
  [INVALID_SYMBOLS]: 'Should have characters A-Z and 0-9',
  [INVALID_WEB_URL]: 'Should have valid URL format'
};

// returns true if email address match
export function isEmailAddress(emailAddress) {
  return emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function isEmailInLimits(emailAddress) {
  return 3 <= emailAddress.length && emailAddress.length < 255;
}

export function isPasswordInLimits(password) {
  return 8 <= password.length && password.length < 255;
}

// should contain at least one digit
export function isPasswordWithNumber(password) {
  return password.match(/^(?=.*\d).+$/);
}

// should contain at least one lowercase
export function isPasswordWithLowerCase(password) {
  return password.match(/^(?=.*[a-z]).+$/);
}

// should contain at least one uppercase
export function isPasswordWithUpperCase(password) {
  return password.match(/^(?=.*[A-Z]).+$/);
}

// should have at least 8 characters of letters and numbers
// export function isPassword(password) {
// return password.match(/^[a-zA-Z0-9]{8,}.+$/);
// }

// returns true if name fits format Lindsey O'hara
const nameRegExp = new XRegExp('^[\\p{L}0-9 ,.\\(\\)\'"’“”\\/-]+$', 'u');
export function isName(name) {
  return name.match(nameRegExp);
}

export function isNameInLimits(name) {
  const length = name.trim().length;
  return length > 0 && length <= 50;
}

// only have A-Z
export function isAlphabet(string) {
  return string.match(/^[A-z]+$/);
}

// returns true if no spaces
export function isNoSpace(string) {
  return !(string.match(/\s/));
}

export function isValidWebURL(string) {
  return string.match(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/);
}

// returns true if there are no emojis
export function isNoEmoji(string) {
  return !(string.match(/[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug));
}

export function isNoSymbols(string) {
  return string.match(/^[_A-z0-9]*((-|\s)*[_A-z0-9])*$/);
}

export function normalizeCapitalFirst(string) {
  const words = string.split(' ');
  const newWords = words.map(word => (
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ));

  return newWords.join(' ');
};

export function normalizeLowerCase(string) {
  return string.toLowerCase();
};

// input is an array of validation obj { erroKeyName: func }
// returns true if all pass
export function compose(input, validationKeys) {
  const errors = [];

  validationKeys.forEach(validationKey => {
    const isValidated = validationMethods[validationKey](input);
    if (!isValidated) { 
      errors.push(validationMessages[validationKey]);
    }
  });

  return errors;
}

// errors = { email: [], password: [] }
// output = ['email wrong', 'password wrong']
export function collapseErrors(errorObject) {
  const errorKeys = Object.keys(errorObject);
  let errors = [];

  errorKeys.forEach(errorKey => {
    errors = errors.concat(errorObject[errorKey]);
  })

  return errors;
}

// returns true if there is an empty field
export function checkHasEmptyFields(fieldsObj) {
  const keys = Object.keys(fieldsObj);

  if (!keys.length) return false;

  let hasEmpty = false;
  keys.forEach(fieldKey => {
    if (fieldsObj[fieldKey] === '') {
      hasEmpty = true;
    }
  });

  return hasEmpty;
}


// overwrite required fields removes a req field in substitute with an optional one
export function checkRequiredFieldsFilled(fieldsList = {}, requiredFields = [], prevValues = {}, overwriteReqFields = {}) {
  if (!requiredFields.length) return true;
  let hasFilled = 0;

  requiredFields.map(requiredField => {
    if (fieldsList[requiredField]) {
      hasFilled++;
    } else if (prevValues[requiredField] && prevValues[requiredField].value) {
      hasFilled++;
    } else if (fieldsList[overwriteReqFields[requiredField]]) {
      hasFilled++;
    } else if (prevValues[overwriteReqFields[requiredField]] &&
      prevValues[overwriteReqFields[requiredField]].value
    ) {
      hasFilled++;
    }
  })

  return hasFilled === requiredFields.length;
};
