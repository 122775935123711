import React, { Component } from 'react';
import PropTypes from 'prop-types';

import User, { TYPE_COMMENT } from '../User';

class TestimonialItem extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    testimonial: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className="TestimonialItem">
        <div className="TestimonialItem__container--testimonial">
          {`"${this.props.testimonial}"`}
        </div>
        <User
          name={this.props.name}
          photo={this.props.photo}
          subheadline={this.props.title}
          variant="comment"
        />
      </div>
    )
  }
}

export default TestimonialItem;
