import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { authLogOut } from '../../../store/Auth/actions';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button, { THEME_GRAY } from '../../../common/Button';

import './SideProfileNav.scss';


export class SideProfileNav extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.navLinks = [
      {
        icon: '',
        url: '/settings',
        text: 'Settings'
      },
      {
        icon: '',
        onclick: () => this.props.logOut(this.props.history),
        text: 'Log Out'
      }

    ];

    this.state = {
      loading: true
    }

    this.profNav = React.createRef();
  }

  componentDidMount = () => {
    this.setState({
      loading: false // prevent the nav from sliding out on initial load
    });
  }

  componentWillUnmount = () => {
    disableBodyScroll(this.profNav.current);
    clearAllBodyScrollLocks();
  }

  render() {
    const { user, onDesktop } = this.props;

    if (this.props.open) {
      disableBodyScroll(this.profNav.current);
    } else {
      clearAllBodyScrollLocks();
    };

    return (
      <React.Fragment>
        <div
          className={classnames(
            "overlay-container", "center-align", 'overlay-container--belowNav'
          )}
          style={{ display: this.props.open ? 'block' : 'none' }}
          onClick={() => this.props.closeNav()}
        />
        <div 
          className={classnames({
            SideProfileNav: true,
            open: this.props.open,
            onDesktop
        })}
          style={{ display: this.state.loading ? 'none' : 'block' }}
          ref={this.profNav}
        >
          <div className="SideProfileNav__user">
            <div
              className="SideProfileNav__user--thumbnail"
              style={{
                backgroundImage: `url(${user.photo.medium})`
              }}
            />

            <h3 className="SideProfileNav__user--name">{user.first_name} {user.last_name}</h3>
            <p className="SideProfileNav__user--headline">{user.headline}</p>

            <Button 
              component={Link}
              to={'/' + user.username} 
              className="SideProfileNav__user--profile-btn"
              onClick={() => this.props.closeNav()} 
              pill 
              noOutline 
              theme={THEME_GRAY}
            >
              View Profile
            </Button>
            <div className="close-btn" onClick={() => this.props.closeNav()} />
          </div>

          <div className="SideProfileNav__links">
            {
              this.navLinks.map((link, index) => {
                if (link.onclick) {
                  return (
                    <div key={index} className="SideProfileNav__links--link-item">
                      <a onClick={link.onclick}>
                        <span>{link.icon}</span>
                        <span>{link.text}</span>
                      </a>
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className="SideProfileNav__links--link-item">
                      <Link to={link.url} onClick={() => this.props.closeNav()}>
                        <span>{link.icon}</span>
                        <span>{link.text}</span>
                      </Link>
                    </div>
                  )
                }
              })
            }
          </div>

          <div className="SideProfileNav__footer">
            <h4>Need Help?</h4>
            Email us at <a href="mailto:help@girlboss.com">help@girlboss.com</a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: (...args) => dispatch(authLogOut(...args))
  }
};

export default connect(null, mapDispatchToProps)(withRouter(SideProfileNav));