import React, { Component } from 'react'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Input.scss';
import TextareaAutosize from 'react-textarea-autosize';

export const INPUT_MADLIB_NAVY = 'navy';
export const INPUT_MADLIB_WHITE = 'white';
export const INPUT_MADLIB_BLACK = 'black';

class InputMadlib extends Component {
	static defaultProps = {
		className: '',
		component: 'textarea',
		disabled: false,
		error: null,
		hideMaxLengthText: false,
		maxLength: 150,
		maxRows: 200,
		onBlur: () => {},
		onChange: () => {},
		rows: 1,
		theme: INPUT_MADLIB_NAVY
	};

	static propTypes = {
		disabled: PropTypes.bool,
		component: PropTypes.oneOf(['input', 'textarea']),
		controlledValue: PropTypes.string,
		className: PropTypes.string,
		error: PropTypes.string,
		hideMaxLengthText: PropTypes.bool,
		inputMode: PropTypes.string,
		maxLength: PropTypes.number,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
		placeholder: PropTypes.string,
		theme: PropTypes.oneOf([
			INPUT_MADLIB_NAVY,
			INPUT_MADLIB_WHITE,
			INPUT_MADLIB_BLACK
		]),
	};

	constructor(props) {
		super(props);
		this.state = {
			rows: 1,
			minRows: 1,
			maxRows: this.props.maxRows,
			showError: false
		};
	}

	handleOnBlur = e => {
		// only show error on escape of text field
		this.setState({ showError: (!!this.props.error) });
		this.props.onBlur(e.target.value);
	}

	handleKeyPress = e => {
		if (e.key === 'Enter') e.preventDefault();
	}

	handleOnInputChange = e => {
		const { value } = e.target;
		this.props.onChange(value);
	}
			
	setRef = ref => {
		const { setRef } = this.props;
		if (setRef) setRef(ref);
	}

	renderMessageText = () => {
		const { hideMaxLengthText, error, maxLength } = this.props;

		if(hideMaxLengthText) {
			if(error) {
				return (
					<p className="InputMadlib__message InputMadlib__message--error">{error}</p>
				);
				}
		} else {
			if(this.state.showError && error) {
				return (
					<p className="InputMadlib__message InputMadlib__message--error">{error}</p>
				);
		} else {
				return (
					<p className="InputMadlib__message">Max {maxLength} characters</p>
				);
			};
		};
	}

	render() {
		const { 
			active, 
			className, 
			controlledValue,
			component,
			disabled,
			error,
			inputMode,
			maxLength,
			placeholder, 
			theme,
			value
		} = this.props;

		let Component = component;
		if (component === 'textarea') Component = TextareaAutosize;

		return (
			<form>
				<Component 
					autoCorrect="off" 
					autoCapitalize="none"
					className={classNames(
						'InputMadlib',
						`InputMadlib__${theme}`,
						className,
						{
								'error': this.state.showError && error ? true : false
						}
					)}
					disabled={disabled}
					inputMode={inputMode}
					inputRef={this.setRef}
					maxLength={maxLength}
					onChange={this.handleOnInputChange}
					onFocus={this.onFocus}
					onBlur={this.handleOnBlur}
					onKeyPress={this.handleKeyPress}
					placeholder={placeholder}
					ref={this.setRef}
					rows={this.state.rows}
					type="email" 
					value={controlledValue !== undefined ? controlledValue : value}
					/>
				{ this.renderMessageText() }
			</form>
		)
	}
}

export default InputMadlib;