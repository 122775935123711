import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import Nav, {
  NAV_TYPE_COMPONENT,
  NAV_TYPE_LINK,
  NAV_TYPE_LINK_EXTERNAL,
  NAV_TYPE_ICON,
  NAV_TYPE_MOBILE_FOOTER
} from '../index.js';
import ProfilePhoto, { SIZE_XSMALL } from '../../../components/ProfilePhoto';
import NotificationIcon from '../../../components/SVG/NotificationIcon';
import searchIcon from '../../../../public/assets/icons/SEARCH.svg';

import ButtonAdd from '../../../components/ButtonAdd';

import {
  PROFILE_PHOTO
} from '../../../common/Input/CommonFields/profile';

import GBetaDesktopLogo from '../../../components/SVG/GBetaDesktopLogo.js';
import GIcon from '../../../../public/assets/GirlbossLogo_GMark_Black.png'

import routes from '../../../config/routes';

import { modalOpen } from '../../../store/Modal/actions';
import { appGetIsUserSubscribed } from '../../../store/App/actions';
import { userGetPhoto } from '../../../store/User/actions';
import { notifsGet, notifsGetUnreadCount } from '../../../store/Notifications/actions';
import { submitEmailToWaitlistReferral } from '../../../store/App/actions';

import {
  POST_MODAL,
  UPDATE_SUBSCRIPTION_MODAL,
  GLOBAL_SEARCH_MODAL
} from '../../ModalManager/ModalTypes';

import {
  GTMpushDataLayerEvent,
  GTM_NOTIFICATIONS_NAV,
  GTM_POST_CLICK_CREATE_NAVBUTTON
} from '../../../lib/GoogleTagManager';

import CommunityIcon from '../../../../public/assets/icons/COMMUNITY-WHITE.svg';
import membersIcon from '../../../../public/assets/icons/MEMBERS-WHITE.svg';
import LibraryIcon from '../../../../public/assets/icons/LIBRARY-WHITE.svg';
import messagesIcon from '../../../../public/assets/icons/MESSAGES-WHITE.svg';
import collectivesIcon from '../../../../public/assets/icons/COLLECTIVES.svg';

import '../Nav.scss';

class ProfileNav extends Component {
  static propTypes = {
    // [PROFILE_PHOTO]: PropTypes.shape({
    //   thumbnail: PropTypes.string.isRequired,
    //   medium: PropTypes.string.isRequired,
    //   large: PropTypes.string.isRequired,
    // }).isRequired
    submitEmailToWaitlistReferral: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isProfileNavOpen: false,
      isNotifNavOpen: false,
      SideProfileNavOpen: false,
      SideNotificationNavOpen: false,
      viewportWidth: null
    };

    this.ajaxRefresh = 0;
  }

  handleWindowResize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    })
  }

  componentDidMount = () => {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    this.ajaxRefresh = setInterval(this.ajaxRefreshCall, 60 * 1000);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowResize);
    clearInterval(this.ajaxRefresh);
  }

  ajaxRefreshCall = () => {
    this.props.notifsGet('ajax-refresh');
  }

  setPhotoRef = node => { this.photoRef = node; }

  setNotifNav = node => { this.notifRef = node; }


  handleCreatePost = e => {
    this.handleOnClickForUserStatus(() => {
      e.stopPropagation();

      this.props.modalOpen({
        modal: POST_MODAL,
        position: 'belowNav'
      });

      this.props.history.push(routes.root);
    });

    GTMpushDataLayerEvent({
      event: GTM_POST_CLICK_CREATE_NAVBUTTON,
      isUserSubscribed: this.props.isUserSubscribed
    });
  }

  handleOnClickForUserStatus = cb => {
    if (this.props.isUserSubscribed) cb();
    else {
      this.props.modalOpen({
        modal: UPDATE_SUBSCRIPTION_MODAL
      });
    }
  }

  toggleMobileProfileNav = () => {
    this.setState({
      SideProfileNavOpen: !this.state.SideProfileNavOpen
    });
  }

  toggleMobileNotificationNav = () => {
    this.setState({
      SideNotificationNavOpen: !this.state.SideNotificationNavOpen
    });
  }

  openGlobalSearch = () => {
    this.props.modalOpen({
      modal: GLOBAL_SEARCH_MODAL,
      user: this.props.user,
      closeClassName: 'GlobalSearchModal__close'
    })
  }

  render() {
    if (this.props.userLoading) return null;

    const photo = this.props[PROFILE_PHOTO];

    const leftLinks = [
      {
        text: 'Home',
        type: NAV_TYPE_LINK,
        url: routes.root,
        desktopOnly:true,
        activeRoutes: [
          routes.root,
          routes.post,
          routes.postThreadComment
        ]
      },
      // {
      //   text: 'Resources',
      //   type: NAV_TYPE_LINK,
      //   url: routes.videos,
      //   desktopOnly:true,
      //   activeRoutes:[
      //     routes.videos
      //   ]
      // },
      {
        text: 'Collectives',
        type: NAV_TYPE_LINK,
        url: routes.collectiveDirectory,
        desktopOnly:true,
        activeRoutes:[
          routes.collectiveDirectory,
          routes.collective
        ]
      },
      {
        text: 'Members',
        type: NAV_TYPE_LINK,
        url: routes.directory,
        desktopOnly:true,
        activeRoutes:[
          routes.directory
        ]
      },
      // {
      //   text: 'Messages',
      //   type: NAV_TYPE_LINK,
      //   url: routes.messaging,
      //   desktopOnly:true,
      //   activeRoutes:[
      //     routes.messaging,
      //     routes.messagingConversation
      //   ],
      //   notification:this.props.notifsUnreadMessageCount > 0
      // },
      {
        text: 'Crowdfund Your Biz',
        type: NAV_TYPE_LINK_EXTERNAL,
        url: 'https://ifundwomen.com/girlboss',
        desktopOnly:true,
        activeRoutes:[]
      },
      {
        text: 'Resources',
        type: NAV_TYPE_LINK_EXTERNAL,
        url: 'https://www.girlboss.com/work/coronavirus-resources-women-work-businesses?ref=community',
        desktopOnly:true,
        activeRoutes:[]
      }
    ];
    const rightLinks = [
      {
        type: NAV_TYPE_COMPONENT,
        component: (
          <div
            className="Nav__profile"
            onClick={this.openGlobalSearch}
          >
            <SVG src={searchIcon} />
          </div>
        )
      },
      {
        type: NAV_TYPE_COMPONENT,
        component: (
          <div
            ref={this.setNotifNav}
            className="Nav__container--notifications"
            onClick={this.toggleMobileNotificationNav}
          >
          {this.props.notifsUnreadCount === 0 ? 
            <div
            alt="Notifications"
            className="Nav__items--icon"
            >
              <NotificationIcon />
            </div> :
            <div 
            className="Nav__notifications--unread"
            >
              <span>{this.props.notifsUnreadCount > 99 ? '99+' : this.props.notifsUnreadCount}</span>
            </div>
          }
          </div>
        )
      },
      {
        type: NAV_TYPE_COMPONENT,
        component: (
          <div
            className="Nav__profile"
            onClick={this.toggleMobileProfileNav}
            ref={this.setPhotoRef}
          >
            <ProfilePhoto
              photo={photo !== undefined ? photo.thumbnail : "https://storage.googleapis.com/girlboss_profile_pictures/avatar_default.png"}
              size= {SIZE_XSMALL}
            />
          </div>
        )
      }
    ];

    const centerLinks = [
      {
        type: NAV_TYPE_COMPONENT,
        className:'Nav__item-left',
        component: (
          <Link to={routes.root}>
            <div className="Nav__logo" />
          </Link>
        )
      },
      {
        type: NAV_TYPE_COMPONENT,
        component: (
          <div
            className="Nav__profile"
            onClick={this.openGlobalSearch}
          >
            <SVG src={searchIcon} />
          </div>
        )
      }, 
      {
        type: NAV_TYPE_COMPONENT,
        component: (
          <div
          ref={this.setNotifNav}
          className="Nav__container--notifications"
          onClick={this.toggleMobileNotificationNav}
        >
          {this.props.notifsUnreadCount === 0 ? 
            <div
            alt="Notifications"
            className="Nav__items--icon"
            >
              <NotificationIcon />
            </div> :
            <div 
              className="Nav__notifications--unread">
             <span>{this.props.notifsUnreadCount > 99 ? '99+' : this.props.notifsUnreadCount}</span>
            </div>
          }
        </div>
        )
      },
      {
        type: NAV_TYPE_COMPONENT,
        component: (
          <div
            className="Nav__profile"
            onClick={this.toggleMobileProfileNav}
            ref={this.setPhotoRef}
          >
            <ProfilePhoto
              size={SIZE_XSMALL}
              photo={photo.thumbnail}
            />  
          </div>
        )
      }
    ]

    const mobileFooterLinks = [
      {
        text: 'Home',
        type: NAV_TYPE_MOBILE_FOOTER,
        url: routes.root,
        icon: CommunityIcon
      },
      {
        text: 'Collectives',
        type: NAV_TYPE_MOBILE_FOOTER,
        url: routes.collectiveDirectory,
        icon: collectivesIcon
      },
      {
        text: 'Members',
        type: NAV_TYPE_MOBILE_FOOTER,
        url: routes.directory,
        icon: membersIcon
      },
      // {
      //   text: 'Messages',
      //   type: NAV_TYPE_MOBILE_FOOTER,
      //   url: routes.messaging,
      //   icon: messagesIcon,
      //   notification:this.props.notifsUnreadMessageCount > 0
      // }
    ];

      return (
        <React.Fragment>
          <Nav
            leftLinks={leftLinks}
            rightLinks={rightLinks}
            centerLinks={centerLinks}
            mobileFooterLinks={mobileFooterLinks}
            onDesktop={this.state.viewportWidth > 1023 ? true : false}
            SideProfileNavOpen={this.state.SideProfileNavOpen}
            SideNotificationNavOpen={this.state.SideNotificationNavOpen}
            toggleMobileProfileNav={this.toggleMobileProfileNav}
            toggleMobileNotificationNav={this.toggleMobileNotificationNav}
          />
        </React.Fragment>
      )
    } 
}

const mapStateToProps = state => {
  return {
    user: state.User.user,
    userLoading: state.User.isLoading,
    [PROFILE_PHOTO]: userGetPhoto(state),
    notifsUnreadCount: notifsGetUnreadCount(state),
    isUserSubscribed: appGetIsUserSubscribed(state),
    notifsUnreadMessageCount: state.Notifications.unread_message_count
  }
};

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args)),
    submitEmailToWaitlistReferral: (...args) => dispatch(submitEmailToWaitlistReferral(...args)),
    notifsGet: (args) => dispatch(notifsGet(args))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileNav));
