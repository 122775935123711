import routes, { routeWithParams } from '../../config/routes';
import { TAG_MARKUP_REGEX_CAPTURE } from '../../utils/userTag.js';

export const PRAISE_REACTION_TYPE = 1;
export const PRIMARY_REACTION_TYPE = PRAISE_REACTION_TYPE;

export const iconTypes = {
  location: '📍'
};

export const reactionTypes = {
  [PRAISE_REACTION_TYPE]: {
    action: 'praise',
    reaction: 'praised'
  },
};

const postNotificationTypes = [1, 4];

export function getNotificationContentType(activityType) {
  const notifData = activityTypes[activityType];
  if (notifData && notifData.on) {
    return notifData.on;
  }

  if (postNotificationTypes.includes(activityType)) {
    return 'post';
  }
  return 'comment';
}

export function getActivityIcon(action) {
  switch(action) {
    case PRAISE_REACTION_TYPE:
    case 'praised':
    case 'praise':
      return '🙌';
    case 'replied':
    case 'reply':
      return '🗯';
    case 'commented':
    case 'comment':
    default:
      return '💬';
  }
};

export const activityTypes = {
  1: {
    action: 'commented',
    preposition: 'on',
    content: 'post',
    label: 'commented_on_post'
  },
  2: {
    action: 'replied',
    preposition: 'to',
    content: 'comment',
    label: 'replied_to_comment'
  },
  3: {
    action: 'replied',
    preposition: 'to',
    pronoun: 'a',
    content: 'comment',
    on: 'post',
    label: 'replied_to_comment_on_post'
  },
  4: {
    action: 'praised',
    content: 'post',
    label: 'praised_your_post'
  },
  5: {
    action: 'praised',
    content: 'comment',
    label: 'praised_your_comment'
  },
  6: {
    action: 'created',
    pronoun: 'a',
    content: 'post',
    label: 'created_post'
  }, 
  7: {
    action: 'tagged',
    preposition: 'in',
    content: 'post',
    pronoun: 'you',
    label: 'tagged_in_post'
  },
  8: {
    action: 'tagged',
    preposition: 'in',
    content: 'reply',
    pronoun: 'you',
    label: 'tagged_in_comment'
  },
  9: {
    action: 'tagged',
    preposition: 'in',
    content: 'reply',
    pronoun: 'you',
    label: 'tagged_in_reply'
  },
  10: {
    action: 'added',
    pronoun: 'a',
    content: 'post',
    preposition: 'in',
    label: 'created_collective_post'
  }
};

export function getNotificationSnippet({ commentContent, postContent, activity }) {
  const { action, content } = activityTypes[activity];

  if (action === 'commented' || action === 'replied') {
    return commentContent && commentContent.body.replace(TAG_MARKUP_REGEX_CAPTURE, '$1');
  }

  if (action === 'praised' && content === 'post') {
    return postContent && postContent.title;
  }

  if (action === 'praised' && content === 'comment') {
    return commentContent && commentContent.body.replace(TAG_MARKUP_REGEX_CAPTURE, '$1');
  }

  if (action === 'created' && content === 'post') {
    return postContent && postContent.title;
  }

  return '';
}

export function getNotificationContentLink(postId, slug, hash) {
  if (!postId) return routes.notFound;
  
  return routeWithParams(routes.post, { id: postId, slug }) + (hash ? `#${hash}` : '');

  // temporary, should add a hook for comment
}

export function getNotificationCollectiveLink(slug) {
  if (!slug) return routes.notFound;
  
  return routeWithParams(routes.collective, { slug });
}