import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  TYPE_POST,
  TYPE_COMMENT,
  TYPE_REPLY
} from '../../components/UserWithBody';
import { TYPE_USER } from '../../containers/Profile/ProfileUser';
import {
  REPORT_MODAL,
  DELETE_MODAL
} from '../ModalManager/ModalTypes';

import { modalOpen } from '../../store/Modal/actions';
import { report } from '../../store/Report/actions';

import FlagIcon, { FLAG_THEME_WHITE, FLAG_THEME_RED } from '../../components/SVG/FlagIcon';


import './UserActions.scss';

class UserActions extends PureComponent {
  static propTypes = {
    userId: PropTypes.number.isRequired,
    type: PropTypes.oneOf([
      'post',
      'comment',
      'reply',
      'user'
    ]),
    typeId: PropTypes.number.isRequired,
    handleEditComment: PropTypes.func,
    handleEditPost: PropTypes.func,
    handleEditReply: PropTypes.func,
    handleReport: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      isActionsMenuOpen: false
    }

    ///
    this.userCommentActions = [
      {
        text: 'Edit Reply',
        onClick: this.props.handleEdit
      },
      {
        text: 'Delete Reply',
        className: 'UserActions__red',
        onClick: this.handleDelete
      }
    ];

    ///
    this.userPostActions = [
      {
        text: 'Edit Post',
        onClick: this.props.handleEdit
      },
      {
        text: 'Delete Post',
        className: 'UserActions__red',
        onClick: this.handleDelete
      }
    ];

    ///
    this.userReplyActions = [
      {
        text: 'Edit Reply',
        onClick: this.props.handleEdit
      },
      {
        text: 'Delete Reply',
        className: 'UserActions__red',
        onClick: this.handleDelete
      }
    ];

    ///
    this.actions = [
      {
        text: `Report ${this.props.type}`,
        onClick: this.handleReport
      }
    ];
  }

  componentDidMount() {
    document.addEventListener('click', this.closeActionsMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeActionsMenu);
  }

  closeActionsMenu = e => {
    if (this.actionsRef && !this.actionsRef.contains(e.target)) {
      this.setState({ isActionsMenuOpen: false });
    }
  }

  toggleActionsMenu = () => {
    this.setState({ isActionsMenuOpen: !this.state.isActionsMenuOpen });
  }

  setActionsRef = ref => {
    this.actionsRef = ref;
  }

  handleDelete = () => {
    this.props.modalOpen({
      modal: DELETE_MODAL,
      id: this.props.typeId,
      submit: this.props.handleDelete,
      position: 'belowNav',
      type: this.props.type
    });
  }

  handleReport = () => {
    this.props.modalOpen({
      modal: REPORT_MODAL,
      type: this.props.type,
      typeId: this.props.typeId,
      reporterId: this.props.ownUserId,
      postId: this.props.type === 'post' ? this.props.typeId : this.props.postId,
      submit: this.props.report
    });
  }

  determineActions = () => {
    const { type, doesBelongToUser } = this.props;

    if (doesBelongToUser) {
      switch (type) {
        case TYPE_POST:
          return this.userPostActions;
        case TYPE_COMMENT:
          return this.userCommentActions;
        case TYPE_REPLY:
        default:
          return this.userReplyActions;
      }
    } else {
      return this.actions;
    }
  }

  render() {
    const { isActionsMenuOpen } = this.state;
    const { flagged } = this.props;

    const actions = this.determineActions();

    return (
      <div className="UserActions">
        <span
          onClick={flagged ? null : this.toggleActionsMenu}
          className="UserActions__icon"
          ref={this.setActionsRef}
        >
        {
          this.props.doesBelongToUser && !this.props.flag || this.props.reportUser ? 
          <React.Fragment>
            <div className="UserActions__dots" />
            <div className="UserActions__dots" />
            <div className="UserActions__dots" />
          </React.Fragment> :
          null
        }
        {
          !this.props.doesBelongToUser && this.props.flag ?
          <FlagIcon theme={flagged ? FLAG_THEME_RED : FLAG_THEME_WHITE} width={23} /> : 
          null
        }
        
        </span>
        { 
          isActionsMenuOpen && (
            <div className="UserActions__menu">
              {
                actions.map(action => (
                  <span
                    className={classNames(
                      "UserActions__menu--item",
                      action.className
                    )}
                    key={action.text}
                    onClick={action.onClick}
                  >
                    {action.text}
                  </span>
                ))
              }
            </div>
          )
        }
      </div>
    )
  }
}

const mapStateToProps = ({ User }) => {
  return {
    ownUserId: User.user.id
  }
};

function mapDispatchToProps(dispatch) {
  return {
    modalOpen: (args) => dispatch(modalOpen(args)),
    report: (...args) => dispatch(report(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
