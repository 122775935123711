import React, { Component } from 'react';
import classNames from 'classnames';
import './PostLoadingScreen.scss';

class PostLoadingScreen extends Component {
    render() {
        const { isLoading } = this.props;

        return (
            <div className={(classNames("PostLoadingScreen", {isLoading}))}>
                <div className="PostLoadingScreen__loading-item">
                    <p>secret loading tags</p>
                </div>
                <div className="PostLoadingScreen__loading-item">
                    <p>super secret post loading screen title</p>
                </div>
                <div className="PostLoadingScreen__loading-item">
                    <p>super secret post loading screen content line number one</p>
                </div>
                <div className="PostLoadingScreen__loading-item">
                    <p>super secret post loading screen content line number two</p>
                </div>
            
                <div className="PostLoadingScreen__container">
                    <div className="PostLoadingScreen__loading-item--circle"/>

                    <div>
                        <div className="PostLoadingScreen__loading-item--small">
                            <p>super secret name of the person who is the post author</p>
                        </div>
                        <div className="PostLoadingScreen__loading-item--small">   
                            <p>super secret title of the author</p>
                        </div>

                        <div className="PostLoadingScreen__loading-item--small">   
                            <p>super secret timestamp</p>
                        </div>
                    </div>
                </div>

                <div className="PostLoadingScreen__container">
                    <div className="PostLoadingScreen__loading-item">
                        <p>secret button</p>
                    </div>
                    <div className="PostLoadingScreen__loading-item">
                        <p>secret button</p>
                    </div>
                </div>
            </div>
        )
    }
}


export default PostLoadingScreen;