const initialState = {
  isLoading: false,
  users: [],
  total: 0,
  offset: 0,
  hasLoadedAllUsers: false
};

import {
  DIRECTORY_SEARCH,
  DIRECTORY_SEARCH_SUCCESS,
  DIRECTORY_SEARCH_ERROR
} from './actions';

const OFFSET_LIMIT = 12;

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case DIRECTORY_SEARCH:
      return {
        ...state,
        isLoading: true
      };
    case DIRECTORY_SEARCH_SUCCESS:
        let hasLoadedAllUsers = false;
        let users;
        let offset;

        if (action.payload.loadMore) {
          users = [...state.users, ...action.payload.user];
          offset = state.offset;
        } else {
          users = [...action.payload.user];
          offset = 0;
        }

        offset += OFFSET_LIMIT;
        if (offset >= action.payload.total) hasLoadedAllUsers = true;

      return {
        ...state,
        offset,
        isLoading: false,
        users,
        total: action.payload.total,
        hasLoadedAllUsers
      };
    case DIRECTORY_SEARCH_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.error
      };
    default:
      return state;
  }
}

export default profileReducer;
