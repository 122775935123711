import {
  PREFERENCES_GET_EXTERNAL,
  PREFERENCES_GET_EXTERNAL_SUCCESS,
  PREFERENCES_GET_EXTERNAL_ERROR,
  PREFERENCES_UPDATE_EXTERNAL,
  PREFERENCES_UPDATE_EXTERNAL_SUCCESS,
  PREFERENCES_UPDATE_EXTERNAL_ERROR
} from './actions';

const initialState = {
  email: '',
  external: {},
  isLoading: true,
  saveCount: 0,
  errorCount: 0
};

function preferencesReducer(state = initialState, action) {
  switch (action.type) {
    case PREFERENCES_GET_EXTERNAL:
      return {
        ...state,
        isLoading: true
      }
    case PREFERENCES_GET_EXTERNAL_SUCCESS:
      return {
        ...state,
        external:action.payload.preferences,
        email:action.payload.email,
        isLoading: false
      }
    case PREFERENCES_GET_EXTERNAL_ERROR:
      return {
        ...state,
        isLoading: false
      }
    case PREFERENCES_UPDATE_EXTERNAL:
        return {
          ...state
        }
    case PREFERENCES_UPDATE_EXTERNAL_SUCCESS:
      return {
        ...state,
        saveCount: state.saveCount + 1,
        isLoading: false
      }
    case PREFERENCES_UPDATE_EXTERNAL_ERROR:
      return {
        ...state,
        errorCount: state.errorCount + 1,
        isLoading: false
      }
  }

  return state;
}

export default preferencesReducer;
