import {
  CLEAR_LOCATION,
  LOCATION_GET_FULFILLED,
  LOCATION_SELECTED_GET_FULFILLED,
  LOCATION_SESSION_TOKEN_SET
} from './actions';

const defaultState = {
  locations: [],
  keys: {},
  session: null,
  selection: {},
  selectionKeys: {}
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_LOCATION:
      let keys = { ...state.keys };
      let locations = state.locations;
      if (action.key) {
        delete keys[action.key];
      } else {
        locations = [];
      }
      return {
        ...state,
        locations,
        keys,
        session:null
      }
    case LOCATION_SESSION_TOKEN_SET: {
      return {
        ...state,
        session: action.payload
      }
    }
    case LOCATION_GET_FULFILLED: {
      let keys = { ...state.keys };
      let locations = null;
      if (action.request.key) {
        keys[action.request.key] = action.payload.data;
      } else {
        locations = action.payload.data;
      }
      return {
        ...state,
        keys,
        locations: locations || state.locations
      }
    }
    case LOCATION_SELECTED_GET_FULFILLED: {
      let selectionKeys = { ...state.selectionKeys };
      let selection = { ...state.selection };
      if (action.request.key) {
        selectionKeys[action.request.key] = action.payload.data;
      } else {
        selection = action.payload.data;
      }
      return {
        ...state,
        selectionKeys,
        selection,
        session: null
      }
    }
    default:
      return state;
  }
};
