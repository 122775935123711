import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WizardActions } from '../../../../components/Wizard';

import { updateCollectiveData } from '../../../../store/Collectives/actions';

import { ModalHeader, ModalBody, ModalFooter } from '../../ModalContainer';
import NewPostForm from '../../../../components/Forms/NewPostForm';

class ComponentCreationPostForm extends Component {
  getFormData = () => {
    this.postForm.submit();
  }

  submitPost = postData => {
    this.props.updateCollectiveData(postData);

    this.props.wizard.next();
  }

  render() {
    const { name } = this.props.collectiveCreation;

    return (
      <div className="CollectiveCreationModal__container">
        <ModalHeader 
          header="New Post"
          subHeader="Create your first post and start building your community."
        />
        <ModalBody>
          {<p className="CollectiveCreationModal__container--collective-line">Posting in <span>{name}</span></p>}

          <NewPostForm 
            close={this.props.close}
            onRef={ref => (this.postForm = ref)}
            setRef={this.props.setRef}
            submitPost={this.submitPost}
          />
        </ModalBody>


        <ModalFooter>
          <WizardActions wizard={this.props.wizard} onNext={this.getFormData} />
        </ModalFooter>
      </div>
    )
  }
}

const mapStateToProps = ({ Collectives }) => {
  return {
    collectiveCreation: Collectives.collectiveCreation
  }
}

export default connect(mapStateToProps, {updateCollectiveData})(ComponentCreationPostForm);