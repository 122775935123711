import React, { Component } from "react";

import collectiveHeroImage from "../../../../../public/assets/illustrations/meeting-mentor.png";

class WelcomeModalCollectiveMessage extends Component {
  render() {
    return (
      <div>
        <h2 className="WelcomeModal__header">Join a Collective today.</h2>

        <div className="WelcomeModal__hero">
          <img src={collectiveHeroImage} />
        </div>
        
        <p className="WelcomeModal__text">
          Collectives are groups filled with like-minded women having the convos
          you actually care about.
        </p>
      </div>
    );
  }
}

export default WelcomeModalCollectiveMessage;
