import {
  FEED_GET_LOADING,
  FEED_GET_SUCCESS,
  FEED_FEATURED_MEMBERS_GET_SUCCESS,
  SET_LAST_UPDATE,
  FEED_UPDATE_NEW_POSTS,
  ON_UPDATE_FEED,
  FEED_UPDATE_BLOCKED_POSTS,
  GET_CONTENT_TAGS,
  SELECT_CONTENT_TAG,
  SELECT_CONTENT_TAG_SUCCESS,
  CLEAR_CONTENT_TAGS_REDUCER,
  RESET_FEED_OFFSET,
  RESET_FEED_ENTITIES,
  INIT_FEED
} from './actions';
import {
  POST_SUCCESS,
  POST_DELETE_SUCCESS,
  POST_EDIT_SUCCESS
} from '../Post/actions';

import { differenceWith, isEqual } from 'lodash';

export const OFFSET_INCREASE = 20;

const initialState = {
  limit: OFFSET_INCREASE,
  offset: 0,
  totalPostCount: OFFSET_INCREASE,
  hasLoadedAllPosts: false,
  entities: [],
  isLoading: false,
  featured_members_entities: [],
  last_updated: null,
  newFeedEntities: null,
  blockedPostsEntities: null,
  contentTags: []
};

function feedReducer(state = initialState, action) {
  switch (action.type) {
    case POST_DELETE_SUCCESS: {
      const { postId } = action;
      let entities = state.entities.slice();

      const index = entities.indexOf(postId);
      entities.splice(index, 1);

      return {
        ...state,
        entities,
        isLoading: false
      }
    }
    case POST_EDIT_SUCCESS: {
      const { postId, payload } = action;

      let entities = state.entities;
      if (state.contentTags.length && !payload.topics.some(topic => (state.contentTags.indexOf(topic) >= 0))) {
        entities = state.entities.filter(post => (post != postId));
      }

      return {
        ...state,
        entities
      }
    }
    case POST_SUCCESS: {
      const feedWithNewPost = state.entities;
      state.entities.unshift(action.payload.id);

      return {
        ...state,
        ...{ entities: feedWithNewPost },
        isLoading: false
      };
    }
    case FEED_GET_SUCCESS: {
      // make data into entities by id to merge with post
      // feed: [key, key, key]
      const { payload, totalPostCount, limitFeed } = action;

      const feedWithEntities = payload.map(feedItem => ( feedItem.id ));
      const entities = [...state.entities, ...feedWithEntities];

      const offset = state.offset + OFFSET_INCREASE;

      let hasLoadedAllPosts;
        if (limitFeed) {
          hasLoadedAllPosts = true;
        } else {
          hasLoadedAllPosts = entities.length >= (totalPostCount || state.totalPostCount);
        }

      return {
        ...state,
        entities,
        offset,
        totalPostCount,
        hasLoadedAllPosts,
        isLoading: false
      };
    }
    case FEED_UPDATE_NEW_POSTS: {
      return {
        ...state,
        newFeedEntities: action.newFeedEntities
      }
    }
    case ON_UPDATE_FEED: {
      const newEntitiesOffset = state.newFeedEntities.length;
      const feedWithEntities = state.newFeedEntities;
      const diffEntities = differenceWith(feedWithEntities, state.entities, isEqual);
      const entities = [...diffEntities, ...state.entities];
      const last_updated = Date.now();

      return {
        ...state,
        entities,
        newFeedEntities: null,
        last_updated,
        offset: state.offset + newEntitiesOffset
      }
    }
    case FEED_FEATURED_MEMBERS_GET_SUCCESS: {
      const featuredMembersEntities = action.payload.map(featuredMember => ( featuredMember.username ));

      return {
        ...state,
        featured_members_entities: featuredMembersEntities
      };
    }
    case FEED_GET_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case SET_LAST_UPDATE:
      let last_updated = Date.now();

      return {
        ...state,
        last_updated
      }
    case FEED_UPDATE_BLOCKED_POSTS: 
      const { blocked_ids } = action;
      const blocked = blocked_ids.map(obj => obj.id);
      let newEntities = state.entities.filter(id => blocked.indexOf(id) === -1 );

      return {
        ...state,
        entities: newEntities,
        blockedPostsEntities: blocked_ids
      }
    case GET_CONTENT_TAGS: 
      return {
        ...state,
        contentTags: [...action.payload]
      }
    case SELECT_CONTENT_TAG: 
      return {
        ...state,
        isLoading: true
      }
    case SELECT_CONTENT_TAG_SUCCESS:
      return {
        ...state,
        contentTags: [...action.payload],
        newFeedEntities: null
      };
    case CLEAR_CONTENT_TAGS_REDUCER:
      return {
        ...state,
        contentTags: []
      }
    case RESET_FEED_OFFSET: 
      return {
        ...state,
        offset: 0
      };
    case RESET_FEED_ENTITIES: 
      return {
        ...state,
        entities: []
      }
    case INIT_FEED: 
      return {
        ...initialState,
        contentTags: []
      }
    default:
      /*
        Default behavior can't set isLoading = false
        due to the possibility of race conditions between
        sagas inadvertently triggering it on load.
      */
      return state;
  }
}

export default feedReducer;
