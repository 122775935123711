import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

class LinkWithParams extends Component {
  static propTypes = { to: PropTypes.string };
  render() {
    const { to, location, children } = this.props;
    return ( <Link to={to + location.search}>{children}</Link> );
  }
}

export default withRouter(LinkWithParams);
