import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SVG from 'react-inlinesvg';
import { selectRequest } from '../../../store/requests';
import { collectiveMemberGet } from '../../../store/Collectives/actions';
import { connectionsGet } from '../../../store/Connections/actions';
import { debounce } from 'lodash';

import { ModalContainer, ModalHeader, ModalBody} from '../ModalContainer';
import UserList from '../../../components/UserList';
import InputWithIcon from '../../../common/Input/InputWithIcon';
import { INPUT_SECONDARY_PLAIN } from '../../../common/Input';
import searchIcon from '../../../../public/assets/icons/SEARCH.svg';

import './UserListModal.scss';
import Button, { THEME_GRAY, THEME_CLEAR } from '../../../common/Button';
import { admirersGet } from '../../../store/Admires/actions';

class UserListModal extends Component {
  static defaultProps = {
    loadMore: () => {},
    hasLoadedAllUsers: true,
    offset: 0
  }

  static propTypes = {
    hasLoadedAllUsers: PropTypes.bool,
    offset: PropTypes.number,
    userList: PropTypes.string,
    currentOption: PropTypes.string,
    options: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      search:''
    }
    this.debouncedLoad = debounce(this.loadMore, 300);
    this.bodyRef = null;
  }

  search = (search) => {
    if (this.state.search != search) {
      this.setState({search}, () => {
        this.props.clear();
        this.debouncedLoad();
      });
    }
  }

  loadMore = () => {
    this.props.loadMore(this.state.search);
  }

  setBodyRef = ref => {
    this.bodyRef = ref;
    this.forceUpdate();
  }

  render() {
    let {
      close,
      setRef,
      canSearch,
      clear,
      request,
      header,
      users,
      hasLoadedAllUsers,
      offset,
      adminIds,
      currentOption,
      options
    } = this.props;

    const useOption = options && currentOption;
    const option = useOption ? options[currentOption] : null;

    return (
      <ModalContainer
        close={close}
        setRef={setRef}
        className="UserListModal"
      >
        {this.props.children}

        <ModalHeader header={header ? header : ''}>
          {
            canSearch && (
              <div style={{marginTop:'30px'}}>
                <InputWithIcon 
                  addBefore={<SVG src={searchIcon} />}
                  autoCorrect={'off'}
                  onChange={this.search}
                  placeholder="Search connections"
                  theme={INPUT_SECONDARY_PLAIN}
                  value={this.state.search}
                />
              </div>
            )
          }
        </ModalHeader>

        <ModalBody setRef={this.setBodyRef}>
          {
            options && (
              <div className="UserListModal__options">
                {
                  Object.keys(options).map(key => (
                    <Button
                      key={options[key].label}
                      pill
                      autoWidth
                      theme={currentOption && currentOption === options[key].label ? THEME_GRAY : THEME_CLEAR}
                      onClick={options[key].action}
                    >
                      {options[key].label}
                    </Button>
                  ))
                }
              </div>
            )
          }
          <UserList
            users={useOption ? option.users : users} 
            hasLoadedAllUsers={useOption ? option.hasLoadedAllUsers : hasLoadedAllUsers}
            offset={offset}
            canSearch={canSearch}
            clear={clear}
            loadMore={this.loadMore}
            debounceLoad={this.debouncedLoad}
            request={request}
            adminIds={adminIds}
            searchValue={this.state.search}
            listRef={this.bodyRef}
          />
        </ModalBody>
      </ModalContainer>
    )
  }
}

export const CollectiveMembersList = connect((state, props) => {
  let collective = state.Collectives.collectives[props.slug];
  let currentMembersList = state.Collectives.currentMembersList;
  let users;

  if (props.role) {
    if (props.role === 'subscriber') {
      users = currentMembersList
      .filter(member => member.role === props.role)
    } else {
      users = collective.members
      .filter(member => member.role === props.role)
    }
  } else {
    users = currentMembersList;
  }

  users = users.map(member => member.user);
    
  return {
    users,
    hasLoadedAllUsers: collective.hasLoadedAllUsers,
    offset: collective.offset,
    request: selectRequest(state, collectiveMemberGet)
  }
})(UserListModal);

export const IndustryAndLocalMembersList = connect((state, props) => {
  let users = props.members;

  return {
    users: users,
    request: selectRequest(state, collectiveMemberGet)
  }
})(UserListModal);

export const ConnectionList = connect((state, props) => {
  let connections = state.Connections.connections;
    
  return {
    users:connections,
    hasLoadedAllUsers: state.Connections.hasLoadedAllConnections,
    offset: state.Connections.offset,
    request: selectRequest(state, connectionsGet)
  }
})(UserListModal);

export const AdmirersList = connect((state, props) => {
  let admirers = state.Admires.admirers;
  
  
  return {
    users:admirers,
    hasLoadedAllUsers: state.Admires.hasLoadedAllAdmirers,
    offset: state.Admires.offset,
    request: selectRequest(state, admirersGet)
  }
})(UserListModal);

export default UserListModal;