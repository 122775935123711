import {
  COLLECTIVES_GET_FULFILLED,
  COLLECTIVE_GET_FULFILLED,
  COLLECTIVE_MEMBER_GET_FULFILLED,
  SET_CURRENT_COLLECTIVE_SLUG,
  CLEAR_COLLECTIVES_OFFSET,
  COLLECTIVE_MEMBER_ADD_FULFILLED,
  COLLECTIVE_MEMBER_REMOVE_FULFILLED,
  CLEAR_CURRENT_MEMBERS_LIST,
  CLEAR_USER_COLLECTIVES,
  CREATE_COLLECTIVE_FULFILLED,
  CREATE_COLLECTIVE_REJECTED,
  UPDATE_COLLECTIVE_FULFILLED,
  UPDATE_COLLECTIVE_REJECTED,
  UPDATE_COLLECTIVE_DATA,
  CLEAR_COLLECTIVE_DATA
} from './actions';

import {
  POST_SUCCESS
} from '../Post/actions';

import { shuffle } from 'lodash';

const defaultState = {
  collectives: {
    girlboss: {}
  },
  userCollectives: {},
  recommendedCollectives: {},
  directorySlugs: [],
  totalCollectives: 0,
  offset: 0,
  hasLoadedAllCollectives: false,
  currentSlug: null,
  currentMembersList: [],
  collectiveCreation: {
    errors: {}
  }
};

const OFFSET_LIMIT = 12;

export default (state = defaultState, action) => {
  switch (action.type) {
    case COLLECTIVES_GET_FULFILLED: {
      if (action.request.key === 'recommended') {
        let recommendedCollectives = {};
        let collectives = shuffle(action.payload.data);

        collectives.forEach(collective => {
          recommendedCollectives[collective.slug] = collective;
        });

        return {
          ...state,
          recommendedCollectives
        }
      } else {
        let collectives = {...state.collectives};
        let directorySlugs;
        let offset;
        let hasLoadedAllCollectives = false;

        // initial load
        if (action.request.initialPayload.offset === 0) {
          offset = 0;
          directorySlugs = [];
        } else {
          // load more
          offset = state.offset;
          directorySlugs = [...state.directorySlugs];
        }

        action.payload.data.forEach(collective => {
          collectives[collective.slug] = collective;
          directorySlugs.push(collective.slug);
        });
                
        offset += OFFSET_LIMIT;

        if (offset >= action.payload.total) hasLoadedAllCollectives = true;

        return {
          ...state,
          collectives,
          directorySlugs,
          totalCollectives: action.payload.total,
          hasLoadedAllCollectives,
          offset
        };
      }
    }
    case COLLECTIVE_GET_FULFILLED:
      let collectives = {
        ...state.collectives,
        [action.payload.data.slug]:action.payload.data
      };
      return {
        ...state,
        collectives
      };
    case COLLECTIVE_MEMBER_GET_FULFILLED: {
      let { userId, slug, members, total, shouldShuffle } = action.payload;

      if (userId) {
        let userCollectives = {...state.userCollectives};
        let hasLoadedAllCollectives = false;
        let offset = state.offset;

        if (shouldShuffle) {
          members = shuffle(members);
        }

        members.forEach(member => {
          userCollectives[member.collective.slug] = member.collective;
          userCollectives[member.collective.slug].role = member.role;
        });

        offset = Object.keys(userCollectives).length;
        if (offset >= total) {
          hasLoadedAllCollectives = true;
        }

        return {
          ...state,
          userCollectives,
          offset,
          hasLoadedAllCollectives
        };
      } else {
        let collectives = { ...state.collectives };
        let currentMembersList = state.currentMembersList;
        
        // //update all members
        let newMembersList = [...currentMembersList, ...members];
        
        // update offset and check if it has loaded all members
        let hasLoadedAllUsers = false;
        if (newMembersList.length >= collectives[slug].member_count) hasLoadedAllUsers = true;
        collectives[slug].hasLoadedAllUsers = hasLoadedAllUsers;
        if (collectives[slug].offset) {
          collectives[slug].offset = collectives[slug].offset + members.length;
        } else {
          collectives[slug].offset =  members.length;
        }

        return { 
          ...state, 
          collectives,
          currentMembersList: newMembersList
        };
      }
    }
    case SET_CURRENT_COLLECTIVE_SLUG: 
      return {
        ...state,
        currentSlug: action.payload
      }
    case CLEAR_USER_COLLECTIVES: 
      return {
        ...state,
        offset: 0,
        userCollectives: {},
        hasLoadedAllCollectives: false
      }
    case CLEAR_COLLECTIVES_OFFSET: 
      return {
        ...state,
        offset: 0,
        hasLoadedAllCollectives: false
      }
    case COLLECTIVE_MEMBER_ADD_FULFILLED: {
      let collectives;
      function addMember() {
        let slug = Object.keys(collectives).find(slug => (collectives[slug].id == action.payload.id));
        let member = action.payload.member;
        member.user = action.payload.user;
        collectives[slug].members.unshift(member);
        collectives[slug].member_count += 1;
      };
      if(action.payload.list === 'recommended') {
        collectives = {...state.recommendedCollectives};
        addMember();
        return {
          ...state,
          recommendedCollectives: collectives
        };
      } else if (action.payload.list === 'user') {
        collectives = {...state.recommendedCollectives};
        addMember();
        return {
          ...state,
          userCollectives: collectives
        }
      } else {
        collectives = {...state.collectives};
        addMember();
        return {
          ...state,
          collectives
        }
      }
    }
    case COLLECTIVE_MEMBER_REMOVE_FULFILLED: {
      const { slug, memberId, list } = action.request.initialPayload;
      let collectives;
      let userCollectives;

      function removeMember() {
        collectives[slug].members = collectives[slug].members.filter(
          member => member.id != memberId
        );
        collectives[slug].member_count -= 1;
        if (userCollectives && userCollectives[slug]) delete userCollectives[slug];
      };
      if(list === 'recommended') {
        collectives = {...state.recommendedCollectives};
        removeMember();

        return {
          ...state,
          recommendedCollectives: collectives
        };
      } else if(list === 'user') {
        collectives = {...state.userCollectives};
        removeMember();

        return {
          ...state,
          userCollectives: collectives
        };
      } else {
        collectives = {...state.collectives};
        userCollectives = { ...state.userCollectives }
        removeMember();

        return {
          ...state,
          collectives,
          userCollectives
        };
      }
    }
    case CLEAR_CURRENT_MEMBERS_LIST: {
      return {
        ...state,
        currentMembersList: []
      }
    }
    case CREATE_COLLECTIVE_FULFILLED: {
      let collectiveCreation = {...state.collectiveCreation};
      if (collectiveCreation.errors) collectiveCreation.errors = {};

      return {
        ...state,
        collectiveCreation
      }
    }
    case UPDATE_COLLECTIVE_FULFILLED: {
      const { data } = action.payload;
      let collectives = {...state.collectives};
      collectives[data.slug] = data;

      let collectiveCreation = {...state.collectiveCreation};
      if (collectiveCreation.errors) collectiveCreation.errors = {};

      return {
        ...state,
        collectives,
        collectiveCreation
      }
    }
    case CREATE_COLLECTIVE_REJECTED:
    case UPDATE_COLLECTIVE_REJECTED: {
      let collectiveCreation = {...state.collectiveCreation};
      const { response } = action.payload;
     
      if (response && response.errors) {
        let errors = {};
        response.errors.forEach(e => {
          errors[e.path] = e.message;
        })
        collectiveCreation.errors = errors;
      }
      return {
        ...state,
        collectiveCreation
      }
    }
    case UPDATE_COLLECTIVE_DATA: {
      let data = {...state.collectiveCreation, ...action.payload};

      return {
        ...state,
        collectiveCreation: data
      }
    }
    case CLEAR_COLLECTIVE_DATA:
      return {
        ...state,
        collectiveCreation: {errors: {}}
      }
    case POST_SUCCESS: {
      const { collective: { slug }, creator } = action.payload;
      const collective = { ...state.collectives[slug] };
      const member = (collective.members || []).find(member => member.user_id === creator.id);

      collective.post_count++;

      if (!member && creator.collective_members[0]) {
        collective.member_count++;
        collective.members = [
          ...collective.members,
          {
            ...creator.collective_members[0],
            user: creator
          }
        ];
      };

      return {
        ...state,
        collectives: {
          ...state.collectives,
          [slug]: collective
        }
      }
    }

    default:
      return state;
  }
};
