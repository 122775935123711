import React, { Component } from 'react'
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import ellipsize from 'ellipsize';
import { Link } from 'react-router-dom';

import MembersPreview from '../../../components/Collectives/MembersPreview';
import PostNumber from '../../../components/Collectives/PostNumber';
import addIcon from '../../../../public/assets/icons/ADD.svg';
import checkIcon from '../../../../public/assets/icons/CHECK.svg'
import { THEME_BLUE, THEME_LIGHT_BLUE } from '../../../common/Button';
import ButtonToggle from '../../../common/Button/ButtonToggle';


class CollectivesDirectoryItem extends Component {
  static propTypes = {
    collective: PropTypes.shape({
      created_at: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      image: PropTypes.image,
      location: PropTypes.string,
      location_lat: PropTypes.string,
      location_lng: PropTypes.string,
      member_count: PropTypes.number,
      members: PropTypes.array,
      name: PropTypes.string,
      post_count: PropTypes.number,
      slug: PropTypes.string,
      tags: PropTypes.array,
      updated_at: PropTypes.string
    })
  };

  onSubscribe = e => {
    e.stopPropagation();
    this.props.onSubscribe({
      collective_id: this.props.collective.id
    });
  }

  onUnsubscribe = e => {
    e.stopPropagation();
    const memberId = this.props.collective.members.find(member => (member.user.id === this.props.user.id)).id;
    this.props.onUnsubscribe({
      memberId,
      collective_id: this.props.collective.id,
      slug: this.props.collective.slug
    });
  }

    renderDescription = () => {
      return ellipsize(this.props.collective.description, 80);
    }

    render() {
      const { 
          collective, 
          collectivePolicy,
          isMobile,
          user
      } = this.props;

      let isSubscribed = !!collective.members.find(member => member.user.id === user.id);
      let isAdmin = collective.members.find(member => (member.user.id === user.id) && (member.role === 'admin'));
      let canSubscribe = collectivePolicy.subscribe();      

      return (
        <div className="CollectivesDirectoryItem">
          <div className="CollectivesDirectoryItem__container">
            <div
              className="CollectivesDirectoryItem__photo"
              style={{ backgroundImage: `url(${collective.image.large})` }}
            />
            <div className="CollectivesDirectoryItem__details">
              <h1>{collective.name}</h1>
              <p>{this.renderDescription()}</p>

              <div className="CollectivesDirectoryItem__details--info">
                <MembersPreview showAvatar={isMobile} members={collective.members} memberCount={collective.member_count} userId={user.id} />
                <PostNumber count={collective.post_count} />
              </div>
            </div>
          </div>

        {
          canSubscribe &&  (!collectivePolicy.limitFeed() ? (
            <ButtonToggle
              noOutline
              pill
              className="CollectivesDirectoryItem__cta"
              checkedTheme={THEME_BLUE}
              uncheckedTheme={THEME_LIGHT_BLUE}
              checkedContent={<React.Fragment><SVG src={checkIcon} /> Subscribed</React.Fragment>}
              uncheckedContent={<React.Fragment><SVG src={addIcon} /> Subscribe</React.Fragment>}
              isChecked={isSubscribed}
              onClick={isSubscribed ? this.onUnsubscribe : this.onSubscribe}
              disabled={isSubscribed && !!isAdmin}
          />
          ) : (
            <div style={{width: '100%', height: '47px'}} />
            )
          )
        }

        <Link className="CollectivesDirectoryItem__link" to={`/groups/${collective.slug}`}>
          <div className="CollectivesDirectoryItem__link"></div>
        </Link>
      </div>

    )
  }
}

export default CollectivesDirectoryItem;