import React, { Component } from 'react';
import PropTypes from 'prop-types';
import XRegExp from 'xregexp';

import InputWithTextHighlighting from './InputWithTextHighlighting.js';
import UserTagSelector from '../../components/UserTagSelector';

import * as tagUtils from '../../utils/userTag.js';

const replaceSpecialChars = (str) => (str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'\\$&'));

class InputWithUserTagging extends Component {
  static propTypes = {
    onChange:PropTypes.func
  };

  constructor (props) {
    super(props);

    const convertedTags = this.convertMarkupToTags(props.value);

    this.state = {
      value:convertedTags.markup || props.value || '',
      showUserSelector:false,
      tagging:false,
      matches:[],
      tags:convertedTags.tags || []
    };

    this.inputRef = null;
  }

  componentDidMount = () => {
    if(this.state.value !== '' && this.props.updateBodyCount) {
      this.props.updateBodyCount(this.state.value);
    };
  }

  setRef = ref => {
    this.inputRef = ref;

    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  handleInput = (e) => {
    const { onChange, updateBodyCount, maxLength } = this.props;
    const value = maxLength ? e.slice(0, maxLength) : e;
    onChange(this.convertTagsToMarkup(value));

    this.setState({value}, () => {
      this.parseMatches();
      if(updateBodyCount) {
        updateBodyCount(value);
      }
    });
  }

  parseMatches = () => {
    const { value } = this.state;

    const regex = new XRegExp(
      `@(?:${tagUtils.NAME_VALID_CHARACTERS_CLASS_ESC}*)(?:\s(?:${tagUtils.NAME_VALID_CHARACTERS_CLASS_ESC}*)){0,4}`,
      'gu'
    );
    let matches = [];
    let match;

    do {
      match = regex.exec(value);
      if (match) {
        matches.push(
          {
            name: match[0],
            startIndex:match.index,
            endIndex:match.index + match[0].length,
            active: this.inputRef &&
                    !this.checkForOverlapWithTag(match[0]) &&
                    this.inputRef.selectionStart >= match.index &&
                    this.inputRef.selectionStart <= match.index + match[0].length
          }
        );
      }
    } while (match);

    this.setState({matches}, () => {
      this.validateSelectedTags();
    });
  }

  validateSelectedTags = () => {
    let { matches, tags } = this.state;

    tags = tags.filter(tag => (
      matches.find(match => (
        match.name.startsWith(tag.name)
      ))
    ));

    this.setState({tags}, () => {
      this.checkForUserSelector();
    });
  }

  checkForUserSelector = () => {
    const { matches, tags } = this.state;
    let match = matches.filter(match => (match.active));
    this.setState({
      showUserSelector:match.length && !tags.find(tag => (tag.name == match[0].name)) || null
    });
  }

  renderUserSelector = () => {
    const { matches, tags } = this.state;
    let match = matches.find(match => (match.active));

    // Return nothing if the match is nonexistent.
    if (!match || match.name === '@') return null;

    const cursor = this.getCursorXY(this.inputRef, match.startIndex);
    const search = match.name.substr(1);

    return (
      <UserTagSelector
        search={search}
        horizontal={cursor.x}
        vertical={cursor.y}
        tags={tags}
        addTag={this.addTag}
        label={this.props.label}
      />
    );
  }

  addTag = (id, name) => {
    let { matches, tags, value } = this.state;

    // Avoid creating duplicate tags.
    if (tags.find(tag => (tag.id == id))) {
      return;
    }

    // Find the active tag, add the ID to it, and replace the name in the textarea and tag.
    let match = matches.find(match => (match.active));
    if (match) {
      match.id = id;
      match.name = '@' + name;
      tags.push(match);
      
      this.setState({tags, showUserSelector:false, value:this.getReplacedNameInMatch(value, name, match)}, () => {
        this.props.onChange(this.convertTagsToMarkup(this.state.value));
        this.inputRef.focus();
        this.inputRef.setSelectionRange(match.startIndex + match.name.length, match.startIndex + match.name.length);
      });
    }
  }

  getReplacedNameInMatch = (value, name, match) => {
    value = value.slice(0, match.startIndex) + '@' + name + value.slice(match.endIndex);
    return value;
  }

  getCursorXY = (input, selectionPoint) => {
    const {
      offsetLeft: inputX,
      offsetTop: inputY,
    } = input
    // create a dummy element that will be a clone of our input
    const div = document.createElement('div')
    // get the computed style of the input and clone it onto the dummy element
    const copyStyle = getComputedStyle(input)
    for (const prop of copyStyle) {
      div.style[prop] = copyStyle[prop]
    }
    // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
    const swap = '.'
    const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value
    // set the div content to that of the textarea up until selection
    const textContent = inputValue.substr(0, selectionPoint)
    // set the text content of the dummy element div
    div.textContent = textContent
    if (input.tagName === 'TEXTAREA') div.style.height = 'auto'
    // if a single line input then the div needs to be single line and not break out like a text area
    if (input.tagName === 'INPUT') div.style.width = 'auto'
    // create a marker element to obtain caret position
    const span = document.createElement('span')
    // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
    span.textContent = inputValue.substr(selectionPoint) || '.'
    // append the span marker to the div
    div.appendChild(span)
    // append the dummy element to the body
    document.body.appendChild(div)
    // get the marker position, this is the caret position top and left relative to the input
    const { offsetLeft: spanX, offsetTop: spanY } = span
    // lastly, remove that dummy element
    // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
    document.body.removeChild(div)
    // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input

    const rect = this.inputRef.getBoundingClientRect();

    return {
      x: rect.left + inputX + spanX,
      // y: rect.top + inputY + spanY
      y: rect.top
    }
  }

  checkForOverlapWithTag = (matchName) => {
    let { tags } = this.state;
    return tags.find(tag => (matchName.startsWith(tag.name)));
  }

  getMatchHighlightRegex = () => {
    let { matches, tags } = this.state;
    if (matches.length) {
      // If there are any matches that are already tagged, don't show them
      if (tags.length) {
        matches = matches.filter(match => (
          // We don't want to include any matches that start with a tag name
          // E.g. "@John Kurnett check this out" (the match name, five words max) starts with "@John Kurnett" (the actual tag name)
          // !tags.find(tag => (match.name.startsWith(tag.name)))
          !this.checkForOverlapWithTag(match.name)
        ))
      }

      if (!matches.length) {
        // Avoid matching accidentally anything if tags filter everything out.
        return new RegExp('a^');
      }

      const regexString = '(' + matches.map(match => (replaceSpecialChars(match.name))).join(')|(') + ')';
      return new RegExp(regexString, 'g');
    }
    // Avoid matching accidentally anything if there are no tags.
    return new RegExp('a^');
  }

  getTagRegex = () => {
    const { tags } = this.state;
    if (tags.length) {
      const regexString = '(' + tags.map(tag => (replaceSpecialChars(tag.name))).join(')|(') + ')';
      return new RegExp(regexString, 'g');
    }
    // Avoid matching accidentally anything if there are no tags.
    return new RegExp('a^');
  }

  convertTagsToMarkup = (markup) => {
    /*
    
    Markup follows format...

    [[U@123||John Kurnett]]

    '123' being the user ID, 'John Kurnett' being the label.

    */

    const { tags } = this.state;

    tags.forEach(tag => {
      const tagRegex = new RegExp(replaceSpecialChars(tag.name));
      markup = markup.replace(tagRegex,
        `${tagUtils.TAG_MARKUP_PREFIX}${tag.id}${tagUtils.TAG_MARKUP_SEPARATOR}${tag.name.substr(1)}${tagUtils.TAG_MARKUP_SUFFIX}`);
    });

    // Add a space between tags if they're smushed together.
    const suffixPrefixRegex = new RegExp(`${tagUtils.TAG_MARKUP_SUFFIX_ESC}${tagUtils.TAG_MARKUP_PREFIX_ESC}`, 'g');
    markup = markup.replace(suffixPrefixRegex, `${tagUtils.TAG_MARKUP_SUFFIX} ${tagUtils.TAG_MARKUP_PREFIX}`);

    return markup;
  }

  convertMarkupToTags = (markup) => {
    /*
    
    [[U@25||John Kurnett]] ==> @John Kurnett + { id:25, name:'@John Kurnett' }

    Match regex [[U@#||words]]
    Split on ||
    Substring U@# ==> # to get id
    Add @ to name to get @name
    Create object with id and name
    Replace regex with name

    */

    if (!markup) return {};

    const tagsMarkup = markup.match(tagUtils.TAG_MARKUP_REGEX);

    if (!tagsMarkup) return {};

    let tags = [];

    tagsMarkup.forEach(tag => {
      let tagContent = tag.replace(tagUtils.TAG_MARKUP_PREFIX_REGEX, '').replace(tagUtils.TAG_MARKUP_SUFFIX_REGEX, '');
      let tagItems = tagContent.split(tagUtils.TAG_MARKUP_SEPARATOR);
      let match = {
        id:tagItems[0],
        name:'@' + tagItems[1]
      }
      tags.push(match);
      markup = markup.replace(tag, '@' + tagItems[1]);
    });

    return {
      tags,
      markup
    };
  }

  render() {
    const { showUserSelector, value } = this.state;

    return (
      <div style={{width:'100%'}}>
        <InputWithTextHighlighting
          {...this.props}
          onChange={this.handleInput}
          onClick={this.parseMatches}
          controlledValue={value}
          setRef={this.setRef}
          expressions={[
            {regex:this.getTagRegex(), color:'#A8B0F5'},
            {regex:this.getMatchHighlightRegex(), color:'#E9EBFF'}
          ]}
        />
        {
          showUserSelector && this.renderUserSelector()
        }
      </div>
    )
  }
}

export default InputWithUserTagging;
