import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { acceptContactRequest, ignoreContactRequest, renewContactRequestState } from '../../../../store/Contact/actions';
import { modalClose } from '../../../../store/Modal/actions';

import { ModalContainer, ModalBody } from '../../ModalContainer'
import ContactRequestResponseContent from './ContactRequestResponseContent';
import ContactRequestResponseAccepted from './ContactRequestResponseAccepted';
import Loader from '../../../../common/Loader';

import {
//   GTMpushDataLayerEvent,
//   GTM_CONTACT_REQUEST_STARTED,
//   GTM_CONTACT_REQUEST_INTENT_SELECTED
} from '../../../../lib/GoogleTagManager';

import './ContactRequestResponseModal.scss';

class ContactRequestResponseModal extends Component {
  static propTypes = {
    initiator:PropTypes.object,
    receiver:PropTypes.object,
    user:PropTypes.object,
    send:PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      waiting:false
    }

    this.props.renewContactRequestState();
  }

  setRef = ref => {
    const { setRef } = this.props;
    if (setRef) setRef(ref);
  }

  onAcceptRequest = (request_id) => {
    this.props.acceptContactRequest(request_id, this.props.sender.username);
    this.setState({waiting:true});
  }

  onIgnoreRequest = (request_id) => {
    this.props.ignoreContactRequest(request_id);
    this.props.modalClose();
  };

  // goToMessaging = () => {
  //   setTimeout(() => {
  //     this.props.modalClose();
  //     this.props.history.push(`/messaging/${this.props.acceptedChannelUrl}`);
  //   }, 1500);
  // }

  render() {
    const { sender, receiver, contactRequest, acceptedChannelUrl } = this.props;
    const { waiting } = this.state;

    // acceptedChannelUrl !== null ? this.goToMessaging() : false;

    return (
      <ModalContainer className={classNames("ContactRequestResponseModal")} ref={this.setRef}>
        {this.props.children}

        <ModalBody>

          {
            !acceptedChannelUrl && (
              (
               !waiting && (
                  <ContactRequestResponseContent 
                    onAccept={this.onAcceptRequest}
                    onIgnore={this.onIgnoreRequest}
                    sender={sender}
                    contactRequest={contactRequest}
                  />
                ) || (
                  <div style={{marginTop: '45%'}}>
                    <Loader className="bg__blue" />
                  </div>
                )
              )
            )
          }

          {
            acceptedChannelUrl && (
              <ContactRequestResponseAccepted sender={sender} receiver={receiver} />
            )
          } 

        </ModalBody>
      </ModalContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    acceptedChannelUrl: state.Contact.acceptedChannelUrl
  }
}

export default connect(mapStateToProps, { acceptContactRequest, ignoreContactRequest, modalClose, renewContactRequestState })(withRouter(ContactRequestResponseModal));
