import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProfilePhotoUploader from '../../components/ProfilePhotoUploader';

import './Onboarding.scss';

class OnboardingPhoto extends PureComponent {
  static propTypes = {
    profilePhoto:PropTypes.func
  };
   
  render() {
    return (
      <div>
        <h2 className="Onboarding__heading">Almost done.</h2>
        <h2 className="Onboarding__heading">Upload a recent photo of yourself</h2>
        <br />
        <ProfilePhotoUploader
          className="Onboarding__photo-upload"
          uploadText="Upload"
        />
      </div>
    )
  }
}

export default OnboardingPhoto;
