import { takeEvery, put, call } from 'redux-saga/effects';
import {
   SEND_CONTACT_REQUEST,
   sendContactRequestSuccess,
   sendContactRequestError,
   CONTACTS_GET,
   contactsGetSuccess,
   contactsGetError,
   contactsGetEmpty,
   CONTACTS_SEARCH,
   ACCEPT_CONTACT_REQUEST,
   IGNORE_CONTACT_REQUEST,
   respondContactRequestSuccess,
   respondContactRequestError
} from './actions';
import {
  userGet
} from '../User/actions';
import { profileGet } from '../Profile/actions';
import { notifsGet } from '../Notifications/actions';
import fetchAPI from '../../services/APIService';
import {
  GTMpushDataLayerEvent,
  GTM_CONTACT_REQUEST_SENT
} from '../../lib/GoogleTagManager';

function* sendContactRequestSaga(action) {
  const response = yield call(fetchAPI, 'sendContactRequest', {
    body: action.payload
  });

  if (response.success) {
    const request = response.request;
    yield put(sendContactRequestSuccess(request));
    yield put(userGet());
  } else {
    yield put(sendContactRequestError(request));
  }

  GTMpushDataLayerEvent({
    event: GTM_CONTACT_REQUEST_SENT
  });
};

function* acceptContactRequestSaga(action) {
  const { requestId, username } = action.payload;
  const response = yield call(fetchAPI, 'acceptContactRequest', {
    body: { requestId }
  });

  if (response.success) {
    const request = response.channel_url;
    yield put(respondContactRequestSuccess(request));
    yield put(notifsGet());

    // Reload profile if viewing the initiator's profile.
    if (window.location.pathname === `/${username}`) {
      yield put(profileGet(username));
    }
  } else {
    yield put(respondContactRequestError(request));
  }
}

function* ignoreContactRequestSaga(action) {
  const response = yield call(fetchAPI, 'ignoreContactRequest', {
    body: {requestId: action.payload}
  });

  if (response.success) {
    const request = response.request;
    // yield put(respondContactRequestSuccess(request));
    yield put(notifsGet());

  } else {
    yield put(respondContactRequestError(request));
  }
}

function* contactsSagaGet(action) {
  const response = yield call(fetchAPI, 'getContacts', {
    body: {}
  });
  const request = response.contacts;
  if(response.success && request.length === 0) {
    yield put(contactsGetEmpty());
    yield put(contactsGetSuccess(request));
  } else if(response.success && request.length > 0) {
    yield put(contactsGetSuccess(request));
  }
  else {
    yield put(contactsGetError(request));
  }
}

function* contactsSearchSaga(action) {
  const response = yield call(fetchAPI, 'getContacts', {
    body: { searchTerms: action.payload}
  });
  const request = response.contacts;
  if(response.success && request.length > 0) {
    yield put(contactsGetSuccess(request));
  } else if(response.success && request.length === 0 && action.payload) {
    yield put(contactsGetError('No results matched your search!'));
  } else {
    yield put(contactsGetError(request));
  }
}

export const contactSagas = [
  takeEvery(SEND_CONTACT_REQUEST, sendContactRequestSaga),
  takeEvery(CONTACTS_GET, contactsSagaGet),
  takeEvery(CONTACTS_SEARCH, contactsSearchSaga),
  takeEvery(ACCEPT_CONTACT_REQUEST, acceptContactRequestSaga),
  takeEvery(IGNORE_CONTACT_REQUEST, ignoreContactRequestSaga)
];
