import React, { Component } from 'react';

import '../Profile.scss';

class ProfileAddContainer extends Component {
  render() {
    return (
      <div
        className="Profile__container--add"
        onClick={this.props.onClick}
      >
        {this.props.text}
      </div>
    )
  }
}

export default ProfileAddContainer;
