import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export const NOTIFICATION_THEME_TAN = '#000000';

class NotificationIcon extends PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf([NOTIFICATION_THEME_TAN])
  };

  static defaultProps = {
    theme: NOTIFICATION_THEME_TAN
  };

  render() {
    return (
      <svg width="15" height="15" viewBox="0 0 22 22" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.25 14.2085C19.25 15.4727 20.2785 16.5002 21.5417 16.5002C21.7947 16.5002 22 16.7056 22 16.9585C22 17.2114 21.7947 17.4167 21.5417 17.4167H0.458313C0.205322 17.4167 0 17.2114 0 16.9585C0 16.7056 0.205322 16.5002 0.458313 16.5002C1.7215 16.5002 2.75 15.4727 2.75 14.2085V10.0835C2.75 6.74487 4.80243 3.71167 7.88336 2.45215C8.22803 1.03687 9.52234 0 11 0C12.5006 0 13.7701 1.01562 14.1139 2.45215C17.1967 3.71167 19.25 6.74585 19.25 10.0835V14.2085ZM8.25714 18.3342H13.7571C14.0101 18.3342 14.2155 18.5396 14.2155 18.7925C14.2155 20.5618 12.7763 22.001 11.0071 22.001C9.23798 22.001 7.79883 20.5618 7.79883 18.7925C7.79883 18.5396 8.00415 18.3342 8.25714 18.3342Z" fill={this.props.theme} />
      </svg>
    )
  }
}

export default NotificationIcon;
