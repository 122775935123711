import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import SVG from 'react-inlinesvg';

import { contactsGet, sendContactRequest } from '../../store/Contact/actions';
import { directorySearch } from '../../store/Directory/actions';
import { admireAdd, admireRemove } from '../../store/Admires/actions';
import { modalOpen } from '../../store/Modal/actions';

import InfiniteScroller from '../../components/InfiniteScroller';
import DirectoryResult from './DirectoryResult';
import UserListItem from '../../components/UserList/UserListItem';
import Loader from '../../common/Loader';
import Button, { THEME_BLACK } from '../../common/Button';
import InputWithIcon from '../../common/Input/InputWithIcon';
import { INPUT_SECONDARY_PLAIN } from '../../common/Input';
import { CONTACT_REQUEST_MODAL } from '../ModalManager/ModalTypes';
import { INDUSTRIES } from '../../common/Dropdown/CommonFields';
import searchIcon from '../../../public/assets/icons/SEARCH.svg';
import locationIcon from '../../../public/assets/icons/LOCATION.svg';

import './Directory.scss';
import InputLocation from '../../common/Input/InputLocation';

class Directory extends PureComponent {
  static propTypes = {

  };

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
      industryTerm: '',
      locationTerm: '',
      placeTerm: '',
      latTerm: '',
      lngTerm: '',
      viewportWidth: null,
      limit: 12
    }

    this.props.contactsGet();
  }

  componentDidMount() {
    this.searchParamsDidChange();

    this.state.viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    window.addEventListener("resize", this.updateViewportSize);
  }

  updateViewportSize = () => {
    this.setState({
      viewportWidth: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    });
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateViewportSize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.searchParamsDidChange();
    }
  }

  searchParamsDidChange() {
    let params = qs.parse(this.props.location.search);

    let searchTerm = (params.search || '').trim();
    let locationTerm = params.location || '';
    let placeTerm = params.place || '';
    let latTerm = params.lat || '';
    let lngTerm = params.lng || '';
    let industryTerm = params.industry || '';

    this.setState({ searchTerm, locationTerm, placeTerm, latTerm, lngTerm, industryTerm }, () => {
      this.search();
    });
  }

  handleSearchChange = value => {
    this.setState({ searchTerm: value });
  }

  handleIndustryChange = (e) => {
    this.setState({ industryTerm: e.target.value }, () => {
      this.updateURL();
      this.search();
    });
  }

  handleLocationChange = (location) => {
    const { formatted_address, place_id, lat, lng } = location || {};
    this.setState({ locationTerm:formatted_address, placeTerm:place_id, latTerm:lat, lngTerm:lng }, () => {
      this.updateURL();
    });
  }

  handleSearchSubmission = (e) => {
    if (e) e.preventDefault();

    this.updateURL();
    this.search();
  }

  updateURL = () => {
    const { searchTerm, locationTerm, placeTerm, latTerm, lngTerm, industryTerm } = this.state;
    const query = {};
    let url = '/directory';

    if (searchTerm) query.search = searchTerm;
    if (locationTerm) query.location = locationTerm;
    if (placeTerm) query.place = placeTerm;
    if (latTerm) query.lat = latTerm;
    if (lngTerm) query.lng = lngTerm;
    if (industryTerm) query.industry = industryTerm;

    const queryString = qs.stringify(query);
    if (queryString) {
      url += `?${queryString}`;
    }
    this.props.history.push(url);
  }

  search = loadMore => {
    const { searchTerm, industryTerm, locationTerm, latTerm, lngTerm, limit } = this.state;

    this.props.directorySearch({
      q: searchTerm,
      industry: industryTerm,
      location: locationTerm,
      lat: latTerm,
      lng: lngTerm,
      offset: loadMore ? this.props.offset : 0,
      limit
    }, loadMore);
  }


  requestSent = id => {
    if (this.props.contactRequestsIds.indexOf(parseInt(id)) !== -1) {
      return true;
    }
    return false;
  };

  contactURL = id => {
    const { contacts } = this.props;
    let contactURL = false;

    contacts.map(contact => {
      if (contact.contact_id == id) {
        // contactURL = contact.sendbird_group_id;
        contactURL = 'x'
      }
    })
    return contactURL;
  };

  onSearchDirectory = () => {
    this.handleSearchSubmission();
  }

  sendContactRequest = user => {
    const { sendContactRequest, modalOpen } = this.props;

    modalOpen({
      modal: CONTACT_REQUEST_MODAL,
      user,
      send: sendContactRequest
    });
  }

  render() {
    const {
      admireAdd,
      admireRemove,
      admiresMap,
      hasLoadedAllUsers,
      loading,
      results,
    } = this.props;

    const { searchTerm, locationTerm, placeTerm, industryTerm, viewportWidth } = this.state;

    const location = locationTerm && placeTerm ? {label:locationTerm, value:placeTerm} : null;

    return (
      <div className="Directory">
        <form className="container" onSubmit={this.handleSearchSubmission}>
          <div className="Directory__search-bar">
            <div className="Directory__search-bar--input">
              <InputWithIcon
                addBefore={<SVG src={searchIcon} />}
                autoCorrect={'off'}
                onChange={this.handleSearchChange}
                placeholder="Search members"
                theme={INPUT_SECONDARY_PLAIN}
                value={searchTerm}
              />
            </div>
            <div className="Directory__search-bar--input">
              <select className="filter-industry" value={industryTerm} onChange={this.handleIndustryChange}>
                <option value="">Any Industry</option>
                {
                  INDUSTRIES.map(industry => (
                    <option
                      value={industry}
                      key={industry}
                    >
                      {industry}
                    </option>
                  ))
                }
              </select>
            </div>
            <div className="Directory__search-bar--input">
              <InputLocation
                defaultValue={location}
                placeholder={"Location"}
                searchKey="directory"
                onChange={this.handleLocationChange}
                styles={{
                  control: { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
                }}
              />
            </div>

            <Button noOutline className="Directory__search-bar--cta" theme={THEME_BLACK} onClick={this.onSearchDirectory}>Search</Button>
          </div>
        </form>
        <div className="directory-container">
          <InfiniteScroller
            shouldLoadMore={!loading && !hasLoadedAllUsers}
            hasLoadedAll={hasLoadedAllUsers}
            loadMore={() => this.search('load-more')}
            className="directory-profile-list-container"
          >
            {
              viewportWidth < 600 ?
                results.map(result => (
                  <UserListItem
                    key={result.id}
                    isCurrentUser={result.id == this.props.user.id}
                    user={result}
                    sendContactRequest={this.sendContactRequest}
                    contactURL={this.contactURL(result.id)}
                    contactRequestSent={this.requestSent(result.id)}
                    currentUser={this.props.user}
                    admireAdd={admireAdd}
                    admiresMap={admiresMap}
                  />
                )) :
                results.map(result => (
                  <DirectoryResult
                    key={result.id}
                    member={result}
                    contactURL={this.contactURL(result.id)}
                    contactRequestSent={this.requestSent(result.id)}
                    sendContactRequest={this.sendContactRequest}
                    currentUserId={this.props.user.id}
                    admireAdd={admireAdd}
                    admireRemove={admireRemove}
                    admiresMap={admiresMap}
                  />
                ))
            }
          </InfiniteScroller>
          {
            loading ?
              <div style={{ position: 'absolute', left: '50%' }}>
                <Loader className="bg__blue" />
              </div> : null
          }

          {
            (!loading && results.length == 0) && (
              <h2 style={{ textAlign: 'center' }}>No users found.</h2>
            )
          }
        </div>
      </div>
    )
  }
};

const mapStateToProps = ({ User, Contact, Directory, Admires }) => {
  return {
    user: User.user,
    contactRequestsIds: User.user.contact_requests.map(contactRequest => contactRequest.contact_id),
    contacts: Contact.contacts,
    loading: Directory.isLoading,
    hasLoadedAllUsers: Directory.hasLoadedAllUsers,
    offset: Directory.offset,
    results: Directory.users,
    totalResults: Directory.total,
    admiresMap: Admires.admiresMap
  }
};

export default connect(mapStateToProps, {
  contactsGet,
  directorySearch,
  admireAdd,
  admireRemove,
  sendContactRequest,
  modalOpen
})(withRouter(Directory));
