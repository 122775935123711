import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  GTMpushDataLayerEvent,
  GTM_ONBOARDING_COMPLETE
} from '../../lib/GoogleTagManager';

import Confetti from '../../components/Confetti';
import {
  onboardingProfileComplete
} from '../../store/Onboarding/actions';

import './Onboarding.scss';

class OnboardingComplete extends PureComponent {
  static propTypes = {

  };

  componentDidMount = () => {
    GTMpushDataLayerEvent({ event: GTM_ONBOARDING_COMPLETE });
    this.props.onboardingProfileComplete();
  }
  
  render() {

    return (
      <div>
        <Confetti />
        <h2 className="Onboarding__heading">Welcome new friend!</h2>
        <h2 className="Onboarding__subheading-grey">A few rules for the road to success</h2>
        <div className="Onboarding__completed-container">
          <div className="Onboarding__completed-row">
            <div className="Onboarding__completed-icon"><span>1</span></div>
            <h4 className="Onboarding__completed-label">Be kind.</h4>
          </div>
          <div className="Onboarding__completed-row">
            <div className="Onboarding__completed-icon"><span>2</span></div>
            <h4 className="Onboarding__completed-label">Be helpful.</h4>
          </div>
          <div className="Onboarding__completed-row">
            <div className="Onboarding__completed-icon"><span>3</span></div>
            <h4 className="Onboarding__completed-label">Be respectful.</h4>
          </div>
          <div className="Onboarding__completed-row">
            <div className="Onboarding__completed-icon"><span>4</span></div>
            <h4 className="Onboarding__completed-label">Brag! But just a little.</h4>
          </div>
          <p>To read our full community guidelines, <a href="/community-guidelines" target="_blank">click here</a></p>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onboardingProfileComplete: () => dispatch(onboardingProfileComplete())
  }
};

export default connect(null, mapDispatchToProps)(OnboardingComplete);