import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonAdd from '../../../components/ButtonAdd';
import EditIcon from '../EditIcon';

import './ProfileSection.scss';

class ProfileSection extends Component {
  static defaultProps = {
    isEditing: false
  };

  static propTypes = {
    label: PropTypes.string,
    about: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    const { label, about, children, className } = this.props;

    return (
      <div className={classNames(
        "ProfileSection",
        className
      )}>
        { label && (
          <div className="ProfileSection__container-header">
            {label}
          </div>
          )
        }
        { about && (
          <div className="ProfileSection__container-about">
            {about}
          </div>
          )
        }
        {children}
      </div>
    )
  }
}

export default ProfileSection;
