/**
  Open + close toaster
**/
export const TOASTER_OPEN = 'TOASTER_OPEN';
export const TOASTER_CLOSE = 'TOASTER_CLOSE';

export const TOASTER_TYPE_ERROR = 'TOASTER_TYPE_ERROR';
export const TOASTER_TYPE_CONFIRM = 'TOASTER_TYPE_CONFIRM';
export const TOASTER_TYPE_SUCCESS = 'TOASTER_TYPE_SUCCESS';


// { type: 'error' }
export const toasterOpen = payload => {
  return {
    type: TOASTER_OPEN,
    payload
  }
};

export const toasterClose = () => {
  return {
    type: TOASTER_CLOSE
  }
};
