import {
  APP_INITIALIZE,
  APP_INITIALIZE_SUCCESS,
  APP_VALIDATE_TOKEN_SUCCESS,
  APP_VALIDATE_TOKEN_FAILURE,
  SUBMIT_EMAIL,
  SUBMIT_EMAIL_SUCCESS,
  SUBMIT_EMAIL_ERROR,
  CM_STATS_SUCCESS,
  IS_NEW_USER_FALSE,
  CREATE_OS_REFERENCE,
  ON_ROUTE_CHANGE
} from './actions';
import {
  AUTH_SIGNUP_SUCCESS,
  AUTH_LOGOUT,
  AUTH_SUBSCRIPTION_NOT_FOUND,
  AUTH_SUBSCRIPTION_FOUND
} from '../Auth/actions';
import {
  PROFILE_GET,
  PROFILE_GET_COMPLETE
} from '../Profile/actions';
import {
  GET_POST,
  GET_POST_SUCCESS,
  GET_POST_ERROR
} from '../Post/actions';

const defaultState = {
  isLoggedIn: false,
  isFullPageLoading: false,
  isUserSubscribed: false,
  totalSubscriberCount: 0,
  pathname: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_SUBSCRIPTION_NOT_FOUND:
      return { ...state, isUserSubscribed: false };
    case AUTH_SUBSCRIPTION_FOUND:
      return { ...state, isUserSubscribed: true };
    case SUBMIT_EMAIL:
      return { ...state, isSubmittingEmail: true };
    case SUBMIT_EMAIL_SUCCESS:
      return {
        ...state,
        isSubmittingEmail: false,
        emailWaitlistError: null,
        emailPayload: action.payload.email,
        totalSubscriberCount: state.totalSubscriberCount + 1
      };
    case SUBMIT_EMAIL_ERROR:
      return { ...state, isSubmittingEmail: false, emailWaitlistError: action.payload.error, emailPayload: action.payload.email };
    case GET_POST:
      return { ...state, isFullPageLoading: true };
    case PROFILE_GET:
      return { ...state, isMainLoading: true };
    case PROFILE_GET_COMPLETE:
      return { ...state, isMainLoading: false };
    case GET_POST_ERROR:
    case GET_POST_SUCCESS:
      return { ...state, isFullPageLoading: false };
    case APP_INITIALIZE_SUCCESS:
      return { ...state, isFullPageLoading: false, isAppInitializing: false };
    case APP_INITIALIZE:
      return { ...state, isFullPageLoading: true, isAppInitializing: true };
    case APP_VALIDATE_TOKEN_SUCCESS:
      return { ...state, isLoggedIn: true };
    case APP_VALIDATE_TOKEN_FAILURE:
      return { ...state, isLoggedIn: false };
    case CM_STATS_SUCCESS:
      return { ...state, totalSubscriberCount: action.payload };
    case CREATE_OS_REFERENCE:
      return {
        ...state,
        os: action.payload
      };
    case ON_ROUTE_CHANGE: 
      return {
        ...state,
        pathname: action.payload.location.pathname
      }
    case AUTH_LOGOUT:
      return { ...state, isFullPageLoading: true };
    default:
      return state;
  }
};
