import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputLocation from '../../common/Input/InputLocation';

import './Onboarding.scss';

class OnboardingLocation extends PureComponent {
  static propTypes = {
    location:PropTypes.object,
    handleLocationChange:PropTypes.func.isRequired
  };
  
  render() {

    const {
      location,
      handleLocationChange,
      errors
    } = this.props;

    return (
      <div className="Onboarding__page-container-location">
        <h2 className="Onboarding__heading">Where are you located?</h2>
          <InputLocation
            placeholder="Location"
            onChange={handleLocationChange}
            defaultValue={location}
          />
      </div>
    )
  }
}

export default OnboardingLocation;
