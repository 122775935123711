import { defineRequest } from '../../lib/reduxRequests';

/**
  Activity
**/

export const CLEAR_ACTIVITY = 'CLEAR_ACTIVITY';

export const activityClear = () => {
  return {
    type: CLEAR_ACTIVITY
  }
}

export const {
  ACTIVITY_GET,
  ACTIVITY_GET_FULFILLED,
  activityGet
} = defineRequest('ACTIVITY_GET', (params, key) => ({ key, payload: params }));

export const activityGetOffset = state => state.Connections.offset;