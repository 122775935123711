import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './ContactRequestModal.scss';

const IM_A_FAN = 'im_a_fan';
const LETS_COLLABORATE = 'lets_collaborate';
const LETS_MEET_UP = 'lets_meet_up';
const I_CAN_HELP_YOU = 'i_can_help_you';
const ID_LOVE_YOUR_HELP = 'id_love_your_help';

const INTENT_LABELS = {
  [IM_A_FAN]:"I'm a fan!",
  [LETS_COLLABORATE]:"Let's collaborate!",
  [LETS_MEET_UP]:"Let's meet up!",
  [I_CAN_HELP_YOU]:"I can help you!",
  [ID_LOVE_YOUR_HELP]:"I'd love your help!"
};

const INTENT_PLACEHOLDERS = {
  [IM_A_FAN]:"Example: I love what you’re doing and would love to chat.",
  [LETS_COLLABORATE]:"Example: We’re neighbors! I’d love to meet up sometime to chat life, work, and more.",
  [LETS_MEET_UP]:"Example: I think we have a lot in common. Would you be open to collaborating?",
  [I_CAN_HELP_YOU]:"Example: I saw that you’re looking for help, and I’m your girl. Let’s connect!",
  [ID_LOVE_YOUR_HELP]:"Example: I admire what you’re doing and would love to pick your brain!"
};

class ContactRequestModalContent extends Component {
  static propTypes = {
    user:PropTypes.object,
    message:PropTypes.string,
    handleMessageChange:PropTypes.func,
    handleMessageSubmission:PropTypes.func,
    intent:PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    this.props.handleMessageChange(e.target.value);
  }

  submit = () => {
    this.props.handleMessageSubmission();
  }

  render() {
    const { user, message, intent } = this.props;

    return (
      <div className="ContactRequestModal__inner">
        <h4 className='ContactRequestModal__heading'>Get specific</h4>
        <h6 className="ContactRequestModal__subheading">"{ INTENT_LABELS[intent] }"</h6>
        <textarea className="ContactRequestModal__message-input"
          value={message}
          onChange={this.handleChange}
          placeholder={INTENT_PLACEHOLDERS[intent]}
        />
        <p style={{ marginTop: '20px' }}>
          If {user.first_name} accepts your request, your emails will be visible to each other on your profiles.
        </p>
        <button className='ContactRequestModal__submit-button' onClick={this.submit}>Send</button>
      </div>
    )
  }
}

export default ContactRequestModalContent;
