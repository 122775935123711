import { takeEvery, select } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';

import { CONNECTIONS_GET } from './actions';
import { userGetId } from '../User/actions';

function* connectionSagaGet(action) {
  const currentUserId = yield select(userGetId);
  const { userId = currentUserId, name = '', offset, limit = 10 } = action.payload;

  yield sagaRequest(action, callAPI('getUsers', {
    connectedUserId: userId,
    inNetwork: 1,
    name,
    offset,
    limit
  }));
};

export const connectionSagas = [
  takeEvery(CONNECTIONS_GET, connectionSagaGet)
];
