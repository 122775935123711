import { takeEvery, put, call } from 'redux-saga/effects';
import { callAPI } from '../../sagas/effects';
import { sagaRequest } from '../../store/requests';
import {
   GET_POST,
   getPostSuccess,
   getPostError,
   GET_POST_COMMENTS,
   getPostCommentsSuccess,
   getPostCommentsError,
   POST_CHECK,
   POST,
   postSuccess,
   postError,
   POST_EDIT,
   postEditSuccess,
   postEditError,
   POST_DELETE,
   postDeleteSuccess,
   postDeleteError,
   POST_REACT,
   postReactSuccess,
   postReactError,
   POST_REACT_DELETE,
   postReactDeleteSuccess,
   postReactDeleteError,
   GET_COMMENT_REPLIES,
   getCommentRepliesSuccess,
   getCommentRepliesError
} from './actions';
import { setLastUpdateTime } from '../Feed/actions';
import fetchAPI from '../../services/APIService';
import {
  GTMpushDataLayerEvent,
  GTM_POST_CREATE,
  GTM_POST_DELETE,
  GTM_POST_EDIT
} from '../../lib/GoogleTagManager';

function* postSagaCheck(action) {
  yield sagaRequest(action, callAPI('checkPost', { body: action.payload }));
}

function* postSaga(action) {
  const { body, updatePosts } = action.payload;

  const response = yield call(fetchAPI, 'postPost', {
    body
  });

  if (response.success && updatePosts) {
    const post = response.post;
    post.creator = response.user;
    yield put(postSuccess(post));
    yield put(setLastUpdateTime());
  } else {
    yield put(postError(response));
  }

  GTMpushDataLayerEvent({
    event: GTM_POST_CREATE,
    titleLength: body.title.length,
    bodyLength: body.body.length
  });
};

function* postSagaGet(action) {
  const response = yield call(fetchAPI, 'getPost', {
    id: action.payload
  });

  if (response.success) {
    yield put(getPostSuccess(response.post));
  } else {
    yield put(getPostError(response));
  }
};

function* postSagaGetComments(action) {
  const response = yield call(fetchAPI, 'getPostComments', {
    id: action.payload.id,
    limit: action.payload.limit,
    offset: action.payload.offset
  });

  if (response.success) {
    yield put(getPostCommentsSuccess(response.comments));
  } else {
    yield put(getPostCommentsError(response));
  }
};

function* postSagaGetCommentReplies(action) {
  const response = yield call(fetchAPI, 'getCommentReplies', {
    id: action.payload,
    limit: action.limit,
    offset: action.offset
  });

  if (response.success) {
    yield put(getCommentRepliesSuccess(response.post));
  } else {
    yield put(getCommentRepliesError(response));
  }
};

function* postSagaEdit(action) {
  const response = yield call(fetchAPI, 'editPost', {
    body: action.payload,
    id: action.id
  });

  if (response.success) {
    yield put(postEditSuccess(response.post.id, response.post));
  } else {
    yield put(postEditError(response));
  }

  GTMpushDataLayerEvent({ event: GTM_POST_EDIT });
};

function* postSagaDelete(action) {
  const response = yield call(fetchAPI, 'deletePost', {
    id: action.postId
  });

  yield put(postDeleteSuccess(action.postId));
  yield put(setLastUpdateTime());

  GTMpushDataLayerEvent({ event: GTM_POST_DELETE });
};

export const postSagas = [
  takeEvery(POST_CHECK, postSagaCheck),
  takeEvery(POST, postSaga),
  takeEvery(GET_POST, postSagaGet),
  takeEvery(GET_POST_COMMENTS, postSagaGetComments),
  takeEvery(GET_COMMENT_REPLIES, postSagaGetCommentReplies),
  takeEvery(POST_EDIT, postSagaEdit),
  takeEvery(POST_DELETE, postSagaDelete)
];
