import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Input from './index.js';

class InputWithLabel extends Component {
  static defaultProps = {
    required: false
  };

  static propTypes = {
    label: PropTypes.string,
    description: PropTypes.string
  };

  render() {
    const { label, description, required } = this.props;

    return (
      <React.Fragment>
        { label && <label className={classnames("Input__label", {'Input__label--required': required})}>{label}</label> }
        { description && <p className="Input__description">{description}</p> }
        <Input
            className={'InputWithLabel__input'}
            {...this.props}
        />
      </React.Fragment>
    )
  }
}

export default InputWithLabel;
