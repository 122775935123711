import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ProfilePersonalityCard.scss';

import AddButton from '../../../../public/assets/add-button-blue.png';

class ProfilePersonalityCard extends Component {
  static propTypes = {
    className:PropTypes.string,
    label:PropTypes.string,
    description:PropTypes.string,
    onClick:PropTypes.func
  }

  create = () => {
    this.props.create();
  }

  constructor(props) {
    super(props);
  }

  render() {

    const { label, description, className } = this.props;

    return (
      <div data-aos="fade-up"
        className={
          classnames(
            "ProfilePersonalityCard",
            className
          )
        }
        onClick={this.create}
      >
        <img src={AddButton} />
        <h2>{label}</h2>
        <p>{description}</p>
      </div>
    )
  }
}

export default ProfilePersonalityCard;