import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './CommentFooter.scss';

class CommentFooter extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  };

  render() {
    return (
      <div
        className={classNames(
          'CommentFooter',
          this.props.className
        )}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    )
  }
}

export default CommentFooter;
