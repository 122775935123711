import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Button, { THEME_YELLOW } from '../../../common/Button';
import { ModalContainer, ModalBody, ModalFooter } from '../ModalContainer';
import { modalClose } from '../../../store/Modal/actions';
import productImage from '../../../../public/assets/Product-Collage-3.png';

import './SignupPromptModal.scss';
import LinkWithParams from '../../../common/LinkWithParams';

class SignupPromptModal extends Component {

  closeModal = () => {
    this.props.modalClose();
  }

  render() {
    const { setRef, close } = this.props;

    return (
      <ModalContainer
        className="SignupPromptModal"
        setRef={setRef}
        close={close}
      >
        {this.props.children}

        <ModalBody setRef={this.props.setRef}>
          <img src={[productImage]} />

          <h1>Looking for more?</h1>

          <h2>Join Girlboss and start connecting.</h2>

        </ModalBody>

        <ModalFooter>
          <LinkWithParams className="SignupPromptModal__signup-link" to="/signup" onClick={this.closeModal}>
            <Button className="SignupPromptModal__cta" theme={THEME_YELLOW} pill noOutline>Sign up</Button>
          </LinkWithParams>

          <p>Have an Account? <LinkWithParams to={'/login'} onClick={this.closeModal}>Log in</LinkWithParams> </p>
        </ModalFooter>
      </ModalContainer>
    )
  }
}

export default connect(null, { modalClose })(SignupPromptModal);