import { defineRequest } from '../../lib/reduxRequests';

/**
  Search
**/

export const CLEAR_LOCATION = 'CLEAR_LOCATIONS';

export const locationClear = (key) => {
  return {
    type: CLEAR_LOCATION,
    key
  }
}

export const LOCATION_SESSION_TOKEN_SET = 'LOCATION_SESSION_TOKEN_SET';

export const locationSessionTokenSet = (payload) => {
  return {
    type: LOCATION_SESSION_TOKEN_SET,
    payload
  }
}

export const {
  LOCATION_SELECTED_GET,
  LOCATION_SELECTED_GET_FULFILLED,
  LOCATION_SELECTED_GET_REJECTED,
  locationSelectedGet
} = defineRequest('LOCATION_SELECTED_GET', (params, key) => ({ key, payload: params }));

export const {
  LOCATION_GET,
  LOCATION_GET_FULFILLED,
  LOCATION_GET_REJECTED,
  locationGet
} = defineRequest('LOCATION_GET', (params, key) => ({ key, payload: params }));

export const locationGetSession = state => state.Location.session;

export const locationGetFulfilled = (initialPayload, payload) => {
  return {
    type: LOCATION_GET_FULFILLED,
    payload,
    initialPayload
  }
}