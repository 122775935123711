import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Wizard from '../../../../components/Wizard';
import { ModalContainer } from '../../ModalContainer';
import CollectiveCreationIntro from './CollectiveCreationIntro';
import CollectiveCreationInfoForm from './CollectiveCreationInfoForm';
import CollectiveCreationPostForm from './CollectiveCreationPostForm';
import CollectiveCreationPreview from './CollectiveCreationPreview';
import CollectiveInvitationIntro from './CollectiveInvitationIntro';

import './CollectiveCreationModal.scss';

const STEPS = [
  { name: 'intro', component: CollectiveCreationIntro },
  { name: 'create', component: CollectiveCreationInfoForm },
  { name: 'post', title: "Add Post", component: CollectiveCreationPostForm },
  { name: 'preview', title: "Preview", component: CollectiveCreationPreview },
  { name: 'invitation-intro', component: CollectiveInvitationIntro }
];

const EDIT_STEPS = [
  { name: 'create', component: CollectiveCreationInfoForm }
]

class CollectiveCreationModal extends Component {
  state = {
    currentStepName: null
  }

  getCurrentStepName = name => {
    this.setState({
      currentStepName: name
    })
  }

  render() {
    const {
      close,
      setRef,
      children,
      collectives,
      collectiveCreation,
      startingStep,
      editMode
    } = this.props;
  
    let createdAndUnpublished = false;
    if (collectives[collectiveCreation.slug] && !collectives[collectiveCreation.slug].published_at) createdAndUnpublished = true;

    return (
      <ModalContainer
        className={classNames("CollectiveCreationModal", {
          intro: this.state.currentStepName === 'intro', 
          invites: this.state.currentStepName === 'invitation-intro',
          'disable-close': createdAndUnpublished
        })}
        close={close}
        setRef={setRef}
      >
        { children }
        <Wizard
          steps={editMode ? EDIT_STEPS : STEPS} 
          onComplete={() => console.log('complete!')} 
          initialStepName={startingStep ? startingStep : null}
          stepProps={editMode ? {editMode} : null}
          getCurrentStepName={this.getCurrentStepName}
        />
      </ModalContainer>
    )
  }
}

const mapStateToProps = ({ Collectives }) => {
  return {
    collectives: Collectives.collectives,
    collectiveCreation: Collectives.collectiveCreation
  }
}

export default connect(mapStateToProps)(CollectiveCreationModal);