import classNames from 'classnames';
import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import Button, { THEME_GRAY, THEME_BLUE, THEME_BLACK } from '../../common/Button';
import './Wizard.scss';

/**
 * Example Usage:
 * 
 * const Step = ({ wizard }) => (
 *   <div>
 *     <h1>{wizard.currentStep.title}</h1>
 *     <WizardActions wizard={wizard} />
 *   </div>
 * )
 * 
 * const STEPS = [
 *  { name: 'intro', component: Step },
 *  { name: 'create', title: "Create", component: Step }
 * ];
 * 
 * <Wizard
 *   steps={STEPS} 
 *   // Common props available for each step
 *   stepProps={{ save: this.save }}
 *   // Called after navigation
 *   onNavigate={stepName => console.log('navigate', stepName)}
 *   // Called when final step was completed
 *   onComplete={() => console.log('complete!')} />
 *   // Can retrieve the current step name
 *   getCurrentStepName={(stepName) => console.log(stepName)} />
 */
export default class Wizard extends Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    initialStepName: PropTypes.string,
    stepProps: PropTypes.object,
    onNavigate: PropTypes.func,
    onComplete: PropTypes.func
  }

  static defaultProps = {
    stepProps: {}
  }

  state = {
    stepStates: []
  };

  componentWillMount() {
    const { steps, initialStepName } = this.props;
    this.setCurrentStepName(initialStepName || steps[0].name);
  }

  componentDidUpdate(prevProps) {
    const { steps } = this.props;
    
    if (steps != prevProps.steps) {
      let { currentStepName, stepStates } = this.state;
      const nextStepName = steps.find(step => step.name === currentStepName)
        ? currentStepName
        : steps[0].name;

      stepStates = stepStates.filter(s => steps.find(step => step === s));
      this.setCurrentStepName(nextStepName, stepStates);
    }
  }

  setCurrentStepName(stepName, stepStates = [...this.state.stepStates]) {
    const { steps } = this.props;
    const stepIndex = steps.findIndex(step => step.name === stepName);
    const currentStep = steps[stepIndex];
    
    if (!currentStep) {
      throw new Error(`Step '${stepName}' does not exist.`);
    }

    const stepState = this.createStepState(currentStep, stepIndex);
    const index = stepStates.findIndex(s => s.currentStep.name === stepName);

    if (index < 0) {
      stepStates.push(stepState);
    } else {
      stepStates[index] = stepState;
    }

    this.setState({ currentStepName: stepName, stepStates }, () => {
      const { getCurrentStepName } = this.props;
      if (getCurrentStepName) getCurrentStepName(this.state.currentStepName);
    });
  }

  createStepState(currentStep, currentStepIndex) {
    const { steps } = this.props;
    const prevStep = steps[currentStepIndex - 1];
    const nextStep = steps[currentStepIndex + 1];

    return {
      currentStep,
      prevStep,
      nextStep,
      back: prevStep && (() => this.navigate(prevStep.name)),
      next: nextStep ? (() => this.navigate(nextStep.name)) : this.complete,
      navigate: this.navigate
    };
  }

  navigate = stepName => {
    const { onNavigate } = this.props;
    this.setCurrentStepName(stepName);
    onNavigate && onNavigate(stepName);
  }

  complete = () => {
    const { onComplete } = this.props;
    onComplete && onComplete();
  }

  render() {
    const { stepProps } = this.props;
    const { className = "Wizard", currentStepName, stepStates } = this.state;

    return (
      <div className={className}>
        {stepStates.map(stepState => (
          <div
            key={stepState.currentStep.name}
            className={classNames('WizardStep', { __active: stepState.currentStep.name === currentStepName })}>
            {createElement(stepState.currentStep.component, { wizard: stepState, stepProps })}
          </div>
        ))}
      </div>
    )
  }
}

export function WizardActions({
  className = "WizardActions",
  wizard = {},
  backLabel,
  backTheme = THEME_GRAY,
  nextLabel,
  nextTheme = wizard.nextStep ? THEME_BLACK : THEME_BLUE,
  nextDisabled,
  nextPending,
  disabled,
  onBack,
  onNext
}) {
  if (!nextLabel) {
    const { nextStep } = wizard;
    nextLabel = "Next" + (nextStep && nextStep.title ? `: ${nextStep.title}` : '');
  }

  return (
    <div className={className}>
      {wizard.prevStep && (
        <Button
          className="WizardActions__cta"
          theme={backTheme}
          disabled={disabled || nextPending}
          onClick={onBack || wizard.back}
          noOutline>
          {backLabel || "Back"}
        </Button>
      )}
      {!wizard.prevStep && (<div></div>)}
      <Button
        className="WizardActions__cta"
        theme={nextTheme}
        disabled={disabled || nextDisabled}
        onClick={onNext || wizard.next}
        noOutline
        isLoading={nextPending}>
        {nextLabel}
      </Button>
    </div>
  )
}
