import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { appValidateTokenFailure } from '../../store/App/actions';

import LoadingScreen from '../../components/LoadingScreen';

import {
  appInitialize,
  appGetIsFullPageLoading,
  appGetIsInitializing,
  appGetIsUserLoggedIn,
  appGetIsUserSubscribed
} from '../../store/App/actions';

import { routes } from '../../config/routes';

class PrivateRoute extends Component {
  static defaultProps = {
    initializeDataCall: () => { }
  };

  static propTypes = {
    isFullPageLoading: PropTypes.bool,
    initializeDataCall: PropTypes.func
  };

  componentWillMount() {
    this.props.initializeDataCall();
  }

  redirectToLogin = () => {
    this.props.appValidateTokenFailure();
    const ref = location.pathname + location.hash + location.search;
    return <Redirect to={`${routes.logIn}?${qs.stringify({ ref })}`} />;
  }

  render() {
    const {
      currentUser,
      redirect,
      render,
      path,
      isFullPageLoading,
      isLoggedIn,
      publicRoute,
      requiresUser,
      location,
      userLoading
    } = this.props;

    let RenderComponent = render;
    let RedirectComponent = redirect;

    // Redirect to the login page if logged out and not on the root path.
    if (
      !isFullPageLoading && !publicRoute && !isLoggedIn && path !== routes.logIn &&
      (path !== '/' || location.hash || location.search.startsWith('?contact='))
    ) {
      return this.redirectToLogin();
    }

    if ((isFullPageLoading || isFullPageLoading === undefined) && currentUser.id) {
      return (
        <Route render={() => (
          <RenderComponent
            {...this.props}
            isLoading
          />
        )} />
      );
    }

    if (isFullPageLoading || isFullPageLoading === undefined || (requiresUser && !currentUser.id)) {
      return (
        <LoadingScreen
          isLoggedIn={this.props.isLoggedIn}
          isAppInitializing={this.props.isAppInitializing}
          requiresUser={requiresUser ? requiresUser : false}
        />
      );
    }

    // Redirect to the login page if an invalid user token is present (resulting in the 
    // user object failing to load).
    if (isLoggedIn && currentUser && !currentUser.id && !userLoading) return this.redirectToLogin();

    if (publicRoute && isLoggedIn) {
      return (
        <Route render={() => (
          <RenderComponent {...this.props} />
        )} />
      );
    };

    if (publicRoute && !isLoggedIn) {
      return (
        <Route render={() => (
          <RedirectComponent {...this.props} />
        )} />
      );
    }

    if (isLoggedIn && !isFullPageLoading) {
      // Show the proper view if the user is logged in
      return (
        <Route render={() => (
          <RenderComponent {...this.props} />
        )} />
      );
    };

    if (!isFullPageLoading && RedirectComponent) {
      return (
        <Route render={() => (
          <RedirectComponent {...this.props} />
        )} />
      );
    };

    return <Redirect to="/" />;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.User.user,
    userLoading: state.User.isLoading,
    isLoggedIn: appGetIsUserLoggedIn(state),
    isUserSubscribed: appGetIsUserSubscribed(state),
    isAppInitializing: appGetIsInitializing(state),
    isFullPageLoading: appGetIsFullPageLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appValidateTokenFailure: () => dispatch(appValidateTokenFailure())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
