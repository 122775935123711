import {
  AUTH_SIGNUP,
  AUTH_SIGNUP_SAVE,
  AUTH_SIGNUP_SAVE_CLEAR,
  AUTH_SIGNUP_BACK,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_ERROR,
  AUTH_INFO_CHECK,
  AUTH_INFO_CHECK_ERROR,
  AUTH_INFO_CHECK_SUCCESS,
  AUTH_USERNAME_CHECK,
  AUTH_USERNAME_CHECK_ERROR,
  AUTH_USERNAME_CHECK_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
  AUTH_ERRORS_CLEAR
} from './actions';

const initialState = {
  step: 1,
  logInErrorCode: null,
  signUpErrors: null,
  isLoading: false,
  fields: {}
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_SIGNUP_BACK: {
      return {
        ...state,
        step: state.step - 1
      };
    }
    case AUTH_SIGNUP_SAVE: {
      const fieldsObj = state.fields || {};
      const mergedFields = Object.assign({}, fieldsObj, action.payload);

      return {
        ...state,
        fields: mergedFields
      };
    }
    case AUTH_SIGNUP_SAVE_CLEAR: {
      return {
        ...state,
        fields: {}
      };
    }
    case AUTH_SIGNUP_ERROR:
    case AUTH_USERNAME_CHECK_ERROR:
    case AUTH_INFO_CHECK_ERROR: {
      return {
        ...state,
        signUpErrors: action.payload.errors,
        isLoading: false
      };
    }
    case AUTH_LOGIN_ERROR: {
      return {
        ...state,
        logInErrorCode: action.payload.code,
        isLoading: false
      };
    }
    case AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        logInErrorCode: null,
        isLoading: false
      };
    }
    case AUTH_INFO_CHECK_SUCCESS: {
      let mergeErrors;

      if (state.signUpErrors) {
        mergeErrors = state.signUpErrors;
        delete mergeErrors['email'];
      }

      return {
        ...state,
        signUpErrors: mergeErrors,
        isLoading: false,
        step: state.step + 1
      };
    }
    case AUTH_USERNAME_CHECK_SUCCESS: {
      let mergeErrors;

      if (state.signUpErrors) {
        mergeErrors = state.signUpErrors;
        delete mergeErrors['username'];
      }

      return {
        ...state,
        signUpErrors: mergeErrors,
        isLoading: false,
        step: state.step + 1
      }
    }
    case AUTH_SIGNUP_SUCCESS: {
      return {
        ...state,
        fields: initialState.fields,
        signUpErrors: null,
        isLoading: false,
        step: initialState.step
      };
    }
    case AUTH_SIGNUP:
    case AUTH_INFO_CHECK:
    case AUTH_USERNAME_CHECK:
    case AUTH_LOGIN: {
      return {
        ...state,
        isLoading: true
      };
    }
    case AUTH_ERRORS_CLEAR: {
      return {
        ...state,
        isLoading: false,
        signUpErrors: null,
        logInErrorCode: null
      };
    }
    default:
      return state;
  }
}

export default authReducer;
