/**
  Request Password Reset
**/
export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_ERROR = 'PASSWORD_RESET_REQUEST_ERROR';

export const passwordResetRequest = (callback, payload, history) => {
  return {
    type: PASSWORD_RESET_REQUEST,
    payload,
    callback,
    history
  }
};

export const passwordResetRequestSuccess = payload => {
  return {
    type: PASSWORD_RESET_REQUEST_SUCCESS,
    payload
  }
};

export const passwordResetRequestError = payload => {
  return {
    type: PASSWORD_RESET_REQUEST_ERROR,
    payload
  }
};

/**
  Password Reset
**/
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const passwordReset = (callback, token, payload, history) => {
  return {
    type: PASSWORD_RESET,
    payload,
    token,
    callback,
    history
  }
};

export const passwordResetSuccess = payload => {
  return {
    type: PASSWORD_RESET_SUCCESS,
    payload
  }
};

export const passwordResetError = payload => {
  return {
    type: PASSWORD_RESET_ERROR,
    payload
  }
};
